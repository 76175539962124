import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Outlet, Link } from "react-router-dom";
export const pageUrl = () => "/angular/ivy-renderer";

export default function IvyRenderer() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Ivy Renderer in Angular | Aspirant's Home");
        const urls = {
            'previous': '/angular/rxjs-observables',
            'next': '/angular/testing'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>What is Ivy Renderer in Angular?</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <strong>Ivy Renderer</strong> is a next-generation rendering engine introduced in Angular 9. Think of it as the tool Angular uses behind the scenes to convert your templates (HTML + Angular code) into browser-readable JavaScript that creates the user interface.
                </p>

                <h5 className='mt-5 mb-3'>Why is Ivy Important?</h5>
                <p>Before Ivy, Angular used an older renderer called <strong>View Engine</strong>. Ivy is faster, more efficient, and simplifies the Angular framework, making it easier for developers to work with.</p>


                <h5 className='mt-5 mb-3'>Key Features of Ivy</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>Smaller Bundle Size:</strong>
                        <ul style={{listStyle: 'disc'}}>
                            <li>Ivy removes unused parts of your code (tree-shaking), resulting in smaller app bundles.</li>
                            <li>This makes apps load faster, especially on slow internet connections.</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Faster Compilation:</strong>
                        <ul style={{listStyle: 'disc'}}>
                            <li>When you make changes in your app, Ivy compiles and reflects them faster during development. This speeds up the process of seeing your updates.</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Better Debugging:</strong>
                        <ul style={{listStyle: 'disc'}}>
                            <li>Ivy makes it easier to debug Angular apps. You can inspect components directly in the browser console, making troubleshooting more straightforward.</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Improved Compatibility:</strong>
                        <ul style={{listStyle: 'disc'}}>
                            <li>Ivy works well with third-party libraries and makes Angular apps more compatible with tools and future updates.</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Dynamic Components:</strong>
                        <ul style={{listStyle: 'disc'}}>
                            <li>Ivy allows creating and rendering components dynamically (on-the-fly) without writing extra boilerplate code.</li>
                        </ul>
                    </li>
                </ul>

                <h5 className='mt-5 mb-3'>How Does Ivy Work?</h5>
                <p>Imagine your Angular component:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-red">@Component</span>({</p>
                        <p class="ml-30">    <span class="color-pink">selector</span>: <span class="color-green">'app-hello'</span>,</p>
                        <p class="ml-30">    <span class="color-pink">template</span>: <span class="color-green">&#96;&lt;h1&gt;Hello, {{ name }}!&lt;/h1&gt;&#96;</span>,</p>
                        <p>})</p>
                        <p><span class="color-pink">export class</span> <span class="color-blue">HelloComponent</span> {</p>
                        <p class="ml-30">    name = <span class="color-green">'Angular'</span>;</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>
                <p>Here,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The Ivy Renderer compiles this template into <strong>optimized JavaScript code</strong> that the browser understands.</li>
                    <li>It only includes the parts of Angular needed for this component, reducing unnecessary code.</li>
                </ul>


                <h5 className='mt-5 mb-3'>How to Know If Your App is Using Ivy?</h5>
                <p>By default, Angular projects created with Angular 9 or later use Ivy. You can check in the <span className='background-grey'>tsconfig.json</span> file under the <strong>angularCompilerOptions</strong> section:</p><div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>{</p>
                        <p class="ml-30">    <span class="color-pink">"angularCompilerOptions"</span>: {</p>
                        <p class="ml-60">      <span class="color-pink">"enableIvy"</span>: <span class="color-blue">true</span></p>
                        <p class="ml-30">    }</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>



            </div>
        </section>
    )
}