import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/tag/del";

export default function Del() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - <del> Tag | Aspirant's Home");
        const urls = {
            'previous': '/html/tag-list'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - &lt;del&gt; Tag</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">&lt;del&gt;</span> tag in HTML is used to represent deleted or removed text. Text inside the <span class="background-grey">&lt;del&gt;</span> tag is typically displayed with a strikethrough, visually indicating that the text has been "crossed out." This tag is useful when displaying edits or showing revisions in a document, and it also conveys semantic meaning, indicating that the text has been intentionally removed.
                </p>

                <h5 className='mt-5 mb-3'>Structure and Purpose of <span class="background-grey">&lt;del&gt;</span> Tag</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>Text within <span class="background-grey">&lt;del&gt;</span> is usually rendered with a strikethrough line by default, indicating deletion.</li>
                    <li>The <span class="background-grey">&lt;del&gt;</span> tag suggests that the text has been removed or changed, often in a way relevant to content history or edits.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <p>The <span class="background-grey">&lt;del&gt;</span> tag wraps around the text you want to mark as deleted:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;del&gt;Your deleted text here&lt;/del&gt;</p>
                        `
                    }}></div>
                </div> 


                <h5 className='mt-5 mb-3'>Example Usage of <span class="background-grey">&lt;del&gt;</span> Tag</h5>
                <p>Here’s an example using <span class="background-grey">&lt;del&gt;</span> to show text that has been removed during editing:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Deleted Text Example&lt;/title&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <br />    
                        <p class="ml-60">        &lt;p&gt;The price of the item was &lt;del&gt;$20&lt;/del&gt; &lt;ins&gt;$15&lt;/ins&gt;.&lt;/p&gt;</p>
                        <br />    
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div> 
                <p>In this example, "$20" is shown with a strikethrough using <span class="background-grey">&lt;del&gt;</span>, indicating that the price was changed to "$15", which is shown with <span class="background-grey">&lt;ins&gt;</span> (for "inserted" text).</p>
                <p>Output will be the following -</p>
                <div className='outputPalateBox mt-2 mb-4'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <!DOCTYPE html>
                        <html lang="en">
                            <head>
                                <meta charset="UTF-8">
                                <title>Deleted Text Example</title>
                            </head>
                            <body>
                            
                                <p>The price of the item was <del>$20</del> <ins>$15</ins>.</p>
                            
                            </body>
                        </html>
                        `
                    }}></div>
                </div> 

                <p>
                    The <span class="background-grey">&lt;del&gt;</span> tag indicates removed or edited text, often displayed with a strikethrough. It conveys semantic meaning that the text was intentionally deleted, making it useful in content revisions or updates. Styling with CSS can enhance its appearance, highlighting the deletion further. The <span class="background-grey">&lt;del&gt;</span> tag aids in readability, especially when showing updates or changes to content over time.
                </p>





            </div>
        </section>
    )
}