import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/arrays";

export default function Arrays() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Array Definition in Javascript | Aspirant's Home");
        const urls = {
            'previous': '/javascript/function-closures',
            'next': '/javascript/array-methods'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Array </h3>
            <div className='mt-4 mb-5'>
                <p>
                    An <strong>array</strong> in JavaScript is a <strong>special type of object</strong> used to store multiple values in a single variable. Think of it as a <strong>list</strong> or <strong>collection</strong> of items, like a box that holds multiple objects, where each item has a position (called an <strong>index</strong>) in the array.
                </p>

                <h5 className='mt-5 mb-3'>Why Use Arrays?</h5>
                <p>Arrays are useful when:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>You want to store multiple related values together (e.g., a list of numbers, names, or objects).</li>
                    <li>You need an easy way to work with these values using loops and built-in methods.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Key Features of Arrays</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>Arrays can hold values of any type: numbers, strings, objects, or even other arrays.</li>
                    <li>The values in an array are stored in a specific order, and each value has an <strong>index</strong>.</li>
                    <li>Array indexes start at <span className='background-grey'>0</span>, meaning the first item is at position <span className='background-grey'>0</span>, the second at <span className='background-grey'>1</span>, and so on.</li>
                </ul>


                <h5 className='mt-5 mb-3'>How to Create an Array</h5>
                <p>There are two main ways to create an array in JavaScript:</p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <h6>Using Square Brackets (Recommended):</h6>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">let</span> fruits = [<span class="color-green">"Apple"</span>, <span class="color-green">"Banana"</span>, <span class="color-green">"Cherry"</span>];</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <h6>Using the <span className='background-grey'>Array</span> Constructor:</h6>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">let</span> fruits = <span class="color-blue">new</span> <span class="color-red">Array</span>(<span class="color-green">"Apple"</span>, <span class="color-green">"Banana"</span>, <span class="color-green">"Cherry"</span>);</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>


            </div>
        </section>
    )
}