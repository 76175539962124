import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/jquery/events/blur";

export default function Blur() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("jQuery blur() Method | Aspirant's Home");
        const urls = {
            'previous': '/jquery/events'
        }
        path.setPreviousNext(urls);
        path.setPreviousName('Back to Events');
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>jQuery blur() Method</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">blur()</span> method in jQuery is used to trigger a function when an HTML element loses focus. This is commonly used for form input fields, such as text boxes or text areas, allowing you to perform actions when the user clicks or tabs out of the input field.
                </p>

                <p>It has a basic syntax -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(selector).<span class="color-red">blur</span>(function);</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">selector</span> specifies the element(s) to attach the <span class="background-grey">blur</span> event to.</li>
                    <li><span class="background-grey">function</span> is a callback function to execute when the element loses focus.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Basic Example: <span class="background-grey">blur()</span> with an Input Field</h5>
                <p>Here's an example that displays a message when an input field loses focus.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">id</span>=<span class="color-green">"username"</span> <span class="color-pink">placeholder</span>=<span class="color-green">"Enter your username"</span>&gt;</p>
                        <p>&lt;p <span class="color-pink">id</span>=<span class="color-green">"message"</span>&gt;</p>
                        `
                    }}></div>
                </div>
                <h6>jQuery Code</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"#username"</span>).<span class="color-red">blur</span>(<span class="color-blue">function</span>() {</p>
                        <p class="ml-30">    $(<span class="color-green">"#message"</span>).<span class="color-red">text</span>(<span class="color-green">"You left the username field."</span>);</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span class="background-grey">blur()</span> method is attached to the input field with the ID <span class="background-grey">#username</span>.</li>
                    <li>When the user clicks or tabs out of the input field, the message "You left the username field." appears in the paragraph with the ID <span class="background-grey">#message</span>.</li>
                </ul>

                


            </div>
        </section>
    )
}