import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/angular/event-binding";

export default function EventBinding() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Event Binding in Angular | Aspirant's Home");
        const urls = {
            'previous': '/angular/property-binding',
            'next': '/angular/two-way-binding'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Event Binding</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Event Binding in Angular allows you to capture and respond to <strong>user actions or events</strong> (such as clicks, keypresses, and mouse movements) within your component. It provides a way to bind an event in the template to a method in the component.
                </p>

                <h5 className='mt-5 mb-3'>Syntax</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>(<span class="color-pink">event</span>)=<span class="color-green">"method($event)"</span></p>
                        `
                    }}></div>
                </div>
                <p>Here,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">event</span>: The name of the DOM event (e.g., <span class="background-grey">click</span>, <span class="background-grey">keyup</span>, <span class="background-grey">change</span>).</li>
                    <li><span class="background-grey">method</span>: The component method to execute when the event occurs.</li>
                    <li><span class="background-grey">$event</span>: An object that contains information about the event (optional).</li>
                </ul>


                <h5 className='mt-5 mb-3'>How Event Binding Works</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>When the user triggers the event (e.g., clicks a button), Angular invokes the specified method in the component.</li>
                    <li>You can optionally pass the <strong>event object</strong> (<span class="background-grey">$event</span>) to the method for additional details.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Example: Basic Event Binding</h5>
                <h6 className='mt-3'>Component (TypeScript)</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">import</span> { Component } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                        <br />
                        <p><span class="color-red">@Component</span>({</p>
                        <p class="ml-30">    <span class="color-pink">selector</span>: <span class="color-green">'app-event-binding'</span>,</p>
                        <p class="ml-30">    <span class="color-pink">template</span>: <span class="color-green">&#96;</span></p>
                        <p class="ml-60 color-green">        &lt;button (click)="onClick()"&gt;Click Me&lt;/button&gt;</p>
                        <p class="ml-30 color-green">    &#96;</p>
                        <p>})</p>
                        <p><span class="color-pink">export class</span> <span class="color-blue">EventBindingComponent</span> {</p>
                        <p class="ml-30">    <span class="color-red">onClick</span>() {</p>
                        <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">'Button clicked!'</span>);</p>
                        <p class="ml-30">    }</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>
                <h6 className='mt-3'>Template (HTML)</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;button (<span class="color-pink">click</span>)=<span class="color-green">"onClick()"</span>&gt;Click Me&lt;/button&gt;</p>
                        `
                    }}></div>
                </div>
                <p><strong>Output:</strong> When the button is clicked, "Button clicked!" is logged to the console.</p>

                <h5 className='mt-5 mb-3'>Example: Using the <span class="background-grey">$event</span> Object</h5>
                <h6 className='mt-3'>Component (TypeScript)</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-red">@Component</span>({</p>
                        <p class="ml-30">    <span class="color-pink">selector</span>: <span class="color-green">'app-event-binding'</span>,</p>
                        <p class="ml-30">    <span class="color-pink">template</span>: <span class="color-green">&#96;</span></p>
                        <p class="ml-60 color-green">        &lt;input (input)="onInput($event)" placeholder="Type something" /&gt;</p>
                        <p class="ml-60 color-green">        &lt;p&gt;You typed: {{ typedText }}&lt;/p&gt;</p>
                        <p class="ml-30 color-green">    &#96;</p>
                        <p>})</p>
                        <p><span class="color-pink">export class</span> <span class="color-blue">EventBindingComponent</span> {</p>
                        <p class="ml-30">    typedText: <span class="color-blue">string</span> = <span class="color-green">''</span>;</p>
                        <br />  
                        <p class="ml-30">    <span class="color-red">onInput</span>(event: <span class="color-blue">Event</span>) {</p>
                        <p class="ml-60">        <span class="color-blue">const</span> inputElement = event.target <span class="color-blue">as</span> HTMLInputElement;</p>
                        <p class="ml-60">        <span class="color-blue">this</span>.typedText = inputElement.value;</p>
                        <p class="ml-30">    }</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>
                <h6 className='mt-3'>Template (HTML)</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;input (<span class="color-pink">input</span>)=<span class="color-green">"onInput($event)"</span> <span class="color-pink">placeholder</span>=<span class="color-green">"Type something"</span> /&gt;</p>
                        <p>&lt;p&gt;You typed: {{ typedText }}&lt;/p&gt;</p>
                        `
                    }}></div>
                </div>
                <p><strong>Output:</strong> It displays the text entered in the input field.</p>


            </div>
        </section>
    )
}