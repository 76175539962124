import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/tag-list";

export default function HTMLTags() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - Tags | Aspirant's Home");
        const urls = {
            'previous': '/html/events'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - Tags</h3>
            <div className='mt-4 mb-5'>
                <p>
                    An HTML tag is a fundamental building block in HTML (HyperText Markup Language) used to create and structure elements within a webpage. Tags are used to define various types of content, like headings, paragraphs, links, images, and much more.
                </p>
                <h5 className='mt-5 mb-3'>Types of HTML Tags</h5>
                <p>
                    Here is a comprehensive list of the most commonly used HTML tags, along with brief explanations of their purposes:
                </p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li className='mb-4'>
                        <h6>Basic Structure Tags</h6>
                        <ul style={{ listStyle: 'disc' }}>
                            <li><Link to="/html/tag/doctype-html" class="noDecor"><span class="background-grey">&lt;!DOCTYPE html&gt;</span></Link> : Defines the document type and version of HTML.</li>
                            <li><Link to="/html/tag/html" class="noDecor"><span class="background-grey">&lt;html&gt;</span></Link> : The root element of an HTML page, containing all other elements.</li>
                            <li><Link to="/html/tag/head" class="noDecor"><span class="background-grey">&lt;head&gt;</span></Link> : Contains meta-information about the document, such as title, scripts, and styles.</li>
                            <li><Link to="/html/tag/title" class="noDecor"><span class="background-grey">&lt;title&gt;</span></Link> : Sets the title of the webpage, shown on the browser tab.</li>
                            <li><Link to="/html/tag/body" class="noDecor"><span class="background-grey">&lt;body&gt;</span></Link> : Contains the visible content of the web page.</li>
                        </ul>
                    </li>
                    <li className='mb-4'>
                        <h6>Metadata and SEO Tags</h6>
                        <ul style={{ listStyle: 'disc' }}>
                            <li><Link to="/html/tag/meta" class="noDecor"><span class="background-grey">&lt;meta&gt;</span></Link> : Provides metadata such as charset, viewport, and descriptions.</li>
                            <li><Link to="/html/tag/link" class="noDecor"><span class="background-grey">&lt;link&gt;</span></Link> : Links to external resources like CSS files</li>
                        </ul>
                    </li>
                    <li className='mb-4'>
                        <h6>Text Content Tags</h6>
                        <ul style={{ listStyle: 'disc' }}>
                            <li><Link to="/html/tag/headings" class="noDecor"><span class="background-grey">&lt;h1&gt;</span> - <span class="background-grey">&lt;h6&gt;</span>: Heading tags, <span class="background-grey">&lt;h1&gt;</span></Link> is the largest and <span class="background-grey">&lt;h6&gt;</span> is the smallest.</li>
                            <li><Link to="/html/tag/p" class="noDecor"><span class="background-grey">&lt;p&gt;</span></Link> : Defines a paragraph.</li>
                            <li><Link to="/html/tag/br" class="noDecor"><span class="background-grey">&lt;br&gt;</span></Link> : Inserts a line break.</li>
                            <li><Link to="/html/tag/hr" class="noDecor"><span class="background-grey">&lt;hr&gt;</span></Link> : Inserts a horizontal line, often used for thematic breaks.</li>
                            <li><Link to="/html/tag/blockquote" class="noDecor"><span class="background-grey">&lt;blockquote&gt;</span></Link> : Used for quoted text.</li>
                            <li><Link to="/html/tag/pre" class="noDecor"><span class="background-grey">&lt;pre&gt;</span></Link> : Displays preformatted text (preserves whitespace and line breaks).</li>
                        </ul>
                    </li>
                    <li className='mb-4'>
                        <h6>Formatting Tags</h6>
                        <ul style={{ listStyle: 'disc' }}>
                            <li><Link to="/html/tag/b" class="noDecor"><span class="background-grey">&lt;b&gt;</span></Link> : Makes text bold.</li>
                            <li><Link to="/html/tag/i" class="noDecor"><span class="background-grey">&lt;i&gt;</span></Link> : Makes text italic.</li>
                            <li><Link to="/html/tag/u" class="noDecor"><span class="background-grey">&lt;u&gt;</span></Link> : Underlines the text.</li>
                            <li><Link to="/html/tag/strong" class="noDecor"><span class="background-grey">&lt;strong&gt;</span></Link> : Emphasizes text strongly (bold by default).</li>
                            <li><Link to="/html/tag/em" class="noDecor"><span class="background-grey">&lt;em&gt;</span></Link> : Emphasizes text (italic by default).</li>
                            <li><Link to="/html/tag/mark" class="noDecor"><span class="background-grey">&lt;mark&gt;</span></Link> : Highlights text.</li>
                            <li><Link to="/html/tag/small" class="noDecor"><span class="background-grey">&lt;small&gt;</span></Link> : Reduces text size.</li>
                            <li><Link to="/html/tag/sup" class="noDecor"><span class="background-grey">&lt;sup&gt;</span></Link> : Makes text superscript.</li>
                            <li><Link to="/html/tag/sub" class="noDecor"><span class="background-grey">&lt;sub&gt;</span></Link> : Makes text subscript.</li>
                            <li><Link to="/html/tag/del" class="noDecor"><span class="background-grey">&lt;del&gt;</span></Link> : Shows text as deleted (strikethrough).</li>
                            <li><Link to="/html/tag/ins" class="noDecor"><span class="background-grey">&lt;ins&gt;</span></Link> : Shows text as inserted (underline).</li>
                        </ul>
                    </li>
                    <li className='mb-4'>
                        <h6>Links and Media Tags</h6>
                        <ul style={{ listStyle: 'disc' }}>
                            <li><Link to="/html/text-links" class="noDecor"><span class="background-grey">&lt;a&gt;</span></Link> : Defines a hyperlink.</li>
                            <li><Link to="/html/images" class="noDecor"><span class="background-grey">&lt;img&gt;</span></Link> : Displays an image.</li>
                            <li><Link to="/html/video-element" class="noDecor"><span class="background-grey">&lt;video&gt;</span></Link> : Embeds a video.</li>
                            <li><Link to="/html/audio-element" class="noDecor"><span class="background-grey">&lt;audio&gt;</span></Link> : Embeds an audio file.</li>
                            <li><Link to="/html/iframes" class="noDecor"><span class="background-grey">&lt;iframe&gt;</span></Link> : Embeds another HTML page or content, like a YouTube video.</li>
                        </ul>
                    </li>
                    <li className='mb-4'>
                        <h6>List Tags</h6>
                        <ul style={{ listStyle: 'disc' }}>
                            <li><Link to="/html/unordered-lists" class="noDecor"><span class="background-grey">&lt;ul&gt;</span></Link> : Unordered list, uses bullets.</li>
                            <li><Link to="/html/ordered-lists" class="noDecor"><span class="background-grey">&lt;ol&gt;</span></Link> : Ordered list, uses numbers.</li>
                            <li><Link to="/html/tag/li" class="noDecor"><span class="background-grey">&lt;li&gt;</span></Link> : List item, used within <span class="background-grey">&lt;ul&gt;</span>  or <span class="background-grey">&lt;ol&gt;</span> .</li>
                        </ul>
                    </li>
                    <li className='mb-4'>
                        <h6>Table Tags</h6>
                        <ul style={{ listStyle: 'disc' }}>
                            <li><Link to="/html/tables" class="noDecor"><span class="background-grey">&lt;table&gt;</span></Link> : Defines a table.</li>
                            <li><Link to="/html/tag/tr" class="noDecor"><span class="background-grey">&lt;tr&gt;</span></Link> : Table row.</li>
                            <li><Link to="/html/tag/td" class="noDecor"><span class="background-grey">&lt;td&gt;</span></Link> : Table cell.</li>
                            <li><Link to="/html/tag/th" class="noDecor"><span class="background-grey">&lt;th&gt;</span></Link> : Table header cell.</li>
                        </ul>
                    </li>
                    <li className='mb-4'>
                        <h6>Form Tags</h6>
                        <ul style={{ listStyle: 'disc' }}>
                            <li><Link to="/html/forms" class="noDecor"><span class="background-grey">&lt;form&gt;</span></Link> : Defines a form for user input.</li>
                            <li><Link to="/html/tag/input" class="noDecor"><span class="background-grey">&lt;input&gt;</span></Link> : Collects user input.</li>
                            <li><Link to="/html/tag/textarea" class="noDecor"><span class="background-grey">&lt;textarea&gt;</span></Link> : Multi-line text input.</li>
                            <li><Link to="/html/tag/button" class="noDecor"><span class="background-grey">&lt;button&gt;</span></Link> : Creates a clickable button.</li>
                            <li><Link to="/html/tag/select" class="noDecor"><span class="background-grey">&lt;select&gt;</span></Link> : Creates a dropdown list.</li>
                            <li><Link to="/html/tag/label" class="noDecor"><span class="background-grey">&lt;label&gt;</span></Link> : Labels an input for accessibility.</li>
                        </ul>
                    </li>
                    <li className='mb-4'>
                        <h6>Semantic HTML Tags</h6>
                        <ul style={{ listStyle: 'disc' }}>
                            <li><Link to="/html/tag/header" class="noDecor"><span class="background-grey">&lt;header&gt;</span></Link> : Represents introductory content or navigation links.</li>
                            <li><Link to="/html/tag/nav" class="noDecor"><span class="background-grey">&lt;nav&gt;</span></Link> : Represents navigation links.</li>
                            <li><Link to="/html/tag/section" class="noDecor"><span class="background-grey">&lt;section&gt;</span></Link> : Represents a thematic grouping of content.</li>
                            <li><Link to="/html/tag/article" class="noDecor"><span class="background-grey">&lt;article&gt;</span></Link> : Represents a standalone piece of content.</li>
                            <li><Link to="/html/tag/aside" class="noDecor"><span class="background-grey">&lt;aside&gt;</span></Link> : Represents content aside from the main content.</li>
                            <li><Link to="/html/tag/footer" class="noDecor"><span class="background-grey">&lt;footer&gt;</span></Link> : Represents the footer of a section or page.</li>
                            <li><Link to="/html/tag/main" class="noDecor"><span class="background-grey">&lt;main&gt;</span></Link> : Represents the main content of the document.</li>
                        </ul>
                    </li>
                    <li className='mb-4'>
                        <h6>Multimedia and Graphics Tags</h6>
                        <ul style={{ listStyle: 'disc' }}>
                            <li><Link to="/html/canvas" class="noDecor"><span class="background-grey">&lt;canvas&gt;</span></Link> : A container for graphics, generally used with JavaScript.</li>
                            <li><Link to="/html/svg" class="noDecor"><span class="background-grey">&lt;svg&gt;</span></Link> : Scalable Vector Graphics, used to define vector-based graphics.</li>
                        </ul>
                    </li>
                    <li className='mb-4'>
                        <h6>Scripting Tags</h6>
                        <ul style={{ listStyle: 'disc' }}>
                            <li><Link to="/html/tag/script" class="noDecor"><span class="background-grey">&lt;script&gt;</span></Link> : Embeds or links to JavaScript.</li>
                            <li><Link to="/html/tag/noscript" class="noDecor"><span class="background-grey">&lt;noscript&gt;</span></Link> : Provides alternative content for browsers without JavaScript enabled.</li>
                        </ul>
                    </li>
                    <li className='mb-4'>
                        <h6>Document and Interactive Tags</h6>
                        <ul style={{ listStyle: 'disc' }}>
                            <li><Link to="/html/tag/details" class="noDecor"><span class="background-grey">&lt;details&gt;</span></Link> : Creates a collapsible section.</li>
                            <li><Link to="/html/tag/summary" class="noDecor"><span class="background-grey">&lt;summary&gt;</span></Link> : Summary for the <Link to="/html/tag/details" class="noDecor"><span class="background-grey">&lt;details&gt;</span></Link> tag.</li>
                            <li><Link to="/html/tag/dialog" class="noDecor"><span class="background-grey">&lt;dialog&gt;</span></Link> : Represents a dialog box or popup.</li>
                            <li><Link to="/html/tag/progress" class="noDecor"><span class="background-grey">&lt;progress&gt;</span></Link> : Displays a progress bar.</li>
                            <li><Link to="/html/tag/meter" class="noDecor"><span class="background-grey">&lt;meter&gt;</span></Link> : Displays a scalar measurement within a range.</li>
                        </ul>
                    </li>
                </ul>


            </div>
        </section>
    )
}