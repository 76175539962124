import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/loop-for-of";

export default function LoopForOf() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Loop For Of in Javascript | Aspirant's Home");
        const urls = {
            'previous': '/javascript/loop-for-in',
            'next': '/javascript/loop-while'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>for...of Loop </h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className='background-grey'>for...of</span> <strong>loop</strong> in JavaScript is used to iterate over <strong>iterable objects</strong>, such as arrays, strings, maps, sets, or any object that has an iterable structure.
                </p>
                <p>
                    Unlike the <span className='background-grey'>for...in</span> loop (which iterates over property names), the <span className='background-grey'>for...of</span> loop iterates over the <strong>values</strong> of an iterable object. It's simpler and more readable when dealing with arrays or similar structures.
                </p>

                
                <h5 className='mt-5 mb-3'>When to Use <span className='background-grey'>for...of</span></h5>
                <p>Use <span className='background-grey'>for...of</span> when:</p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>You need to work with the <strong>values</strong> of an iterable object.</li>
                    <li>You want an easy and clean way to iterate through arrays, strings, or other iterable structures.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Syntax</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">for</span> (value <span class="color-blue">of</span> iterable) {</p>
                        <p class="ml-30 color-grey">    // Code to run for each value</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span className='background-grey'>value</span>: Represents the current value in the iterable (e.g., an array element).</li>
                    <li><span className='background-grey'>iterable</span>: The object you want to iterate over (e.g., an array, string, or map).</li>
                </ul>

                <h5 className='mt-5 mb-3'>Example 1: Looping Through an Array</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> fruits = [<span class="color-green">"Apple"</span>, <span class="color-green">"Banana"</span>, <span class="color-green">"Cherry"</span>];</p>

                        <p><span class="color-blue">for</span> (<span class="color-blue">let</span> fruit <span class="color-pink">of</span> fruits) {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(fruit);</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>
                <h6>Output</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>Apple</p>
                        <p>Banana</p>
                        <p>Cherry</p>
                        `
                    }}></div>
                </div>
                <p>Here, The <span className='background-grey'>for...of</span> loop iterates through each value (<span className='background-grey'>"Apple"</span>, <span className='background-grey'>"Banana"</span>, <span className='background-grey'>"Cherry"</span>) in the <span className='background-grey'>fruits</span> array.</p>


                <h5 className='mt-5 mb-3'>Example 2: Looping Through a String</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> word = <span class="color-green">"HELLO"</span>;</p>

                        <p><span class="color-blue">for</span> (<span class="color-blue">let</span> char <span class="color-pink">of</span> word) {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(char);</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>
                <h6>Output</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>H</p>
                        <p>E</p>
                        <p>L</p>
                        <p>L</p>
                        <p>O</p>
                        `
                    }}></div>
                </div>
                <p>The <span className='background-grey'>for...of</span> loop goes through each character in the string.</p>


                <h5 className='mt-5 mb-3'>Difference Between <span className='background-grey'>for...in</span> and <span className='background-grey'>for...of</span></h5>

                <table className='myBorderClass table table-bordered'>
                    <thead>
                        <tr>
                            <th style={{backgroundColor:'#ddd'}}>Feature</th>
                            <th style={{backgroundColor:'#ddd'}}>for...in</th>
                            <th style={{backgroundColor:'#ddd'}}>for...of</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Use Case</td>
                            <td>Iterates over property names (keys).</td>
                            <td>Iterates over values.</td>
                        </tr>
                        <tr>
                            <td>Works With</td>
                            <td>Objects, arrays, and other enumerables.</td>
                            <td>Arrays, strings, maps, sets, etc.</td>
                        </tr>
                        <tr>
                            <td>Example Output</td>
                            <td>Indexes for arrays or keys for objects.</td>
                            <td>Values of arrays or iterable items.</td>
                        </tr>
                    </tbody>
                </table>

                <h5 className='mt-5 mb-3'>Iterating Over an Array (Comparison)</h5>
                <p>Using <span className='background-grey'>for...in</span>:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> fruits = [<span class="color-green">"Apple"</span>, <span class="color-green">"Banana"</span>, <span class="color-green">"Cherry"</span>];</p>

                        <p><span class="color-blue">for</span> (<span class="color-blue">let</span> index <span class="color-pink">in</span> fruits) {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(index); <span class="color-grey">// Logs 0, 1, 2</span></p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(fruits[index]); <span class="color-grey">// Logs "Apple", "Banana", "Cherry"</span></p>
                        <p>}</p>
                        `
                    }}></div>
                </div>
                <p>Using <span className='background-grey'>for...of</span>:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">for</span> (<span class="color-blue">let</span> fruit <span class="color-pink">of</span> fruits) {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(fruit); <span class="color-grey">// Logs "Apple", "Banana", "Cherry"</span></p>
                        <p>}</p>
                        `
                    }}></div>
                </div>



            </div>  
        </section>
    )
}