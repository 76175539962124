import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Outlet, Link } from "react-router-dom";
export const pageUrl = () => "/angular/shared-modules";

export default function SharedModule() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Shared Module in Angular | Aspirant's Home");
        const urls = {
            'previous': '/angular/feature-modules',
            'next': '/angular/lazy-loaded-modules'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>What is a Shared Module in Angular?</h3>
            <div className='mt-4 mb-5'>
                <p>Imagine you're working on a big project, and there are certain things everyone needs to use, like:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>A button design.</li>
                    <li>A date-picker component.</li>
                    <li>A custom pipe to format data.</li>
                    <li>Common directives like toggling visibility.</li>
                </ul>
                <p>Instead of adding these <strong>shared features</strong> to every module separately, we create a <strong>Shared Module</strong>. This module contains common, reusable pieces of the application that can be shared across multiple modules.</p>


                <h5 className='mt-5 mb-3'>Why Use a Shared Module?</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li><strong>Reusability</strong>: Write once, use everywhere.</li>
                    <li><strong>Cleaner Code</strong>: Avoids duplicating imports and declarations in multiple modules.</li>
                    <li><strong>Easier Maintenance</strong>: Update one place to reflect changes everywhere.</li>
                </ul>


                <h5 className='mt-5 mb-3'>How to Create a Shared Module?</h5>
                <p>Here’s a simple step-by-step guide for beginners:</p>

                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <h6>Generate a Shared Module</h6>
                        <p>Use the Angular CLI:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">ng generate</span> module <span class="color-blue">shared</span></p>
                                `
                            }}></div>
                        </div>
                        <p>This creates:</p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>A folder named <span class="background-grey">shared</span>.</li>
                            <li>A file named <span class="background-grey">shared.module.ts</span>.</li>
                        </ul>
                    </li>
                    <li className='mt-4'>
                        <h6>Add Common Features to the Shared Module</h6>
                        <p>For example, let’s assume you want to:</p>
                        <ul className='mb-3' style={{ listStyle: 'decimal' }}>
                            <li>Use <strong>Angular Material</strong> buttons across the app.</li>
                            <li>Create a custom <strong>uppercase pipe</strong>.</li>
                            <li>Add a <strong>custom directive</strong> for hiding/showing elements.</li>
                        </ul>
                        <p>Here’s what you’d do in <span class="background-grey">shared.module.ts</span> :</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">import</span> { <span class="color-blue">NgModule</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                                <p><span class="color-pink">import</span> { <span class="color-blue">CommonModule</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/common'</span>; <span class="color-grey">// Provides directives like ngIf, ngFor</span></p>
                                <p><span class="color-pink">import</span> { <span class="color-blue">MatButtonModule</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/material/button'</span>; <span class="color-grey">// Angular Material Button</span></p>
                                <p><span class="color-pink">import</span> { <span class="color-blue">UppercasePipe</span> } <span class="color-pink">from</span> <span class="color-green">'./uppercase.pipe'</span>; <span class="color-grey">// Custom pipe</span></p>
                                <p><span class="color-pink">import</span> { <span class="color-blue">HideElementDirective</span> } <span class="color-pink">from</span> <span class="color-green">'./hide-element.directive'</span>; <span class="color-grey">// Custom directive</span></p>
                                <br />
                                <p><span class="color-red">@NgModule</span>({</p>
                                <p class="ml-30">    <span class="color-pink">declarations</span>: [</p>
                                <p class="ml-60">        <span class="color-blue">UppercasePipe</span>,        <span class="color-grey">// Declare the custom pipe</span></p>
                                <p class="ml-60">        <span class="color-blue">HideElementDirective</span>, <span class="color-grey">// Declare the custom directive</span></p>
                                <p class="ml-30">    ],</p>
                                <p class="ml-30">    <span class="color-pink">imports</span>: [</p>
                                <p class="ml-60">        <span class="color-blue">CommonModule</span>,        <span class="color-grey">// Basic Angular directives (ngIf, ngFor, etc.)</span></p>
                                <p class="ml-60">        <span class="color-blue">MatButtonModule</span>,     <span class="color-grey">// Import Angular Material button module</span></p>
                                <p class="ml-30">    ],</p>
                                <p class="ml-30">    <span class="color-pink">exports</span>: [</p>
                                <p class="ml-60">        <span class="color-blue">CommonModule</span>,        <span class="color-grey">// Export so other modules can use ngIf, ngFor</span></p>
                                <p class="ml-60">        <span class="color-blue">MatButtonModule</span>,     <span class="color-grey">// Export so other modules can use Material buttons</span></p>
                                <p class="ml-60">        <span class="color-blue">UppercasePipe</span>,       <span class="color-grey">// Export the custom pipe</span></p>
                                <p class="ml-60">        <span class="color-blue">HideElementDirective</span> <span class="color-grey">// Export the custom directive</span></p>
                                <p class="ml-30">    ],</p>
                                <p>})</p>
                                <p><span class="color-pink">export class</span> <span class="color-blue">SharedModule</span> {}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='mt-4'>
                        <h6>Create a Custom Pipe</h6>
                        <p>In the <span class="background-grey">shared</span> folder, create a pipe:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">ng generate</span> pipe <span class="color-blue">shared/uppercase</span></p>
                                `
                            }}></div>
                        </div>
                        <p>This creates: <span class="background-grey">uppercase.pipe.ts</span></p>
                        <p>Now in <span class="background-grey">uppercase.pipe.ts</span> file :</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">import</span> { <span class="color-blue">Pipe</span>, <span class="color-blue">PipeTransform</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                                <br />
                                <p><span class="color-red">@Pipe</span>({</p>
                                <p class="ml-30">    <span class="color-pink">name</span>: <span class="color-green">'uppercase'</span>,</p>
                                <p>})</p>
                                <p><span class="color-pink">export class</span> <span class="color-blue">UppercasePipe</span> <span class="color-pink">implements</span> <span class="color-blue">PipeTransform</span> {</p>
                                <p class="ml-30">    <span class="color-red">transform</span>(value: <span class="color-blue">string</span>): string {</p>
                                <p class="ml-60">        <span class="color-pink">return</span> value.<span class="color-red">toUpperCase</span>();</p>
                                <p class="ml-30">    }</p>
                                <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='mt-4'>
                        <h6>Create a Custom Directive</h6>
                        <p>In the <span class="background-grey">shared</span> folder, create a directive:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">ng generate</span> directive <span class="color-blue">shared/hide-element</span></p>
                                `
                            }}></div>
                        </div>
                        <p>This creates: <span class="background-grey">hide-element.directive.ts</span></p>
                        <p>Now in <span class="background-grey">hide-element.directive.ts</span> file :</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">import</span> { <span class="color-blue">Directive</span>, <span class="color-blue">ElementRef</span>, <span class="color-blue">Renderer2</span>, <span class="color-blue">Input</span>, <span class="color-blue">OnChanges</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                                <br />
                                <p><span class="color-red">@Directive</span>({</p>
                                <p class="ml-30">    <span class="color-pink">selector</span>: <span class="color-green">'[appHideElement]'</span>,</p>
                                <p>})</p>
                                <p><span class="color-pink">export class</span> <span class="color-blue">HideElementDirective</span> <span class="color-pink">implements</span> <span class="color-blue">OnChanges</span> {</p>
                                <p class="ml-30">    <span class="color-red">@Input</span>() appHideElement: <span class="color-pink">boolean</span> = <span class="color-blue">false</span>;</p>
                                <br />
                                <p class="ml-30">    <span class="color-red">constructor</span>(<span class="color-pink">private</span> el: <span class="color-blue">ElementRef</span>, <span class="color-pink">private</span> renderer: <span class="color-blue">Renderer2</span>) {}</p>
                                <br />
                                <p class="ml-30">    <span class="color-red">ngOnChanges</span>() {</p>
                                <p class="ml-60">        <span class="color-blue">if</span> (<span class="color-blue">this</span>.appHideElement) {</p>
                                <p class="ml-90">            <span class="color-blue">this</span>.renderer.<span class="color-red">setStyle</span>(<span class="color-blue">this</span>.el.nativeElement, <span class="color-green">'display'</span>, <span class="color-green">'none'</span>);</p>
                                <p class="ml-60">        } <span class="color-blue">else</span> {</p>
                                <p class="ml-90">            <span class="color-blue">this</span>.renderer.<span class="color-red">removeStyle</span>(<span class="color-blue">this</span>.el.nativeElement, <span class="color-green">'display'</span>);</p>
                                <p class="ml-60">        }</p>
                                <p class="ml-30">    }</p>
                                <p>}</p>
                                `
                            }}></div>
                        </div>
                        <p>Use it in templates:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;div [<span class="color-pink">appHideElement</span>]=<span class="color-green">"true"</span>&gt;You can't see me!&lt;/div&gt;</p>
                                    <p>&lt;div [<span class="color-pink">appHideElement</span>]=<span class="color-green">"false"</span>&gt;You can see me!&lt;/div&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='mt-4'>
                        <h6>Use the Shared Module</h6>
                        <p>Now that your <span class="background-grey">SharedModule</span> has the common features, import it into any module where you need these features.</p>

                        <strong>In a Feature Module (<span class="background-grey">products.module.ts</span>):</strong>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">import</span> { <span class="color-blue">NgModule</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                                <p><span class="color-pink">import</span> { <span class="color-blue">CommonModule</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/common'</span>;</p>
                                <p><span class="color-pink">import</span> { <span class="color-blue">ProductsComponent</span> } <span class="color-pink">from</span> <span class="color-green">'./products.component'</span>;</p>
                                <p><span class="color-pink">import</span> { <span class="color-blue">SharedModule</span> } <span class="color-pink">from</span> <span class="color-green">'../shared/shared.module'</span>; <span class="color-grey">// Import Shared Module</span></p>
                                <br />
                                <p><span class="color-red">@NgModule</span>({</p>
                                <p class="ml-30">    <span class="color-pink">declarations</span>: [<span class="color-blue">ProductsComponent</span>],</p>
                                <p class="ml-30">    <span class="color-pink">imports</span>: [</p>
                                <p class="ml-60">        <span class="color-blue">CommonModule</span>,</p>
                                <p class="ml-60">        <span class="color-blue">SharedModule</span>, <span class="color-grey">// Import the Shared Module</span></p>
                                <p class="ml-30">    ],</p>
                                <p>})</p>
                                <p><span class="color-pink">export class</span> <span class="color-blue">ProductsModule</span> {}</p>
                                `
                            }}></div>
                        </div>
                        <p>Now, in <span class="background-grey">ProductsComponent</span>, you can use:</p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>
                                <strong>Angular Material buttons:</strong>
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>&lt;button <span class="color-pink">mat-button</span>&gt;Click Me&lt;/button&gt;</p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <strong>Custom pipe:</strong>
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>&lt;p&gt;{{ <span class="color-green">'angular'</span> | <span class="color-pink">uppercase</span> }}&lt;/p&gt; <span class="color-grey">&lt;!-- Output: ANGULAR --&gt;</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <strong>Custom directive:</strong>
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>&lt;div [<span class="color-pink">appHideElement</span>]=<span class="color-green">"true"</span>&gt;Hidden Content&lt;/div&gt;</p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>

                <p className='mt-4'>By organizing your code with a SharedModule, you make your app cleaner and more efficient!</p>



            </div>
        </section>
    )
}