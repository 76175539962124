import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/tag/blockquote";

export default function BlockQuote() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - <blockquote> Tag | Aspirant's Home");
        const urls = {
            'previous': '/html/tag-list'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - &lt;blockquote&gt; Tag</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">&lt;blockquote&gt;</span> tag in HTML is used to indicate a long or extended quotation from another source. It’s a block-level element, which means it creates a new block of text and often comes with default indentation. The <span class="background-grey">&lt;blockquote&gt;</span> tag is typically used for quotes that span multiple lines or paragraphs, while shorter quotes can be wrapped in the inline <span class="background-grey">&lt;q&gt;</span> tag.
                </p>

                <h5 className='mt-5 mb-3'>Structure and Purpose of <span class="background-grey">&lt;blockquote&gt;</span> Tag</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>Used to attribute longer quotes or excerpts from external sources.</li>
                    <li>Browsers usually apply left-margin indentation to differentiate the quote visually.</li>
                    <li>The <span class="background-grey">cite</span> attribute can include a URL as the source of the quote.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <p>The <span class="background-grey">&lt;blockquote&gt;</span> tag surrounds the quoted text:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;blockquote&gt;</p>
                        <p class="ml-30">    Your quoted text goes here.</p>
                        <p>&lt;/blockquote&gt;</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Example Usage of <span class="background-grey">&lt;blockquote&gt;</span> Tag</h5>
                <p>Here’s an example of using <span class="background-grey">&lt;blockquote&gt;</span> to quote an external source:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Blockquote Example&lt;/title&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <br />    
                        <p class="ml-60">        &lt;h2&gt;Inspirational Quote&lt;/h2&gt;</p>
                        <br />        
                        <p class="ml-60">        &lt;blockquote <span class="color-pink">cite</span>=<span class="color-green">"https://www.example.com/quotes"</span>&gt;</p>
                        <p class="ml-90">        "The journey of a thousand miles begins with one step."</p>
                        <p class="ml-60">        &lt;/blockquote&gt;</p>
                        <br />        
                        <p class="ml-60">        &lt;p&gt;- Lao Tzu&lt;/p&gt;</p>
                        <br />    
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Output will be the following -</p>
                <div className='outputPalateBox mt-2 mb-4'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <!DOCTYPE html>
                        <html lang="en">
                            <head>
                                <meta charset="UTF-8">
                                <title>Blockquote Example</title>
                            </head>
                            <body>
                            
                                <h2>Inspirational Quote</h2>
                                
                                <blockquote cite="https://www.example.com/quotes">
                                "The journey of a thousand miles begins with one step."
                                </blockquote>
                                
                                <p>- Lao Tzu</p>
                            
                            </body>
                        </html>
                        `
                    }}></div>
                </div>

                <p>
                    In this example, the <span class="background-grey">&lt;blockquote&gt;</span> tag is used to display an inspirational quote, with the <span class="background-grey">cite</span> attribute optionally indicating the source URL. Browsers may not show the <span class="background-grey">cite</span> URL but using it helps provide context and accessibility.
                </p>

                
                <h5 className='mt-5 mb-3'>The <span class="background-grey">cite</span> Attribute</h5>
                <p>The <span class="background-grey">cite</span> attribute is used to provide a reference for the quote. Although this is optional, it’s useful for documentation and can be beneficial for SEO and accessibility:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;blockquote <span class="color-pink">cite</span>=<span class="color-green">"https://www.example.com/source"</span>&gt;</p>
                        <p class="ml-30">    "The only limit to our realization of tomorrow is our doubts of today."</p>
                        <p>&lt;/blockquote&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Output will be the following -</p>
                <div className='outputPalateBox mt-2 mb-4'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <blockquote cite="https://www.example.com/source">
                            "The only limit to our realization of tomorrow is our doubts of today."
                        </blockquote>
                        `
                    }}></div>
                </div>



            </div>
        </section>
    )
}