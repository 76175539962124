import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Outlet, Link } from "react-router-dom";
export const pageUrl = () => "/angular/feature-modules";

export default function FeatureModule() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Feature Module in Angular | Aspirant's Home");
        const urls = {
            'previous': '/angular/root-module',
            'next': '/angular/shared-modules'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>What is a Feature Module in Angular?</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Think of an Angular application as a big house. The <strong>root module</strong> (called AppModule) is like the foundation of the house—it holds everything together. But a big house needs separate rooms for different purposes, right? For example:
                </p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>One room for the <strong>kitchen</strong>.</li>
                    <li>Another room for the <strong>bedroom</strong>.</li>
                    <li>A third room for the <strong>living room</strong>.</li>
                </ul>
                <p>Similarly, in an Angular app, <strong>Feature Modules</strong> are like separate rooms for different features. Each feature (or room) has its own <strong>components</strong>, <strong>services</strong>, and other things it needs.</p>


                <h5 className='mt-5 mb-3'>Why Do We Need Feature Modules?</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li><strong>Organized Code</strong>: Instead of dumping everything into one file, you separate features into their own modules.</li>
                    <li><strong>Easier to Manage</strong>: If your app grows big, it’s easier to work on one feature without affecting others.</li>
                    <li><strong>Lazy Loading</strong>: Some features can load only when the user needs them (to save time and memory).</li>
                </ul>


                <h5 className='mt-5 mb-3'>How to Create a Feature Module?</h5>
                <p>Open your terminal and type:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">ng generate</span> module <span class="color-blue">feature-name</span></p>
                        `
                    }}></div>
                </div>
                <p>For example -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">ng generate</span> module <span class="color-blue">products</span></p>
                        `
                    }}></div>
                </div>
                <p>This creates a folder named <span class="background-grey">products</span> and a file called <span class="background-grey">products.module.ts</span>.</p>


                <h5 className='mt-5 mb-3'>What Does a Feature Module Look Like?</h5>
                <p>Here’s a simple <span class="background-grey">products.module.ts</span> file:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">import</span> { <span class="color-blue">NgModule</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                        <p><span class="color-pink">import</span> { <span class="color-blue">CommonModule</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/common'</span>; <span class="color-grey">// For Angular's basic features like ngIf, ngFor</span></p>
                        <p><span class="color-pink">import</span> { <span class="color-blue">ProductsComponent</span> } <span class="color-pink">from</span> <span class="color-green">'./products.component'</span>; <span class="color-grey">// Feature-specific component</span></p>
                        <br />
                        <p><span class="color-red">@NgModule</span>({</p>
                        <p class="ml-30">    <span class="color-pink">declarations</span>: [</p>
                        <p class="ml-60">        <span class="color-blue">ProductsComponent</span>, <span class="color-grey">// Tells Angular this component belongs to this module</span></p>
                        <p class="ml-30">    ],</p>
                        <p class="ml-30">    <span class="color-pink">imports</span>: [</p>
                        <p class="ml-60">        <span class="color-blue">CommonModule</span>, <span class="color-grey">// Provides Angular directives like ngIf, ngFor</span></p>
                        <p class="ml-30">    ],</p>
                        <p class="ml-30">    <span class="color-pink">exports</span>: [</p>
                        <p class="ml-60">        <span class="color-blue">ProductsComponent</span>, <span class="color-grey">// Allows this component to be used in other parts of the app</span></p>
                        <p class="ml-30">    ],</p>
                        <p>})</p>
                        <p><span class="color-pink">export class</span> <span class="color-blue">ProductsModule</span> {}</p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-3'>How to Add a Component to a Feature Module?</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <p>Use the command:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">ng generate</span> component <span class="color-blue">products/product-list</span></p>
                                `
                            }}></div>
                        </div>
                        <p>This will create:</p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>product-list.component.ts</li>
                            <li>product-list.component.html</li>
                            <li>product-list.component.css</li>
                        </ul>
                    </li>
                    <li className='mt-3'>
                        The component gets added to the <span class="background-grey">ProductsModule</span> automatically (in <span class="background-grey">declarations</span>).
                    </li>
                </ul>


                <h5 className='mt-5 mb-3'>How to Use a Feature Module?</h5>
                <p>Let’s say you created a <span class="background-grey">ProductsModule</span>. To use it:</p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        Open <span class="background-grey">app.module.ts</span> (root module) and <strong>import</strong> the feature module:
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">import</span> { <span class="color-blue">ProductsModule</span> } <span class="color-pink">from</span> <span class="color-green">'./products/products.module'</span>;</p>
                                <br />
                                <p><span class="color-red">@NgModule</span>({</p>
                                <p class="ml-30">    <span class="color-pink">imports</span>: [</p>
                                <p class="ml-60">        <span class="color-blue">ProductsModule</span>, <span class="color-grey">// Add it here</span></p>
                                <p class="ml-30">    ],</p>
                                <p>})</p>
                                <p><span class="color-pink">export class</span> <span class="color-blue">AppModule</span> {}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>Now, the <span class="background-grey">ProductsModule</span> and its components can be used in your app.</li>
                </ul>

                
                <h5 className='mt-5 mb-3'>What if the Feature Module Needs Routing?</h5>
                <p>Sometimes, you want a feature module to have its <strong>own routes</strong> (like <span class="background-grey">/products</span>, <span class="background-grey">/products/details</span>). Here’s how to do that:</p>

                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <p>Generate a module with routing:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">ng generate</span> module <span class="color-blue">products</span> <span class="color-pink">--routing</span></p>
                                `
                            }}></div>
                        </div>
                        <p>This will create:</p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li><strong>products.module.ts</strong> (Feature Module)</li>
                            <li><strong>products-routing.module.ts</strong> (Handles routes for this module)</li>
                        </ul>
                    </li>
                    <li className='mt-4'>
                        <p>In <span class="background-grey">products-routing.module.ts</span>, define the routes:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">import</span> { <span class="color-blue">NgModule</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                                <p><span class="color-pink">import</span> { <span class="color-blue">RouterModule</span>, <span class="color-blue">Routes</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/router'</span>;</p>
                                <p><span class="color-pink">import</span> { <span class="color-blue">ProductsComponent</span> } <span class="color-pink">from</span> <span class="color-green">'./products.component'</span>;</p>
                                <br />
                                <p><span class="color-blue">const</span> routes: <span class="color-pink">Routes</span> = [</p>
                                <p class="ml-30">    { <span class="color-pink">path</span>: <span class="color-green">''</span>, <span class="color-pink">component</span>: <span class="color-blue">ProductsComponent</span> }, <span class="color-grey">// Route for Products</span></p>
                                <p>];</p>
                                <br />
                                <p><span class="color-red">@NgModule</span>({</p>
                                <p class="ml-30">  <span class="color-pink">imports</span>: [RouterModule.<span class="color-red">forChild</span>(routes)], <span class="color-grey">// For feature-specific routing</span></p>
                                <p class="ml-30">  <span class="color-pink">exports</span>: [<span class="color-blue">RouterModule</span>],</p>
                                <p>})</p>
                                <p><span class="color-pink">export class</span> <span class="color-blue">ProductsRoutingModule</span> {}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='mt-4'>
                        <p>Update <span class="background-grey">products.module.ts</span> to include routing:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">import</span> { <span class="color-blue">NgModule</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                                <p><span class="color-pink">import</span> { <span class="color-blue">CommonModule</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/common'</span>;</p>
                                <p><span class="color-pink">import</span> { <span class="color-blue">ProductsComponent</span> } <span class="color-pink">from</span> <span class="color-green">'./products.component'</span>;</p>
                                <p><span class="color-pink">import</span> { <span class="color-blue">ProductsRoutingModule</span> } <span class="color-pink">from</span> <span class="color-green">'./products-routing.module'</span>;</p>
                                <br />
                                <p><span class="color-red">@NgModule</span>({</p>
                                <p class="ml-30">    <span class="color-pink">declarations</span>: [<span class="color-blue">ProductsComponent</span>],</p>
                                <p class="ml-30">    <span class="color-pink">imports</span>: [</p>
                                <p class="ml-60">        <span class="color-blue">CommonModule</span>,</p>
                                <p class="ml-60">        <span class="color-blue">ProductsRoutingModule</span>, <span class="color-grey">// Import feature routing</span></p>
                                <p class="ml-30">    ],</p>
                                <p>})</p>
                                <p><span class="color-pink">export class</span> <span class="color-blue">ProductsModule</span> {}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='mt-4'>
                        <p>Add the feature module route to the main app routing (<span class="background-grey">app-routing.module.ts</span>):</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">const</span> routes: <span class="color-pink">Routes</span> = [</p>
                                <p class="ml-30">    { <span class="color-pink">path</span>: <span class="color-green">'products'</span>, <span class="color-pink">loadChildren</span>: () => <span class="color-red">import</span>(<span class="color-green">'./products/products.module'</span>).<span class="color-red">then</span>(m => m.ProductsModule) },</p>
                                <p>];</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>

                <p className='mt-5'>With this modular approach, building and maintaining your app becomes much simpler! Let me know if you'd like to see any specific part in action! </p>



            </div>
        </section>
    )
}