import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Outlet, Link } from "react-router-dom";
export const pageUrl = () => "/angular/using-built-in-pipes";

export default function BuiltInPipe() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Built-in Pipes in Angular | Aspirant's Home");
        const urls = {
            'previous': '/angular/interceptors',
            'next': '/angular/creating-custom-pipes'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Built-in Pipes</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In Angular, <strong>pipes</strong> are tools that transform data before displaying it in the view (HTML). They are simple functions that format data directly in the template.
                </p>

                <h5 className='mt-5 mb-3'>Why Use Pipes?</h5>
                <p>Pipes are useful for:</p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>Formatting dates, numbers, or text.</li>
                    <li>Changing how data is displayed without altering the original data.</li>
                    <li>Simplifying templates by applying transformations directly.</li>
                </ul>
                <p>For example:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;p&gt;{{ userName | <span class="color-pink">uppercase</span> }}&lt;/p&gt;</p>
                        `
                    }}></div>
                </div>
                <p>The <span class="background-grey">uppercase</span> pipe converts <span class="background-grey">userName</span> to uppercase letters.</p>


                <h5 className='mt-5 mb-3'>How to Use Built-In Pipes</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        Pipes are applied in Angular templates using the <strong>pipe operator (|)</strong>.
                    </li>
                    <li>
                        <p>Syntax:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>{{ expression | <span class="color-pink">pipeName</span> }}</p>
                                `
                            }}></div>
                        </div>
                        <p>You can chain multiple pipes:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>{{ expression | <span class="color-pink">pipe1</span> | <span class="color-pink">pipe2</span> }}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>


                <h5 className='mt-5 mb-3'>Common Built-In Pipes</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>Uppercase and Lowercase Pipes</strong>
                        <p>Used to convert text to uppercase or lowercase.</p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>
                                <strong>Uppercase:</strong>
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>&lt;p&gt;{{ <span class="color-green">'hello'</span> | <span class="color-pink">uppercase</span> }}&lt;/p&gt; <span class="color-grey">&lt;!-- Output: HELLO --&gt;</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <strong>Lowercase:</strong>
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>&lt;p&gt;{{ <span class="color-green">'HELLO'</span> | <span class="color-pink">lowercase</span> }}&lt;/p&gt; <span class="color-grey">&lt;!-- Output: hello --&gt;</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>Date Pipe</strong>
                        <p>Formats dates in different ways.</p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>
                                <strong>Default Date Format:</strong>
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                        <p>&lt;p&gt;{{ currentDate | <span class="color-pink">date</span> }}&lt;/p&gt; <span class="color-grey">&lt;!-- Output: Apr 15, 2024 --&gt;</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <strong>Custom Formats:</strong>
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                        <p>&lt;p&gt;{{ currentDate | <span class="color-pink">date</span>:<span class="color-green">'fullDate'</span> }}&lt;/p&gt; <span class="color-grey">&lt;!-- Output: Monday, April 15, 2024 --&gt;</span></p>
                                        <p>&lt;p&gt;{{ currentDate | <span class="color-pink">date</span>:<span class="color-green">'shortDate'</span> }}&lt;/p&gt; <span class="color-grey">&lt;!-- Output: 4/15/24 --&gt;</span></p>
                                        <p>&lt;p&gt;{{ currentDate | <span class="color-pink">date</span>:<span class="color-green">'dd-MM-yyyy'</span> }}&lt;/p&gt; <span class="color-grey">&lt;!-- Output: 15-04-2024 --&gt;</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>Currency Pipe</strong>
                        <p>Formats numbers as currency.</p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>
                                <strong>Example:</strong>
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>&lt;p&gt;{{ 1234.56 | <span class="color-pink">currency</span> }}&lt;/p&gt; <span class="color-grey">&lt;!-- Output: $1,234.56 --&gt;</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <strong>With Custom Currency:</strong>
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>&lt;p&gt;{{ <span class="color-pink">1234.56</span> | <span class="color-pink">currency</span>:<span class="color-green">'EUR'</span> }}&lt;/p&gt; <span class="color-grey">&lt;!-- Output: €1,234.56 --&gt;</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>Decimal Pipe</strong>
                        <p>Formats numbers with a specified number of decimal places.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;p&gt;{{ 1234.567 | <span class="color-pink">number</span>:<span class="color-green">'1.2-2'</span> }}&lt;/p&gt; <span class="color-grey">&lt;!-- Output: 1,234.57 --&gt;</span></p>
                                `
                            }}></div>
                        </div>
                        <p>Where, <span class="background-grey">1.2-2</span> is at least 1 digit before the decimal, 2 minimum and 2 maximum digits after the decimal.</p>
                    </li>
                    <li>
                        <strong>Percent Pipe</strong>
                        <p>Converts a number into a percentage.</p>
                        <p>For example:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;p&gt;{{ 0.25 | <span class="color-pink">percent</span> }}&lt;/p&gt; <span class="color-grey">&lt;!-- Output: 25% --&gt;</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>JSON Pipe</strong>
                        <p>Displays an object or array as a JSON string (useful for debugging).</p>
                        <p>For example: </p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>&lt;pre&gt;{{ { <span class="color-pink">name</span>: <span class="color-green">'John'</span>, <span class="color-pink">age</span>: <span class="color-pink">30</span> } | <span class="color-pink">json</span> }}&lt;/pre&gt;</p>
                                <p class="color-grey">&lt;!-- Output:</p>
                                <p class="color-grey">{</p>
                                <p class="ml-30 color-grey">  "name": "John",</p>
                                <p class="ml-30 color-grey">  "age": 30</p>
                                <p class="color-grey">}</p>
                                <p class="color-grey">--&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Slice Pipe</strong>
                        <p>Extracts a portion of a string or an array.</p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>
                                <strong>String Example:</strong>
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>&lt;p&gt;{{ <span class="color-green">'Hello World'</span> | <span class="color-pink">slice:0:5</span> }}&lt;/p&gt; <span class="color-grey">&lt;!-- Output: Hello --&gt;</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <strong>Array Example:</strong>
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>&lt;p&gt;{{ [<span class="color-pink">1</span>, <span class="color-pink">2</span>, <span class="color-pink">3</span>, <span class="color-pink">4</span>, <span class="color-pink">5</span>] | <span class="color-pink">slice:1:4</span> }}&lt;/p&gt; <span class="color-grey">&lt;!-- Output: 2,3,4 --&gt;</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>Async Pipe</strong>
                        <p>Handles asynchronous data like Observables or Promises in Angular.</p>
                        <p>For example:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p class="color-grey">// In Component</p>
                                    <p>data$ = of(<span class="color-green">'Hello from Async Pipe'</span>);</p>
                                `
                            }}></div>
                        </div>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;p&gt;{{ data$ | <span class="color-pink">async</span> }}&lt;/p&gt; &lt;!-- Output: Hello from Async Pipe --&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>


                <p className='mt-5'>
                    Built-in pipes in Angular simplify data transformations in your templates. They save time, improve readability, and are easy to use even for beginners. Experiment with pipes to understand how they can make your applications look polished and user-friendly.
                </p>



            </div>
        </section>
    )
}