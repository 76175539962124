import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/jquery/events/submit";

export default function Submit() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("jQuery submit() Method | Aspirant's Home");
        const urls = {
            'previous': '/jquery/events'
        }
        path.setPreviousNext(urls);
        path.setPreviousName('Back to Events');
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>jQuery submit() Method</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">submit()</span> method in jQuery is used to attach an event handler to the <strong>submit event</strong> of a form. This event is triggered when a form is submitted, either via a submit button or programmatically using JavaScript.
                </p>

                <p>It has a basic syntax -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(document).<span class="color-red">submit</span>(function);</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">selector</span> specifies the form(s) to which the submit event will be attached.</li>
                    <li><span class="background-grey">function</span> is the callback function that executes when the form is submitted.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Example : Prevent Default Submission and Validate</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;form <span class="color-pink">id</span>=<span class="color-green">"loginForm"</span>&gt;</p>
                        <p class="ml-30">    &lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">id</span>=<span class="color-green">"username"</span> <span class="color-pink">placeholder</span>=<span class="color-green">"Username"</span> <span class="color-pink">required</span>&gt;</p>
                        <p class="ml-30">    &lt;input <span class="color-pink">type</span>=<span class="color-green">"password"</span> <span class="color-pink">id</span>=<span class="color-green">"password"</span> <span class="color-pink">placeholder</span>=<span class="color-green">"Password"</span> <span class="color-pink">required</span>&gt;</p>
                        <p class="ml-30">    &lt;button <span class="color-pink">type</span>=<span class="color-green">"submit"</span>&gt;Login&lt;/button&gt;</p>
                        <p>&lt;/form&gt;</p>
                        `
                    }}></div>
                </div>
                <h6>jQuery Code</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"#loginForm"</span>).<span class="color-red">submit</span>(<span class="color-blue">function</span>(event) {</p>
                        <p class="ml-30">    event.<span class="color-red">preventDefault</span>(); <span class="color-grey">// Prevent default submission</span></p>
                        <p class="ml-30">    <span class="color-blue">if</span> ($(<span class="color-green">"#username"</span>).<span class="color-red">val</span>() === <span class="color-green">""</span> || $(<span class="color-green">"#password"</span>).<span class="color-red">val</span>() === <span class="color-green">""</span>) {</p>
                        <p class="ml-60">        <span class="color-red">alert</span>(<span class="color-green">"All fields are required!"</span>);</p>
                        <p class="ml-30">    } <span class="color-blue">else</span> {</p>
                        <p class="ml-60">        <span class="color-red">alert</span>(<span class="color-green">"Form successfully validated!"</span>);</p>
                        <p class="ml-30">    }</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>It prevents the default form submission and validates input fields before proceeding.</li>
                </ul>




            </div>
        </section>
    )
}