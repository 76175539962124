import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/jquery/events/event-data";

export default function Data() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("jQuery event.data Object | Aspirant's Home");
        const urls = {
            'previous': '/jquery/events'
        }
        path.setPreviousNext(urls);
        path.setPreviousName('Back to Events');
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>jQuery event.data Object</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">event.data</span> object in jQuery is used to <strong>pass additional data</strong> to an event handler when attaching it to an element. It contains the custom data that was provided during the binding of the event, making it accessible inside the event handler.
                </p>

                <h5 className='mt-5 mb-3'>How It Works</h5>
                <p>
                    When you use <span class="background-grey">event.data</span>, the data is passed at the time of attaching the event handler using <span class="background-grey">.on()</span> or similar methods. This data becomes part of the <span class="background-grey">event</span> object, and you can access it using <span class="background-grey">event.data</span> within the event handler.
                </p>

                <p>It has a basic syntax -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(selector).<span class="color-red">on</span>(eventType, { <span class="color-pink">key</span>: value }, handlerFunction);</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">selector</span> is the target element(s).</li>
                    <li><span class="background-grey">eventType</span> is the type of event (e.g., <span class="background-grey">"click"</span>, <span class="background-grey">"hover"</span>).</li>
                    <li><span class="background-grey">&#123; key: value &#125;</span> is an object containing the custom data to pass.</li>
                    <li><span class="background-grey">handlerFunction</span> is the function to handle the event, where <span class="background-grey">event.data</span> is available.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Example : Passing Static Data</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;button <span class="color-pink">id</span>=<span class="color-green">"myButton"</span>&gt;Click Me&lt;/button&gt;</p>
                        `
                    }}></div>
                </div>
                <h6>jQuery Code</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"#myButton"</span>).<span class="color-red">on</span>(<span class="color-green">"click"</span>, { <span class="color-pink">message</span>: <span class="color-green">"Hello, World!"</span> }, <span class="color-blue">function</span>(event) {</p>
                        <p class="ml-30">    <span class="color-red">alert</span>(event.data.message); <span class="color-grey">// Displays: "Hello, World!"</span></p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span class="background-grey">message</span> data is passed at the time of event binding and accessed in the handler via <span class="background-grey">event.data</span>.</li>
                </ul>


            </div>
        </section>
    )
}