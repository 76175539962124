import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/events/onblur";

export default function Onblur() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - onblur Event | Aspirant's Home");
        const urls = {
            'previous': '/html/events'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - onblur Event</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">onblur</span> event in HTML is triggered when an element loses focus. It’s commonly used with form fields (like <span class="background-grey">&lt;input&gt;</span>, <span class="background-grey">&lt;textarea&gt;</span>, <span class="background-grey">&lt;select&gt;</span>, etc.) to validate input, hide help text, or perform an action after a user has finished interacting with the element.
                </p>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <p>To use <span class="background-grey">onblur</span>, add it as an attribute to an HTML element, with JavaScript code or a function to execute when the element loses focus.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">onblur</span>=<span class="color-green">"alert('Input field lost focus')"</span> <span class="color-pink">placeholder</span>=<span class="color-green">"Click here"</span>&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example, An alert appears when the input field loses focus.</p>


                <h5 className='mt-5 mb-3'>Example: Basic Input Validation with <span class="background-grey">onblur</span></h5>
                <p>You can use <span class="background-grey">onblur</span> to validate a form field as soon as it loses focus.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">id</span>=<span class="color-green">"username"</span> <span class="color-pink">onblur</span>=<span class="color-green">"validateUsername()"</span> <span class="color-pink">placeholder</span>=<span class="color-green">"Enter username"</span>&gt;</p>
                        <p>&lt;p <span class="color-pink">id</span>=<span class="color-green">"errorText"</span> <span class="color-pink">style</span>=<span class="color-green">"color:red; display:none;"</span>&gt;Username is required.&lt;/p&gt;</p>
                        <br />
                        <p>&lt;script&gt;</p>
                        <p class="ml-30">    <span class="color-blue">function</span> <span class="color-red">validateUsername</span>() {</p>
                        <p class="ml-60">        <span class="color-blue">const</span> username = document.<span class="color-red">getElementById</span>(<span class="color-green">"username"</span>).value;</p>
                        <p class="ml-60">        <span class="color-blue">const</span> errorText = document.<span class="color-red">getElementById</span>(<span class="color-green">"errorText"</span>);</p>
                        <p class="ml-60">        <span class="color-blue">if</span> (username === <span class="color-green">""</span>) {</p>
                        <p class="ml-90">            errorText.style.display = <span class="color-green">"block"</span>; <span class="color-grey">// Show error message</span></p>
                        <p class="ml-60">        } <span class="color-blue">else</span> {</p>
                        <p class="ml-90">            errorText.style.display = <span class="color-green">"none"</span>; <span class="color-grey">// Hide error message</span></p>
                        <p class="ml-60">        }</p>
                        <p class="ml-30">    }</p>
                        <p>&lt;/script&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span class="background-grey">validateUsername</span> function checks if the "username" field is empty when it loses focus.</li>
                    <li>If empty, it displays an error message; otherwise, the message remains hidden.</li>
                </ul>


            </div>
        </section>
    )
}