import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/tag/body";

export default function Body() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - <body> Tag | Aspirant's Home");
        const urls = {
            'previous': '/html/tag-list'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - &lt;body&gt; Tag</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">&lt;body&gt;</span> tag in HTML represents the main content of a web page that is visible to users in the browser. Everything within the <span class="background-grey">&lt;body&gt;</span> tag is rendered on the page, including text, images, links, forms, tables, and other media elements. It comes after the <span class="background-grey">&lt;head&gt;</span> tag in the HTML document structure and is required for every HTML page.
                </p>

                <h5 className='mt-5 mb-3'>Structure and Purpose of <span class="background-grey">&lt;body&gt;</span></h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span class="background-grey">&lt;body&gt;</span> contains everything a user sees and interacts with on the page.</li>
                    <li>The browser renders the content inside <span class="background-grey">&lt;body&gt;</span>, so it’s where all visual and interactive elements are placed.</li>
                    <li>While <span class="background-grey">&lt;head&gt;</span> holds metadata and links to resources, <span class="background-grey">&lt;body&gt;</span> holds the actual page content.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <p>The <span class="background-grey">&lt;body&gt;</span> tag wraps all the visible content and interactive elements on the web page:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;body&gt;</p>
                        <p class="ml-30">    &lt;!-- Visible content goes here --&gt;</p>
                        <p>&lt;/body&gt;</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Example Structure of an HTML Document with <span class="background-grey">&lt;body&gt;</span></h5>
                <p>Here’s a complete HTML document showing how the <span class="background-grey">&lt;body&gt;</span> fits with other tags:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</spam><span class="color-grey">&gt;</span></p>
                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                        <p class="ml-60">        &lt;title&gt;My Web Page&lt;/title&gt;</p>
                        <p class="ml-60">        &lt;link <span class="color-pink">rel</span>=<span class="color-green">"stylesheet"</span> <span class="color-pink">href</span>=<span class="color-green">"styles.css"</span>&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <br />    
                        <p class="ml-60">        &lt;h1&gt;Welcome to My Website&lt;/h1&gt;</p>
                        <p class="ml-60">        &lt;p&gt;This is a paragraph explaining the purpose of the website.&lt;/p&gt;</p>
                        <p class="ml-60">        &lt;img <span class="color-pink">src</span>=<span class="color-green">"image.jpg"</span> <span class="color-pink">alt</span>=<span class="color-green">"Sample image"</span>&gt;</p>
                        <p class="ml-60">        &lt;a <span class="color-pink">href</span>=<span class="color-green">"https://example.com"</span>&gt;Visit Example&lt;/a&gt;</p>
                        <br />    
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span class="background-grey">&lt;head&gt;</span> section includes metadata and links, like the <span class="background-grey">&lt;title&gt;</span> and CSS file link.</li>
                    <li>The <span class="background-grey">&lt;body&gt;</span> section contains the page’s actual content: a heading, paragraph, image, and link.</li>
                </ul>

            </div>
        </section>
    )
}