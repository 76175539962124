import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/tag/headings";

export default function Headings() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML Headings - <h1> to <h6> Tag | Aspirant's Home");
        const urls = {
            'previous': '/html/tag-list'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML Headings - &lt;h1&gt; to &lt;h6&gt; Tag</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">&lt;h1&gt;</span> to <span class="background-grey">&lt;h6&gt;</span> tags in HTML represent heading elements, used to structure and organize content on a web page. These tags are essential for content hierarchy, as they communicate the importance of different sections. Search engines and screen readers use headings to understand the structure and hierarchy of content, making them crucial for accessibility and SEO.
                </p>

                <h5 className='mt-5 mb-3'>Structure and Purpose of Heading Tags</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">&lt;h1&gt;</span> is the most important heading, while <span class="background-grey">&lt;h6&gt;</span> is the least.</li>
                    <li>Headings create a logical flow, with <span class="background-grey">&lt;h1&gt;</span> typically used for main titles and <span class="background-grey">&lt;h2&gt;</span> through <span class="background-grey">&lt;h6&gt;</span> for subheadings.</li>
                    <li>Headings improve search engine indexing and help screen readers navigate content.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <p>Each heading tag is written as follows:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;h1&gt;Main Heading (Highest Level)&lt;/h1&gt;</p>
                        <p>&lt;h2&gt;Subheading (Second Level)&lt;/h2&gt;</p>
                        <p>&lt;h3&gt;Sub-subheading (Third Level)&lt;/h3&gt;</p>
                        <p class="color-grey">&lt;!-- Continue with &lt;h4&gt;, &lt;h5&gt;, and &lt;h6&gt; as needed --&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Output will be the following -</p>
                <div className='outputPalateBox mt-2 mb-4'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                            <h1>Main Heading (Highest Level)</h1>
                            <h2>Subheading (Second Level)</h2>
                            <h3>Sub-subheading (Third Level)</h3>
                            <!-- Continue with <h4>, <h5>, and <h6> as needed -->
                        `
                    }}></div>
                </div>

                <p>
                    The <span class="background-grey">&lt;h1&gt;</span> to <span class="background-grey">&lt;h6&gt;</span> tags define headings and create a clear content hierarchy on a webpage. Starting with <span class="background-grey">&lt;h1&gt;</span> as the most important and descending in importance, these tags are essential for accessibility, SEO, and content readability. Proper use of headings with logical nesting helps organize content effectively, and they can be styled with CSS to fit any design style.
                </p>


            </div>
        </section>
    )
}