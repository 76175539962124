import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/tag/u";

export default function UTag() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - <u> Tag | Aspirant's Home");
        const urls = {
            'previous': '/html/tag-list'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - &lt;u&gt; Tag</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">&lt;u&gt;</span> tag in HTML is used to underline text. This tag is generally used for stylistic purposes, such as underlining names, special terms, or other non-linked text. However, underlined text can sometimes be confused with hyperlinks, so it’s often best used sparingly or in contexts where the meaning is clear.
                </p>

                <h5 className='mt-5 mb-3'>Structure and Purpose of <span class="background-grey">&lt;u&gt;</span> Tag</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>It adds a simple underline style to text.</li>
                    <li>Unlike tags like <span class="background-grey">&lt;strong&gt;</span> or <span class="background-grey">&lt;em&gt;</span>, it doesn’t carry any additional meaning or emphasis.</li>
                    <li>Commonly used for stylistic reasons, like emphasizing names or technical terms that require distinct visual treatment.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <p>The <span class="background-grey">&lt;u&gt;</span> tag wraps around the text to be underlined:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;u&gt;Your underlined text here&lt;/u&gt;</p>
                        `
                    }}></div>
                </div>  


                <h5 className='mt-5 mb-3'>Example Usage of <span class="background-grey">&lt;u&gt;</span> Tag</h5>
                <p>Here’s an example of the <span class="background-grey">&lt;u&gt;</span> tag used to underline a name within a sentence:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Underline Text Example&lt;/title&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <br />    
                        <p class="ml-60">        &lt;p&gt;Welcome, &lt;u&gt;John Doe&lt;/u&gt;, to our event!&lt;/p&gt;</p>
                        <br />    
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example, the <span class="background-grey">&lt;u&gt;</span> tag underlines "John Doe" for a subtle emphasis on the name.</p>
                <p>Output will be the following -</p>
                <div className='outputPalateBox mt-2 mb-4'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <!DOCTYPE html>
                        <html lang="en">
                            <head>
                                <meta charset="UTF-8">
                                <title>Underline Text Example</title>
                            </head>
                            <body>
                            
                                <p>Welcome, <u>John Doe</u>, to our event!</p>
                            
                            </body>
                        </html>
                        `
                    }}></div>
                </div>

                <p>The <span class="background-grey">&lt;u&gt;</span> tag is used for underlining text, primarily for visual emphasis without semantic meaning. It’s best for non-link text, such as names or technical terms, and can be styled with CSS to customize the underline’s appearance. However, avoid overusing it for general text since it can be confused with hyperlinks.</p>


            </div>
        </section>
    )
}