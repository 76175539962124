import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Outlet, Link } from "react-router-dom";
export const pageUrl = () => "/angular/navigating-between-routes";

export default function NavigatingRoutes() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Navigating Between Routes in Angular | Aspirant's Home");
        const urls = {
            'previous': '/angular/setting-up-routing',
            'next': '/angular/route-guards'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Navigating Routes</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In Angular, <strong>navigating between routes</strong> allows users to switch between different views or components in the application, often based on a URL path. Angular uses the <strong>Angular Router</strong> to manage navigation.
                </p>

                <h5 className='mt-5 mb-3'>Navigating Between Routes</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li className='mt-4'>
                        <strong>Using Router Links (Template-Based Navigation)</strong>
                        <p>Angular provides the <span class="background-grey">routerLink</span> directive for template-based navigation.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>&lt;nav&gt;</p>
                                <p class="ml-30">    &lt;a <span class="color-pink">routerLink</span>=<span class="color-green">"/"</span>&gt;Home&lt;/a&gt;</p>
                                <p class="ml-30">    &lt;a <span class="color-pink">routerLink</span>=<span class="color-green">"/about"</span>&gt;About&lt;/a&gt;</p>
                                <p>&lt;/nav&gt;</p>
                                `
                            }}></div>
                        </div>
                        <p>Here,</p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>Clicking these links changes the URL and loads the respective component.</li>
                            <li><strong>Active Link Styling</strong>: Use the <span class="background-grey">routerLinkActive</span> directive to style active links.</li>
                        </ul>
                        <div className='codePalateBox mt-4 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>&lt;a <span class="color-pink">routerLink</span>=<span class="color-green">"/"</span> <span class="color-pink">routerLinkActive</span>=<span class="color-green">"active"</span>&gt;Home&lt;/a&gt;</p>
                                <p>&lt;a <span class="color-pink">routerLink</span>=<span class="color-green">"/about"</span> <span class="color-pink">routerLinkActive</span>=<span class="color-green">"active"</span>&gt;About&lt;/a&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='mt-4'>
                        <strong>Programmatic Navigation</strong>
                        <p>You can navigate between routes programmatically using the <span class="background-grey">Router</span> service.</p>
                        <div className='codePalateBox mt-4 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">import</span> { <span class="color-blue">Component</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                                    <p><span class="color-pink">import</span> { <span class="color-blue">Router</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/router'</span>;</p>
                                    <br />
                                    <p><span class="color-red">@Component</span>({</p>
                                    <p class="ml-30">    <span class="color-pink">selector</span>: <span class="color-green">'app-home'</span>,</p>
                                    <p class="ml-30">    <span class="color-pink">template</span>: <span class="color-green">&#96;</span></p>
                                    <p class="ml-60 color-green">        &lt;button (click)="goToAbout()"&gt;Go to About&lt;/button&gt;</p>
                                    <p class="ml-30 color-green">    &#96;,</p>
                                    <p>})</p>
                                    <p><span class="color-pink">export class</span> <span class="color-blue">HomeComponent</span> {</p>
                                    <p class="ml-30">    <span class="color-red">constructor</span>(<span class="color-pink">private</span> router: <span class="color-blue">Router</span>) {}</p>
                                    <br />    
                                    <p class="ml-30">    <span class="color-red">goToAbout</span>() {</p>
                                    <p class="ml-60">        <span class="color-blue">this</span>.router.<span class="color-red">navigate</span>([<span class="color-green">'/about'</span>]); <span class="color-grey">// Navigate to the 'about' route</span><p>
                                    <p class="ml-30">    }</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                        <p>Here, <span class="background-grey">navigate</span> takes an array of route segments to navigate to.</p>
                    </li>
                    <li className='mt-4'>
                        <strong>Navigating with Parameters</strong>
                        <p className='mt-4'>Routes can include parameters to pass dynamic values.</p>

                        <h6 className='mt-3'>Define Routes with Parameters:</h6>
                        <div className='codePalateBox mt-4 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">const</span> routes: <span class="color-pink">Routes</span> = [</p>
                                <p class="ml-30">    { <span class="color-pink">path</span>: <span class="color-green">'profile/:id'</span>, <span class="color-pink">component</span>: <span class="color-blue">ProfileComponent</span> }, <span class="color-grey">// Route with parameter</span></p>
                                <p>];</p>
                                `
                            }}></div>
                        </div>

                        <h6 className='mt-3'>Access Parameters in the Component:</h6>
                        <div className='codePalateBox mt-4 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">import</span> { <span class="color-blue">Component</span>, <span class="color-blue">OnInit</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                                <p><span class="color-pink">import</span> { <span class="color-blue">ActivatedRoute</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/router'</span>;</p>
                                <br />
                                <p><span class="color-red">@Component</span>({</p>
                                <p class="ml-30">    <span class="color-pink">selector</span>: <span class="color-green">'app-profile'</span>,</p>
                                <p class="ml-30">    <span class="color-pink">template</span>: <span class="color-green">&#96;&lt;h1&gt;Profile ID: {{ id }}&lt;/h1&gt;&#96;</span>,</p>
                                <p>})</p>
                                <p><span class="color-pink">export class</span> <span class="color-blue">ProfileComponent</span> <span class="color-pink">implements</span> <span class="color-blue">OnInit</span> {</p>
                                <p class="ml-30">    id: <span class="color-blue">string</span> | <span class="color-blue">null</span> = <span class="color-blue">null</span>;</p>
                                <br />
                                <p class="ml-30">    <span class="color-red">constructor</span>(<span class="color-blue">private</span> route: <span class="color-blue">ActivatedRoute</span>) {}</p>
                                <br />
                                <p class="ml-30">    <span class="color-red">ngOnInit</span>() {</p>
                                <p class="ml-60">        <span class="color-blue">this</span>.id = <span class="color-blue">this</span>.route.snapshot.paramMap.<span class="color-red">get</span>(<span class="color-green">'id'</span>); <span class="color-grey">// Get the 'id' parameter</span></p>
                                <p class="ml-30">    }</p>
                                <p>}</p>
                                `
                            }}></div>
                        </div>

                        <h6 className='mt-3'>Navigate to Routes with Parameters:</h6>
                        <div className='codePalateBox mt-4 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;a [<span class="color-pink">routerLink</span>]="[<span class="color-green">'/profile'</span>, <span class="color-pink">123</span>]"&gt;Go to Profile 123&lt;/a&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='mt-4'>
                        <strong>Handling Query Parameters</strong>
                        <p className='mt-4'>Query parameters are used for optional parameters.</p>

                        <h6 className='mt-3'>Navigate with Query Parameters:</h6>
                        <div className='codePalateBox mt-4 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">this</span>.router.<span class="color-red">navigate</span>([<span class="color-green">'/about'</span>], { queryParams: { ref: <span class="color-green">'user123'</span> } });</p>
                                `
                            }}></div>
                        </div>

                        <h6 className='mt-3'>Navigate with Query Parameters:</h6>
                        <div className='codePalateBox mt-4 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">import</span> { <span class="color-blue">Component</span>, <span class="color-blue">OnInit</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                                <p><span class="color-pink">import</span> { <span class="color-blue">ActivatedRoute</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/router'</span>;</p>
                                <br />
                                <p><span class="color-red">@Component</span>({</p>
                                <p class="ml-30">    <span class="color-pink">selector</span>: <span class="color-green">'app-about'</span>,</p>
                                <p class="ml-30">    <span class="color-pink">template</span>: <span class="color-green">&#96;&lt;p&gt;Query Param: {{ ref }}&lt;/p&gt;&#96;</span>,</p>
                                <p>})</p>
                                <p><span class="color-pink">export class</span> <span class="color-blue">AboutComponent</span> <span class="color-pink">implements</span> <span class="color-blue">OnInit</span> {</p>
                                <p class="ml-30">    ref: <span class="color-blue">string</span> | <span class="color-blue">null</span> = <span class="color-blue">null</span>;</p>
                                <p class="ml-30">    <span class="color-red">constructor</span>(<span class="color-pink">private</span> route: <span class="color-blue">ActivatedRoute</span>) {}</p>
                                <p class="ml-30">    <span class="color-red">ngOnInit</span>() {</p>
                                <p class="ml-60">        <span class="color-blue">this</span>.route.queryParamMap.<span class="color-red">subscribe</span>((params) => {</p>
                                <p class="ml-90">            <span class="color-blue">this</span>.ref = params.<span class="color-red">get</span>(<span class="color-green">'ref'</span>);</p>
                                <p class="ml-60">        });</p>
                                <p class="ml-30">    }</p>
                                <p>}</p>
                                `
                            }}></div>
                        </div>

                    </li>
                </ul>

                <p>
                    Let's explain how query parameter works -
                </p>
                <p>Suppose the URL is -</p>
                <div className='codePalateBox mt-4 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>http://example.com/about?ref=user123</p>
                        `
                    }}></div>
                </div>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span class="background-grey">ActivatedRoute</span> detects the <span class="background-grey">ref</span> query parameter from the URL (<span class="background-grey">?ref=user123</span>).</li>
                    <li>The value of <span class="background-grey">ref</span> (<span class="background-grey">user123</span>) is extracted using <span class="background-grey">params.get('ref')</span> and assigned to the <span class="background-grey">ref</span> property.</li>
                    <li>The template displays:
                        <div className='codePalateBox mt-4 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>Query Param: user123</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>
                <p><strong>If the URL changes dynamically to:</strong></p>
                <div className='codePalateBox mt-4 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>http://example.com/about?ref=admin456</p>
                        `
                    }}></div>
                </div>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span class="background-grey">queryParamMap</span> Observable detects the change.</li>
                    <li>The subscribe method updates the <span class="background-grey">ref</span> variable to <span class="background-grey">admin456</span>.</li>
                    <li>The template updates automatically to:
                        <div className='codePalateBox mt-4 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;p&gt;Query Param: admin456&lt;/p&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>

            </div>
        </section>
    )
}