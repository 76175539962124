import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/jquery/load";

export default function Load() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("jQuery - AJAX load() Method | Aspirant's Home");
        const urls = {
            'previous': '/jquery/ajax',
            'next': '/jquery/get-post'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>jQuery - AJAX load() Method</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">.load()</span> method in jQuery is an AJAX function that allows you to load content from a server and insert it directly into a selected HTML element. This method is very useful for updating parts of a web page without reloading the whole page.
                </p>
                <p>It has a basic syntax -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(selector).<span class="color-red">load</span>(url, [data], [callback]);</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">selector</span> specifies the HTML element where the content should be loaded.</li>
                    <li><span class="background-grey">url</span> is the URL from which the content will be loaded.</li>
                    <li><span class="background-grey">data</span> (optional) is an object or string that sends additional data with the request (often used for sending query parameters).</li>
                    <li><span class="background-grey">callback</span> (optional) is a function that executes after the content has been loaded successfully.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Example 1: Basic Usage</h5>
                <p>Suppose you have a button, and when clicked, it loads content from another file into a <span class="background-grey">&lt;div&gt;</span> on the page.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;button <span class="color-pink">id</span>=<span class="color-green">"loadButton"</span>&gt;Load Content&lt;/button&gt;</p>
                        <p>&lt;div <span class="color-pink">id</span>=<span class="color-green">"contentArea"</span>&gt;&lt;/div&gt;</p>
                        `
                    }}></div>
                </div>
                <h6>jQuery Code</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"#loadButton"</span>).<span class="color-red">click</span>(<span class="color-blue">function</span>() {</p>
                        <p class="ml-30">    $(<span class="color-green">"#contentArea"</span>).<span class="color-red">load</span>(<span class="color-green">"example.html"</span>);</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example, when the button is clicked, the <span class="background-grey">.load()</span> method retrieves the content from "example.html" and places it inside the <span class="background-grey">&lt;div&gt;</span> with the <span class="background-grey">id="contentArea"</span>.</p>


                <h5 className='mt-5 mb-3'>Example 2: Loading Specific Part of a Page</h5>
                <p>You can load a specific part of a page by adding a space and selector to the URL.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(<span class="color-green">"#contentArea"</span>).<span class="color-red">load</span>(<span class="color-green">"example.html #section"</span>);</p>
                        `
                    }}></div>
                </div>
                <p>This example loads only the part of "example.html" that has the <span class="background-grey">id="section"</span> into the <span class="background-grey">#contentArea</span> div.</p>


                <h5 className='mt-5 mb-3'>Example 3: Passing Data with <span class="background-grey">.load()</span></h5>
                <p>You can pass data to the server when making the request. This can be useful for sending query parameters, such as filters or search criteria.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(<span class="color-green">"#contentArea"</span>).<span class="color-red">load</span>(<span class="color-green">"search.php"</span>, { searchTerm: <span class="color-green">"jQuery"</span>, category: <span class="color-green">"tutorial"</span> });</p>
                        `
                    }}></div>
                </div>
                <p>Here,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span class="background-grey">.load()</span> method sends <span class="background-grey">searchTerm</span> and <span class="background-grey">category</span> parameters to "search.php".</li>
                    <li>"search.php" can then use these parameters to return filtered results, which will be loaded into <span class="background-grey">#contentArea</span>.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Example 4: Using a Callback Function</h5>
                <p>You can use a callback function that runs after the content is loaded. This is helpful if you want to perform further actions once the content is loaded.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"#contentArea"</span>).<span class="color-red">load</span>(<span class="color-green">"example.html"</span>, <span class="color-blue">function</span>(response, status, xhr) {</p>
                        <p class="ml-30">    <span class="color-blue">if</span> (status == <span class="color-green">"success"</span>) {</p>
                        <p class="ml-60">        <span class="color-red">alert</span>(<span class="color-green">"Content loaded successfully!"</span>);</p>
                        <p class="ml-30">    } <span class="color-blue">else if</span> (status == <span class="color-green">"error"</span>) {</p>
                        <p class="ml-60">        <span class="color-red">alert</span>(<span class="color-green">"Error: "</span> + xhr.status + <span class="color-green">" "</span> + xhr.statusText);</p>
                        <p class="ml-30">    }</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>Here,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The callback function checks the status of the request.</li>
                    <li>If the content loads successfully, an alert is shown.</li>
                    <li>If there's an error, an error message with the status code and message is displayed.</li>
                </ul>


            </div>
        </section>
    )
}