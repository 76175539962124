import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/angular/form-validation";

export default function FormValidation() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Form Validation in Angular | Aspirant's Home");
        const urls = {
            'previous': '/angular/reactive-forms',
            'next': '/angular/handling-form-submissions'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Form Validation</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Form validation in Angular ensures that user inputs meet the desired criteria before processing or saving data. Angular provides built-in validation for both <strong>Template-Driven Forms</strong> and <strong>Reactive Forms</strong>. Additionally, you can create custom validations for more complex scenarios.
                </p>

                <h5 className='mt-5 mb-3'>Types of Validation in Angular</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li className='mt-3'>
                        <strong>Built-In Validation</strong> Angular supports common validation rules like:
                        <ul style={{ listStyle: 'disc' }}>
                            <li><span class="background-grey">required</span>: Ensures the field is not empty.</li>
                            <li><span class="background-grey">minlength</span> and <span class="background-grey">maxlength</span>: Restricts the length of input.</li>
                            <li><span class="background-grey">pattern</span>: Validates input against a regular expression.</li>
                            <li><span class="background-grey">email</span>: Validates the input as a valid email format.</li>
                        </ul>
                    </li>
                    <li className='mt-3'>
                        <strong>Custom Validation</strong>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>Define validation logic in the component or as a directive.</li>
                            <li>Use for complex or application-specific rules.</li>
                        </ul>
                    </li>
                </ul>


                <h5 className='mt-5 mb-3'>Form Validation in Template-Driven Forms</h5>
                <p>In template-driven forms, validation is achieved using Angular's built-in directives directly in the HTML template.</p>


                <h6 className='mt-4'>Example: Login Form with Validation</h6>
                <h6 className='mt-4'>Template</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;form #<span class="color-pink">loginForm</span>=<span class="color-green">"ngForm"</span> (<span class="color-pink">ngSubmit</span>)=<span class="color-green">"onSubmit(loginForm)"</span>&gt;</p>
                        <p class="ml-30">    &lt;div&gt;</p>
                        <p class="ml-60">        &lt;label <span class="color-pink">for</span>=<span class="color-green">"email"</span>&gt;Email:&lt;/label&gt;</p>
                        <p class="ml-60">        &lt;input <span class="color-pink">type</span>=<span class="color-green">"email"</span> <span class="color-pink">id</span>=<span class="color-green">"email"</span> <span class="color-pink">name</span>=<span class="color-green">"email"</span> [<span class="color-pink">(ngModel)</span>]=<span class="color-green">"user.email"</span> <span class="color-pink">required</span>&gt;</p>
                        <p class="ml-60">        &lt;div *<span class="color-pink">ngIf</span>=<span class="color-green">"loginForm.submitted && loginForm.controls.email?.invalid"</span>&gt;</p>
                        <p class="ml-90">            &lt;small *<span class="color-pink">ngIf</span>=<span class="color-green">"loginForm.controls.email?.errors?.required"</span>&gt;Email is required.&lt;/small&gt;</p>
                        <p class="ml-90">            &lt;small *<span class="color-pink">ngIf</span>=<span class="color-green">"loginForm.controls.email?.errors?.email"</span>&gt;Invalid email format.&lt;/small&gt;</p>
                        <p class="ml-60">        &lt;/div&gt;</p>
                        <p class="ml-30">    &lt;/div&gt;</p>
                        <br />
                        <p class="ml-30">    &lt;div&gt;</p>
                        <p class="ml-60">        &lt;label <span class="color-pink">for</span>=<span class="color-green">"password"</span>&gt;Password:&lt;/label&gt;</p>
                        <p class="ml-60">        &lt;input <span class="color-pink">type</span>=<span class="color-green">"password"</span> <span class="color-pink">id</span>=<span class="color-green">"password"</span> <span class="color-pink">name</span>=<span class="color-green">"password"</span> [<span class="color-pink">(ngModel)</span>]=<span class="color-green">"user.password"</span> <span class="color-pink">minlength</span>=<span class="color-green">"6"</span> <span class="color-pink">required</span>&gt;</p>
                        <p class="ml-60">        &lt;div *<span class="color-pink">ngIf</span>=<span class="color-green">"loginForm.submitted && loginForm.controls.password?.invalid"</span>&gt;</p>
                        <p class="ml-90">            &lt;small *<span class="color-pink">ngIf</span>=<span class="color-green">"loginForm.controls.password?.errors?.required"</span>&gt;Password is required.&lt;/small&gt;</p>
                        <p class="ml-90">            &lt;small *<span class="color-pink">ngIf</span>=<span class="color-green">"loginForm.controls.password?.errors?.minlength"</span>&gt;Minimum 6 characters required.&lt;/small&gt;</p>
                        <p class="ml-60">        &lt;/div&gt;</p>
                        <p class="ml-30">    &lt;/div&gt;</p>
                        <br />
                        <p class="ml-30">    &lt;button <span class="color-pink">type</span>=<span class="color-green">"submit"</span> [<span class="color-pink">disabled</span>]=<span class="color-green">"loginForm.invalid"</span>&gt;Login&lt;/button&gt;</p>
                        <p>&lt;/form&gt;</p>
                        `
                    }}></div>
                </div>


                <h6 className='mt-4'>Component</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">import</span> { Component } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                        <br />
                        <p><span class="color-red">@Component</span>({</p>
                        <p class="ml-30">  <span class="color-pink">selector</span>: <span class="color-green">'app-login'</span>,</p>
                        <p class="ml-30">  <span class="color-pink">templateUrl</span>: <span class="color-green">'./login.component.html'</span>,</p>
                        <p>})</p>
                        <p><span class="color-pink">export class</span> <span class="color-blue">LoginComponent</span> {</p>
                        <p class="ml-30">  user = { <span class="color-pink">email</span>: <span class="color-green">''</span>, <span class="color-green">password</span>: <span class="color-green">''</span> };</p>
                        <br />
                        <p class="ml-30">  <span class="color-red">onSubmit</span>(form: <span class="color-blue">any</span>) {</p>
                        <p class="ml-60">       <span class="color-blue">if</span> (form.valid) {</p>
                        <p class="ml-90">           <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">'Form Data:'</span>, form.value);</p>
                        <p class="ml-60">       } <span class="color-blue">else</span> {</p>
                        <p class="ml-90">           <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">'Form Invalid'</span>);</p>
                        <p class="ml-60">       }</p>
                        <p class="ml-30">  }</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>


                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>
                        <span class="background-grey">loginForm.controls.email?.errors?.required</span> this expression checks:
                        <ul style={{ listStyle: 'disc' }}>
                            <li>If the <span class="background-grey">email</span> control exists (<span class="background-grey">loginForm.controls.email</span>).</li>
                            <li>If the <span class="background-grey">email</span> control has an errors object (<span class="background-grey">loginForm.controls.email?.errors</span>).</li>
                            <li>If the <span class="background-grey">required</span> error exists in the <span class="background-grey">errors</span> object (<span class="background-grey">loginForm.controls.email?.errors?.required</span>).</li>
                        </ul>
                        <p>If all these conditions are true:</p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>The field has failed the <strong>required</strong> validation (meaning it’s empty).</li>
                            <li>The expression evaluates to <span class="background-grey">true</span>, and any associated message (like a validation error message) is displayed.</li>
                        </ul>
                        <p>If any of the conditions are false:</p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>The expression evaluates to <span class="background-grey">undefined</span> or <span class="background-grey">false</span>, and no action is taken.</li>
                        </ul>
                    </li>
                    <li>
                        <span class="background-grey">loginForm.controls.password?.errors?.required</span> this expression checks:
                        Similar to the email validation, the block displays error messages only if the <span class="background-grey">password</span> field is invalid after form submission.
                        <strong>Error Messages:</strong>
                        <ul style={{ listStyle: 'disc' }}>
                            <li><span class="background-grey">required</span>: Shown when the field is empty.</li>
                            <li><span class="background-grey">minlength</span>: Shown when the input has fewer than six characters.</li>
                        </ul>
                    </li>
                </ul>


                <h5 className='mt-5 mb-3'>Form Validation in Reactive Forms</h5>
                <p>In reactive forms, validation is defined programmatically in the component using Angular's <span class="background-grey">FormBuilder</span> and <span class="background-grey">Validators</span>.</p>

                <h6 className='mt-4'>Example: Registration Form with Validation</h6>
                <h6 className='mt-4'>Template</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;form [<span class="color-pink">formGroup</span>]=<span class="color-green">"registrationForm"</span> (<span class="color-pink">ngSubmit</span>)=<span class="color-green">"onSubmit()"</span>&gt;</p>
                        <p class="ml-30">    &lt;div&gt;</p>
                        <p class="ml-60">        &lt;label <span class="color-pink">for</span>=<span class="color-green">"username"</span>&gt;Username:&lt;/label&gt;</p>
                        <p class="ml-60">        &lt;input <span class="color-pink">id</span>=<span class="color-green">"username"</span> <span class="color-green">formControlName</span>=<span class="color-green">"username"</span>&gt;</p>
                        <p class="ml-60">        &lt;div *<span class="color-pink">ngIf</span>=<span class="color-green">"username?.touched && username?.invalid"</span>&gt;</p>
                        <p class="ml-90">            &lt;small *<span class="color-pink">ngIf</span>=<span class="color-green">"username?.errors?.required"</span>&gt;Username is required.&lt;/small&gt;</p>
                        <p class="ml-90">            &lt;small *<span class="color-pink">ngIf</span>=<span class="color-green">"username?.errors?.minlength"</span>&gt;Minimum 3 characters required.&lt;/small&gt;</p>
                        <p class="ml-60">        &lt;/div&gt;</p>
                        <p class="ml-30">    &lt;/div&gt;</p>
                        <br />
                        <p class="ml-30">    &lt;div&gt;</p>
                        <p class="ml-60">        &lt;label <span class="color-pink">for</span>=<span class="color-green">"email"</span>&gt;Email:&lt;/label&gt;</p>
                        <p class="ml-60">        &lt;input <span class="color-pink">id</span>=<span class="color-green">"email"</span> <span class="color-pink">formControlName</span>=<span class="color-green">"email"</span>&gt;</p> 
                        <p class="ml-60">        &lt;div *<span class="color-pink">ngIf</span>=<span class="color-green">"email?.touched && email?.invalid"</span>&gt;</p>
                        <p class="ml-90">            &lt;small *<span class="color-pink">ngIf</span>=<span class="color-green">"email?.errors?.required"</span>&gt;Email is required.&lt;/small&gt;</p>
                        <p class="ml-90">            &lt;small *<span class="color-pink">ngIf</span>=<span class="color-green">"email?.errors?.email"</span>&gt;Invalid email format.&lt;/small&gt;</p>
                        <p class="ml-60">        &lt;/div&gt;</p>
                        <p class="ml-30">    &lt;/div&gt;</p>
                        <br />
                        <p class="ml-30">    &lt;div&gt;</p>
                        <p class="ml-60">        &lt;label <span class="color-pink">for</span>=<span class="color-green">"password"</span>&gt;Password:&lt;/label&gt;</p>
                        <p class="ml-60">        &lt;input <span class="color-pink">id</span>=<span class="color-green">"password"</span> <span class="color-pink">type</span>=<span class="color-green">"password"</span> <span class="color-pink">formControlName</span>=<span class="color-green">"password"</span>&lt;</p>
                        <p class="ml-60">        &lt;div *<span class="color-pink">ngIf</span>=<span class="color-green">"password?.touched && password?.invalid"</span>&gt;</p>
                        <p class="ml-90">            &lt;small *<span class="color-pink">ngIf</span>=<span class="color-green">"password?.errors?.required"</span>&gt;Password is required.&lt;/small&gt;</p>
                        <p class="ml-90">            &lt;small *<span class="color-pink">ngIf</span>=<span class="color-green">"password?.errors?.minlength"</span>&gt;Minimum 6 characters required.&lt;/small&gt;</p>
                        <p class="ml-60">        &lt;/div&gt;</p>
                        <p class="ml-30">    &lt;/div&gt;</p>
                        <br />
                        <p class="ml-30">    &lt;button <span class="color-pink">type</span>=<span class="color-green">"submit"</span> [<span class="color-pink">disabled</span>]=<span class="color-green">"registrationForm.invalid"</span>&gt;Register&lt;/button&gt;</p>
                        <p>&lt;/form&gt;</p>
                        `
                    }}></div>
                </div>

                <h6 className='mt-4'>Component</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">import</span> { Component } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                        <p><span class="color-pink">import</span> { FormBuilder, FormGroup, Validators } <span class="color-pink">from</span> <span class="color-green">'@angular/forms'</span>;</p>
                        <br />
                        <p><span class="color-red">@Component</span>({</p>
                        <p class="ml-30">  <span class="color-pink">selector</span>: <span class="color-green">'app-registration'</span>,</p>
                        <p class="ml-30">  <span class="color-pink">templateUrl</span>: <span class="color-green">'./registration.component.html'</span>,</p>
                        <p>})</p>
                        <p><span class="color-pink">export class</span> <span class="color-blue">RegistrationComponent</span> {</p>
                        <p class="ml-30">  registrationForm: <span class="color-blue">FormGroup</span>;</p>
                        <br />
                        <p class="ml-30">  <span class="color-red">constructor</span>(private fb: <span class="color-blue">FormBuilder</span>) {</p>
                        <p class="ml-60">       <span class="color-blue">this</span>.registrationForm = <span class="color-blue">this</span>.fb.<span class="color-red">group</span>({</p>
                        <p class="ml-90">           <span class="color-pink">username</span>: [<span class="color-green">''</span>, [Validators.required, Validators.<span class="color-red">minLength</span>(<span class="color-pink">3</span>)]],</p>
                        <p class="ml-90">           <span class="color-pink">email</span>: [<span class="color-green">''</span>, [Validators.required, Validators.email]],</p>
                        <p class="ml-90">           <span class="color-pink">password</span>: [<span class="color-green">''</span>, [Validators.required, Validators.<span class="color-red">minLength</span>(<span class="color-pink">6</span>)]],</p>
                        <p class="ml-60">       });</p>
                        <p class="ml-30">  }</p>
                        <br />
                        <p class="ml-30">  <span class="color-blue">get</span> <span class="color-red">username</span>() {</p>
                        <p class="ml-60">       <span class="color-blue">return this</span>.registrationForm.<span class="color-red">get</span>(<span class="color-green">'username'</span>);</p>
                        <p class="ml-30">  }</p>
                        <br />
                        <p class="ml-30">  <span class="color-blue">get</span> <span class="color-red">email</span>() {</p>
                        <p class="ml-60">       <span class="color-blue">return this</span>.registrationForm.<span class="color-red">get</span>(<span class="color-green">'email'</span>);</p>
                        <p class="ml-30">  }</p>
                        <br />
                        <p class="ml-30">  <span class="color-blue">get</span> <span class="color-red">password</span>() {</p>
                        <p class="ml-60">       <span class="color-blue">return this</span>.registrationForm.<span class="color-red">get</span>(<span class="color-green">'password'</span>);</p>
                        <p class="ml-30">  }</p>
                        <br />
                        <p class="ml-30">  <span class="color-red">onSubmit</span>() {</p>
                        <p class="ml-60">       <span class="color-blue">if</span> (<span class="color-blue">this</span>.registrationForm.valid) {</p>
                        <p class="ml-90">            <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">'Form Data:'</span>, <span class="color-blue">this</span>.registrationForm.value);</p>
                        <p class="ml-60">       } <span class="color-blue">else</span> {</p>
                        <p class="ml-90">             <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">'Form Invalid'</span>);</p>
                        <p class="ml-60">       }</p>
                        <p class="ml-30">  }</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>

                <p>In the above example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>A <span class="background-grey">FormGroup</span> object representing the entire form.</li>
                    <li>Contains form controls like <span class="background-grey">username</span>, <span class="background-grey">email</span>, and <span class="background-grey">password</span>.</li>
                </ul>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p class="ml-30">  <span class="color-red">constructor</span>(private fb: <span class="color-blue">FormBuilder</span>) {</p>
                        <p class="ml-60">       <span class="color-blue">this</span>.registrationForm = <span class="color-blue">this</span>.fb.<span class="color-red">group</span>({</p>
                        <p class="ml-90">           <span class="color-pink">username</span>: [<span class="color-green">''</span>, [Validators.required, Validators.<span class="color-red">minLength</span>(<span class="color-pink">3</span>)]],</p>
                        <p class="ml-90">           <span class="color-pink">email</span>: [<span class="color-green">''</span>, [Validators.required, Validators.email]],</p>
                        <p class="ml-90">           <span class="color-pink">password</span>: [<span class="color-green">''</span>, [Validators.required, Validators.<span class="color-red">minLength</span>(<span class="color-pink">6</span>)]],</p>
                        <p class="ml-60">       });</p>
                        <p class="ml-30">  }</p>
                        `
                    }}></div>
                </div>
                <p>In the above example, The <span class="background-grey">FormBuilder</span> service is injected into the component via the <span class="background-grey">constructor</span>. This service is used to simplify form creation.</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>
                        <span class="background-grey">fb.group()</span>: Creates a form group with three controls (<span class="background-grey">username</span>, <span class="background-grey">email</span>, <span class="background-grey">password</span>).
                    </li> 
                    <li>
                        Each control is defined with -
                        <ul style={{ listStyle: 'disc' }}>
                            <li>
                                <strong>Default Value:</strong>
                                <ul style={{ listStyle: 'disc' }}>
                                    <li><span class="background-grey">''</span> (empty string) indicates the initial value of the control.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Validators:</strong>
                                <ul style={{ listStyle: 'disc' }}>
                                    <li><span class="background-grey">Validators.required</span>: Ensures the field is not empty.</li>
                                    <li><span class="background-grey">Validators.minLength(3)</span>: Requires at least 3 characters (for username).</li>
                                    <li><span class="background-grey">Validators.email</span>: Validates the input format as an email address.</li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                </ul>



            </div>
        </section>
    )
}