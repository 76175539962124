import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/jquery/events/event-type";

export default function Type() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("jQuery event.type Object | Aspirant's Home");
        const urls = {
            'previous': '/jquery/events'
        }
        path.setPreviousNext(urls);
        path.setPreviousName('Back to Events');
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>jQuery event.type Object</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">event.type</span> property in jQuery provides the <strong>type of event</strong> that was triggered, such as <span class="background-grey">click</span>, <span class="background-grey">mouseover</span>, <span class="background-grey">keydown</span>, etc. It is a <strong>read-only string</strong> that specifies the name of the event.
                </p>

                <p>It has a basic syntax -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(selector).<span class="color-red">on</span>(event, <span class="color-blue">function</span>(event) {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(event.type);</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-3'>Example 1: Logging Event Type</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;button <span class="color-pink">id</span>=<span class="color-green">"myButton"</span>&gt;Click Me&lt;/button&gt;</p>
                        `
                    }}></div>
                </div>
                <h6>jQuery Code</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"#myButton"</span>).<span class="color-red">on</span>(<span class="color-green">"click"</span>, <span class="color-blue">function</span>(event) {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Event type:"</span>, event.type);</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>When you click the button, the <span class="background-grey">event.type</span> logs: <span class="background-grey">Event type: click</span></li>
                </ul>

                <h5 className='mt-5 mb-3'>Example 2: Event Delegation with Type Checking</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;ul&gt;</p>
                        <p class="ml-30">    &lt;li&gt;Item 1&lt;/li&gt;</p>
                        <p class="ml-30">    &lt;li&gt;Item 2&lt;/li&gt;</p>
                        <p class="ml-30">    &lt;li&gt;Item 3&lt;/li&gt;</p>
                        <p>&lt;/ul&gt;</p>
                        `
                    }}></div>
                </div>
                <h6>jQuery Code</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"ul"</span>).<span class="color-red">on</span>(<span class="color-green">"click mouseover"</span>, <span class="color-green">"li"</span>, <span class="color-blue">function</span>(event) {</p>
                        <p class="ml-30">    <span class="color-blue">if</span> (event.type === <span class="color-green">"click"</span>) {</p>
                        <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"List item clicked:"</span>, $(this).<span class="color-red">text</span>());</p>
                        <p class="ml-30">    } <span class="color-blue">else if</span> (event.type === <span class="color-green">"mouseover"</span>) {</p>
                        <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Mouse over item:"</span>, $(this).<span class="color-red">text</span>());</p>
                        <p class="ml-30">    }</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>Clicking on a list item logs: <span class="background-grey">List item clicked: Item 1</span></li>
                    <li>Hovering over a list item logs: <span class="background-grey">Mouse over item: Item 2</span></li>
                </ul>

            </div>
        </section>
    )
}