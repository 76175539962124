import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Outlet, Link } from "react-router-dom";
export const pageUrl = () => "/angular/handling-http-responses";

export default function HttpResponses() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Handling HTTP Responses in Angular | Aspirant's Home");
        const urls = {
            'previous': '/angular/making-http-requests',
            'next': '/angular/interceptors'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Handling HTTP Responses</h3>
            <div className='mt-4 mb-5'>
                <p>
                    When building Angular applications, you often need to communicate with servers to fetch or send data. This is done using <strong>HTTP requests</strong>. Angular provides the <strong>HttpClient</strong> module to handle HTTP communication effectively.
                </p>

                <h5 className='mt-5 mb-3'>Understanding HTTP Responses</h5>
                <p>When you make an HTTP request, the server sends back a <strong>response</strong>. This response can be:</p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>Success (200)</strong>: Data returned successfully. Example: A list of users.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>[</p>
                                <p class="ml-30">    { <span class="color-green">"id"</span>: <span class="color-pink">1</span>, <span class="color-green">"name"</span>: <span class="color-green">"John Doe"</span> },</p>
                                <p class="ml-30">    { <span class="color-green">"id"</span>: <span class="color-pink">2</span>, <span class="color-green">"name"</span>: <span class="color-green">"Jane Smith"</span> }</p>
                                <p>]</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='mt-4'>
                        <strong>Error (e.g., 404, 500)</strong>: Something went wrong. Example: Server not found.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>{</p>
                                <p class="ml-30">    <span class="color-green">"status"</span>: <span class="color-pink">404</span>,</p>
                                <p class="ml-30">    <span class="color-green">"message"</span>: <span class="color-green">"Resource not found"</span></p>
                                <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>
                <p>Angular makes it easy to handle both success and error responses.</p>


                <h5 className='mt-5 mb-3'>Handling Both Success and Errors</h5>
                <p>When making HTTP requests, you must handle both:</p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>Success:</strong> The <span class="background-grey">data</span> in the <span class="background-grey">.subscribe()</span> function contains the response from the server.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">this</span>.dataService.<span class="color-red">getUsers</span>().<span class="color-red">subscribe</span>(</p>
                                <p class="ml-30">    (data) => {</p>
                                <p class="ml-60">      <span class="color-blue">this</span>.users = data; <span class="color-grey">// Success: Use the data</span></p>
                                <p class="ml-30">    }</p>
                                <p>);</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Error:</strong> The <span class="background-grey">error</span> in the <span class="background-grey">.subscribe()</span> function contains information about what went wrong.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">this</span>.dataService.<span class="color-red">getUsers</span>().<span class="color-red">subscribe</span>(</p>
                                <p class="ml-30">    (data) => {</p>
                                <p class="ml-60">        <span class="color-blue">this</span>.users = data;</p>
                                <p class="ml-30">    },</p>
                                <p class="ml-30">    (error) => {</p>
                                <p class="ml-60">        <span class="color-blue">this</span>.errorMessage = <span class="color-green">'Something went wrong!'</span>;</p>
                                <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">error</span>(<span class="color-green">'Error:'</span>, error); <span class="color-grey">// Log the error for debugging</span></p>
                                <p class="ml-30">    }</p>
                                <p>);</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>


            </div>
        </section>
    )
}