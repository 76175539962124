import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/tag/sup";

export default function Sup() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - <sup> Tag | Aspirant's Home");
        const urls = {
            'previous': '/html/tag-list'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - &lt;sup&gt; Tag</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">&lt;sup&gt;</span> tag in HTML is used to display text in a superscript format, which is text raised slightly above the normal line and shown in a smaller font size. Superscript text is commonly used for mathematical exponents, ordinal numbers, and footnotes.
                </p>

                <h5 className='mt-5 mb-3'>Structure and Purpose of <span class="background-grey">&lt;sup&gt;</span> Tag</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span class="background-grey">&lt;sup&gt;</span> tag raises text above the baseline, making it useful for exponents and notations.</li>
                    <li>It is commonly found in mathematical expressions, chemical formulas, ordinals, and references.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <p>The <span class="background-grey">&lt;sup&gt;</span> tag wraps around the text you want to display as superscript:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;sup&gt;Your superscript text here&lt;/sup&gt;</p>
                        `
                    }}></div>
                </div>  


                <h5 className='mt-5 mb-3'>Example Usage of <span class="background-grey">&lt;sup&gt;</span> Tag</h5>
                <p>Here’s an example using <span class="background-grey">&lt;sup&gt;</span> to display an exponent:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Superscript Example&lt;/title&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <br />    
                        <p class="ml-60">        &lt;p&gt;The area of a square is calculated as side&lt;sup&gt;2&lt;/sup&gt;.&lt;/p&gt;</p>
                        <br />    
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div> 
                <p>In this example, "2" is displayed as a superscript, representing the mathematical exponent in "side²".</p>
                <p>Output will be the following -</p>
                <div className='outputPalateBox mt-2 mb-4'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <!DOCTYPE html>
                        <html lang="en">
                        <head>
                        <meta charset="UTF-8">
                        <title>Superscript Example</title>
                        </head>
                        <body>

                        <p>The area of a square is calculated as side<sup>2</sup>.</p>

                        </body>
                        </html>
                        `
                    }}></div>
                </div> 


            </div>
        </section>
    )
}