import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/events/onmouseout";

export default function Onmouseout() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - onmouseout Event | Aspirant's Home");
        const urls = {
            'previous': '/html/events'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - onmouseout Event</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">onmouseout</span> event in HTML is triggered when the mouse pointer leaves an HTML element. This event is often used to reverse or hide changes made when the mouse entered the element (typically with onmouseover). It can help you create interactive effects like hiding tooltips, removing highlights, or stopping animations when the mouse moves away from an element.
                </p>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <p>To use <span class="background-grey">onmouseout</span>, add it as an attribute to an HTML element and specify the JavaScript code or function that should execute when the mouse leaves the element.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;p <span class="color-pink">onmouseout</span>=<span class="color-green">"alert('Mouse left this text!')"</span>
                            &gt;Hover over and then move away from this text&lt;/p&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example, when the user hovers over the paragraph and then moves the mouse away, an alert appears saying "Mouse left this text!"</p>

                <h5 className='mt-5 mb-3'>Example: Reverting Styles with <span class="background-grey">onmouseout</span></h5>
                <p><span class="background-grey">onmouseout</span> is commonly used to reverse style changes made with <span class="background-grey">onmouseover</span>, providing a dynamic and interactive effect.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;style&gt;</p>
                        <p class="ml-30">    .highlight {</p>
                        <p class="ml-60">        <span class="color-green">background-color</span>: lightyellow;</p>
                        <p class="ml-60">        <span class="color-green">color</span>: darkblue;</p>
                        <p class="ml-30">    }</p>
                        <p>&lt;/style&gt;</p>
                        <br />
                        <p>&lt;p <span class="color-pink">onmouseover</span>=<span class="color-green">"this.classList.add('highlight')"</span> <span class="color-pink">onmouseout</span>=<span class="color-green">"this.classList.remove('highlight')"</span>&gt;</p>
                        <p class="ml-30">    Hover over me to change my style, then move away to revert it</p>
                        <p>&lt;/p&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>When the mouse hovers over the paragraph, the <span class="background-grey">highlight</span> class is added, changing the background and text color.</li>
                    <li>When the mouse leaves, <span class="background-grey">onmouseout</span> removes the <span class="background-grey">highlight</span> class, restoring the original style.</li>
                </ul>
                <p>Output will be the following -</p>
                <div className='outputPalateBox mt-2 mb-4'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <style>
                            .highlight {
                                background-color: lightyellow;
                                color: darkblue;
                            }
                        </style>
                      
                        <p onmouseover="this.classList.add('highlight')" onmouseout="this.classList.remove('highlight')">
                            Hover over me to change my style, then move away to revert it
                        </p>
                        `
                    }}></div>
                </div>



            </div>
        </section>
    )
}