import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/jquery/add";

export default function Add() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("jQuery - Add Elements | Aspirant's Home");
        const urls = {
            'previous': '/jquery/set',
            'next': '/jquery/remove'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>jQuery - Add Elements</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In jQuery, you can easily add new elements to the DOM using a set of convenient methods. These methods allow you to insert elements before, after, inside, or outside of other elements, giving you precise control over where new content appears.
                </p>

                <h5 className='mt-5 mb-3'>Adding Elements Inside an Element</h5>
                <p>jQuery provides methods to add elements inside another element. You can add elements at the beginning or end of a selected element.</p>

                <h4 className='mt-5'>.append()</h4>
                <p>The <span class="background-grey">.append()</span> method inserts new content at the <strong>end</strong> of the selected element(s). Look at the example below -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(<span class="color-green">"#myDiv"</span>).<span class="color-red">append</span>(<span class="color-green">"&lt;p&gt;This is added at the end.&lt;/p&gt;"</span>);</p>
                        `
                    }}></div>
                </div>
                <p>It adds a new paragraph inside <span class="background-grey">#myDiv</span> at the <strong>end</strong>. </p>


                <h4 className='mt-5'>.prepend()</h4>
                <p>The <span class="background-grey">.prepend()</span> method inserts new content at the <strong>beginning</strong> of the selected element(s). Look at the example below -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(<span class="color-green">"#myDiv"</span>).<span class="color-red">prepend</span>(<span class="color-green">"&lt;p&gt;This is added at the beginning.&lt;/p&gt;"</span>);</p>
                        `
                    }}></div>
                </div>
                <p>It adds a new paragraph inside <span class="background-grey">#myDiv</span> at the start.</p>



                <h5 className='mt-5 mb-3'>Adding Elements Outside an Element</h5>
                <p>You can also insert elements outside of a selected element, either before or after it in the DOM.</p>

                <h4 className='mt-5'>.before()</h4>
                <p>The <span class="background-grey">.before()</span> method inserts content <strong>before</strong> the selected element(s). Look at the example below -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(<span class="color-green">"#myDiv"</span>).<span class="color-red">before</span>(<span class="color-green">"&lt;p&gt;This is added before the div.&lt;/p&gt;"</span>);</p>
                        `
                    }}></div>
                </div>
                <p>It adds a new paragraph before <span class="background-grey">#myDiv</span>.</p>

                <h4 className='mt-5'>.after()</h4>
                <p>The <span class="background-grey">.after()</span> method inserts content <strong>after</strong> the selected element(s). Look at the example below -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(<span class="color-green">"#myDiv"</span>).<span class="color-red">after</span>(<span class="color-green">"&lt;p&gt;This is added after the div.&lt;/p&gt;"</span>);</p>
                        `
                    }}></div>
                </div>
                <p>It adds a new paragraph after <span class="background-grey">#myDiv</span>.</p>



                <h5 className='mt-5 mb-3'>Using Elements or jQuery Objects as Content</h5>

                <p>You can add content by passing either <strong>HTML strings</strong>, <strong>jQuery objects</strong>, or <strong>existing elements</strong> as arguments to these methods.</p>

                <h6>Example 1: Using an HTML String</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(<span class="color-green">"#myDiv"</span>).<span class="color-red">append</span>(<span class="color-green">"<span>Added with HTML string</span>"</span>);</p>
                        `
                    }}></div>
                </div>
                <p>It adds <span class="background-grey">&lt;span&gt;Added with HTML string&lt;/span&gt;</span> inside <span class="background-grey">#myDiv</span>.</p>


                <h6>Example 2: Using an Existing jQuery Object</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> newElement = $(<span class="color-green">"&lt;p&gt;This is a new paragraph.&lt;/p&gt;"</span>);</p>
                        <p>$(<span class="color-green">"#myDiv"</span>).<span class="color-red">append</span>(newElement);</p>
                        `
                    }}></div>
                </div>
                <p>It adds <span class="background-grey">newElement</span> as a paragraph inside <span class="background-grey">#myDiv</span>.</p>




            </div>
        </section>
    )
}