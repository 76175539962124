import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/tag/button";

export default function Button() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - <button> Tag | Aspirant's Home");
        const urls = {
            'previous': '/html/tag-list'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - &lt;button&gt; Tag</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">&lt;button&gt;</span> tag in HTML creates a clickable button element, allowing users to perform actions like submitting forms, resetting fields, or triggering JavaScript functions. It is more versatile than the <span class="background-grey">&lt;input type="button"&gt;</span> element because it can contain both text and other HTML elements like images or icons.
                </p>

                <h5 className='mt-5 mb-3'>Basic Structure of the <span class="background-grey">&lt;button&gt;</span> Tag</h5>
                <p>The <span class="background-grey">&lt;button&gt;</span> tag is a container element, so it has both opening and closing tags. Any text or HTML inside the opening and closing tags will appear on the button.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;button&gt;Click Me!&lt;/button&gt;</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Types of <span class="background-grey">&lt;button&gt;</span> Tag</h5>
                <p>The <span class="background-grey">type</span> attribute specifies the button's behavior in the form:</p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>Submit</strong> (<span class="background-grey">type="submit"</span>): Submits the form data. This is the default type when used inside a form.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&ltbutton <span class="color-pink">type</span>=<span class="color-green">"submit"</span>&gt;Submit&lt;/button&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Button</strong> (<span class="background-grey">type="button"</span>): A general button, often used with JavaScript to perform an action when clicked.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;button <span class="color-pink">type</span>=<span class="color-green">"button"</span> <span class="color-pink">onclick</span>=<span class="color-green">"alert('Button clicked!')"</span>&lt;Click Me&lt;/button&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Reset</strong> (<span class="background-grey">type="reset"</span>): Resets all form fields to their default values.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;button <span class="color-pink">type</span>=<span class="color-green">"reset"</span>&gt;Reset Form&lt;/button&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>


                <h5 className='mt-5 mb-3'>Styling <span class="background-grey">&lt;button&gt;</span> with CSS</h5>
                <p>You can style buttons to create an appealing and interactive interface:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Styled Button Example&lt;/title&gt;</p>
                        <p class="ml-60">        &lt;style&gt;</p>
                        <p class="ml-90">            button {</p>
                        <p class="ml-120">                <span class="color-green">padding</span>: <span class="color-pink">10px 15px</span>;</p>
                        <p class="ml-120">                <span class="color-green">font-size</span>: <span class="color-pink">16px</span>;</p>
                        <p class="ml-120">                <span class="color-green">color</span>: <span class="color-pink">#fff</span>;</p>
                        <p class="ml-120">                <span class="color-green">background-color</span>: <span class="color-pink">#4CAF50</span>;</p>
                        <p class="ml-120">                <span class="color-green">border</span>: none;</p>
                        <p class="ml-120">                <span class="color-green">border-radius</span>: <span class="color-pink">5px</span>;</p>
                        <p class="ml-120">                <span class="color-green">cursor</span>: pointer;</p>
                        <p class="ml-90">            }</p>
                        <p class="ml-90">            button:<span class="color-pink">hover</span> {</p>
                        <p class="ml-120">                <span class="color-green">background-color</span>: <span class="color-pink">#45a049</span>;</p>
                        <p class="ml-90">            }</p>
                        <p class="ml-60">        &lt;/style&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <br />    
                        <p class="ml-60">        &lt;h2&gt;Styled Button&lt;/h2&gt;</p>
                        <p class="ml-60">        &lt;button <span class="color-pink">type</span>=<span class="color-green">"button"</span> <span class="color-pink">onclick</span>=<span class="color-green">"alert('Styled button clicked!')"</span>&lt;Click Me&lt;/button&gt;</p>
                        <br />    
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Output will be the following -</p>
                <div className='outputPalateBox mt-2 mb-4'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <!DOCTYPE html>
                        <html lang="en">
                            <head>
                                <meta charset="UTF-8">
                                <title>Styled Button Example</title>
                                <style>
                                    button {
                                        padding: 10px 15px;
                                        font-size: 16px;
                                        color: #fff;
                                        background-color: #4CAF50;
                                        border: none;
                                        border-radius: 5px;
                                        cursor: pointer;
                                    }
                                    button:hover {
                                        background-color: #45a049;
                                    }
                                </style>
                            </head>
                            <body>
                            
                                <h2>Styled Button</h2>
                                <button type="button" onclick="alert('Styled button clicked!')">Click Me</button>
                            
                            </body>
                        </html>
                        `
                    }}></div>
                </div>



            </div>
        </section>
    )
}