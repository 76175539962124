import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/tag/strong";

export default function Strong() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - <strong> Tag | Aspirant's Home");
        const urls = {
            'previous': '/html/tag-list'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - &lt;strong&gt; Tag</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">&lt;strong&gt;</span> tag in HTML is used to give text a semantic emphasis, indicating that the content is of high importance or significance. By default, text within a <span class="background-grey">&lt;strong&gt;</span> tag is displayed in bold, but it also provides meaningful emphasis that is recognized by screen readers and search engines. This makes <span class="background-grey">&lt;strong&gt;</span> more suitable than <span class="background-grey">&lt;b&gt;</span> for marking essential information.
                </p>

                <h5 className='mt-5 mb-3'>Structure and Purpose of <span class="background-grey">&lt;strong&gt;</span> Tag</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span class="background-grey">&lt;strong&gt;</span> tag not only makes text bold but also conveys a sense of importance or urgency</li>
                    <li>It typically read <span class="background-grey">&lt;strong&gt;</span> text with added emphasis, making it more accessible.</li>
                    <li>Search engines may treat <span class="background-grey">&lt;strong&gt;</span> text as relevant or keyword-rich, which can aid in SEO.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <p>The <span class="background-grey">&lt;strong&gt;</span> tag is used by wrapping it around the important text:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;strong&gt;Important text here&lt;/strong&gt;</p>
                        `
                    }}></div>
                </div>  


                <h5 className='mt-5 mb-3'>Example Usage of <span class="background-grey">&lt;strong&gt;</span> Tag</h5>
                <p>Here’s an example using <span class="background-grey">&lt;strong&gt;</span> to emphasize a warning within a paragraph:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Strong Text Example&lt;/title&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <br />    
                        <p class="ml-60">        &lt;p&gt;Please &lt;strong&gt;do not share&lt;/strong&gt; your password with anyone.&lt;/p&gt;</p>
                        <br />    
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>  
                <p>In this example, "do not share" is marked with <span class="background-grey">&lt;strong&gt;</span>, visually emphasizing it in bold and indicating that it’s crucial information.</p>
                <p>Output will be the following -</p>
                <div className='outputPalateBox mt-2 mb-4'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <!DOCTYPE html>
                        <html lang="en">
                            <head>
                                <meta charset="UTF-8">
                                <title>Strong Text Example</title>
                            </head>
                            <body>
                            
                                <p>Please <strong>do not share</strong> your password with anyone.</p>
                            
                            </body>
                        </html>
                        `
                    }}></div>
                </div>

                <p>
                    The <span class="background-grey">&lt;strong&gt;</span> tag is used to indicate text of high importance or urgency, rendering it in bold and providing semantic emphasis that enhances accessibility and SEO. It is ideal for emphasizing warnings, instructions, and crucial information in content. For decorative bolding without emphasis, the <span class="background-grey">&lt;b&gt;</span> tag is a better choice. The <span class="background-grey">&lt;strong&gt;</span> tag can also be further styled using CSS to align with a website’s design.
                </p>



            </div>
        </section>
    )
}