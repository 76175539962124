import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/events/onload";

export default function Onload() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - onload Event | Aspirant's Home");
        const urls = {
            'previous': '/html/events'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - onload Event</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">onload</span> event in HTML is triggered when a web page or an element (like an image, iframe, or script) has fully loaded. This event is often used to initialize scripts or functions, preload images, or fetch data from a server once all elements are loaded.
                </p>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <p>To use onload, add it as an attribute to the <span class="background-grey">&lt;body&gt;</span> tag (for the entire page) or other elements like <span class="background-grey">&lt;img&gt;</span> or <span class="background-grey">&lt;iframe&gt;</span>.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;body <span class="color-pink">onload</span>=<span class="color-green">"init()"</span>&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example, the <span class="background-grey">init()</span> function will run when the page fully loads.</p>

                <h5 className='mt-5 mb-3'>Example: Page Load Greeting</h5>
                <p>Using <span class="background-grey">onload</span>, you can display a greeting or initiate a welcome action once the page loads.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;body <span class="color-pink">onload</span>=<span class="color-green">"displayGreeting()"</span>&gt;</p>
                        <p class="ml-30">    &lt;h1&gt;Welcome to My Website&lt;/h1&gt;</p>
                        <p class="ml-30">    &lt;p <span class="color-pink">id</span>=<span class="color-green">"greeting"</span>&gt;&lt;/p&gt;</p>
                        <br />
                        <p class="ml-30">    &lt;script&gt;</p>
                        <p class="ml-60">        <span class="color-blue">function</span> <span class="color-red">displayGreeting</span>() {</p>
                        <p class="ml-90">            document.<span class="color-red">getElementById</span>(<span class="color-green">"greeting"</span>).textContent = <span class="color-green">"Hello! Thanks for visiting."</span>;</p>
                        <p class="ml-60">        }</p>
                        <p class="ml-30">    &lt;/script&gt;</p>
                        <p>&lt;/body&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example, The <span class="background-grey">displayGreeting</span> function updates the <span class="background-grey">&lt;p&gt;</span> element with a welcome message when the page loads.</p>



            </div>
        </section>
    )
}