import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/jquery/events/event-preventdefault";

export default function PreventDefault() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("jQuery event.preventDefault() Object | Aspirant's Home");
        const urls = {
            'previous': '/jquery/events'
        }
        path.setPreviousNext(urls);
        path.setPreviousName('Back to Events');
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>jQuery event.preventDefault() Object</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">event.preventDefault()</span> method in jQuery is used to prevent the default action associated with an event from occurring. This is useful when you want to override the browser's standard behavior for an element, such as stopping a form submission or disabling a link's navigation.
                </p>


                <p>It has a basic syntax -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>event.<span class="color-red">preventDefault</span>();</p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-3'>Example 1: Preventing Link Navigation</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;a <span class="color-pink">href</span>=<span class="color-green">"https://example.com"</span> <span class="color-pink">id</span>=<span class="color-green">"myLink"</span>&gt;Go to Example&lt;/a&gt;</p>
                        `
                    }}></div>
                </div>
                <h6>jQuery Code</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"#myLink"</span>).<span class="color-red">on</span>(<span class="color-green">"click"</span>, <span class="color-blue">function</span>(event) {</p>
                        <p class="ml-30">    event.<span class="color-red">preventDefault</span>(); <span class="color-grey">// Prevents navigation</span></p>
                        <p class="ml-30">    <span class="color-red">alert</span>(<span class="color-green">"Link click prevented!"</span>);</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>Clicking the link does not navigate to https://example.com. Instead, the custom message is shown.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Example 2: Stopping Form Submission</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;form <span class="color-pink">id</span>=<span class="color-green">"myForm"</span>&gt;</p>
                        <p class="ml-30">    &lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">placeholder</span>=<span class="color-green">"Type something"</span> /&gt;</p>
                        <p class="ml-30">    &lt;button <span class="color-pink">type</span>=<span class="color-green">"submit"</span>&gt;Submit&lt;/button&gt;</p>
                        <p>&lt;/form&gt;</p>
                        `
                    }}></div>
                </div>
                <h6>jQuery Code</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"#myForm"</span>).<span class="color-red">on</span>(<span class="color-green">"submit"</span>, <span class="color-blue">function</span>(event) {</p>
                        <p class="ml-30">    event.<span class="color-red">preventDefault</span>(); <span class="color-grey">// Stops the form from being submitted</span></p>
                        <p class="ml-30">    <span class="color-red">alert</span>(<span class="color-green">"Form submission prevented!"</span>);</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>Submitting the form doesn't reload the page or send data to the server.</li>
                </ul>


            </div>
        </section>
    )
}