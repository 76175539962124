import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/jquery/events/focus";

export default function Focus() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("jQuery focus() Method | Aspirant's Home");
        const urls = {
            'previous': '/jquery/events'
        }
        path.setPreviousNext(urls);
        path.setPreviousName('Back to Events');
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>jQuery focus() Method</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">focus()</span> method in jQuery is used to trigger a function when an element, usually a form field, gains focus. This event occurs when the user clicks into the element or navigates to it using the keyboard, making it an ideal method for actions like highlighting an input field or displaying helper text.
                </p>

                <p>It has a basic syntax -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(selector).<span class="color-red">focus</span>(function);</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">selector</span> specifies the element(s) to attach the <span class="background-grey">focus</span> event to.</li>
                    <li><span class="background-grey">function</span> is a callback function to execute when the element receives focus.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Basic Example: Using <span class="background-grey">focus()</span> to Change Background Color</h5>
                <p>Here's an example that changes the background color of an input field when it gains focus.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">id</span>=<span class="color-green">"nameInput"</span> <span class="color-pink">placeholder</span>=<span class="color-green">"Enter your name"</span>&gt;</p>
                        `
                    }}></div>
                </div>
                <h6>jQuery Code</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"#nameInput"</span>).<span class="color-red">focus</span>(<span class="color-blue">function</span>() {</p>
                        <p class="ml-30">    $(this).<span class="color-red">css</span>(<span class="color-green">"background-color"</span>, <span class="color-green">"#e0f7fa"</span>);</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span class="background-grey">focus()</span> method is attached to the input field with the ID <span class="background-grey">#nameInput</span>.</li>
                    <li>When the user clicks into or navigates to this input field, its background color changes to a light blue (<span class="background-grey">#e0f7fa</span>).</li>
                </ul>


            </div>
        </section>
    )
}