import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/control-structure";

export default function ControlStructure() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Control Structures in Javascript | Aspirant's Home");
        const urls = {
            'previous': '/javascript/operators',
            'next': '/javascript/object-definition'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Control Structures</h3>
            <div className='mt-4 mb-5'>

            </div>
        </section>
    )
}