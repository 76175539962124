import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/angular/creating-and-using-components";

export default function CreatingComponent() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Creating and Using Components in Angular | Aspirant's Home");
        const urls = {
            'previous': '/angular/architecture',
            'next': '/angular/component-interaction'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Components in Angular</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Components are the building blocks of Angular applications. They define a specific part of the UI and its behavior. Let's go step by step to understand how to create and use components in an Angular application.
                </p>

                
                <h5 className='mt-5 mb-3'>Creating a Component</h5>

                <h6 className='mt-4'>Using Angular CLI</h6>
                <p>The easiest way to create a component is by using the Angular CLI command:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">ng generate</span> component component-name</p>
                        `
                    }}></div>
                </div>
                <p>Or the shorthand:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">ng g c</span> component-name</p>
                        `
                    }}></div>
                </div>
                <strong>Example:</strong>
                <p>Let's create a component named <span class="background-grey">user-profile</span>:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">ng generate</span> component user-profile</p>
                        `
                    }}></div>
                </div>
                <p>This command generates the following files in a folder named <span class="background-grey">user-profile</span>:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">user-profile.component.ts</span>: Contains the logic for the component.</li>
                    <li><span class="background-grey">user-profile.component.html</span>: The template for the component.</li>
                    <li><span class="background-grey">user-profile.component.css</span>: The styles for the component.</li>
                    <li><span class="background-grey">user-profile.component.spec.ts</span>: The testing file for the component.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Structure of a Component</h5>
                <p>A component consists of the following:</p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>TypeScript File (.ts)</strong>: Contains the logic and data for the component.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">import</span> { <span class="color-blue">Component</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                                    <br />
                                    <p><span class="color-red">@Component</span>({</p>
                                    <p class="ml-30">    <span class="color-pink">selector</span>: <span class="color-green">'app-user-profile'</span>,</p>
                                    <p class="ml-30">    <span class="color-pink">templateUrl</span>: <span class="color-green">'./user-profile.component.html'</span>,</p>
                                    <p class="ml-30">    <span class="color-pink">styleUrls</span>: [<span class="color-green">'./user-profile.component.css'</span>]</p>
                                    <p>})</p>
                                    <p><span class="color-pink">export class</span> <span class="color-blue">UserProfileComponent</span> {</p>
                                    <p class="ml-30">    name = <span class="color-green">'John Doe'</span>;</p>
                                    <p class="ml-30">    age = <span class="color-pink">30</span>;</p>
                                    <br />    
                                    <p class="ml-30">    <span class="color-red">greet</span>() {</p>
                                    <p class="ml-60">        <span class="color-blue">return</span> <span class="color-green">&#96;Hello, $&#123;this.name&#125;!&#96;</span>;</p>
                                    <p class="ml-30">    }</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>

                        <h5 className='mt-5 mb-3'><span class="background-grey">@Component</span> decorator</h5>
                        <p>
                            The <span class="background-grey">@Component</span> decorator in Angular is a core feature used to define a <strong>component</strong>, which is a building block of Angular applications. It provides metadata to configure the behavior, structure, and presentation of the component.
                        </p>
                        <p>Here, in the above example -</p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>
                                <span class="background-grey">@Component:</span>
                                <p>Decorator that specifies metadata for the UserProfileComponent.</p>
                            </li>
                            <li>
                                <span class="background-grey">selector: 'app-user-profile':</span>
                                <ul className='mb-3' style={{listStyle:'disc'}}>
                                    <li>The custom HTML tag used to include this component in templates.</li>
                                    <li>Example usage: &lt;app-user-profile&gt;&lt;/app-user-profile&gt;.</li>
                                </ul>
                            </li>
                            <li>
                                <span class="background-grey">templateUrl: './user-profile.component.html':</span>
                                <p>The external HTML file that defines the component's structure and content.</p>
                            </li>
                            <li>
                                <span class="background-grey">styleUrls: ['./user-profile.component.css']:</span>
                                <p>The external CSS file that defines the styles for this component.</p>
                            </li>
                        </ul>

                    </li>
                    <li>
                        <strong>HTML Template (.html)</strong>: Defines the UI structure.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>&lt;h1&gt;User Profile&lt;/h1&gt;</p>
                                <p>&lt;p&gt;Name: {{ <span class="color-blue">name</span> }}&lt;/p&gt;</p>
                                <p>&lt;p&gt;Age: {{ <span class="color-blue">age</span> }}&lt;/p&gt;</p>
                                <p>&lt;button <span class="color-pink">(click)</span>=<span class="color-green">"showGreeting()"</span>&gt;Greet&lt;/button&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>CSS/SCSS File (.css or .scss)</strong>: Contains the styles specific to the component.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>h1 {</p>
                                <p class="ml-30">    color: blue;</p>
                                <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Selector</strong>: Defined in the <span class="background-grey">@Component</span> decorator. The selector is used to place the component in the application.
                    </li>
                </ul>


                <h5 className='mt-5 mb-3'>Adding the Component to a Module</h5>
                <p>After creating the component, Angular automatically adds it to the <span class="background-grey">declarations</span> array of the root module (<span class="background-grey">AppModule</span>) or the relevant module.</p>
                <p>Example (app.module.ts):</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">import</span> { <span class="color-blue">NgModule</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                        <p><span class="color-pink">import</span> { <span class="color-blue">BrowserModule</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/platform-browser'</span>;</p>
                        <p><span class="color-pink">import</span> { <span class="color-blue">AppComponent</span> } <span class="color-pink">from</span> <span class="color-green">'./app.component'</span>;</p>
                        <p><span class="color-pink">import</span> { <span class="color-blue">UserProfileComponent</span> } <span class="color-pink">from</span> <span class="color-green">'./user-profile/user-profile.component'</span>;</p>
                        <br />
                        <p><span class="color-red">@NgModule</span>({</p>
                        <p class="ml-30">    <span class="color-pink">declarations</span>: [AppComponent, UserProfileComponent], <span class="color-grey">// Declare the new component here</span></p>
                        <p class="ml-30">    <span class="color-pink">imports</span>: [BrowserModule],</p>
                        <p class="ml-30">    <span class="color-pink">providers</span>: [],</p>
                        <p class="ml-30">    <span class="color-pink">bootstrap</span>: [AppComponent]</p>
                        <p>})</p>
                        <p><span class="color-pink">export class</span> <span class="color-blue">AppModule</span> {}</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Using the Component in a Template</h5>
                <p>To display the component, use its selector in another component's HTML.</p>
                <p>Example (app.component.html):</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;h1&gt;Welcome to My Application&lt;/h1&gt;</p>
                        <p>&lt;<span class="color-pink">app-user-profile</span>&gt;&lt;/<span class="color-pink">app-user-profile</span>&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Here, <span class="background-grey">&lt;app-user-profile&gt;</span> is the selector for the <span class="background-grey">UserProfileComponent</span>.</p>
                   


            </div>
        </section>
    )
}