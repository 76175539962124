import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/if-else";

export default function IfElse() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("If - Else in Javascript | Aspirant's Home");
        const urls = {
            'previous': '/javascript/operators',
            'next': '/javascript/switch'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>If - Else</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className='background-grey'>if-else</span> statement in JavaScript is used to make decisions in your code. It allows your program to execute different blocks of code based on certain conditions.
                </p>

                <h5 className='mt-5 mb-3'>How <span className='background-grey'>if-else</span> Works</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>If Statement:</strong>
                        <p>It checks a condition. If the condition is <strong>true</strong>, it runs the code inside the block <span className='background-grey'>{ }</span>.</p>
                    </li>
                    <li>
                        <strong>Else Statement:</strong>
                        <p>If the condition in the <span className='background-grey'>if</span> statement is <strong>false</strong>, the <span className='background-grey'>else</span> block runs.</p>
                    </li>
                </ul>

                <h5 className='mt-5 mb-3'>Syntax</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">if</span> (condition) {</p>
                        <p class="ml-30 color-grey">    // Code to run if the condition is true</p>
                        <p>} <span class="color-blue">else</span> {</p>
                        <p class="ml-30 color-grey">    // Code to run if the condition is false</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Example 1: Basic <span className='background-grey'>if-else</span></h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> age = <span class="color-pink">18</span>;</p>
                        <p><span class="color-blue">if</span> (age >= <span class="color-pink">18</span>) {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"You are an adult."</span>);</p>
                        <p>} <span class="color-blue">else</span> {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"You are a minor."</span>);</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>
                <p>Here,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>If <span className='background-grey'>age</span> is 18 or more, it prints: <strong>"You are an adult."</strong></li>
                    <li>Otherwise, it prints: <strong>"You are a minor."</strong></li>
                </ul>

                <h5 className='mt-5 mb-3'>What is an <span className='background-grey'>else if</span>?</h5>
                <p>The <span className='background-grey'>else if</span> <strong>statement</strong> checks another condition if the first condition is false. This allows you to check multiple conditions.</p>


                <h5 className='mt-5 mb-3'>Syntax for <span className='background-grey'>else if</span></h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">if</span> (condition1) {</p>
                        <p class="ml-30 color-grey">    // Code to run if condition1 is true</p>
                        <p>} <span class="color-blue">else if</span> (condition2) {</p>
                        <p class="ml-30 color-grey">    // Code to run if condition2 is true</p>
                        <p>} <span class="color-blue">else</span> {</p>
                        <p class="ml-30 color-grey">    // Code to run if none of the conditions are true</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Example 2: Using <span className='background-grey'>else if</span></h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> marks = <span class="color-pink">85</span>;</p>

                        <p><span class="color-blue">if</span> (marks >= <span class="color-pink">90</span>) {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Grade: A"</span>);</p>
                        <p>} <span class="color-blue">else if</span> (marks >= <span class="color-pink">75</span>) {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Grade: B"</span>);</p>
                        <p>} <span class="color-blue">else if</span> (marks >= <span class="color-pink">50</span>) {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Grade: C"</span>);</p>
                        <p>} <span class="color-blue">else</span> {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Grade: F"</span>);</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>
                <p>Here,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>If <span className='background-grey'>marks</span> is 90 or more, it prints: <strong>"Grade: A"</strong>.</li>
                    <li>If <span className='background-grey'>marks</span> is between 75 and 89, it prints: <strong>"Grade: B"</strong>.</li>
                    <li>If <span className='background-grey'>marks</span> is between 50 and 74, it prints: <strong>"Grade: C"</strong>.</li>
                    <li>Otherwise, it prints: <strong>"Grade: F"</strong>.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Nested <span className='background-grey'>if-else</span></h5>
                <p>You can use one <span className='background-grey'>if-else</span> inside another <span className='background-grey'>if-else</span>.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> age = <span class="color-pink">25</span>;</p>

                        <p><span class="color-blue">if</span> (age >= <span class="color-pink">18</span>) {</p>
                        <p class="ml-30">    <span class="color-blue">if</span> (age >= <span class="color-pink">21</span>) {</p>
                        <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"You can vote and drink alcohol."</span>);</p>
                        <p class="ml-30">    } <span class="color-blue">else</span> {</p>
                        <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"You can vote but cannot drink alcohol."</span>);</p>
                        <p class="ml-30">    }</p>
                        <p>} <span class="color-blue">else</span> {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"You are too young to vote."</span>);</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>



            </div>
        </section>
    )
}