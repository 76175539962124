import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/angular/property-binding";

export default function PropertyBinding() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Property Binding in Angular | Aspirant's Home");
        const urls = {
            'previous': '/angular/interpolation',
            'next': '/angular/event-binding'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Property Binding</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Property Binding in Angular allows you to bind a property of a DOM element, component, or directive to a <strong>TypeScript expression</strong> in the component. It enables the dynamic assignment of values to element properties in the template, keeping the view and the data in sync.
                </p>


                <h5 className='mt-5 mb-3'>Syntax</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>[<span class="color-pink">DOM_property</span>]=<span class="color-green">"expression"</span></p>
                        `
                    }}></div>
                </div>
                <p>Here,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">DOM_property</span>: The target property of the DOM element.</li>
                    <li><span class="background-grey">expression</span>: A TypeScript expression from the component class.</li>
                </ul>

                <h5 className='mt-5 mb-3'>How Property Binding Works</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>Angular replaces the value of the DOM property with the result of the <strong>expression</strong>.</li>
                    <li>It is a <strong>one-way binding</strong>, meaning the data flows <strong>from the component to the view</strong>.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Example: Property Binding</h5>
                <h6 className='mt-3'>Component (TypeScript)</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">import</span> { Component } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                        <br />
                        <p><span class="color-red">@Component</span>({</p>
                        <p class="ml-30">    <span class="color-pink">selector</span>: <span class="color-green">'app-property-binding'</span>,</p>
                        <p class="ml-30">    <span class="color-pink">template</span>: <span class="color-green">&#96;</span></p>
                        <p class="ml-60 color-green">        &lt;h1 [innerText]="title"&gt;&lt;/h1&gt;</p>
                        <p class="ml-60 color-green">        &lt;img [src]="imageUrl" [alt]="imageAlt" /&gt;</p>
                        <p class="ml-60 color-green">        &lt;button [disabled]="isDisabled"&gt;Click Me&lt;/button&gt;</p>
                        <p class="ml-30 color-green">    &#96;</p>
                        <p>})</p>
                        <p><span class="color-pink">export class</span> <span class="color-blue">PropertyBindingComponent</span> {</p>
                        <p class="ml-30">    title: <span class="color-blue">string</span> = <span class="color-green">'Property Binding Example'</span>;</p>
                        <p class="ml-30">    imageUrl: <span class="color-blue">string</span> = <span class="color-green">'https://via.placeholder.com/150'</span>;</p>
                        <p class="ml-30">    imageAlt: <span class="color-blue">string</span> = <span class="color-green">'Placeholder Image'</span>;</p>
                        <p class="ml-30">    isDisabled: <span class="color-blue">boolean</span> = <span class="color-blue">true</span>;</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>
                <h6 className='mt-3'>Template (HTML)</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;h1 [<span class="color-pink">innerText</span>]=<span class="color-green">"title"</span>&gt;&lt;/h1&gt;</p>
                        <p>&lt;img [<span class="color-pink">src</span>]=<span class="color-green">"imageUrl"</span> [<span class="color-pink">alt</span>]=<span class="color-green">"imageAlt"</span> /&gt;</p>
                        <p>&lt;button [<span class="color-pink">disabled</span>]=<span class="color-green">"isDisabled"</span>&gt;Click Me&lt;/button&gt;</p>
                        `
                    }}></div>
                </div>
                <p><strong>Output in the Browser:</strong></p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span class="background-grey">&lt;h1&gt;</span> displays the text "Property Binding Example".</li>
                    <li>The <span class="background-grey">&lt;img&gt;</span> displays the image from the specified URL with the specified alt text.</li>
                    <li>The <span class="background-grey">&lt;button&gt;</span> is disabled.</li>

                </ul>


            </div>
        </section>
    )
}