import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/tag/b";

export default function BTag() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - <b> Tag | Aspirant's Home");
        const urls = {
            'previous': '/html/tag-list'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - &lt;b&gt; Tag</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">&lt;b&gt;</span> tag in HTML is used to apply bold styling to text. The primary purpose of <span class="background-grey">&lt;b&gt;</span> is to emphasize text without adding semantic importance, unlike the <span class="background-grey">&lt;strong&gt;</span> tag, which is used to indicate text of high importance. The <span class="background-grey">&lt;b&gt;</span> tag is often used for visual effect rather than meaning, so screen readers typically don’t treat it as having extra emphasis.
                </p>

                <h5 className='mt-5 mb-3'>Structure and Purpose of <span class="background-grey">&lt;b&gt;</span> Tag</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>Makes the text appear bold to provide visual contrast.</li>
                    <li>Unlike <span class="background-grey">&lt;strong&gt;</span>, the <span class="background-grey">&lt;b&gt;</span> tag does not imply importance, urgency, or weight.</li>
                    <li>It can highlight keywords, headings, or any content where boldness is desired purely for visual reasons.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <p>The <span class="background-grey">&lt;b&gt;</span> tag is straightforward to use:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;b&gt;Your bold text here&lt;/b&gt;</p>
                        `
                    }}></div>
                </div>  

                <h5 className='mt-5 mb-3'>Example Usage of <span class="background-grey">&lt;b&gt;</span> Tag</h5>
                <p>Below is an example of using the <span class="background-grey">&lt;b&gt;</span> tag to emphasize certain words within a paragraph:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Bold Text Example&lt;/title&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <br />    
                        <p class="ml-60">        &lt;p&gt;Our &lt;b&gt;special offer&lt;/b&gt; is available for a &lt;b&gt;limited time only&lt;/b&gt;. Don’t miss out!&lt;/p&gt;</p>
                        <br />    
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>  
                <p>In this example, the <span class="background-grey">&lt;b&gt;</span> tag is used to make “special offer” and “limited time only” bold, visually distinguishing them from other text.</p>
                <p>Output will be the following -</p>
                <div className='outputPalateBox mt-2 mb-4'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <!DOCTYPE html>
                        <html lang="en">
                            <head>
                                <meta charset="UTF-8">
                                <title>Bold Text Example</title>
                            </head>
                            <body>
                            
                                <p>Our <b>special offer</b> is available for a <b>limited time only</b>. Don’t miss out!</p>
                            
                            </body>
                        </html>
                        `
                    }}></div>
                </div> 
                
                <p>The <span class="background-grey">&lt;b&gt;</span> tag is used to make text bold purely for visual emphasis without adding semantic weight. It’s useful for drawing attention to specific words or phrases and can be styled with CSS to match the design of the webpage. For adding importance and significance to text, consider using the <span class="background-grey">&lt;strong&gt;</span> tag instead.</p>



            </div>
        </section>
    )
}