import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Outlet, Link } from "react-router-dom";
export const pageUrl = () => "/angular/interceptors";

export default function Interceptors() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Interceptors in Angular | Aspirant's Home");
        const urls = {
            'previous': '/angular/handling-http-responses',
            'next': '/angular/using-built-in-pipes'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Interceptors</h3>
            <div className='mt-4 mb-5'>
                <p>
                    An HTTP Interceptor in Angular is a special service that allows you to intercept and modify HTTP requests or responses globally before they are handled by the app. Interceptors are part of Angular's HttpClientModule and are particularly useful for tasks such as:
                </p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>Adding headers (e.g., authorization tokens).</li>
                    <li>Logging HTTP requests and responses.</li>
                    <li>Modifying requests or responses.</li>
                    <li>Handling global errors.</li>
                    <li>Caching responses.</li>
                </ul>

                <h5 className='mt-5 mb-3'>How Interceptors Work</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>Interceptors act as a <strong>middleware</strong> for HTTP requests and responses.</li>
                    <li>
                        They intercept every HTTP call made using the <strong>HttpClient</strong> and allow you to:
                        <ul style={{listStyle: 'disc'}}>
                            <li>Modify the request before it is sent.</li>
                            <li>Handle or modify the response when it is received.</li>
                        </ul>
                    </li>

                </ul>


                <h5 className='mt-5 mb-3'>Creating an HTTP Interceptor</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>Generate an Interceptor</strong>
                        <p>Use Angular CLI to generate an interceptor:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">ng generate</span> interceptor <span class="color-blue">my-interceptor</span></p>
                                `
                            }}></div>
                        </div>
                        <p>This command creates two files:</p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li><span class="background-grey">my-interceptor.interceptor.ts</span>: Contains the interceptor logic.</li>
                            <li><span class="background-grey">my-interceptor.interceptor.spec.ts</span>: Test file for the interceptor.</li>
                        </ul>
                    </li>
                    <li className='mt-4'>
                        <strong>Implement the Interceptor</strong>
                        <p>Interceptors must implement the <span class="background-grey">HttpInterceptor</span> interface. Here's the basic structure:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">import</span> { <span class="color-blue">Injectable</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                                <p><span class="color-pink">import</span> { <span class="color-blue">HttpInterceptor</span>, <span class="color-blue">HttpRequest</span>, <span class="color-blue">HttpHandler</span>, <span class="color-blue">HttpEvent</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/common/http'</span>;</p>
                                <p><span class="color-pink">import</span> { <span class="color-blue">Observable</span> } <span class="color-pink">from</span> <span class="color-green">'rxjs'</span>;</p>
                                <br />
                                <p><span class="color-red">@Injectable</span>()</p>
                                <p><span class="color-pink">export class</span> <span class="color-blue">MyInterceptor</span> <span class="color-pink">implements</span> <span class="color-blue">HttpInterceptor</span> {</p>
                                <p class="ml-30">    <span class="color-red">intercept</span>(req: <span class="color-blue">HttpRequest</span><<span class="color-pink">any</span>>, next: <span class="color-blue">HttpHandler</span>): <span class="color-blue">Observable</span><HttpEvent<any>> {</p>
                                <p class="ml-60 color-grey">        // Modify the request here if needed</p>
                                <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">'Intercepted Request:'</span>, req);</p>
                                <br />
                                <p class="ml-60 color-grey">        // Pass the request to the next handler</p>
                                <p class="ml-60">        <span class="color-blue">return</span> next.<span class="color-red">handle</span>(req);</p>
                                <p class="ml-30">    }</p>
                                <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>

                    <li className='mt-4'>
                        <strong>Register the Interceptor</strong>
                        <p className='mt-3'>You must register the interceptor globally in the <span class="background-grey">providers</span> array in the module (e.g., <span class="background-grey">AppModule</span>).</p>
                        <p>Add this to your <span class="background-grey">app.module.ts</span>:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">import</span> { <span class="color-blue">HTTP_INTERCEPTORS</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/common/http'</span>;</p>
                                <p><span class="color-pink">import</span> { <span class="color-blue">MyInterceptor</span> } <span class="color-pink">from</span> <span class="color-green">'./my-interceptor.interceptor'</span>;</p>
                                <br />
                                <p><span class="color-red">@NgModule</span>({</p>
                                <p class="ml-30">    <span class="color-pink">providers</span>: [</p>
                                <p class="ml-60">        {</p>
                                <p class="ml-90">            <span class="color-pink">provide</span>: <span class="color-blue">HTTP_INTERCEPTORS</span>,</p>
                                <p class="ml-90">            <span class="color-pink">useClass</span>: <span class="color-blue">MyInterceptor</span>,</p>
                                <p class="ml-90">            <span class="color-pink">multi</span>: <span class="color-blue">true</span>, <span class="color-grey">// Allows multiple interceptors to be used</span></p>
                                <p class="ml-60">        },</p>
                                <p class="ml-30">    ],</p>
                                <p>})</p>
                                <p><span class="color-pink">export class</span> <span class="color-blue">AppModule</span> {}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>


            </div>
        </section>
    )
}