import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/jquery/descendants";

export default function Descendants() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("jQuery Traversing - Descendants | Aspirant's Home");
        const urls = {
            'previous': '/jquery/ancestors',
            'next': '/jquery/siblings'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>jQuery Traversing - Descendants</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In jQuery, <strong>descendant traversing</strong> allows you to select elements that are nested within a specific parent element, like children, grandchildren, or any deeper levels of nested elements. These methods are useful for navigating downward in the DOM hierarchy to target specific elements based on their relationship to a parent.
                </p>

                <h5 className='mt-5 mb-3'>Methods for Traversing Descendants in jQuery</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>
                        <h4><span class="background-grey">.children()</span></h4>
                        <p>Look at the below example -</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>$(<span class="color-green">"#parentElement"</span>).<span class="color-red">children</span>().<span class="color-red">css</span>(<span class="color-green">"color"</span>, <span class="color-green">"blue"</span>);</p>
                                `
                            }}></div>
                        </div>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>It selects all direct children of the selected element.</li>
                            <li>It retrieves only one level down (i.e., immediate children).</li>
                            <li>You can specify a selector to filter the children that are selected.</li>
                            <li>You can find all direct children of <span class="background-grey">#parentElement</span> and changes their text color to blue.</li>
                        </ul>
                        <p>You can get children with selector also -</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>$(<span class="color-green">"#parentElement"</span>).<span class="color-red">children</span>(<span class="color-green">".highlighted"</span>).<span class="color-red">css</span>(<span class="color-green">"background-color"</span>, <span class="color-green">"yellow"</span>);</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='mt-5'>
                        <h4><span class="background-grey">.find(selector)</span></h4>
                        <p>Look at the below example -</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>$(<span class="color-green">"#parentElement"</span>).<span class="color-red">find</span>(<span class="color-green">"p"</span>).<span class="color-red">css</span>(<span class="color-green">"font-weight"</span>, <span class="color-green">"bold"</span>);</p>
                                `
                            }}></div>
                        </div>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>It selects all descendants (children, grandchildren, etc.) of the selected element that match a specified selector.</li>
                            <li>Unlike <span class="background-grey">.children()</span>, <span class="background-grey">.find()</span> goes through all levels of descendants within the selected element.</li>
                            <li>Finds all <span class="background-grey">&lt;p&gt;</span> elements inside <span class="background-grey">#parentElement</span> (regardless of nesting depth) and makes their text bold.</li>
                        </ul>
                    </li>
                    <li className='mt-5'>
                        <h4><span class="background-grey">.contents()</span></h4>
                        <p>Look at the below example -</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>$(<span class="color-green">"#container"</span>).<span class="color-red">contents</span>().<span class="color-red">filter</span>(<span class="color-blue">function</span>() {</p>
                                <p class="ml-30">    <span class="color-blue">return</span> this.nodeType === <span class="color-pink">3</span>; <span class="color-grey">// Node type 3 represents text nodes</span></p>
                                <p>}).<span class="color-red">wrap</span>(<span class="color-green">"&lt;strong&gt;&lt;/strong&gt;"</span>);</p>
                                `
                            }}></div>
                        </div>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>It selects all direct child nodes of the selected element, including text and comment nodes, not just elements.</li>
                            <li>It is useful when you need access to everything inside an element, even non-element nodes.</li>
                            <li>It wraps any text nodes (non-element nodes) within <span class="background-grey">#container</span> in <span class="background-grey">&lt;strong&gt;</span> tags.</li>
                        </ul>
                    </li>
                </ul>


            </div>
        </section>
    )
}