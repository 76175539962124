import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/object-methods";

export default function Methods() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Object Methods in JavaScript | Aspirant's Home");
        const urls = {
            'previous': '/javascript/object-prototypes',
            'next': '/javascript/object-properties'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Object Methods</h3>
            <div className='mt-4 mb-5'>
                <p>An <strong>object method</strong> is a <strong>function</strong> that is a <strong>property</strong> of an object. Methods are actions that an object can perform, like "speak," "run," or "calculate."</p>

                <p>In simple terms, if an object has properties (data), methods are the actions related to that data.</p>


                <h5 className='mt-5 mb-3'>How to Define and Use Methods in Objects</h5>
                <p>A method is defined just like a normal property, but its value is a function.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> person = {</p>
                        <p class="ml-30">    <span class="color-pink">name</span>: <span class="color-green">"Alice"</span>,</p>
                        <p class="ml-30">    <span class="color-pink">age</span>: <span class="color-pink">25</span>,</p>
                        <p class="ml-30">    <span class="color-pink">greet</span>: <span class="color-red">function</span>() {</p>
                        <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Hello, my name is "</span> + <span class="color-blue">this</span>.name);</p>
                        <p class="ml-30">    }</p>
                        <p>};</p>
                        <br />
                        <p>person.<span class="color-red">greet</span>();  <span class="color-grey">// Output: Hello, my name is Alice</span></p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Key Concepts for Methods</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>Methods are Functions</strong>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>The only difference is that they belong to an object.</li>
                            <li>You call them using the object name: <span className='background-grey'>objectName.methodName()</span>.</li>
                        </ul>
                    </li>
                    <li>
                        <strong>The <span className='background-grey'>this</span> Keyword</strong>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>Inside a method, <span className='background-grey'>this</span> refers to the object that owns the method.</li>
                            <li>It allows the method to access other properties of the same object.</li>
                        </ul>
                    </li>
                </ul>


                <h5 className='mt-5 mb-3'>Adding Methods to Objects</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>When Defining an Object</strong>
                        <p>You can add methods directly when creating the object.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> car = {</p>
                                <p class="ml-30">    <span class="color-pink">brand</span>: <span class="color-green">"Toyota"</span>,</p>
                                <p class="ml-30">    <span class="color-pink">year</span>: <span class="color-pink">2023</span>,</p>
                                <p class="ml-30">    <span class="color-pink">start</span>: <span class="color-red">function</span>() {</p>
                                <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-blue">green"The car is starting..."</span>);</p>
                                <p class="ml-30">    }</p>
                                <p>};</p>
                                <br />
                                <p>car.<span class="color-red">start</span>();  <span class="color-grey">// Output: The car is starting...</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Adding Methods to an Existing Object</strong>
                        <p>You can add methods after creating the object.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> book = {</p>
                                <p class="ml-30">    <span class="color-pink">title</span>: <span class="color-green">"JavaScript Basics"</span></p>
                                <p>};</p>
                                <br />
                                <p class="color-grey">// Adding a method</p>
                                <p>book.describe = <span class="color-red">function</span>() {</p>
                                <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"This book is titled "</span> + this.title);</p>
                                <p>};</p>
                                <br />
                                <p>book.<span class="color-red">describe</span>();  <span class="color-grey">// Output: This book is titled JavaScript Basics</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>

                <h5 className='mt-5 mb-3'>Shorter Syntax for Methods</h5>
                <p>
                    Modern JavaScript allows you to define methods in a shorter way without using the <span className='background-grey'>function</span> keyword.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> person = {</p>
                        <p class="ml-30">    <span class="color-pink">name</span>: <span class="color-green">"John"</span>,</p>
                        <p class="ml-30">    <span class="color-red">greet</span>() {  <span class="color-grey">// Shorter syntax for a method</span></p>
                        <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Hello, my name is "</span> + <span class="color-blue">this</span>.name);</p>
                        <p class="ml-30">    }</p>
                        <p>};</p>
                        <br />
                        <p>person.<span class="color-red">greet</span>();  <span class="color-grey">// Output: Hello, my name is John</span></p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-3'>Using Methods Inside Objects</h5>
                <p>You can combine properties and methods to create practical objects.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> library = {</p>
                        <p class="ml-30">    <span class="color-pink">name</span>: <span class="color-green">"City Library"</span>,</p>
                        <p class="ml-30">    <span class="color-pink">books</span>: [<span class="color-green">"JavaScript Basics"</span>, <span class="color-green">"Python for All"</span>, <span class="color-green">"React in Depth"</span>],</p>
                        <p class="ml-30">    <span class="color-pink">showBooks</span>: <span class="color-red">function</span>() {</p>
                        <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Books available: "</span> + <span class="color-blue">this</span>.books.<span class="color-red">join</span>(<span class="color-green">", "</span>));</p>
                        <p class="ml-30">    }</p>
                        <p>};</p>
                        <br />
                        <p>library.<span class="color-blue">showBooks</span>();</p>
                        <p class="color-grey">// Output: Books available: JavaScript Basics, Python for All, React in Depth</p>
                        `
                    }}></div> 
                </div>

                <h5 className='mt-5 mb-3'>Built-in Object Methods</h5>
                <p>JavaScript provides many built-in methods that you can use directly on objects or data types.</p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <span className='background-grey'>Object.keys()</span>
                        <p>Gets an array of all the keys in an object.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> car = { <span class="color-pink">brand</span>: <span class="color-green">"Ford"</span>, <span class="color-pink">year</span>: <span class="color-pink">2020</span> };</p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(Object.<span class="color-red">keys</span>(car));  <span class="color-grey">// Output: ["brand", "year"]</span></p>
                                `
                            }}></div> 
                        </div>
                    </li>
                    <li>
                        <span className='background-grey'>Object.values()</span>
                        <p>Gets an array of all the values in an object.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">console</span>.<span class="color-red">log</span>(Object.<span class="color-red">values</span>(car));  <span class="color-grey">// Output: ["Ford", 2020]</span></p>
                                `
                            }}></div> 
                        </div>
                    </li>
                    <li>
                        <span className='background-grey'>Object.entries()</span>
                        <p>Gets an array of key-value pairs.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">console</span>.<span class="color-red">log</span>(Object.<span class="color-red">entries</span>(car));  <span class="color-grey">// Output: [["brand", "Ford"], ["year", 2020]]</span></p>
                                `
                            }}></div> 
                        </div>
                    </li>
                </ul>


            </div>
        </section>
    )
}