import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Outlet, Link } from "react-router-dom";
export const pageUrl = () => "/angular/http-client-module";

export default function HttpClientModule() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTTP Client Module in Angular | Aspirant's Home");
        const urls = {
            'previous': '/angular/injecting-services',
            'next': '/angular/setting-up-routing'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTTP Client Module</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">HttpClientModule</span> in Angular is a powerful tool for making HTTP requests to communicate with back-end servers. It provides a simplified API for sending and receiving data, working seamlessly with RESTful services, APIs, and more. It is part of the <span class="background-grey">@angular/common/http</span> package.
                </p>

                <h5 className='mt-5 mb-3'>Key Features of HttpClient</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>Simplified syntax for HTTP operations like <span class="background-grey">GET</span>, <span class="background-grey">POST</span>, <span class="background-grey">PUT</span>, <span class="background-grey">DELETE</span>.</li>
                    <li>Supports request and response handling with <Link to="/angular/observables"><strong>Observables</strong></Link>.</li>
                    <li>Built-in support for <Link to="/angular/interceptors"><strong>interceptors</strong></Link> (e.g., adding headers, logging, error handling).</li>
                    <li>Handles JSON data by default.</li>
                    <li>Automatically maps responses to JavaScript objects.</li>
                    <li>Provides mechanisms for error handling and retry logic.</li>
                </ul>

                <h5 className='mt-5 mb-3'>How to Use HttpClientModule</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li className='mt-5'>
                        <strong>Import the HttpClientModule</strong>
                        <p>Add the <span class="background-grey">HttpClientModule</span> to the imports array in your app's main module or a specific feature module.</p>
                        <p>In <strong>app.module.ts</strong></p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">import</span> { <span class="color-blue">NgModule</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                                <p><span class="color-pink">import</span> { <span class="color-blue">BrowserModule</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/platform-browser'</span>;</p>
                                <p><span class="color-pink">import</span> { <span class="color-blue">HttpClientModule</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/common/http'</span>;</p>
                                <p><span class="color-pink">import</span> { <span class="color-blue">AppComponent</span> } <span class="color-pink">from</span> <span class="color-green">'./app.component'</span>;</p>
                                <br />
                                <p><span class="color-red">@NgModule</span>({</p>
                                <p class="ml-30">    <span class="color-pink">declarations</span>: [<span class="color-blue">AppComponent</span>],</p>
                                <p class="ml-30">    <span class="color-pink">imports</span>: [</p>
                                <p class="ml-60">        <span class="color-blue">BrowserModule</span>,</p>
                                <p class="ml-60">        <span class="color-blue">HttpClientModule</span>, <span class="color-grey">// Import HttpClientModule here</span></p>
                                <p class="ml-30">    ],</p>
                                <p class="ml-30">    <span class="color-pink">bootstrap</span>: [<span class="color-blue">AppComponent</span>],</p>
                                <p>})</p>
                                <p><span class="color-pink">export class</span> <span class="color-blue">AppModule</span> {}</p>
                                `
                            }}></div>
                        </div>
                        <p>Here, in this example -</p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>Angular starts the application by bootstrapping the <span class="background-grey">AppModule</span>.</li>
                            <li>The <strong>AppModule</strong>:
                                <ul style={{listStyle:'disc'}}>
                                    <li>Declares the <span class="background-grey">AppComponent</span> as the main UI component.</li>
                                    <li>Imports necessary modules like <span class="background-grey">BrowserModule</span> (for rendering) and <span class="background-grey">HttpClientModule</span> (for backend communication).</li>
                                </ul>
                            </li>
                            <li>The bootstrap property tells Angular to render the <span class="background-grey">AppComponent</span> in the <span class="background-grey">&lt;app-root&gt;</span> selector defined in the <span class="background-grey">index.html</span> file.</li>
                        </ul>
                    </li>
                    <li className='mt-5'>
                        <strong>Inject HttpClient in a Service</strong>
                        <p>You typically use the HttpClient in a service to manage HTTP requests.</p>
                        <h6>Creating a Data Service</h6>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">import</span> { <span class="color-blue">Injectable</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                                <p><span class="color-pink">import</span> { <span class="color-blue">HttpClient</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/common/http'</span>;</p>
                                <p><span class="color-pink">import</span> { <span class="color-blue">Observable</span> } <span class="color-pink">from</span> <span class="color-green">'rxjs'</span>;</p>
                                <br />
                                <p><span class="color-red">@Injectable</span>({</p>
                                <p class="ml-30">    <span class="color-pink">providedIn</span>: <span class="color-green">'root'</span>,</p>
                                <p>})</p>
                                <p><span class="color-pink">export class</span> <span class="color-blue">DataService</span> {</p>
                                <p class="ml-30">    <span class="color-pink">private</span> apiUrl = <span class="color-green">'https://jsonplaceholder.typicode.com/posts'</span>; <span class="color-grey">// Example API</span></p>
                                <br />
                                <p class="ml-30">    <span class="color-red">constructor</span>(<span class="color-pink">private</span> http: <span class="color-blue">HttpClient</span>) {}</p>
                                <br />
                                <p class="ml-30 color-grey">    // GET request</p>
                                <p class="ml-30">    <span class="color-red">getPosts</span>(): <span class="color-blue">Observable</span><<span class="color-pink">any</span>[]> {</p>
                                <p class="ml-60">        <span class="color-blue">return this</span>.http.<span class="color-red">get</span><<span class="color-pink">any</span>[]>(<span class="color-blue">this</span>.apiUrl);</p>
                                <p class="ml-30">    }</p>
                                <br />
                                <p class="ml-30 color-grey">    // POST request</p>
                                <p class="ml-30">    <span class="color-red">createPost</span>(data: <span class="color-pink">any</span>): <span class="color-blue">Observable</span><<span class="color-pink">any</span>> {</p>
                                <p class="ml-60">        <span class="color-blue">return this</span>.http.<span class="color-red">post</span><<span class="color-pink">any</span>>(<span class="color-blue">this</span>.apiUrl, data);</p>
                                <p class="ml-30">    }</p>
                                <br />
                                <p class="ml-30 color-grey">    // PUT request</p>
                                <p class="ml-30">    <span class="color-red">updatePost</span>(id: <span class="color-pink">number</span>, data: <span class="color-pink">any</span>): <span class="color-blue">Observable</span><<span class="color-pink">any</span>> {</p>
                                <p class="ml-60">        <span class="color-blue">return this</span>.http.<span class="color-red">put</span><<span class="color-pink">any</span>>(<span class="color-green">&#96;$&#123;this.apiUrl&#125;/$&#123;id&#125;&#96;</span>, data);</p>
                                <p class="ml-30">    }</p>
                                <br />
                                <p class="ml-30 color-grey">    // DELETE request</p>
                                <p class="ml-30">    <span class="color-red">deletePost</span>(id: <span class="color-pink">number</span>): Observable<any> {</p>
                                <p class="ml-60">        <span class="color-blue">return this</span>.http.<span class="color-red">delete</span><<span class="color-pink">any</span>>(<span class="color-green">&#96;$&#123;this.apiUrl&#125;/$&#123;id&#125;&#96;</span>);</p>
                                <p class="ml-30">    }</p>
                                <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='mt-5'>
                        <strong>Use the Service in a Component</strong>
                        <h6 className='mt-4'>Using <span class="background-grey">DataService</span> in a Component</h6>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">import</span> { <span class="color-blue">Component</span>, <span class="color-blue">OnInit</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                                <p><span class="color-pink">import</span> {<span class="color-blue"> DataService</span> } <span class="color-pink">from</span> <span class="color-green">'./data.service'</span>;</p>
                                <br />
                                <p><span class="color-red">@Component</span>({</p>
                                <p class="ml-30">    <span class="color-pink">selector</span>: <span class="color-green">'app-posts'</span>,</p>
                                <p class="ml-30">    <span class="color-pink">template</span>: <span class="color-green">&#96;</span></p>
                                <p class="ml-60 color-green">        &lt;div *ngIf="posts"&gt;</p>
                                <p class="ml-90 color-green">            &lt;h2&gt;Posts&lt;/h2&gt;</p>
                                <p class="ml-90 color-green">            &lt;ul&gt;</p>
                                <p class="ml-120 color-green">                &lt;li *ngFor="let post of posts"&gt;{{ post.title }}&lt;/li&gt;</p>
                                <p class="ml-90 color-green">            &lt;/ul&gt;</p>
                                <p class="ml-60 color-green">        &lt;/div&gt;</p>
                                <p class="ml-30 color-green">    &#96;,</p>
                                <p>})</p>
                                <p><span class="color-pink">export class</span> <span class="color-blue">PostsComponent</span> <span class="color-pink">implements</span> <span class="color-blue">OnInit</span> {</p>
                                <p class="ml-30">    posts: <span class="color-pink">any</span>[] = [];</p>
                                <br />
                                <p class="ml-30">    <span class="color-red">constructor</span>(<span class="color-pink">private</span> dataService: <span class="color-blue">DataService</span>) {}</p>
                                <br />
                                <p class="ml-30">    <span class="color-red">ngOnInit</span>(): <span class="color-blue">void</span> {</p>
                                <p class="ml-60 color-grey">        // Fetch posts when the component initializes</p>
                                <p class="ml-60">       <span class="color-blue">this</span>.dataService.<span class="color-red">getPosts</span>().<span class="color-red">subscribe</span>(</p>
                                <p class="ml-90">            (data) => (<span class="color-blue">this</span>.posts = data),</p>
                                <p class="ml-90">            (error) => <span class="color-pink">console</span>.<span class="color-red">error</span>(<span class="color-green">'Error fetching posts:'</span>, error)</p>
                                <p class="ml-60">        );</p>
                                <p class="ml-30">    }</p>
                                <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>


            </div>
        </section>
    )
}