import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/tag/select";

export default function Select() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - <select> Tag | Aspirant's Home");
        const urls = {
            'previous': '/html/tag-list'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - &lt;select&gt; Tag</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">&lt;select&gt;</span> tag in HTML is used to create a dropdown list, allowing users to choose one or more options from a predefined list. It is commonly used within forms to offer a list of choices like countries, categories, or other selection-based data.
                </p>

                <h5 className='mt-5 mb-3'>Basic Structure of the <span class="background-grey">&lt;select&gt;</span> Tag</h5>
                <p>The <span class="background-grey">&lt;select&gt;</span> tag requires an opening and closing tag and contains multiple <span class="background-grey">&lt;option&gt;</span> tags for each choice in the list.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;select <span class="color-pink">name</span>=<span class="color-green">"dropdownName"</span>&gt;</p>
                        <p class="ml-30">    &lt;option <span class="color-pink">value</span>=<span class="color-green">"option1"</span>&gt;Option 1&lt;/option&gt;</p>
                        <p class="ml-30">    &lt;option <span class="color-pink">value</span>=<span class="color-green">"option2"</span>&gt;Option 2&lt;/option&gt;</p>
                        <p class="ml-30">    &lt;option <span class="color-pink">value</span>=<span class="color-green">"option3"</span>&gt;Option 3&lt;/option&gt;</p>
                        <p>&lt;/select&gt;</p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-3'>Key Attributes of <span class="background-grey">&lt;select&gt;</span></h5>
                <ul style={{listStyle:'decimal'}}>
                    <li><span class="background-grey">name</span>: Specifies the name of the dropdown, used to retrieve the selected value when submitting the form.</li>
                    <li><span class="background-grey">id</span>: Used to link the <span class="background-grey">&lt;select&gt;</span> with a <span class="background-grey">&lt;label&gt;</span> or to apply CSS or JavaScript.</li>
                    <li><span class="background-grey">multiple</span>: Allows users to select multiple options by holding down the Ctrl (Windows) or Command (Mac) key while selecting.</li>
                    <li><span class="background-grey">size</span>: Specifies the number of visible options in the list. If the list contains more options than specified by size, a scroll bar appears.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Attributes of <span class="background-grey">&lt;option&gt;</span></h5>
                <p>Each <span class="background-grey">&lt;option&gt;</span> inside the <span class="background-grey">&lt;select&gt;</span> dropdown represents an individual choice:</p>
                <ul style={{listStyle:'decimal'}}>
                    <li><span class="background-grey">value</span>: Defines the data sent to the server when the option is selected.</li>
                    <li><span class="background-grey">selected</span>: Makes the option selected by default.</li>
                    <li><span class="background-grey">disabled</span>: Disables the option, making it unselectable.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Example: Simple Dropdown</h5>
                <p>This example shows a dropdown for selecting a user’s preferred language.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;label <span class="color-pink">for</span>=<span class="color-green">"language"</span>&gt;Choose a language:&lt;/label&gt;</p>
                        <p>&lt;select <span class="color-pink">name</span>=<span class="color-green">"language"</span> <span class="color-pink">id</span>=<span class="color-green">"language"</span>&gt;</p>
                        <p class="ml-30">    &lt;option <span class="color-pink">value</span>=<span class="color-green">"english"</span>&gt;English&lt;/option&gt;</p>
                        <p class="ml-30">    &lt;option <span class="color-pink">value</span>=<span class="color-green">"spanish"</span>&gt;Spanish&lt;/option&gt;</p>
                        <p class="ml-30">    &lt;option <span class="color-pink">value</span>=<span class="color-green">"french"</span>&gt;French&lt;/option&gt;</p>
                        <p class="ml-30">    &lt;option <span class="color-pink">value</span>=<span class="color-green">"german"</span>&gt;German&lt;/option&gt;</p>
                        <p>&lt;/select&gt;</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Example: Dropdown with a Default Selection</h5>
                <p>The <span class="background-grey">selected</span> attribute preselects an option in the dropdown.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;label <span class="color-pink">for</span>=<span class="color-green">"country"</span>&gt;Choose your country:&lt;/label&gt;</p>
                        <p>&lt;select <span class="color-pink">name</span>=<span class="color-green">"country"</span> <span class="color-pink">id</span>=<span class="color-green">"country"</span>&gt;</p>
                        <p class="ml-30">    &lt;option <span class="color-pink">value</span>=<span class="color-green">"us"</span>&gt;United States&lt;/option&gt;</p>
                        <p class="ml-30">    &lt;option <span class="color-pink">value</span>=<span class="color-green">"ca"</span> <span class="color-grey">selected</span>&gt;Canada&lt;/option&gt;</p>
                        <p class="ml-30">    &lt;option <span class="color-pink">value</span>=<span class="color-green">"uk"</span>&gt;United Kingdom&lt;/option&gt;</p>
                        <p class="ml-30">    &lt;option <span class="color-pink">value</span>=<span class="color-green">"au"</span>&gt;Australia&lt;/option&gt;</p>
                        <p>&lt;/select&gt;</p>
                        `
                    }}></div>
                </div>




            </div>
        </section>
    )
}