import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/tag/link";

export default function LinkTag() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - <link> Tag | Aspirant's Home");
        const urls = {
            'previous': '/html/tag-list'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - &lt;link&gt; Tag</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">&lt;link&gt;</span> tag in HTML is used to link an external resource to an HTML document. It’s most commonly used to link CSS stylesheets but can also link other resources, such as icons, preloaded assets, or structured data files. The <span class="background-grey">&lt;link&gt;</span> tag is self-closing and must be placed within the <span class="background-grey">&lt;head&gt;</span> section of the HTML document.
                </p>

                <h5 className='mt-5 mb-3'>Structure and Purpose of <span class="background-grey">&lt;link&gt;</span></h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>Primarily used to link CSS files, icons, and prefetch data.</li>
                    <li>Unlike <span class="background-grey">&lt;a&gt;</span>, it doesn’t create a clickable link on the page.</li>
                    <li>Linking external stylesheets and preloading assets improves page loading and organization.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Syntax of <span class="background-grey">&lt;link&gt;</span></h5>
                <p>The <span class="background-grey">&lt;link&gt;</span> tag requires several attributes to specify the type and purpose of the linked resource:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;link <span class="color-pink">rel</span>=<span class="color-green">"relationship"</span> <span class="color-pink">href</span>=<span class="color-green">"URL"</span> <span class="color-pink">type</span>=<span class="color-green">"MIME_type"</span> /&gt;</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Common <span class="background-grey">&lt;link&gt;</span> Tag Attributes</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <span class="background-grey">rel</span>: Specifies the relationship between the current document and the linked resource.
                        <ul style={{ listStyle: 'disc' }} className='mt-3'>
                            <li>
                                <span class="background-grey">stylesheet</span>: Links to an external CSS file.
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>&lt;link <span class="color-pink">rel</span>=<span class="color-green">"stylesheet"</span> <span class="color-pink">href</span>=<span class="color-green">"styles.css"</span>&gt;</p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <span class="background-grey">icon</span>: Links to a favicon (small icon shown in the browser tab).
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>&lt;link <span class="color-pink">rel</span>=<span class="color-green">"icon"</span> <span class="color-pink">href</span>=<span class="color-green">"favicon.ico"</span> <span class="color-pink">type</span>=<span class="color-green">"image/x-icon"</span>&gt;</p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <span class="background-grey">preload</span>: Instructs the browser to load resources (like fonts) in advance to improve performance.
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>&lt;link <span class="color-pink">rel</span>=<span class="color-green">"preload"</span> <span class="color-pink">href</span>=<span class="color-green">"font.woff2"</span> <span class="color-pink">as</span>=<span class="color-green">"font"</span> <span class="color-pink">type</span>=<span class="color-green">"font/woff2"</span> <span class="color-pink">crossorigin</span>=<span class="color-green">"anonymous"</span>&gt;</p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <span class="background-grey">manifest</span>: Links to a web app manifest file, which provides information about the app (e.g., icons, start URL).
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>&lt;link <span class="color-pink">rel</span>=<span class="color-green">"manifest"</span> <span class="color-pink">href</span>=<span class="color-green">"site.webmanifest"</span>&gt;</p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <span class="background-grey">href</span>: It specifies the URL of the linked resource. For example, the path to a CSS file, favicon, or other resources.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;link <span class="color-pink">rel</span>=<span class="color-green">"stylesheet"</span> <span class="color-pink">href</span>=<span class="color-green">"styles.css"</span>&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <span class="background-grey">type</span>: It defines the MIME type of the linked resource. It is used primarily for stylesheets or structured data.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;link <span class="color-pink">rel</span>=<span class="color-green">"stylesheet"</span> <span class="color-pink">href</span>=<span class="color-green">"styles.css"</span> <span class="color-pink">type</span>=<span class="color-green">"text/css"</span>&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <span class="background-grey">as</span>: Used with <span class="background-grey">rel="preload"</span> to specify the type of content being preloaded (e.g., <span class="background-grey">font</span>, <span class="background-grey">script</span>, <span class="background-grey">image</span>).
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;link <span class="color-pink">rel</span>=<span class="color-green">"preload"</span> <span class="color-pink">href</span>=<span class="color-green">"main.js"</span> <span class="color-pink">as</span>=<span class="color-green">"script"</span>&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <span class="background-grey">crossorigin</span>: Allows cross-origin requests for the linked resource, necessary for fonts and scripts hosted on a different domain.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;link <span class="color-pink">rel</span>=<span class="color-green">"stylesheet"</span> <span class="color-pink">href</span>=<span class="color-green">"https://fonts.googleapis.com/css?family=Roboto"</span> <span class="color-pink">crossorigin</span>=<span class="color-green">"anonymous"</span>&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>

                <p>The <span class="background-grey">&lt;link&gt;</span> tag is an essential part of the HTML document for linking external resources. It adds CSS for styling, icons for branding, and preloads for performance, making it an indispensable tool for efficient web design.</p>

                        


            </div>
        </section>
    )
}