import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/tag/em";

export default function EmTag() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - <em> Tag | Aspirant's Home");
        const urls = {
            'previous': '/html/tag-list'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - &lt;em&gt; Tag</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">&lt;em&gt;</span> tag in HTML is used to add emphasis to text, typically displaying it in italics. Unlike the <span class="background-grey">&lt;i&gt;</span> tag, which only changes the visual style, <span class="background-grey">&lt;em&gt;</span> conveys semantic emphasis, meaning the text it contains is important within its context. This emphasis can aid both screen readers, which may read it with additional stress, and search engines, which may treat the text as significant content.
                </p>

                <h5 className='mt-5 mb-3'>Structure and Purpose of <span class="background-grey">&lt;em&gt;</span> Tag</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span class="background-grey">&lt;em&gt;</span> tag highlights words or phrases that need extra emphasis in the context.</li>
                    <li>It generally give emphasized text a distinct intonation, making the meaning clearer.</li>
                    <li>Search engines may recognize <span class="background-grey">&lt;em&gt;</span> text as relevant or important, contributing to SEO benefits.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <p>The <span class="background-grey">&lt;em&gt;</span> tag wraps around the text you want to emphasize:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;em&gt;Your emphasized text here&lt;/em&gt;</p>
                        `
                    }}></div>
                </div>  

                <h5 className='mt-5 mb-3'>Example Usage of <span class="background-grey">&lt;em&gt;</span> Tag</h5>
                <p>Here’s an example using <span class="background-grey">&lt;em&gt;</span> to emphasize a specific phrase in a sentence:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Emphasized Text Example&lt;/title&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <br />    
                        <p class="ml-60">        &lt;p&gt;If you want results, you must &lt;em&gt;commit fully&lt;/em&gt; to the process.&lt;/p&gt;</p>
                        <br />    
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div> 
                <p>In this example, "commit fully" is emphasized with <span class="background-grey">&lt;em&gt;</span>, rendering it in italics and adding a meaningful emphasis.</p>
                <p>Output will be the following -</p>
                <div className='outputPalateBox mt-2 mb-4'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <!DOCTYPE html>
                        <html lang="en">
                            <head>
                                <meta charset="UTF-8">
                                <title>Emphasized Text Example</title>
                            </head>
                            <body>
                            
                                <p>If you want results, you must <em>commit fully</em> to the process.</p>
                            
                            </body>
                        </html>
                        `
                    }}></div>
                </div>


            </div>
        </section>
    )
}