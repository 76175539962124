import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/jquery/events/event-relatedtarget";

export default function RelatedTarget() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("jQuery event.relatedTarget Object | Aspirant's Home");
        const urls = {
            'previous': '/jquery/events'
        }
        path.setPreviousNext(urls);
        path.setPreviousName('Back to Events');
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>jQuery event.relatedTarget Object</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">event.relatedTarget</span> property in jQuery is used to identify the element that the mouse came from or is moving to during certain mouse events, such as <span class="background-grey">mouseenter</span>, <span class="background-grey">mouseleave</span>, <span class="background-grey">mouseover</span>, and <span class="background-grey">mouseout</span>. It provides a reference to the element that is related to the event's target.
                </p>


                <p>It has a basic syntax -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">var</span> relatedElement = event.relatedTarget;</p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-3'>Example 1: Using <span class="background-grey">mouseenter</span> and <span class="background-grey">mouseleave</span></h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;div <span class="color-pink">id</span>=<span class="color-green">"box1"</span> <span class="color-pink">style</span>=<span class="color-green">"width: 100px; height: 100px; background-color: lightblue;"</span>&gt;Box 1&lt;/div&gt;</p>
                        <p>&lt;div <span class="color-pink">id</span>=<span class="color-green">"box2"</span> <span class="color-pink">style</span>=<span class="color-green">"width: 100px; height: 100px; background-color: lightgreen; margin-top: 20px;"</span>&gt;Box 2&lt;/div&gt;</p>
                        `
                    }}></div>
                </div>
                <h6>jQuery Code</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"div"</span>).<span class="color-red">on</span>(<span class="color-green">"mouseenter mouseleave"</span>, <span class="color-blue">function</span>(event) {</p>
                        <p class="ml-30">    <span class="color-blue">if</span> (event.type === <span class="color-green">"mouseenter"</span>) {</p>
                        <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Mouse entered from:"</span>, event.relatedTarget);</p>
                        <p class="ml-30">    } <span class="color-blue">else if</span> (event.type === <span class="color-green">"mouseleave"</span>) {</p>
                        <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Mouse left to:"</span>, event.relatedTarget);</p>
                        <p class="ml-30">    }</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>When <span class="background-grey">mouseenter</span> is triggered <span class="background-grey">event.relatedTarget</span> gives the element from which the mouse pointer came.</li>
                    <li>When <span class="background-grey">mouseleave</span> is triggered <span class="background-grey">event.relatedTarget</span> gives the element to which the mouse pointer is moving.</li>
                </ul>





            </div>
        </section>
    )
}