import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/jquery/events/event-currenttarget";

export default function CurrentTarget() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("jQuery event.currentTarget Object | Aspirant's Home");
        const urls = {
            'previous': '/jquery/events'
        }
        path.setPreviousNext(urls);
        path.setPreviousName('Back to Events');
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>jQuery event.currentTarget Object</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">event.currentTarget</span> property in jQuery refers to the element on which the event handler is <strong>currently attached</strong>. It is particularly useful in event handling to determine the element that is handling the event, regardless of where the event was initiated.
                </p>

                <p>It has a basic syntax -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(selector).<span class="color-red">on</span>(eventType, <span class="color-blue">function</span>(event) {</p>
                        <p class="ml-30">    <span class="color-blue">let</span> element = event.currentTarget;</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">event.currentTarget</span> is always points to the element where the event handler is attached.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Example : Trigger a Click Event Handler Without Propagation</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;ul <span class="color-pink">id</span>=<span class="color-green">"menu"</span>&gt;</p>
                        <p class="ml-30">    &lt;li&gt;Item 1&lt;/li&gt;</p>
                        <p class="ml-30">    &lt;li&gt;Item 2&lt;/li&gt;</p>
                        <p class="ml-30">    &lt;li&gt;Item 3&lt;/li&gt;</p>
                        <p>&lt;/ul&gt;</p>
                        `
                    }}></div>
                </div>
                <h6>jQuery Code</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"#menu"</span>).<span class="color-red">on</span>(<span class="color-green">"click"</span>, <span class="color-green">"li"</span>, <span class="color-blue">function</span>(event) {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Handler attached to:"</span>, event.currentTarget); <span class="color-grey">// Logs #menu</span></p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Clicked item:"</span>, event.target); <span class="color-grey">// Logs the clicked &lt;li&gt;</span></p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">event.currentTarget</span> refers to #menu, the element where the event handler is attached.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Example : Using <span class="background-grey">event.currentTarget</span> to Modify Handler Element</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;ul&gt;</p>
                        <p class="ml-30">    &lt;li <span class="color-pink">class</span>=<span class="color-green">"item"</span>&gt;Item 1&lt;/li&gt;</p>
                        <p class="ml-30">    &lt;li <span class="color-pink">class</span>=<span class="color-green">"item"</span>&gt;Item 2&lt;/li&gt;</p>
                        <p class="ml-30">    &lt;li <span class="color-pink">class</span>=<span class="color-green">"item"</span>&gt;Item 3&lt;/li&gt;</p>
                        <p>&lt;/ul&gt;</p>
                        `
                    }}></div>
                </div>
                <h6>jQuery Code</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">".item"</span>).<span class="color-red">on</span>(<span class="color-green">"click"</span>, <span class="color-blue">function</span>(event) {</p>
                        <p class="ml-30">    $(event.currentTarget).<span class="color-red">css</span>(<span class="color-green">"color"</span>, <span class="color-green">"red"</span>);</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>Clicking on any &lt;li&gt; changes its text color to red.</li>
                    <li><span class="background-grey">event.currentTarget</span> ensures only the handler element is modified, not the actual clicked child if nested elements are present.</li>
                </ul>




            </div>
        </section>
    )
}