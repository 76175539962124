import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/function-definition";

export default function FunctionDefinition() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Function Definition in JavaScript | Aspirant's Home");
        const urls = {
            'previous': '/javascript/object-protection',
            'next': '/javascript/function-parameters'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Function Definition</h3>
            <div className='mt-4 mb-5'>
                <p>A <strong>function</strong> in JavaScript is a <strong>block of reusable code</strong> designed to perform a specific task.</p>
                <ul style={{listStyle: 'disc'}}>
                    <li>You can <strong>call</strong> (or invoke) the function whenever you need it, instead of writing the same code multiple times.</li>
                    <li>Functions help make your code organized, reusable, and easy to understand.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Why Use Functions?</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li><strong>Reusability</strong>: Write code once and use it many times.</li>
                    <li><strong>Modularity</strong>: Break your program into smaller, manageable pieces.</li>
                    <li><strong>Clean Code</strong>: Avoid repeating the same code (also called <strong>DRY</strong> - Don't Repeat Yourself).</li>
                    <li><strong>Maintainability</strong>: Make changes easily without affecting other parts of your code.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Defining a Function</h5>
                <p>You can define a function in JavaScript using the <span className='background-grey'>function</span> keyword.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">function</span> <span class="color-red">functionName</span>(parameters) {</p>
                        <p class="ml-30 color-grey">    // Code to execute</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span className='background-grey'>functionName</span>: The name of the function.</li>
                    <li><span className='background-grey'>parameters</span>: Optional inputs to the function. (You can pass data to a function.)</li>
                </ul>

                <h5 className='mt-5 mb-3'>Simple Function</h5>
                <p>Here’s a function that prints a greeting message:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">function</span> <span class="color-red">greet</span>() {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Hello, welcome to JavaScript!"</span>);</p>
                        <p>}</p>
                        <br />
                        <p class="color-grey">// Calling the function</p>
                        <p><span class="color-red">greet</span>();  <span class="color-grey">// Output: Hello, welcome to JavaScript!</span></p>
                        `
                    }}></div>
                </div>



            </div>
        </section>
    )
}