import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/events/oninput";

export default function Oninput() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - oninput Event | Aspirant's Home");
        const urls = {
            'previous': '/html/events'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - oninput Event</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">oninput</span> event in HTML is triggered whenever the value of an element changes, such as when the user types in a text field, changes a slider position, or updates a textarea. Unlike the onchange event, oninput fires immediately with each change, making it ideal for real-time feedback.
                </p>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <p>To use <span class="background-grey">oninput</span>, add it as an attribute to an HTML element, with JavaScript code or a function to run every time the element’s value changes.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">oninput</span>=<span class="color-green">"alert('Text input changed!')"</span> <span class="color-pink">placeholder</span>=<span class="color-green">"Type something"</span>&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example, An alert appears every time the text in the input changes.</p>


                <h5 className='mt-5 mb-3'>Example: Real-Time Character Counter</h5>
                <p>With <span class="background-grey">oninput</span>, you can show a live character count as a user types in a text field.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;textarea <span class="color-pink">id</span>=<span class="color-green">"message"</span> <span class="color-pink">oninput</span>=<span class="color-green">"countCharacters()"</span> <span class="color-pink">placeholder</span>=<span class="color-green">"Type your message here"</span>&gt;&lt;/textarea&gt;</p>
                        <p>&lt;p <span class="color-pink">id</span>=<span class="color-green">"charCount"</span>&gt;0 characters&lt;/p&gt;</p>
                        <br />
                        <p>&lt;script&gt;</p>
                        <p class="ml-30">    <span class="color-blue">function</span> <span class="color-red">countCharacters</span>() {</p>
                        <p class="ml-60">        <span class="color-blue">const</span> message = document.<span class="color-red">getElementById</span>(<span class="color-green">"message"</span>).value;</p>
                        <p class="ml-60">        document.<span class="color-red">getElementById</span>(<span class="color-green">"charCount"</span>).textContent = message.length + <span class="color-green">" characters"</span>;</p>
                        <p class="ml-30">    }</p>
                        <p>&lt;/script&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example, The <span class="background-grey">countCharacters</span> function updates the character count in real-time as the user types.</p>


            </div>
        </section>
    )
}