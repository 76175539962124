import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/events/onmouseover";

export default function Onmouseover() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - onmouseover Event | Aspirant's Home");
        const urls = {
            'previous': '/html/events'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - onmouseover Event</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">onmouseover</span> event in HTML is triggered when a user moves the mouse pointer over an HTML element. This event is useful for creating interactive effects, such as displaying tooltips, highlighting elements, showing additional content, or starting animations when a user hovers over an element.
                </p>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <p>To use <span class="background-grey">onmouseover</span>, add it as an attribute to an HTML element and specify the JavaScript code or function that should execute when the mouse pointer hovers over that element.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;p <span class="color-pink">onmouseover</span>=<span class="color-green">"alert('Mouse is over this text!')"</span>&gt;Hover over this text&lt;/p&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example, when the user hovers over the paragraph, an alert message displays "Mouse is over this text!"</p>


                <h5 className='mt-5 mb-3'>Example: Changing Styles with <span class="background-grey">onmouseover</span></h5>
                <p><span class="background-grey">onmouseover</span> is commonly used to change an element’s style, providing visual feedback when the user hovers over it.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;style&gt;</p>
                        <p class="ml-30">    .highlight {</p>
                        <p class="ml-60">        <span class="color-green">background-color</span>: lightyellow;</p>
                        <p class="ml-60">        <span class="color-green">color</span>: darkblue;</p>
                        <p class="ml-30">    }</p>
                        <p>&lt;/style&gt;</p>
                        <br />
                        <p>&lt;p <span class="color-pink">onmouseover</span>=<span class="color-green">"this.classList.add('highlight')"</span> <span class="color-pink">onmouseout</span>=<span class="color-green">"this.classList.remove('highlight')"</span>&gt;</p>
                        <p class="ml-30">    Hover over me to change my style</p>
                        <p>&lt;/p&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>When the mouse hovers over the paragraph, the <span class="background-grey">highlight</span> class is added, changing the background and text color.</li>
                    <li>When the mouse leaves (<span class="background-grey">onmouseout</span>), the <span class="background-grey">highlight</span> class is removed, restoring the original style.</li>
                </ul>
                <p>Output will be the following -</p>
                <div className='outputPalateBox mt-2 mb-4'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <style>
                            .highlight {
                                background-color: lightyellow;
                                color: darkblue;
                            }
                        </style>
                        
                        <p onmouseover="this.classList.add('highlight')" onmouseout="this.classList.remove('highlight')">
                            Hover over me to change my style
                        </p>
                        `
                    }}></div>
                </div>



            </div>
        </section>
    )
}