import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/events";

export default function HTMLEvents() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - Events | Aspirant's Home");
        const urls = {
            'previous': '/html/web-storage',
            'next': '/html/tag-list'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - Events</h3>
            <div className='mt-4 mb-5'>
                <p>
                    HTML Events are actions or occurrences that happen in the browser while a user interacts with a web page. Like <strong>clicking a button</strong>, <strong>loading a page</strong>, <strong>hovering over an element</strong>, and more. Events allow HTML elements to "react" to user actions, enabling interactivity on websites by triggering JavaScript functions or other responses.
                </p>
                <h5 className='mt-5 mb-3'>Types of HTML Events</h5>
                <p>HTML events can be categorized based on the type of interaction, such as mouse events, keyboard events, form events, and document events.</p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li className='mb-4'>
                        <h6>Mouse Events</h6>
                        <ul style={{ listStyle: 'disc' }}>
                            <li><Link to="/html/events/onclick" class="noDecor"><span class="background-grey">onclick</span></Link> : Triggered when an element is clicked.</li>
                            <li><Link to="/html/events/ondblclick" class="noDecor"><span class="background-grey">ondblclick</span></Link> : Triggered when an element is double-clicked.</li>
                            <li><Link to="/html/events/onmousedown" class="noDecor"><span class="background-grey">onmousedown</span></Link> : Triggered when a mouse button is pressed down over an element.</li>
                            <li><Link to="/html/events/onmouseup" class="noDecor"><span class="background-grey">onmouseup</span></Link> : Triggered when a mouse button is released over an element.</li>
                            <li><Link to="/html/events/onmouseover" class="noDecor"><span class="background-grey">onmouseover</span></Link> : Triggered when the mouse pointer moves over an element.</li>
                            <li><Link to="/html/events/onmouseout" class="noDecor"><span class="background-grey">onmouseout</span></Link> : Triggered when the mouse pointer moves out of an element.</li>
                            <li><Link to="/html/events/onmousemove" class="noDecor"><span class="background-grey">onmousemove</span></Link> : Triggered when the mouse pointer moves within an element.</li>
                        </ul>
                    </li>
                    <li className='mb-4'>
                        <h6>Keyboard Events</h6>
                        <ul style={{ listStyle: 'disc' }}>
                            <li><Link to="/html/events/onkeydown" class="noDecor"><span class="background-grey">onkeydown</span></Link> : Triggered when a key is pressed down.</li>
                            <li><Link to="/html/events/onkeypress" class="noDecor"><span class="background-grey">onkeypress</span></Link> : Triggered when a key is pressed (deprecated; onkeydown and onkeyup are recommended).</li>
                            <li><Link to="/html/events/onkeyup" class="noDecor"><span class="background-grey">onkeyup</span></Link> : Triggered when a key is released.</li>
                        </ul>
                    </li>
                    <li className='mb-4'>
                        <h6>Form Events</h6>
                        <ul style={{ listStyle: 'disc' }}>
                            <li><Link to="/html/events/onsubmit" class="noDecor"><span class="background-grey">onsubmit</span></Link> : Triggered when a form is submitted.</li>
                            <li><Link to="/html/events/onchange" class="noDecor"><span class="background-grey">onchange</span></Link> : Triggered when the value of an input, select, or textarea changes.</li>
                            <li><Link to="/html/events/onfocus" class="noDecor"><span class="background-grey">onfocus</span></Link> : Triggered when an element (like an input) gains focus.</li>
                            <li><Link to="/html/events/onblur" class="noDecor"><span class="background-grey">onblur</span></Link> : Triggered when an element loses focus.</li>
                            <li><Link to="/html/events/oninput" class="noDecor"><span class="background-grey">oninput</span></Link> : Triggered when a user inputs text in an input or textarea.</li>
                        </ul>
                    </li>
                    <li className='mb-4'>
                        <h6>Window and Document Events</h6>
                        <ul style={{ listStyle: 'disc' }}>
                            <li><Link to="/html/events/onload" class="noDecor"><span class="background-grey">onload</span></Link> : Triggered when the page or an image has fully loaded.</li>
                            <li><Link to="/html/events/onunload" class="noDecor"><span class="background-grey">onunload</span></Link> : Triggered when the page is closed or navigated away from.</li>
                            <li><Link to="/html/events/onresize" class="noDecor"><span class="background-grey">onresize</span></Link> : Triggered when the browser window is resized.</li>
                            <li><Link to="/html/events/onscroll" class="noDecor"><span class="background-grey">onscroll</span></Link> : Triggered when the user scrolls on an element.</li>
                        </ul>
                    </li>
                    <li className='mb-4'>
                        <h6>Clipboard Events</h6>
                        <ul style={{ listStyle: 'disc' }}>
                            <li><Link to="/html/events/oncopy" class="noDecor"><span class="background-grey">oncopy</span></Link> : Triggered when content is copied.</li>
                            <li><Link to="/html/events/oncut" class="noDecor"><span class="background-grey">oncut</span></Link> : Triggered when content is cut.</li>
                            <li><Link to="/html/events/onpaste" class="noDecor"><span class="background-grey">onpaste</span></Link> : Triggered when content is pasted.</li>
                        </ul>
                    </li>
                    <li className='mb-4'>
                        <h6>Media Events</h6>
                        <ul style={{ listStyle: 'disc' }}>
                            <li><Link to="/html/events/onplay" class="noDecor"><span class="background-grey">onplay</span></Link> : Triggered when media (audio/video) starts playing.</li>
                            <li><Link to="/html/events/onpause" class="noDecor"><span class="background-grey">onpause</span></Link> : Triggered when media is paused.</li>
                            <li><Link to="/html/events/onvolumechange" class="noDecor"><span class="background-grey">onvolumechange</span></Link> : Triggered when the volume of the media is changed.</li>
                            <li><Link to="/html/events/onended" class="noDecor"><span class="background-grey">onended</span></Link> : Triggered when media playback reaches the end</li>
                        </ul>
                    </li>
                    <li className='mb-4'>
                        <h6>Drag Events</h6>
                        <ul style={{ listStyle: 'disc' }}>
                            <li><Link to="/html/events/ondrag" class="noDecor"><span class="background-grey">ondrag</span></Link> : Triggered when an element is being dragged.</li>
                            <li><Link to="/html/events/ondrop" class="noDecor"><span class="background-grey">ondrop</span></Link> : Triggered when the dragged element is dropped on a target.</li>
                            <li><Link to="/html/events/ondragover" class="noDecor"><span class="background-grey">ondragover</span></Link> : Triggered when an element is dragged over a valid drop target.</li>
                        </ul>
                    </li>
                    <li className='mb-4'>
                        <h6>Touch Events</h6>
                        <ul style={{ listStyle: 'disc' }}>
                            <li><Link to="/html/events/ontouchstart" class="noDecor"><span class="background-grey">ontouchstart</span></Link> : Triggered when a touch event starts.</li>
                            <li><Link to="/html/events/ontouchmove" class="noDecor"><span class="background-grey">ontouchmove</span></Link> : Triggered when the touch moves across the screen.</li>
                            <li><Link to="/html/events/ontouchend" class="noDecor"><span class="background-grey">ontouchend</span></Link> : Triggered when the touch is lifted from the screen.</li>
                        </ul>
                    </li>
                </ul>




            </div>
        </section>
    )
}