import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Outlet, Link } from "react-router-dom";
export const pageUrl = () => "/angular/state-management";

export default function StateManagement() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("State Management in Angular | Aspirant's Home");
        const urls = {
            'previous': '/angular/pure-vs-impure-pipes',
            'next': '/angular/rxjs-observables'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>What is State in Angular?</h3>
            <div className='mt-4 mb-5'>
                <p>State is the data your app uses. Examples:</p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>A shopping cart in an online store.</li>
                    <li>Logged-in user's information.</li>
                    <li>A list of products displayed on a page.</li>
                </ul>
                <p>State can be:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>Local State</strong>: Specific to one component (e.g., a form value or a counter).</li>
                    <li><strong>Shared State</strong>: Shared across multiple components (e.g., a cart shared between product and checkout pages).</li>
                </ul>


                <h5 className='mt-5 mb-3'>Why Do We Need State Management?</h5>
                <p>Suppose you’re building an app:</p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>A user adds a product to the cart.</li>
                    <li>The cart count in the header needs to update.</li>
                    <li>The checkout page also needs this updated cart data.</li>
                </ul>
                <p>Without proper state management:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>You’ll write <strong>duplicate code</strong>.</li>
                    <li>Sharing the state becomes messy.</li>
                    <li>Bugs and inconsistencies will occur.</li>
                </ul>
                <p>State management helps keep your app organized and reliable.</p>


                <h5 className='mt-5 mb-3'>Basic Ways to Manage State in Angular</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>Local Component State</strong>
                        <p>Each component has its own data and logic.</p>
                        <p>Example:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-red">@Component</span>({</p>
                                <p class="ml-30">    <span class="color-pink">selector</span>: <span class="color-green">'app-counter'</span>,</p>
                                <p class="ml-30">    <span class="color-pink">template</span>: <span class="color-green">&#96;</span></p>
                                <p class="ml-60 color-green">        &lt;h2&gt;Counter: {{ count }}&lt;/h2&gt;</p>
                                <p class="ml-60 color-green">        &lt;button (click)="increment()"&gt;Increment&lt;/button&gt;</p>
                                <p class="ml-30 color-green">    &#96;,</p>
                                <p>})</p>
                                <p><span class="color-pink">export class</span> <span class="color-blue">CounterComponent</span> {</p>
                                <p class="ml-30">    count = <span class="color-pink">0</span>; <span class="color-grey">// Local state</span></p>
                                <br />  
                                <p class="ml-30">    <span class="color-red">increment</span>() {</p>
                                <p class="ml-60">       <span class="color-blue">this</span>.count<span class="color-pink">++</span>;</p>
                                <p class="ml-30">    }</p>
                                <p>}</p>
                                `
                            }}></div>
                        </div>
                        <p>It is good for simple apps. But it doesn’t work if you need to share data across components.</p>
                    </li>
                    <li>
                        <strong>Services for Shared State</strong>
                        <p>Services are like a <strong>central place</strong> to store and share data.</p>
                        <p>Example:</p>
                        <p>Let’s create a <strong>cart service</strong> to manage shopping cart data.</p>

                        <h6 className='mt-3'>Step 1: Create a Service</h6>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">import</span> { <span class="color-blue">Injectable</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                                <br />
                                <p><span class="color-red">@Injectable</span>({</p>
                                <p class="ml-30">    <span class="color-pink">providedIn</span>: <span class="color-green">'root'</span>,</p>
                                <p>})</p>
                                <p><span class="color-pink">export class</span> <span class="color-blue">CartService</span> {</p>
                                <p class="ml-30">    <span class="color-pink">private</span> cart: <span class="color-blue">string</span>[] = []; <span class="color-grey">// Shared state</span></p>
                                <br />
                                <p class="ml-30">    <span class="color-red">addItem</span>(item: <span class="color-blue">string</span>) {</p>
                                <p class="ml-60">        <span class="color-blue">this</span>.cart.<span class="color-red">push</span>(item);</p>
                                <p class="ml-30">    }</p>
                                <br />
                                <p class="ml-30">    <span class="color-red">getItems</span>() {</p>
                                <p class="ml-60">        <span class="color-pink">return</span> <span class="color-blue">this</span>.cart;</p>
                                <p class="ml-30">    }</p>
                                <p>}</p>
                                `
                            }}></div>
                        </div>
                        <h6 className='mt-3'>Step 2: Use the Service in Components</h6>
                        <p>In the <strong>Product Component</strong> (to add items):</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">import</span> { <span class="color-blue">Component</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                                <p><span class="color-pink">import</span> { <span class="color-blue">CartService</span> } <span class="color-pink">from</span> <span class="color-green">'./cart.service'</span>;</p>
                                <br />
                                <p><span class="color-red">@Component</span>({</p>
                                <p class="ml-30">    <span class="color-pink">selector</span>: <span class="color-green">'app-product'</span>,</p>
                                <p class="ml-30">    <span class="color-pink">template</span>: <span class="color-green">&#96;&lt;button (click)="addToCart()"&gt;Add to Cart&lt;/button&gt;&#96;</span>,</p>
                                <p>})</p>
                                <p><span class="color-pink">export class</span> <span class="color-blue">ProductComponent</span> {</p>
                                <p class="ml-30">    <span class="color-red">constructor</span>(<span class="color-pink">private</span> cartService: <span class="color-blue">CartService</span>) {}</p>
                                <br />
                                <p class="ml-30">    <span class="color-red">addToCart</span>() {</p>
                                <p class="ml-60">        <span class="color-blue">this</span>.cartService.<span class="color-red">addItem</span>(<span class="color-green">'Product 1'</span>);</p>
                                <p class="ml-30">    }</p>
                                <p>}</p>
                                `
                            }}></div>
                        </div>
                        <p>In the <strong>Cart Component</strong> (to display items):</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">import</span> { <span class="color-blue">Component</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                                <p><span class="color-pink">import</span> { <span class="color-blue">CartService</span> } <span class="color-pink">from</span> <span class="color-green">'./cart.service'</span>;</p>
                                <br />
                                <p><span class="color-red">@Component</span>({</p>
                                <p class="ml-30">    <span class="color-pink">selector</span>: <span class="color-green">'app-cart'</span>,</p>
                                <p class="ml-30">    <span class="color-pink">template</span>: <span class="color-green">&#96;</span></p>
                                <p class="ml-60 color-green">        &lt;h2&gt;Cart Items:&lt;/h2&gt;</p>
                                <p class="ml-60 color-green">        &lt;ul&gt;</p>
                                <p class="ml-90 color-green">            &lt;li *ngFor="let item of cartItems"&gt;{{ item }}&lt;/li&gt;</p>
                                <p class="ml-60 color-green">        &lt;/ul&gt;</p>
                                <p class="ml-30 color-green">    &#96;,</p>
                                <p>})</p>
                                <p><span class="color-pink">export class</span> <span class="color-blue">CartComponent</span> {</p>
                                <p class="ml-30">    cartItems: <span class="color-blue">string</span>[] = [];</p>
                                <br />
                                <p class="ml-30">    <span class="color-red">constructor</span>(<span class="color-pink">private</span> cartService: <span class="color-blue">CartService</span>) {</p>
                                <p class="ml-60">        <span class="color-blue">this</span>.cartItems = <span class="color-blue">this</span>.cartService.<span class="color-red">getItems</span>();</p>
                                <p class="ml-30">    }</p>
                                <p>}</p>
                                `
                            }}></div>
                        </div>
                        <p>Now the <strong>cart data is shared</strong> between the Product and Cart components.</p>



                    </li>
                </ul>


            </div>
        </section>
    )
}