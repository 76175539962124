import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/events/ondragover";

export default function Ondragover() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - ondragover Event | Aspirant's Home");
        const urls = {
            'previous': '/html/events'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - ondragover Event</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">ondragover</span> event in HTML is triggered when an element or its descendants are being dragged over a valid drop target. This event is essential in the <strong>Drag and Drop API</strong>, as it allows the browser to recognize that the element being dragged can be dropped onto the target element. The <span class="background-grey">ondragover</span> event does not occur until the browser is explicitly told that the drop is allowed, usually by calling <span class="background-grey">event.preventDefault()</span> within the event handler.
                </p>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <p>You can use the <span class="background-grey">ondragover</span> event in the HTML element that you want to act as the drop target:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;div <span class="color-pink">id</span>=<span class="color-green">"dropTarget"</span> <span class="color-pink">ondragover</span>=<span class="color-green">"allowDrop(event)"</span>&gt;</p>
                        <p class="ml-30">    Drag something here!</p>
                        <p>&lt;/div&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Or you can attach it with JavaScript:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>document.<span class="color-red">getElementById</span>(<span class="color-green">"dropTarget"</span>).ondragover = <span class="color-blue">function</span>(event) {</p>
                        <p class="ml-30">    event.<span class="color-red">preventDefault</span>(); <span class="color-grey">// Allow drop</span></p>
                        <p>};</p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-3'>Example: Visual Feedback During Dragging</h5>
                <p>You can also provide visual feedback while dragging by using the <span class="background-grey">ondragover</span> event to change the appearance of the drop target.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;div <span class="color-pink">id</span>=<span class="color-green">"dragElement"</span> <span class="color-pink">ondrag</span>=<span class="color-green">"dragStart(event)"</span> <span class="color-pink">draggable</span>=<span class="color-green">"true"</span>&gt;</p>
                        <p class="ml-30">    Drag me!</p>
                        <p>&lt;/div&gt;</p>
                        <br />
                        <p>&lt;div <span class="color-pink">id</span>=<span class="color-green">"dropTarget"</span> <span class="color-pink">ondragover</span>=<span class="color-green">"changeAppearance(event)"</span> <span class="color-pink">ondrop</span>=<span class="color-green">"dropElement(event)"</span>&gt;</p>
                        <p class="ml-30">    Drop here!</p>
                        <p>&lt;/div&gt;</p>
                        <br />
                        <p>&lt;script&gt;</p>
                        <p class="ml-30">    <span class="color-blue">function</span> <span class="color-red">changeAppearance</span>(event) {</p>
                        <p class="ml-60">        event.<span class="color-red">preventDefault</span>();</p>
                        <p class="ml-60">        document.<span class="color-red">getElementById</span>(<span class="color-green">"dropTarget"</span>).style.backgroundColor = <span class="color-green">"lightgreen"</span>; <span class="color-grey">// Change background during drag</span></p>
                        <p class="ml-30">    }</p>
                        <br />
                        <p class="ml-30">    <span class="color-blue">function</span> <span class="color-red">dropElement</span>(event) {</p>
                        <p class="ml-60">        event.<span class="color-red">preventDefault</span>();</p>
                        <p class="ml-60">        document.<span class="color-red">getElementById</span>(<span class="color-green">"dropTarget"</span>).style.backgroundColor = <span class="color-green">"lightgrey"</span>; <span class="color-grey">// Reset background</span></p>
                        <p class="ml-60">        document.<span class="color-red">getElementById</span>(<span class="color-green">"dropTarget"</span>).innerHTML = <span class="color-green">"Element Dropped!"</span>;</p>
                        <p class="ml-30">    }</p>
                        <br />
                        <p class="ml-30">    <span class="color-blue">function</span> <span class="color-red">dragStart</span>(event) {</p>
                        <p class="ml-60">        event.dataTransfer.<span class="color-red">setData</span>(<span class="color-green">"text"</span>, event.target.id);</p>
                        <p class="ml-30">    }</p>
                        <p>&lt;/script&gt;</p>
                        `
                    }}></div>
                </div>

                <p>Here, <span class="background-grey">changeAppearance(event)</span> function changes the background color of the drop target to indicate that the element can be dropped there. The background reverts after the drop.</p>


            </div>
        </section>
    )
}