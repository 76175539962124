import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/object-protection";

export default function ObjectProtection() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Object Protection in JavaScript | Aspirant's Home");
        const urls = {
            'previous': '/javascript/object-getset',
            'next': '/javascript/function-definition'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Object Protection</h3>
            <div className='mt-4 mb-5'>
                <p>Object protection in JavaScript refers to controlling how objects and their properties can be accessed, modified, or deleted. This ensures the integrity of your objects and prevents unwanted changes.</p>

                <p>JavaScript provides several features to protect objects, such as:</p>
                <ul style={{listStyle: 'decimal'}}>
                    <li>Making properties read-only.</li>
                    <li>Preventing new properties from being added.</li>
                    <li>Preventing existing properties from being deleted or reconfigured.</li>
                    <li>Freezing an object to make it completely immutable.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Levels of Object Protection</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li><strong>Writable</strong>: Controls whether a property can be updated.</li>
                    <li><strong>Configurable</strong>: Controls whether a property can be deleted or reconfigured.</li>
                    <li><strong>Extensible</strong>: Controls whether new properties can be added to the object.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Techniques for Object Protection</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <span className='background-grey'>Object.seal()</span>
                        <ul style={{ listStyle: 'disc' }}>
                            <li><strong>Prevents adding or removing properties</strong> from an object.</li>
                            <li>Existing properties remain writable but cannot be reconfigured.</li>
                        </ul>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> car = {</p>
                                <p class="ml-30">    <span class="color-pink">brand</span>: <span class="color-green">"Toyota"</span>,</p>
                                <p class="ml-30">    <span class="color-pink">color</span>: <span class="color-green">"Red"</span></p>
                                <p>};</p>
                                <br />
                                <p>Object.<span class="color-red">seal</span>(car);<p>
                                <br />
                                <p class="color-grey">// Attempting to add a new property</p>
                                <p>car.model = <span class="color-green">"Corolla"</span>;  <span class="color-grey">// Ignored (no error in non-strict mode)</span></p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(car.model); <span class="color-grey">// Output: undefined</span></p>
                                <br />
                                <p class="color-grey">// Attempting to delete a property</p>
                                <p><span class="color-pink">delete</span> car.color;      <span class="color-grey"> // False (property not deleted)</span></p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(car.color); <span class="color-grey">// Output: Red</span></p>
                                <br />
                                <p class="color-grey">// Updating existing property is allowed</p>
                                <p>car.color = <span class="color-green">"Blue"</span>;</p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(car.color); <span class="color-grey">// Output: Blue</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <span className='background-grey'>Object.freeze()</span>
                        <p> It's completely locks the object, for this reason</p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>No new properties can be added.</li>
                            <li>Existing properties cannot be updated, reconfigured, or deleted.</li>
                        </ul>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> person = {</p>
                                <p class="ml-30">    <span class="color-pink">name</span>: <span class="color-green">"Alice"</span>,</p>
                                <p class="ml-30">    <span class="color-pink">age</span>: <span class="color-pink">25</span></p>
                                <p>};</p>
                                <br />
                                <p>Object.<span class="color-red">freeze</span>(person);</p>
                                <br />
                                <p class="color-grey">// Attempting to modify the object</p>
                                <p>person.age = <span class="color-pink">30</span>;      <span class="color-grey"> // Ignored (no error in non-strict mode)</span></p>
                                <p>person.city = <span class="color-green">"Paris"</span>; <span class="color-grey">// Ignored</span></p>
                                <p><span class="color-pink">delete</span> person.name;   <span class="color-grey"> // Ignored</span></p>
                                <br />
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(person);   <span class="color-grey">// Output: { name: "Alice", age: 25 }</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <span className='background-grey'>Object.preventExtensions()</span>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>It <strong>prevents new properties</strong> from being added to the object.</li>
                            <li>Existing properties can still be modified or deleted.</li>
                        </ul>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> book = {</p>
                                <p class="ml-30">    <span class="color-pink">title</span>: <span class="color-green">"JavaScript Basics"</span></p>
                                <p>};</p>
                                <br />
                                <p>Object.<span class="color-red">preventExtensions</span>(book);</p>
                                <br />
                                <p class="color-grey">// Attempting to add a new property</p>
                                <p>book.author = <span class="color-green">"John"</span>; <span class="color-grey">// Ignored</span></p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(book.author); <span class="color-grey">// Output: undefined</span></p>
                                <br />
                                <p class="color-grey">// Modifying existing property</p>
                                <p>book.title = <span class="color-green">"Advanced JavaScript"</span>;</p>
                                <p><span class="color-pink">console</span>.<span class="color-re">log</span>(book.title); <span class="color-grey">// Output: Advanced JavaScript</span></p>
                                <br />
                                <p class="color-grey">// Deleting a property</p>
                                <p><span class="color-pink">delete</span> book.title;</p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(book.title); <span class="color-grey">// Output: undefined</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>


                <h5 className='mt-5 mb-3'>Checking Object Protection Status</h5>
                <p>JavaScript provides methods to check the protection level of an object:</p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <span className='background-grey'>Object.isSealed()</span>
                        <p>It checks if an object is sealed.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">console</span>.<span class="color-red">log</span>(Object.<span class="color-red">isSealed</span>(car)); <span class="color-grey">// Output: true (if &#96;Object.seal()&#96; was used)</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <span className='background-grey'>Object.isFrozen()</span>
                        <p>It checks if an object is frozen.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">console</span>.<span class="color-red">log</span>(Object.<span class="color-red">isFrozen</span>(person)); <span class="color-grey">// Output: true (if &#96;Object.freeze()&#96; was used)</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <span className='background-grey'>Object.isExtensible()</span>
                        <p>It checks if an object can be extended (if new properties can be added).</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">console</span>.<span class="color-red">log</span>(Object.<span class="color-red">isExtensible</span>(book)); <span class="color-grey">// Output: false (if &#96;Object.preventExtensions()&#96; was used)</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>
            

            </div>
        </section>
    )
}