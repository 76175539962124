import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/function-bind";

export default function FunctionBind() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Function Bind in JavaScript | Aspirant's Home");
        const urls = {
            'previous': '/javascript/function-apply',
            'next': '/javascript/function-closures'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>What is bind() in JavaScript?</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className='background-grey'>bind()</span> <strong>method</strong> in JavaScript creates a <strong>new function</strong> with a specific value of <span className='background-grey'>this</span> and optional default arguments. Unlike <span className='background-grey'>call()</span> or <span className='background-grey'>apply()</span>, <span className='background-grey'>bind()</span> <strong>does not immediately execute</strong> the function. Instead, it returns a new function that you can invoke later.
                </p>

                <h5 className='mt-5 mb-3'>Why Use <span className='background-grey'>bind()</span>?</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>To create a new function with a fixed <span className='background-grey'>this</span> value.</li>
                    <li>To set default arguments for a function (partial application).</li>
                    <li>To ensure that a function uses the correct <span className='background-grey'>this</span>, especially when passing it as a callback.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Syntax</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">let</span> newFunction = originalFunction.<span class="color-red">bind</span>(thisArg, arg1, arg2, ...);</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span className='background-grey'>originalFunction</span>: The function you want to bind.</li>
                    <li><span className='background-grey'>thisArg</span>: The value you want <span className='background-grey'>this</span> to refer to in the new function.</li>
                    <li><span className='background-grey'>arg1, arg2, ...</span>: (Optional) Default arguments for the new function.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Example 1: Binding <span className='background-grey'>this</span> to an Object</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> person = {</p>
                        <p class="ml-30">    <span class="color-pink">name</span>: <span class="color-green">"Alice"</span>,</p>
                        <p class="ml-30">    <span class="color-pink">greet</span>: <span class="color-red">function</span>() {</p>
                        <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Hello, "</span> + <span class="color-blue">this</span>.name + <span class="color-green">"!"</span>);</p>
                        <p class="ml-30">    }</p>
                        <p>};</p>
                        <p><span class="color-blue">let</span> greetFunction = person.greet.<span class="color-red">bind</span>(person);</p>
                        <p class="color-grey">// Call the bound function</p>
                        <p><span class="color-pink">greetFunction</span>(); <span class="color-grey">// Output: Hello, Alice!</span></p>
                        `
                    }}></div>
                </div>
                <p>Here, in this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span className='background-grey'>person.greet</span> is bound to the <span className='background-grey'>person</span> object using <span className='background-grey'>bind()</span>.</li>
                    <li>Even if you call <span className='background-grey'>greetFunction</span> outside of <span className='background-grey'>person</span>, <span className='background-grey'>this</span> still refers to <span className='background-grey'>person</span>.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Example 2: Passing a Function as a Callback</h5>
                <p>When passing a method as a callback, <span className='background-grey'>this</span> can lose its context. Use <span className='background-grey'>bind()</span> to fix it.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> person = {</p>
                        <p class="ml-30">    <span class="color-pink">name</span>: <span class="color-green">"Bob"</span>,</p>
                        <p class="ml-30">    <span class="color-pink">greet</span>: <span class="color-red">function</span>() {</p>
                        <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Hi, "</span> + <span class="color-blue">this</span>.name + <span class="color-green">"!"</span>);</p>
                        <p class="ml-30">    }</p>
                        <p>};</p>
                        <br />
                        <p class="color-grey">// Pass greet as a callback (loses &#96;this&#96;)</p>
                        <p><span class="color-red">setTimeout</span>(person.greet, <span class="color-pink">1000</span>); <span class="color-grey">// Output: Hi, undefined!</span></p>
                        <br />
                        <p class="color-grey">// Fix with bind</p>
                        <p><span class="color-red">setTimeout</span>(person.greet.<span class="color-red">bind</span>(person), <span class="color-pink">1000</span>); <span class="color-grey">// Output: Hi, Bob!</span></p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Example 3: Partial Application (Setting Default Arguments)</h5>
                <p>You can use <span className='background-grey'>bind()</span> to create a function with some arguments pre-set.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">function</span> <span class="color-red">multiply</span>(a, b) {</p>
                        <p class="ml-30">    <span class="color-blue">return</span> a <span class="color-pink">*</span> b;</p>
                        <p>}</p>
                        <p><span class="color-blue">let</span> double = multiply.<span class="color-red">bind</span>(<span class="color-blue">null</span>, <span class="color-pink">2</span>); <span class="color-grey">// &#96;a&#96; is fixed to 2</span></p>
                        <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-red">double</span>(<span class="color-pink">5</span>)); <span class="color-grey">// Output: 10</span></p>
                        <p><span class="color-blue">let</span> triple = multiply.<span class="color-red">bind</span>(<span class="color-blue">null</span>, <span class="color-pink">3</span>); <span class="color-grey">// &#96;a&#96; is fixed to 3</span></p>
                        <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-red">triple</span?>(<span class="color-pink">4</span>)); <span class="color-grey">// Output: 12</span></p>
                        `
                    }}></div>
                </div>
                <p>Here, in this example</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span className='background-grey'>double</span> is a new function where the first argument (<span className='background-grey'>a</span>) is always 2.</li>
                    <li>You only need to pass the second argument (<span className='background-grey'>b</span>) when calling it.</li>
                </ul>



            </div>
        </section>
    )
}