import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/jquery/events/trigger-handler";

export default function TriggerHandler() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("jQuery triggerHandler() Method | Aspirant's Home");
        const urls = {
            'previous': '/jquery/events'
        }
        path.setPreviousNext(urls);
        path.setPreviousName('Back to Events');
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>jQuery triggerHandler() Method</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">triggerHandler()</span> method in jQuery is similar to the <span class="background-grey">trigger()</span> method but with some key differences. It is used to trigger an event handler for the selected elements without propagating the event to parent elements and without affecting the native default behavior.
                </p>

                <p>It has a basic syntax -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(selector).<span class="color-red">triggerHandler</span>(eventType, [extraParameters]);</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">selector</span> is the target element(s) on which to trigger the event handler.</li>
                    <li><span class="background-grey">eventType</span> is the type of event to trigger (e.g., <span class="background-grey">"click"</span>, <span class="background-grey">"focus"</span>).</li>
                    <li><span class="background-grey">extraParameters</span> (optional) are the additional data to pass to the event handler.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Example : Trigger a Click Event Handler Without Propagation</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;div <span class="color-pink">id</span>=<span class="color-green">"parentDiv"</span>&gt;</p>
                        <p class="ml-30">    &lt;button <span class="color-pink">id</span>=<span class="color-green">"childButton"</span>&gt;Click Me&lt;/button&gt;</p>
                        <p>&lt;/div&gt;</p>
                        `
                    }}></div>
                </div>
                <h6>jQuery Code</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"#childButton"</span>).<span class="color-red">click</span>(<span class="color-blue">function</span>(event) {</p>
                        <p class="ml-30">    <span class="color-red">alert</span>(<span class="color-green">"Button clicked!"</span>);</p>
                        <p>});</p>
                        <br />
                        <p>$(<span class="color-green">"#parentDiv"</span>).<span class="color-red">click</span>(<span class="color-blue">function</span>() {</p>
                        <p class="ml-30">    <span class="color-red">alert</span>(<span class="color-green">"Parent Div clicked!"</span>);</p>
                        <p>});</p>
                        <br />
                        <p class="color-grey">// Trigger click event using triggerHandler</p>
                        <p>$(<span class="color-green">"#childButton"</span>).<span class="color-red">triggerHandler</span>(<span class="color-green">"click"</span>);</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>Here, only the button's click handler executes.</li>
                    <li>The event does not propagate to <span class="background-grey">#parentDiv</span></li>
                </ul>


            </div>
        </section>
    )
}