import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/tag/i";

export default function ITag() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - <i> Tag | Aspirant's Home");
        const urls = {
            'previous': '/html/tag-list'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - &lt;i&gt; Tag</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">&lt;i&gt;</span> tag in HTML is used to display text in italics. Originally intended to italicize text for stylistic reasons, it’s commonly used to indicate a different voice, a thought, or technical terms without adding extra emphasis or importance. The <span class="background-grey">&lt;i&gt;</span> tag is typically used for subtle distinctions, while <span class="background-grey">&lt;em&gt;</span> is preferred for emphasized text with semantic meaning.
                </p>

                <h5 className='mt-5 mb-3'>Structure and Purpose of <span class="background-grey">&lt;i&gt;</span> Tag</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>Makes text appear in italics, providing a distinct visual style.</li>
                    <li>Unlike <span class="background-grey">&lt;em&gt;</span>, it does not imply additional meaning, urgency, or importance.</li>
                    <p>It is suitable for words in foreign languages, technical terms, or titles of works like books or movies.</p>
                </ul>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <p>The <span class="background-grey">&lt;i&gt;</span> tag wraps around the text you want to italicize:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;i&gt;Your italicized text here&lt;/i&gt;</p>
                        `
                    }}></div>
                </div>  

                <h5 className='mt-5 mb-3'>Example Usage of <span class="background-grey">&lt;i&gt;</span> Tag</h5>
                <p>Here’s an example showing how to use the <span class="background-grey">&lt;i&gt;</span> tag to italicize a book title:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Italic Text Example&lt;/title&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <br />    
                        <p class="ml-60">        &lt;p&gt;One of my favorite books is &lt;i&gt;The Great Gatsby&lt;/i&gt; by F. Scott Fitzgerald.&lt;/p&gt;</p>
                        <br />    
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>  
                <p>In this example, <span class="background-grey">&lt;i&gt;</span> is used to italicize the book title "The Great Gatsby."</p>
                <p>Output will be the following -</p>
                <div className='outputPalateBox mt-2 mb-4'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <!DOCTYPE html>
                        <html lang="en">
                            <head>
                                <meta charset="UTF-8">
                                <title>Italic Text Example</title>
                            </head>
                            <body>
                            
                                <p>One of my favorite books is <i>The Great Gatsby</i> by F. Scott Fitzgerald.</p>
                            
                            </body>
                        </html>
                        `
                    }}></div>
                </div> 
                        


            </div>
        </section>
    )
}