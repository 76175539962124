import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/jquery/ajax";

export default function Ajax() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("jQuery - AJAX Introduction | Aspirant's Home");
        const urls = {
            'previous': '/jquery/filtering',
            'next': '/jquery/load'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>jQuery - AJAX Introduction</h3>
            <div className='mt-4 mb-5'>
                <p>
                    <strong>AJAX (Asynchronous JavaScript and XML)</strong> is a technique that allows web pages to update dynamically by fetching data from a server in the background, without reloading the whole page. jQuery simplifies the process of using AJAX with a set of built-in methods that make it easy to communicate with a server, retrieve data, and update parts of a web page.
                </p>

                <h5 className='mt-5 mb-3'>Why Use jQuery with AJAX?</h5>
                <p>jQuery offers several methods that make it easy to work with AJAX, allowing you to communicate with a server to fetch or send data. These methods simplify the process of making HTTP requests (GET or POST) to retrieve different types of data (such as text, HTML, XML, or JSON) and to load this data into specific parts of a webpage.</p>

                <p><strong>jQuery AJAX methods</strong> allow you to request various data formats from a server:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>Text</strong>: Retrieve plain text content, which can be loaded directly into an HTML element.</li>
                    <li><strong>HTML</strong>: Fetches HTML code that can be inserted into specific elements on the page.</li>
                    <li><strong>XML</strong>: Useful when working with APIs or data sources that provide XML responses.</li>
                    <li><strong>JSON</strong>: JSON is lightweight and easy to parse, making it a popular choice for modern web applications. JSON data can be easily converted to JavaScript objects, which simplifies data handling on the client side.</li>
                </ul>

            </div>
        </section>
    )
}