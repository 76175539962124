import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/array-sort";

export default function ArraySort() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Array Sort in Javascript | Aspirant's Home");
        const urls = {
            'previous': '/javascript/array-search',
            'next': '/javascript/array-iteration'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Array Sort</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Sorting in JavaScript means arranging the elements of an array in a specific order, such as <strong>alphabetical</strong> or <strong>numerical</strong>. JavaScript provides the <span className='background-grey'>sort()</span> method to sort arrays.
                </p>

                <h5 className='mt-5 mb-3'>How Does <span className='background-grey'>sort()</span> Work?</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span className='background-grey'>sort()</span> method <strong>modifies the original array</strong>.</li>
                    <li>By default, it sorts array elements as <strong>strings</strong> in <strong>alphabetical order</strong>, even if they are numbers.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Syntax of <span className='background-grey'>sort()</span></h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>array.<span class="color-blue">sort</span>([compareFunction])</p>
                        `
                    }}></div>
                </div>
                <p>Where, <span className='background-grey'>compareFunction</span> is Optional. A function that defines the sorting logic. Without this, elements are sorted as strings.</p>


                <h5 className='mt-5 mb-3'>Sorting Without a <span className='background-grey'>compareFunction</span></h5>
                <p>When you use <span className='background-grey'>sort()</span> without a <span className='background-grey'>compareFunction</span>, it arranges the array elements as <strong>strings</strong> in <strong>alphabetical order</strong>.</p>

                <h6>Example:</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> fruits = [<span class="color-green">"Banana"</span>, <span class="color-green">"Apple"</span>, <span class="color-green">"Cherry"</span>];</p>
                        <p>fruits.<span class="color-red">sort</span>();</p>
                        <br />
                        <p><span class="color-pink">console</span>.<span class="color-red">log</span>(fruits); <span class="color-grey">// Output: ["Apple", "Banana", "Cherry"]</span></p>
                        `
                    }}></div>
                </div>
                <h6>Sorting Numbers Without <span className='background-grey'>compareFunction</span>:</h6>
                <p>If the array contains numbers, <span className='background-grey'>sort()</span> treats them as strings, which can lead to incorrect results.</p>
                <h6>Example:</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> numbers = [<span class="color-pink">10</span>, <span class="color-pink">5</span>, <span class="color-pink">20</span>, <span class="color-pink">15</span>];</p>
                        <p>numbers.<span class="color-red">sort</span>();</p>
                        <br />
                        <p><span class="color-pink">console</span>.<span class="color-red">log</span>(numbers); <span class="color-grey">// Output: [10, 15, 20, 5] (Incorrect order)</span></p>
                        `
                    }}></div>
                </div>
                <p>To fix this, use a <span className='background-grey'>compareFunction</span>, as shown below.</p>


                <h5 className='mt-5 mb-3'>Sorting With a <span className='background-grey'>compareFunction</span></h5>
                <p>A <span className='background-grey'>compareFunction</span> helps sort numbers or apply custom sorting logic. It takes two arguments and returns:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>A negative value</strong>: If the first value should come before the second.</li>
                    <li><strong>Zero</strong>: If the two values are equal.</li>
                    <li><strong>A positive value</strong>: If the first value should come after the second.</li>
                </ul>

                <h6>Example 1: Sort Numbers in Ascending Order</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> numbers = [<span class="color-pink">10</span>, <span class="color-pink">5</span>, <span class="color-pink">20</span>, <span class="color-pink">15</span>];</p>
                        <p>numbers.<span class="color-red">sort</span>((a, b) => a - b);</p>
                        <br />
                        <p><span class="color-pink">console</span>.<span class="color-red">log</span>(numbers); <span class="color-grey">// Output: [5, 10, 15, 20]</span></p>
                        `
                    }}></div>
                </div>
                <h6>Example 2: Sort Numbers in Descending Order</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> numbers = [<span class="color-pink">10</span>, <span class="color-pink">5</span>, <span class="color-pink">20</span>, <span class="color-pink">15</span>];</p>
                        <p>numbers.<span class="color-red">sort</span>((a, b) => b - a);</p>
                        <br />
                        <p><span class="color-pink">console</span>.<span class="color-red">log</span>(numbers); <span class="color-grey">// Output: [20, 15, 10, 5]</span></p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-3'>Sorting Strings in Reverse Order</h5>
                <p>You can sort strings in reverse alphabetical order using a custom <span className='background-grey'>compareFunction</span></p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> fruits = [<span class="color-green">"Banana"</span>, <span class="color-green">"Apple"</span>, <span class="color-green">"Cherry"</span>];</p>
                        <p>fruits.<span class="color-red">sort</span>((a, b) => b.<span class="color-red">localeCompare</span>(a));</p>
                        <br />
                        <p><span class="color-pink">console</span>.<span class="color-red">log</span>(fruits); <span class="color-grey">// Output: ["Cherry", "Banana", "Apple"]</span></p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Sorting Objects in an Array</h5>
                <p>When you have an array of objects, you can use a <span className='background-grey'>compareFunction</span> to sort based on a specific property.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> students = [</p>
                        <p class="ml-30">    { <span class="color-pink">name</span>: <span class="color-green">"Alice"</span>, <span class="color-pink">age</span>: <span class="color-pink">22</span> },</p>
                        <p class="ml-30">    { <span class="color-pink">name</span>: <span class="color-green">"Bob"</span>, <span class="color-pink">age</span>: <span class="color-pink">18</span> },</p>
                        <p class="ml-30">    { <span class="color-pink">name</span>: <span class="color-green">"Charlie"</span>, <span class="color-pink">age</span>: <span class="color-pink">20</span> }</p>
                        <p>];</p>
                        <br />
                        <p class="color-grey">// Sort by age in ascending order</p>
                        <p>students.<span class="color-red">sort</span>((a, b) => a.age - b.age);</p>
                        <br />
                        <p><span class="color-pink">console</span>.<span class="color-red">log</span>(students);</p>
                        <p class="color-grey">// Output: [{ name: "Bob", age: 18 }, { name: "Charlie", age: 20 }, { name: "Alice", age: 22 }]</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Reversing an Array</h5>
                <p>If you want to reverse the order of elements after sorting, you can use the <span className='background-grey'>reverse()</span> method.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> fruits = [<span class="color-green">"Banana"</span>, <span class="color-green">"Apple"</span>, <span class="color-green">"Cherry"</span>];</p>
                        <p>fruits.<span class="color-red">sort</span>().<span class="color-red">reverse</span>();</p>
                        <br />
                        <p><span class="color-pink">console</span>.<span class="color-red">log</span>(fruits); <span class="color-grey">// Output: ["Cherry", "Banana", "Apple"]</span></p>
                        `
                    }}></div>
                </div>



            </div>
        </section>
    )
}