import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/angular/environment-setup";

export default function EnvironmentSetup() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Environment Setup and Creating First App | Aspirant's Home");
        const urls = {
            'previous': '/angular/introduction',
            'next': '/angular/folder-structure'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Environment Setup and Creating First App</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Setting up the environment for developing an Angular application involves installing the necessary tools and creating a project structure. Here's a <strong>step-by-step</strong> guide:
                </p>

                <ul className='mt-5' style={{ listStyle: 'decimal' }}>
                    <li className='operator'>
                        <h4>Install Node.js</h4>
                        <p>Angular requires <strong>Node.js</strong> for managing dependencies and running its development server.</p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>
                                <strong>Download and Install Node.js:</strong>
                                <ul style={{ listStyle: 'disc' }}>
                                    <li>Visit the <a href='https://nodejs.org/en' target='_blank'>Node.js official website</a>.</li>
                                    <li>Download the <strong>LTS version</strong> for stability.</li>
                                    <li>Install Node.js, which also includes <strong>npm</strong> (Node Package Manager).</li>
                                </ul>
                            </li>
                            <li className='mt-4'>
                                <strong>Verify Installation</strong>
                                <p>Open a terminal or command prompt and run:</p>
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                        <p>node -v</p>
                                        <p>npm -v</p>
                                        `
                                    }}></div>
                                </div>
                                <p>This displays the installed versions of Node.js and npm.</p>
                            </li>
                        </ul>
                    </li>
                    <li className='operator'>
                        <h4>Install Angular CLI</h4>
                        <p>The Angular CLI (Command Line Interface) is a tool that simplifies Angular development.</p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>
                                <strong>Install Angular CLI Globally</strong>:
                                <p>Run the following command in your terminal:</p>
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-blue">npm</span> install -g @angular/cli</p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li className='mt-4'>
                                <strong>Verify Angular CLI Installation</strong>:
                                <p>Check the installed version:</p>
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-blue">ng</span> version</p>
                                        `
                                    }}></div>
                                </div>
                                <p>This confirms Angular CLI is installed and shows its version.</p>
                            </li>
                        </ul>
                    </li>
                    <li className='operator'>
                        <h4>Create a New Angular Project</h4>
                        <p>After setting up Angular CLI, you can create a new Angular project.</p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>
                                <strong>Generate a New Project</strong>:
                                <p>Run the following command:</p>
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-blue">ng</span> new my-angular-app</p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li className='mt-4'>
                                <strong>Navigate to the Project Directory</strong>:
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                        <p><span class="color-blue">cd</span> my-angular-app</p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li className='operator'>
                        <h4>Run the Development Server</h4>
                        <p>Start the Angular application to view it in the browser.</p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>
                                <strong>Run the App</strong>:
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-blue">ng</span> serve</p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li className='mt-4'>
                                <strong>Access the App</strong>:
                                <p>Open a browser and navigate to <span class="background-grey">http://localhost:4200/</span>.</p>
                            </li>
                        </ul>
                    </li>
                </ul>

                <p>Now your Angular environment is ready! You can start building components, services, and other features.</p>

            </div>
        </section>
    )
}