import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/tag/p";

export default function PTag() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - <p> Tag | Aspirant's Home");
        const urls = {
            'previous': '/html/tag-list'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - &lt;p&gt; Tag</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">&lt;p&gt;</span> tag in HTML is used to define a paragraph of text. It is a block-level element that adds space above and below the content, making it useful for organizing text in a readable format. The <span class="background-grey">&lt;p&gt;</span> tag automatically separates blocks of text, so there’s no need to add extra line breaks or spacing within paragraphs.
                </p>

                <h5 className='mt-5 mb-3'>Structure and Purpose of <span class="background-grey">&lt;p&gt;</span> Tag</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span class="background-grey">&lt;p&gt;</span> tag structures content into paragraphs, improving readability.</li>
                    <li>Browsers apply default top and bottom margins around <span class="background-grey">&lt;p&gt;</span> elements, creating space between blocks of text.</li>
                    <li>Used primarily for plain text, but it can also hold inline elements like <span class="background-grey">&lt;strong&gt;</span>, <span class="background-grey">&lt;em&gt;</span>, <span class="background-grey">&lt;a&gt;</span>, etc., for formatting within the paragraph.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <p>The <span class="background-grey">&lt;p&gt;</span> tag opens and closes around text content, making it easy to create separate paragraphs:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;p&gt;This is a paragraph of text.&lt;/p&gt;</p>
                        <p>&lt;p&gt;This is another paragraph of text.&lt;/p&gt;</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Example Usage of <span class="background-grey">&lt;p&gt;</span></h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Paragraph Example&lt;/title&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <br />    
                        <p class="ml-60">        &lt;h1&gt;About Us&lt;/h1&gt;</p>
                        <br />        
                        <p class="ml-60">        &lt;p&gt;Welcome to our website! We are dedicated to providing quality content and resources.&lt;/p&gt;</p>
                        <p class="ml-60">        &lt;p&gt;Our mission is to make learning accessible and enjoyable for everyone.&lt;/p&gt;</p>
                        <br />    
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>
                <p>The <span class="background-grey">&lt;p&gt;</span> tag is a fundamental HTML element for creating paragraphs. It structures content, adds readability, and can be styled or enhanced with inline elements. It’s a go-to for organizing text content on any web page.</p>

            </div>
        </section>
    )
}