import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/tag/pre";

export default function PreTag() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - <pre> Tag | Aspirant's Home");
        const urls = {
            'previous': '/html/tag-list'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - &lt;pre&gt; Tag</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">&lt;pre&gt;</span> tag in HTML is used to display preformatted text, where the whitespace (such as spaces, tabs, and line breaks) is preserved as written. This makes it useful for displaying code snippets, ASCII art, or any text where formatting is important. By default, browsers render the text inside a <span class="background-grey">&lt;pre&gt;</span> tag in a monospaced (fixed-width) font, and it respects all whitespace characters exactly as they appear.
                </p>

                <h5 className='mt-5 mb-3'>Structure and Purpose of <span class="background-grey">&lt;pre&gt;</span> Tag</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>Unlike other elements, <span class="background-grey">&lt;pre&gt;</span> maintains all spaces, tabs, and line breaks.</li>
                    <li>Text inside <span class="background-grey">&lt;pre&gt;</span> is shown in a fixed-width font, making it ideal for code or structured text.</li>
                    <li>Handy for examples, code blocks, or anything requiring exact formatting.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <p>The <span class="background-grey">&lt;pre&gt;</span> tag wraps around the preformatted text:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;pre&gt;</p>
                        <p>Your preformatted text</p>
                        <p>goes here, with spaces and line breaks</p>
                        <p>preserved as they appear.</p>
                        <p>&lt;/pre&gt;</p>
                        `
                    }}></div>
                </div>   
                
                <h5 className='mt-5 mb-3'>Example Usage of <span class="background-grey">&lt;pre&gt;</span> Tag</h5>
                <p>Here’s an example displaying a code snippet using <span class="background-grey">&lt;pre&gt;</span>:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Preformatted Text Example&lt;/title&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <br />    
                        <p class="ml-60">        &lt;h2&gt;JavaScript Code Example&lt;/h2&gt;</p>
                        <br />        
                        <p class="ml-60">        &lt;pre&gt;</p>
                        <p class="ml-60">        <span class="color-blue">function</span> <span class="color-red">greet</span>() {</p>
                        <p class="ml-90">            <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Hello, World!"</span>);</p>
                        <p class="ml-60">        }</p>
                        <p class="ml-60">        &lt;/pre&gt;</p>
                        <br />    
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>  
                <p>Output will be the following -</p>
                <div className='outputPalateBox mt-2 mb-4'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <!DOCTYPE html>
                        <html lang="en">
                        <head>
                          <meta charset="UTF-8">
                          <title>Preformatted Text Example</title>
                        </head>
                        <body>
                        
                        <h2>JavaScript Code Example</h2>
                        
                        <pre>
                        function greet() {
                          console.log("Hello, World!");
                        }
                        </pre>
                        
                        </body>
                        </html>
                        `
                    }}></div>
                </div>  

                <p>The <span class="background-grey">&lt;pre&gt;</span> tag is ideal for presenting preformatted text that requires preserved spacing and structure. It’s commonly used for displaying code, structured data, or anything that relies on specific spacing. Styled with CSS, it becomes a versatile element for presenting information clearly and consistently across devices.</p>


            </div>
        </section>
    )
}