import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/jquery/traversing";

export default function Traversing() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("jQuery - Traversing | Aspirant's Home");
        const urls = {
            'previous': '/jquery/dimensions',
            'next': '/jquery/ancestors'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>jQuery - Traversing</h3>
            <div className='mt-4 mb-5'>
                <p>
                    <strong>Traversing</strong> in jQuery refers to the process of navigating through the DOM (Document Object Model) tree to select and interact with elements based on their relationships to other elements. This includes moving up to parent elements, down to child elements, or sideways to sibling elements. Traversing is especially useful when you want to dynamically access and manipulate specific elements within complex HTML structures, without needing explicit IDs or class names.
                </p>

                <h5 className='mt-5 mb-3'>Methods of Traversing in jQuery</h5>
                <p>
                    Let's dive into the different methods available for traversing the DOM in jQuery, broken down by direction: <strong>Up the DOM</strong> (towards ancestors), <strong>Down the DOM</strong> (towards descendants), and <strong>Sideways</strong> (sibling elements). 
                </p>



            </div>
        </section>
    )
}