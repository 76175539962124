import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/events/onunload";

export default function Onunload() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - onunload Event | Aspirant's Home");
        const urls = {
            'previous': '/html/events'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - onunload Event</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">onunload</span> event in HTML is triggered when a user leaves a webpage, either by closing the browser tab or window, navigating to a different page, or refreshing the page. This event can be used to perform cleanup actions, save data, or alert users before they leave.
                </p>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <p>To use <span class="background-grey">onunload</span>, add it as an attribute to the <span class="background-grey">&lt;body&gt;</span> tag to run JavaScript code when the page unloads.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;body <span class="color-pink">onunload</span>=<span class="color-green">"alert('You are leaving the page')"</span>&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example, The alert message appears when the user attempts to leave the page.</p>


                <h5 className='mt-5 mb-3'>Example: Save User Progress</h5>
                <p>You can use <span class="background-grey">onunload</span> to save user data, such as form inputs, to <span class="background-grey">localStorage</span> when the page unloads. This lets users continue where they left off if they return to the page later.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;body <span class="color-pink">onunload</span>=<span class="color-green">"saveProgress()"</span>&gt;</p>
                        <p class="ml-30">    &lt;h1&gt;Save Progress Example&lt;/h1&gt;</p>
                        <p class="ml-30">    &lt;label <span class="color-pink">for</span>=<span class="color-green">"notes"</span>&gt;Notes:&lt;/label&gt;</p>
                        <p class="ml-30">    &lt;textarea <span class="color-pink">id</span>=<span class="color-green">"notes"</span> <span class="color-pink">placeholder</span>=<span class="color-green">"Type your notes here..."</span>&gt;&lt;/textarea&gt;</p>
                        <br />
                        <p class="ml-30">    &lt;script&gt;</p>
                        <p class="ml-60">        <span class="color-blue">function</span> <span class="color-red">saveProgress</span>() {</p>
                        <p class="ml-90">            <span class="color-blue">const</span> notes = document.<span class="color-red">getElementById</span>(<span class="color-green">"notes"</span>).value;</p>
                        <p class="ml-90">            localStorage.<span class="color-red">setItem</span>(<span class="color-green">"userNotes"</span>, notes);</p>
                        <p class="ml-60">        }</p>
                        <br />        
                        <p class="ml-60">        window.onload = <span class="color-red">function</span>() {</p>
                        <p class="ml-90">            document.<span class="color-red">getElementById</span>(<span class="color-green">"notes"</span>).value = localStorage.<span class="color-red">getItem</span>(<span class="color-green">"userNotes"</span>) || <span class="color-green">""</span>;</p>
                        <p class="ml-60">        };</p>
                        <p class="ml-30">    &lt;/script&gt;</p>
                        <p>&lt;/body&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span class="background-grey">saveProgress</span> function saves the content of the textarea to <span class="background-grey">localStorage</span> when the page unloads.</li>
                    <li>When the page loads, the previous notes are restored from <span class="background-grey">localStorage</span>.</li>
                </ul>



            </div>
        </section>
    )
}