import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/jquery/events/focusin";

export default function Focusin() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("jQuery focusin() Method | Aspirant's Home");
        const urls = {
            'previous': '/jquery/events'
        }
        path.setPreviousNext(urls);
        path.setPreviousName('Back to Events');
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>jQuery focusin() Method</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">focusin()</span> method in jQuery triggers an event when an element (typically an input, textarea, or select) or any of its child elements gains focus. Unlike <span class="background-grey">focus()</span>, the <span class="background-grey">focusin()</span> method can bubble up the DOM, meaning the event can be captured at a parent level, making it easier to monitor focus events across groups of elements.
                </p>

                <p>It has a basic syntax -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(selector).<span class="color-red">focusin</span>(function);</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">selector</span> specifies the element(s) to attach the <span class="background-grey">focusin</span> event to.</li>
                    <li><span class="background-grey">function</span> is a callback function to execute when the element or any of its child elements gains focus.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Example: Highlighting a Form Section with <span class="background-grey">focusin()</span></h5>
                <p>In this example, when any input field within a specified section gains focus, the background color of the entire section changes.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;div <span class="color-pink">class</span>=<span class="color-green">"form-section"</span> <span class="color-pink">style</span>=<span class="color-green">"padding: 10px; border: 1px solid #ddd;"</span>&gt;</p>
                        <p class="ml-30">    &lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">placeholder</span>=<span class="color-green">"First Name"</span>&gt;</p>
                        <p class="ml-30">    &t;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">placeholder</span>=<span class="color-green">"Last Name"</span>&gt;</p>
                        <p>&lt;/div&gt;</p>
                        `
                    }}></div>
                </div>
                <h6>jQuery Code</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">".form-section"</span>).<span class="color-red">focusin</span>(<span class="color-blue">function</span>() {</p>
                        <p class="ml-30">    $(this).<span class="color-red">css</span>(<span class="color-green">"background-color"</span>, <span class="color-green">"#e0f7fa"</span>);</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span class="background-grey">focusin()</span> method is applied to the <span class="background-grey">.form-section</span> container.</li>
                    <li>When any input field inside <span class="background-grey">.form-section</span> gains focus, the background color of the entire section changes to a light blue (<span class="background-grey">#e0f7fa</span>).</li>
                </ul>


            </div>
        </section>
    )
}