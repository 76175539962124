import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/jquery/callback";

export default function Callback() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Callback in jQuery | Aspirant's Home");
        const urls = {
            'previous': '/jquery/stop-function',
            'next': '/jquery/chaining'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Callback in jQuery</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In jQuery, a <strong>callback function</strong> is a function that is passed as an argument to another function, to be executed after the first function completes. Callbacks are particularly useful in jQuery animations and asynchronous operations, like AJAX requests, where you may want to perform an action only after the initial task has finished.
                </p>

                <h5 className='mt-5 mb-3'>Why Use Callbacks?</h5>
                <p>
                    When you create animations or other time-consuming tasks, JavaScript doesn’t stop executing other code while it waits for the task to complete. Instead, it continues executing the next lines of code. This can cause unexpected behavior if you want certain actions to happen after an animation or AJAX request completes. A callback ensures that your specified code only runs after the initial operation finishes.
                </p>

                <h5 className='mt-5 mb-3'>Basic Syntax of Callback in jQuery</h5>
                <p>Here’s how a callback function is typically used in jQuery:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(selector).<span class="color-red">action</span>(parameters, callback);</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">selector</span> is the element(s) on which the action is performed.</li>
                    <li><span class="background-grey">action</span> is the jQuery method (e.g., <span class="background-grey">animate</span>, <span class="background-grey">fadeOut</span>, etc.) that you’re applying.</li>
                    <li><span class="background-grey">parameters</span> are any additional parameters the method might require (e.g., speed, properties).</li>
                    <li><span class="background-grey">callback</span> is a function that executes once the action completes.</li>
                </ul>

                
                <h5 className='mt-5 mb-3'>Some Examples of Callback in jQuery</h5>
                <p>Here are the some examples of Callback.</p>

                <h6>Example 1: Using a Callback with <span class="background-grey">fadeOut()</span></h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"#box"</span>).<span class="color-red">fadeOut</span>(<span class="color-pink">500</span>, <span class="color-blue">function</span>() {</p>
                        <p class="ml-30">    <span class="color-red">alert</span>(<span class="color-green">"The fade-out animation is complete!"</span>);</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>Here, <span class="background-grey">#box</span> fades out over 500 milliseconds. When the fade-out animation completes, the callback function displays an alert message.</li>
                    <li>Without the callback, the alert might execute immediately, even before the animation finishes.</li>
                </ul>

                <h6>Example 2: Callback with <span class="background-grey">slideDown()</span></h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"#panel"</span>).<span class="color-red">slideDown</span>(<span class="color-pink">600</span>, <span class="color-blue">function</span>() {</p>
                        <p class="ml-30">    $(<span class="color-green">"#message"</span>).<span class="color-red">text</span>(<span class="color-green">"Slide down complete!"</span>);</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>Where, This code slides down <span class="background-grey">#panel</span> over 600 milliseconds. Once the slide-down finishes, it changes the text in <span class="background-grey">#message</span> to indicate completion.</p>

            </div>
        </section>
    )
}