import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/jquery/events";

export default function Events() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Events of jQuery | Aspirant's Home");
        const urls = {
            'previous': '/jquery/selectors',
            'next': '/jquery/hide-and-show'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Events of jQuery</h3>
            <div className='mt-4 mb-5'>
                <p>
                    jQuery events allow you to capture and respond to user interactions and other occurrences in a web page, like clicks, hovers, or form submissions. They are essential for making your page dynamic and interactive.
                </p>

                <h5 className='mt-5 mb-3'>Syntax of jQuery Events</h5>
                <p>The general syntax for handling events in jQuery is:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(selector).<span class="color-red">event</span>(<span class="color-blue">function</span>() {</p>
                        <p class="ml-30 color-grey">    // Action to perform when the event occurs</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">selector</span> is the element you want to apply the event to (e.g., <span class="background-grey">#button</span>, <span class="background-grey">.class</span>, <span class="background-grey">tag</span>).</li>
                    <li><span class="background-grey">event</span> is the event you want to capture, like <span class="background-grey">click</span>, <span class="background-grey">hover</span>, or <span class="background-grey">focus</span>.</li>
                    <li><span class="background-grey">function()</span> that defines what happens when the event is triggered.</li>
                </ul>

                
                <h5 className='mt-5 mb-3'>Events Methods</h5>
                <p>jQuery provides some convenient methods for working with events:</p>

                <table className="myBorderClass table" border="1">
                    <thead>
                        <tr>
                            <th>Method</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><Link to="/jquery/events/click">click()</Link></td>
                            <td>It triggers the click event.</td>
                        </tr>
                        <tr>
                            <td><Link to="/jquery/events/dblclick">dblclick()</Link></td>
                            <td>It triggers the double click event</td>
                        </tr>
                        <tr>
                            <td><Link to="/jquery/events/blur">blur()</Link></td>
                            <td>It triggers the blur event</td>
                        </tr>
                        <tr>
                            <td><Link to="/jquery/events/change">change()</Link></td>
                            <td>It triggers the change event</td>
                        </tr>
                        <tr>
                            <td><Link to="/jquery/events/focus">focus()</Link></td>
                            <td>It triggers the focus event</td>
                        </tr>
                        <tr>
                            <td><Link to="/jquery/events/focusin">focusin()</Link></td>
                            <td>It attaches an event handler to the focusin event</td>
                        </tr>
                        <tr>
                            <td><Link to="/jquery/events/focusout">focusout()</Link></td>
                            <td>It attaches an event handler to the focusout event</td>
                        </tr>
                        <tr>
                            <td><Link to="/jquery/events/hover">hover()</Link></td>
                            <td>This method attaches two event handlers to the hover event</td>
                        </tr>
                        <tr>
                            <td><Link to="/jquery/events/keydown">keydown()</Link></td>
                            <td>It triggers the keydown event</td>
                        </tr>
                        <tr>
                            <td><Link to="/jquery/events/keypress">keypress()</Link></td>
                            <td>It triggers the keypress event</td>
                        </tr>
                        <tr>
                            <td><Link to="/jquery/events/keyup">keyup()</Link></td>
                            <td>It triggers the keyup event</td>
                        </tr>
                        <tr>
                            <td><Link to="/jquery/events/mousedown">mousedown()</Link></td>
                            <td>It triggers the mousedown event</td>
                        </tr>
                        <tr>
                            <td><Link to="/jquery/events/mouseenter">mouseenter()</Link></td>
                            <td>It triggers the mouseenter event</td>
                        </tr>
                        <tr>
                            <td><Link to="/jquery/events/mouseleave">mouseleave()</Link></td>
                            <td>It triggers the mouseleave event</td>
                        </tr>
                        <tr>
                            <td><Link to="/jquery/events/mousemove">mousemove()</Link></td>
                            <td>It triggers the mousemove event</td>
                        </tr>
                        <tr>
                            <td><Link to="/jquery/events/mouseover">mouseover()</Link></td>
                            <td>It triggers the mouseover event</td>
                        </tr>
                        <tr>
                            <td><Link to="/jquery/events/mouseout">mouseout()</Link></td>
                            <td>It triggers the mouseout event</td>
                        </tr>
                        <tr>
                            <td><Link to="/jquery/events/mouseup">mouseup()</Link></td>
                            <td>It triggers the mouseup event</td>
                        </tr>
                        <tr>
                            <td><Link to="/jquery/events/off">off()</Link></td>
                            <td>It removes event handlers attached with the on() method</td>
                        </tr>
                        <tr>
                            <td><Link to="/jquery/events/on">on()</Link></td>
                            <td>It attaches event handlers to elements</td>
                        </tr>
                        <tr>
                            <td><Link to="/jquery/events/one">one()</Link></td>
                            <td>It adds one or more event handlers to selected elements. This handler can only be triggered once per element</td>
                        </tr>
                        <tr>
                            <td><Link to="/jquery/events/ready">ready()</Link></td>
                            <td>It specifies a function to execute when the DOM is fully loaded</td>
                        </tr>
                        <tr>
                            <td><Link to="/jquery/events/resize">resize()</Link></td>
                            <p>It triggers the resize event</p>
                        </tr>
                        <tr>
                            <td><Link to="/jquery/events/scroll">scroll()</Link></td>
                            <p>It triggers the scroll event</p>
                        </tr>
                        <tr>
                            <td><Link to="/jquery/events/select">select()</Link></td>
                            <p>It triggers the select event</p>
                        </tr>
                        <tr>
                            <td><Link to="/jquery/events/submit">submit()</Link></td>
                            <p>It triggers the submit event</p>
                        </tr>
                        <tr>
                            <td><Link to="/jquery/events/trigger">trigger()</Link></td>
                            <td>It triggers all events bound to the selected elements</td>
                        </tr>
                        <tr>
                            <td><Link to="/jquery/events/trigger-handler">triggerHandler()</Link></td>
                            <td>It triggers all functions bound to a specified event for the selected elements</td>
                        </tr>
                    </tbody>
                </table>


                <h5 className='mt-5 mb-3'>Events Objects</h5>
                <p>Every jQuery event handler can access an event object, which provides information about the event. You can use this object to get details like the mouse position, key pressed, or element involved.</p>
                <p>Let's take a simple example of event object -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(document).<span class="color-red">click</span>(<span class="color-blue">function</span>(event) {</p>
                        <p class="ml-30">    <span class="color-red">alert</span>(<span class="color-green">"Clicked at position: X="</span> + event.pageX + <span class="color-green">", Y="</span> + event.pageY);</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>This example shows an alert with the X and Y coordinates of where the click happened on the page.</p>

                <p>Below are the commmonly used event objects -</p>
                <table className="myBorderClass table" border="1">
                    <thead>
                        <tr>
                            <th>Object Name</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><Link to="/jquery/events/event-currenttarget">event.currentTarget</Link></td>
                            <td>It returns the current DOM element within the event object</td>
                        </tr>
                        <tr>
                            <td><Link to="/jquery/events/event-data">event.data</Link></td>
                            <td>It contains the optional data passed to an event method when the current executing handler is bound</td>
                        </tr>
                        <tr>
                            <td><Link to="/jquery/events/event-delegatetarget">event.delegateTarget</Link></td>
                            <td>It returns the element where the currently-called jQuery event handler was attached</td>
                        </tr>
                        <tr>
                            <td><Link to="/jquery/events/event-isdefaultprevented">event.isDefaultPrevented()</Link></td>
                            <td>It returns whether event.preventDefault() was called for the event object</td>
                        </tr>
                        <tr>
                            <td><Link to="/jquery/events/event-isimmediatepropagationstopped">event.isImmediatePropagationStopped()</Link></td>
                            <td>It returns whether event.stopImmediatePropagation() was called for the event object</td>
                        </tr>
                        <tr>
                            <td><Link to="/jquery/events/event-ispropagationstopped">event.isPropagationStopped()</Link></td>
                            <td>It returns whether event.stopPropagation() was called for the event object</td>
                        </tr>
                        <tr>
                            <td><Link to="/jquery/events/event-namespace">event.namespace</Link></td>
                            <td>It returns the namespace specified when the event was triggered</td>
                        </tr>
                        <tr>
                            <td><Link to="/jquery/events/event-pagex">event.pageX</Link></td>
                            <td>It returns the mouse position relative to the left edge of the document</td>
                        </tr>
                        <tr>
                            <td><Link to="/jquery/events/event-pagey">event.pageY</Link></td>
                            <td>It returns the mouse position relative to the top edge of the document</td>
                        </tr>
                        <tr>
                            <td><Link to="/jquery/events/event-preventdefault">event.preventDefault()</Link></td>
                            <td>It prevents the default action of the event</td>
                        </tr>
                        <tr>
                            <td><Link to="/jquery/events/event-relatedtarget">event.relatedTarget</Link></td>
                            <td>It returns which element being entered or exited on mouse movement</td>
                        </tr>
                        <tr>
                            <td><Link to="/jquery/events/event-result">event.result</Link></td>
                            <td>It contains the last/previous value returned by an event handler triggered by the specified event</td>
                        </tr>
                        <tr>
                            <td><Link to="/jquery/events/event-stopimmediatepropagation">event.stopImmediatePropagation()</Link></td>
                            <td>It prevents other event handlers from being called</td>
                        </tr>
                        <tr>
                            <td><Link to="/jquery/events/event-stoppropagation">event.stopPropagation()</Link></td>
                            <td>It prevents the event from bubbling up the DOM tree, preventing any parent handlers from being notified of the event</td>
                        </tr>
                        <tr>
                            <td><Link to="/jquery/events/event-target">event.target</Link></td>
                            <td>It returns which DOM element triggered the event</td>
                        </tr>
                        <tr>
                            <td><Link to="/jquery/events/event-timestamp">event.timeStamp</Link></td>
                            <td>It returns the number of milliseconds since January 1, 1970, when the event is triggered</td>
                        </tr>
                        <tr>
                            <td><Link to="/jquery/events/event-type">event.type</Link></td>
                            <td>It returns which event type was triggered</td>
                        </tr>
                        <tr>
                            <td><Link to="/jquery/events/event-which">event.which</Link></td>
                            <td>It returns which keyboard key or mouse button was pressed for the event</td>
                        </tr>
                    </tbody>
                </table>


            </div>
        </section>
    )
}