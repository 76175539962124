import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/tag/mark";

export default function Mark() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - <mark> Tag | Aspirant's Home");
        const urls = {
            'previous': '/html/tag-list'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - &lt;mark&gt; Tag</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">&lt;mark&gt;</span> tag in HTML is used to highlight or "mark" text. Text within the <span class="background-grey">&lt;mark&gt;</span> tag typically appears with a yellow background (though this can be styled), making it stand out visually, similar to using a highlighter on printed text. The <span class="background-grey">&lt;mark&gt;</span> tag is generally used to highlight key terms or important parts of content, especially in contexts like search results or emphasizing key points within an article.
                </p>

                <h5 className='mt-5 mb-3'>Structure and Purpose of <span class="background-grey">&lt;mark&gt;</span> Tag</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The default style of <span class="background-grey">&lt;mark&gt;</span> is a yellow background, helping to catch the reader's attention.</li>
                    <li>The tag implies that the marked text is relevant or noteworthy within the surrounding content.</li>
                    <li>The <span class="background-grey">&lt;mark&gt;</span> tag’s highlighting nature can aid users in locating key information more quickly.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <p>The <span class="background-grey">&lt;mark&gt;</span> tag wraps around the text you want to highlight:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;mark&gt;Your highlighted text here&lt;/mark&gt;</p>
                        `
                    }}></div>
                </div>  


                <h5 className='mt-5 mb-3'>Example Usage of <span class="background-grey">&lt;mark&gt;</span> Tag</h5>
                <p>Here’s an example using <span class="background-grey">&lt;mark&gt;</span> to highlight a specific keyword in a paragraph:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Highlighted Text Example&lt;/title&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <br />    
                        <p class="ml-60">        &lt;p&gt;The most common search term was &lt;mark&gt;JavaScript tutorial&lt;/mark&gt;.&lt;/p&gt;</p>
                        <br />    
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>  
                <p>In this example, "JavaScript tutorial" is highlighted with <span class="background-grey">&lt;mark&gt;</span>, making it stand out for users who are skimming for important terms.</p>
                <p>Output will be the following -</p>
                <div className='outputPalateBox mt-2 mb-4'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <!DOCTYPE html>
                        <html lang="en">
                            <head>
                                <meta charset="UTF-8">
                                <title>Highlighted Text Example</title>
                            </head>
                            <body>
                            
                                <p>The most common search term was <mark>JavaScript tutorial</mark>.</p>
                            
                            </body>
                        </html>
                        `
                    }}></div>
                </div>  
                        


            </div>
        </section>
    )
}