import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/jquery/events/event-result";

export default function Result() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("jQuery event.result Object | Aspirant's Home");
        const urls = {
            'previous': '/jquery/events'
        }
        path.setPreviousNext(urls);
        path.setPreviousName('Back to Events');
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>jQuery event.result Object</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">event.result</span> property in jQuery is used to store the value returned by the last triggered event handler. It allows you to access the result of the last executed event handler in the chain. This is particularly useful when multiple event handlers are attached to the same event and you need to retrieve or utilize their return values.
                </p>

                <p>It has a basic syntax -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(selector).<span class="color-red">on</span>(event, <span class="color-blue">function</span>(event) {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(event.result);</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-3'>Example : Accessing the Last Return Value</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;button <span class="color-pink">id</span>=<span class="color-green">"myButton"</span>&gt;Click Me&lt;/button&gt;</p>
                        `
                    }}></div>
                </div>
                <h6>jQuery Code</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"#myButton"</span>).<span class="color-red">on</span>(<span class="color-green">"click"</span>, <span class="color-blue">function</span>() {</p>
                        <p class="ml-30">    <span class="color-blue">return</span> <span class="color-green">"Hello, World!"</span>;</p>
                        <p>});</p>
                        <br />
                        <p>$(<span class="color-green">"#myButton"</span>).<span class="color-red">on</span>(<span class="color-green">"click"</span>, <span class="color-blue">function</span>(event) {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Result from the previous handler:"</span>, event.result);</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The first event handler (<span class="background-grey">return "Hello, World!"</span>) runs and sets <span class="background-grey">event.result</span> to <span class="background-grey">"Hello, World!"</span>.</li>
                    <li>The second event handler logs the value <span class="background-grey">Result from the previous handler: Hello, World!</span>.</li>
                </ul>


            </div>
        </section>
    )
}