import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/angular/viewchild-and-contentchild";

export default function ViewChildContentChild() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("@ViewChild and @ContentChild in Angular | Aspirant's Home");
        const urls = {
            'previous': '/angular/lifecycle-hooks',
            'next': '/angular/interpolation'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>@ViewChild and @ContentChild</h3>
            <div className='mt-4 mb-5'>
                <p>
                    <span class="background-grey">@ViewChild</span> and <span class="background-grey">@ContentChild</span> are decorators in Angular used to access and manipulate elements or components within a template or projected content.
                </p>

                <h5 className='mt-5 mb-3'><span class="background-grey">@ViewChild</span></h5>
                <p>The <span class="background-grey">@ViewChild</span> decorator is used to access a DOM element, directive, or child component that is part of the component's <strong>own view (template)</strong>. It allows interaction with DOM elements or child components after they have been initialized.</p>

                <ul style={{ listStyle: 'disc' }}>
                    <li>It works within the component's view (HTML template).</li>
                    <li>Can be used to access DOM elements, directives, or child components.</li>
                    <li>Accessible after Angular initializes the view (<span class="background-grey">ngAfterViewInit()</span> lifecycle hook).</li>
                </ul>

                <h6 className='mt-4'>Accessing a DOM Element:</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">import</span> { Component, ElementRef, ViewChild } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                        <br />
                        <p><span class="color-red">@Component</span>({</p>
                        <p class="ml-30">    <span class="color-pink">selector</span>: <span class="color-green">'app-viewchild-example'</span>,</p>
                        <p class="ml-30">    <span class="color-pink">template</span>: <span class="color-green">&#96;&lt;p #paragraph&gt;Hello ViewChild&lt;/p&gt;&lt;button (click)="changeText()"&gt;Change Text&lt;/button&gt;&#96;</span></p>
                        <p>})</p>
                        <p><span class="color-pink">export class</span> <span class="color-blue">ViewChildExampleComponent</span> {</p>
                        <p class="ml-30">    <span class="color-red">@ViewChild</span>(<span class="color-green">'paragraph'</span>) paragraph!: <span class="color-blue">ElementRef</span>;</p>
                        <br />
                        <p class="ml-30">    <span class="color-red">changeText</span>() {</p>
                        <p class="ml-60">        <span class="color-blue">this</span>.paragraph.nativeElement.textContent = <span class="color-green">'Text Changed!'</span>;</p>
                        <p class="ml-30">    }</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>When the component is rendered, the <span class="background-grey">&lt;p&gt;</span> element with <span class="background-grey">#paragraph</span> is referenced by <span class="background-grey">@ViewChild</span>.</li>
                    <li>Clicking the button triggers the <span class="background-grey">changeText()</span> method.</li>
                    <li>The method modifies the text content of the <span class="background-grey">&lt;p&gt;</span> element via <span class="background-grey">ElementRef</span>.</li>
                </ul>

                <h6 className='mt-4'>Accessing a Child Component:</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">import</span> { Component, ViewChild } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                        <br />
                        <p><span class="color-red">@Component</span>({</p>
                        <p class="ml-30">    <span class="color-pink">selector</span>: <span class="color-green">'app-child'</span>,</p>
                        <p class="ml-30">    <span class="color-pink">template</span>: <span class="color-green">&#96;&lt;p&gt;Child Component&lt;/p&gt;&#96;</span></p>
                        <p>})</p>
                        <p><span class="color-pink">export class</span> <span class="color-blue">ChildComponent</span> {</p>
                        <p class="ml-30">    message = <span class="color-green">'Hello from Child'</span>;</p>
                        <p>}</p>
                        <br />
                        <p><span class="color-red">@Component</span>({</p>
                        <p class="ml-30">    <span class="color-pink">selector</span>: <span class="color-green">'app-parent'</span>,</p>
                        <p class="ml-30">    <span class="color-pink">template</span>: <span class="color-green">&#96;&lt;app-child&gt;&lt;/app-child&gt;&lt;button (click)="accessChild()"&gt;Access Child&lt;/button&gt;&#96;</span></p>
                        <p>})</p>
                        <p><span class="color-pink">export class</span> <span class="color-blue">ParentComponent</span> {</p>
                        <p class="ml-30">    <span class="color-red">@ViewChild</span>(ChildComponent) childComponent!: <span class="color-blue">ChildComponent</span>;</p>
                        <br />
                        <p class="ml-30">    <span class="color-red">accessChild</span>() {</p>
                        <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-blue">this</span>.childComponent.message); <span class="color-grey">// Outputs: "Hello from Child"</span></p>
                        <p class="ml-30">    }</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The parent component <span class="background-grey">(ParentComponent)</span> template renders the child component <span class="background-grey">(ChildComponent)</span> using <span class="background-grey">&lt;app-child&gt;</span>.</li>
                    <li>Angular initializes the <span class="background-grey">ChildComponent</span> instance.</li>
                    <li>The <span class="background-grey">@ViewChild</span> decorator retrieves the <span class="background-grey">ChildComponent</span> instance and assigns it to the childComponent property in the parent.</li>
                    <li>When the button in the parent is clicked, the <span class="background-grey">accessChild()</span> method is triggered.</li>
                    <li>This method accesses the message property of the <span class="background-grey">ChildComponent</span> and logs <span class="background-grey">"Hello from Child"</span> to the console.</li>
                </ul>


                <h5 className='mt-5 mb-3'><span class="background-grey">@ContentChild</span></h5>
                <p>The <span class="background-grey">@ContentChild</span> decorator is used to access elements, directives, or components that are <strong>projected into a component using</strong> <span class="background-grey">&lt;ng-content&gt;</span>. This is useful for working with content provided by a parent component.</p>

                <ul style={{ listStyle: 'disc' }}>
                    <li>It works with <strong>projected content</strong> (via <span class="background-grey">&lt;ng-content&gt;</span>).</li>
                    <li>Can access DOM elements, directives, or components within the projected content.</li>
                    <li>Accessible after Angular initializes the content (<span class="background-grey">ngAfterContentInit()</span> lifecycle hook).</li>
                </ul>

                <h6 className='mt-4'>Accessing Projected DOM Content:</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">import</span> { Component, ContentChild, ElementRef } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                        <br />
                        <p><span class="color-red">@Component</span>({</p>
                        <p class="ml-30">    <span class="color-pink">selector</span>: <span class="color-green">'app-contentchild-example'</span>,</p>
                        <p class="ml-30">    <span class="color-pink">template</span>: <span class="color-green">&#96;&lt;ng-content&gt;&lt;/ng-content&gt;&lt;button (click)="changeProjectedContent()"&gt;Change Content&lt;/button&gt;&#96;</span></p>
                        <p>})</p>
                        <p><span class="color-pink">export class</span> <span class="color-blue">ContentChildExampleComponent</span> {</p>
                        <p class="ml-30">    <span class="color-red">@ContentChild</span>(<span class="color-green">'projectedContent'</span>) projectedContent!: <span class="color-blue">ElementRef</span>;</p>
                        <br />
                        <p class="ml-30">    <span class="color-red">changeProjectedContent</span>() {</p>
                        <p class="ml-60">        <span class="color-blue">this</span>.projectedContent.nativeElement.textContent = <span class="color-green">'Content Changed!'</span>;</p>
                        <p class="ml-30">    }</p>
                        <p>}</p>
                        <br />
                        <p><span class="color-red">@Component</span>({</p>
                        <p class="ml-30">    <span class="color-pink">selector</span>: <span class="color-green">'app-parent'</span>,</p>
                        <p class="ml-30">    <span class="color-pink">template</span>: <span class="color-green">&#96;&lt;app-contentchild-example&gt;&lt;p #projectedContent&gt;Projected Content&lt;/p&gt;&lt;/app-contentchild-example&gt;&#96;</span></p>
                        <p>})</p>
                        <p><span class="color-pink">export class</span> <span class="color-blue">ParentComponent</span> {}</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span class="background-grey">&lt;p&gt;</span> element in the parent component is projected into the child component using <span class="background-grey">&lt;ng-content&gt;</span>.</li>
                    <li>The <span class="background-grey">@ContentChild</span> decorator in the child component identifies the <span class="background-grey">#projectedContent</span> element and assigns it to the projectedContent property.</li>
                    <li>When the button is clicked in the child component, the <span class="background-grey">changeProjectedContent()</span> method is called.</li>
                    <li>It modifies the text content of the projected <span class="background-grey">&lt;p&gt;</span> element.</li>
                </ul>

                <h6 className='mt-4'>Accessing a Projected Component:</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">import</span> { Component, ContentChild } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                        <br />
                        <p><span class="color-red">@Component</span>({</p>
                        <p class="ml-30">    <span class="color-pink">selector</span>: <span class="color-green">'app-child'</span>,</p>
                        <p class="ml-30">    <span class="color-pink">template</span>: <span class="color-green">&#96;&lt;p&gt;Child Component Content&lt;/p&gt;&#96;</span></p>
                        <p>})</p>
                        <p><span class="color-pink">export class</span> <span class="color-blue">ChildComponent</span> {</p>
                        <p class="ml-30">    message = <span class="color-green">'Hello from Projected Child'</span>;</p>
                        <p>}</p>
                        <br />
                        <p><span class="color-red">@Component</span>({</p>
                        <p class="ml-30">    <span class="color-pink">selector</span>: <span class="color-green">'app-parent'</span>,</p>
                        <p class="ml-30">    <span class="color-pink">template</span>: <span class="color-green">&#96;</span></p>
                        <p class="ml-60 color-green">        &lt;app-contentchild-example&gt;</p>
                        <p class="ml-90 color-green">            &lt;app-child&gt;&lt;/app-child&gt;</p>
                        <p class="ml-60 color-green">        &lt;/app-contentchild-example&gt;</p>
                        <p class="ml-30 color-green">    &#96;</p>
                        <p>})</p>
                        <p><span class="color-pink">export class</span> <span class="color-blue">ParentComponent</span> {}</p>
                        <br />
                        <p><span class="color-red">@Component</span>({</p>
                        <p class="ml-30">    <span class="color-pink">selector</span>: <span class="color-green">'app-contentchild-example'</span>,</p>
                        <p class="ml-30">    <span class="color-pink">template</span>: <span class="color-green">&#96;&lt;ng-content&gt;&lt;/ng-content&gt;&#96;</span></p>
                        <p>})</p>
                        <p><span class="color-pink">export class</span> <span class="color-blue">ContentChildExampleComponent</span> {</p>
                        <p class="ml-30">    <span class="color-red">@ContentChild</span>(ChildComponent) childComponent!: <span class="color-blue">ChildComponent</span>;</p>
                        <br />
                        <p class="ml-30">    <span class="color-red">ngAfterContentInit</span>() {</p>
                        <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-blue">this</span>.childComponent.message); <span class="color-grey">// Outputs: "Hello from Projected Child"</span></p>
                        <p class="ml-30">    }</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span class="background-grey">ParentComponent</span> projects the <span class="background-grey">ChildComponent</span> into the <span class="background-grey">ContentChildExampleComponent</span> using <span class="background-grey">&lt;ng-content&gt;</span>.</li>
                    <li>Angular locates the <span class="background-grey">ChildComponent</span> within the projected content of the <span class="background-grey">ContentChildExampleComponent</span>.</li>
                    <li>The <span class="background-grey">@ContentChild</span> decorator assigns the <span class="background-grey">ChildComponent</span> instance to the <span class="background-grey">childComponent</span> property.</li>
                    <li>Inside the <span class="background-grey">ngAfterContentInit</span> hook, the <span class="background-grey">ContentChildExampleComponent</span> accesses the <span class="background-grey">message</span> property of the projected <span class="background-grey">ChildComponent</span> and logs it to the console.</li>
                </ul>


            </div>
        </section>
    )
}