import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/angular/creating-custom-directives";

export default function CustomDirective() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Custom Directives in Angular | Aspirant's Home");
        const urls = {
            'previous': '/angular/attribute-directives',
            'next': '/angular/template-driven-forms'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Custom Directives</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Custom directives in Angular allow you to define reusable behaviors and extend the functionality of DOM elements beyond the built-in directives. They can modify elements, listen to events, or apply custom logic.
                </p>

                <h5 className='mt-5 mb-3'>Steps to Create a Custom Attribute Directive</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        To <strong>Generate the Directive</strong> Use the Angular CLI:
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">ng generate</span> directive directive-name</p>
                                `
                            }}></div>
                        </div>
                        <p>This creates the directive file and updates the module declaration.</p>
                    </li>
                    <li><strong>Implement the Directive Logic</strong> To implement logic Write the behavior you want to apply to the host element.</li>
                    <li>
                        <strong>Apply the Directive</strong> Use the directive in your templates by adding it to an HTML element.
                    </li>
                </ul>


                <h5 className='mt-5 mb-3'>Example: Highlight Directive</h5>
                <p>A custom directive to change the background color of an element when the user hovers over it.</p>

                <h6 className='mt-4'>Step 1: Generate the Directive</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">ng generate</span> directive highlight</p>
                        `
                    }}></div>
                </div>
                <p>This generates:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">highlight.directive.ts</span></li>
                    <li>Updates the module to declare the directive.</li>
                </ul>

                <h6 className='mt-4'>Step 2: Write the Logic</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">import</span> { Directive, ElementRef, HostListener, Input } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                        <br />
                        <p><span class="color-red">@Directive</span>({</p>
                        <p class="ml-30">    <span class="color-pink">selector</span>: <span class="color-green">'[appHighlight]'</span></p>
                        <p>})</p>
                        <p><span class="color-pink">export class</span> <span class="color-blue">HighlightDirective</span> {</p>
                        <p class="ml-30">    <span class="color-red">@Input</span>(<span class="color-green">'appHighlight'</span>) highlightColor: <span class="color-blue">string</span> = <span class="color-green">'yellow'</span>; <span class="color-grey">// Default color</span></p>
                        <br />
                        <p class="ml-30">    <span class="color-red">constructor</span>(private el: <span class="color-blue">ElementRef</span>) {}</p>
                        <br />
                        <p class="ml-30">    <span class="color-red">@HostListener</span>(<span class="color-green">'mouseenter'</span>) <span class="color-red">onMouseEnter</span>() {</p>
                        <p class="ml-60">        <span class="color-blue">this</span>.<span class="color-red">highlight</span>(<span class="color-blue">this</span>.highlightColor);</p>
                        <p class="ml-30">    }</p>
                        <br />
                        <p class="ml-30">    <span class="color-red">@HostListener</span>(<span class="color-green">'mouseleave'</span>) <span class="color-red">onMouseLeave</span>() {</p>
                        <p class="ml-60">        <span class="color-blue">this</span>.<span class="color-red">highlight</span>(<span class="color-green">''</span>);</p>
                        <p class="ml-30">    }</p>
                        <br />
                        <p class="ml-30">    <span class="color-blue">private</span> <span class="color-red">highlight</span>(color: <span class="color-blue">string</span>) {</p>
                        <p class="ml-60">        <span class="color-blue">this</span>.el.nativeElement.style.backgroundColor = color;</p>
                        <p class="ml-30">    }</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>

                <h6 className='mt-4'>Step 3: Use the Directive</h6>
                <p>Add it to an HTML element by referencing its selector (appHighlight).</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;p <span class="color-pink">appHighlight</span>=<span class="color-green">"lightblue"</span>&gt;Hover over this text to see the highlight effect.&lt;/p&gt;</p>
                        <p>&lt;p <span class="color-pink">appHighlight</span>=<span class="color-green">"pink"</span>&gt;This text will have a pink highlight.&lt;/p&gt;</p>
                        `
                    }}></div>
                </div>


            </div>
        </section>
    )
}