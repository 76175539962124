import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/environment-setup";

export default function EnvironmentSetup() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Environment Setup in Javascript | Aspirant's Home");
        const urls = {
            'previous': '/javascript/introduction',
            'next': '/javascript/variables-and-data-types'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Environment Setup</h3>
            <div className='mt-4 mb-5'>
                <p>To setup an environment for javascript, we need the following things -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>A <strong>browser</strong>: To run and test your JavaScript code.</li>
                    <li>A <strong>code editor</strong>: To write your JavaScript programs.</li>
                    <li>(Optional) <strong>Node.js</strong>: To run JavaScript outside the browser.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Setting Up JavaScript in the Browser</h5>
                <h6 className='mt-3'>Step 1: Use Any Modern Web Browser</h6>
                <p>JavaScript can run in any browser like:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>Google Chrome</li>
                    <li>Mozilla Firefox</li>
                    <li>Microsoft Edge</li>
                    <li>Safari</li>
                </ul>
                <p>All modern browsers come with a built-in <strong>JavaScript engine</strong> to execute JavaScript code.</p>

                <h6 className='mt-3'>Step 2: Write and Run JavaScript in the Browser Console</h6>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>Open your browser.</li>
                    <li>
                        Open the <strong>Developer Tools</strong>:
                        <ul style={{ listStyle: 'disc' }}>
                            <li>In Chrome/Edge: Press <span className='background-grey'>Ctrl+Shift+I</span> (Windows) or <span className='background-grey'>Cmd+Option+I</span> (Mac).</li>
                            <li>In Firefox: Press <span className='background-grey'>Ctrl+Shift+K</span> (Windows) or <span className='background-grey'>Cmd+Option+K</span> (Mac).</li>
                        </ul>
                    </li>
                    <li>Go to the <strong>Console tab</strong>.</li>
                    <li>
                        Type JavaScript code and press Enter. For example:
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Hello, World!"</span>);</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>

                <h5 className='mt-5 mb-3'>Setting Up a Code Editor</h5>
                <h6 className='mt-3'>Option 1: Visual Studio Code (VS Code)</h6>
                <ul style={{ listStyle: 'disc' }}>
                    <li>
                        <strong>Why</strong>: Lightweight, powerful, and popular among developers.
                    </li>
                    <li>
                        <strong>How to Install:</strong>
                        <ul style={{ listStyle: 'decimal' }}>
                            <li>Go to the <a href='https://code.visualstudio.com/' target='_blank'>official VS Code website</a>.</li>
                            <li>Download and install the editor for your operating system.</li>
                            <li>Open VS Code and create a new file with the extension .js (e.g., <span className='background-grey'>script.js</span>).</li>
                        </ul>
                    </li>
                </ul>
                <h6 className='mt-3'>Option 2: Alternatives</h6>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>Notepad++</strong>: Simple and lightweight for beginners.</li>
                    <li><strong>Sublime Text</strong>: A powerful editor with advanced features.</li>
                    <li><strong>Online Editors</strong>: If you don't want to install anything, use tools like <a href='https://jsfiddle.net/' target='_blank'>JSFiddle</a> or <a href='https://codepen.io/' target='_blank'>CodePen</a>.</li>
                </ul>

                
                <h5 className='mt-5 mb-3'>Writing and Running JavaScript</h5>
                <h6 className='mt-3'>Option 1: Inside an HTML File</h6>
                <p>You can write JavaScript directly in an HTML file. Here's how:</p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>Open your editor and create an <span className='background-grey'>index.html</span> file.</li>            
                    <li>Add this code:
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                                <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                                <p class="ml-30">    &lt;head&gt;</p>
                                <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                                <p class="ml-60">        &lt;meta <span class="color-pink">name</span>=<span class="color-green">"viewport"</span> <span class="color-pink">content</span>=<span class="color-green">"width=device-width, initial-scale=1.0"</span>&gt;</p>
                                <p class="ml-60">        &lt;title&gt;JavaScript Example&lt;/title&gt;</p>
                                <p class="ml-30">    &lt;/head&gt;</p>
                                <p class="ml-30">    &lt;body&gt;</p>
                                <p class="ml-60">        &lt;h1&gt;Hello, JavaScript!&lt;/h1&gt;</p>
                                <p class="ml-60">        &lt;script&gt;</p>
                                <p class="ml-90">            <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Hello from JavaScript!"</span>);</p>
                                <p class="ml-90">            <span class="color-red">alert</span>(<span class="color-green">"Welcome to JavaScript!"</span>);</p>
                                <p class="ml-60">        &lt;/script&gt;</p>
                                <p class="ml-30">    &lt;/body&gt;</p>
                                <p>&lt;/html&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>Open the <span className='background-grey'>index.html</span> file in your browser.</li>
                    <li>Open the browser console to see the output.</li>
                </ul>

                <h6 className='mt-3'>Option 2: External JavaScript File</h6>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        Create a JavaScript file, e.g., <span className='background-grey'>script.js</span>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Hello from external JavaScript!"</span>);</p>
                                    <p><span class="color-red">alert</span>(<span class="color-green">"This is an external JavaScript file!"</span>);</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        Link it to your HTML file:
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                                <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                                <p class="ml-30">    &lt;head&gt;</p>
                                <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                                <p class="ml-60">        &lt;meta <span class="color-pink">name</span>=<span class="color-green">"viewport"</span> <span class="color-pink">content</span>=<span class="color-green">"width=device-width, initial-scale=1.0"</span>&gt;</p>
                                <p class="ml-60">        &lt;title&gt;JavaScript External File&lt;/title&gt;</p>
                                <p class="ml-30">    &lt;/head&gt;</p>
                                <p class="ml-30">    &lt;body&gt;</p>
                                <p class="ml-60">        &lt;h1&gt;External JavaScript Example&lt;/h1&gt;</p>
                                <p class="ml-60">        &lt;script <span class="color-pink">src</span>=<span class="color-green">"script.js"</span>&gt;&lt;/script&gt;</p>
                                <p class="ml-30">    &lt;/body&gt;</p>
                                <p>&lt;/html&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>Open the HTML file in your browser to see the result.</li>
                </ul>

                <h5 className='mt-5 mb-3'>(Optional) Setting Up Node.js</h5>
                <p>Node.js allows you to run JavaScript outside the browser.</p>
                <h6 className='mt-3'>Step 1: Download Node.js</h6>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>Visit <a href='https://nodejs.org/en' target='_blank'>Node.js official website</a>.</li>
                    <li>Download the <strong>LTS (Long-Term Support)</strong> version for your operating system.</li>
                    <li>Install Node.js by following the installer instructions.</li>
                </ul>

                <h6 className='mt-3'>Step 2: Verify Installation</h6>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>Open the terminal (Command Prompt or any terminal tool).</li>
                    <li>Type:
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>node -v</p>
                                `
                            }}></div>
                        </div>
                        <p>If it shows a version number (e.g., <span className='background-grey'>v18.16.0</span>), Node.js is installed.</p>
                    </li>
                </ul>

                <h6 className='mt-3'>Step 3: Run JavaScript in Node.js</h6>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        Create a file called <span className='background-grey'>script.js</span> and add this code:
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Hello from Node.js!"</span>);</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>Open the terminal and navigate to the file location.</li>
                    <li>Run:
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>node script.js</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>





                <h5 className='mt-5 mb-3'>You're Ready to Start!</h5>
                <p>With your browser or Node.js, and a code editor like VS Code, you're all set to dive into JavaScript. Start experimenting by writing small programs like:</p>
                <ul style={{listStyle: 'disc'}}>
                    <li>Printing messages to the console.</li>
                    <li>Creating a simple calculator.</li>
                    <li>Making an interactive webpage.</li>
                </ul>

            </div>
        </section>
    )
}