import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/function-closures";

export default function FunctionClosure() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Function Closures in JavaScript | Aspirant's Home");
        const urls = {
            'previous': '/javascript/function-bind',
            'next': '/javascript/arrays'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>What is Closure in JavaScript?</h3>
            <div className='mt-4 mb-5'>
                <p>
                    A <strong>closure</strong> is a feature in JavaScript where an <strong>inner function has access to the variables and functions of its outer function, even after the outer function has finished executing</strong>. This means that a function can "remember" the environment where it was created.
                </p>

                <h5 className='mt-5 mb-3'>Key Points About Closures</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>Access to Outer Scope:</strong>
                        <p>A closure allows the inner function to access variables from its outer function.</p>
                    </li>
                    <li>
                        <strong>Persistence of Data:</strong>
                        <p>Variables in the outer function are "saved" even after the outer function is no longer running.</p>
                    </li>
                    <li>
                        <strong>Encapsulation:</strong>
                        <p>Closures can be used to protect or hide variables, creating a private scope for your data.</p>
                    </li>
                </ul>

                <h5 className='mt-5 mb-3'>How Does a Closure Work?</h5>
                <p>Whenever a function is defined inside another function, the inner function forms a closure by "remembering" the variables of its outer function.</p>

                <h5 className='mt-5 mb-3'>Example 1: A Simple Closure</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">function</span> <span class="color-red">outerFunction</span>() {</p>
                        <p class="ml-30">    <span class="color-blue">let</span> outerVariable = <span class="color-green">"Hello, I'm from outer function!"</span>;</p>
                            
                        <p class="ml-30">    <span class="color-blue">function</span> <span class="color-red">innerFunction</span>() {</p>
                        <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">log</span>(outerVariable); <span class="color-grey">// Accessing the outer variable</span></p>
                        <p class="ml-30">    }</p>
                            
                        <p class="ml-30">    <span class="color-pink">return</span> innerFunction;</p>
                        <p>}</p>
                        
                        <p><span class="color-blue">let</span> myClosure = <span class="color-red">outerFunction</span>(); <span class="color-grey">// outerFunction is executed, returning innerFunction</span></p>
                        <p><span class="color-red">myClosure</span>(); <span class="color-grey">// Output: Hello, I'm from outer function!</span></p>
                        `
                    }}></div>
                </div>
                <p>Here,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span className='background-grey'>innerFunction</span> forms a closure over <span className='background-grey'>outerVariable</span>.</li>
                    <li>Even though <span className='background-grey'>outerFunction</span> has finished executing, <span className='background-grey'>innerFunction</span> can still access <span className='background-grey'>outerVariable</span>.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Why Are Closures Useful?</h5>
                <p>Closures are powerful because they allow you to:</p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li><strong>Maintain State</strong>: Retain data across function calls.</li>
                    <li><strong>Create Private Variables</strong>: Hide variables from the global scope.</li>
                    <li><strong>Build Factories</strong>: Generate functions dynamically with their own private data.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Example 2: Maintaining State with Closures</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">function</span> <span class="color-red">counter</span>() {</p>
                        <p class="ml-30">    <span class="color-blue">let</span> count = <span class="color-pink">0</span>; <span class="color-grey">// A private variable</span></p>
                        
                        <p class="ml-30">    <span class="color-blue">return</span> <span class="color-red">function</span>() {</p>
                        <p class="ml-60">        count<span class="color-pink">++</span>; <span class="color-grey">// Modify the private variable</span></p>
                        <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">log</span>(count);</p>
                        <p class="ml-30">    };</p>
                        <p>}</p>
                        
                        <p><span class="color-blue">let</span> myCounter = <span class="color-red">counter</span>(); <span class="color-grey">// Create a new counter</span></p>
                        <p><span class="color-red">myCounter</span>(); <span class="color-grey">// Output: 1</span></p>
                        <p><span class="color-red">myCounter</span>(); <span class="color-grey">// Output: 2</span></p>
                        <p><span class="color-red">myCounter</span>(); <span class="color-grey">// Output: 3</span></p>
                        `
                    }}></div>
                </div>
                <p>Here, in the example</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span className='background-grey'>count</span> is a private variable inside the <span className='background-grey'>counter</span> function.</li>
                    <li>The returned <span className='background-grey'>inner</span> function (closure) keeps access to <span className='background-grey'>count</span>, even though <span className='background-grey'>counter</span> has finished executing.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Example 3: Encapsulation with Closures</h5>
                <p>Closures help hide variables from the outside world, creating private data.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">function</span> <span class="color-red">createPerson</span>(name) {</p>
                        <p class="ml-30">    <span class="color-blue">let</span> age = <span class="color-pink">30</span>; <span class="color-grey">// Private variable</span></p>
                        
                        <p class="ml-30">    <span class="color-blue">return</span> {</p>
                        <p class="ml-60">        <span class="color-pink">getName</span>: <span class="color-red">function</span>() {</p>
                        <p class="ml-90">            <span class="color-pink">return</span> name;</p>
                        <p class="ml-60">        },</p>
                        <p class="ml-60">        <span class="color-pink">getAge</span>: <span class="color-red">function</span>() {</p>
                        <p class="ml-90">            <span class="color-pink">return</span> age;</p>
                        <p class="ml-60">        },</p>
                        <p class="ml-60">        <span class="color-pink">setAge</span>: <span class="color-red">function</span>(newAge) {</p>
                        <p class="ml-90">            <span class="color-blue">if</span> (newAge > <span class="color-pink">0</span>) {</p>
                        <p class="ml-120">                age = newAge; <span class="color-grey">// Modify private variable</span></p>
                        <p class="ml-90">            }</p>
                        <p class="ml-60">        }</p>
                        <p class="ml-30">    };</p>
                        <p>}
                        
                        <p><span class="color-blue">let</span> person = <span class="color-red">createPerson</span>(<span class="color-green">"Alice"</span>);</p>
                        <p><span class="color-pink">console</span>.<span class="color-red">log</span>(person.<span class="color-red">getName</span>()); <span class="color-grey">// Output: Alice</span></p>
                        <p><span class="color-pink">console</span>.<span class="color-red">log</span>(person.<span class="color-red">getAge</span>());  <span class="color-grey">// Output: 30</span></p>
                        
                        <p>person.<span class="color-red">setAge</span>(<span class="color-pink">35</span>);</p>
                        <p><span class="color-pink">console</span>.<span class="color-red">log</span>(person.<span class="color-red">getAge</span>());  <span class="color-grey">// Output: 35</span></p>
                        
                        <p><span class="color-pink">console</span>.<span class="color-red">log</span>(person.age); <span class="color-grey">// Output: undefined (age is private)</span></p>
                        `
                    }}></div>
                </div>
                <p>Here,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span className='background-grey'>age</span> variable is private and cannot be accessed directly.</li>
                    <li>The returned object provides controlled access through methods.</li>
                </ul>


            </div>
        </section>
    )
}