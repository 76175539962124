import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/jquery/siblings";

export default function Siblings() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("jQuery Traversing - Siblings | Aspirant's Home");
        const urls = {
            'previous': '/jquery/descendants',
            'next': '/jquery/filtering'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>jQuery Traversing - Siblings</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In jQuery, <strong>sibling traversing</strong> allows you to select elements that share the same parent as the selected element. Sibling elements are those that are on the same level in the DOM hierarchy. Traversing siblings is useful for navigating to elements that are directly related to the selected element, either immediately before or after it.
                </p>

                <h5 className='mt-5 mb-3'>Methods for Traversing Siblings in jQuery</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>
                        <h4><span class="background-grey">.siblings()</span></h4>
                        <p>Look at the below example -</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>$(<span class="color-green">"#myElement"</span>).<span class="color-red">siblings</span>().<span class="color-red">css</span>(<span class="color-green">"color"</span>, <span class="color-green">"green"</span>);</p>
                                `
                            }}></div>
                        </div>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>It selects all siblings of the selected element.</li>
                            <li>It returns all sibling elements except the selected element itself</li>
                            <li>It finds all siblings of <span class="background-grey">#myElement</span> and changes their text color to green.</li>
                        </ul>
                    </li>
                    <li className='mt-5'>
                        <h4><span class="background-grey">.next()</span></h4>
                        <p>Look at the below example -</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>$(<span class="color-green">"#myElement"</span>).<span class="color-red">next</span>().<span class="color-red">css</span>(<span class="color-green">"background-color"</span>, <span class="color-green">"lightblue"</span>);</p>
                                `
                            }}></div>
                        </div>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>It selects the immediate next sibling of the selected element.</li>
                            <li>It retrieves only the next element in the DOM order, if it exists.</li>
                            <li>It finds the next sibling of <span class="background-grey">#myElement</span> and changes its background color.</li>
                        </ul>
                    </li>
                    <li className='mt-5'>
                        <h4><span class="background-grey">.nextAll()</span></h4>
                        <p>Look at the below example -</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>$(<span class="color-green">"#myElement"</span>).<span class="color-red">nextAll</span>().<span class="color-red">css</span>(<span class="color-green">"font-weight"</span>, <span class="color-green">"bold"</span>);</p>
                                `
                            }}></div>
                        </div>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>It selects all sibling elements after the selected element.</li>
                            <li>It returns all elements that follow the selected element within the same parent.</li>
                            <li>It finds all siblings that come after <span class="background-grey">#myElement</span> and makes their text bold.</li>
                        </ul>
                    </li>
                    <li className='mt-5'>
                        <h4><span class="background-grey">.nextUntil(selector)</span></h4>
                        <p>Look at the below example -</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>$(<span class="color-green">"#myElement"</span>).<span class="color-red">nextUntil</span>(<span class="color-green">".stopElement"</span>).<span class="color-red">css</span>(<span class="color-green">"font-size"</span>, <span class="color-green">"14px"</span>);</p>
                                `
                            }}></div>
                        </div>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>It selects all siblings after the selected element until it reaches an element matching the specified selector.</li>
                            <li>It excludes the specified element itself.</li>
                            <li>It finds all siblings after <span class="background-grey">#myElement</span> up to (but not including) <span class="background-grey">.stopElement</span> and changes their font size.</li>
                        </ul>
                    </li>
                    <li className='mt-5'>
                        <h4><span class="background-grey">.prev()</span></h4>
                        <p>Look at the below example -</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>$(<span class="color-green">"#myElement"</span>).<span class="color-red">prev</span>().<span class="color-red">css</span>(<span class="color-green">"border"</span>, <span class="color-green">"1px solid red"</span>);</p>
                                `
                            }}></div>
                        </div>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>It selects the immediate previous sibling of the selected element.</li>
                            <li>It retrieves only the preceding element, if it exists.</li>
                            <li>It finds the previous sibling of <span class="background-grey">#myElement</span> and adds a red border.</li>
                        </ul>
                    </li>
                    <li className='mt-5'>
                        <h4><span class="background-grey">.prevAll()</span></h4>
                        <p>Look at the below example -</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>$(<span class="color-green">"#myElement"</span>).<span class="color-red">prevAll</span>().<span class="color-red">css</span>(<span class="color-green">"text-decoration"</span>, <span class="color-green">"underline"</span>);</p>
                                `
                            }}></div>
                        </div>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>It selects all sibling elements before the selected element.</li>
                            <li>It returns all elements that precede the selected element within the same parent.</li>
                            <li>It find all siblings before <span class="background-grey">#myElement</span> and underlines their text.</li>
                        </ul>
                    </li>
                    <li className='mt-5'>
                        <h4><span class="background-grey">.prevUntil(selector)</span></h4>
                        <p>Look at the below example -</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>$(<span class="color-green">"#myElement"</span>).<span class="color-red">prevUntil</span>(<span class="color-green">".startElement"</span>).<span class="color-red">css</span>(<span class="color-green">"opacity"</span>, <span class="color-green">"0.5"</span>);</p>
                                `
                            }}></div>
                        </div>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>It selects all siblings before the selected element until it reaches an element matching the specified selector.</li>
                            <li>It excludes the specified element itself.</li>
                            <li>It find all siblings before <span class="background-grey">#myElement</span> up to (but not including) <span class="background-grey">.startElement</span> and sets their opacity.</li>
                        </ul>
                    </li>
                </ul>




            </div>
        </section>
    )
}