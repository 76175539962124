import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/jquery/filtering";

export default function Filtering() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("jQuery Traversing - Filtering | Aspirant's Home");
        const urls = {
            'previous': '/jquery/siblings',
            'next': '/jquery/ajax'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>jQuery Traversing - Filtering</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In jQuery, <strong>filtering</strong> allows you to refine the elements selected by traversing methods to get exactly the elements you need. This is especially useful when working with large sets of elements and needing only a subset that matches specific criteria. Filtering methods help you narrow down selections by matching certain conditions, making it easier to manipulate or style only the relevant elements.
                </p>

                <h5 className='mt-5 mb-3'>Filtering Methods in jQuery</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>
                        <h4><span class="background-grey">.filter(selector | function)</span></h4>
                        <p>Look at the below example -</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>$(<span class="color-green">"li"</span>).<span class="color-red">filter</span>(<span class="color-green">".active"</span>).<span class="color-red">css</span>(<span class="color-green">"color"</span>, <span class="color-green">"blue"</span>);</p>
                                `
                            }}></div>
                        </div>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>It narrows down the selection to only the elements that match a specified selector or condition.</li>
                            <li>You can use a selector string, a function, or both to specify the filter criteria.</li>
                            <li>It finds all <span class="background-grey">&lt;li&gt;</span> elements with the class <span class="background-grey">active</span> and changes their text color to blue.</li>
                        </ul>
                        <p className='mt-4'>You can filter the element using a <strong>function</strong> also -</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>$(<span class="color-green">"li"</span>).<span class="color-red">filter</span>(<span class="color-red">function</span>() {</p>
                                <p class="ml-30">    <span class="color-blue">return</span> $(this).<span class="color-red">text</span>() === <span class="color-green">"Home"</span>;</p>
                                <p>}).<span class="color-red">css</span>(<span class="color-green">"font-weight"</span>, <span class="color-green">"bold"</span>);</p>
                                `
                            }}></div>
                        </div>
                        <p>It find all <span class="background-grey">&lt;li&gt;</span> elements with text content "Home" and makes them bold.</p>
                    </li>
                    <li className='mt-5'>
                        <h4><span class="background-grey">.not(selector | function)</span></h4>
                        <p>Look at the below example -</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>$(<span class="color-green">"li"</span>).<span class="color-red">not</span>(<span class="color-green">".inactive"</span>).<span class="color-red">css</span>(<span class="color-green">"background-color"</span>, <span class="color-green">"yellow"</span>);</p>
                                `
                            }}></div>
                        </div>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>Excludes elements from the selection that match the specified selector or condition.</li>
                            <li>This method is useful for removing specific elements from a set of selected elements.</li>
                            <li>Finds all <span class="background-grey">&lt;li&gt;</span> elements except those with the class inactive and changes their background color.</li>
                        </ul>
                        <p className='mt-4'>You can filter the element using a <strong>function</strong> also -</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>$(<span class="color-green">"li"</span>).<span class="color-red">not</span>(<span class="color-blue">function</span>() {</p>
                                <p class="ml-30">    <span class="color-blue">return</span> $(this).<span class="color-red">text</span>() === <span class="color-green">"Contact"</span>;</p>
                                <p>}).<span class="color-red">css</span>(<span class="color-green">"text-decoration"</span>, <span class="color-green">"underline"</span>);</p>
                                `
                            }}></div>
                        </div>
                        <p>It find all <span class="background-grey">&lt;li&gt;</span> elements that do not contain the text "Contact" and underlines them.</p>
                    </li>
                    <li className='mt-5'>
                        <h4><span class="background-grey">.has(selector)</span></h4>
                        <p>Look at the below example -</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>$(<span class="color-green">"div"</span>).<span class="color-red">has</span>(<span class="color-green">"p"</span>).<span class="color-red">css</span>(<span class="color-green">"border"</span>, <span class="color-green">"1px solid green"</span>);</p>
                                `
                            }}></div>
                        </div>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>It selects elements that contain at least one descendant matching the specified selector.</li>
                            <li>It often used to find parent elements that have specific child elements.</li>
                            <li>It finds all <span class="background-grey">&lt;div&gt;</span> elements that contain a <span class="background-grey">&lt;p&gt;</span> tag and adds a green border.</li>
                        </ul>
                    </li>
                    <li className='mt-5'>
                        <h4><span class="background-grey">.is(selector)</span></h4>
                        <p>Look at the below example -</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">if</span> ($(<span class="color-green">"li"</span>).<span class="color-red">is</span>(<span class="color-green">".active"</span>)) {</p>
                                <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"There is at least one active list item."</span>);</p>
                                <p>}</p>
                                `
                            }}></div>
                        </div>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>It checks if any of the selected elements match a specific selector.</li>
                            <li>It returns <span class="background-grey">true</span> if there is a match; otherwise, returns <span class="background-grey">false</span>.</li>
                            <li>It often used for conditional logic, rather than for directly filtering the selection.</li>
                            <li>It checks if there is any <span class="background-grey">&lt;li&gt;</span> element with the class <span class="background-grey">active</span> and logs a message if <span class="background-grey">true</span>.</li>
                        </ul>
                    </li>

                    <li className='mt-5'>
                        <h4><span class="background-grey">.slice(start, end)</span></h4>
                        <p>Look at the below example -</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>$(<span class="color-green">"li"</span>).<span class="color-red">slice</span>(<span class="color-pink">1</span>, <span class="color-pink">3</span>).<span class="color-red">css</span>(<span class="color-green">"color"</span>, <span class="color-green">"red"</span>);</p>
                                `
                            }}></div>
                        </div>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>It selects a subset of elements from a larger set based on start and end index positions.</li>
                            <li>It is useful for limiting the number of selected elements, similar to slicing an array.</li>
                            <li>It selects the second and third <span class="background-grey">&lt;li&gt;</span> elements (indices 1 and 2) and changes their text color.</li>
                        </ul>
                    </li>

                    <li className='mt-5'>
                        <h4><span class="background-grey">.first()</span></h4>
                        <p>Look at the below example -</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>$(<span class="color-green">"li"</span>).<span class="color-red">first</span>().<span class="color-red">css</span>(<span class="color-green">"font-size"</span>, <span class="color-green">"20px"</span>);</p>
                                `
                            }}></div>
                        </div>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>It selects only the first element from the set of matched elements.</li>
                            <li>It finds the first <span class="background-grey">&lt;li&gt;</span> element and increases its font size.</li>
                        </ul>
                    </li>

                    <li className='mt-5'>
                        <h4><span class="background-grey">.last()</span></h4>
                        <p>Look at the below example -</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>$(<span class="color-green">"li"</span>).<span class="color-red">last</span>().<span class="color-red">css</span>(<span class="color-green">"font-size"</span>, <span class="color-green">"20px"</span>);</p>
                                `
                            }}></div>
                        </div>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>It selects only the last element from the set of matched elements.</li>
                            <li>It finds the last <span class="background-grey">&lt;li&gt;</span> element and increases its font size.</li>
                        </ul>
                    </li>

                    <li className='mt-5'>
                        <h4><span class="background-grey">.eq(index)</span></h4>
                        <p>Look at the below example -</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>$(<span class="color-green">"li"</span>).<span class="color-red">eq</span>(<span class="color-pink">2</span>).<span class="color-red">css</span>(<span class="color-green">"color"</span>, <span class="color-green">"purple"</span>);</p>
                                `
                            }}></div>
                        </div>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>It selects a specific element based on its index within the selection.</li>
                            <li>It finds the third <span class="background-grey">&lt;li&gt;</span> element (index 2) and changes its text color to purple.</li>
                        </ul>
                    </li>



                </ul>


                
            </div>
        </section>
    )
}