import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/events/onkeydown";

export default function Onkeydown() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - onkeydown Event | Aspirant's Home");
        const urls = {
            'previous': '/html/events'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - onkeydown Event</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">onkeydown</span> event in HTML is triggered when a user presses down on a key on the keyboard. This event is typically used to detect when a specific key is pressed, enabling features like keyboard shortcuts, form navigation, real-time character validation, or initiating actions through keyboard interaction. The <span class="background-grey">onkeydown</span> event can capture most keys, including letters, numbers, function keys, and even special keys like "Shift," "Enter," and "Control."
                </p>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <p>To use <span class="background-grey">onkeydown</span>, add it as an attribute to an HTML element and specify JavaScript code or a function that should run whenever a key is pressed down within that element.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">onkeydown</span>=<span class="color-green">"alert('Key pressed!')"</span> <span class="color-pink">placeholder</span>=<span class="color-green">"Type here"</span>&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Every time a key is pressed down while typing in the input field, an alert is displayed.</p>


                <h5 className='mt-5 mb-3'>Example: Detecting Specific Key Presses</h5>
                <p>
                    You can use <span class="background-grey">onkeydown</span> to check which specific key was pressed by accessing the <span class="background-grey">event.key</span> property. This is useful for creating keyboard shortcuts or triggering actions based on specific keys.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">onkeydown</span>=<span class="color-green">"checkKey(event)"</span> <span class="color-pink">placeholder</span>=<span class="color-green">"Press Enter"</span>&gt;</p>
                        <br />
                        <p>&lt;script&gt;</p>
                        <p class="ml-30">  <span class="color-blue">function</span> <span class="color-red">checkKey</span>(event) {</p>
                        <p class="ml-60">    <span class="color-blue">if</span> (event.key === "Enter") {</p>
                        <p class="ml-90">      <span class="color-red">alert</span>(<span class="color-green">"You pressed the Enter key!"</span>);</p>
                        <p class="ml-60">    }</p>
                        <p class="ml-30">  }</p>
                        <p>&lt;/script&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span class="background-grey">checkKey</span> function checks if the "Enter" key was pressed.</li>
                    <li>If "Enter" is pressed, it displays an alert saying "You pressed the Enter key!"</li>
                </ul>


                <h5 className='mt-5 mb-3'>Example: Restricting Input to Numbers Only</h5>
                <p>You can use <span class="background-grey">onkeydown</span> to restrict input so that only specific characters, like numbers, are allowed in an input field.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">onkeydown</span>=<span class="color-green">"allowNumbersOnly(event)"</span> <span class="color-pink">placeholder</span>=<span class="color-green">"Enter numbers only"</span>&gt;</p>
                        <br />
                        <p>&lt;script&gt;</p>
                        <p class="ml-30">  <span class="color-blue">function</span> <span class="color-red">allowNumbersOnly</span>(event) {</p>
                        <p class="ml-60">    <span class="color-blue">if</span> (<span class="color-red">isNaN</span>(event.key) && event.key !== <span class="color-green">"Backspace"</span>) {</p>
                        <p class="ml-90">      event.<span class="color-red">preventDefault</span>(); <span class="color-grey">// Prevents typing of non-numeric characters</span></p>
                        <p class="ml-60">    }</p>
                        <p class="ml-30">  }</p>
                        <p>&lt;/script&gt;</p>
                        `
                    }}></div>
                </div>
                <p>The <span class="background-grey">allowNumbersOnly</span> function prevents typing any non-numeric characters (except "Backspace") by calling <span class="background-grey">event.preventDefault()</span> for those keys.</p>


            </div>
        </section>
    )
}