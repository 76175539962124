import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/array-iteration";

export default function ArrayIteration() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Array Iteration in Javascript | Aspirant's Home");
        const urls = {
            'previous': '/javascript/array-sort',
            'next': '/javascript/dates'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Array Iteration</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Array iteration means going through each element of an array, one by one, to perform actions like displaying values, modifying elements, or applying logic to them. JavaScript provides several methods to iterate over arrays efficiently.
                </p>

                <ul style={{ listStyle: 'decimal' }}>
                    <li className='operator mt-5'>
                        <h5><span className='background-grey'>for</span> Loop</h5>
                        <p>The traditional <span className='background-grey'>for</span> loop is commonly used for array iteration. You control the start, end, and step of the iteration.</p>
                        <h6>Example:</h6>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> fruits = [<span class="color-green">"Apple"</span>, <span class="color-green">"Banana"</span>, <span class="color-green">"Cherry"</span>];</p>
                                <br />
                                <p><span class="color-blue">for</span> (<span class="color-blue">let</span> i = <span class="color-pink">0</span>; i < fruits.length; i++) {</p>
                                <p class="ml-30">  <span class="color-pink">console</span>.<span class="color-red">log</span>(fruits[i]); <span class="color-grey">// Output: Apple, Banana, Cherry</span></p>
                                <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator mt-5'>
                        <h5><span className='background-grey'>forEach()</span> Method</h5>
                        <p>The <span className='background-grey'>forEach()</span> method executes a function for each element in the array. It's simple and avoids managing loop counters manually.</p>
                        <h6>Syntax:</h6>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>array.<span class="color-red">forEach</span>(function(element, index, array) {</p>
                                <p class="ml-30 color-grey">    // Code to execute</p>
                                <p>});</p>
                                `
                            }}></div>
                        </div>
                        <h6>Example:</h6>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> fruits = [<span class="color-green">"Apple"</span>, <span class="color-green">"Banana"</span>, <span class="color-green">"Cherry"</span>];</p>
                                <br />
                                <p>fruits.<span class="color-red">forEach</span>((fruit, index) => {</p>
                                <p class="ml-30">  <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">&#96;Index: $&#123;index&#125;, Fruit: $&#123;fruit&#125;&#96;</span>);</p>
                                <p>});</p>
                                <p class="color-grey">// Output:</p>
                                <p class="color-grey">// Index: 0, Fruit: Apple</p>
                                <p class="color-grey">// Index: 1, Fruit: Banana</p>
                                <p class="color-grey">// Index: 2, Fruit: Cherry</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator mt-5'>
                        <h5><span className='background-grey'>for...of</span> Loop</h5>
                        <p>The <span className='background-grey'>for...of</span> <strong>loop</strong> is used to iterate over the <strong>values</strong> of an array directly.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> fruits = [<span class="color-green">"Apple"</span>, <span class="color-green">"Banana"</span>, <span class="color-green">"Cherry"</span>];</p>
                                <br />
                                <p><span class="color-blue">for</span> (<span class="color-blue">let</span> fruit <span class="color-pink">of</span> fruits) {</p>
                                <p class="ml-30">  <span class="color-pink">console</span>.<span class="color-red">log</span>(fruit); <span class="color-grey">// Output: Apple, Banana, Cherry</span></p>
                                <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator mt-5'>
                        <h5><span className='background-grey'>for...in</span> Loop</h5>
                        <p>The <span className='background-grey'>for...in</span> <strong>loop</strong> iterates over the <strong>indexes (keys)</strong> of an array. Use it if you need the index of each element.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> fruits = [<span class="color-green">"Apple"</span>, <span class="color-green">"Banana"</span>, <span class="color-green">"Cherry"</span>];</p>
                                <br />
                                <p><span class="color-blue">for</span> (<span class="color-blue">let</span> index <span class="color-pink">in</span> fruits) {</p>
                                <p class="ml-30">  <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">&#96;Index: $&#123;index&#125;, Fruit: $&#123;fruits[index]&#125;&#96;</span>);</p>
                                <p>}</p>
                                <p class="color-grey">// Output:</p>
                                <p class="color-grey">// Index: 0, Fruit: Apple</p>
                                <p class="color-grey">// Index: 1, Fruit: Banana</p>
                                <p class="color-grey">// Index: 2, Fruit: Cherry</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator mt-5'>
                        <h5><span className='background-grey'>map()</span> Method</h5>
                        <p>The <span className='background-grey'>map()</span> method creates a <strong>new array</strong> by applying a function to each element of the array. It doesn’t modify the original array.</p>
                        <h6>Syntax</h6>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>array.<span class="color-red">map</span>(function(element, index, array) {</p>
                                <p class="ml-30 color-grey">    // Return transformed value</p>
                                <p>});</p>
                                `
                            }}></div>
                        </div>
                        <h6>Example</h6>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> numbers = [<span class="color-pink">1</span>, <span class="color-pink">2</span>, <span class="color-pink">3</span>, <span class="color-pink">4</span>];</p>
                                <br />
                                <p><span class="color-blue">let</span> squares = numbers.<span class="color-red">map</span>(num => num * num);</p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(squares); <span class="color-grey">// Output: [1, 4, 9, 16]</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator mt-5'>
                        <h5><span className='background-grey'>filter()</span> Method</h5>
                        <p>The <span className='background-grey'>filter()</span> method creates a new array containing only the elements that satisfy a given condition.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> numbers = [<span class="color-pink">1</span>, <span class="color-pink">2</span>, <span class="color-pink">3</span>, <span class="color-pink">4</span>, <span class="color-pink">5</span>];</p>
                                <br />
                                <p><span class="color-blue">let</span> evenNumbers = numbers.<span class="color-red">filter</span>(num => num % <span class="color-pink">2</span> === <span class="color-pink">0</span>);</p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(evenNumbers); <span class="color-grey">// Output: [2, 4]</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator mt-5'>
                        <h5><span className='background-grey'>reduce()</span> Method</h5>
                        <p>The <span className='background-grey'>reduce()</span> method applies a function to <strong>accumulate</strong> all the elements into a single value (like sum, product, etc.).</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> numbers = [<span class="color-pink">1</span>, <span class="color-pink">2</span>, <span class="color-pink">3</span>, <span class="color-pink">4</span>];</p>
                                <br />
                                <p><span class="color-blue">let</span> sum = numbers.<span class="color-red">reduce</span>((accumulator, current) => accumulator + current, <span class="color-pink">0</span>);</p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(sum); <span class="color-grey">// Output: 10</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator mt-5'>
                        <h5><span className='background-grey'>every()</span> and <span className='background-grey'>some()</span> Methods</h5>
                        <p><span className='background-grey'>every()</span> checks if <strong>all elements</strong> satisfy a condition. <span className='background-grey'>some()</span> checks if <strong>at least one element</strong> satisfies a condition.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> numbers = [<span class="color-pink">2</span>, <span class="color-pink">4</span>, <span class="color-pink">6</span>, <span class="color-pink">8</span>];</p>
                                <br />
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(numbers.<span class="color-red">every</span>(num => num % 2 === 0)); <span class="color-grey">// Output: true</span></p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(numbers.some(num => num > <span class="color-pink">5</span>));       <span class="color-grey">// Output: true</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>


            </div>
        </section>
    )
}