import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/jquery/events/one";

export default function One() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("jQuery one() Method | Aspirant's Home");
        const urls = {
            'previous': '/jquery/events'
        }
        path.setPreviousNext(urls);
        path.setPreviousName('Back to Events');
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>jQuery one() Method</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">one()</span> method in jQuery attaches an event handler to an element that is executed only once. After the event is triggered, the handler is automatically removed, ensuring the event doesn't fire multiple times for the same element.
                </p>

                <p>It has a basic syntax -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(selector).<span class="color-red">one</span>(event, childSelector, data, function);</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">event</span> is the type of event to bind (e.g., click, mouseenter).</li>
                    <li><span class="background-grey">childSelector</span> (optional) is a selector string to filter child elements that trigger the event (used for event delegation).</li>
                    <li><span class="background-grey">data</span> (optional) is additional data to pass to the event handler.</li>
                    <li><span class="background-grey">function</span> is the callback function to execute when the event occurs.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Example 1: Basic Usage of <span class="background-grey">one()</span></h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;button <span class="color-pink">id</span>=<span class="color-green">"btn"</span>&gt;Click Me&lt;/button&gt;</p>
                        `
                    }}></div>
                </div>
                <h6>jQuery Code</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"#btn"</span>).<span class="color-red">one</span>(<span class="color-green">"click"</span>, <span class="color-blue">function</span>() {</p>
                        <p class="ml-30">    <span class="color-red">alert</span>(<span class="color-green">"Button clicked!"</span>);</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>Clicking the button shows an alert.</li>
                    <li>Subsequent clicks do nothing because the event handler is removed after the first execution.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Example 2: Event Delegation with <span class="background-grey">one()</span></h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;div <span class="color-red">id</span>=<span class="color-green">"container"</span>&gt;</p>
                        <p class="ml-30">    &lt;button <span class="color-pink">class</span>=<span class="color-green">"child"</span>&gt;Child Button&lt;/button&gt;</p>
                        <p>&lt;/div&gt;</p>
                        <p>&lt;button <span class="color-pink">id</span>=<span class="color-green">"addButton"</span>&gt;Add Child&lt;/button&gt;</p>
                        `
                    }}></div>
                </div>
                <h6>jQuery Code</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p class="color-grey">// Event delegation with one()</p>
                        <p>$(<span class="color-green">"#container"</span>).<span class="color-red">one</span>(<span class="color-green">"click"</span>, <span class="color-green">".child"</span>, <span class="color-blue">function</span>() {</p>
                        <p class="ml-30">    <span class="color-red">alert</span>(<span class="color-green">"Child button clicked!"</span>);</p>
                        <p>});</p>
                        <br />
                        <p class="color-grey">// Adding new buttons dynamically</p>
                        <p>$(<span class="color-green">"#addButton"</span>).<span class="color-red">on</span>(<span class="color-green">"click"</span>, <span class="color-blue">function</span>() {</p>
                        <p class="ml-30">    $(<span class="color-green">"#container"</span>).<span class="color-red">append</span>(<span class="color-green">'&lt;button class="child"&gt;New Child&lt;/button&gt;'</span>);</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>Only the first click on any <span class="background-grey">.child</span> button triggers the alert, even for dynamically added buttons.</li>
                </ul>


            </div>
        </section>
    )
}