import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/jquery/css-classes";

export default function CSSClasses() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("jQuery - Get and Set CSS Classes | Aspirant's Home");
        const urls = {
            'previous': '/jquery/remove',
            'next': '/jquery/css-function'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>jQuery - Get and Set CSS Classes</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In jQuery, you can <strong>get and set CSS classes</strong> on elements easily with methods that allow you to add, remove, toggle, and check for classes. These methods help manipulate the appearance and behavior of elements dynamically.
                </p>

                <h5 className='mt-5 mb-3'>Getting Classes</h5>
                <p>To check if an element has a specific class, use the <span class="background-grey">.hasClass()</span> method.</p>

                <p>The <span class="background-grey">.hasClass()</span> method checks if an element has a particular class and returns <span class="background-grey">true</span> if the class exists or <span class="background-grey">false</span> if it doesn't. Take a look at the below example -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">if</span> ($(<span class="color-green">"#myElement"</span>).<span class="color-red">hasClass</span>(<span class="color-green">"highlight"</span>)) {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Element has the 'highlight' class."</span>);</p>
                        <p>} <span class="color-blue">else</span> {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Element does not have the 'highlight' class."</span>);</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>
                <p>Above function checks if <span class="background-grey">#myElement</span> has the <span class="background-grey">highlight</span> class.</p>


                <h5 className='mt-5 mb-3'>Adding Classes</h5>
                <p>To add a CSS class to an element, you can use the <span class="background-grey">.addClass()</span> method. </p>

                <p>The <span class="background-grey">.addClass()</span> method adds one or more classes to the selected element(s). Take a look at below examples -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(<span class="color-green">"#myElement"</span>).<span class="color-red">addClass</span>(<span class="color-green">"highlight"</span>);</p>
                        `
                    }}></div>
                </div>
                <p>It adds the <span class="background-grey">highlight</span> class to <span class="background-grey">#myElement</span>. The element <span class="background-grey">#myElement</span> now has the <span class="background-grey">highlight</span> class, applying any associated CSS styling.</p>

                <p>You can also add multiple classes by passing them as a space-separated string.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(<span class="color-green">"#myElement"</span>).<span class="color-red">addClass</span>(<span class="color-green">"highlight border"</span>);</p>
                        `
                    }}></div>
                </div>
                <p>It adds both <span class="background-grey">highlight</span> and <span class="background-grey">border</span> classes to <span class="background-grey">#myElement</span>.</p>


                <h5 className='mt-5 mb-3'>Removing Classes</h5>
                <p>To remove a CSS class from an element, use the <span class="background-grey">.removeClass()</span> method.</p>

                <p>The <span class="background-grey">.removeClass()</span> method removes one or more classes from the selected element(s). Look at the below example -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(<span class="color-green">"#myElement"</span>).<span class="color-red">removeClass</span>(<span class="color-green">"highlight"</span>);</p>
                        `
                    }}></div>
                </div>
                <p>It removes the <span class="background-grey">highlight</span> class from <span class="background-grey">#myElement</span>. The element <span class="background-grey">#myElement</span> no longer has the <span class="background-grey">highlight</span> class.</p>
                <p>Like with <span class="background-grey">.addClass()</span>, you can remove multiple classes by passing them as a space-separated string.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(<span class="color-green">"#myElement"</span>).<span class="color-red">removeClass</span>(<span class="color-green">"highlight border"</span>);</p>
                        `
                    }}></div>
                </div>
                <p>It removes both <span class="background-grey">highlight</span> and <span class="background-grey">border</span> classes from <span class="background-grey">#myElement</span>.</p>


                <h5 className='mt-5 mb-3'>Toggling Classes</h5>
                <p>To add a class if it’s missing or remove it if it’s present, use the .toggleClass() method.</p>
                <p>The .toggleClass() method toggles a class on and off based on whether it’s already present. Look at the below example -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(<span class="color-green">"#myElement"</span>).<span class="color-red">toggleClass</span>(<span class="color-green">"highlight"</span>);</p>
                        `
                    }}></div>
                </div>
                <p>It adds the <span class="background-grey">highlight</span> class to <span class="background-grey">#myElement</span> if it’s not already there or removes it if it is. The <span class="background-grey">highlight</span> class toggles on and off each time <span class="background-grey">.toggleClass()</span> is called.</p>

                <p>You can also use .toggleClass() with multiple classes or with a boolean parameter.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(<span class="color-green">"#myElement"</span>).<span class="color-red">toggleClass</span>(<span class="color-green">"highlight"</span>, <span class="color-blue">true</span>);</p>
                        `
                    }}></div>
                </div>
                <p>It adds the <span class="background-grey">highlight</span> class to <span class="background-grey">#myElement</span> (if the boolean parameter is <span class="background-grey">true</span>) or removes it (if <span class="background-grey">false</span>).</p>




            </div>
        </section>
    )
}