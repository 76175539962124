import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/tag/label";

export default function Label() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - <label> Tag | Aspirant's Home");
        const urls = {
            'previous': '/html/tag-list'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - &lt;label&gt; Tag</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">&lt;label&gt;</span> tag in HTML is used to provide a text label for form elements, such as <span class="background-grey">&lt;input&gt;</span>, <span class="background-grey">&lt;textarea&gt;</span>, <span class="background-grey">&lt;select&gt;</span>, etc. It improves accessibility by helping screen readers identify what each form element does, and it also enhances the user experience by making form elements easier to interact with.
                </p>
                        
                <h5 className='mt-5 mb-3'>Why Use <span class="background-grey">&lt;label&gt;</span> ?</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>Labels help screen readers understand the purpose of each form element.</li>
                    <li>Associating a label with a form element allows users to click the label to activate or focus on the corresponding input element.</li>
                    <li>Labels provide clear descriptions of form fields, making forms easier to understand.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Basic Structure of the <span class="background-grey">&lt;label&gt;</span> Tag</h5>
                <p>The <span class="background-grey">&lt;label&gt;</span> tag is often paired with an <span class="background-grey">id</span> attribute on the form element it describes and uses the <span class="background-grey">for</span> attribute to link the label to that element.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;label <span class="color-pink">for</span>=<span class="color-green">"username"</span>&gt;Username:&lt;/label&gt;</p>
                            <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">id</span>=<span class="color-green">"username"</span> <span class="color-pink">name</span>=<span class="color-green">"username"</span>&gt;</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Associating <span class="background-grey">&lt;label&gt;</span> with Form Elements</h5>
                <p>There are two primary ways to use <span class="background-grey">&lt;label&gt;</span>:</p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>Using the <span class="background-grey">for</span> Attribute</strong>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>This method requires that the form element has a unique <span class="background-grey">id</span>.</li>
                            <li>When the user clicks on the label, the associated form element will be focused or activated.</li>
                        </ul>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>&lt;label <span class="color-pink">for</span>=<span class="color-green">"email"</span>&gt;Email:&lt;/label&gt;</p>
                                <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"email"</span> <span class="color-pink">id</span>=<span class="color-green">"email"</span> <span class="color-pink">name</span>=<span class="color-green">"email"</span>&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Wrapping the Form Element Inside <span class="background-grey">&lt;label&gt;</span></strong>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>This method does not require an <span class="background-grey">id</span> attribute.</li>
                            <li>The label and form element are directly associated by wrapping the input within the label.</li>
                        </ul>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>&lt;label&gt;</p>
                                <p class="ml-30">    Password:</p>
                                <p class="ml-30">    &lt;input <span class="color-pink">type</span>=<span class="color-green">"password"</span> <span class="color-pink">name</span>=<span class="color-green">"password"</span>&gt;</p>
                                <p>&lt;/label&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>


            </div>
        </section>
    )
}