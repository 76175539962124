import React, { useEffect, useState, useRef } from 'react'
import { Outlet, Link } from "react-router-dom";
import TopHeader from '../TopHeader';
import Footer from '../Footer'
import { MenuContext, TitleContext, PathContext } from "../../Context";
import { useContext } from "react";
import PreviousNext from '../PreviousNext';

export default function AngularLayout() {

    const addspaceElement = useRef();
    const menuElement = useRef([]);
    const menu = useContext(MenuContext);
    const pathName = useContext(PathContext);
    console.log(pathName)
    const pathname = pathName.pathName;
    const [showSubMenu, setShowSubMenu] = useState(document.getElementsByClassName("activeSubMenu")[0] ? false : true)
    const title = useContext(TitleContext);

    const setPathName = (path) => {
        if(path)
            pathName.setPathName(path);
    }

    useEffect(() => {
        setPathName(window.location.pathname)
        pathName.setPreviousName('Previous');
        //console.log(pathName.backname)
        if (menu.isMenu == true) menu.setMenuOpen(false)
        addspaceElement.current.scrollIntoView({ block: "center" })
        const element = document.getElementsByClassName("activeMenu")[0];
        element && element.scrollIntoView({ block: "center" });
        const subelement = document.getElementsByClassName("activeSubMenu")[0];
        subelement && subelement.scrollIntoView({ block: "center" });
    }, [pathname,showSubMenu])

    return (
        <>
            <TopHeader />
            <div className='container-fluid'>
                <div className='row' style={{background:'#ddd'}}>
                    <div ref={menuElement} className={menu.isMenu == true ? 'col-md-3 col-lg-2 menuColumnMobile menuColumn p-0' : ' menuColumn p-0'} >
                        <div className='d-flex align-items-center headerMenu headerMainMenu sticky-top mb-4' style={{ 'height': '150px' }}>
                            <div>
                                <h2>Angular Tutorial</h2>
                                <Link to="/quiz/angular" className='rounded-corner btn btn-primary float-end'><small>Get Quiz</small></Link>
                            </div>
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/angular/introduction' ? 'activeMenu' : ''}>
                                    <Link to="/angular/introduction" onClick={() => { setPathName('/angular/introduction'); } }>Introduction</Link>
                                </li>
                                <li className={pathname == '/angular/environment-setup' ? 'activeMenu' : ''}>
                                    <Link to="/angular/environment-setup" onClick={() => { setPathName('/angular/environment-setup'); } }>Environment Setup and Creating First App</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            Structure
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/angular/folder-structure' ? 'activeMenu' : ''}>
                                    <Link to="/angular/folder-structure" onClick={() => { setPathName('/angular/folder-structure'); } }>Folder Structure</Link>
                                </li>
                                <li className={pathname == '/angular/architecture' ? 'activeMenu' : ''}>
                                    <Link to="/angular/architecture" onClick={() => { setPathName('/angular/modules-components'); } }>Architecture</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            Components
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/angular/creating-and-using-components' ? 'activeMenu' : ''}>
                                    <Link to="/angular/creating-and-using-components" onClick={() => { setPathName('/angular/creating-and-using-components'); } }>Creating and Using Components</Link>
                                </li>
                                <li className={pathname == '/angular/component-interaction' ? 'activeMenu' : ''}>
                                    <Link to="/angular/component-interaction" onClick={() => { setPathName('/angular/component-interaction'); } }>Component Interaction</Link>
                                </li>
                                <li className={pathname == '/angular/lifecycle-hooks' ? 'activeMenu' : ''}>
                                    <Link to="/angular/lifecycle-hooks" onClick={() => { setPathName('/angular/lifecycle-hooks'); } }>Lifecycle Hooks</Link>
                                </li>
                                <li className={pathname == '/angular/viewchild-and-contentchild' ? 'activeMenu' : ''}>
                                    <Link to="/angular/viewchild-and-contentchild" onClick={() => { setPathName('/angular/viewchild-and-contentchild'); } }>ViewChild and ContentChild</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            Data Binding
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/angular/interpolation' ? 'activeMenu' : ''}>
                                    <Link to="/angular/interpolation" onClick={() => { setPathName('/angular/interpolation'); } }>Interpolation</Link>
                                </li>
                                <li className={pathname == '/angular/property-binding' ? 'activeMenu' : ''}>
                                    <Link to="/angular/property-binding" onClick={() => { setPathName('/angular/property-binding'); } }>Property Binding</Link>
                                </li>
                                <li className={pathname == '/angular/event-binding' ? 'activeMenu' : ''}>
                                    <Link to="/angular/event-binding" onClick={() => { setPathName('/angular/event-binding'); } }>Event Binding</Link>
                                </li>
                                <li className={pathname == '/angular/two-way-binding' ? 'activeMenu' : ''}>
                                    <Link to="/angular/two-way-binding" onClick={() => { setPathName('/angular/two-way-binding'); } }>Two-Way Binding</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            Directives
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/angular/structural-directives' ? 'activeMenu' : ''}>
                                    <Link to="/angular/structural-directives" onClick={() => { setPathName('/angular/structural-directives'); } }>Structural Directives</Link>
                                </li>
                                <li className={pathname == '/angular/attribute-directives' ? 'activeMenu' : ''}>
                                    <Link to="/angular/attribute-directives" onClick={() => { setPathName('/angular/attribute-directives'); } }>Attribute Directives</Link>
                                </li>
                                <li className={pathname == '/angular/creating-custom-directives' ? 'activeMenu' : ''}>
                                    <Link to="/angular/creating-custom-directives" onClick={() => { setPathName('/angular/creating-custom-directives'); } }>Creating Custom Directives</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            Forms
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/angular/template-driven-forms' ? 'activeMenu' : ''}>
                                    <Link to="/angular/template-driven-forms" onClick={() => { setPathName('/angular/template-driven-forms'); } }>Template-Driven Forms</Link>
                                </li>
                                <li className={pathname == '/angular/reactive-forms' ? 'activeMenu' : ''}>
                                    <Link to="/angular/reactive-forms" onClick={() => { setPathName('/angular/reactive-forms'); } }>Reactive Forms</Link>
                                </li>
                                <li className={pathname == '/angular/form-validation' ? 'activeMenu' : ''}>
                                    <Link to="/angular/form-validation" onClick={() => { setPathName('/angular/form-validation'); } }>Form Validation</Link>
                                </li>
                                <li className={pathname == '/angular/handling-form-submissions' ? 'activeMenu' : ''}>
                                    <Link to="/angular/handling-form-submissions" onClick={() => { setPathName('/angular/handling-form-submissions'); } }>Handling Form Submissions</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            Services
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/angular/creating-services' ? 'activeMenu' : ''}>
                                    <Link to="/angular/creating-services" onClick={() => { setPathName('/angular/creating-services'); } }>Creating Services</Link>
                                </li>
                                <li className={pathname == '/angular/injecting-services' ? 'activeMenu' : ''}>
                                    <Link to="/angular/injecting-services" onClick={() => { setPathName('/angular/injecting-services'); } }>Injecting Services</Link>
                                </li>
                                <li className={pathname == '/angular/http-client-module' ? 'activeMenu' : ''}>
                                    <Link to="/angular/http-client-module" onClick={() => { setPathName('/angular/http-client-module'); } }>HTTP Client Module</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            Routing
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/angular/setting-up-routing' ? 'activeMenu' : ''}>
                                    <Link to="/angular/setting-up-routing" onClick={() => { setPathName('/angular/setting-up-routing'); } }>Setting Up Routing</Link>
                                </li>
                                <li className={pathname == '/angular/navigating-between-routes' ? 'activeMenu' : ''}>
                                    <Link to="/angular/navigating-between-routes" onClick={() => { setPathName('/angular/navigating-between-routes'); } }>Navigating Between Routes</Link>
                                </li>
                                <li className={pathname == '/angular/route-guards' ? 'activeMenu' : ''}>
                                    <Link to="/angular/route-guards" onClick={() => { setPathName('/angular/route-guards'); } }>Route Guards</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            Modules
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/angular/root-module' ? 'activeMenu' : ''}>
                                    <Link to="/angular/root-module" onClick={() => { setPathName('/angular/root-module'); } }>Root Module</Link>
                                </li>
                                <li className={pathname == '/angular/feature-modules' ? 'activeMenu' : ''}>
                                    <Link to="/angular/feature-modules" onClick={() => { setPathName('/angular/feature-modules'); } }>Feature Modules</Link>
                                </li>
                                <li className={pathname == '/angular/shared-modules' ? 'activeMenu' : ''}>
                                    <Link to="/angular/shared-modules" onClick={() => { setPathName('/angular/shared-modules'); } }>Shared Modules</Link>
                                </li>
                                <li className={pathname == '/angular/lazy-loaded-modules' ? 'activeMenu' : ''}>
                                    <Link to="/angular/lazy-loaded-modules" onClick={() => { setPathName('/angular/lazy-loaded-modules'); } }>Lazy Loaded Modules</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            HTTP and APIs
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/angular/making-http-requests' ? 'activeMenu' : ''}>
                                    <Link to="/angular/making-http-requests" onClick={() => { setPathName('/angular/making-http-requests'); } }>Making HTTP Requests</Link>
                                </li>
                                <li className={pathname == '/angular/handling-http-responses' ? 'activeMenu' : ''}>
                                    <Link to="/angular/handling-http-responses" onClick={() => { setPathName('/angular/handling-http-responses'); } }>Handling HTTP Responses</Link>
                                </li>
                                <li className={pathname == '/angular/interceptors' ? 'activeMenu' : ''}>
                                    <Link to="/angular/interceptors" onClick={() => { setPathName('/angular/interceptors'); } }>Interceptors</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            Pipes
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/angular/using-built-in-pipes' ? 'activeMenu' : ''}>
                                    <Link to="/angular/using-built-in-pipes" onClick={() => { setPathName('/angular/using-built-in-pipes'); } }>Using Built-in Pipes</Link>
                                </li>
                                <li className={pathname == '/angular/creating-custom-pipes' ? 'activeMenu' : ''}>
                                    <Link to="/angular/creating-custom-pipes" onClick={() => { setPathName('/angular/creating-custom-pipes'); } }>Creating Custom Pipes</Link>
                                </li>
                                <li className={pathname == '/angular/pure-vs-impure-pipes' ? 'activeMenu' : ''}>
                                    <Link to="/angular/pure-vs-impure-pipes" onClick={() => { setPathName('/angular/pure-vs-impure-pipes'); } }>Pure vs. Impure Pipes</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            Misc.
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/angular/state-management' ? 'activeMenu' : ''}>
                                    <Link to="/angular/state-management" onClick={() => { setPathName('/angular/state-management'); } }>State Management</Link>
                                </li>
                                <li className={pathname == '/angular/rxjs-observables' ? 'activeMenu' : ''}>
                                    <Link to="/angular/rxjs-observables" onClick={() => { setPathName('/angular/rxjs-observables'); } }>RxJS Observables</Link>
                                </li>
                                <li className={pathname == '/angular/ivy-renderer' ? 'activeMenu' : ''}>
                                    <Link to="/angular/ivy-renderer" onClick={() => { setPathName('/angular/ivy-renderer'); } }>Ivy Renderer</Link>
                                </li>
                                <li className={pathname == '/angular/testing' ? 'activeMenu' : ''}>
                                    <Link to="/angular/testing" onClick={() => { setPathName('/angular/testing'); } }>Testing</Link>
                                </li>
                                <li className={pathname == '/angular/deployment' ? 'activeMenu' : ''}>
                                    <Link to="/angular/deployment" onClick={() => { setPathName('/angular/deployment'); } }>Deployment</Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div className='col-12 middlePart'>
                        
                        <div className='headerAdSpace' ref={addspaceElement}>
                            <img className='img-fluid' src='https://aspirantshome.com/slide1.png' />
                        </div>
                        <div className='contentPart row sticky-top'>
                            <div className='col-12 col-md-12 mb-4 contentSection'>
                                <div className='container'>
                                    <div className='contentPage'>
                                        {
                                            pathName.urls ? <PreviousNext urls={ pathName.urls } name={pathName.backname} /> : <></>
                                        }
                                            <Outlet />
                                        {
                                            pathName.urls ? <PreviousNext urls={ pathName.urls } /> : <></>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='footerAdSpace'>
                                <img className='img-fluid' src='https://aspirantshome.com/slide4.png' />
                            </div>
                        </div>

                        
                    </div>
                    <div className='col-12  rightPart'>
                        <img className='img-fluid' src='https://aspirantshome.com/slide2.png' />
                        <img className='mt-5 img-fluid' src='https://aspirantshome.com/slide3.png' />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <div className='container-fluid footerPart'>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}