import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/jquery/events/event-which";

export default function Which() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("jQuery event.which Object | Aspirant's Home");
        const urls = {
            'previous': '/jquery/events'
        }
        path.setPreviousNext(urls);
        path.setPreviousName('Back to Events');
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>jQuery event.which Object</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">event.which</span> property in jQuery is used to identify which <strong>mouse button</strong>, <strong>keyboard key</strong>, or <strong>button on other input devices</strong> was pressed during an event. It returns a number representing the corresponding button or key.
                </p>

                <p>It has a basic syntax -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(selector).<span class="color-red">on</span>(event, <span class="color-blue">function</span>(event) {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(event.which);</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Example : Detect Mouse Button Click</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;div <span class="color-pink">id</span>=<span class="color-green">"myDiv"</span> <span class="color-green">style</span>=<span class="color-green">"width: 200px; height: 100px; background-color: lightblue;"</span>&gt;</p>
                        <p class="ml-30">    Click inside this box</p>
                        <p>&lt;/div&gt;</p>
                        `
                    }}></div>
                </div>
                <h6>jQuery Code</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"#myDiv"</span>).<span class="color-red">on</span>(<span class="color-green">"mousedown"</span>, <span class="color-blue">function</span>(event) {</p>
                        <p class="ml-30">    <span class="color-blue">if</span> (event.which === <span class="color-pink">1</span>) {</p>
                        <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Left mouse button clicked."</span>);</p>
                        <p class="ml-30">    } <span class="color-blue">else if</span> (event.which === <span class="color-pink">2</span>) {</p>
                        <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Middle mouse button clicked."</span>);</p>
                        <p class="ml-30">    } <span class="color-blue">else if</span> (event.which === <span class="color-pink">3</span>) {</p>
                        <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Right mouse button clicked."</span>);</p>
                        <p class="ml-30">    }</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>Clicking inside the <span class="background-grey">#myDiv</span> with different mouse buttons logs the respective button information.</li>
                </ul>


            </div>
        </section>
    )
}