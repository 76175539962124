import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/object-getset";

export default function GetSet() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Object Get/Set in JavaScript | Aspirant's Home");
        const urls = {
            'previous': '/javascript/object-properties',
            'next': '/javascript/object-protection'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Object Get/Set</h3>
            <div className='mt-4 mb-5'>
                <p>In JavaScript, <span className='background-grey'>get</span> <strong>and</strong> <span className='background-grey'>set</span> are special methods called <strong>accessors</strong> that allow you to:</p>
                <ul style={{listStyle: 'disc'}}>
                    <li><span className='background-grey'>get</span>: Access (read) the value of a property.</li>
                    <li><span className='background-grey'>set</span>: Update (write) the value of a property.</li>
                </ul>
                <p>These methods provide a way to control how properties of an object are accessed and updated.</p>


                <h5 className='mt-5 mb-3'>Why Use <span className='background-grey'>get</span> and <span className='background-grey'>set</span>?</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <h6>To Add Logic:</h6>
                        <p>You can add custom logic when getting or setting a property. For example:</p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>Validate a value before setting it.</li>
                            <li>Compute or modify the value when accessing it.</li>
                        </ul>
                    </li>
                    <li className='mt-3'>
                        <h6>Encapsulation</h6>
                        <p>You can hide the actual internal property and control its access.</p>
                    </li>
                </ul>

                <h5 className='mt-5 mb-3'>Define <span className='background-grey'>get</span> and <span className='background-grey'>set</span> in an Object</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> obj = {</p>
                        <p class="ml-30 color-grey">    // Getter</p>
                        <p class="ml-30">    <span class="color-blue">get</span> <span class="color-red">propertyName</span>() {</p>
                        <p class="ml-60">        <span class="color-pink">return</span> someValue;</p>
                        <p class="ml-30">    },</p>
                        <br />
                        <p class="ml-30 color-grey">    // Setter</p>
                        <p class="ml-30">    <span class="color-blue">set</span> <span class="color-red">propertyName</span>(value) {</p>
                        <p class="ml-60 color-grey">        // Perform actions with 'value'</p>
                        <p class="ml-30">    }</p>
                        <p>};</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Example : Basic <span className='background-grey'>get</span> and <span className='background-grey'>set</span></h5>
                <p>Let’s create an object that stores a person’s full name, but we’ll use <span className='background-grey'>get</span> and <span className='background-grey'>set</span> to control how the name is accessed and updated.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> person = {</p>
                        <p class="ml-30">    <span class="color-pink">firstName</span>: <span class="color-green">"Alice"</span>,</p>
                        <p class="ml-30">    <span class="color-pink">lastName</span>: <span class="color-green">"Johnson"</span>,</p>
                        <br />
                        <p class="ml-30 color-grey">    // Getter for fullName</p>
                        <p class="ml-30">    <span class="color-blue">get</span> <span class="color-red">fullName</span>() {</p>
                        <p class="ml-60">        <span class="color-pink">return</span> <span class="color-blue">this</span>.firstName + <span class="color-green">" "</span> + <span class="color-blue">this</span>.lastName;</p>
                        <p class="ml-30">    },</p>
                        <br />
                        <p class="ml-30 color-grey">    // Setter for fullName</p>
                        <p class="ml-30">    <span class="color-blue">set</span> <span class="color-red">fullName</span>(name) {</p>
                        <p class="ml-60">        <span class="color-blue">let</span> parts = name.<span class="color-red">split</span>(<span class="color-green">" "</span>);</p>
                        <p class="ml-60">        <span class="color-blue">this</span>.firstName = parts[<span class="color-pink">0</span>];</p>
                        <p class="ml-60">        <span class="color-blue">this</span>.lastName = parts[<span class="color-pink">1</span>];</p>
                        <p class="ml-30">    }</p>
                        <p>};</p>
                        <br />
                        <p class="color-grey">// Using the getter</p>
                        <p><span class="color-pink">console</span>.<span class="color-red">log</span>(person.fullName);  <span class="color-grey">// Output: Alice Johnson</span></p>
                        <br />
                        <p class="color-grey">// Using the setter</p>
                        <p>person.fullName = <span class="color-green">"Bob Smith"</span>;</p>
                        <p><span class="color-pink">console</span>.<span class="color-red">log</span>(person.firstName); <span class="color-grey">// Output: Bob</span></p>
                        <p><span class="color-pink">console</span>.<span class="color-red">log</span>(person.lastName);  <span class="color-grey">// Output: Smith</span></p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>How <span className='background-grey'>get</span> and <span className='background-grey'>set</span> Work</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>Getter</strong> (<span className='background-grey'>get</span>):
                        <ul style={{ listStyle: 'disc' }}>
                            <li>A getter is like a function that runs whenever you try to read the property.</li>
                            <li>You don’t need to call it like a function; just use it like a property.</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Setter</strong> (<span className='background-grey'>set</span>):
                        <ul style={{ listStyle: 'disc' }}>
                            <li>A setter is like a function that runs whenever you try to update the property.</li>
                            <li>It takes one parameter, which is the value you’re assigning.</li>
                        </ul>
                    </li>
                </ul>


            </div>
        </section>
    )
}