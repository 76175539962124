import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/jquery/ancestors";

export default function Ancestors() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("jQuery Traversing - Ancestors | Aspirant's Home");
        const urls = {
            'previous': '/jquery/traversing',
            'next': '/jquery/descendants'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>jQuery Traversing - Ancestors</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In jQuery, <strong>ancestor traversing</strong> allows you to select elements that are above a specific element in the DOM hierarchy, such as its parents, grandparents, or further ancestors. This is helpful when you want to make changes to higher-level elements relative to the selected element. Let's go through the main methods used to traverse ancestors in jQuery.
                </p>

                <h5 className='mt-5 mb-3'>Methods for Traversing Ancestors in jQuery</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>
                        <h4><span class="background-grey">.parent()</span></h4>
                        <p>Look at the below example -</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>$(<span class="color-green">"#childElement"</span>).<span class="color-red">parent</span>().<span class="color-red">css</span>(<span class="color-green">"background-color"</span>, <span class="color-green">"lightblue"</span>);</p>
                                `
                            }}></div>
                        </div>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>It selects the immediate parent of the selected element.</li>
                            <li>It retrieves only one level up (i.e., the direct parent).</li>
                            <li>It finds the direct parent of <span class="background-grey">#childElement</span> and changes its background color.</li>
                        </ul>
                    </li>
                    <li className='mt-5'>
                        <h4><span class="background-grey">.parents()</span></h4>
                        <p>Look at the below example -</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>$(<span class="color-green">"#childElement"</span>).<span class="color-red">parents</span>().<span class="color-red">css</span>(<span class="color-green">"border"</span>, <span class="color-green">"1px solid black"</span>);</p>
                                `
                            }}></div>
                        </div>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>It selects all ancestors (parents, grandparents, etc.) of the selected element up the DOM tree.</li>
                            <li>You can specify a selector to narrow down which ancestors to select.</li>
                            <li>It finds all ancestors of <span class="background-grey">#childElement</span> and adds a border.</li>
                        </ul>

                    </li>
                    <li className='mt-5'>
                        <h4><span class="background-grey">.parentsUntil(selector)</span></h4>
                        <p>Look at the below example -</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>$(<span class="color-green">"#childElement"</span>).<span class="color-red">parentsUntil</span>(<span class="color-green">".stopElement"</span>).<span class="color-red">css</span>(<span class="color-green">"color"</span>, <span class="color-green">"red"</span>);</p>
                                `
                            }}></div>
                        </div>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>It selects all ancestors between the selected element and the specified ancestor (up to but not including that ancestor).</li>
                            <li>It is useful for selecting multiple levels up to a specific point without including that top-level element.</li>
                            <li>It selects ancestors of <span class="background-grey">#childElement</span> up to <span class="background-grey">.stopElement</span> (without selecting <span class="background-grey">.stopElement</span>) and changes their text color to red.</li>
                        </ul>
                    </li>
                    <li className='mt-5'>
                        <h4><span class="background-grey">.closest(selector)</span></h4>
                        <p>Look at the below example -</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>$(<span class="color-green">"#childElement"</span>).<span class="color-red">closest</span>(<span class="color-green">".container"</span>).<span class="color-red">css</span>(<span class="color-green">"padding"</span>, <span class="color-green">"10px"</span>);</p>
                                `
                            }}></div>
                        </div>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>It finds the closest ancestor (including the selected element itself) that matches the specified selector.</li>
                            <li>It stops at the first match it finds while moving up the DOM tree.</li>
                            <li>It finds the nearest ancestor with the class <span class="background-grey">container</span> (including <span class="background-grey">#childElement</span> itself if it has that class) and adds padding.</li>
                        </ul>
                    </li>
                </ul>


            </div>
        </section>
    )
}