import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/jquery/no-conflict-function";

export default function NoConflict() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("jQuery - The noConflict() Method | Aspirant's Home");
        const urls = {
            'previous': '/jquery/get-post'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>jQuery - The noConflict() Method</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">noConflict()</span> method in jQuery is used to avoid conflicts between jQuery and other JavaScript libraries that may also use the <span class="background-grey">$</span> symbol as a shorthand or variable. By using <span class="background-grey">noConflict()</span>, you can release the <span class="background-grey">$</span> shortcut and assign jQuery to a different variable, preventing any issues in your code when multiple libraries are in use.
                </p>
                <p>It has a basic syntax -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>jQuery.<span class="color-red">noConflict</span>();</p>
                        `
                    }}></div>
                </div>
                <p>When <span class="background-grey">noConflict()</span> is called, the <span class="background-grey">$</span> symbol is freed, meaning it’s no longer associated with jQuery. From that point on, you’ll need to use jQuery instead of <span class="background-grey">$</span> when referencing jQuery methods.</p>

                <h4 className='mt-4'>Example 1: Using <span class="background-grey">noConflict()</span> in Basic Scenario</h4>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p class="color-grey">// Free the $ symbol from jQuery</p>
                        <p>jQuery.<span class="color-red">noConflict</span>();</p>
                        <br />
                        <p class="color-grey">// Use jQuery instead of $</p>
                        <p>jQuery(document).<span class="color-red">ready</span>(<span class="color-blue">function</span>() {</p>
                        <p class="ml-30">    jQuery(<span class="color-green">"button"</span>).<span class="color-red">click</span>(<span class="color-blue">function</span>() {</p>
                        <p class="ml-60">        jQuery(<span class="color-green">"p"</span>).<span class="color-red">text</span>(<span class="color-green">"Hello from jQuery!"</span>);</p>
                        <p class="ml-30">    });</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">jQuery.noConflict()</span> frees the <span class="background-grey">$</span> symbol, so it’s not used by jQuery.</li>
                    <li>All jQuery methods now use <span class="background-grey">jQuery</span> instead of <span class="background-grey">$</span>.</li>
                </ul>


                <h4 className='mt-4'>Example 2: Assigning jQuery to a Custom Variable</h4>
                <p>If you prefer using a shorter variable name, you can assign jQuery to a custom variable after calling <span class="background-grey">noConflict()</span>.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p class="color-grey">// Free the $ symbol and assign jQuery to a new variable, "jq"</p>
                        <p><span class="color-blue">var</span> jq = jQuery.<span class="color-red">noConflict</span>();</p>
                        <br />
                        <p>jq(document).<span class="color-red">ready</span>(<span class="color-blue">function</span>() {</p>
                        <p class="ml-30">    jq(<span class="color-green">"button"</span>).<span class="color-red">click</span>(<span class="color-blue">function</span>() {</p>
                        <p class="ml-60">        jq(<span class="color-green">"p"</span>).<span class="color-red">text</span>(<span class="color-green">"Hello from jQuery with custom variable!"</span>);</p>
                        <p class="ml-30">    });</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">noConflict()</span> frees <span class="background-grey">$</span>.</li>
                    <li>The variable <span class="background-grey">jq</span> is now a reference to jQuery, so <span class="background-grey">jq()</span> is used to call jQuery methods.</li>
                </ul>




            </div>
        </section>
    )
}