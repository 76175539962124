import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/jquery/events/event-timestamp";

export default function Timestamp() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("jQuery event.timeStamp Object | Aspirant's Home");
        const urls = {
            'previous': '/jquery/events'
        }
        path.setPreviousNext(urls);
        path.setPreviousName('Back to Events');
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>jQuery event.timeStamp Object</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">event.timeStamp</span> property in jQuery represents <strong>the time (in milliseconds)</strong> when the event was created. This value is measured relative to the time the browser was loaded.
                </p>

                <p>It has a basic syntax -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(selector).<span class="color-red">on</span>(event, <span class="color-blue">function</span>(event) {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(event.timeStamp);</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-3'>Example 1: Logging <span class="background-grey">event.timeStamp</span></h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;button <span class="color-pink">id</span>=<span class="color-green">"myButton"</span>&gt;Click Me&lt;/button&gt;</p>
                        `
                    }}></div>
                </div>
                <h6>jQuery Code</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"#myButton"</span>).<span class="color-red">on</span>(<span class="color-green">"click"</span>, <span class="color-blue">function</span>(event) {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Event occurred at:"</span>, event.timeStamp, <span class="color-green">"milliseconds since page load."</span>);</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>When you click the button, the time (in milliseconds since the page was loaded) is logged.</li>
                    <li>Output (example): <span class="background-grey">Event occurred at: 1234567 milliseconds since page load</span>.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Example 2: Comparing Event Timing</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;div <span class="color-pink">id</span>=<span class="color-green">"parent"</span>&gt;</p>
                        <p class="ml-30">    &lt;button <span class="color-pink">id</span>=<span class="color-green">"child"</span>&gt;Click Me&lt;/button&gt;</p>
                        <p>&lt;/div&gt;</p>
                        `
                    }}></div>
                </div>
                <h6>jQuery Code</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"#parent"</span>).<span class="color-red">on</span>(<span class="color-green">"click"</span>, <span class="color-blue">function</span>(event) {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Parent clicked at:"</span>, event.timeStamp);</p>
                        <p>});</p>
                        <br />
                        <p>$(<span class="color-green">"#child"</span>).<span class="color-red">on</span>(<span class="color-green">"click"</span>, <span class="color-blue">function</span>(event) {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Child clicked at:"</span>, event.timeStamp);</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>When the button is clicked, both the child and parent handlers log their timestamps.</li>
                    <li>You can compare these timestamps to observe that the child's event occurs slightly before the parent's due to event bubbling.</li>
                </ul>
                <p>Here is the Output -</p>
                <div className='outputPalateBox mt-2 mb-4'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>Child clicked at: 3050</p>
                        <p>Parent clicked at: 3060</p>
                        `
                    }}></div>
                </div>


            </div>
        </section>
    )
}