import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/tag/small";

export default function Small() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - <small> Tag | Aspirant's Home");
        const urls = {
            'previous': '/html/tag-list'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - &lt;small&gt; Tag</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">&lt;small&gt;</span> tag in HTML is used to render text in a smaller font size than the surrounding text. It’s often used for displaying additional information, disclaimers, fine print, or any other content that is not the main focus but still relevant. By default, text within <span class="background-grey">&lt;small&gt;</span> appears slightly smaller, although this can be customized with CSS.
                </p>

                <h5 className='mt-5 mb-3'>Structure and Purpose of <span class="background-grey">&lt;small&gt;</span> Tag</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>It reduces the size of the text relative to the main content, making it less visually prominent.</li>
                    <li>It is commonly used for legal disclaimers, copyright notices, or secondary details.</li>
                    <li>It is primarily for styling, the <span class="background-grey">&lt;small&gt;</span> tag implies that the text is supplementary.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <p>The <span class="background-grey">&lt;small&gt;</span> tag wraps around the text you want to display in a smaller font:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            &lt;p&gt;&lt;small&gt;Your smaller text here&lt;/small&gt;</p>
                        `
                    }}></div>
                </div>  

                <h5 className='mt-5 mb-3'>Example Usage of <span class="background-grey">&lt;small&gt;</span> Tag</h5>
                <p>Here’s an example using <span class="background-grey">&lt;small&gt;</span> for a copyright notice:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Small Text Example&lt;/title&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <br />    
                        <p class="ml-60">        &lt;p&gt;Website content by Example Inc. &lt;small&gt;&copy; 2024 All rights reserved.&lt;/small&gt;&lt;/p&gt;</p>
                        <br />    
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div> 
                <p>In this example, the copyright notice is displayed in a smaller font to distinguish it from the main content.</p>
                <p>Output will be the following -</p>
                <div className='outputPalateBox mt-2 mb-4'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <!DOCTYPE html>
                        <html lang="en">
                            <head>
                                <meta charset="UTF-8">
                                <title>Small Text Example</title>
                            </head>
                            <body>
                            
                                <p>Website content by Example Inc. <small>&copy; 2024 All rights reserved.</small></p>
                            
                            </body>
                        </html>
                        `
                    }}></div>
                </div> 



            </div>
        </section>
    )
}