import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/events/ondrop";

export default function Ondrop() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - ondrop Event | Aspirant's Home");
        const urls = {
            'previous': '/html/events'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - ondrop Event</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">ondrop</span> event in HTML is triggered when an element is dropped onto a valid drop target. This event is part of the <strong>Drag and Drop API</strong> and occurs when a user completes a drag-and-drop action. It usually works in conjunction with the <span class="background-grey">ondrag</span> and <span class="background-grey">ondragover</span> events.
                </p>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <p>The <span class="background-grey">ondrop</span> event is added to an element that is designated as a drop target. Typically, this is an element where you can drop another element that has been dragged.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;div <span class="color-pink">id</span>=<span class="color-green">"dropTarget"</span> <span class="color-pink">ondrop</span>=<span class="color-green">"handleDrop(event)"</span> <span class="color-pink">ondragover</span>=<span class="color-green">"allowDrop(event)"</span>&gt;</p>
                        <p class="ml-30">    Drop here!</p>
                        <p>&lt;/div&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Alternatively, you can attach the event using JavaScript:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>document.<span class="color-red">getElementById</span>(<span class="color-green">"dropTarget"</span>).ondrop = <span class="color-red">function</span>(event) {</p>
                        <p class="ml-30">    console.<span class="color-red">log</span>(<span class="color-green">"Element dropped!"</span>);</p>
                        <p>};</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Example: Basic Drag and Drop Interaction</h5>
                <p>This example shows a basic implementation where an element is dragged and dropped onto a drop target.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;div <span class="color-pink">id</span>=<span class="color-green">"dragElement"</span> <span class="color-pink">ondrag</span>=<span class="color-green">"dragStart(event)"</span> <span class="color-pink">draggable</span>=<span class="color-green">"true"</span>&gt;</p>
                        <p class="ml-30">    Drag me!</p>
                        <p>&lt;/div&gt;</p>
                        <br />
                        <p>&lt;div <span class="color-pink">id</span>=<span class="color-green">"dropTarget"</span> <span class="color-pink">ondrop</span>=<span class="color-green">"dropElement(event)"</span> <span class="color-pink">ondragover</span>=<span class="color-green">"allowDrop(event)"</span>&gt;</p>
                        <p class="ml-30">    Drop here!</p>
                        <p>&lt;/div&gt;</p>
                        <br />
                        <p>&lt;script&gt;</p>
                        <p class="ml-30">    <span class="color-blue">function</span> <span class="color-red">allowDrop</span>(event) {</p>
                        <p class="ml-60">        event.<span class="color-red">preventDefault</span>(); <span class="color-grey">// Allows the drop</span></p>
                        <p class="ml-30">    }</p>
                        <br />
                        <p class="ml-30">    <span class="color-blue">function</span> <span class="color-red">dropElement</span>(event) {</p>
                        <p class="ml-60">        event.<span class="color-red">preventDefault</span>();</p>
                        <p class="ml-60">        document.<span class="color-red">getElementById</span>(<span class="color-green">"dropTarget"</span>).innerHTML = <span class="color-green">"Element Dropped!"</span>;</p>
                        <p class="ml-60">        console.<span class="color-red">log</span>(<span class="color-green">"Element has been dropped."</span>);</p>
                        <p class="ml-30">    }</p>
                        <br />
                        <p class="ml-30">    <span class="color-blue">function</span> <span class="color-red">dragStart</span>(event) {</p>
                        <p class="ml-60">        event.dataTransfer.<span class="color-red">setData</span>(<span class="color-green">"text"</span>, event.target.id);</p>
                        <p class="ml-30">    }</p>
                        <p>&lt;/script&gt;</p>
                        `
                    }}></div>
                </div>

                <p>In this example,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">allowDrop(event)</span>: This function is necessary to allow an element to be dropped into the target. By default, browsers prevent dropping elements into unknown targets.</li>
                    <li><span class="background-grey">dropElement(event)</span>: This function is triggered when the element is dropped. It changes the content of the drop target to display "Element Dropped!" and logs a message to the console.</li>
                    <li><span class="background-grey">dragStart(event)</span>: This function sets the dragged element's ID to be transferred during the drag.</li>
                </ul>




            </div>
        </section>
    )
}