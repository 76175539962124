import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/events/ontouchstart";

export default function Ontouchstart() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - ontouchstart Event | Aspirant's Home");
        const urls = {
            'previous': '/html/events'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - ontouchstart Event</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">ontouchstart</span> event in HTML is triggered when a touch point (such as a finger) is placed on an element, typically used for mobile and tablet devices that support touch events. This event is part of the <strong>Touch Events API</strong> and allows web developers to detect when a user first touches an element on the screen.
                </p>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <p>You can use the <span class="background-grey">ontouchstart</span> event in an HTML element like this:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;div <span class="color-pink">ontouchstart</span>=<span class="color-green">"handleTouchStart(event)"</span>&gt;</p>
                        <p class="ml-30">    Touch me!</p>
                        <p>&lt;/div&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Or attach it via JavaScript:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>document.<span class="color-red">getElementById</span>(<span class="color-green">"elementId"</span>).ontouchstart = <span class="color-red">function</span>(event) {</p>
                        <p class="ml-30 color-grey">    // Your code here</p>
                        <p>};</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Example: Basic <span class="background-grey">ontouchstart</span> Event Handling</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                        <p class="ml-30">&lt;head&gt;</p>
                        <p class="ml-60">  &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                        <p class="ml-60">  &lt;meta <span class="color-pink">name</span>=<span class="color-green">"viewport"</span> <span class="color-pink">content</span>=<span class="color-green">"width=device-width, initial-scale=1.0"</span>&gt;</p>
                        <p class="ml-60">  &lt;title&gt;Touch Start Example&lt;/title&gt;</p>
                        <p class="ml-30">&lt;/head&gt;</p>
                        <p class="ml-30">&lt;body&gt;</p>
                        <p class="ml-60">  &lt;div <span class="color-pink">id</span>=<span class="color-green">"touchArea"</span> <span class="color-pink">style</span>=<span class="color-green">"width: 200px; height: 200px; background-color: lightblue;"</span>&gt;</p>
                        <p class="ml-90">    Touch me!</p>
                        <p class="ml-60">  &lt;/div&gt;</p>
                        <br />
                        <p class="ml-60">  &lt;script&gt;</p>
                        <p class="ml-90">    <span class="color-blue">function</span> <span class="color-red">handleTouchStart</span>(event) {</p>
                        <p class="ml-120">      event.<span class="color-red">preventDefault</span>();</p>
                        <p class="ml-120">      document.<span class="color-red">getElementById</span>(<span class="color-green">"touchArea"</span>).style.backgroundColor = <span class="color-green">"lightgreen"</span>;</p>
                        <p class="ml-120">      <span class="color-red">alert</span>(<span class="color-green">"Touch started!"</span>);</p>
                        <p class="ml-90">    }</p>
                        <br />
                        <p class="ml-90">    document.<span class="color-red">getElementById</span>(<span class="color-green">"touchArea"</span>).ontouchstart = handleTouchStart;</p>
                        <p class="ml-60">  &lt;/script&gt;</p>
                        <p class="ml-30">&lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>

                <p>In this Example,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">ontouchstart</span>: When the user touches the element (in this case, the div with the ID touchArea), the event handler handleTouchStart(event) is triggered.</li>
                    <li><span class="background-grey">event.preventDefault()</span>: This prevents the default touch behavior (e.g., scrolling or zooming) when touching the element.</li>
                </ul>




            </div>
        </section>
    )
}