import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/tag/td";

export default function TdTag() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - <td> Tag | Aspirant's Home");
        const urls = {
            'previous': '/html/tag-list'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - &lt;td&gt; Tag</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">&lt;td&gt;</span> tag in HTML is used to define a standard cell in a table. It represents a single data point in a row (<span class="background-grey">&lt;tr&gt;</span>) within a table (<span class="background-grey">&lt;table&gt;</span>). The <span class="background-grey">&lt;td&gt;</span> tag is used alongside <span class="background-grey">&lt;th&gt;</span> (for header cells) and is an essential part of creating rows and columns in tables.
                </p>

                <h5 className='mt-5 mb-3'>Structure and Purpose of <span class="background-grey">&lt;td&gt;</span> Tag</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span class="background-grey">&lt;td&gt;</span> tag is specifically for data cells within a row.</li>
                    <li><span class="background-grey">&lt;td&gt;</span> cells go inside <span class="background-grey">&lt;tr&gt;</span> tags and are used to structure and display data in a tabular layout.</li>
                    <li>A <span class="background-grey">&lt;td&gt;</span> cell can contain text, images, links, or other HTML elements to organize content neatly in a table format.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <p>The <span class="background-grey">&lt;td&gt;</span> tag is used within a <span class="background-grey">&lt;tr&gt;</span> to define a cell:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;table&gt;</p>
                        <p class="ml-30">    &lt;tr&gt;</p>
                        <p class="ml-60">        &lt;td&gt;Cell 1&lt;/td&gt;</p>
                        <p class="ml-60">        &lt;td&gt;Cell 2&lt;/td&gt;</p>
                        <p class="ml-30">    &lt;/tr&gt;</p>
                        <p class="ml-30">    &lt;tr&gt;</p>
                        <p class="ml-60">        &lt;td&gt;Cell 3&lt;/td&gt;</p>
                        <p class="ml-60">        &lt;td&gt;Cell 4&lt;/td&gt;</p>
                        <p class="ml-30">    &lt;/tr&gt;</p>
                        <p>&lt;/table&gt;</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Attributes for <span class="background-grey">&lt;td&gt;</span> Tag</h5>
                <p>The <span class="background-grey">&lt;td&gt;</span> tag has attributes that can be used to control the appearance and behavior of cells within the table:</p>
                <ul style={{listStyle:'disc'}}>
                    <li><strong>colspan</strong>: Specifies the number of columns a cell should span.</li>
                    <li><strong>rowspan</strong>: Specifies the number of rows a cell should span.</li>
                </ul>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;table <span class="color-pink">border</span>=<span class="color-green">"1"</span>&gt;</p>
                        <p class="ml-30">    &lt;tr&gt;</p>
                        <p class="ml-60">        &lt;td <span class="color-pink">rowspan</span>=<span class="color-green">"2"</span>&gt;Rowspan Cell&lt;/td&gt;</p>
                        <p class="ml-60">        &lt;td&gt;Column 1&lt;/td&gt;</p>
                        <p class="ml-60">        &lt;td&gt;Column 2&lt;/td&gt;</p>
                        <p class="ml-30">    &lt;/tr&gt;</p>
                        <p class="ml-30">    &lt;tr&gt;</p>
                        <p class="ml-60">        &lt;td <span class="color-pink">colspan</span>=<span class="color-green">"2"</span>&gt;Colspan Cell&lt;/td&gt;</p>
                        <p class="ml-30">    &lt;/tr&gt;</p>
                        <p>&lt;/table&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example, The cell with rowspan="2" spans two rows, while the cell with colspan="2" spans across two columns.</p>
                <p>Output will be the following -</p>
                <div className='outputPalateBox mt-2 mb-4' style={{'border':'none'}}>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <table class="myBorderClass" border="1">
                            <tr>
                                <td rowspan="2">Rowspan Cell</td>
                                <td>Column 1</td>
                                <td>Column 2</td>
                            </tr>
                            <tr>
                                <td colspan="2">Colspan Cell</td>
                            </tr>
                        </table>
                        `
                    }}></div>
                </div>




            </div>
        </section>
    )
}