import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/events/ondblclick";

export default function Ondblclick() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - ondblclick Event | Aspirant's Home");
        const urls = {
            'previous': '/html/events'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - ondblclick Event</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">ondblclick</span> event in HTML is used to trigger JavaScript code when an HTML element is double-clicked. This event allows for actions to be taken only after a quick, consecutive double-click on an element, often providing a secondary interaction option. For example, you can use <span class="background-grey">ondblclick</span> to edit text, zoom in on an image, or perform other custom actions.
                </p>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <p>To use <span class="background-grey">ondblclick</span>, add it as an attribute to an HTML element and specify the JavaScript code or function you want to run when the element is double-clicked.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;button <span class="color-pink">ondblclick</span>=<span class="color-green">"alert('Button double-clicked!')"</span>&gt;Double-Click Me&lt;/button&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example, when the button is double-clicked, an alert box displays the message "Button double-clicked!"</p>

                <h5 className='mt-5 mb-3'>Example: Using <span class="background-grey">ondblclick</span> with JavaScript Functions</h5>
                <p>It’s often better to call a separate JavaScript function from the <span class="background-grey">ondblclick</span> attribute, which keeps the HTML cleaner and allows for more complex logic.</p>

                <h6>Example: Calling a Function on Double-Click</h6>
                <p>Define a JavaScript function, then call it with <span class="background-grey">ondblclick</span>.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                        <p class="ml-60">        &lt;title&gt;ondblclick Example&lt;/title&gt;</p>
                        <p class="ml-60">        &lt;script&gt;</p>
                        <p class="ml-90">            <span class="color-blue">function</span> <span class="color-red">showDoubleClickMessage</span>() {</p>
                        <p class="ml-120">                <span class="color-red">alert</span>(<span class="color-green">"You double-clicked this element!"</span>);</p>
                        <p class="ml-90">            }</p>
                        <p class="ml-60">        &lt;/script&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <br />    
                        <p class="ml-60">        &lt;div <span class="color-pink">ondblclick</span>=<span class="color-green">"showDoubleClickMessage()"</span>&gt;Double-click this text&lt;/div&gt;</p>
                        <br />    
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Here, when the text inside the <span class="background-grey">div</span> is double-clicked, the <span class="background-grey">showDoubleClickMessage()</span> function runs, showing an alert.</p>

            </div>
        </section>
    )
}