import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/jquery/get-started";

export default function GetStarted() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Get Started with jQuery | Aspirant's Home");
        const urls = {
            'previous': '/jquery/introduction',
            'next': '/jquery/syntax'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Get Started with jQuery</h3>
            <div className='mt-4 mb-5'>
                <p>To get started with jQuery, you’ll need to include the jQuery library in your HTML file. </p>
                <p>You can include jQuery in two ways: by using a <strong>CDN</strong> (Content Delivery Network) or by <strong>downloading</strong> the library.</p>

                <h5 className='mt-5 mb-3'>Using a CDN</h5>
                <p>This is the easiest way to add jQuery. Just add the following line inside the <span class="background-grey">&lt;head&gt;</span> section of your HTML file:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;head&gt;</p>
                        <p class="ml-30">    &lt;script <span class="color-pink">src</span>=<span class="color-green">"https://code.jquery.com/jquery-3.6.0.min.js"</span>&gt;&lt;/script&gt;</p>
                        <p>&lt;/head&gt;</p>
                        `
                    }}></div>
                </div>
                <p>This loads the latest version of jQuery from the jQuery CDN (you can also get it from other CDNs if needed).</p>

                <h5 className='mt-5 mb-3'>Download and Use Locally</h5>
                <p>If you want to use jQuery offline, you can download it from jquery.com and save the file to your project folder. Then, include it like this:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;head&gt;</p>
                        <p class="ml-30">    &lt;script <span class="color-pink">src</span>=<span class="color-green">"path/to/jquery-3.6.0.min.js"</span>&gt;&lt;/script&gt;</p>
                        <p>&lt;/head&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Replace <span class="background-grey">"path/to/"</span> with the actual path where you saved the jQuery file.</p>



            </div>
        </section>
    )
}