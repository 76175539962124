import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/jquery/events/trigger";

export default function Trigger() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("jQuery trigger() Method | Aspirant's Home");
        const urls = {
            'previous': '/jquery/events'
        }
        path.setPreviousNext(urls);
        path.setPreviousName('Back to Events');
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>jQuery trigger() Method</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">trigger()</span> method in jQuery is used to programmatically trigger an event on selected elements. It allows you to simulate events like clicks, focus, or custom-defined events, and execute the associated event handlers.
                </p>

                <p>It has a basic syntax -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(selector).<span class="color-red">trigger</span>(eventType, [extraParameters]);</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">selector</span> specifies the target element(s) for the event.</li>
                    <li><span class="background-grey">eventType</span> is the type of event to trigger (e.g., <span class="background-grey">"click"</span>, <span class="background-grey">"focus"</span>, <span class="background-grey">"customEvent"</span>).</li>
                    <li><span class="background-grey">extraParameters</span> (optional) are the additional data passed to the event handler when triggered.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Example : Trigger a Built-In Event (Click Event)</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;button <span class="color-pink">id</span>=<span class="color-green">"btn"</span>&gt;Click Me&lt;/button&gt;</p>
                        `
                    }}></div>
                </div>
                <h6>jQuery Code</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"#btn"</span>).<span class="color-red">click</span>(<span class="color-blue">function</span>() {</p>
                        <p class="ml-30">    <span class="color-red">alert</span>(<span class="color-green">"Button clicked!"</span>);</p>
                        <p>});</p>
                        <br />
                        <p class="color-grey">// Programmatically trigger the click event</p>
                        <p>$(<span class="color-green">"#btn"</span>).<span class="color-red">trigger</span>(<span class="color-green">"click"</span>);</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>On simulates a button click, displaying the alert.</li>
                </ul>


            </div>
        </section>
    )
}