import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/jquery/events/resize";

export default function Resize() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("jQuery resize() Method | Aspirant's Home");
        const urls = {
            'previous': '/jquery/events'
        }
        path.setPreviousNext(urls);
        path.setPreviousName('Back to Events');
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>jQuery resize() Method</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">resize()</span> method in jQuery is used to attach an event handler to the <strong>resize event</strong> of an element, typically the <span class="background-grey">window</span>. It executes the attached function whenever the size of the element changes (e.g., the browser window is resized).
                </p>

                <p>It has a basic syntax -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(document).<span class="color-red">resize</span>(function);</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">function</span> is callback function to execute when the resize event occurs.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Example 1: Basic Usage with the <span class="background-grey">window</span></h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(window).<span class="color-red">resize</span>(<span class="color-blue">function</span>() {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Window resized!"</span>);</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>It logs a message to the console whenever the browser window is resized.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Example 2: Updating Element Dimensions</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;div <span class="color-pink">id</span>=<span class="color-green">"info"</span>&gt;Resize the browser window&lt;/div&gt;</p>
                        `
                    }}></div>
                </div>
                <h6>jQuery Code</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(window).<span class="color-red">resize</span>(<span class="color-blue">function</span>() {</p>
                        <p class="ml-30">    $(<span class="color-green">"#info"</span>).<span class="color-red">text</span>(<span class="color-green">"Width: "</span> + $(window).<span class="color-red">width</span>() + <span class="color-green">", Height: "</span> + $(window).<span class="color-red">height</span>());</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>It displays the current window dimensions inside the <span class="background-grey">#info</span> div whenever the browser window is resized.</li>
                </ul>


            </div>
        </section>
    )
}