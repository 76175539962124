import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/jquery/set";

export default function Set() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Set Content and Attributes in jQuery | Aspirant's Home");
        const urls = {
            'previous': '/jquery/get',
            'next': '/jquery/add'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Set Content and Attributes in jQuery</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In jQuery, you can set <strong>content and attributes</strong> on HTML elements using methods that mirror those used for retrieving data. These methods make it easy to modify text, HTML, values of form fields, and other attributes directly.
                </p>

                <h5 className='mt-5 mb-3'>To Set Content</h5>
                <p>The primary methods for setting content in jQuery are <span class="background-grey">.text()</span>, <span class="background-grey">.html()</span>, and <span class="background-grey">.val()</span>. When used with an argument, these methods set the content or value of the selected element(s).</p>


                <h4 className='mt-5'>.text(content)</h4>
                <p>The <span class="background-grey">.text()</span> method sets the <strong>text content</strong> of the selected elements, replacing any existing text and removing any HTML tags within them. </p>
                <p>Let's look at the example below -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(<span class="color-green">"#myElement"</span>).<span class="color-red">text</span>(<span class="color-green">"Hello, World!"</span>);</p>
                        `
                    }}></div>
                </div>
                <p>It sets the text content of <span class="background-grey">#myElement</span> to <span class="background-grey">"Hello, World!"</span> and displays <span class="background-grey">"Hello, World!"</span> as plain text, without interpreting HTML.</p>


                <h4 className='mt-5'>.html(content)</h4>
                <p>The <span class="background-grey">.html()</span> method sets the <strong>HTML content</strong> of the selected elements. This allows you to insert HTML tags, which will be rendered as HTML.</p>
                <p>Let's look at the example below -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(<span class="color-green">"#myElement"</span>).<span class="color-red">html</span>(<span class="color-green">"<strong>Hello, World!</strong>"</span>);</p>
                        `
                    }}></div>
                </div>
                <p>It sets the HTML content of <span class="background-grey">#myElement</span> to bolded text <span class="background-grey">"Hello, World!"</span> and displays <span class="background-grey">"Hello, World!"</span> in bold, interpreting HTML tags.</p>


                <h4 className='mt-5'>.val(value)</h4>
                <p>The <span class="background-grey">.val()</span> method sets the value of form elements like <span class="background-grey">&lt;input&gt;</span>, <span class="background-grey">&lt;textarea&gt;</span>, and <span class="background-grey">&lt;select&gt;</span>. This is useful for setting the default or initial value of a form field.</p>
                <p>Let's look at the example below -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(<span class="color-green">"#myInput"</span>).<span class="color-red">val</span>(<span class="color-green">"John Doe"</span>);</p>
                        `
                    }}></div>
                </div>
                <p>It sets the value of the input field <span class="background-grey">#myInput</span> to <span class="background-grey">"John Doe"</span>. It displays <span class="background-grey">"John Doe"</span> as the current value in the input field.</p>


                <h5 className='mt-5 mb-3'>Setting Attributes</h5>
                <p>To set the value of an attribute for an HTML element, you use the <span class="background-grey">.attr()</span> method. This can be used to update any attribute, such as <span class="background-grey">href</span>, <span class="background-grey">src</span>, <span class="background-grey">id</span>, <span class="background-grey">class</span>, or <span class="background-grey">data-*</span> attributes.</p>

                <p>The <span class="background-grey">.attr()</span> method sets the <strong>value of a specified attribute</strong> for the selected element(s). Look at the below example -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(<span class="color-green">"#myLink"</span>).<span class="color-red">attr</span>(<span class="color-green">"href"</span>, <span class="color-green">"https://www.example.com"</span>);</p>
                        `
                    }}></div>
                </div>
                <p>It sets the <span class="background-grey">href</span> attribute of <span class="background-grey">#myLink</span> to "https://www.example.com". It updates the link destination for <span class="background-grey">#myLink</span>.</p>

                <p>You can also set multiple attributes at once by passing an object of key-value pairs to <span class="background-grey">.attr()</span>.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"#myImage"</span>).<span class="color-red">attr</span>({</p>
                        <p class="ml-30">    <span class="color-pink">src</span>: <span class="color-green">"image.jpg"</span>,</p>
                        <p class="ml-30">    <span class="color-pink">alt</span>: <span class="color-green">"A descriptive image"</span></p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>It sets both the <span class="background-grey">src</span> and <span class="background-grey">alt</span> attributes of <span class="background-grey">#myImage</span>. It updates <span class="background-grey">#myImage</span> to display "image.jpg" with "A descriptive image" as the alt text.</p>


            </div>
        </section>
    )
}