import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/events/oncut";

export default function Oncut() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - oncut Event | Aspirant's Home");
        const urls = {
            'previous': '/html/events'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - oncut Event</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">oncut</span> event in HTML is triggered when a user cuts content from an element, typically using keyboard shortcuts (like <span class="background-grey">Ctrl+X</span>) or right-clicking and selecting "Cut." This event can be used to track cut actions, display messages, or modify the content that gets cut.
                </p>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <p>The <span class="background-grey">oncut</span> event can be added to an HTML element where users might cut content.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;p <span class="color-pink">oncut</span>=<span class="color-green">"handleCut()"</span>&gt;This is some cuttable text.&lt;/p&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Alternatively, you can attach it using JavaScript:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>document.<span class="color-red">getElementById</span>(<span class="color-green">"cuttableText"</span>).oncut = <span class="color-red">function</span>() {</p>
                        <p class="ml-30">    console.<span class="color-red">log</span>(<span class="color-green">"Content cut!"</span>);</p>
                        <p>};</p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-3'>Example: Display a Message on Cut</h5>
                <p>This example displays a message in the console when text is cut.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;p <span class="color-pink">oncut</span>=<span class="color-green">"showCutMessage()"</span>&gt;Try cutting this text!&lt;/p&gt;</p>
                        <br />
                        <p>&lt;script&gt;</p>
                        <p class="ml-30">    <span class="color-blue">function</span> <span class="color-red">showCutMessage</span>() {</p>
                        <p class="ml-60">        console.<span class="color-red">log</span>(<span class="color-green">"Content has been cut!"</span>);</p>
                        <p class="ml-30">    }</p>
                        <p>&lt;/script&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Here, The <span class="background-grey">showCutMessage</span> function is triggered when the text is cut, logging a message to the console.</p>




            </div>
        </section>
    )
}