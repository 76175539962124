import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/events/onpause";

export default function Onpause() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - onpause Event | Aspirant's Home");
        const urls = {
            'previous': '/html/events'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - onpause Event</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">onpause</span> event in HTML is triggered when a media element (like <span class="background-grey">&lt;audio&gt;</span> or <span class="background-grey">&lt;video&gt;</span>) is paused. This event occurs when the <span class="background-grey">pause()</span> method is called or when the user manually pauses the media (e.g., using the pause button on the media player controls).
                </p>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <p>The <span class="background-grey">onpause</span> event can be added directly to a media element:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;video <span class="color-pink">onpause</span>=<span class="color-green">"handlePause()"</span>&gt;Your browser does not support the video tag.&lt;/video&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Alternatively, it can be attached using JavaScript:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>document.<span class="color-red">getElementById</span>(<span class="color-green">"myVideo"</span>).onpause = <span class="color-red">function</span>() {</p>
                        <p class="ml-30">    console.<span class="color-red">log</span>(<span class="color-green">"The video has been paused."</span>);</p>
                        <p>};</p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-3'>Example: Display a Message When Playback is Paused</h5>
                <p>This example logs a message in the console when a video is paused.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;video <span class="color-pink">onpause</span>=<span class="color-green">"showPauseMessage()"</span> <span class="color-pink">controls</span>&gt;</p>
                        <p class="ml-30">    &lt;source <span class="color-pink">src</span>=<span class="color-green">"video.mp4"</span> <span class="color-pink">type</span>=<span class="color-green">"video/mp4"</span>&gt;</p>
                        <p class="ml-30">    Your browser does not support the video tag.</p>
                        <p>&lt;/video&gt;</p>
                        <br />
                        <p>&lt;script&gt;</p>
                        <p class="ml-30">    <span class="color-blue">function</span> <span class="color-red">showPauseMessage</span>() {</p>
                        <p class="ml-60">        console.<span class="color-red">log</span>(<span class="color-green">"The video has been paused."</span>);</p>
                        <p class="ml-30">    }</p>
                        <p>&lt;/script&gt;</p>
                        `
                    }}></div>
                </div>


            </div>
        </section>
    )
}