import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/angular/lifecycle-hooks";

export default function LifecycleHooks() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Lifecycle Hooks in Angular | Aspirant's Home");
        const urls = {
            'previous': '/angular/component-interaction',
            'next': '/angular/viewchild-and-contentchild'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Lifecycle Hooks in Angular</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Lifecycle hooks in Angular are special methods that allow you to perform actions at different stages of a component's lifecycle. These hooks provide insights into how and when a component is created, updated, or destroyed.
                </p>

                <h5 className='mt-5 mb-3'>Component Lifecycle Stages</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>Creation</strong>
                        <p>The component is initialized and added to the DOM.</p>
                    </li>
                    <li>
                        <strong>Change Detection and Updates</strong>
                        <p>Angular checks for changes in data-bound properties and updates the DOM.</p>
                        </li>
                    <li>
                        <strong>Destruction</strong>
                        <p>The component is removed from the DOM and destroyed.</p>
                    </li>
                </ul>


                <h5 className='mt-5 mb-3'>Common Lifecycle Hooks</h5>
                <p>Below are the most commonly used lifecycle hooks, their purpose, and how to implement them:</p>
                <ul className='mt-4' style={{ listStyle: 'decimal' }}>
                    <li>
                        <span class="background-grey">ngOnChanges()</span>
                        <p>It is used whenever the value of an <span class="background-grey">@Input</span> property changes. It will called before <span class="background-grey">ngOnInit()</span> and whenever an input changes.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">import</span> { Component, Input, OnChanges, SimpleChanges } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                                <br />
                                <p><span class="color-red">@Component</span>({</p>
                                <p class="ml-30">    <span class="color-pink">selector</span>: <span class="color-green">'app-child'</span>,</p>
                                <p class="ml-30">    <span class="color-pink">template</span>: <span class="color-green">&#96;&lt;p&gt;{{ data }}&lt;/p&gt;&#96;</span></p>
                                <p>})</p>
                                <p><span class="color-pink">export class</span> <span class="color-blue">ChildComponent</span> <span class="color-pink">implements</span> <span class="color-blue">OnChanges</span> {</p>
                                <p class="ml-30">    <span class="color-red">@Input</span>() data: <span class="color-blue">string</span> = <span class="color-green">''</span>;</p>
                                <br />
                                <p class="ml-30">    <span class="color-red">ngOnChanges</span>(changes: <span class="color-blue">SimpleChanges</span>) {</p>
                                <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">'Changes detected:'</span>, changes);</p>
                                <p class="ml-30">    }</p>
                                <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <span class="background-grey">ngOnInit()</span>
                        <p>It is called once after the component is initialized and the input properties are set.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">import</span> { Component, OnInit } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                                <br />
                                <p><span class="color-red">@Component</span>({</p>
                                <p class="ml-30">    <span class="color-pink">selector</span>: <span class="color-green">'app-user'</span>,</p>
                                <p class="ml-30">    <span class="color-pink">template</span>: <span class="color-green">&#96;&lt;p&gt;User Component Initialized&lt;/p&gt;&#96;</span></p>
                                <p>})</p>
                                <p><span class="color-pink">export class</span> <span class="color-blue">UserComponent</span> <span class="color-pink">implements</span> <span class="color-blue">OnInit</span> {</p>
                                <p class="ml-30">    <span class="color-red">ngOnInit</span>() {</p>
                                <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">'Component Initialized'</span>);</p>
                                <p class="ml-30">    }</p>
                                <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <span class="background-grey">ngDoCheck()</span>
                        <p>It is called during every change detection run, allowing you to implement custom change detection logic.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">import</span> { Component, DoCheck } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                                <br />
                                <p><span class="color-red">@Component</span>({</p>
                                <p class="ml-30">    <span class="color-pink">selector</span>: <span class="color-green">'app-check'</span>,</p>
                                <p class="ml-30">    <span class="color-pink">template</span>: <span class="color-green">&#96;&lt;p&gt;Custom Change Detection&lt;/p&gt;&#96;</span></p>
                                <p>})</p>
                                <p><span class="color-pink">export class</span> <span class="color-blue">CheckComponent</span> <span class="color-pink">implements</span> <span class="color-blue">DoCheck</span> {</p>
                                <p class="ml-30">    <span class="color-red">ngDoCheck</span>() {</p>
                                <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">'Change detection triggered'</span>);</p>
                                <p class="ml-30">    }</p>
                                <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <span class="background-grey">ngAfterContentInit()</span>
                        <p>It is called once after the content (HTML projected into the component using <span class="background-grey">&lt;ng-content&gt;</span>) has been initialized.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">import</span> { Component, AfterContentInit } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                                <br />
                                <p><span class="color-red">@Component</span>({</p>
                                <p class="ml-30">    <span class="color-pink">selector</span>: <span class="color-green">'app-content'</span>,</p>
                                <p class="ml-30">    <span class="color-pink">template</span>: <span class="color-green">&#96;&lt;ng-content&gt;&lt;/ng-content&gt;&#96;</span></p>
                                <p>})</p>
                                <p><span class="color-pink">export class</span> <span class="color-blue">ContentComponent</span> <span class="color-pink">implements</span> <span class="color-blue">AfterContentInit</span> {</p>
                                <p class="ml-30">    <span class="color-red">ngAfterContentInit</span>() {</p>
                                <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">'Content Initialized'</span>);</p>
                                <p class="ml-30">    }</p>
                                <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <span class="background-grey">ngAfterContentChecked()</span>
                        <p>It is called after the projected content has been checked during the change detection cycle.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">import</span> { Component, AfterContentChecked } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                                <br />
                                <p><span class="color-red">@Component</span>({</p>
                                <p class="ml-30">    <span class="color-pink">selector</span>: <span class="color-green">'app-content-check'</span>,</p>
                                <p class="ml-30">    <span class="color-pink">template</span>: <span class="color-green">&#96;&lt;ng-content&gt;&lt;/ng-content&gt;&#96;</span></p>
                                <p>})</p>
                                <p><span class="color-pink">export class</span> <span class="color-blue">ContentCheckComponent</span> <span class="color-pink">implements</span> <span class="color-blue">AfterContentChecked</span> {</p>
                                <p class="ml-30">    <span class="color-red">ngAfterContentChecked</span>() {</p>
                                <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">'Content Checked'</span>);</p>
                                <p class="ml-30">    }</p>
                                <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <span class="background-grey">ngAfterViewInit()</span>
                        <p>It is called once after the component's view and its child views have been initialized.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">import</span> { Component, AfterViewInit, ViewChild, ElementRef } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                                <br />
                                <p><span class="color-red">@Component</span>({</p>
                                <p class="ml-30">    <span class="color-pink">selector</span>: <span class="color-green">'app-view'</span>,</p>
                                <p class="ml-60">    <span class="color-pink">template</span>: <span class="color-green">&#96;&lt;p #paragraph&gt;View Initialized&lt;/p&gt;&#96;</span></p>
                                <p>})</p>
                                <p><span class="color-pink">export class</span> <span class="color-blue">ViewComponent</span> <span class="color-pink">implements</span> <span class="color-blue">AfterViewInit</span> {</p>
                                <p class="ml-30">    <span class="color-red">@ViewChild</span>(<span class="color-green">'paragraph'</span>) paragraph!: <span class="color-blue">ElementRef</span>;</p>
                                <br />
                                <p class="ml-30">    <span class="color-red">ngAfterViewInit</span>() {</p>
                                <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">'View Initialized:'</span>, <span class="color-blue">this</span>.paragraph.nativeElement.textContent);</p>
                                <p class="ml-30">    }</p>
                                <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <span class="background-grey">ngAfterViewChecked()</span>
                        <p>It is called after the component's view and its child views have been checked.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">import</span> { Component, AfterViewChecked } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                                <br />
                                <p><span class="color-red">@Component</span>({</p>
                                <p class="ml-30">    <span class="color-pink">selector</span>: <span class="color-green">'app-view-check'</span>,</p>
                                <p class="ml-30">    <span class="color-pink">template</span>: <span class="color-green">&#96;&lt;p&gt;View Check&lt;/p&gt;&#96;</span></p>
                                <p>})</p>
                                <p><span class="color-pink">export class</span> <span class="color-blue">ViewCheckComponent</span> <span class="color-pink">implements</span> <span class="color-blue">AfterViewChecked</span> {</p>
                                <p class="ml-30">    <span class="color-red">ngAfterViewChecked</span>() {</p>
                                <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">'View Checked'</span>);</p>
                                <p class="ml-30">    }</p>
                                <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <span class="background-grey">ngOnDestroy()</span>
                        <p>It is called just before the component is destroyed.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">import</span> { Component, OnDestroy } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                                <br />
                                <p><span class="color-red">@Component</span>({</p>
                                <p class="ml-30">    <span class="color-pink">selector</span>: <span class="color-green">'app-destroy'</span>,</p>
                                <p class="ml-30">    <span class="color-pink">template</span>: <span class="color-green">&#96;&lt;p&gt;Component Destroyed&lt;/p&gt;&#96;</span></p>
                                <p>})</p>
                                <p><span class="color-pink">export class</span> <span class="color-blue">DestroyComponent</span> <span class="color-pink">implements</span> <span class="color-blue">OnDestroy</span> {</p>
                                <p class="ml-30">    <span class="color-red">ngOnDestroy</span>() {</p>
                                <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">'Component Destroyed'</span>);</p>
                                <p class="ml-30">    }</p>
                                <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>


            </div>
        </section>
    )
}