import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/tag/meta";

export default function Meta() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - <meta> Tag | Aspirant's Home");
        const urls = {
            'previous': '/html/tag-list'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - &lt;meta&gt; Tag</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">&lt;meta&gt;</span> tag in HTML is used to define metadata about an HTML document. Metadata provides additional information to search engines, browsers, and other web services. The <span class="background-grey">&lt;meta&gt;</span> tag is placed within the <span class="background-grey">&lt;head&gt;</span> section of the document and doesn’t display content on the page itself.
                </p>
                        
                <h5 className='mt-5 mb-3'>Structure and Purpose of <span class="background-grey">&lt;meta&gt;</span></h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">&lt;meta&gt;</span> tags supply information such as character encoding, author, description, keywords, and viewport settings.</li>
                    <li>Descriptive metadata helps search engines index and rank the page effectively.</li>
                    <li>Includes settings that control the page’s display on mobile devices, browser behavior, and page load optimizations.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Common <span class="background-grey">&lt;meta&gt;</span> Tag Attributes</h5>
                <p>The <span class="background-grey">&lt;meta&gt;</span> tag is self-closing and takes several important attributes depending on its purpose.</p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <span class="background-grey">charset</span>: Specifies the character encoding for the document, ensuring correct display of text characters. UTF-8 is the most widely used encoding.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <span class="background-grey">name</span> <strong>and</strong> <span class="background-grey">content</span>: Used together to provide document metadata. Common examples include:
                        <ul style={{ listStyle: 'disc' }} className='mt-3'>
                            <li>
                                <strong>Description</strong>: A brief description of the page content, shown in search engine results.
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>&lt;meta <span class="color-pink">name</span>=<span class="color-green">"description"</span> <span class="color-pink">content</span>=<span class="color-green">"Learn HTML and web development basics."</span>&gt;</p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <strong>Keywords</strong>: Keywords related to the page content, though less important for SEO today.
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                        <p>&lt;meta <span class="color-pink">name</span>=<span class="color-green">"keywords"</span> <span class="color-pink">content</span>=<span class="color-green">"HTML, web development, tutorials"</span>&gt;</p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <strong>Author</strong>: Specifies the author of the document.
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>&lt;meta <span class="color-pink">name</span>=<span class="color-green">"author"</span> <span class="color-pink">content</span>=<span class="color-green">"John Doe"</span>&gt;</p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <strong>Viewport</strong>: Controls the page’s dimensions and scaling on mobile devices, crucial for responsive design.
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>&lt;meta <span class="color-pink">name</span>=<span class="color-green">"viewport"</span> <span class="color-pink">content</span>=<span class="color-green">"width=device-width, initial-scale=1.0"</span>&gt;</p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <span class="background-grey">http-equiv</span>: Acts as an HTTP header and affects the browser’s behavior. Common examples include:
                        <ul style={{ listStyle: 'disc' }}>
                            <li>
                                <strong>Refresh</strong>: Reloads the page after a specified time interval.
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>&lt;meta <span class="color-pink">http-equiv</span>=<span class="color-green">"refresh"</span> <span class="color-pink">content</span>=<span class="color-green">"30"</span>&gt;</p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <strong>Content-Security-Policy</strong>: Controls which content sources are allowed for security.
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>&lt;meta <span class="color-pink">http-equiv</span>=<span class="color-green">"Content-Security-Policy"</span> <span class="color-pink">content</span>=<span class="color-green">"default-src 'self'"</span>&gt;</p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <strong>X-UA-Compatible</strong>: Specifies how the page should be displayed in Internet Explorer.
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>&lt;meta <span class="color-pink">http-equiv</span>=<span class="color-green">"X-UA-Compatible"</span> <span class="color-pink">content</span>=<span class="color-green">"IE=edge"</span>&gt;</p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <span class="background-grey">property</span>: Often used in Open Graph meta tags for social media sharing, specifying how content appears when shared on platforms like Facebook.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>&lt;meta <span class="color-pink">property</span>=<span class="color-green">"og:title"</span> <span class="color-pink">content</span>=<span class="color-green">"Welcome to My Website"</span>&gt;</p>
                                <p>&lt;meta <span class="color-pink">property</span>=<span class="color-green">"og:image"</span> <span class="color-pink">content</span>=<span class="color-green">"image.jpg"</span>&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>

                <p>The <span class="background-grey">&lt;meta&gt;</span> tag is a versatile HTML element for providing essential metadata that helps with SEO, user experience, browser settings, and social media optimization. Proper use of <span class="background-grey">&lt;meta&gt;</span> tags enhances how a web page appears in search engines and on social media, making it an essential tool in HTML document structure.</p>



            </div>
        </section>
    )
}