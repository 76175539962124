import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/jquery/events/event-isdefaultprevented";

export default function IsDefaultPrevented() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("jQuery event.isDefaultPrevented() Object | Aspirant's Home");
        const urls = {
            'previous': '/jquery/events'
        }
        path.setPreviousNext(urls);
        path.setPreviousName('Back to Events');
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>jQuery event.isDefaultPrevented() Object</h3>
            <div className='mt-4 mb-5'>
                <p>The <span class="background-grey">event.isDefaultPrevented()</span> method in jQuery checks whether the default action of an event has been prevented using the <span class="background-grey">event.preventDefault()</span> method.</p>

                <p>When <span class="background-grey">event.preventDefault()</span> is called, the browser’s default behavior for the event (e.g., navigating to a link, submitting a form) is stopped. The <span class="background-grey">isDefaultPrevented()</span> method returns <span class="background-grey">true</span> if the default action has been prevented, otherwise it returns <span class="background-grey">false</span>.</p>


                <p>It has a basic syntax -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>event.<span class="color-red">isDefaultPrevented</span>();</p>
                        `
                    }}></div>
                </div>
                <p>It returns <span class="background-grey">true</span>, if <span class="background-grey">event.preventDefault()</span> was called. It returns <span class="background-grey">false</span>, if the default action was not prevented.</p>


                <h5 className='mt-5 mb-3'>Example : Checking Default Prevention for a Link</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;a <span class="color-pink">href</span>=<span class="color-green">"https://example.com"</span> <span class="color-pink">id</span>=<span class="color-green">"myLink"</span>&gt;Go to Example&lt;/a&gt;</p>
                        `
                    }}></div>
                </div>
                <h6>jQuery Code</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"#myLink"</span>).<span class="color-red">on</span>(<span class="color-green">"click"</span>, <span class="color-blue">function</span>(event) {</p>
                        <p class="ml-30">    event.<span class="color-red">preventDefault</span>(); <span class="color-grey">// Prevent the default behavior (navigation)</span></p>
                        <p class="ml-30">    <span class="color-blue">if</span> (event.<span class="color-red">isDefaultPrevented</span>()) {</p>
                        <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Default action prevented!"</span>); <span class="color-grey">// Logs this message</span></p>
                        <p class="ml-30">    } <span class="color-blue">else</span> {</p>
                        <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Default action not prevented!"</span>);</p>
                        <p class="ml-30">    }</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>Clicking on the link will not navigate to <span class="background-grey">https://example.com</span> because the default action is prevented.</li>
                    <li><span class="background-grey">event.isDefaultPrevented()</span> returns <span class="background-grey">true</span>.</li>
                </ul>



            </div>
        </section>
    )
}