import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/jquery/events/mouseout";

export default function Mouseout() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("jQuery mouseout() Method | Aspirant's Home");
        const urls = {
            'previous': '/jquery/events'
        }
        path.setPreviousNext(urls);
        path.setPreviousName('Back to Events');
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>jQuery mouseout() Method</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">mouseout()</span> method in jQuery triggers an event when the mouse pointer leaves a selected element or any of its child elements. It is commonly used to reverse hover effects, hide tooltips, or stop animations after a user moves the mouse out of an element's boundary.
                </p>

                <p>It has a basic syntax -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(selector).<span class="color-red">mouseout</span>(function);</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">selector</span> specifies the element(s) to attach the <span class="background-grey">mouseout</span> event to.</li>
                    <li><span class="background-grey">function</span> is a callback function to execute when the mouse leaves the element or its child elements.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Example: Changing Background Color on Mouse Leave</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;div <span class="color-pink">id</span>=<span class="color-green">"hoverBox"</span> <span class="color-pink">style</span>=<span class="color-green">"width: 200px; height: 100px; background-color: lightblue;"</span>&gt;</p>
                        <p class="ml-30">    Hover over this box!</p>
                        <p>&lt;/div&gt;</p>
                        `
                    }}></div>
                </div>
                <h6>jQuery Code</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"#hoverBox"</span>).<span class="color-red">mouseover</span>(<span class="color-blue">function</span>() {</p>
                        <p class="ml-30">    $(this).<span class="color-red">css</span>(<span class="color-green">"background-color"</span>, <span class="color-green">"lightgreen"</span>);</p>
                        <p>}).<span class="color-red">mouseout</span>(<span class="color-blue">function</span>() {</p>
                        <p class="ml-30">    $(this).<span class="color-red">css</span>(<span class="color-green">"background-color"</span>, <span class="color-green">"lightblue"</span>);</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>When the mouse enters the <span class="background-grey">#hoverBox</span> element, the <span class="background-grey">mouseover()</span> event changes its background color to light green.</li>
                    <li>When the mouse leaves the element, the <span class="background-grey">mouseout()</span> event reverts it back to light blue.</li>
                </ul>

            </div>
        </section>
    )
}