import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/angular/injecting-services";

export default function InjectingServices() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Injecting Services in Angular | Aspirant's Home");
        const urls = {
            'previous': '/angular/creating-services',
            'next': '/angular/http-client-module'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Injecting Services</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Injecting a service in Angular involves using the <strong>Dependency Injection (DI)</strong> system to provide a service instance to a component, directive, or another service. This enables you to access the functionality or data encapsulated in the service.
                </p>

                <h5 className='mt-5 mb-3'>Different Ways Inject a Service</h5>
                <p>Angular supports injecting services at different levels based on the configuration.</p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>Root Level Injection</strong>
                        <p>Using <span class="background-grey">providedIn: 'root'</span> in the <span class="background-grey">@Injectable</span> decorator registers the service globally. The same instance is shared across the application.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">import</span> { Injectable } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                                <br />
                                <p><span class="color-red">@Injectable</span>({</p>
                                <p class="ml-30">    <span class="color-pink">providedIn</span>: <span class="color-green">'root'</span>, <span class="color-grey">// Makes the service globally available as a singleton</span></p>
                                <p>})</p>
                                <p><span class="color-pink">export class</span> <span class="color-blue">LoggingService</span> {</p>
                                <p class="ml-30">    <span class="color-red">log</span>(message: <span class="color-blue">string</span>): void {</p>
                                <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">&#96;LoggingService: $&#123;message&#125;&#96;</span>);</p>
                                <p class="ml-30">    }</p>
                                <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='mt-4'>
                        <strong>Component Level Injection</strong>
                        <p>Use the <strong>constructor injection</strong> method to inject the service.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">import</span> { Component } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                                <p><span class="color-pink">import</span> { LoggingService } <span class="color-pink">from</span> <span class="color-green">'./logging.service'</span>;</p>
                                <br />
                                <p><span class="color-red">@Component</span>({</p>
                                <p class="ml-30">    <span class="color-pink">selector</span>: <span class="color-green">'app-log-test'</span>,</p>
                                <p class="ml-30">    <span class="color-pink">template</span>: <span class="color-green">&#96;</span></p>
                                <p class="ml-60 color-green">        &lt;button (click)="logMessage()"&gt;Log Message&lt;/button&gt;</p>
                                <p class="ml-30 color-green">    &#96;,</p>
                                <p>})</p>
                                <p><span class="color-pink">export class</span> <span class="color-blue">LogTestComponent</span> {</p>
                                <p class="ml-30">    <span class="color-red">constructor</span>(<span class="color-pink">private</span> loggingService: <span class="color-blue">LoggingService</span>) {}</p>
                                <br />
                                <p class="ml-30">    <span class="color-red">logMessage</span>(): void {</p>
                                <p class="ml-60">        <span class="color-blue">this</span>.loggingService.<span class="color-red">log</span>(<span class="color-green">'This is a test message!'</span>);</p>
                                <p class="ml-30">    }</p>
                                <p>}</p>
                                `
                            }}></div>
                        </div>
                        <p>In this example,</p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>The <span class="background-grey">LoggingService</span> is injected into the component's constructor.</li>
                            <li>The <span class="background-grey">private loggingService</span> parameter is automatically assigned an instance of <span class="background-grey">LoggingService</span>.</li>
                        </ul>
                    </li>
                    <li className='mt-4'>
                        <strong>Module Level Injection</strong>
                        <p>You can register a service in the providers array of an Angular module. This scopes the service to the specific module.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">import</span> { NgModule } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                                <p><span class="color-pink">import</span> { LoggingService } <span class="color-pink">from</span> <span class="color-green">'./logging.service'</span>;</p>
                                <br />
                                <p><span class="color-red">@NgModule</span>({</p>
                                <p class="ml-30">    <span class="color-pink">declarations</span>: [<span class="color-green">...</span>],</p>
                                <p class="ml-30">    <span class="color-pink">imports</span>: [<span class="color-green">...</span>],</p>
                                <p class="ml-30">    <span class="color-pink">providers</span>: [LoggingService], <span class="color-grey">// Registers the service for this module</span></p>
                                <p>})</p>
                                <p><span class="color-pink">export class</span> <span class="color-blue">AppModule</span> {}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='mt-4'>
                        <strong>Injecting Services into Other Services</strong>
                        <p>Services can also depend on other services.</p>
                        <h6>Injecting <span class="background-grey">LoggingService</span> into a <span class="background-grey">NotificationService</span></h6>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">import</span> { Injectable } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                                <p><span class="color-pink">import</span> { LoggingService } <span class="color-pink">from</span> <span class="color-green">'./logging.service'</span>;</p>
                                <br />
                                <p><span class="color-red">@Injectable</span>({</p>
                                <p class="ml-30">    <span class="color-pink">providedIn</span>: <span class="color-green">'root'</span>,</p>
                                <p>})</p>
                                <p><span class="color-pink">export class</span> <span class="color-blue">NotificationService</span> {</p>
                                <p class="ml-30">    <span class="color-red">constructor</span>(<span class="color-pink">private</span> loggingService: <span class="color-blue">LoggingService</span>) {}</p>
                                <br />
                                <p class="ml-30">    <span class="color-red">notify</span>(message: <span class="color-blue">string</span>): void {</p>
                                <p class="ml-60">        <span class="color-blue">this</span>.loggingService.<span class="color-red">log</span>(<span class="color-green">&#96;Notification: $&#123;message&#125;&#96;</span>);</p>
                                <p class="ml-60">        <span class="color-red">alert</span>(message);</p>
                                <p class="ml-30">    }</p>
                                <p>}</p>
                                `
                            }}></div>
                        </div>
                        <p>Here, <span class="background-grey">NotificationService</span> depends on <span class="background-grey">LoggingService</span>. When <span class="background-grey">NotificationService</span> is injected, Angular resolves its dependencies and provides the required <span class="background-grey">LoggingService</span> instance.</p>
                    </li>
                </ul>

                
            </div>
        </section>
    )
}