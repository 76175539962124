import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Outlet, Link } from "react-router-dom";
export const pageUrl = () => "/angular/observables";

export default function Observables() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Observables in Angular | Aspirant's Home");
        const urls = {
            'previous': '/angular/injecting-services',
            'next': '/angular/setting-up-routing'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Observables</h3>
            <div className='mt-4 mb-5'>
                <p>
                    <strong>Observables</strong> are a key part of Angular's reactive programming model, introduced by the <strong>RxJS (Reactive Extensions for JavaScript)</strong> library. Observables represent <strong>streams of data</strong> that you can <strong>subscribe to</strong> in order to receive updates when new data is emitted.
                </p>
                <p>
                    Think of an Observable like a pipe through which data flows over time. Components and services can listen to these streams and react to the data, enabling asynchronous operations.
                </p>

                <h5 className='mt-5 mb-3'>Key Features of Observables</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li><strong>Asynchronous</strong>: Observables handle asynchronous data like HTTP requests, user input, or real-time updates.</li>
                    <li><strong>Lazy</strong>: Observables don’t start emitting data until they are subscribed to.</li>
                    <li><strong>Multiple Emissions</strong>: They can emit multiple values over time (unlike promises, which resolve only once).</li>
                    <li><strong>Operators</strong>: RxJS provides a wide range of operators (like <span class="background-grey">map</span>, <span class="background-grey">filter</span>, <span class="background-grey">merge</span>) to manipulate the data stream.</li>
                    <li><strong>Unsubscribe</strong>: Observables allow you to unsubscribe to clean up resources and prevent memory leaks.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Example of Observables</h5>
                <p>Here’s a simple example of creating and subscribing to an Observable:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">import</span> { <span class="color-blue">Observable</span> } <span class="color-pink">from</span> <span class="color-green">'rxjs'</span>;</p>
                        <br />
                        <p><span class="color-blue">const</span> myObservable = <span class="color-blue">new</span> <span class="color-red">Observable</span>((observer) => {</p>
                        <p class="ml-30">    observer.<span class="color-red">next</span>(<span class="color-green">'Hello'</span>); <span class="color-grey">// Emit "Hello"</span></p>
                        <p class="ml-30">    observer.<span class="color-red">next</span>(<span class="color-green">'World'</span>); <span class="color-grey">// Emit "World"</span></p>
                        <p class="ml-30">    observer.<span class="color-red">complete</span>();    <span class="color-grey">// Signal that no more values will be emitted</span></p>
                        <p>});</p>
                        <br />
                        <p class="color-grey">// Subscribing to the Observable</p>
                        <p>myObservable.<span class="color-red">subscribe</span>({</p>
                        <p class="ml-30">    <span class="color-pink">next</span>: (value) => <span class="color-pink">console</span>.<span class="color-red">log</span>(value), <span class="color-grey">// Handle emitted values</span></p>
                        <p class="ml-30">    <span class="color-pink">error</span>: (err) => <span class="color-pink">console</span>.<span class="color-red">error</span>(<span class="color-green">'Error:'</span>, err), <span class="color-grey">// Handle errors</span></p>
                        <p class="ml-30">    <span class="color-pink">complete</span>: () => <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">'Completed'</span>), <span class="color-grey">// Handle completion</span></p>
                        <p>});</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Observables in Angular</h5>
                <p>In Angular, Observables are widely used for:</p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li><strong>HTTP Requests</strong>: Using the HttpClient service.</li>
                    <li><strong>Forms</strong>: Observing form control value changes.</li>
                    <li><strong>Routing</strong>: Listening to route parameter or query parameter changes.</li>
                    <li><strong>Event Handling</strong>: Observing user events like clicks or keypresses.</li>
                    <li><strong>Custom Data Streams</strong>: Sharing data between components or services.</li>
                </ul>

                <p>Let's check an example of <strong>HTTP Request with Observables</strong></p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">import</span> { <span class="color-blue">HttpClient</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/common/http'</span>;</p>
                        <p><span class="color-pink">import</span> { <span class="color-blue">Component</span>, <span class="color-blue">OnInit</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                        <br />
                        <p><span class="color-red">@Component</span>({</p>
                        <p class="ml-30">    <span class="color-pink">selector</span>: <span class="color-green">'app-posts'</span>,</p>
                        <p class="ml-30">    <span class="color-pink">template</span>: <span class="color-green">&#96;</span></p>
                        <p class="ml-60 color-green">        &lt;ul&gt;</p>
                        <p class="ml-90 color-green">            &lt;li *ngFor="let post of posts"&gt;{{ post.title }}&lt;/li&gt;</p>
                        <p class="ml-60 color-green">        &lt;/ul&gt;</p>
                        <p class="ml-30 color-green">    &#96;,</p>
                        <p>})</p>
                        <p><span class="color-pink">export class</span> <span class="color-blue">PostsComponent</span> <span class="color-pink">implements</span> <span class="color-blue">OnInit</span> {</p>
                        <p class="ml-30">    posts: <span class="color-blue">any</span>[] = [];</p>
                        <br />
                        <p class="ml-30">    <span class="color-red">constructor</span>(<span class="color-pink">private</span> http: <span class="color-blue">HttpClient</span>) {}</p>
                        <br />
                        <p class="ml-30">    <span class="color-red">ngOnInit</span>() {</p>
                        <p class="ml-60">        <span class="color-blue">this</span>.http.<span class="color-red">get</span>(<span class="color-green">'https://jsonplaceholder.typicode.com/posts'</span>).<span class="color-red">subscribe</span>({</p>
                        <p class="ml-90">            <span class="color-pink">next</span>: (data) => {</p>
                        <p class="ml-120">                <span class="color-blue">this</span>.posts = data <span class="color-blue">as</span> any[];</p>
                        <p class="ml-90">            },</p>
                        <p class="ml-90">            <span class="color-pink">error</span>: (err) => {</p>
                        <p class="ml-120">                <span class="color-pink">console</span>.<span class="color-red">error</span>(<span class="color-green">'Error fetching posts:'</span>, err);</p>
                        <p class="ml-90">            },</p>
                        <p class="ml-90">            <span class="color-pink">complete</span>: () => {</p>
                        <p class="ml-120">                <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">'Data fetching complete'</span>);</p>
                        <p class="ml-90">            },</p>
                        <p class="ml-60">        });</p>
                        <p class="ml-30">    }</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>


            </div>
        </section>
    )
}