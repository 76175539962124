import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Outlet, Link } from "react-router-dom";
export const pageUrl = () => "/angular/rxjs-observables";

export default function RxjsObservables() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("RxJS Observables in Angular | Aspirant's Home");
        const urls = {
            'previous': '/angular/state-management',
            'next': '/angular/ivy-renderer'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>What is an Observable?</h3>
            <div className='mt-4 mb-5'>
                <p>An <strong>Observable</strong> is a tool that allows your application to deal with <strong>asynchronous data</strong>.</p>
                <p>Think of it like:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>A news channel</strong> that broadcasts news updates (data).</li>
                    <li><strong>You (subscriber)</strong> tune in to the channel to receive the news.</li>
                </ul>

                <p>In Angular, Observables are used to handle:</p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li><strong>HTTP Requests</strong>: Getting data from APIs.</li>
                    <li><strong>User Events</strong>: Tracking clicks, inputs, or changes.</li>
                    <li><strong>Real-time </strong>: Working with live updates like WebSockets.</li>
                </ul>


                <h5 className='mt-5 mb-3'>How Does an Observable Work?</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li><strong>Create an Observable</strong>: Set up the source of data.</li>
                    <li><strong>Subscribe</strong>: Start listening to the Observable.</li>
                    <li><strong>React to Data</strong>: Handle the data when it arrives.</li>
                    <li><strong>Complete or Error</strong>: Stop when the job is done or if an error occurs.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Example: Simple Observable</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">import</span> { <span class="color-blue">Observable</span> } <span class="color-pink">from</span> <span class="color-green">'rxjs'</span>;</p>
                        <br />
                        <p class="color-grey">// Create an Observable</p>
                        <p><span class="color-blue">const</span> observable = <span class="color-blue">new</span> <span class="color-red">Observable</span>((observer) => {</p>
                        <p class="ml-30">    observer.<span class="color-red">next</span>(<span class="color-green">'Hello'</span>);  <span class="color-grey">// Emit "Hello"</span></p>
                        <p class="ml-30">    observer.<span class="color-red">next</span>(<span class="color-green">'World'</span>);  <span class="color-grey">// Emit "World"</span></p>
                        <p class="ml-30">    observer.<span class="color-red">complete</span>();     <span class="color-grey">// Signal that it is done</span></p>
                        <p>});</p>
                        <br />
                        <p class="color-grey">// Subscribe to the Observable</p>
                        <p>observable.<span class="color-red">subscribe</span>({</p>
                        <p class="ml-30">    next: (data) => <span class="color-pink">console</span>.<span class="color-red">log</span>(data),   <span class="color-grey">// React to emitted data</span></p>
                        <p class="ml-30">    complete: () => <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">'Done!'</span>) <span class="color-grey">// Handle when it's finished</span></p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>Here,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The Observable emits <strong>"Hello"</strong> and <strong>"World"</strong>.</li>
                    <li>The subscriber listens to the data and prints it.</li>
                    <li>When the Observable completes, the subscriber gets notified with <strong>"Done!"</strong>.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Operators in RxJS</h5>
                <p>RxJS <strong>operators</strong> are functions to process data emitted by an Observable. Commonly used operators:</p>

                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong><span className='background-grey'>map</span>: Transform Data</strong>
                        <p>Example: Only get post titles.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">this</span>.dataService.<span class="color-red">getPosts</span>()</p>
                                <p>.<span class="color-red">pipe</span>(<span class="color-red">map</span>(posts => posts.<span class="color-red">map</span>(post => post.title)))</p>
                                <p>.<span class="color-red">subscribe</span>(titles => <span class="color-pink">console</span>.<span class="color-red">log</span>(titles));</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong><span className='background-grey'>filter</span>: Filter Data</strong>
                        <p>Example: Get posts where <span className='background-grey'>id</span> is less than 5.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">this</span>.dataService.<span class="color-red">getPosts</span>()</p>
                                <p>.<span class="color-red">pipe</span>(<span class="color-red">filter</span>(posts => posts.id < <span class="color-pink">5</span>))</p>
                                <p>.<span class="color-red">subscribe</span>(filteredPosts => <span class="color-pink">console</span>.<span class="color-red">log</span>(filteredPosts));</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong><span className='background-grey'>catchError</span>: Handle Errors</strong>
                        <p>Example: Log errors in API calls.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">import</span> { <span class="color-blue">catchError</span> } <span class="color-pink">from</span> <span class="color-green">'rxjs/operators'</span>;</p>
                                <p><span class="color-pink">import</span> { <span class="color-blue">of</span> } <span class="color-pink">from</span> <span class="color-green">'rxjs'</span>;</p>
                                <br />
                                <p><span class="color-blue">this</span>.dataService.<span class="color-red">getPosts</span>()</p>
                                <p class="ml-30">    .<span class="color-red">pipe</span>(catchError(error => {</p>
                                <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">error</span>(error);</p>
                                <p class="ml-60">        <span class="color-pink">return</span> <span class="color-red">of</span>([]); <span class="color-grey">// Return an empty array if an error occurs</span></p>
                                <p class="ml-30">    }))</p>
                                <p>.<span class="color-red">subscribe</span>(data => <span class="color-pink">console</span>.<span class="color-red">log</span>(data));</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>

                <h5 className='mt-5 mb-3'>Unsubscribing from Observables</h5>
                <p>If an Observable keeps running (e.g., live updates), you must unsubscribe when it's no longer needed to prevent memory leaks.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">import</span> { <span class="color-blue">Subscription</span> } <span class="color-pink">from</span> <span class="color-green">'rxjs'</span>;</p>
                        <br />
                        <p><span class="color-pink">export class</span> <span class="color-blue">ExampleComponent</span> <span class="color-pink">implements</span> <span class="color-blue">OnInit</span>, <span class="color-blue">OnDestroy</span> {</p>
                        <p class="ml-30">    <span class="color-pink">private</span> subscription!: <span class="color-blue">Subscription</span>;</p>
                        <br />
                        <p class="ml-30">    <span class="color-red">ngOnInit</span>() {</p>
                        <p class="ml-60">        <span class="color-blue">this</span>.subscription = <span class="color-blue">this</span>.dataService.<span class="color-red">getPosts</span>().<span class="color-red">subscribe</span>((data) => {</p>
                        <p class="ml-90">            <span class="color-pink">console</span>.<span class="color-red">log</span>(data);</p>
                        <p class="ml-60">        });</p>
                        <p class="ml-30">    }</p>
                        <br />
                        <p class="ml-30">    <span class="color-red">ngOnDestroy</span>() {</p>
                        <p class="ml-60 color-grey">        // Unsubscribe when the component is destroyed</p>
                        <p class="ml-90">        <span class="color-blue">this</span>.subscription.<span class="color-red">unsubscribe</span>();</p>
                        <p class="ml-60">    }</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>


            </div>
        </section>
    )
}