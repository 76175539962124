import React, { useEffect} from 'react'
import { MenuContext, TitleContext, PathContext } from "../Context";
import { useState, createContext, useContext } from "react";
import { Outlet, Link } from "react-router-dom";

export default function TopHeader() {
    const menu = useContext(MenuContext);
    const pathName = useContext(PathContext);
    console.log(pathName)
    const pathname = pathName.pathName;

    function openMenuPanel(){
        console.log(menu.isMenu)
        menu.setMenuOpen(!menu.isMenu)
    }
    const setPathName = (path) => {
        if(path)
            pathName.setPathName(path);
    }

    useEffect(() => {
        setPathName(window.location.pathname)
        console.log(pathname)
    }, [pathname])

    return (
        <>
            <div className="topHeader">
                <div className="div">
                    <div className="div">
                        {/* <i class="fa-solid fa-house-user"></i> Aspirant's Home */}
                        <a href='https://aspirantshome.com'>
                            <img src="https://aspirantshome.com/logo-new.png" />
                        </a>
                        <div className='float-end headerSocialPart'>
                            <span>
                                <a style={{ 'color':'inherit'}} target='_blank' href='https://www.facebook.com/people/Aspirants-Home/61560419296735/'>
                                    <i className="fa-brands fa-facebook"></i>
                                </a>
                            </span>
                            <span>
                                <a style={{ 'color': 'inherit' }} target='_blank' href='https://x.com/AspirantHome'>
                                    <i className="fa-brands fa-x-twitter"></i>
                                </a>
                            </span>
                            {/* <span><i className="fa-brands fa-linkedin"></i></span>
                            <span><i className="fa-brands fa-instagram"></i></span>
                            <span><i className="fa-brands fa-youtube"></i></span> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="topHeader-2">
                <div className="div">
                    <div className="div language-menu">
                        <div className="dropdown my-menu-dropdown text-center">
                            <span className='menuBar' onClick={()=>openMenuPanel()}>
                                <i class="fa fa-bars" aria-hidden="true"></i>
                            </span>
                            <Link className={pathname && pathname.includes('node-js') ? 'dropdown-item myLink active' : 'dropdown-item myLink'} to="/node-js/introduction">NodeJs</Link>
                            <Link className={pathname && pathname.includes('mongo-db') ? 'dropdown-item myLink active' : 'dropdown-item myLink'} to="/mongo-db/introduction">MongoDB</Link>
                            <Link className={pathname && pathname.includes('angular') ? 'dropdown-item myLink active' : 'dropdown-item myLink'} to="/angular/introduction">Angular</Link>
                            <Link className={pathname && pathname.includes('react-js') ? 'dropdown-item myLink active' : 'dropdown-item myLink'} to="/react-js/introduction">ReactJS</Link>
                            <Link className={pathname && pathname.includes('javascript') ? 'dropdown-item myLink active' : 'dropdown-item myLink'} to="/javascript/introduction">Javascript</Link>
                            <Link className={pathname && pathname.includes('html') ? 'dropdown-item myLink active' : 'dropdown-item myLink'} to="/html/introduction">HTML</Link>
                            <Link className={pathname && pathname.includes('css') ? 'dropdown-item myLink active' : 'dropdown-item myLink'} to="/css/introduction">CSS</Link>
                            <Link className={pathname && pathname.includes('mysql') ? 'dropdown-item myLink active' : 'dropdown-item myLink'} to="/mysql/introduction">Mysql</Link>
                            <Link className={pathname && pathname.includes('php') ? 'dropdown-item myLink active' : 'dropdown-item myLink'} to="/php/introduction">PHP</Link>
                            {/* <Link className={pathname && pathname.includes('python') ? 'dropdown-item myLink active' : 'dropdown-item myLink'} to="/python/introduction">Python</Link> */}
                            <Link className={pathname && pathname.includes('jquery') ? 'dropdown-item myLink active' : 'dropdown-item myLink'} to="/jquery/introduction">JQuery</Link>
                            <Link className={pathname && pathname.includes('next-js') ? 'dropdown-item myLink active' : 'dropdown-item myLink'} to="/next-js/introduction">Next Js</Link>
                            {/* <Link className={pathname && pathname.includes('blockchain') ? 'dropdown-item myLink active' : 'dropdown-item myLink'} to="/blockchain/introduction">Blockchain</Link> */}
                            {/* <Link className={pathname && pathname.includes('machine-learning') ? 'dropdown-item myLink active' : 'dropdown-item myLink'} to="/machine-learning/introduction">Machine Learning</Link> */}

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
