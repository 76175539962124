import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/tag/input";

export default function Input() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - <input> Tag | Aspirant's Home");
        const urls = {
            'previous': '/html/tag-list'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - &lt;input&gt; Tag</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">&lt;input&gt;</span> tag in HTML is used to create interactive elements within a web form that allow users to input various types of data. This versatile tag can be used to create text fields, checkboxes, radio buttons, file upload fields, and more, depending on the type attribute specified.
                </p>

                <h5 className='mt-5 mb-3'>Basic Structure of the <span class="background-grey">&lt;input&gt;</span> Tag</h5>
                <p>The <span class="background-grey">&lt;input&gt;</span> tag is an empty (self-closing) element, meaning it does not have a closing tag. It takes a variety of attributes to specify its behavior, appearance, and function.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">name</span>=<span class="color-green">"username"</span> /&gt;</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Common Types of <span class="background-grey">&lt;input&gt;</span> Tag</h5>
                <p>The <span class="background-grey">type</span> attribute is the most important attribute for <span class="background-grey">&lt;input&gt;</span>, as it defines the type of data the input accepts:</p>

                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>Text</strong> (<span class="background-grey">type="text"</span>): Standard single-line text field.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">name</span>=<span class="color-green">"username"</span> <span class="color-pink">placeholder</span>=<span class="color-green">"Enter your username"</span> /&gt;</p>
                                `
                            }}></div>
                        </div>
                        <p>Output will be -</p>
                        <div className='outputPalateBox mt-2 mb-4' style={{border:'none'}}>
                            <div className='outputPalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <input type="text" name="username" placeholder="Enter your username" />
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Password</strong> (<span class="background-grey">type="password"</span>): Hides the entered text, often used for passwords.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"password"</span> <span class="color-pink">name</span>=<span class="color-green">"password"</span> <span class="color-pink">placeholder</span>=<span class="color-green">"Enter your password"</span> /&gt;</p>
                                `
                            }}></div>
                        </div>
                        <p>Output will be -</p>
                        <div className='outputPalateBox mt-2 mb-4' style={{border:'none'}}>
                            <div className='outputPalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <input type="password" name="password" placeholder="Enter your password" />
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Email</strong> (<span class="background-grey">type="email"</span>): Validates email format.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;input <span class="color-pink">type</span>= <span class="color-green">"email"</span>  <span class="color-pink">name</span>= <span class="color-green">"email"</span>  <span class="color-pink">placeholder</span>= <span class="color-green">"Enter your email"</span> /&gt;</p>
                                `
                            }}></div>
                        </div>
                        <p>Output will be -</p>
                        <div className='outputPalateBox mt-2 mb-4' style={{border:'none'}}>
                            <div className='outputPalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><input type="email" name="email" placeholder="Enter your email" /></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Number</strong> (<span class="background-grey">type="number"</span>): Accepts numeric input and includes optional min/max.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"number"</span> <span class="color-pink">name</span>=<span class="color-green">"age"</span> <span class="color-pink">min</span>=<span class="color-green">"1"</span> <span class="color-pink">max</span>=<span class="color-green">"100"</span>  <span class="color-pink">value</span>=<span class="color-green">"1"</span> /&gt;</p>
                                `
                            }}></div>
                        </div>
                        <p>Output will be -</p>
                        <div className='outputPalateBox mt-2 mb-4' style={{border:'none'}}>
                            <div className='outputPalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><input type="number" name="age" min="1" max="100" value="1" /></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Checkbox</strong> (<span class="background-grey">type="checkbox"</span>): Allows for binary yes/no selection.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"checkbox"</span> <span class="color-pink">name</span>=<span class="color-green">"subscribe"</span> /&gt; Subscribe to newsletter</p>
                                `
                            }}></div>
                        </div>
                        <p>Output will be -</p>
                        <div className='outputPalateBox mt-2 mb-4' style={{border:'none'}}>
                            <div className='outputPalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><input type="checkbox" name="subscribe" /> Subscribe to newsletter</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Radio</strong> (<span class="background-grey">type="radio"</span>): Allows for one selection within a group.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"radio"</span> <span class="color-pink">name</span>=<span class="color-green">"gender"</span> <span class="color-pink">value</span>=<span class="color-green">"male"</span> /&gt; Male</p>
                                <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"radio"</span> <span class="color-pink">name</span>=<span class="color-green">"gender"</span> <span class="color-pink">value</span>=<span class="color-green">"female"</span> /&gt; Female</p>
                                `
                            }}></div>
                        </div>
                        <p>Output will be -</p>
                        <div className='outputPalateBox mt-2 mb-4' style={{border:'none'}}>
                            <div className='outputPalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><input type="radio" name="gender" value="male" /> Male</p>
                                <p><input type="radio" name="gender" value="female" /> Female</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>File</strong> (<span class="background-grey">type="file"</span>): Lets users upload files.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"file"</span> <span class="color-pink">name</span>=<span class="color-green">"profilePicture"</span> /&gt;</p>
                                `
                            }}></div>
                        </div>
                        <p>Output will be -</p>
                        <div className='outputPalateBox mt-2 mb-4' style={{border:'none'}}>
                            <div className='outputPalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><input type="file" name="profilePicture" /></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Date</strong> (<span class="background-grey">type="date"</span>): Displays a date picker.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"date"</span> <span class="color-pink">name</span>=<span class="color-green">"birthday"</span> /&gt;</p>
                                `
                            }}></div>
                        </div>
                        <p>Output will be -</p>
                        <div className='outputPalateBox mt-2 mb-4' style={{border:'none'}}>
                            <div className='outputPalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><input type="date" name="birthday" /></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Color</strong> (<span class="background-grey">type="color"</span>): Opens a color picker.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"color"</span> <span class="color-pink">name</span>=<span class="color-green">"favcolor"</span> /&gt;</p>
                                `
                            }}></div>
                        </div>
                        <p>Output will be -</p>
                        <div className='outputPalateBox mt-2 mb-4' style={{border:'none'}}>
                            <div className='outputPalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><input type="color" name="favcolor" /></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Range</strong> (<span class="background-grey">type="range"</span>): Creates a slider input with min/max values.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"range"</span> <span class="color-pink">name</span>=<span class="color-green">"volume"</span> <span class="color-pink">min</span>=<span class="color-green">"0"</span> <span class="color-pink">max</span>=<span class="color-green">"100"</span> /&gt;</p>
                                `
                            }}></div>
                        </div>
                        <p>Output will be -</p>
                        <div className='outputPalateBox mt-2 mb-4' style={{border:'none'}}>
                            <div className='outputPalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><input type="range" name="volume" min="0" max="100" /></p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>

                <p>
                    The <span class="background-grey">&lt;input&gt;</span> tag is highly flexible, supporting many types and attributes for creating interactive forms. Each type and attribute can be customized to improve the input experience and ensure valid data submission.
                </p>


            </div>
        </section>
    )
}