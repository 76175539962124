import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/jquery/events/click";

export default function Click() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("jQuery click() Method | Aspirant's Home");
        const urls = {
            'previous': '/jquery/events'
        }
        path.setPreviousNext(urls);
        path.setPreviousName('Back to Events');
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>jQuery click() Method</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">click()</span> function in jQuery is an event-handling method that allows you to execute code when a user clicks on an HTML element. It simplifies setting up click event listeners and is widely used for adding interactivity to web elements, like buttons, links, or images.
                </p>
                <p>It has a basic syntax -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(selector).<span class="color-red">click</span>(function);</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">selector</span> is specifies the element(s) to attach the <span class="background-grey">click</span> event to.</li>
                    <li><span class="background-grey">function</span> is a function to execute when the element is clicked. This function is often called a callback function.</li>
                </ul>

                
                <h5 className='mt-5 mb-3'>Basic Example: <span class="background-grey">click()</span> Method</h5>
                <p>Here's an example that changes the text of a paragraph when a button is clicked.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;button <span class="color-pink">id</span>=<span class="color-green">"myButton"</span>&gt;Click Me!&lt;/button&gt;</p>
                        <p>&lt;p <span class="color-pink">id</span>=<span class="color-green">"myParagraph"</span>&gt;This is a paragraph.&lt;/p&gt;</p>
                        `
                    }}></div>
                </div>
                <h6>jQuery Code</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"#myButton"</span>).<span class="color-red">click</span>(<span class="color-blue">function</span>() {</p>
                        <p class="ml-30">    $(<span class="color-green">"#myParagraph"</span>).<span class="color-red">text</span>(<span class="color-green">"Button clicked!"</span>);</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span class="background-grey">click()</span> method attaches an event listener to <span class="background-grey">#myButton</span>.</li>
                    <li>When the button is clicked, the text in <span class="background-grey">#myParagraph</span> changes to "Button clicked!".</li>
                </ul>



            </div>
        </section>
    )
}