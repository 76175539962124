import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/events/onkeyup";

export default function Onkeyup() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - onkeyup Event | Aspirant's Home");
        const urls = {
            'previous': '/html/events'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - onkeyup Event</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">onkeyup</span> event in HTML is triggered when a user releases a key on the keyboard. This event is useful for detecting input changes in real time, validating or formatting data as the user types, and enabling keyboard shortcuts. Unlike <span class="background-grey">onkeypress</span>, <span class="background-grey">onkeyup</span> captures both character and non-character keys, including "Shift," "Enter," and arrow keys.
                </p>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <p>To use <span class="background-grey">onkeyup</span>, add it as an attribute to an HTML element and specify JavaScript code or a function that should run when a key is released.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">onkeyup</span>=<span class="color-green">"alert('Key released!')"</span> <span class="color-pink">placeholder</span>=<span class="color-green">"Type here"</span>&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Here, Each time the user releases a key after pressing it, an alert appears.</p>


                <h5 className='mt-5 mb-3'>Example: Displaying Live Character Count</h5>
                <p>Using <span class="background-grey">onkeyup</span>, you can dynamically display the number of characters a user has typed in an input field.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;textarea <span class="color-pink">onkeyup</span>=<span class="color-green">"updateCharacterCount()"</span> <span class="color-pink">id</span>=<span class="color-green">"textInput"</span> <span class="color-pink">rows</span>=<span class="color-green">"5"</span> <span class="color-pink">cols</span>=<span class="color-green">"30"</span> <span class="color-pink">placeholder</span>=<span class="color-green">"Type here..."</span>&gt;&lt;/textarea&gt;</p>
                        <p>&lt;p&gt;Characters: &lt;span <span class="color-pink">id</span>=<span class="color-green">"charCount"</span>&gt;0&lt;/span&gt;&lt;/p&gt;</p>
                        <br />
                        <p>&lt;script&gt;</p>
                        <p class="ml-30">  <span class="color-blue">function</span> <span class="color-red">updateCharacterCount</span>() {</p>
                        <p class="ml-60">    <span class="color-blue">const</span> textInput = document.<span class="color-red">getElementById</span>(<span class="color-green">"textInput"</span>);</p>
                        <p class="ml-60">    document.<span class="color-red">getElementById</span>(<span class="color-green">"charCount"</span>).textContent = textInput.value.length;</p>
                        <p class="ml-30">  }</p>
                        <p>&lt;/script&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span class="background-grey">updateCharacterCount</span> function updates the displayed character count each time the user releases a key in the <span class="background-grey">textarea</span>.</li>
                </ul>


            </div>
        </section>
    )
}