import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/jquery/events/event-stopimmediatepropagation";

export default function StopImmediatePropagation() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("jQuery event.stopImmediatePropagation() Object | Aspirant's Home");
        const urls = {
            'previous': '/jquery/events'
        }
        path.setPreviousNext(urls);
        path.setPreviousName('Back to Events');
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>jQuery event.stopImmediatePropagation() Object</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">event.stopImmediatePropagation()</span> method in jQuery is used to <strong>prevent the execution of other event handlers</strong> attached to the same element and stop the event from bubbling up the DOM hierarchy. This means:
                </p>
                <ul style={{listStyle:'disc'}}>
                    <li>It stops other handlers attached to the same event from executing.</li>
                    <li>It also prevents the event from propagating (bubbling) to parent elements.</li>
                </ul>

                <p>It has a basic syntax -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>event.<span class="color-red">stopImmediatePropagation</span>();</p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-3'>Example : Stopping Other Event Handlers</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;button <span class="color-pink">id</span>=<span class="color-green">"myButton"</span>&gt;Click Me&lt;/button&gt;</p>
                        `
                    }}></div>
                </div>
                <h6>jQuery Code</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"#myButton"</span>).<span class="color-red">on</span>(<span class="color-green">"click"</span>, <span class="color-blue">function</span>() {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"First handler executed."</span>);</p>
                        <p class="ml-30">    event.<span class="color-red">stopImmediatePropagation</span>();</p>
                        <p>});</p>
                        <br />
                        <p>$(<span class="color-green">"#myButton"</span>).<span class="color-red">on</span>(<span class="color-green">"click"</span>, <span class="color-blue">function</span>() {</p>
                        <p class="ml-30">  <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Second handler executed."</span>);</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The first event handler is triggered and logs <span class="background-grey">"First handler executed."</span></li>
                    <li><span class="background-grey">event.stopImmediatePropagation()</span> prevents the second handler from executing.</li>
                    <li>Only <span class="background-grey">"First handler executed."</span> is logged.</li>
                </ul>



            </div>
        </section>
    )
}