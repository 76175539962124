import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/jquery/css-function";

export default function CSS() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("jQuery - css() Method | Aspirant's Home");
        const urls = {
            'previous': '/jquery/css-classes',
            'next': '/jquery/dimensions'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>jQuery - css() Method</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In jQuery, the <span class="background-grey">.css()</span> method allows you to <strong>get or set CSS properties</strong> for HTML elements. This method is helpful when you want to dynamically style elements or retrieve their current style values.
                </p>

                <h5 className='mt-5 mb-3'>Getting CSS Property Values</h5>
                <p>You can use the <span class="background-grey">.css()</span> method to get the current value of a specific CSS property for an element. Here is the syntax -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(selector).<span class="color-red">css</span>(<span class="color-green">"propertyName"</span>);</p>
                        `
                    }}></div>
                </div>
                <p>Where, <span class="background-grey">propertyName</span> is the CSS property you want to retrieve, passed as a string (e.g., <span class="background-grey">"color"</span>, <span class="background-grey">"font-size"</span>).</p>

                <p>Let's take a simple example -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> color = $(<span class="color-green">"#myElement"</span>).<span class="color-red">css</span>(<span class="color-green">"color"</span>);</p>
                        <p><span class="color-pink">console</span>.<span class="color-red">log</span>(color); <span class="color-grey">// Outputs the current color of #myElement</span></p>
                        `
                    }}></div>
                </div>
                <p>It retrieves the <span class="background-grey">color</span> property of <span class="background-grey">#myElement</span> and logs it to the console. If <span class="background-grey">#myElement</span> has a color style, it returns that color (e.g., <span class="background-grey">"rgb(0, 0, 0)"</span> for black).</p>


                <h5 className='mt-5 mb-3'>Setting CSS Property Values</h5>
                <p>You can also use the <span class="background-grey">.css()</span> method to set one or more CSS properties for an element.</p>
                <p>To set a <strong>single</strong> property, pass the property name and its new value as two arguments. Here is the syntax -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(selector).<span class="color-red">css</span>(<span class="color-green">"propertyName"</span>, <span class="color-green">"value"</span>);</p>
                        `
                    }}></div>
                </div>
                <p>Let's look at the below example -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(<span class="color-green">"#myElement"</span>).<span class="color-red">css</span>(<span class="color-green">"color"</span>, <span class="color-green">"blue"</span>);</p>
                        `
                    }}></div>
                </div>
                <p>It changes the <span class="background-grey">color</span> property of <span class="background-grey">#myElement</span> to blue. In this example <span class="background-grey">#myElement</span> will now have blue text.</p>

                <p>To set <strong>multiple</strong> properties at once, pass an object with key-value pairs, where each key is a CSS property, and each value is the desired property value. Here is the syntax -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(selector).<span class="color-red">css</span>({</p>
                        <p class="ml-30">    <span class="color-green">"property1"</span>: <span class="color-green">"value1"</span>,</p>
                        <p class="ml-30">    <span class="color-green">"property2"</span>: <span class="color-green">"value2"</span>,</p>
                        <p class="ml-30 color-grey">    // more properties</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>Let's look at the below example -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"#myElement"</span>).<span class="color-red">css</span>({</p>
                        <p class="ml-30">    <span class="color-green">"color"</span>: <span class="color-green">"green"</span>,</p>
                        <p class="ml-30">    <span class="color-green">"font-size"</span>: <span class="color-green">"20px"</span>,</p>
                        <p class="ml-30">    <span class="color-green">"background-color"</span>: <span class="color-green">"yellow"</span></p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>It sets multiple CSS properties for <span class="background-grey">#myElement</span>. It changes the text color to green, font size to 20px, and background color to yellow for <span class="background-grey">#myElement</span>.</p>



            </div>
        </section>
    )
}