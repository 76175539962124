import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/jquery/events/event-ispropagationstopped";

export default function IsPropagationStopped() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("jQuery event.isPropagationStopped() Object | Aspirant's Home");
        const urls = {
            'previous': '/jquery/events'
        }
        path.setPreviousNext(urls);
        path.setPreviousName('Back to Events');
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>jQuery event.isPropagationStopped() Object</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">event.isPropagationStopped()</span> method in jQuery checks if the propagation of an event has been stopped using the <span class="background-grey">event.stopPropagation()</span> method. When <span class="background-grey">event.stopPropagation()</span> is called, the event is prevented from bubbling up to parent elements.
                </p>

                <p>It has a basic syntax -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>event.<span class="color-red">isPropagationStopped</span>();</p>
                        `
                    }}></div>
                </div>
                <p>It return <span class="background-grey">true</span>, if <span class="background-grey">event.stopPropagation()</span> has been called and return <span class="background-grey">false</span>, if propagation has not been stopped.</p>


                <h5 className='mt-5 mb-3'>How Propagation Works</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>Event Bubbling</strong>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>Events "bubble" from the target element up through its ancestors.</li>
                            <li>For example, a <span class="background-grey">click</span> on a child element triggers <span class="background-grey">click</span> handlers on its parent and further up the DOM tree.</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Stopping Propagation</strong>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>By calling <span class="background-grey">event.stopPropagation()</span>, the event stops at the current element and doesn't propagate to its ancestors.</li>
                        </ul>
                    </li>
                </ul>


                <h5 className='mt-5 mb-3'>Example : Stopping Propagation</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;div <span class="color-pink">id</span>=<span class="color-green">"parent"</span>&gt;</p>
                        <p class="ml-30">    &lt;button <span class="color-pink">id</span>=<span class="color-green">"child"</span>&gt;Click Me&lt;/button&gt;</p>
                        <p>&lt;/div&gt;</p>
                        `
                    }}></div>
                </div>
                <h6>jQuery Code</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"#child"</span>).<span class="color-red">on</span>(<span class="color-green">"click"</span>, <span class="color-blue">function</span>(event) {</p>
                        <p class="ml-30">    event.<span class="color-red">stopPropagation</span>(); <span class="color-grey">// Stop the event from reaching #parent</span></p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Child clicked, propagation stopped."</span>);</p>
                        <p>});</p>
                        <br />
                        <p>$(<span class="color-green">"#parent"</span>).<span class="color-red">on</span>(<span class="color-green">"click"</span>, <span class="color-blue">function</span>(event) {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Parent clicked."</span>); <span class="color-grey">// This will not be logged</span></p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>Clicking the button logs <span class="background-grey">Child clicked, propagation stopped</span>.</li>
                    <li>The <span class="background-grey">click</span> event doesn't reach the <span class="background-grey">#parent</span> handler because propagation is stopped.</li>
                </ul>



            </div>
        </section>
    )
}