import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/jquery/remove";

export default function Remove() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("jQuery - Remove Elements | Aspirant's Home");
        const urls = {
            'previous': '/jquery/add',
            'next': '/jquery/css-classes'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>jQuery - Remove Elements</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In jQuery, you can remove elements from the DOM using a few simple methods. These methods allow you to delete elements, either by removing them entirely or by clearing out their content, depending on what you need.
                </p>

                <h5 className='mt-5 mb-3'>1. Removing Elements Completely</h5>
                <p>When you want to completely remove an element (along with its content and children), use <span class="background-grey">.remove()</span> or <span class="background-grey">.detach()</span>.</p>


                <h4 className='mt-5'>1.1 .remove()</h4>
                <p>The <span class="background-grey">.remove()</span> method completely removes the selected element(s) from the DOM, including their content and child elements. Look at the example below -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(<span class="color-green">"#myDiv"</span>).<span class="color-red">remove</span>();</p>
                        `
                    }}></div>
                </div>
                <p>It removes the element with the ID <span class="background-grey">myDiv</span>, along with all its content and children. In this example, The element <span class="background-grey">#myDiv</span> is completely removed from the DOM.</p>

                <p>You can also pass a selector to <span class="background-grey">.remove()</span> to filter elements based on certain criteria.</p>

                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(<span class="color-green">"p"</span>).<span class="color-red">remove</span>(<span class="color-green">".classToRemove"</span>);</p>
                        `
                    }}></div>
                </div>
                <p>It removes only the &lt;p&gt; elements that have the class <span class="background-grey">classToRemove</span>.</p>


                <h4 className='mt-5'>1.2 .detach()</h4>
                <p>The <span class="background-grey">.detach()</span> method is similar to <span class="background-grey">.remove()</span>, but it preserves data and events associated with the removed elements. This can be useful if you need to remove elements temporarily and reattach them later. Take a look below for simple example -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">let</span> removedElement = $(<span class="color-green">"#myDiv"</span>).<span class="color-red">detach</span>();</p>
                        `
                    }}></div>
                </div>
                <p>It removes <span class="background-grey">#myDiv</span> from the DOM, preserving its data and event handlers. Here in this above example, <span class="background-grey">#myDiv</span> is removed from the DOM, but stored in <span class="background-grey">removedElement</span>, allowing it to be reinserted if needed.</p>


                <h5 className='mt-5 mb-3'>2. Removing Only the Content of an Element</h5>
                <p>If you want to keep the element itself but remove all of its content and children, use the <span class="background-grey">.empty()</span> method.</p>

                <h4 className='mt-5'>.empty()</h4>
                <p>The <span class="background-grey">.empty()</span> method removes all child elements and text within the selected element(s), but keeps the element itself in the DOM. Look at the below example -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(<span class="color-green">"#myDiv"</span>).<span class="color-red">empty</span>();</p>
                        `
                    }}></div>
                </div>
                <p>It removes all content inside <span class="background-grey">#myDiv</span>, but keeps the <span class="background-grey">#myDiv</span> element in place.</p>

                <p className='mt-5 featureClass'>These methods provide control over removing elements or just clearing content, helping you manage the DOM more efficiently.</p>

            </div>
        </section>
    )
}