import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/object-properties";

export default function Properties() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Object Properties in JavaScript | Aspirant's Home");
        const urls = {
            'previous': '/javascript/object-methods',
            'next': '/javascript/object-getset'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Object Properties</h3>
            <div className='mt-4 mb-5'>
                <p>An <strong>object property</strong> is a <strong>key-value pair</strong> in an object.</p>
                <ul style={{listStyle:'disc'}}>
                    <li>The <strong>key</strong> is the name of the property (like a label).</li>
                    <li>The <strong>value</strong> is the data stored in the property.</li>
                </ul>
                <p>Think of an object as a <strong>real-world thing</strong>, like a <strong>car</strong>.</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>Its <strong>properties</strong> could be its color, brand, or model.</li>
                    <li>For example:
                        <ul style={{listStyle: 'disc'}}>
                            <li><strong>Key</strong>: color, <strong>Value</strong>: "red"</li>
                            <li><strong>Key</strong>: brand, <strong>Value</strong>: "Toyota"</li>
                        </ul>
                    </li>
                </ul>


                <h5 className='mt-5 mb-3'>Syntax for Object Properties</h5>
                <p>Here’s how you define and use properties in an object:</p>
                <h6 className='mt-3'>Defining an Object with Properties</h6>
                <p>You define properties by listing them as key-value pairs inside curly braces <span className='background-grey'>&#123; &#125;</span>.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> car = {</p>
                        <p class="ml-30">    <span class="color-pink">brand</span>: <span class="color-green">"Toyota"</span>,</p>
                        <p class="ml-30">    <span class="color-pink">color</span>: <span class="color-green">"Red"</span>,</p>
                        <p class="ml-30">    <span class="color-pink">year</span>: <span class="color-pink">2023</span></p>
                        <p>};</p>
                        `
                    }}></div>
                </div>
                <p>In this example:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>Keys</strong>: <span className='background-grey'>brand</span>, <span className='background-grey'>color</span>, <span className='background-grey'>year</span></li>
                    <li><strong>Values</strong>: <span className='background-grey'>"Toyota"</span>, <span className='background-grey'>"Red"</span>, <span className='background-grey'>2023</span></li>
                </ul>

                <h5 className='mt-5 mb-3'>Accessing Object Properties</h5>
                <p>You can access object properties using:</p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <h6>Dot Notation</h6>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">console</span>.<span class="color-red">log</span>(car.brand);  <span class="color-grey">// Output: Toyota</span></p>
                                    <p><span class="color-pink">console</span>.<span class="color-red">log</span>(car.year);   <span class="color-grey">// Output: 2023</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <h6>Bracket Notation</h6>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">console</span>.<span class="color-red">log</span>(car[<span class="color-green">"color"</span>]);  <span class="color-grey">// Output: Red</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>

                <h5 className='mt-5 mb-3'>When to Use Bracket Notation</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>
                        If the key has special characters or spaces:
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">let</span> person = { <span class="color-green">"full name"</span>: <span class="color-green">"Alice"</span> };</p>
                                    <p><span class="color-pink">console</span>.<span class="color-red">log</span>(person[<span class="color-green">"full name"</span>]);  <span class="color-grey">// Output: Alice</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        If the key is dynamic (stored in a variable):
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">let</span> key = <span class="color-green">"color"</span>;</p>
                                    <p><span class="color-pink">console</span>.<span class="color-red">log</span>(car[<span class="color-pink">key</span>]);  <span class="color-grey">// Output: Red</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>

                <h5 className='mt-5 mb-3'>Adding, Updating, and Deleting Properties</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <h6>Adding a New Property</h6>
                        <p>You can add a new property to an object after it’s created.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>car.type = <span class="color-green">"Sedan"</span>;  <span class="color-grey">// Adds a new property</span></p>
                                    <p><span class="color-pink">console</span>.<span class="color-red">log</span>(car);</p>
                                    <p class="color-grey">// Output: { brand: "Toyota", color: "Red", year: 2023, type: "Sedan" }</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <h6>Updating an Existing Property</h6>
                        <p>You can change the value of an existing property.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>car.color = <span class="color-green">"Blue"</span>;  <span class="color-grey">// Updates the color</span></p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(car.color);  <span class="color-grey">// Output: Blue</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <h6>Deleting a Property</h6>
                        <p>You can remove a property using the delete keyword.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">delete</span> car.year;  <span class="color-grey">// Deletes the year property</span></p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(car);</p>
                                <p class="color-grey">// Output: { brand: "Toyota", color: "Blue", type: "Sedan" }</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>


            </div>
        </section>
    )
}