import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Outlet, Link } from "react-router-dom";
export const pageUrl = () => "/angular/setting-up-routing";

export default function SetupRouting() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Setting Up Routing in Angular | Aspirant's Home");
        const urls = {
            'previous': '/angular/http-client-module',
            'next': '/angular/navigating-between-routes'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Setting Up Routing</h3>
            <div className='mt-4 mb-5'>
                <p>
                    <strong>Routing</strong> in Angular allows you to navigate between different views or components in your application based on the URL. It uses the <strong>Angular Router</strong> to configure routes and manage navigation.
                </p>

                <h5 className='mt-5 mb-3'>Steps to Set Up Routing in Angular</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>Generate an Angular App</strong>
                        <p>If you haven't created an Angular app, use this command:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">ng new</span> my-angular-app <span class="color-pink">--routing</span></p>
                                `
                            }}></div>
                        </div>
                        <p>The <span class="background-grey">--routing</span> flag automatically generates a <span class="background-grey">app-routing.module.ts</span> file for routing setup. If you already have an app without routing, you can manually add routing.</p>
                    </li>
                    <li className='mt-4'>
                        <strong>Create a Routing Module</strong>
                        <p>If the app-routing.module.ts file doesn't exist, create one using:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">ng generate</span> module app-routing <span class="color-pink">--flat --module=app</span></p>
                                `
                            }}></div>
                        </div>
                        <ul style={{ listStyle: 'disc' }}>
                            <li><span class="background-grey">--flat</span>: Places the file in the current directory.</li>
                            <li><span class="background-grey">--module=app</span>: Automatically imports and declares it in the <span class="background-grey">AppModule</span>.</li>
                        </ul>
                    </li>
                    <li className='mt-4'>
                        <strong>Define Routes</strong>
                        <p>In the <span class="background-grey">app-routing.module.ts</span> file, configure your application routes. Each route maps a <strong>path</strong> to a <strong>component</strong>.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">import</span> { <span class="color-blue">NgModule</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                                <p><span class="color-pink">import</span> { <span class="color-blue">RouterModule</span>, <span class="color-blue">Routes</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/router'</span>;</p>
                                <p><span class="color-pink">import</span> { <span class="color-blue">HomeComponent</span> } <span class="color-pink">from</span> <span class="color-green">'./home/home.component'</span>;</p>
                                <p><span class="color-pink">import</span> { <span class="color-blue">AboutComponent</span> } <span class="color-pink">from</span> <span class="color-green">'./about/about.component'</span>;</p>
                                <br />
                                <p><span class="color-blue">const</span> routes: <span class="color-pink">Routes</span> = [</p>
                                <p class="ml-30">    { <span class="color-pink">path</span>: <span class="color-green">''</span>, <span class="color-pink">redirectTo</span>: <span class="color-green">'home'</span>, <span class="color-pink">pathMatch</span>: <span class="color-green">'full'</span> }, <span class="color-grey">// Default route</span></p>
                                <p class="ml-30">    { <span class="color-pink">path</span>: <span class="color-green">'home'</span>, <span class="color-pink">component</span>: <span class="color-blue">HomeComponent</span> },</p>
                                <p class="ml-30">    { <span class="color-pink">path</span>: <span class="color-green">'about'</span>, <span class="color-pink">component</span>: <span class="color-blue">AboutComponent</span> },</p>
                                <p class="ml-30">    { <span class="color-pink">path</span>: <span class="color-green">'**'</span>, <span class="color-pink">redirectTo</span>: <span class="color-green">'home'</span> }, <span class="color-grey">// Wildcard route for unknown paths</span></p>
                                <p>];</p>
                                <br />
                                <p><span class="color-red">@NgModule</span>({</p>
                                <p class="ml-30">    <span class="color-pink">imports</span>: [<span class="color-blue">RouterModule</span>.<span class="color-red">forRoot</span>(routes)],</p>
                                <p class="ml-30">    <span class="color-pink">exports</span>: [<span class="color-blue">RouterModule</span>],</p>
                                <p>})</p>
                                <p><span class="color-pink">export class</span> <span class="color-blue">AppRoutingModule</span> {}</p>
                                `
                            }}></div>
                        </div>
                        <p>Where,</p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li><span class="background-grey">path</span>: URL segment.</li>
                            <li><span class="background-grey">component</span>: Component to display for the path.</li>
                            <li><span class="background-grey">redirectTo</span>: Redirects to another path.</li>
                            <li><span class="background-grey">pathMatch</span>: Matches the entire path (<span class="background-grey">'full'</span>) or partial path (<span class="background-grey">'prefix'</span>).</li>
                            <li><strong>Wildcard route ('**')</strong>: Handles undefined routes.</li>
                        </ul>
                    </li>
                    <li className='mt-4'>
                        <strong>Add RouterModule to App Module</strong>
                        <p>Ensure the routing module is imported in the <span class="background-grey">AppModule</span>:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">import</span> { <span class="color-blue">NgModule</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                                <p><span class="color-pink">import</span> { <span class="color-blue">BrowserModule</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/platform-browser'</span>;</p>
                                <p><span class="color-pink">import</span> { <span class="color-blue">AppRoutingModule</span> } <span class="color-pink">from</span> <span class="color-green">'./app-routing.module'</span>; <span class="color-grey">// Import the routing module</span></p>
                                <p><span class="color-pink">import</span> { <span class="color-blue">AppComponent</span> } <span class="color-pink">from</span> <span class="color-green">'./app.component'</span>;</p>
                                <p><span class="color-pink">import</span> { <span class="color-blue">HomeComponent</span> } <span class="color-pink">from</span> <span class="color-green">'./home/home.component'</span>;</p>
                                <p><span class="color-pink">import</span> { <span class="color-blue">AboutComponent</span> } <span class="color-pink">from</span> <span class="color-green">'./about/about.component'</span>;</p>
                                <br />
                                <p><span class="color-red">@NgModule</span>({</p>
                                <p class="ml-30">    <span class="color-pink">declarations</span>: [</p>
                                <p class="ml-60">        <span class="color-blue">AppComponent</span>,</p>
                                <p class="ml-60">        <span class="color-blue">HomeComponent</span>,</p>
                                <p class="ml-60">        <span class="color-blue">AboutComponent</span></p>
                                <p class="ml-30">    ],</p>
                                <p class="ml-30">    <span class="color-pink">imports</span>: [</p>
                                <p class="ml-60">        <span class="color-blue">BrowserModule</span>,</p>
                                <p class="ml-60">        <span class="color-blue">AppRoutingModule</span>, <span class="color-grey">// Add it here</span></p>
                                <p class="ml-30">    ],</p>
                                <p class="ml-30">    <span class="color-pink">bootstrap</span>: [<span class="color-blue">AppComponent</span>],</p>
                                <p>})</p>
                                <p><span class="color-pink">export class</span> <span class="color-blue">AppModule</span> {}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='mt-4'>
                        <strong>Create Components for Each Route</strong>
                        <p>Create components corresponding to your routes using:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">ng generate</span> component <span class="color-blue">home</span></p>
                                <p><span class="color-pink">ng generate</span> component <span class="color-blue">about</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='mt-4'>
                        <strong>Add Router Outlet</strong>
                        <p>The <span class="background-grey">&lt;router-outlet&gt;</span> directive acts as a placeholder for rendering routed components. Add it to the <span class="background-grey">app.component.html</span> file:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>&lt;nav&gt;</p>
                                <p class="ml-30">    &lt;a <span class="color-pink">routerLink</span>=<span class="color-green">"/home"</span>&gt;Home&lt;/a&gt;</p>
                                <p class="ml-30">    &lt;a <span class="color-pink">routerLink</span>=<span class="color-green">"/about"</span>&gt;About&lt;/a&gt;</p>
                                <p>&lt;/nav&gt;</p>
                                <br />
                                <p class="color-grey">&lt;!-- Component views will be loaded here --&gt;</p>
                                <p>&lt;<span class="color-pink">router-outlet</span>&gt;&lt;<span class="color-pink">/router-outlet</span>&gt;</p>
                                `
                            }}></div>
                        </div>
                        <p>Where,</p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li><span class="background-grey">routerLink</span>: Binds links to specific routes.</li>
                            <li><span class="background-grey">&lt;router-outlet&gt;</span>: Placeholder for routed components.</li>
                        </ul>
                    </li>
                </ul>

                <p>By setting up Angular routing, you create a structured way to manage navigation and views in your application!</p>

            </div>
        </section>
    )
}