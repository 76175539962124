import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/events/onkeypress";

export default function Onkeypress() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - onkeypress Event | Aspirant's Home");
        const urls = {
            'previous': '/html/events'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - onkeypress Event</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">onkeypress</span> event in HTML is triggered when a user presses a key that produces a character value (like letters, numbers, and symbols). This event is commonly used to detect character input, apply filters (such as allowing only letters or numbers), or capture key input for custom functionality. However, it’s worth noting that <span class="background-grey">onkeypress</span> doesn’t detect non-character keys, like "Shift," "Ctrl," or "Arrow" keys, and it's now largely superseded by the <span class="background-grey">onkeydown</span> event, which captures all key types.
                </p>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <p>To use <span class="background-grey">onkeypress</span>, add it as an attribute to an HTML element and specify JavaScript code or a function to run when a keypress is detected.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">onkeypress</span>=<span class="color-green">"alert('A key was pressed!')"</span> <span class="color-pink">placeholder</span>=<span class="color-green">"Type here"</span>&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Whenever a key that produces a character (e.g., letters or numbers) is pressed within the input field, an alert appears.</p>


                <h5 className='mt-5 mb-3'>Example: Allowing Only Alphabet Characters</h5>
                <p>You can use <span class="background-grey">onkeypress</span> to filter input, allowing only alphabet characters.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">onkeypress</span>=<span class="color-green">"allowOnlyLetters(event)"</span> <span class="color-pink">placeholder</span>=<span class="color-green">"Enter letters only"</span>&gt;</p>
                        <br />
                        <p>&lt;script&gt;</p>
                        <p class="ml-30">  <span class="color-blue">function</span> <span class="color-red">allowOnlyLetters</span>(event) {</p>
                        <p class="ml-60">    <span class="color-blue">const</span> charCode = event.charCode;</p>
                        <p class="ml-60">    <span class="color-blue">if</span> ((charCode < <span class="color-pink">65</span> || charCode > <span class="color-pink">90</span>) && (charCode < <span class="color-pink">97</span> || charCode > <span class="color-pink">122</span>)) {</p>
                        <p class="ml-90">      event.<span class="color-red">preventDefault</span>(); <span class="color-grey">// Prevent non-alphabet characters</span></p>
                        <p class="ml-60">    }</p>
                        <p class="ml-30">  }</p>
                        <p>&lt;/script&gt;</p>
                        `
                    }}></div>
                </div>
                <p>The <span class="background-grey">allowOnlyLetters</span> function restricts input to uppercase and lowercase English letters by using ASCII ranges (65–90 for uppercase, 97–122 for lowercase).</p>


                <h5 className='mt-5 mb-3'>Example: Limiting Input Length</h5>
                <p>Using <span class="background-grey">onkeypress</span>, you can limit the maximum number of characters a user can enter in an input field.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">id</span>=<span class="color-green">"limitedInput"</span> <span class="color-pink">onkeypress</span>=<span class="color-green">"limitInputLength(event, 10)"</span> <span class="color-pink">placeholder</span>=<span class="color-green">"Max 10 chars"</span>&gt;</p>
                        <br />
                        <p>&lt;script&gt;</p>
                        <p class="ml-30">  <span class="color-blue">function</span> <span class="color-red">limitInputLength</span>(event, maxLength) {</p>
                        <p class="ml-60">    <span class="color-blue">const</span> inputField = event.target;</p>
                        <p class="ml-60">    <span class="color-blue">if</span> (inputField.value.length >= maxLength) {</p>
                        <p class="ml-90">      event.<span class="color-red">preventDefault</span>();</p>
                        <p class="ml-60">    }</p>
                        <p class="ml-30">  }</p>
                        <p>&lt;/script&gt;</p>
                        `
                    }}></div>
                </div>
                <p>The <span class="background-grey">limitInputLength</span> function restricts the input length to 10 characters by calling <span class="background-grey">event.preventDefault()</span> if the length exceeds <span class="background-grey">maxLength</span>.</p>


            </div>
        </section>
    )
}