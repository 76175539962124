import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/tag/th";

export default function ThTag() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - <th> Tag | Aspirant's Home");
        const urls = {
            'previous': '/html/tag-list'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - &lt;th&gt; Tag</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">&lt;th&gt;</span> tag in HTML is used to define a header cell in a table. Unlike the <span class="background-grey">&lt;td&gt;</span> tag (for data cells), the <span class="background-grey">&lt;th&gt;</span> tag is used specifically for cells that serve as headers, usually at the top of each column or at the start of each row. By default, content inside a <span class="background-grey">&lt;th&gt;</span> cell is bolded and centered, which makes it visually distinct from regular data cells.
                </p>

                <h5 className='mt-5 mb-3'>Structure and Purpose of <span class="background-grey">&lt;th&gt;</span> Tag</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span class="background-grey">&lt;th&gt;</span> tag is intended to provide descriptive headers for rows and columns, making the table more understandable.</li>
                    <li><span class="background-grey">&lt;th&gt;</span> elements are placed within <span class="background-grey">&lt;tr&gt;</span> tags, often in the first row for column headers or the first column for row headers.</li>
                    <li><span class="background-grey">&lt;th&gt;</span> cells with bold text and center alignment by default, though this can be customized with CSS.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <p>The <span class="background-grey">&lt;th&gt;</span> tag is typically used within a <span class="background-grey">&lt;tr&gt;</span> inside a <span class="background-grey">&lt;table&gt;</span>:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;table&gt;</p>
                            <p class="ml-30">    &lt;tr&gt;</p>
                            <p class="ml-60">        &lt;th&gt;Header 1&lt;/th&gt;</p>
                            <p class="ml-60">        &lt;th&gt;Header 2&lt;/th&gt;</p>
                            <p class="ml-30">    &lt;/tr&gt;</p>
                            <p class="ml-30">    &lt;tr&gt;</p>
                            <p class="ml-60">        &lt;td&gt;Data 1&lt;/td&gt;</p>
                            <p class="ml-60">        &lt;td&gt;Data 2&lt;/td&gt;</p>
                            <p class="ml-30">    &lt;/tr&gt;</p>
                            <p>&lt;/table&gt;</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Advanced Example with Styled <span class="background-grey">&lt;th&gt;</span> Tag</h5>
                <p>Here’s an example of a styled table with custom headers:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Styled Table Headers&lt;/title&gt;</p>
                        <p class="ml-60">        &lt;style&gt;</p>
                        <p class="ml-90">            table {</p>
                        <p class="ml-120">                <span class="color-green">width</span>: <span class="color-pink">60%</span>;</p>
                        <p class="ml-120">                <span class="color-green">border-collapse</span>: collapse;</p>
                        <p class="ml-90">            }</p>
                        <p class="ml-90">            th, td {</p>
                        <p class="ml-120">                <span class="color-green">padding</span>: <span class="color-pink">12px</span>;</p>
                        <p class="ml-150">                <span class="color-green">text-align</span>: left;</p>
                        <p class="ml-150">                <span class="color-green">border</span>: <span class="color-pink">1px</span> solid <span class="color-green">#ddd</span>;</p>
                        <p class="ml-90">            }</p>
                        <p class="ml-90">            th {</p>
                        <p class="ml-120">                <span class="color-green">background-color</span>: <span class="color-pink">#4CAF50</span>; <span class="color-grey">/* Green background for headers */</span></p>
                        <p class="ml-120">                <span class="color-green">color</span>: white;             <span class="color-grey"> /* White text for headers */</span></p>
                        <p class="ml-90">            }</p>
                        <p class="ml-60">        &lt;/style&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <br />    
                        <p class="ml-60">        &lt;h2&gt;Project Status&lt;/h2&gt;</p>
                        <p class="ml-60">        &lt;table&gt;</p>
                        <p class="ml-90">            &lt;tr&gt;</p>
                        <p class="ml-120">                &lt;th&gt;Project Name&lt;/th&gt;</p>
                        <p class="ml-120">                &lt;th&gt;Manager&lt;/th&gt;</p>
                        <p class="ml-120">                &lt;th&gt;Status&lt;/th&gt;</p>
                        <p class="ml-90">            &lt;/tr&gt;</p>
                        <p class="ml-90">            &lt;tr&gt;</p>
                        <p class="ml-120">                &lt;td&gt;Website Redesign&lt;/td&gt;</p>
                        <p class="ml-120">                &lt;td&gt;John Doe&lt;/td&gt;</p>
                        <p class="ml-120">                &lt;td&gt;In Progress&lt;/td&gt;</p>
                        <p class="ml-90">            &lt;/tr&gt;</p>
                        <p class="ml-90">            &lt;tr&gt;</p>
                        <p class="ml-120">                &lt;td&gt;Mobile App&lt;/td&gt;</p>
                        <p class="ml-120">                &lt;td&gt;Jane Smith&lt;/td&gt;</p>
                        <p class="ml-120">                &lt;td&gt;Completed&lt;/td&gt;</p>
                        <p class="ml-90">            &lt;/tr&gt;</p>
                        <p class="ml-60">        &lt;/table&gt;</p>
                        <br />    
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span class="background-grey">&lt;th&gt;</span> cells have a green background with white text for a clean and distinct header row.</li>
                    <li>Each data cell is aligned to the left with consistent padding for readability.</li>
                </ul>
                <p>Output will be the following -</p>
                <div className='outputPalateBox mt-2 mb-4'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <!DOCTYPE html>
                        <html lang="en">
                        <head>
                          <meta charset="UTF-8">
                          <title>Styled Table Headers</title>
                          <style>
                            table {
                              width: 60%;
                              border-collapse: collapse;
                            }
                            th, td {
                              padding: 12px;
                              text-align: left;
                              border: 1px solid #ddd;
                            }
                            th {
                              background-color: #4CAF50; /* Green background for headers */
                              color: white;              /* White text for headers */
                            }
                          </style>
                        </head>
                        <body>
                        
                        <h2>Project Status</h2>
                        <table>
                          <tr>
                            <th>Project Name</th>
                            <th>Manager</th>
                            <th>Status</th>
                          </tr>
                          <tr>
                            <td>Website Redesign</td>
                            <td>John Doe</td>
                            <td>In Progress</td>
                          </tr>
                          <tr>
                            <td>Mobile App</td>
                            <td>Jane Smith</td>
                            <td>Completed</td>
                          </tr>
                        </table>
                        
                        </body>
                        </html>
                        `
                    }}></div>
                </div>



            </div>
        </section>
    )
}