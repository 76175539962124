import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Outlet, Link } from "react-router-dom";
export const pageUrl = () => "/angular/lazy-loaded-modules";

export default function LazyLoadedModules() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Lazy Loaded Modules in Angular | Aspirant's Home");
        const urls = {
            'previous': '/angular/shared-modules',
            'next': '/angular/making-http-requests'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Lazy Loaded Modules</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Imagine you are working on a big application with many features, but the user only needs to use one feature at a time. Loading all the code for the application at once can make it slow, even though most of it isn’t used immediately. <strong>Lazy loading</strong> solves this problem by <strong>loading modules only when the user needs them</strong>.
                </p>


                <h5 className='mt-5 mb-3'>Why Use Lazy Loading?</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li><strong>Improves Performance</strong>: Only the required part of the app is loaded initially.</li>
                    <li><strong>Reduces Initial Load Time</strong>: The app becomes faster when it starts.</li>
                    <li><strong>Saves Bandwidth</strong>: Users don’t download code they don’t need.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Real-Life Analogy</h5>
                <p>Think of a movie streaming service. Instead of downloading the entire library of movies to your device, you only download the movie you want to watch. Lazy loading in Angular works the same way—it loads the code only when it’s needed.</p>


                <h5 className='mt-5 mb-3'>How to Set Up Lazy Loading?</h5>
                <p>Here’s a step-by-step guide to creating a <strong>lazy-loaded module</strong> in Angular:</p>

                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>Create a New Module</strong>
                        <p>First, generate a feature module. For example, let’s create a <span class="background-grey">Products</span> module:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">ng generate</span> module <span class="color-blue">products</span> <span class="color-pink">--route</span> <span class="color-blue">products</span> <span class="color-pink">--module</span> <span class="color-blue">app.module</span></p>
                                `
                            }}></div>
                        </div>
                        <p>What this command does:</p>
                        <ul style={{ listStyle: 'decimal' }}>
                            <li>Creates a <span class="background-grey">ProductsModule</span>.</li>
                            <li>Automatically sets up <strong>lazy loading</strong> for this module by adding a route.</li>
                        </ul>
                        <p className='mt-3'>You will see:</p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li><span class="background-grey">products.module.ts</span>: The new module file.</li>
                            <li><span class="background-grey">products-routing.module.ts</span>: The file that handles routing for the module.</li>
                        </ul>
                    </li>
                    <li className='mt-4'>
                        <strong>Update App Routing</strong>
                        <p>Angular automatically adds the lazy-loading route in the <span class="background-grey">app-routing.module.ts</span> file:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">import</span> { <span class="color-blue">NgModule</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                                <p><span class="color-pink">import</span> { <span class="color-blue">RouterModule</span>, <span class="color-blue">Routes</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/router'</span>;</p>
                                <br />
                                <p><span class="color-blue">const</span> routes: <span class="color-pink">Routes</span> = [</p>
                                <p class="ml-30">    { <span class="color-pink">path</span>: <span class="color-green">'products'</span>, <span class="color-pink">loadChildren</span>: () => <span class="color-red">import</span>(<span class="color-green">'./products/products.module'</span>).<span class="color-red">then</span>(m => m.ProductsModule) },</p>
                                <p class="ml-30">    { <span class="color-pink">path</span>: <span class="color-green">''</span>, <span class="color-pink">redirectTo</span>: <span class="color-green">'/home'</span>, <span class="color-pink">pathMatch</span>: <span class="color-green">'full'</span> }, <span class="color-grey">// Redirect to home by default</span></p>
                                <p class="ml-30">    { <span class="color-pink">path</span>: <span class="color-green">'**'</span>, <span class="color-pink">redirectTo</span>: <span class="color-green">'/home'</span> }, <span class="color-grey">// Handle unknown routes</span></p>
                                <p>];</p>
                                <br />
                                <p><span class="color-red">@NgModule</span>({</p>
                                <p class="ml-30">    <span class="color-pink">imports</span>: [RouterModule.<span class="color-red">forRoot</span>(routes)],</p>
                                <p class="ml-30">    <span class="color-pink">exports</span>: [<span class="color-blue">RouterModule</span>],</p>
                                <p>})</p>
                                <p><span class="color-pink">export class</span> <span class="color-blue">AppRoutingModule</span> {}</p>
                                `
                            }}></div>
                        </div>
                        <p>Here, </p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>The route <span class="background-grey">products</span> is configured to load the <span class="background-grey">ProductsModule</span>.</li>
                            <li><span class="background-grey">loadChildren</span> specifies that the module will be loaded only when the user navigates to <span class="background-grey">/products</span>.</li>
                        </ul>
                    </li>
                    <li className='mt-4'>
                        <strong>Products Module Structure</strong>
                        <p>Let’s assume you have the following structure in the <span class="background-grey">products</span> folder:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>products/</p>
                                <p>├── products.module.ts</p>
                                <p>├── products-routing.module.ts</p>
                                <p>├── products.component.ts</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='mt-4'>
                        <strong>Products Module Code</strong>
                        <p>In <span class="background-grey">products.module.ts</span>:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">import</span> { <span class="color-blue">NgModule</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                                <p><span class="color-pink">import</span> { <span class="color-blue">CommonModule</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/common'</span>;</p>
                                <p><span class="color-pink">import</span> { <span class="color-blue">ProductsComponent</span> } <span class="color-pink">from</span> <span class="color-green">'./products.component'</span>;</p>
                                <p><span class="color-pink">import</span> { <span class="color-blue">RouterModule</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/router'</span>;</p>
                                <br />
                                <p><span class="color-red">@NgModule</span>({</p>
                                <p class="ml-30">    <span class="color-pink">declarations</span>: [<span class="color-blue">ProductsComponent</span>], <span class="color-grey">// Declare the component</span></p>
                                <p class="ml-30">    <span class="color-pink">imports</span>: [</p>
                                <p class="ml-60">        <span class="color-blue">CommonModule</span>,       <span class="color-grey">// For Angular's basic directives (ngIf, ngFor)</span></p>
                                <p class="ml-60">        <span class="color-blue">RouterModule</span>,       <span class="color-grey">// Required for routing</span></p>
                                <p class="ml-30">    ],</p>
                                <p>})</p>
                                <p><span class="color-pink">export class</span> <span class="color-blue">ProductsModule</span> {}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='mt-4'>
                        <strong>Set Up Routing for Products</strong>
                        <p>In products-routing.module.ts:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">import</span> { <span class="color-blue">NgModule</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                                <p><span class="color-pink">import</span> { <span class="color-blue">RouterModule</span>, <span class="color-blue">Routes</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/router'</span>;</p>
                                <p><span class="color-pink">import</span> { <span class="color-blue">ProductsComponent</span> } <span class="color-pink">from</span> <span class="color-green">'./products.component'</span>;</p>
                                <br />
                                <p><span class="color-blue">const</span> routes: <span class="color-pink">Routes</span> = [</p>
                                <p class="ml-30">    { <span class="color-pink">path</span>: <span class="color-green">''</span>, <span class="color-pink">component</span>: <span class="color-blue">ProductsComponent</span> }, <span class="color-grey">// Default route for Products</span></p>
                                <p>];</p>
                                <br />
                                <p><span class="color-red">@NgModule</span>({</p>
                                <p class="ml-30">    <span class="color-pink">imports</span>: [RouterModule.<span class="color-red">forChild</span>(routes)], <span class="color-grey">// 'forChild' for feature module routing</span></p>
                                <p class="ml-30">    <span class="color-pink">exports</span>: [<span class="color-blue">RouterModule</span>],</p>
                                <p>})</p>
                                <p><span class="color-pink">export class</span> <span class="color-blue">ProductsRoutingModule</span> {}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='mt-4'>
                        <strong>Add a Component for Products</strong>
                        <p>Generate a component for the module:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">ng generate</span> component <span class="color-blue">products/products</span></p>
                                `
                            }}></div>
                        </div>
                        <p>The <span class="background-grey">products.component.ts</span> file:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">import</span> { <span class="color-blue">Component</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                                <br />
                                <p><span class="color-red">@Component</span>({</p>
                                <p class="ml-30">    <span class="color-pink">selector</span>: <span class="color-green">'app-products'</span>,</p>
                                <p class="ml-30">    <span class="color-pink">template</span>: <span class="color-green">&#96;&lt;h1&gt;Products Page&lt;/h1&gt;&#96;</span>,</p>
                                <p>})</p>
                                <p><span class="color-pink">export class</span> <span class="color-blue">ProductsComponent</span> {}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>


                <h5 className='mt-5 mb-3'>How Lazy Loading Works</h5>
                <ul style={{listStyle: 'decimal'}}>
                    <li>When the app loads, only the <span class="background-grey">AppModule</span> and its dependencies are loaded.</li>    
                    <li>When the user navigates to <span class="background-grey">/products</span>, Angular:
                        <ul style={{listStyle:'disc'}}>
                            <li>Detects the <span class="background-grey">loadChildren</span> route.</li>
                            <li>Loads the <span class="background-grey">ProductsModule</span> dynamically.</li>
                            <li>Displays the <span class="background-grey">ProductsComponent</span>.</li>
                        </ul>
                    </li>
                </ul>


                <h5 className='mt-5 mb-3'>Benefits of Lazy Loading</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>Faster Initial Load:</strong>
                        <ul style={{listStyle: "disc"}}>
                            <li>Only the core app loads at the start.</li>
                            <li>Extra features are loaded later.</li>
                        </ul>
                    </li>
                    <li className='mt-3'>
                        <strong>Optimized Resource Usage:</strong>
                        <ul style={{ listStyle: "disc" }}>
                            <li>Reduces unnecessary code downloads.</li>
                        </ul>
                    </li>
                    <li className='mt-3'>
                        <strong>Scalable for Large Apps:</strong>
                        <ul style={{ listStyle: "disc" }}>
                            <li>Perfect for apps with many features or pages.</li>
                        </ul>
                    </li>
                </ul>



            </div>
        </section>
    )
}