import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/jquery/get-post";

export default function GetPost() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("jQuery - AJAX get() and post() Methods | Aspirant's Home");
        const urls = {
            'previous': '/jquery/load',
            'next': '/jquery/no-conflict-function'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>jQuery - AJAX get() and post() Methods</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">.get()</span> and <span class="background-grey">.post()</span> methods in jQuery are two AJAX functions that let you send HTTP GET or POST requests to a server and handle the response. These methods are used to retrieve or submit data asynchronously without reloading the web page, making your application more dynamic and responsive.
                </p>

                <h5 className='mt-5 mb-3'>jQuery <span class="background-grey">.get()</span> Method</h5>
                <p>The <span class="background-grey">.get()</span> method sends an HTTP GET request to retrieve data from a server. It's commonly used to fetch data without affecting any server-side resources (like fetching information from a database or an API endpoint).</p>
                <p>It has a basic syntax -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$.<span class="color-red">get</span>(url, [data], [callback]);</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">url</span> is the URL where the request is sent (e.g., an API endpoint or server file).</li>
                    <li><span class="background-grey">data</span> (optional) is an object or query string with data to send to the server.</li>
                    <li><span class="background-grey">callback</span> (optional) is a function to execute if the request is successful. The server response is passed to this function as a parameter.</li>
                </ul>

                <h4 className='mt-4'>Example 1: Basic <span class="background-grey">.get()</span> Request</h4>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$.<span class="color-red">get</span>(<span class="color-green">"data.txt"</span>, <span class="color-blue">function</span>(data) {</p>
                        <p class="ml-30">    $(<span class="color-green">"#content"</span>).<span class="color-red">html</span>(data);</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span class="background-grey">.get()</span> method requests the contents of "data.txt" from the server.</li>
                    <li>If successful, it inserts the content into the element with the <span class="background-grey">id="content"</span>.</li>
                </ul>

                <h4 className='mt-4'>Example 2: <span class="background-grey">.get()</span> with Data and Callback</h4>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$.<span class="color-red">get</span>(<span class="color-green">"search.php"</span>, { query: <span class="color-green">"jQuery"</span> }, <span class="color-blue">function</span>(response) {</p>
                        <p class="ml-30">    $(<span class="color-green">"#results"</span>).<span class="color-red">html</span>(response);</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span class="background-grey">.get()</span> method sends a <span class="background-grey">query</span> parameter with the value "jQuery" to "search.php".</li>
                    <li>The server processes this request and returns results, which are displayed in the <span class="background-grey">#results</span> element.</li>
                </ul>


                <h5 className='mt-5 mb-3'>jQuery <span class="background-grey">.post()</span> Method</h5>
                <p>The <span class="background-grey">.post()</span> method sends an HTTP POST request to submit data to a server. It is typically used for sending form data or larger, more sensitive data, as it doesn’t display the parameters in the URL.</p>
                <p>Syntax will be the following -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$.<span class="color-red">post</span>(url, [data], [callback]);</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">url</span> is the URL where the request is sent.</li>
                    <li><span class="background-grey">data</span> (optional) is an object containing data to send to the server.</li>
                    <li><span class="background-grey">callback</span> (optional) is a function to execute if the request is successful. The server response is passed to this function.</li>
                </ul>


                <h4 className='mt-4'>Example 1: Basic <span class="background-grey">.post()</span> Request</h4>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$.<span class="color-red">post</span>(<span class="color-green">"submit.php"</span>, { name: <span class="color-green">"John"</span>, age: <span class="color-pink">30</span> }, <span class="color-blue">function</span>(response) {</p>
                        <p class="ml-30">    $(<span class="color-green">"#message"</span>).<span class="color-red">html</span>(response);</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span class="background-grey">.post()</span> method sends data 	<span class="background-grey">&#123; name: "John", age: 30 &#125;</span> to "submit.php".</li>
                    <li>The server processes the data and returns a response, which is displayed in the <span class="background-grey">#message</span> element.</li>
                </ul>


                <h4 className='mt-4'>Example 2: <span class="background-grey">.post()</span> with Form Data</h4>
                <p>If you have a form and want to submit it using AJAX without reloading the page:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;form <span class="color-pink">id</span>=<span class="color-green">"myForm"</span>&gt;</p>
                        <p class="ml-30">    &lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">name</span>=<span class="color-green">"username"</span> <span class="color-pink">placeholder</span>=<span class="color-green">"Enter username"</span>&gt;</p>
                        <p class="ml-30">    &lt;button <span class="color-pink">type</span>=<span class="color-green">"submit"</span>&gt;Submit&lt;/button&gt;</p>
                        <p>&lt;/form&gt;</p>
                        <p>&lt;div <span class="color-pink">id</span>=<span class="color-green">"result"</span>&gt;&lt;/div&gt;</p>
                        `
                    }}></div>
                </div>
                <h6>jQuery Code</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"#myForm"</span>).<span class="color-red">submit</span>(<span class="color-blue">function</span>(event) {</p>
                        <p class="ml-30">    event.<span class="color-red">preventDefault</span>(); <span class="color-grey">// Prevents the default form submission behavior</span></p>
                        <br />
                        <p class="ml-30">    $.<span class="color-red">post</span>(<span class="color-green">"formHandler.php"</span>, $(this).<span class="color-red">serialize</span>(), <span class="color-blue">function</span>(response) {</p>
                        <p class="ml-60">        $(<span class="color-green">"#result"</span>).<span class="color-red">html</span>(response);</p>
                        <p class="ml-30">    });</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">$(this).serialize()</span> gathers all form input data into a query string.</li>
                    <li>The <span class="background-grey">.post()</span> method sends this data to "formHandler.php".</li>
                    <li>The server response is shown in the <span class="background-grey">#result</span> div.</li>
                </ul>



            </div>
        </section>
    )
}