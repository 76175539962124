import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/loop-for";

export default function LoopFor() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Loop For in Javascript | Aspirant's Home");
        const urls = {
            'previous': '/javascript/switch',
            'next': '/javascript/loop-for-in'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>For Loop</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className='background-grey'>for</span> <strong>loop</strong> in JavaScript is a control structure used to repeat a block of code a specific number of times. It's great for tasks like iterating through arrays or performing repetitive actions.
                </p>

                <h5 className='mt-5 mb-3'>Why Use a <span className='background-grey'>for</span> Loop?</h5>
                <p>A <span className='background-grey'>for</span> loop is useful when:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>You know how many times you want to repeat an action.</li>
                    <li>You need to iterate over items, such as numbers, arrays, or strings.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Syntax of a <span className='background-grey'>for</span> Loop</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">for</span> (initialization; condition; increment) {</p>
                        <p class="ml-30 color-grey">    // Code to run in each iteration</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li><strong>Initialization</strong>: Start a counter or variable (runs only once at the start).</li>
                    <li><strong>Condition</strong>: The loop continues as long as this evaluates to <span className='background-grey'>true</span>.</li>
                    <li><strong>Increment/Decrement</strong>: Updates the counter/variable after each iteration.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Example: Counting Numbers</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">for</span> (<span class="color-blue">let</span> i = <span class="color-pink">1</span>; i <= <span class="color-pink">5</span>; i++) {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Number:"</span>, i);</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li><strong>Initialization</strong>: <span className='background-grey'>let i = 1</span> (starts the counter at 1).</li>
                    <li><strong>Condition</strong>: <span className='background-grey'>i &lt;= 5</span> (runs as long as <span className='background-grey'>i</span> is less than or equal to 5).</li>
                    <li><strong>Increment</strong>: <span className='background-grey'>i++</span> (increases <span className='background-grey'>i</span> by 1 after each loop).</li>
                </ul>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>Number: <span class="color-pink">1</span></p>
                        <p>Number: <span class="color-pink">2</span></p>
                        <p>Number: <span class="color-pink">3</span></p>
                        <p>Number: <span class="color-pink">4</span></p>
                        <p>Number: <span class="color-pink">5</span></p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-3'>How the <span className='background-grey'>for</span> Loop Works</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>First Iteration:</strong>
                        <ul style={{listStyle: 'disc'}}>
                            <li><span className='background-grey'>i = 1</span></li>
                            <li>Condition <span className='background-grey'>i &lt;= 5</span> → true</li>
                            <li>Runs the code inside the loop.</li>
                            <li>Increments <span className='background-grey'>i</span> to 2.</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Subsequent Iterations:</strong>
                        <ul style={{listStyle: 'disc'}}>
                            <li>Repeats the process (checking the condition, running the code, incrementing <span className='background-grey'>i</span>).</li>
                        </ul>
                    </li>
                    <li>
                        <strong>End:</strong>
                        <ul style={{listStyle: 'disc'}}>
                            <li>When <span className='background-grey'>i = 6</span>, the condition <span className='background-grey'>i &lt;= 5</span> becomes <strong>false</strong>, and the loop stops.</li>
                        </ul>
                    </li>
                </ul>


                <h5 className='mt-5 mb-3'>Example: Loop Through an Array</h5>
                <p>You can use a <span className='background-grey'>for</span> loop to iterate through an array.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> fruits = [<span class="color-green">"Apple"</span>, <span class="color-green">"Banana"</span>, <span class="color-green">"Cherry"</span>];</p>

                        <p><span class="color-blue">for</span> (<span class="color-blue">let</span> i = <span class="color-pink">0</span>; i < fruits.length; i++) {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(fruits[i]);</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>
                <h6>Output</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>Apple</p>
                        <p>Banana</p>
                        <p>Cherry</p>
                        `
                    }}></div>
                </div>
                <p>Here,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span className='background-grey'>fruits.length</span> gives the number of elements in the array.</li>
                    <li>The loop runs from <span className='background-grey'>i = 0</span> (first element) to <span className='background-grey'>i = fruits.length - 1</span> (last element).</li>
                </ul>


            </div>
        </section>
    )
}