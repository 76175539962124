import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/events/ondrag";

export default function Ondrag() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - ondrag Event | Aspirant's Home");
        const urls = {
            'previous': '/html/events'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - ondrag Event</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">ondrag</span> event in HTML is triggered when an element is being dragged. It fires repeatedly as the user moves the element, while dragging it over the page. This event is part of the Drag and Drop API and is used to provide real-time feedback during the drag operation.
                </p>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <p>The <span class="background-grey">ondrag</span> event can be added directly to an element that is draggable:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;div <span class="color-pink">ondrag</span>=<span class="color-green">"handleDrag()"</span>&gt;Drag me!&lt;/div&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Alternatively, you can attach the event using JavaScript:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>document.<span class="color-red">getElementById</span>(<span class="color-green">"dragElement"</span>).ondrag = <span class="color-red">function</span>() {</p>
                        <p class="ml-30">    console.<span class="color-red">log</span>(<span class="color-green">"The element is being dragged."</span>);</p>
                        <p>};</p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-3'>Example: Display a Message While Dragging</h5>
                <p>This example uses the <span class="background-grey">ondrag</span> event to display a message in the console when an element is being dragged.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;div <span class="color-pink">id</span>=<span class="color-green">"dragElement"</span> <span class="color-pink">ondrag</span>=<span class="color-green">"showDragMessage()"</span> <span class="color-pink">draggable</span>=<span class="color-green">"true"</span>&gt;</p>
                        <p class="ml-30">    Drag me!</p>
                        <p>&lt;/div&gt;</p>
                        <br />
                        <p>&lt;script&gt;</p>
                        <p class="ml-30">    <span class="color-blue">function</span> <span class="color-red">showDragMessage</span>() {</p>
                        <p class="ml-60">        console.<span class="color-red">log</span>(<span class="color-green">"The element is being dragged."</span>);</p>
                        <p class="ml-30">    }</p>
                        <p>&lt;/script&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Here, The <span class="background-grey">showDragMessage</span> function is called while the element is being dragged, and it logs the message "The element is being dragged." to the console.</p>


            </div>
        </section>
    )
}