import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/events/ontouchmove";

export default function Ontouchmove() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - ontouchmove Event | Aspirant's Home");
        const urls = {
            'previous': '/html/events'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - ontouchmove Event</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">ontouchmove</span> event in HTML is triggered when a touch point (such as a finger or stylus) is moved across the screen while it is still in contact with a touch-enabled element. This event is part of the <strong>Touch Events API</strong> and is typically used in mobile and tablet applications to track the movement of a user's touch gesture.
                </p>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <p>You can use the <span class="background-grey">ontouchmove</span> event directly in HTML, like this:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;div <span class="color-pink">ontouchmove</span>=<span class="color-green">"handleTouchMove(event)"</span>&gt;</p>
                        <p class="ml-30">    Move your finger over me!</p>
                        <p>&lt;/div&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Or attach it via JavaScript:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>document.<span class="color-red">getElementById</span>(<span class="color-green">"elementId"</span>).ontouchmove = <span class="color-red">function</span>(event) {</p>
                        <p class="ml-30 color-grey">    // Your code here</p>
                        <p>};</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Example: Basic <span class="background-grey">ontouchmove</span> Event Handling</h5>
                <p>This example demonstrates how to track the movement of a touch and update the element's position on the screen based on the user's finger movement.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">name</span>=<span class="color-green">"viewport"</span> <span class="color-pink">content</span>=<span class="color-green">"width=device-width, initial-scale=1.0"</span>&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Touch Move Example&lt;/title&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <p class="ml-60">        &lt;div <span class="color-pink">id</span>=<span class="color-green">"touchArea"</span> <span class="color-pink">style</span>=<span class="color-green">"width: 100px; height: 100px; background-color: lightblue; position: absolute;"</span>&gt;</p>
                        <p class="ml-90">            Move me!</p>
                        <p class="ml-60">        &lt;/div&gt;</p>
                        <br />    
                        <p class="ml-60">        &lt;script&gt;</p>
                        <p class="ml-90">            <span class="color-blue">function</span> <span class="color-red">handleTouchMove</span>(event) {</p>
                        <p class="ml-120">                event.<span class="color-red">preventDefault</span>(); <span class="color-grey">// Prevent the default scrolling behavior</span></p>
                        <p class="ml-120">                <span class="color-blue">var</span> touch = event.touches[<span class="color-pink">0</span>]; <span class="color-grey">// Get the first touch point</span></p>
                        <p class="ml-120">                <span class="color-blue">var</span> element = document.<span class="color-red">getElementById</span>(<span class="color-green">"touchArea"</span>);</p>
                        <p class="ml-120 color-grey">                // Move the element based on touch coordinates</p>
                        <p class="ml-120">                element.style.left = touch.pageX - <span class="color-pink">50</span> + <span class="color-green">"px"</span>; <span class="color-grey">// Subtract to center the element</span></p>
                        <p class="ml-120">                element.style.top = touch.pageY - <span class="color-pink">50</span> + <span class="color-green">"px"</span>; <span class="color-grey">// Subtract to center the element</span></p>
                        <p class="ml-90">            }</p>
                        <p class="ml-90">            document.<span class="color-red">getElementById</span>(<span class="color-green">"touchArea"</span>).ontouchmove = handleTouchMove;</p>
                        <p class="ml-60">        &lt;/script&gt;</p>
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>

                <p>The <span class="background-grey">ontouchmove</span> event allows you to track multiple touch points, so you can monitor the movement of more than one finger at a time. You can access all active touch points using the event.touches array.</p>

            </div>
        </section>
    )
}