import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/angular/structural-directives";

export default function StructuralDirectives() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Structural Directives in Angular | Aspirant's Home");
        const urls = {
            'previous': '/angular/two-way-binding',
            'next': '/angular/attribute-directives'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Structural Directives</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Structural directives in Angular are directives that <strong>alter the DOM layout</strong> by adding, removing, or manipulating elements. They are applied to HTML elements to control the structure of the page dynamically.
                </p>
                <p>
                    Structural directives are prefixed with an asterisk (<span class="background-grey">*</span>), which tells Angular to treat the directive as a template.
                </p>

                <h5 className='mt-5 mb-3'>Common Structural Directives in Angular</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li><span class="background-grey">*ngIf</span>: Conditionally includes or excludes elements based on a Boolean expression.</li>
                    <li><span class="background-grey">*ngFor</span>: Iterates over a collection and renders a template for each item.</li>
                    <li><span class="background-grey">*ngSwitch</span>: Displays one of many elements based on a matching condition.</li>
                </ul>


                <h5 className='mt-5 mb-3'><span class="background-grey">*ngIf</span></h5>
                <p>The <span class="background-grey">*ngIf</span> directive conditionally renders an element based on a Boolean expression.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;div *<span class="color-pink">ngIf</span>=<span class="color-green">"condition"</span>&gt;Content to display&lt;/div&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Let's take an example -</p>
                <h6 className='mt-3'>Component (TypeScript)</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">import</span> { Component } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                        <br />
                        <p><span class="color-red">@Component</span>({</p>
                        <p class="ml-30">    <span class="color-pink">selector</span>: <span class="color-green">'app-ngif-example'</span>,</p>
                        <p class="ml-30">    <span class="color-pink">template</span>: <span class="color-green">&#96;</span></p>
                        <p class="ml-60 color-green">        &lt;button (click)=<span class="color-green">"toggle()"</span>&gt;Toggle&lt;/button&gt;</p>
                        <p class="ml-60 color-green">        &lt;p *ngIf=<span class="color-green">"isVisible"</span>&gt;This text is visible when 'isVisible' is true.&lt;/p&gt;</p>
                        <p class="ml-30 color-green">    &#96;</p>
                        <p>})</p>
                        <p><span class="color-pink">export class</span> <span class="color-blue">NgIfExampleComponent</span> {</p>
                        <p class="ml-30">    isVisible: <span class="color-blue">boolean</span> = <span class="color-blue">true</span>;</p>
                        <br />
                        <p class="ml-30">    <span class="color-red">toggle</span>() {</p>
                        <p class="ml-60">        <span class="color-blue">this</span>.isVisible = !<span class="color-blue">this</span>.isVisible;</p>
                        <p class="ml-30">    }</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>
                <h6 className='mt-3'>Template (HTML)</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;p *<span class="color-pink">ngIf</span>=<span class="color-green">"isVisible"</span>&gt;This text is visible when 'isVisible' is true.&lt;/p&gt;</p>
                        `
                    }}></div>
                </div>
                <p><strong>Output:</strong> The text appears or disappears when you click the button.</p>


                <h5 className='mt-5 mb-3'><span class="background-grey">*ngFor</span></h5>
                <p>The <span class="background-grey">*ngFor</span> directive is used to loop through a collection and render the template for each item.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            &lt;div *<span class="color-pink">ngFor</span>=<span class="color-green">"let item of items"</span>&gt;{{ item }}&lt;/div&gt;
                        `
                    }}></div>
                </div>
                <p>Let's take an example -</p>
                <h6 className='mt-3'>Component (TypeScript)</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">import</span> { Component } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                        <br />
                        <p><span class="color-red">@Component</span>({</p>
                        <p class="ml-30">    <span class="color-pink">selector</span>: <span class="color-green">'app-ngfor-example'</span>,</p>
                        <p class="ml-30">    <span class="color-pink">template</span>: <span class="color-green">&#96;</span></p>
                        <p class="ml-60 color-green">        &lt;ul&gt;</p>
                        <p class="ml-90 color-green">            &lt;li *ngFor="let name of names"&gt;{{ name }}&lt;/li&gt;</p>
                        <p class="ml-60 color-green">        &lt;/ul&gt;</p>
                        <p class="ml-30 color-green">    &#96;</p>
                        <p>})</p>
                        <p><span class="color-pink">export class</span> <span class="color-blue">NgForExampleComponent</span> {</p>
                        <p class="ml-30">    names: <span class="color-blue">string</span>[] = [<span class="color-green">'Alice'</span>, <span class="color-green">'Bob'</span>, <span class="color-green">'Charlie'</span>];</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>
                <h6 className='mt-3'>Template (HTML)</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;ul&gt;</p>
                        <p class="ml-30">    &lt;li *<span class="color-pink">ngFor</span>=<span class="color-green">"let name of names"</span>&gt;{{ name }}&lt;/li&gt;</p>
                        <p>&lt;/ul&gt;</p>
                        `
                    }}></div>
                </div>
                <p><strong>Output:</strong></p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>- Alice</p>
                        <p>- Bob</p>
                        <p>- Charlie</p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-3'><span class="background-grey">*ngSwitch</span></h5>
                <p>The <span class="background-grey">*ngSwitch</span> directive is used to conditionally display one of many elements based on a matching expression.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;div [<span class="color-pink">ngSwitch</span>]=<span class="color-green">"expression"</span>&gt;</p>
                        <p class="ml-30">    &lt;p *<span class="color-pink">ngSwitchCase</span>=<span class="color-green">"value1"</span>&gt;Content for value1&lt;/p&gt;</p>
                        <p class="ml-30">    &lt;p *<span class="color-pink">ngSwitchCase</span>=<span class="color-green">"value2"</span>&gt;Content for value2&lt;/p&gt;</p>
                        <p class="ml-30">    &lt;p *<span class="color-pink">ngSwitchDefault</span>&gt;Default content&lt;/p&gt;</p>
                        <p>&lt;/div&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Let's take an example -</p>
                <h6 className='mt-3'>Component (TypeScript)</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">import</span> { Component } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                        <br />
                        <p><span class="color-red">@Component</span>({</p>
                        <p class="ml-30">    <span class="color-pink">selector</span>: <span class="color-green">'app-ngswitch-example'</span>,</p>
                        <p class="ml-30">    <span class="color-pink">template</span>: <span class="color-green">&#96;</span></p>
                        <p class="ml-60 color-green">        &lt;div [ngSwitch]="day"&gt;</p>
                        <p class="ml-90 color-green">            &lt;p *ngSwitchCase="'Monday'"&gt;Today is Monday&lt;/p&gt;</p>
                        <p class="ml-90 color-green">            &lt;p *ngSwitchCase="'Tuesday'"&gt;Today is Tuesday&lt;/p&gt;</p>
                        <p class="ml-90 color-green">            &lt;p *ngSwitchDefault&gt;It's another day&lt;/p&gt;</p>
                        <p class="ml-60 color-green">        &lt;/div&gt;</p>
                        <p class="ml-60 color-green">        &lt;button (click)="nextDay()"&gt;Next Day&lt;/button&gt;</p>
                        <p class="ml-30 color-green">    &#96;  </p> 
                        <p>})</p>
                        <p><span class="color-pink">export class</span> <span class="color-blue">NgSwitchExampleComponent</span> {</p>
                        <p class="ml-30">    day: <span class="color-blue">string</span> = <span class="color-green">'Monday'</span>;</p>
                        <br />
                        <p class="ml-30">    <span class="color-red">nextDay</span>() {</p>
                        <p class="ml-60">        <span class="color-blue">const</span> days = [<span class="color-green">'Monday'</span>, <span class="color-green">'Tuesday'</span>, <span class="color-green">'Wednesday'</span>];</p>
                        <p class="ml-60">        <span class="color-blue">const</span> index = days.<span class="color-red">indexOf</span>(<span class="color-blue">this</span>.day);</p>
                        <p class="ml-60">        <span class="color-blue">this</span>.day = days[(index + <span class="color-pink">1</span>) <span class="color-pink">%</span> days.length];</p>
                        <p class="ml-30">    }</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>
                <h6 className='mt-3'>Template (HTML)</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;div [<span class="color-pink">ngSwitch</span>]=<span class="color-green">"day"</span>&gt;</p>
                        <p class="ml-30">    &lt;p *<span class="color-pink">ngSwitchCase</span>=<span class="color-green">"'Monday'"</span>&gt;Today is Monday&lt;/p&gt;</p>
                        <p class="ml-30">    &lt;p *<span class="color-pink">ngSwitchCase</span>=<span class="color-green">"'Tuesday'"</span>&gt;Today is Tuesday&lt;/p&gt;</p>
                        <p class="ml-30">    &lt;p *<span class="color-pink">ngSwitchDefault</span>&gt;It's another day&lt;/p&gt;</p>
                        <p>&lt;/div&gt;</p>
                        `
                    }}></div>
                </div>
                <p><strong>Output:</strong> It displays the message based on the current day and cycles through days on button clicks.</p>
                




            </div>
        </section>
    )
}