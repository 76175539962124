import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/operators";

export default function Operators() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Operators in Javascript | Aspirant's Home");
        const urls = {
            'previous': '/javascript/variables-and-data-types',
            'next': '/javascript/if-else'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Operators</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Operators are symbols or keywords in JavaScript that perform specific operations on values or variables. These operations can include mathematical calculations, comparisons, or assigning values.
                </p>

                <h5 className='mt-5 mb-3'>Types of Operators</h5>
                <p>JavaScript has several categories of operators:</p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>Arithmetic Operators</li>
                    <li>Assignment Operators</li>
                    <li>Comparison Operators</li>
                    <li>Logical Operators</li>
                    <li>Bitwise Operators</li>
                    <li>String Operators</li>
                    <li>Conditional (Ternary) Operator</li>
                    <li>Type Operators</li>
                </ul>

                <h5 className='mt-5 mb-3'>Arithmetic Operators</h5>
                <p>Arithmetic operators are used for mathematical calculations.</p>
                <table className='myBorderClass table table-bordered'>
                    <thead>
                        <tr>
                            <th style={{backgroundColor: '#ddd'}}>Operator</th>
                            <th style={{backgroundColor: '#ddd'}}>Description</th>
                            <th style={{backgroundColor: '#ddd'}}>Example</th>
                            <th style={{backgroundColor: '#ddd'}}>Result</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span className='background-grey'>+</span></td>
                            <td>Addition</td>
                            <td><span className='background-grey'>5 + 3</span></td>
                            <td><span className='background-grey'>8</span></td>
                        </tr>
                        <tr>
                            <td><span className='background-grey'>-</span></td>
                            <td>Subtraction</td>
                            <td><span className='background-grey'>10 - 6</span></td>
                            <td><span className='background-grey'>4</span></td>
                        </tr>
                        <tr>
                            <td><span className='background-grey'>*</span></td>
                            <td>Multiplication</td>
                            <td><span className='background-grey'>4 * 2</span></td>
                            <td><span className='background-grey'>8</span></td>
                        </tr>
                        <tr>
                            <td><span className='background-grey'>/</span></td>
                            <td>Division</td>
                            <td><span className='background-grey'>8 / 2</span></td>
                            <td><span className='background-grey'>4</span></td>
                        </tr>
                        <tr>
                            <td><span className='background-grey'>%</span></td>
                            <td>Modulus (Remainder)</td>
                            <td><span className='background-grey'>10 % 3</span></td>
                            <td><span className='background-grey'>1</span></td>
                        </tr>
                        <tr>
                            <td><span className='background-grey'>++</span></td>
                            <td>Increment (Add 1)</td>
                            <td><span className='background-grey'>let x = 5; x++</span></td>
                            <td><span className='background-grey'>6</span></td>
                        </tr>
                        <tr>
                            <td><span className='background-grey'>--</span></td>
                            <td>Decrement (Subtract 1)</td>
                            <td><span className='background-grey'>let y = 5; y--</span></td>
                            <td><span className='background-grey'>4</span></td>
                        </tr>

                    </tbody>
                </table>


                <h5 className='mt-5 mb-3'>Assignment Operators</h5>
                <p>Assignment operators assign values to variables.</p>
                <table className='myBorderClass table table-bordered'>
                    <thead>
                        <tr>
                            <th style={{backgroundColor: '#ddd'}}>Operator</th>
                            <th style={{backgroundColor: '#ddd'}}>Description</th>
                            <th style={{backgroundColor: '#ddd'}}>Example</th>
                            <th style={{backgroundColor: '#ddd'}}>Result</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span className='background-grey'>=</span></td>
                            <td>Assign</td>
                            <td><span className='background-grey'>x = 10</span></td>
                            <td><span className='background-grey'>x = 10</span></td>
                        </tr>
                        <tr>
                            <td><span className='background-grey'>+=</span></td>
                            <td>Add and Assign</td>
                            <td><span className='background-grey'>x += 5</span></td>
                            <td><span className='background-grey'>x = x + 5</span></td>
                        </tr>
                        <tr>
                            <td><span className='background-grey'>-=</span></td>
                            <td>Subtract and Assign</td>
                            <td><span className='background-grey'>x -= 2</span></td>
                            <td><span className='background-grey'>x = x - 2</span></td>
                        </tr>
                        <tr>
                            <td><span className='background-grey'>*=</span></td>
                            <td>Multiply and Assign</td>
                            <td><span className='background-grey'>x *= 3</span></td>
                            <td><span className='background-grey'>x = x * 3</span></td>
                        </tr>
                        <tr>
                            <td><span className='background-grey'>/=</span></td>
                            <td>Divide and Assign</td>
                            <td><span className='background-grey'>x /= 2</span></td>
                            <td><span className='background-grey'>x = x / 2</span></td>
                        </tr>
                        <tr>
                            <td><span className='background-grey'>%=</span></td>
                            <td>Modulus and Assign</td>
                            <td><span className='background-grey'>x %= 2</span></td>
                            <td><span className='background-grey'>x = x % 2</span></td>
                        </tr>
                    </tbody>
                </table>


                <h5 className='mt-5 mb-3'>Comparison Operators</h5>
                <p>Comparison operators compare two values and return a Boolean (<span className='background-grey'>true</span> or <span className='background-grey'>false</span>).</p>
                <table className='myBorderClass table table-bordered'>
                    <thead>
                        <tr>
                            <th style={{backgroundColor: '#ddd'}}>Operator</th>
                            <th style={{backgroundColor: '#ddd'}}>Description</th>
                            <th style={{backgroundColor: '#ddd'}}>Example</th>
                            <th style={{backgroundColor: '#ddd'}}>Result</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span className='background-grey'>==</span></td>
                            <td>Equal to</td>
                            <td><span className='background-grey'>5 == "5"</span></td>
                            <td><span className='background-grey'>true</span></td>
                        </tr>
                        <tr>
                            <td><span className='background-grey'>===</span></td>
                            <td>Strictly Equal (Type + Value)</td>
                            <td><span className='background-grey'>5 === "5"</span></td>
                            <td><span className='background-grey'>false</span></td>
                        </tr>
                        <tr>
                            <td><span className='background-grey'>!=</span></td>
                            <td>Not Equal</td>
                            <td><span className='background-grey'>5 != 3</span></td>
                            <td><span className='background-grey'>true</span></td>
                        </tr>
                        <tr>
                            <td><span className='background-grey'>!==</span></td>
                            <td>Strictly Not Equal</td>
                            <td><span className='background-grey'>5 !== "5"</span></td>
                            <td><span className='background-grey'>true</span></td>
                        </tr>
                        <tr>
                            <td><span className='background-grey'>&gt;</span></td>
                            <td>Greater than</td>
                            <td><span className='background-grey'>10 &gt; 5</span></td>
                            <td><span className='background-grey'>true</span></td>
                        </tr>
                        <tr>
                            <td><span className='background-grey'>&lt;</span></td>
                            <td>Less than</td>
                            <td><span className='background-grey'>10 &lt; 5</span></td>
                            <td><span className='background-grey'>false</span></td>
                        </tr>
                        <tr>
                            <td><span className='background-grey'>&gt;=</span></td>
                            <td>Greater than or Equal to</td>
                            <td><span className='background-grey'>10 &gt;= 10</span></td>
                            <td><span className='background-grey'>true</span></td>
                        </tr>
                        <tr>
                            <td><span className='background-grey'>&lt;=</span></td>
                            <td>Less than or Equal to</td>
                            <td><span className='background-grey'>5 &lt;= 10</span></td>
                            <td><span className='background-grey'>true</span></td>
                        </tr>

                    </tbody>
                </table>


                <h5 className='mt-5 mb-3'>Logical Operators</h5>
                <p>Logical operators are used for combining conditions.</p>
                <table className='myBorderClass table table-bordered'>
                    <thead>
                        <tr>
                            <th style={{backgroundColor: '#ddd'}}>Operator</th>
                            <th style={{backgroundColor: '#ddd'}}>Description</th>
                            <th style={{backgroundColor: '#ddd'}}>Example</th>
                            <th style={{backgroundColor: '#ddd'}}>Result</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span className='background-grey'>&&</span></td>
                            <td>Logical AND</td>
                            <td><span className='background-grey'>true && false</span></td>
                            <td><span className='background-grey'>false</span></td>
                        </tr>
                        <tr>
                            <td>`</td>
                            <td></td>
                            <td>`</td>
                            <td>Logical OR</td>
                        </tr>
                        <tr>
                            <td><span className='background-grey'>!</span></td>
                            <td>Logical NOT</td>
                            <td><span className='background-grey'>!true</span></td>
                            <td><span className='background-grey'>false</span></td>
                        </tr>
                    </tbody>
                </table>


                <h5 className='mt-5 mb-3'>String Operators</h5>
                <p>String operators are used to concatenate (combine) strings.</p>
                <table className='myBorderClass table table-bordered'>
                    <thead>
                        <tr>
                            <th style={{backgroundColor: '#ddd'}}>Operator</th>
                            <th style={{backgroundColor: '#ddd'}}>Description</th>
                            <th style={{backgroundColor: '#ddd'}}>Example</th>
                            <th style={{backgroundColor: '#ddd'}}>Result</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span className='background-grey'>+</span></td>
                            <td>Concatenate strings</td>
                            <td><span className='background-grey'>"Hello" + " World"</span></td>
                            <td><span className='background-grey'>"Hello World"</span></td>
                        </tr>
                        <tr>
                            <td><span className='background-grey'>+=</span></td>
                            <td>Add and Assign String</td>
                            <td><span className='background-grey'>let x = "Hi"; x += " there!";</span></td>
                            <td><span className='background-grey'>Hi there!</span></td>
                        </tr>
                    </tbody>
                </table>

                <h5 className='mt-5 mb-3'>Conditional (Ternary) Operator</h5>
                <p>The ternary operator is a shorthand for <span className='background-grey'>if-else</span> statements.</p>
                <table className='myBorderClass table table-bordered'>
                    <thead>
                        <tr>
                            <th style={{backgroundColor: '#ddd'}}>Operator</th>
                            <th style={{backgroundColor: '#ddd'}}>Example</th>
                            <th style={{backgroundColor: '#ddd'}}>Result</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span className='background-grey'>condition ? value1 : value2</span></td>
                            <td><span className='background-grey'>5 &gt; 3 ? "Yes" : "No"</span></td>
                            <td><span className='background-grey'>"Yes"</span></td>
                        </tr>
                    </tbody>
                </table>


                <h5 className='mt-5 mb-3'>Type Operators</h5>
                <p>Type operators check or change the data type.</p>
                <table className='myBorderClass table table-bordered'>
                    <thead>
                        <tr>
                            <th style={{backgroundColor: '#ddd'}}>Operator</th>
                            <th style={{backgroundColor: '#ddd'}}>Description</th>
                            <th style={{backgroundColor: '#ddd'}}>Example</th>
                            <th style={{backgroundColor: '#ddd'}}>Result</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span className='background-grey'>typeof</span></td>
                            <td>Returns the type of a variable</td>
                            <td><span className='background-grey'>typeof 42</span></td>
                            <td><span className='background-grey'>"number"</span></td>
                        </tr>
                        <tr>
                            <td><span className='background-grey'>instanceof</span></td>
                            <td>Checks if an object is an instance of a class</td>
                            <td><span className='background-grey'>arr instanceof Array</span></td>
                            <td><span className='background-grey'>true</span></td>
                        </tr>
                    </tbody>
                </table>


                <h5 className='mt-5 mb-3'>Some Example</h5>

                <h6 className='mt-3'>Example 1: Arithmetic and Assignment</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> x = <span class="color-pink">10</span>;</p>
                        <p>x += <span class="color-pink">5</span>;  <span class="color-grey">// Same as x = x + 5</span></p>
                        <p><span class="color-pink">console</span>.<span class="color-red">log</span>(x);  <span class="color-grey">// Output: 15</span></p>
                        `
                    }}></div>
                </div>

                <h6 className='mt-3'>Example 2: Comparison</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> a = <span class="color-pink">5</span>, b = <span class="color-green">"5"</span>;</p>
                        <p><span class="color-pink">console</span>.<span class="color-red">log</span>(a == b);   <span class="color-grey">// Output: true (checks only value)</span></p>
                        <p><span class="color-pink">console</span>.<span class="color-red">log</span>(a === b);  <span class="color-grey">// Output: false (checks value and type)</span></p>
                        `
                    }}></div>
                </div>

                <h6 className='mt-3'>Example 3: Logical</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> isSunny = <span class="color-blue">true</span>;</p>
                        <p><span class="color-blue">let</span> isWeekend = <span class="color-blue">false</span>;</p>
                        <br />
                        <p><span class="color-pink">console</span>.<span class="color-red">log</span>(isSunny <span class="color-pink">&&</span> isWeekend);  <span class="color-grey">// Output: false</span></p>
                        <p><span class="color-pink">console</span>.<span class="color-red">log</span>(isSunny <span class="color-pink">||</span> isWeekend);  <span class="color-grey">// Output: true</span></p>
                        <p><span class="color-pink">console</span>.<span class="color-red">log</span>(!isSunny);              <span class="color-grey">// Output: false</span></p>
                        `
                    }}></div>
                </div>

                <h6 className='mt-3'>Example 4: String Concatenation</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> greeting = <span class="color-green">"Hello"</span>;</p>
                        <p><span class="color-blue">let</span> name = <span class="color-green">"John"</span>;</p>
                        <p><span class="color-pink">console</span>.<span class="color-red">log</span>(greeting + <span class="color-green">", "</span> + name + <span class="color-green">"!"</span>);  <span class="color-grey">// Output: Hello, John!</span></p>
                        `
                    }}></div>
                </div>


            </div>
        </section>
    )
}