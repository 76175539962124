import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/jquery/dimensions";

export default function Dimension() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("jQuery - Dimensions | Aspirant's Home");
        const urls = {
            'previous': '/jquery/css-function',
            'next': '/jquery/traversing'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>jQuery - Dimensions</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In jQuery, you can easily <strong>get and set dimensions</strong> (width and height) of elements using built-in methods. These methods allow you to retrieve or modify dimensions, including padding, borders, and margins, giving precise control over an element's layout.
                </p>

                <h5 className='mt-5 mb-3'>Getting Dimensions</h5>
                <p>jQuery provides methods to get the width and height of elements, with or without padding, borders, and margins.</p>


                <h4 className='mt-5'>.width() and .height()</h4>
                <p>The <span class="background-grey">.width()</span> and <span class="background-grey">.height()</span> methods retrieve the width and height of an element, excluding padding, borders, and margins. Look at the below syntax -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(selector).<span class="color-red">width</span>(); <span class="color-grey">// Gets the width</span></p>
                            <p>$(selector).<span class="color-red">height</span>(); <span class="color-grey">// Gets the height</span></p>
                        `
                    }}></div>
                </div>
                <p>Take a look below for an simple example -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> width = $(<span class="color-green">"#myElement"</span>).<span class="color-red">width</span>();</p>
                        <p><span class="color-blue">let</span> height = $(<span class="color-green">"#myElement"</span>).<span class="color-red">height</span>();</p>
                        <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Width:"</span>, width, <span class="color-green">"Height:"</span>, height);</p>
                        `
                    }}></div>
                </div>
                <p>It retrieves the width and height of <span class="background-grey">#myElement</span>. It logs the width and height in pixels, without padding, borders, or margins.</p>


                <h4 className='mt-5'>.innerWidth() and .innerHeight()</h4>
                <p>The <span class="background-grey">.innerWidth()</span> and <span class="background-grey">.innerHeight()</span> methods include padding but exclude borders and margins. Look at the below syntax -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(selector).<span class="color-red">innerWidth</span>(); <span class="color-grey">// Gets width including padding</span></p>
                            <p>$(selector).<span class="color-red">innerHeight</span>(); <span class="color-grey">// Gets height including padding</span></p>
                        `
                    }}></div>
                </div>
                <p>Take a look below for an simple example -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> innerWidth = $(<span class="color-green">"#myElement"</span>).<span class="color-red">innerWidth</span>();</p>
                        <p><span class="color-blue">let</span> innerHeight = $(<span class="color-green">"#myElement"</span>).<span class="color-red">innerHeight</span>();</p>
                        <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Inner Width:"</span>, innerWidth, <span class="color-green">"Inner Height:"</span>, innerHeight);</p>
                        `
                    }}></div>
                </div>
                <p>It retrieves the width and height of <span class="background-grey">#myElement</span>, including padding. It logs the width and height in pixels, including padding but not borders or margins.</p>


                <h4 className='mt-5'>.outerWidth() and .outerHeight()</h4>
                <p>The <span class="background-grey">.outerWidth()</span> and <span class="background-grey">.outerHeight()</span> methods include padding and borders, but exclude margins by default. Look at the below syntax -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(selector).<span class="color-red">outerWidth</span>(); <span class="color-grey">// Gets width including padding and border</span></p>
                            <p>$(selector).<span class="color-red">outerHeight</span>(); <span class="color-grey">// Gets height including padding and border</span></p>
                        `
                    }}></div>
                </div>
                <p>Take a look below for an simple example -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> outerWidth = $(<span class="color-green">"#myElement"</span>).<span class="color-red">outerWidth</span>();</p>
                        <p><span class="color-blue">let</span> outerHeight = $(<span class="color-green">"#myElement"</span>).<span class="color-red">outerHeight</span>();</p>
                        <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Outer Width:"</span>, outerWidth, <span class="color-green">"Outer Height:"</span>, outerHeight);</p>
                        `
                    }}></div>
                </div>

                <p>It retrieves the width and height of <span class="background-grey">#myElement</span>, including padding and borders. It logs the width and height in pixels, including padding and borders, but not margins.</p>

                <p>To include margins, pass true as an argument to <span class="background-grey">.outerWidth()</span> or <span class="background-grey">.outerHeight()</span>.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">let</span> outerWidthWithMargin = $(<span class="color-green">"#myElement"</span>).<span class="color-red">outerWidth</span>(<span class="color-blue">true</span>);</p>
                            <p><span class="color-blue">let</span> outerHeightWithMargin = $(<span class="color-green">"#myElement"</span>).<span class="color-red">outerHeight</span>(<span class="color-blue">true</span>);</p>
                        `
                    }}></div>
                </div>
                <p>It retrieves the width and height of <span class="background-grey">#myElement</span>, including padding, borders, and margins.</p>


                <h5 className='mt-5 mb-3'>Setting Dimensions</h5>
                <p>jQuery also allows you to set dimensions dynamically using <span class="background-grey">.width()</span> and <span class="background-grey">.height()</span> methods.</p>
                <p>To set the width and height of an element, pass a value in pixels or as a percentage. Here is the syntax -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(selector).<span class="color-red">width</span>(value); <span class="color-grey">// Sets width</span></p>
                        <p>$(selector).<span class="color-red">height</span>(value); <span class="color-grey">// Sets height</span></p>
                        `
                    }}></div>
                </div>
                <p>Let's look at the below example -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(<span class="color-green">"#myElement"</span>).<span class="color-red">width</span>(<span class="color-pink">300</span>).<span class="color-red">height</span>(<span class="color-pink">200</span>);</p>
                        `
                    }}></div>
                </div>
                <p>It sets the width of <span class="background-grey">#myElement</span> to 300px and height to 200px. <span class="background-grey">#myElement</span> now has the specified dimensions.</p>

                <p>You can also set dimensions as a percentage of its parent element’s dimensions.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(<span class="color-green">"#myElement"</span>).<span class="color-red">width</span>(<span class="color-green">"50%"</span>).<span class="color-red">height</span>(<span class="color-green">"75%"</span>);</p>
                        `
                    }}></div>
                </div>
                <p>It sets the width to 50% and height to 75% of <span class="background-grey">#myElement</span>'s parent element’s dimensions.</p>



            </div>
        </section>
    )
}