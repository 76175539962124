import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/array-methods";

export default function ArrayMethods() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Array Methods in Javascript | Aspirant's Home");
        const urls = {
            'previous': '/javascript/arrays',
            'next': '/javascript/array-search'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Array Methods</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Array methods in JavaScript are built-in functions that allow us to perform various operations on arrays, such as adding, removing, or modifying elements, and performing calculations. These methods make working with arrays easier and more powerful.
                </p>

                <h5 className='mt-5 mb-3'>Commonly Used Array Methods</h5>
                <p>Here are some of the most important array methods, explained step by step:</p>

                <ul style={{ listStyle: 'decimal' }}>
                    <li className='operator mt-5'>
                        <h5><span className='background-grey'>push()</span> – <strong>Add an Element to the End</strong></h5>
                        <p>The <span className='background-grey'>push()</span> method adds one or more elements to the <strong>end</strong> of an array.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> fruits = [<span class="color-green">"Apple"</span>, <span class="color-green">"Banana"</span>];</p>
                                <p>fruits.<span class="color-red">push</span>(<span class="color-green">"Cherry"</span>);</p>
                                
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(fruits); <span class="color-grey">// Output: ["Apple", "Banana", "Cherry"]</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator mt-5'>
                        <h5><span className='background-grey'>pop()</span> – <strong>Remove the Last Element</strong></h5>
                        <p>The <span className='background-grey'>pop()</span> method removes the <strong>last</strong> element from an array and returns it.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> fruits = [<span class="color-green">"Apple"</span>, <span class="color-green">"Banana"</span>, <span class="color-green">"Cherry"</span>];</p>
                                <p><span class="color-blue">let</span> lastFruit = fruits.<span class="color-red">pop</span>();</p>
                                <br />
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(fruits);    <span class="color-grey">// Output: ["Apple", "Banana"]</span></p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(lastFruit); <span class="color-grey">// Output: "Cherry"</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator mt-5'>
                        <h5><span className='background-grey'>unshift()</span> – <strong>Add an Element to the Beginning</strong></h5>
                        <p>The <span className='background-grey'>unshift()</span> method adds one or more elements to the <strong>beginning</strong> of an array.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> fruits = [<span class="color-green">"Banana"</span>, <span class="color-green">"Cherry"</span>];</p>
                                <p>fruits.<span class="color-red">unshift</span>(<span class="color-green">"Apple"</span>);</p>
                                <br />
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(fruits); <span class="color-grey">// Output: ["Apple", "Banana", "Cherry"]</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator mt-5'>
                        <h5><span className='background-grey'>shift()</span> – <strong>Remove the First Element</strong></h5>
                        <p>The <span className='background-grey'>shift()</span> method removes the <strong>first</strong> element from an array and returns it.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> fruits = [<span class="color-green">"Apple"</span>, <span class="color-green">"Banana"</span>, <span class="color-green">"Cherry"</span>];</p>
                                <p><span class="color-blue">let</span> firstFruit = fruits.<span class="color-red">shift</span>();</p>
                                <br />
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(fruits);     <span class="color-grey">// Output: ["Banana", "Cherry"]</span></p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(firstFruit); <span class="color-grey">// Output: "Apple"</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator mt-5'>
                        <h5><span className='background-grey'>concat()</span> – <strong>Merge Arrays</strong></h5>
                        <p>The <span className='background-grey'>concat()</span> method combines two or more arrays into a single array.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> fruits = [<span class="color-green">"Apple"</span>, <span class="color-green">"Banana"</span>];</p>
                                <p><span class="color-blue">let</span> veggies = [<span class="color-green">"Carrot"</span>, <span class="color-green">"Potato"</span>];</p>
                                <p><span class="color-blue">let</span> combined = fruits.<span class="color-red">concat</span>(veggies);</p>
                                <br />
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(combined); <span class="color-grey">// Output: ["Apple", "Banana", "Carrot", "Potato"]</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator mt-5'>
                        <h5><span className='background-grey'>indexOf()</span> – <strong>Find the Index of an Element</strong></h5>
                        <p>The <span className='background-grey'>indexOf()</span> method returns the <strong>index</strong> of the first occurrence of an element in the array. If the element is not found, it returns <span className='background-grey'>-1</span>.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> fruits = [<span class="color-green">"Apple"</span>, <span class="color-green">"Banana"</span>, <span class="color-green">"Cherry"</span>];</p>
                                <p><span class="color-blue">let</span> index = fruits.<span class="color-red">indexOf</span>(<span class="color-green">"Banana"</span>);</p>
                                <br />
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(index); <span class="color-grey">// Output: 1</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator mt-5'>
                        <h5><span className='background-grey'>includes()</span> – <strong>Check if an Element Exists</strong></h5>
                        <p>The <span className='background-grey'>includes()</span> method checks if an array contains a specific element. It returns <span className='background-grey'>true</span> if the element is found, and <span className='background-grey'>false</span> otherwise.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> fruits = [<span class="color-green">"Apple"</span>, <span class="color-green">"Banana"</span>, <span class="color-green">"Cherry"</span>];</p>
                                <p><span class="color-blue">let</span> hasBanana = fruits.<span class="color-red">includes</span>(<span class="color-green">"Banana"</span>);</p>
                                <br />
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(hasBanana); <span class="color-grey">// Output: true</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator mt-5'>
                        <h5><span className='background-grey'>slice()</span> – <strong>Extract a Portion of an Array</strong></h5>
                        <p>The <span className='background-grey'>slice()</span> method returns a <strong>new array</strong> containing a portion of the original array, without modifying the original array.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> fruits = [<span class="color-green">"Apple"</span>, <span class="color-green">"Banana"</span>, <span class="color-green">"Cherry"</span>, <span class="color-green">"Date"</span>];</p>
                                <p><span class="color-blue">let</span> sliced = fruits.<span class="color-red">slice</span>(<span class="color-pink">1</span>, <span class="color-pink">3</span>);</p>
                                <br />
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(sliced); <span class="color-grey">// Output: ["Banana", "Cherry"]</span></p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(fruits); <span class="color-grey">// Output: ["Apple", "Banana", "Cherry", "Date"]</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator mt-5'>
                        <h5><span className='background-grey'>splice()</span> – <strong>Add/Remove Elements at a Specific Position</strong></h5>
                        <p>The <span className='background-grey'>splice()</span> method can add or remove elements from an array at a specific index.</p>
                        <h6>Example 1: Remove Elements</h6>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> fruits = [<span class="color-green">"Apple"</span>, <span class="color-green">"Banana"</span>, <span class="color-green">"Cherry"</span>];</p>
                                <p>fruits.<span class="color-red">splice</span>(<span class="color-pink">1</span>, <span class="color-pink">1</span>); <span class="color-grey">// Remove 1 element at index 1</span></p>
                                <br />
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(fruits); <span class="color-grey">// Output: ["Apple", "Cherry"]</span></p>
                                `
                            }}></div>
                        </div>
                        <h6>Example 2: Add Elements</h6>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> fruits = [<span class="color-green">"Apple"</span>, <span class="color-green">"Cherry"</span>];</p>
                                <p>fruits.<span class="color-red">splice</span>(<span class="color-pink">1</span>, <span class="color-pink">0</span>, <span class="color-green">"Banana"</span>); <span class="color-grey">// Add "Banana" at index 1</span></p>
                                <br />
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(fruits); <span class="color-grey">// Output: ["Apple", "Banana", "Cherry"]</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator mt-5'>
                        <h5><span className='background-grey'>join()</span> – <strong>Combine Array Elements into a String</strong></h5>
                        <p>The <span className='background-grey'>join()</span> method joins all elements of an array into a single string, separated by a specified delimiter (default is a comma).</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> fruits = [<span class="color-green">"Apple"</span>, <span class="color-green">"Banana"</span>, <span class="color-green">"Cherry"</span>];</p>
                                <p><span class="color-blue">let</span> fruitString = fruits.<span class="color-red">join</span>(<span class="color-green">", "</span>);</p>
                                <br />
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(fruitString); <span class="color-grey">// Output: "Apple, Banana, Cherry"</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator mt-5'>
                        <h5><span className='background-grey'>reverse()</span> – <strong>Reverse the Order of an Array</strong></h5>
                        <p>The <span className='background-grey'>reverse()</span> method reverses the order of elements in an array.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> fruits = [<span class="color-green">"Apple"</span>, <span class="color-green">"Banana"</span>, <span class="color-green">"Cherry"</span>];</p>
                                <p>fruits.<span class="color-red">reverse</span>();</p>
                                <br />
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(fruits); <span class="color-grey">// Output: ["Cherry", "Banana", "Apple"]</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator mt-5'>
                        <h5><span className='background-grey'>sort()</span> – <strong>Sort the Elements</strong></h5>
                        <p>The <span className='background-grey'>sort()</span> method sorts the elements of an array in <strong>alphabetical order</strong> by default. For numbers, you may need a comparison function.</p>
                        <h6>Example</h6>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> fruits = [<span class="color-green">"Banana"</span>, <span class="color-green">"Apple"</span>, <span class="color-green">"Cherry"</span>];</p>
                                <p>fruits.<span class="color-red">sort</span>();</p>
                                <br />
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(fruits); <span class="color-grey">// Output: ["Apple", "Banana", "Cherry"]</span></p>
                                `
                            }}></div>
                        </div>
                        <h6>Example with Numbers:</h6>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> numbers = [<span class="color-pink">5</span>, <span class="color-pink">3</span>, <span class="color-pink">8</span>, <span class="color-pink">1</span>];</p>
                                <p>numbers.<span class="color-red">sort</span>((a, b) => a - b); <span class="color-grey">// Sort in ascending order</span></p>
                                <br />
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(numbers); <span class="color-grey">// Output: [1, 3, 5, 8]</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator mt-5'>
                        <h5><span className='background-grey'>map()</span> – <strong>Transform Each Element</strong></h5>
                        <p>The <span className='background-grey'>map()</span> method creates a new array by applying a function to each element of the original array.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> numbers = [<span class="color-pink">1</span>, <span class="color-pink">2</span>, <span class="color-pink">3</span>];</p>
                                <p><span class="color-blue">let</span> squared = numbers.<span class="color-red">map</span>(num => num * num);</p>
                                <br />
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(squared); <span class="color-grey">// Output: [1, 4, 9]</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator mt-5'>
                        <h5><span className='background-grey'>filter()</span> – <strong>Get Specific Elements</strong></h5>
                        <p>The <span className='background-grey'>filter()</span> method creates a new array with elements that satisfy a condition.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let/span> numbers = [<span class="color-pink">1</span>, <span class="color-pink">2</span>, <span class="color-pink">3</span>, <span class="color-pink">4</span>, <span class="color-pink">5</span>];</p>
                                <p><span class="color-blue">let</span> evenNumbers = numbers.<span class="color-red">filter</span>(num => num % <span class="color-pink">2</span> === <span class="color-pink">0</span>);</p>
                                <br />
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(evenNumbers); <span class="color-grey">// Output: [2, 4]</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator mt-5'>
                        <h5><span className='background-grey'>reduce()</span> – <strong>Calculate a Single Value</strong></h5>
                        <p>The <span className='background-grey'>reduce()</span> method applies a function to accumulate all elements into a single value.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> numbers = [<span class="color-pink">1</span>, <span class="color-pink">2</span>, <span class="color-pink">3</span>, <span class="color-pink">4</span>];</p>
                                <p><span class="color-blue">let</span> sum = numbers.<span class="color-red">reduce</span>((total, num) => total + num, <span class="color-pink">0</span>);</p>
                                <br />
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(sum); <span class="color-grey">// Output: 10</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>


            </div>
        </section>
    )
}