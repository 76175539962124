import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/jquery/events/event-stoppropagation";

export default function StopPropagation() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("jQuery event.stopPropagation() Object | Aspirant's Home");
        const urls = {
            'previous': '/jquery/events'
        }
        path.setPreviousNext(urls);
        path.setPreviousName('Back to Events');
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>jQuery event.stopPropagation() Object</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">event.stopPropagation()</span> method in jQuery is used to <strong>prevent the event from bubbling up</strong> the DOM hierarchy. This means the event will not propagate to parent elements, but <strong>other event handlers attached to the same element will still execute</strong>.
                </p>


                <p>It has a basic syntax -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>event.<span class="color-red">stopPropagation</span>();</p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-3'>Example : Stopping Event Bubbling</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;div <span class="color-pink">id</span>=<span class="color-green">"parentDiv"</span> <span class="color-pink">style</span>=<span class="color-green">"padding: 20px; background: lightgray;"</span>&gt;</p>
                        <p class="ml-30">    Parent Div</p>
                        <p class="ml-30">    &lt;button <span class="color-pink">id</span>=<span class="color-green">"childButton"</span>&gt;Click Me&lt;/button&gt;</p>
                        <p>&lt;/div&gt;</p>
                        `
                    }}></div>
                </div>
                <h6>jQuery Code</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"#childButton"</span>).<span class="color-red">on</span>(<span class="color-green">"click"</span>, <span class="color-blue">function</span>(event) {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Child button clicked."</span>);</p>
                        <p class="ml-30">    event.<span class="color-red">stopPropagation</span>();</p>
                        <p>});</p>
                        <br />
                        <p>$(<span class="color-green">"#parentDiv"</span>).<span class="color-red">on</span>(<span class="color-green">"click"</span>, <span class="color-blue">function</span>() {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Parent div clicked."</span>);</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>When the button is clicked:
                        <ul style={{listStyle:'disc'}}>
                            <li>The <span class="background-grey">#childButton</span> handler logs: <span class="background-grey">"Child button clicked."</span></li>
                            <li><span class="background-grey">event.stopPropagation()</span> stops the event from bubbling to <span class="background-grey">#parentDiv</span>.</li>
                        </ul>
                    </li>
                    <li>The <span class="background-grey">#parentDiv</span> handler is not executed.</li>
                    <li>Output:
                        Only <span class="background-grey">"Child button clicked."</span> is logged.
                    </li>
                </ul>

                <h5 className='mt-5 mb-3'>Example : Allowing Handlers on the Same Element</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;div <span class="color-pink">id</span>=<span class="color-green">"parentDiv"</span> <span class="color-pink">style</span>=<span class="color-green">"padding: 20px; background: lightgray;"</span>&gt;</p>
                        <p class="ml-30">    Parent Div</p>
                        <p class="ml-30">    &lt;button <span class="color-pink">id</span>=<span class="color-green">"myButton"</span>&gt;Click Me&lt;/button&gt;</p>
                        <p>&lt;/div&gt;</p>
                        `
                    }}></div>
                </div>
                <h6>jQuery Code</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p class="color-grey">// First handler on the button</p>
                        <p>$(<span class="color-green">"#myButton"</span>).<span class="color-red">on</span>(<span class="color-green">"click"</span>, <span class="color-blue">function</span>(event) {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"First handler executed."</span>);</p>
                        <p class="ml-30 color-grey">    // Stop event propagation to parent elements</p>
                        <p class="ml-30">    event.<span class="color-red">stopPropagation</span>();</p>
                        <p>});</p>
                        <br />
                        <p class="color-grey">// Second handler on the same button</p>
                        <p>$(<span class="color-green">"#myButton"</span>).<span class="color-red">on</span>(<span class="color-green">"click"</span>, <span class="color-blue">function</span>() {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Second handler executed."</span>);</p>
                        <p>});</p>
                        <br />
                        <p class="color-grey">// Handler on the parent div</p>
                        <p>$(<span class="color-green">"#parentDiv"</span>).<span class="color-red">on</span>(<span class="color-green">"click"</span>, <span class="color-blue">function</span>() {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Parent div handler executed."</span>);</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>
                        When you click the button:
                        <ul style={{listStyle:'disc'}}>
                            <li>The first handler logs: <span class="background-grey">"First handler executed."</span></li>
                            <li>The second handler logs: <span class="background-grey">"Second handler executed."</span></li>
                            <li>The <span class="background-grey">event.stopPropagation()</span> in the first handler prevents the event from bubbling to the #parentDiv, so its handler is <strong>not executed</strong>.</li>
                        </ul>
                    </li>
                </ul>


                <h5 className='mt-5 mb-3'>Comparison Between <span class="background-grey">stopPropagation()</span> and <span class="background-grey">stopImmediatePropagation()</span></h5>
                <table className='table myBorderClass'>
                    <thead>
                        <tr>
                            <th style={{background:'#ccc'}}>Feature</th>
                            <th style={{background:'#ccc'}}>stopPropagation()</th>
                            <th style={{background:'#ccc'}}>stopImmediatePropagation()</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Stops Bubbling</td>
                            <td>Yes</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>Prevents Other Handlers</td>
                            <td>No (other handlers on the same element execute)</td>
                            <td>Yes (stops all other handlers on the same element)</td>
                        </tr>
                        <tr>
                            <td>Scope</td>
                            <td>Affects parent elements</td>
                            <td>Affects parent elements and other handlers on the same element</td>
                        </tr>
                    </tbody>
                </table>


            </div>
        </section>
    )
}