import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/tag/tr";

export default function TrTag() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - <tr> Tag | Aspirant's Home");
        const urls = {
            'previous': '/html/tag-list'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - &lt;tr&gt; Tag</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">&lt;tr&gt;</span> tag in HTML is used to define a row in a table. Within a <span class="background-grey">&lt;table&gt;</span>, each <span class="background-grey">&lt;tr&gt;</span> tag represents a single row and contains table cells, defined by either <span class="background-grey">&lt;td&gt;</span> for regular data cells or <span class="background-grey">&lt;th&gt;</span> for header cells. Using <span class="background-grey">&lt;tr&gt;</span> allows for the organization and display of data in a tabular format, with each row representing a distinct set of data entries.
                </p>

                <h5 className='mt-5 mb-3'>Structure and Purpose of <span class="background-grey">&lt;tr&gt;</span> Tag</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span class="background-grey">&lt;tr&gt;</span> tag creates a row within a table, serving as a container for table cells.</li>
                    <li>Rows defined by <span class="background-grey">&lt;tr&gt;</span> go inside <span class="background-grey">&lt;table&gt;</span>, and usually within <span class="background-grey">&lt;thead&gt;</span>, <span class="background-grey">&lt;tbody&gt;</span>, or <span class="background-grey">&lt;tfoot&gt;</span> sections, depending on the row's purpose.</li>
                    <li>Each <span class="background-grey">&lt;tr&gt;</span> can include any combination of header (<span class="background-grey">&lt;th&gt;</span>) and data (<span class="background-grey">&lt;td&gt;</span>) cells for versatile table layouts.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <p>The <span class="background-grey">&lt;tr&gt;</span> tag is used within a <span class="background-grey">&lt;table&gt;</span> to create rows, with each cell inside a <span class="background-grey">&lt;tr&gt;</span> defined by either <span class="background-grey">&lt;td&gt;</span> or <span class="background-grey">&lt;th&gt;</span>:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;table&gt;</p>
                        <p class="ml-30">    &lt;tr&gt;</p>
                        <p class="ml-60">        &lt;th&gt;Header 1&lt;/th&gt;</p>
                        <p class="ml-60">        &lt;th&gt;Header 2&lt;/th&gt;</p>
                        <p class="ml-30">    &lt;/tr&gt;</p>
                        <p class="ml-30">    &lt;tr&gt;</p>
                        <p class="ml-60">        &lt;td&gt;Data 1&lt;/td&gt;</p>
                        <p class="ml-60">        &lt;td&gt;Data 2&lt;/td&gt;</p>
                        <p class="ml-30">    &lt;/tr&gt;</p>
                        <p>&lt;/table&gt;</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Styling <span class="background-grey">&lt;tr&gt;</span> with CSS</h5>
                <p>You can use CSS to style table rows directly, for example, to add background colors or highlight specific rows.</p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>Alternate Row Colors:</strong>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>tr:<span class="color-pink">nth-child</span>(even) {</p>
                                <p class="ml-30">    <span class="color-green">background-color</span>: <span class="color-pink">#f2f2f2</span>; <span class="color-grey">/* Light gray for even rows */</span></p>
                                <p>}</p>
                                <p>tr:<span class="color-pink">nth-child</span>(odd) {</p>
                                <p class="ml-30">    <span class="color-green">background-color</span>: <span class="color-pink">#ffffff</span>; <span class="color-grey">/* White for odd rows */</span></p>
                                <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Hover Effect for Rows:</strong>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>tr:<span class="color-pink">hover</span> {</p>
                                <p class="ml-30">    <span class="color-green">background-color</span>: <span class="color-pink">#d1e7dd</span>; <span class="color-grey">/* Light green when hovered */</span></p>
                                <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>


                <h5 className='mt-5 mb-3'>Advanced Example with Styled <span class="background-grey">&lt;tr&gt;</span> Tag</h5>
                <p>This example adds alternating row colors and a hover effect:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Styled Table Rows&lt;/title&gt;</p>
                        <p class="ml-60">        &lt;style&gt;</p>
                        <p class="ml-90">            table {</p>
                        <p class="ml-120">                <span class="color-green">width</span>: <span class="color-pink">50%</span>;</p>
                        <p class="ml-120">                <span class="color-green">border-collapse</span>: collapse;</p>
                        <p class="ml-90">            }</p>
                        <p class="ml-90">            th, td {</p>
                        <p class="ml-120">                <span class="color-green">padding</span>: <span class="color-pink">10px;</span></p>
                        <p class="ml-120">                <span class="color-green">text-align</span>: left;</p>
                        <p class="ml-90">            }</p>
                        <p class="ml-90">            tr:<span class="color-pink">nth-child</span>(even) {</p>
                        <p class="ml-120">                <span class="color-green">background-color</span>: <span class="color-pink">#f2f2f2</span>;</p>
                        <p class="ml-90">            }</p>
                        <p class="ml-90">            tr:<span class="color-pink">hover</span> {</p>
                        <p class="ml-120">                <span class="color-green">background-color</span>: <span class="color-pink">#cce5ff</span>;</p>
                        <p class="ml-90">            }</p>
                        <p class="ml-60">        &lt;/style&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <br />    
                        <p class="ml-60">        &lt;h2&gt;Employee List&lt;/h2&gt;</p>
                        <p class="ml-60">        &lt;table <span class="color-pink">border</span>=<span class="color-green">"1"</span>&gt;</p>
                        <p class="ml-90">            &lt;tr&gt;</p>
                        <p class="ml-120">                &lt;th&gt;Employee Name&lt;/th&gt;</p>
                        <p class="ml-120">                &lt;th&gt;Position&lt;/th&gt;</p>
                        <p class="ml-90">            &lt;/tr&gt;</p>
                        <p class="ml-90">            &lt;tr&gt;</p>
                        <p class="ml-120">                &lt;td&gt;John Doe&lt;/td&gt;</p>
                        <p class="ml-120">                &lt;td&gt;Manager&lt;/td&gt;</p>
                        <p class="ml-90">            &lt;/tr&gt;</p>
                        <p class="ml-90">            &lt;tr&gt;</p>
                        <p class="ml-120">                &lt;td&gt;Jane Smith&lt;/td&gt;</p>
                        <p class="ml-120">                &lt;td&gt;Developer&lt;/td&gt;</p>
                        <p class="ml-90">            &lt;/tr&gt;</p>
                        <p class="ml-60">        &lt;/table&gt;</p>
                        <br />    
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Output will be the following -</p>
                <div className='outputPalateBox mt-2 mb-4' style={{border:'none'}} >
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <!DOCTYPE html>
                        <html lang="en">
                        <head>
                          <meta charset="UTF-8">
                          <title>Styled Table Rows</title>
                          <style>
                            table {
                              width: 50%;
                              border-collapse: collapse;
                            }
                            th, td {
                              padding: 10px;
                              text-align: left;
                            }
                            tr:nth-child(even) {
                              background-color: #f2f2f2;
                            }
                            tr:hover {
                              background-color: #cce5ff;
                            }
                          </style>
                        </head>
                        <body>
                        
                        <h2>Employee List</h2>
                        <table border="1">
                          <tr>
                            <th>Employee Name</th>
                            <th>Position</th>
                          </tr>
                          <tr>
                            <td>John Doe</td>
                            <td>Manager</td>
                          </tr>
                          <tr>
                            <td>Jane Smith</td>
                            <td>Developer</td>
                          </tr>
                        </table>
                        
                        </body>
                        </html>
                        `
                    }}></div>
                </div>
                        


            </div>
        </section>
    )
}