import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/jquery/events/event-pagex";

export default function PageX() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("jQuery event.pageX Object | Aspirant's Home");
        const urls = {
            'previous': '/jquery/events'
        }
        path.setPreviousNext(urls);
        path.setPreviousName('Back to Events');
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>jQuery event.pageX Object</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">event.pageX</span> property in jQuery retrieves the <strong>horizontal position</strong> of the mouse pointer, relative to the entire document, at the time the event was triggered. It includes any scrolling that has occurred, providing the position as if the document were fully visible.
                </p>

                <p>It has a basic syntax -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">var</span> x = event.pageX;</p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-3'>Example 1: Tracking Mouse Pointer Position</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;div <span class="color-pink">id</span>=<span class="color-green">"trackingArea"</span> <span class="color-pink">style</span>=<span class="color-green">"width: 500px; height: 300px; background-color: lightblue;"</span>&gt;</p>
                        <p class="ml-30">    Move your mouse here</p>
                        <p>&lt;/div&gt;</p>
                        `
                    }}></div>
                </div>
                <h6>jQuery Code</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"#trackingArea"</span>).<span class="color-red">on</span>(<span class="color-green">"mousemove"</span>, <span class="color-blue">function</span>(event) {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Mouse X position:"</span>, event.pageX);</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>When the mouse moves inside the <span class="background-grey">#trackingArea</span>, the current horizontal position of the mouse pointer relative to the document is logged.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Example 2: Display Mouse Position on Screen</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;div <span class="color-pink">id</span>=<span class="color-green">"output"</span>&gt;&lt;/div&gt;</p>
                            <p>&lt;div <span class="color-pink">style</span>=<span class="color-green">"height: 1000px;"</span>&gt;Scroll down and move the mouse&lt;/div&gt;</p>
                        `
                    }}></div>
                </div>
                <h6>jQuery Code</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(document).<span class="color-red">on</span>(<span class="color-green">"mousemove"</span>, <span class="color-blue">function</span>(event) {</p>
                        <p class="ml-30">    $(<span class="color-green">"#output"</span>).<span class="color-red">text</span>(<span class="color-green">"Mouse X: "</span> + event.pageX + <span class="color-green">"px"</span>);</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span class="background-grey">#output</span> element updates in real time with the mouse's X-coordinate as the user moves the mouse around the page, even after scrolling.</li>
                </ul>



            </div>
        </section>
    )
}