import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/jquery/animate";

export default function Animate() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Animate Effects of jQuery | Aspirant's Home");
        const urls = {
            'previous': '/jquery/slide',
            'next': '/jquery/stop-function'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Animate Effects of jQuery</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The jQuery <span class="background-grey">animate()</span> method allows you to create custom animations by changing CSS properties over time. This method gives you fine-grained control over animations, letting you animate nearly any CSS property that has a numeric value, such as <span class="background-grey">width</span>, <span class="background-grey">height</span>, <span class="background-grey">opacity</span>, <span class="background-grey">margin</span>, and <span class="background-grey">padding</span>.
                </p>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <p>Here is an basic syntax -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(selector).<span class="color-red">animate</span>({ properties }, speed, easing, callback);</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">properties</span> is an object containing the CSS properties and their target values to animate.</li>
                    <li><span class="background-grey">speed</span> (optional) is the duration of the animation. Can be <span class="background-grey">"slow"</span>, <span class="background-grey">"fast"</span>, or a specific time in milliseconds (e.g., <span class="background-grey">400</span>).</li>
                    <li><span class="background-grey">easing</span> (optional) defines the animation speed curve. Common options are <span class="background-grey">"swing"</span> (default, starts slow and speeds up) and <span class="background-grey">"linear"</span> (constant speed).</li>
                    <li><span class="background-grey">callback</span> (optional) is a function to execute once the animation completes.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Some Examples of <span class="background-grey">animate()</span> Method</h5>
                <p>Here are some example of <span class="background-grey">animate()</span> method.</p>

                <h6>Example 1: Basic Animation</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"#box"</span>).<span class="color-red">animate</span>({</p>
                        <p class="ml-30">    <span class="color-pink">width</span>: <span class="color-green">"300px"</span>,</p>
                        <p class="ml-30">    <span class="color-pink">height</span>: <span class="color-green">"200px"</span>,</p>
                        <p class="ml-30">    <span class="color-pink">opacity</span>: <span class="color-pink">0.5</span></p>
                        <p>}, <span class="color-green">"slow"</span>);</p>
                        `
                    }}></div>
                </div>
                <p>This example animates the <span class="background-grey">#box</span> element by increasing its width to 300px, height to 200px, and reducing opacity to 0.5 with a slow animation.</p>

                <h6>Example 2: Animation with Multiple Properties and Speed</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"#box"</span>).<span class="color-red">animate</span>({</p>
                        <p class="ml-30">    <span class="color-pink">left</span>: <span class="color-green">"+=100px"</span>,</p>
                        <p class="ml-30">    <span class="color-pink">height</span>: <span class="color-green">"toggle"</span></p>
                        <p>}, <span class="color-pink">500</span>);</p>
                        `
                    }}></div>
                </div>
                <p>This example moves the <span class="background-grey">#box</span> element 100 pixels to the right and toggles its height (showing or hiding it), completing the animation in 500 milliseconds.</p>

                <h6>Example 3: Animation with Easing and Callback</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"#box"</span>).<span class="color-red">animate</span>({</p>
                        <p class="ml-30">    <span class="color-pink">width</span>: <span class="color-green">"400px"</span>,</p>
                        <p class="ml-30">    <span class="color-pink">opacity</span>: <span class="color-pink">0.8</span></p>
                        <p>}, <span class="color-pink">600</span>, <span class="color-green">"linear"</span>, <span class="color-blue">function</span>() {</p>
                        <p class="ml-30">    <span class="color-red">alert</span>(<span class="color-green">"Animation complete!"</span>);</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>Here, the <span class="background-grey">#box</span> element’s width and opacity are animated over 600 milliseconds with a linear easing, and an alert appears when the animation is finished.</p>



            </div>
        </section>
    )
}