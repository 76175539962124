import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Outlet, Link } from "react-router-dom";
export const pageUrl = () => "/angular/deployment";

export default function Deployment() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Deployment in Angular | Aspirant's Home");
        const urls = {
            'previous': '/angular/testing'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Deployment</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Deploying an Angular application means preparing it to run on a web server so users can access it via a browser. Here's how you can deploy your Angular app step by step.
                </p>

                <ul style={{ listStyle: 'decimal' }}>
                    <li className='operator'>
                        <h5 className='mt-5 mb-3'>Preparing Your Angular App</h5>
                        <p>Before deploying, you need to build your app into static files that can be served by a web server.</p>

                        <h6 className='mt-3'>Step 1: Build the App</h6>
                        <p>Run the following command in your terminal:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>ng build --prod</p>
                                `
                            }}></div>
                        </div>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>It creates a production-ready version of your app.</li>
                            <li>The files will be optimized (minified) to load faster.</li>
                            <li>The output files are placed in the <span className='background-grey'>dist/</span> folder.</li>
                        </ul>
                        <p>In the <span className='background-grey'>dist/your-app-name</span> folder, it creates 3 files -</p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li><span className='background-grey'>index.html</span>: The main HTML file.</li>
                            <li><span className='background-grey'>.js files</span>: The JavaScript files for your app logic.</li>
                            <li><span className='background-grey'>.css files</span>: The CSS styles for your app.</li>
                        </ul>
                    </li>
                    <li className='operator'>
                        <h5 className='mt-5 mb-3'>Deploying Your Angular App</h5>
                        <p>You can deploy the generated files to a web server. Here are some common deployment methods:</p>

                        <ul style={{ listStyle: 'decimal' }}>
                            <li>
                                <strong>Option 1: Deploy to a Static File Hosting Service</strong>
                                <h6 className='mt-4'>Example: Using GitHub Pages</h6>
                                <ul style={{ listStyle: 'decimal' }}>
                                    <li>
                                        Install the Angular CLI GitHub Pages package:
                                        <div className='codePalateBox mt-2 mb-4'>
                                            <div className='codePalate' dangerouslySetInnerHTML={{
                                                __html: `
                                                    <p><span class="color-pink">npm</span> install <span class="color-pink">-g</span> <span class="color-blue">angular-cli-ghpages</span></p>
                                                `
                                            }}></div>
                                        </div>
                                    </li>
                                    <li>
                                        Build and deploy your app in one command:
                                        <div className='codePalateBox mt-2 mb-4'>
                                            <div className='codePalate' dangerouslySetInnerHTML={{
                                                __html: `
                                                    <p><span class="color-pink">ng</span> deploy <span class="color-blue">--base-href=/your-repository-name/</span></p>
                                                `
                                            }}></div>
                                        </div>
                                        <p>It replace <span className='background-grey'>/your-repository-name/</span> with your actual GitHub repository name.</p>
                                    </li>
                                    <li>Your app will be live at <span className='background-grey'>https://your-username.github.io/your-repository-name.</span></li>
                                </ul>
                            </li>
                            <li className='mt-4'>
                                <strong>Option 2: Deploy to Firebase Hosting</strong>
                                <ul style={{ listStyle: 'decimal' }}>
                                    <li>
                                        Install Firebase CLI:
                                        <div className='codePalateBox mt-2 mb-4'>
                                            <div className='codePalate' dangerouslySetInnerHTML={{
                                                __html: `
                                                    <p><span class="color-pink">npm</span> install <span class="color-pink">-g</span> <span class="color-blue">firebase-tools</span></p>
                                                `
                                            }}></div>
                                        </div>
                                    </li>
                                    <li>
                                        Log in to Firebase:
                                        <div className='codePalateBox mt-2 mb-4'>
                                            <div className='codePalate' dangerouslySetInnerHTML={{
                                                __html: `
                                                    <p><span class="color-pink">firebase</span> login</p>
                                                `
                                            }}></div>
                                        </div>
                                    </li>
                                    <li>
                                        Initialize Firebase in your project:
                                        <div className='codePalateBox mt-2 mb-4'>
                                            <div className='codePalate' dangerouslySetInnerHTML={{
                                                __html: `
                                                    <p><span class="color-pink">firebase</span> init</p>
                                                `
                                            }}></div>
                                        </div>
                                        <ul style={{ listStyle: 'disc' }}>
                                            <li>It select "Hosting" when prompted.</li>
                                            <li>Choose your Firebase project.</li>
                                            <li>Specify the <span className='background-grey'>dist/your-app-name</span> folder as the public directory.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        Deploy your app:
                                        <div className='codePalateBox mt-2 mb-4'>
                                            <div className='codePalate' dangerouslySetInnerHTML={{
                                                __html: `
                                                    <p><span class="color-pink">firebase</span> deploy</p>
                                                `
                                            }}></div>
                                        </div>
                                    </li>
                                    <li>Your app will be live at a URL like <span className='background-grey'>https://your-project-name.web.app</span>.</li>
                                </ul>
                            </li>
                            <li className='mt-4'>
                                <strong>Option 3: Deploy to a Web Server (e.g., Apache, Nginx)</strong>
                                <ul style={{ listStyle: 'decimal' }}>
                                    <li>
                                        Copy the files from the <span className='background-grey'>dist/your-app-name</span> folder to the web server's public directory.
                                        <ul style={{ listStyle: 'disc' }}>
                                            <li>For <strong>Apache</strong>: Place them in the <span className='background-grey'>htdocs/</span> folder.</li>
                                            <li>For <strong>Nginx</strong>: Place them in <span className='background-grey'>/var/www/html/</span>.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        Configure the server to serve the index.html file for all routes.
                                        <ul style={{ listStyle: 'disc' }}>
                                            <li>
                                                Example Nginx configuration:
                                                <div className='codePalateBox mt-2 mb-4'>
                                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                                        __html: `
                                                        <p>server {</p>
                                                        <p class="ml-30">    listen 80;</p>
                                                        <p class="ml-30">    server_name your-domain.com;</p>
                                                        <br />  
                                                        <p class="ml-30">    root /var/www/html;</p>
                                                        <p class="ml-30">    index index.html;</p>
                                                        <br />  
                                                        <p class="ml-30">    location / {</p>
                                                        <p class="ml-60">        try_files $uri /index.html;</p>
                                                        <p class="ml-30">    }</p>
                                                        <p>}</p>
                                                        `
                                                    }}></div>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li className='mt-4'>
                                <strong>Option 4: Deploy to a Cloud Provider</strong>
                                <ul style={{ listStyle: 'decimal' }}>
                                    <li>
                                        <strong>AWS S3</strong> (Static Website Hosting)
                                        <ul style={{ listStyle: 'disc' }}>
                                            <li>Upload the <span className='background-grey'>dist/your-app-name</span> files to an S3 bucket.</li>
                                            <li>Enable "Static Website Hosting" in the bucket settings.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>Heroku</strong>
                                        <ul style={{ listStyle: 'disc' }}>
                                            <li>Use a Node.js server to serve your app.</li>
                                            <li>Deploy it using Git.</li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>

                        </ul>
                    </li>
                    <li className='operator'>
                        <h5 className='mt-5 mb-3'>Verify Your Deployment</h5>
                        <p>After deployment, test your app by visiting the deployment URL. Make sure all the routes work and there are no errors.</p>
                    </li>
                </ul>


            </div>
        </section>
    )
}