import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/function-apply";

export default function FunctionApply() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Function Apply in JavaScript | Aspirant's Home");
        const urls = {
            'previous': '/javascript/function-call',
            'next': '/javascript/function-bind'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>What is apply() in JavaScript?</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className='background-grey'>apply()</span> <strong>method</strong> in JavaScript is similar to the <span className='background-grey'>call()</span> method. It is used to <strong>invoke a function and explicitly set the value of</strong> <span className='background-grey'>this</span>. The main difference is that <span className='background-grey'>apply()</span> allows you to pass arguments as an <strong>array</strong> instead of individual values.
                </p>


                <h5 className='mt-5 mb-3'>Why Use <span className='background-grey'>apply()</span>?</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>To call a function with a specific value for <span className='background-grey'>this</span>.</li>
                    <li>To pass multiple arguments to a function as an array (especially useful when you already have data in an array format).</li>
                </ul>

                <h5 className='mt-5 mb-3'>Syntax</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>functionName.<span class="color-blue">apply</span>(thisArg, [arg1, arg2, ...]);</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span className='background-grey'>functionName</span>: The function you want to call.</li>
                    <li><span className='background-grey'>thisArg</span>: The value you want <span className='background-grey'>this</span> to refer to inside the function.</li>
                    <li><span className='background-grey'>arg1, arg2, ...</span>: The arguments you want to pass to the function.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Example 1: Using <span className='background-grey'>apply()</span> to Set <span className='background-grey'>this</span></h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">function</span> <span class="color-red">greet</span>(greeting, age) {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(greeting + <span class="color-green">", my name is "</span> + <span class="color-blue">this</span>.name + <span class="color-green">" and I am "</span> + age + <span class="color-green">" years old."</span>);</p>
                        <p>}</p>
                        <p><span class="color-blue">let</span> person = { <span class="color-pink">name</span>: <span class="color-green">"Alice"</span> };</p>
                        <p class="color-grey">// Use apply() to set &#96;this&#96; and pass arguments as an array</p>
                        <p>greet.<span class="color-red">apply</span>(person, [<span class="color-green">"Hi"</span>, <span class="color-pink">25</span>]);</p>
                        <p class="color-grey">// Output: Hi, my name is Alice and I am 25 years old.</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Key Difference Between <span className='background-grey'>call()</span> and <span className='background-grey'>apply()</span></h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>
                        <span className='background-grey'>call()</span>: Pass arguments <strong>individually</strong>.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>greet.<span class="color-red">call</span>(person, <span class="color-green">"Hi"</span>, <span class="color-pink">25</span>);</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <span className='background-grey'>apply()</span>: Pass arguments as an <strong>array</strong>.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>greet.<span class="color-red">apply</span>(person, [<span class="color-green">"Hi"</span>, <span class="color-pink">25</span>]);</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>


                <h5 className='mt-5 mb-3'>Example 2: Finding Maximum in an Array</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> numbers = [<span class="color-pink">5</span>, <span class="color-pink">10</span>, <span class="color-pink">15</span>];</p>
                        <br />
                        <p class="color-grey">// Use apply() to pass the array as arguments</p></p>
                        <p><span class="color-blue">let</span> max = Math.max.<span class="color-red">apply</span>(null, numbers);</p>
                        <p><span class="color-pink">console</span>.<span class="color-red">log</span>(max); <span class="color-grey">// Output: 15</span></p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span className='background-grey'>Math.max</span> expects individual arguments (not an array).</li>
                    <li><span className='background-grey'>apply()</span> is used to "spread" the array elements into separate arguments.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Example 3: Borrowing Methods Using <span className='background-grey'>apply()</span></h5>
                <p>You can borrow methods from one object and use them for another by setting <span className='background-grey'>this</span> using <span className='background-grey'>apply()</span>.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> person1 = {</p>
                        <p class="ml-30">    <span class="color-pink">name</span>: <span class="color-green">"Alice"</span>,</p>
                        <p class="ml-30">    <span class="color-pink">greet</span>: <span class="color-blue">function</span>(age) {</p>
                        <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Hi, my name is "</span> + <span class="color-blue">this</span>.name + <span class="color-green">" and I am "</span> + age + <span class="color-green">" years old."</span>);</p>
                        <p class="ml-30">    }</p>
                        <p>};</p>
                        <br />
                        <p><span class="color-blue">let</span> person2 = { <span class="color-pink">name</span>: <span class="color-green">"Bob"</span> };</p>
                        <br />
                        <p class="color-grey">// Borrow greet method from person1 for person2</p>
                        <p>person1.greet.<span class="color-red">apply</span>(person2, [<span class="color-pink">30</span>]); </p>
                        <p class="color-grey">// Output: Hi, my name is Bob and I am 30 years old.</p>
                        `
                    }}></div>
                </div>



            </div>
        </section>
    )
}