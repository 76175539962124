import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/angular/component-interaction";

export default function ComponentInteraction() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("How Components Works in Angular | Aspirant's Home");
        const urls = {
            'previous': '/angular/creating-and-using-components',
            'next': '/angular/lifecycle-hooks'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>How Components Interact?</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In Angular applications, <strong>component interaction</strong> refers to the process of enabling communication between components. Since Angular applications are built with multiple components, these components often need to share data or respond to events. Component interaction can happen between:
                </p>

                <ul style={{ listStyle: 'decimal' }}>
                    <li>Parent and Child Components</li>
                    <li>Sibling Components</li>
                    <li>Unrelated Components</li>
                </ul>


                <h5 className='mt-5 mb-3'>Interaction Between Parent and Child Components</h5>
                <p>Component can interact between parent and child in two ways -</p>

                <h4 className='mt-5'>A. Passing Data from Parent to Child</h4>
                <p>Use the <span class="background-grey">@Input</span> <strong>decorator</strong> to pass data from a parent component to a child component.</p>
                <h6 className='mt-4'>In the Child Component:</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">import</span> { Component, Input } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                        <br />
                        <p><span class="color-red">@Component</span>({</p>
                        <p class="ml-30">    <span class="color-pink">selector</span>: <span class="color-green">'app-child'</span>,</p>
                        <p class="ml-30">    <span class="color-pink">template</span>: <span class="color-green">&#96;&lt;p&gt;Received from Parent: {{ data }}&lt;/p&gt;&#96;</span></p>
                        <p>})</p>
                        <p><span class="color-pink">export class</span> <span class="color-blue">ChildComponent</span> {</p>
                        <p class="ml-30">    <span class="color-red">@Input</span>() data: string = <span class="color-green">''</span>; <span class="color-grey">// Input property to receive data</span></p>
                        <p>}</p>
                        `
                    }}></div>
                </div>
                <h6>In the Parent Component</h6>
                <p>Use the child component's selector and bind the input property.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;<span class="color-pink">app-child</span> [<span class="color-pink">data</span>]=<span class="color-green">"'Hello from Parent'"</span>&gt;&lt;/<span class="color-pink">app-child</span>&gt;</p>
                        `
                    }}></div>
                </div>


                <h4 className='mt-5'>B. Sending Data from Child to Parent</h4>
                <p>Use the <span class="background-grey">@Output</span> <strong>decorator</strong> and <span class="background-grey">EventEmitter</span> to send data from a child component to a parent component.</p>

                <h6 className='mt-4'>In the Child Component:</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">import</span> { Component, Output, EventEmitter } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                        <br />
                        <p><span class="color-red">@Component</span>({</p>
                        <p class="ml-30">    <span class="color-pink">selector</span>: <span class="color-green">'app-child'</span>,</p>
                        <p class="ml-30">    <span class="color-pink">template</span>: <span class="color-green">&#96;&lt;button <span class="color-pink">(click)</span>=<span class="color-green">"sendMessage()"</span>&gt;Send to Parent&lt;/button&gt;&#96;</span></p>
                        <p>})</p>
                        <p><span class="color-pink">export class</span> <span class="color-blue">ChildComponent</span> {</p>
                        <p class="ml-30">    <span class="color-red">@Output</span>() messageEvent = <span class="color-blue">new</span> <span class="color-red">EventEmitter</span>&lt;<span class="color-blue">string</span>&gt;();</p>
                        <br />
                        <p class="ml-30">    <span class="color-red">sendMessage</span>() {</p>
                        <p class="ml-60">        this.messageEvent.<span class="color-red">emit</span>(<span class="color-green">'Hello Parent!'</span>);</p>
                        <p class="ml-30">    }</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>
                <h6>In the Parent Component</h6>
                <p>It handle the emitted event.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;<span class="color-pink">app-child</span> <span class="color-pink">(messageEvent)</span>=<span class="color-green">"receiveMessage($event)"</span>&gt;&lt;/<span class="color-pink">app-child</span>&gt;</p>
                        `
                    }}></div>
                </div>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">export class</span> <span class="color-blue">ParentComponent</span> {</p>
                        <p class="ml-30">    <span class="color-red">receiveMessage</span>(message: <span class="color-blue">string</span>) {</p>
                        <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">'Message from Child:'</span>, message);</p>
                        <p class="ml-30">    }</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-3'>Interaction Between Sibling Components</h5>
                <p>Since sibling components do not have a direct relationship, communication between them is typically achieved through a <strong>shared service</strong>.</p>

                <h4 className='mt-5'>A. Create a Service</h4>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        Generate a service using Angular CLI:
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">ng generate</span> service data</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        In the service, create properties or methods to share data:
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">import</span> { Injectable } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                                <p><span class="color-pink">import</span> { BehaviorSubject } <span class="color-pink">from</span> <span class="color-green">'rxjs'</span>;</p>
                                <br />
                                <p><span class="color-red">@Injectable</span>({</p>
                                <p class="ml-30">    <span class="color-pink">providedIn</span>: <span class="color-green">'root'</span></p>
                                <p>})</p>
                                <p><span class="color-pink">export class</span> <span class="color-blue">DataService</span> {</p>
                                <p class="ml-30">    <span class="color-blue">private</span> messageSource = <span class="color-blue">new</span> <span class="color-red">BehaviorSubject</span>&lt;<span class="color-blue">string</span>&gt;(<span class="color-green">'Default Message'</span>);</p>
                                <p class="ml-30">    currentMessage = this.messageSource.<span class="color-red">asObservable</span>();</p>
                                <br />
                                <p class="ml-30">    <span class="color-red">changeMessage</span>(message: <span class="color-blue">string</span>) {</p>
                                <p class="ml-60">        this.messageSource.<span class="color-red">next</span>(message);</p>
                                <p class="ml-30">    }</p>
                                <p>}</p>
                                `
                            }}></div>
                        </div>

                    </li>
                </ul>

                <h4 className='mt-5 mb-3'>B. Use the Service in Sibling Components</h4>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <p>In the First Sibling Component (to send data):</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">import</span> { Component } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                                <p><span class="color-pink">import</span> { DataService } <span class="color-pink">from</span> <span class="color-green">'./data.service'</span>;</p>
                                <br />
                                <p><span class="color-red">@Component</span>({</p>
                                <p class="ml-30">    <span class="color-pink">selector</span>: <span class="color-green">'app-sibling-one'</span>,</p>
                                <p class="ml-30">    <span class="color-pink">template</span>: <span class="color-green">&#96;&lt;button (click)="sendMessage()"&gt;Send Message&lt;/button&gt;&#96;</span></p>
                                <p>})</p>
                                <p><span class="color-pink">export class</span> <span class="color-blue">SiblingOneComponent</span> {</p>
                                <p class="ml-30">    <span class="color-red">constructor</span>(<span class="color-pink">private</span> dataService: <span class="color-blue">DataService</span>) {}</p>
                                <br />
                                <p class="ml-30">    <span class="color-red">sendMessage</span>() {</p>
                                <p class="ml-60">        <span class="color-blue">this</span>.dataService.<span class="color-red">changeMessage</span>(<span class="color-green">'Hello from Sibling One'</span>);</p>
                                <p class="ml-30">    }</p>
                                <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <p>In the Second Sibling Component (to receive data):</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">import</span> { Component, OnInit } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                                <p><span class="color-pink">import</span> { DataService } <span class="color-pink">from</span> <span class="color-green">'./data.service'</span>;</p>
                                <br />
                                <p><span class="color-red">@Component</span>({</p>
                                <p class="ml-30">    <span class="color-pink">selector</span>: <span class="color-green">'app-sibling-two'</span>,</p>
                                <p class="ml-30">    <span class="color-pink">template</span>: <span class="color-green">&#96;&lt;p&gt;{{ message }}&lt;/p&gt;&#96;</span></p>
                                <p>})</p>
                                <p><span class="color-pink">export class</span> <span class="color-blue">SiblingTwoComponent</span> <span class="color-pink">implements</span> <span class="color-blue">OnInit</span> {</p>
                                <p class="ml-30">    message: <span class="color-blue">string</span> = <span class="color-green">''</span>;</p>
                                <br />
                                <p class="ml-30">    <span class="color-red">constructor</span>(<span class="color-pink">private</span> dataService: <span class="color-red">DataService</span>) {}</p>
                                <br />
                                <p class="ml-30">    <span class="color-red">ngOnInit</span>() {</p>
                                <p class="ml-60">        <span class="color-blue">this</span>.dataService.currentMessage.<span class="color-red">subscribe</span>((message) => {</p>
                                <p class="ml-90">            <span class="color-blue">this</span>.message = message;</p>
                                <p class="ml-60">        });</p>
                                <p class="ml-30">    }</p>
                                <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>



                <h5 className='mt-5 mb-3'>Interaction Between Unrelated Components</h5>
                <p>For communication between components that are not parent-child or siblings, use a <strong>shared service</strong>. This approach is similar to sibling communication and utilizes the service to manage state or events between unrelated components.</p>


            </div>
        </section>
    )
}