import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/angular/introduction";

export default function Introduction() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("An Introduction to Angular | Aspirant's Home");
        const urls = {
            'next': '/angular/environment-setup'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>What is Angular?</h3>
            <div className='mt-4 mb-5'>
            
                <p>
                    Angular is a <strong>TypeScript-based framework</strong> developed and maintained by <strong>Google</strong> for building <strong>dynamic, modern web applications</strong>. It allows developers to create <strong>single-page applications (SPAs)</strong>, which load a single HTML page and dynamically update the content without requiring a full page reload. Angular is known for its strong focus on productivity, scalability, and performance.
                </p>

                <h5 className='mt-5 mb-3'>Key Features of Angular:</h5>
                <ul className='featureList' style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>Component-Based Architecture</strong>:
                        <p>Applications in Angular are built using <strong>components</strong>, which are reusable building blocks that define the structure (HTML), behavior (TypeScript), and appearance (CSS) of a portion of the application.</p>
                    </li>
                    <li>
                        <strong>Two-Way Data Binding</strong>:
                        <p>Angular provides seamless synchronization between the view (HTML) and the model (TypeScript). Changes in the model automatically reflect in the view, and vice versa.</p>
                    </li>
                    <li>
                        <strong>Dependency Injection</strong>:
                        <p>Angular's built-in dependency injection simplifies the management of services and promotes modular code.</p>
                    </li>
                    <li>
                        <strong>Directives</strong>:
                        <p>Directives allow developers to extend the functionality of HTML by creating custom elements or attributes. Examples include:</p>
                        <ul style={{listStyle:'disc'}}>
                            <li style={{marginTop:0}}>Structural directives like <span class="background-grey">*ngIf</span> and <span class="background-grey">*ngFor</span>.</li>
                            <li style={{marginTop:0}}>Attribute directives like <span class="background-grey">ngClass</span> and <span class="background-grey">ngStyle</span>.</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Routing:</strong>
                        <p>Angular's <strong>Router Module</strong> enables navigation between different views or components in an application, creating seamless single-page app experiences.</p>
                    </li>
                    <li>
                        <strong>Built-in Services:</strong>
                        <p>Angular includes various <strong>services</strong> like HTTP client, animation, and forms handling, making complex tasks easier to implement.</p>
                    </li>
                    <li>
                        <strong>TypeScript:</strong>
                        <p>Angular is built using <strong>TypeScript</strong>, a superset of JavaScript that provides static typing and modern JavaScript features.</p>
                    </li>
                    <li>
                        <strong>RxJS for Reactive Programming:</strong>
                        <p>Angular leverages <strong>RxJS(Reactive Extensions for JavaScript)</strong> for handling asynchronous data streams and managing complex events.</p>
                    </li>
                </ul>
                <h5 className='mt-5 mb-3'>Why Use Angular?</h5>
                <ul style={{listStyle:'disc'}}>
                    <li><strong>Scalability</strong>: Suited for both small and large applications.</li>
                    <li><strong>Cross-Platform Development</strong>: Can be used for web, mobile, and desktop applications.</li>
                    <li><strong>Community and Ecosystem</strong>: Supported by Google, with a large community and extensive third-party libraries.</li>
                    <li><strong>Modularity</strong>: Encourages the development of reusable, modular code.</li>
                </ul>


                <p className='featureClass mt-5'>Angular is a <strong>powerful and versatile framework</strong> that is particularly well-suited for building scalable, maintainable, and dynamic applications.</p>
            </div>
        </section>
    )
}