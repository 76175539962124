import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/jquery/events/mouseenter";

export default function Mouseenter() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("jQuery mouseenter() Method | Aspirant's Home");
        const urls = {
            'previous': '/jquery/events'
        }
        path.setPreviousNext(urls);
        path.setPreviousName('Back to Events');
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>jQuery mouseenter() Method</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">mouseenter()</span> method in jQuery triggers an event when the mouse pointer enters a selected element. It’s useful for handling actions that should occur when a user hovers over an element, such as showing tooltips, highlighting elements, or triggering animations. Unlike the <span class="background-grey">mouseover()</span> method, <span class="background-grey">mouseenter()</span> only triggers once when entering the element, without firing repeatedly as the mouse moves within the element’s area.
                </p>

                <p>It has a basic syntax -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(selector).<span class="color-red">mouseenter</span>(function);</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">selector</span> specifies the element(s) to attach the <span class="background-grey">mouseenter</span> event to.</li>
                    <li><span class="background-grey">function</span> is a callback function to execute when the mouse enters the element(s).</li>
                </ul>

                <h5 className='mt-5 mb-3'>Example: Changing Background Color on <span class="background-grey">mouseenter</span></h5>
                <p>Here’s an example where a div changes color when the mouse enters its area.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;div <span class="color-pink">id</span>=<span class="color-green">"hoverBox"</span> <span class="color-pink">style</span>=<span class="color-green">"width: 150px; height: 100px; background-color: lightblue;"</span>&gt;</p>
                        <p class="ml-30">    Hover over me!</p>
                        <p>&lt;/div&gt;</p>
                        `
                    }}></div>
                </div>
                <h6>jQuery Code</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"#hoverBox"</span>).<span class="color-red">mouseenter</span>(<span class="color-blue">function</span>() {</p>
                        <p class="ml-30">    $(this).<span class="color-red">css</span>(<span class="color-green">"background-color"</span>, <span class="color-green">"lightgreen"</span>);</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>When the mouse enters the <span class="background-grey">#hoverBox</span> element, its background color changes to light green.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Example: Displaying Tooltips on Hover</h5>
                <p>You can use <span class="background-grey">mouseenter()</span> to display additional information or tooltips when the mouse enters an element.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;div <span class="color-pink">id</span>=<span class="color-green">"infoBox"</span> <span class="color-pink">style</span>=<span class="color-green">"width: 150px; height: 100px; background-color: coral;"</span>&gt;</p>
                        <p class="ml-30">    Hover to see info!</p>
                        <p>&lt;/div&gt;</p>
                        <p>&lt;p <span class="color-pink">id</span>=<span class="color-green">"tooltip"</span> <span class="color-pink">style</span>=<span class="color-green">"display: none;"</span>&gt;This is extra information!&lt;/p&gt;</p>
                        `
                    }}></div>
                </div>
                <h6>jQuery Code</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"#infoBox"</span>).<span class="color-red">mouseenter</span>(<span class="color-blue">function</span>() {</p>
                        <p class="ml-30">    $(<span class="color-green">"#tooltip"</span>).<span class="color-red">show</span>();</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>When the mouse enters <span class="background-grey">#infoBox</span>, the <span class="background-grey">#tooltip</span> paragraph is displayed.</li>
                    <li>The tooltip is hidden again when the mouse leaves <span class="background-grey">#infoBox</span>.</li>
                </ul>


            </div>
        </section>
    )
}