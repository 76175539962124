import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/function-call";

export default function FunctionCall() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Function Call in JavaScript | Aspirant's Home");
        const urls = {
            'previous': '/javascript/function-invocation',
            'next': '/javascript/function-apply'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>What is call() in JavaScript?</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className='background-grey'>call()</span> <strong>method</strong> in JavaScript is used to <strong>invoke a function and explicitly set the value of</strong> <span className='background-grey'>this</span>. It allows you to call a function as if it belongs to a specific object, even if it doesn't.
                </p>

                <h5 className='mt-5 mb-3'>Why Use <span className='background-grey'>call()</span>?</h5>
                <p>By default, the value of <span className='background-grey'>this</span> in a function depends on how the function is called:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>In a <strong>regular function</strong>, <span className='background-grey'>this</span> refers to the global object (or <span className='background-grey'>undefined</span> in strict mode).</li>
                    <li>In a <strong>method of an object</strong>, <span className='background-grey'>this</span> refers to the object.</li>
                </ul>
                <p>The <span className='background-grey'>call()</span> method lets you override this behavior and explicitly define what <span className='background-grey'>this</span> refers to.</p>

                
                <h5 className='mt-5 mb-3'>Syntax</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>functionName.<span class="color-blue">call</span>(thisArg, arg1, arg2, ...);</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span className='background-grey'>functionName</span>: The function you want to invoke.</li>
                    <li><span className='background-grey'>thisArg</span>: The value you want <span className='background-grey'>this</span> to refer to inside the function.</li>
                    <li><span className='background-grey'>arg1, arg2, ...</span>: The arguments you want to pass to the function.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Example 1: Using <span className='background-grey'>call()</span> to Change <span className='background-grey'>this</span></h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">function</span> <span class="color-red">greet</span>() {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Hello, "</span> + <span class="color-blue">this</span>.name + <span class="color-green">"!"</span>);</p>
                        <p>}</p>
                        <p><span class="color-blue">let</span> person = { <span class="color-pink">name</span>: <span class="color-green">"Alice"</span> };</p>
                        <p class="color-grey">// Use call() to set &#96;this&#96; to the &#96;person&#96; object</p>
                        <p>greet.<span class="color-red">call</span>(person); <span class="color-grey">// Output: Hello, Alice!</span></p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span className='background-grey'>greet</span> function does not belong to the <span className='background-grey'>person</span> object.</li>
                    <li>Using <span className='background-grey'>call()</span>, we make <span className='background-grey'>this</span> inside the <span className='background-grey'>greet</span> function refer to the <span className='background-grey'>person</span> object.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Example 2: Function with Arguments Using <span className='background-grey'>call()</span></h5>
                <p>If the function takes parameters, you can pass them after the <span className='background-grey'>thisArg</span>.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">function</span> <span class="color-red">introduce</span>(greeting, age) {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(greeting + <span class="color-green">", my name is "</span> + <span class="color-blue">this</span>.name + <span class="color-green">" and I am "</span> + age + <span class="color-green">" years old."</span>);</p>
                        <p>}</p>
                        <p><span class="color-blue">let</span> person = { name: <span class="color-green">"Bob"</span> };</p>
                        <p class="color-grey">// Use call() to set &#96;this&#96; and pass arguments</p>
                        <p>introduce.<span class="color-red">call</span>(person, <span class="color-green">"Hi"</span>, <span class="color-pink">25</span>);</p>
                        <p class="color-grey">// Output: Hi, my name is Bob and I am 25 years old.</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Example 3: Borrowing Methods Using <span className='background-grey'>call()</span></h5>
                <p>You can use <span className='background-grey'>call()</span> to borrow methods from one object for another.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> person1 = {</p>
                        <p class="ml-30">    <span class="color-pink">name</span>: <span class="color-green">"Alice"</span>,</p>
                        <p class="ml-30">    <span class="color-pink">greet</span>: <span class="color-red">function</span>() {</p>
                        <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Hello, "</span> + <span class="color-blue">this</span>.name + <span class="color-green">"!"</span>);</p>
                        <p class="ml-30">    }</p>
                        <p>};</p>
                        <p><span class="color-blue">let</span> person2 = { <span class="color-pink">name</span>: <span class="color-green">"Charlie"</span> };</p>
                        <p class="color-grey">// Borrow &#96;greet&#96; from &#96;person1&#96; and call it for &#96;person2&#96;</p>
                        <p>person1.greet.<span class="color-red">call</span>(person2); <span class="color-grey">// Output: Hello, Charlie!</span></p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Using <span className='background-grey'>call()</span> with Arrays</h5>
                <p>Although <span className='background-grey'>call()</span> is typically used with objects, it can also be used with array-like objects.</p>
                <h6>Example:</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> numbers = [<span class="color-pink">5</span>, <span class="color-pink">10</span>, <span class="color-pink">15</span>];</p>
                        <br />
                        <p class="color-grey">// Use Math.max with call() to find the largest number</p>
                        <p><span class="color-blue">let</span> max = Math.max.<span class="color-red">call</span>(null, ...numbers);</p>
                        <p><span class="color-pink">console</span>.<span class="color-red">log</span>(max); <span class="color-grey">// Output: 15</span></p>
                        `
                    }}></div>
                </div>
                <p>Here,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span className='background-grey'>Math.max</span> expects arguments, not an array.</li>
                    <li><span className='background-grey'>call()</span> is used to pass the array elements as individual arguments.</li>
                </ul>


            </div>
        </section>
    )
}