import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/tag/sub";

export default function Sub() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - <sub> Tag | Aspirant's Home");
        const urls = {
            'previous': '/html/tag-list'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - &lt;sub&gt; Tag</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">&lt;sub&gt;</span> tag in HTML is used to display text in a subscript format, which is text lowered slightly below the normal line and shown in a smaller font size. Subscript text is often used in chemical formulas, mathematical expressions, and technical documentation where specific characters need to be displayed below the baseline.
                </p>

                <h5 className='mt-5 mb-3'>Structure and Purpose of <span class="background-grey">&lt;sub&gt;</span> Tag</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span class="background-grey">&lt;sub&gt;</span> tag lowers text below the baseline, making it useful for scientific and technical notation.</li>
                    <li>It is commonly used in chemical formulas, mathematical expressions, and annotations.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <p>The <span class="background-grey">&lt;sub&gt;</span> tag wraps around the text you want to display as subscript:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;sub&gt;Your subscript text here&lt;/sub&gt;</p>
                        `
                    }}></div>
                </div> 
                
                <h5 className='mt-5 mb-3'>Example Usage of <span class="background-grey">&lt;sub&gt;</span> Tag</h5>
                <p>Here’s an example using <span class="background-grey">&lt;sub&gt;</span> to display a chemical formula:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Subscript Example&lt;/title&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <br />    
                        <p class="ml-60">        &lt;p&gt;The formula for water is H&lt;sub&gt;2&lt;/sub&gt;O.&lt;/p&gt;</p>
                        <br />    
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div> 
                <p>In this example, "2" is displayed as a subscript, representing the chemical notation for two hydrogen atoms in "H₂O".</p>
                <p>Output will be the following -</p>
                <div className='outputPalateBox mt-2 mb-4'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <!DOCTYPE html>
                        <html lang="en">
                            <head>
                                <meta charset="UTF-8">
                                <title>Subscript Example</title>
                            </head>
                            <body>
                            
                                <p>The formula for water is H<sub>2</sub>O.</p>
                            
                            </body>
                        </html>
                        `
                    }}></div>
                </div> 

                <p>The <span class="background-grey">&lt;sub&gt;</span> tag lowers text to a subscript position, making it ideal for chemical formulas, mathematical indices, and other technical notations. By default, it renders the text smaller and lowered, but CSS can be used to further style it. The <span class="background-grey">&lt;sub&gt;</span> tag is essential for scientific and mathematical documents, adding clarity to symbols and formulas within the content.</p>



            </div>
        </section>
    )
}