import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/jquery/events/dblclick";

export default function Dblclick() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("jQuery dblclick() Method | Aspirant's Home");
        const urls = {
            'previous': '/jquery/events'
        }
        path.setPreviousNext(urls);
        path.setPreviousName('Back to Events');
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>jQuery dblclick() Method</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">dblclick()</span> method in jQuery is an event-handling function that triggers when a user double-clicks on an HTML element. This method allows you to execute specific code or perform actions on elements in response to a double-click.
                </p>

                <p>It has a basic syntax -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(selector).<span class="color-red">dblclick</span>(function);</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">selector</span> is specifies the element(s) to attach the <span class="background-grey">dblclick</span> event to.</li>
                    <li><span class="background-grey">function</span> is a function to execute when the element is clicked. This function is often called a callback function.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Basic Example: Using <span class="background-grey">dblclick()</span> to Change Text</h5>
                <p>Here’s an example that changes the text of a paragraph when double-clicked.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;p <span class="color-pink">id</span>=<span class="color-green">"myParagraph"</span>&gt;Double-click me!&lt;/p&gt;</p>
                        `
                    }}></div>
                </div>
                <h6>jQuery Code</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"#myParagraph"</span>).<span class="color-red">dblclick</span>(<span class="color-blue">function</span>() {</p>
                        <p class="ml-30">    $(this).<span class="color-red">text</span>(<span class="color-green">"You double-clicked me!"</span>);</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span class="background-grey">dblclick()</span> method is applied to the paragraph with the ID <span class="background-grey">#myParagraph</span>.</li>
                    <li>When the paragraph is double-clicked, its text changes to "You double-clicked me!"</li>
                </ul>


            </div>
        </section>
    )
}