import React, { useEffect, useState, useRef } from 'react'
import { Outlet, Link } from "react-router-dom";
import TopHeader from '../TopHeader';
import Footer from '../Footer'
import { MenuContext, TitleContext, PathContext } from "../../Context";
import { useContext } from "react";
import PreviousNext from '../PreviousNext';

export default function JavascriptLayout() {

    const addspaceElement = useRef();
    const menuElement = useRef([]);
    const menu = useContext(MenuContext);
    const pathName = useContext(PathContext);
    console.log(pathName)
    const pathname = pathName.pathName;
    const [showSubMenu, setShowSubMenu] = useState(document.getElementsByClassName("activeSubMenu")[0] ? false : true)
    const title = useContext(TitleContext);

    const setPathName = (path) => {
        if(path)
            pathName.setPathName(path);
    }

    useEffect(() => {
        setPathName(window.location.pathname)
        pathName.setPreviousName('Previous');
        //console.log(pathName.backname)
        if (menu.isMenu == true) menu.setMenuOpen(false)
        addspaceElement.current.scrollIntoView({ block: "center" })
        const element = document.getElementsByClassName("activeMenu")[0];
        element && element.scrollIntoView({ block: "center" });
        const subelement = document.getElementsByClassName("activeSubMenu")[0];
        subelement && subelement.scrollIntoView({ block: "center" });
    }, [pathname,showSubMenu])

    return (
        <>
            <TopHeader />
            <div className='container-fluid'>
                <div className='row' style={{background:'#ddd'}}>
                    <div ref={menuElement} className={menu.isMenu == true ? 'col-md-3 col-lg-2 menuColumnMobile menuColumn p-0' : ' menuColumn p-0'} >
                        <div className='d-flex align-items-center headerMenu headerMainMenu sticky-top mb-4' style={{ 'height': '150px' }}>
                            <div>
                                <h2>Javascript Tutorial</h2>
                                <Link to="/quiz/javascript" className='rounded-corner btn btn-primary float-end'><small>Get Quiz</small></Link>
                            </div>
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/javascript/introduction' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/introduction" onClick={() => { setPathName('/javascript/introduction'); } }>Introduction</Link>
                                </li>
                                <li className={pathname == '/javascript/environment-setup' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/environment-setup" onClick={() => { setPathName('/javascript/environment-setup'); } }>Environment Setup</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            Basics
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/javascript/variables-and-data-types' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/variables-and-data-types" onClick={() => { setPathName('/javascript/variables-and-data-types'); } }>Variables and Data Types</Link>
                                </li>
                                <li className={pathname == '/javascript/operators' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/operators" onClick={() => { setPathName('/javascript/operators'); } }>Operators</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            Control Structures
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/javascript/if-else' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/if-else" onClick={() => { setPathName('/javascript/if-else'); } }>If Else</Link>
                                </li>
                                <li className={pathname == '/javascript/switch' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/switch" onClick={() => { setPathName('/javascript/switch'); } }>Switch</Link>
                                </li>
                                <li className={pathname == '/javascript/loop-for' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/loop-for" onClick={() => { setPathName('/javascript/loop-for'); } }>Loop For</Link>
                                </li>
                                <li className={pathname == '/javascript/loop-for-in' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/loop-for-in" onClick={() => { setPathName('/javascript/loop-for-in'); } }>Loop For In</Link>
                                </li>
                                <li className={pathname == '/javascript/loop-for-of' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/loop-for-of" onClick={() => { setPathName('/javascript/loop-for-of'); } }>Loop For Of</Link>
                                </li>
                                <li className={pathname == '/javascript/loop-while' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/loop-while" onClick={() => { setPathName('/javascript/loop-while'); } }>Loop While</Link>
                                </li>
                                <li className={pathname == '/javascript/break-statement' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/break-statement" onClick={() => { setPathName('/javascript/break-statement'); } }>Break</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            Objects
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/javascript/object-definition' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/object-definition" onClick={() => { setPathName('/javascript/object-definition'); } }>Object Definition</Link>
                                </li>
                                <li className={pathname == '/javascript/object-prototypes' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/object-prototypes" onClick={() => { setPathName('/javascript/object-prototypes'); } }>Object Prototypes</Link>
                                </li>
                                <li className={pathname == '/javascript/object-methods' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/object-methods" onClick={() => { setPathName('/javascript/object-methods'); } }>Object Methods</Link>
                                </li>
                                <li className={pathname == '/javascript/object-properties' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/object-properties" onClick={() => { setPathName('/javascript/object-properties'); } }>Object Properties</Link>
                                </li>
                                <li className={pathname == '/javascript/object-getset' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/object-getset" onClick={() => { setPathName('/javascript/object-getset'); } }>Object Get/Set</Link>
                                </li>
                                <li className={pathname == '/javascript/object-protection' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/object-protection" onClick={() => { setPathName('/javascript/object-protection'); } }>Object Protection</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            Functions
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/javascript/function-definition' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/function-definition" onClick={() => { setPathName('/javascript/function-definition'); } }>Function Definition</Link>
                                </li>
                                <li className={pathname == '/javascript/function-parameters' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/function-parameters" onClick={() => { setPathName('/javascript/function-parameters'); } }>Function Parameters</Link>
                                </li>
                                <li className={pathname == '/javascript/function-invocation' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/function-invocation" onClick={() => { setPathName('/javascript/function-invocation'); } }>Function Invocation</Link>
                                </li>
                                <li className={pathname == '/javascript/function-call' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/function-call" onClick={() => { setPathName('/javascript/function-call'); } }>Function Call</Link>
                                </li>
                                <li className={pathname == '/javascript/function-apply' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/function-apply" onClick={() => { setPathName('/javascript/function-apply'); } }>Function Apply</Link>
                                </li>
                                <li className={pathname == '/javascript/function-bind' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/function-bind" onClick={() => { setPathName('/javascript/function-bind'); } }>Function Bind</Link>
                                </li>
                                <li className={pathname == '/javascript/function-closures' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/function-closures" onClick={() => { setPathName('/javascript/function-closures'); } }>Function Closures</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            Arrays
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/javascript/arrays' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/arrays" onClick={() => { setPathName('/javascript/arrays'); } }>Array Definition</Link>
                                </li>
                                <li className={pathname == '/javascript/array-methods' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/array-methods" onClick={() => { setPathName('/javascript/array-methods'); } }>Array Methods</Link>
                                </li>
                                <li className={pathname == '/javascript/array-search' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/array-search" onClick={() => { setPathName('/javascript/array-search'); } }>Array Search</Link>
                                </li>
                                <li className={pathname == '/javascript/array-sort' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/array-sort" onClick={() => { setPathName('/javascript/array-sort'); } }>Array Sort</Link>
                                </li>
                                <li className={pathname == '/javascript/array-iteration' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/array-iteration" onClick={() => { setPathName('/javascript/array-iteration'); } }>Array Iteration</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            Dates
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/javascript/dates' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/dates" onClick={() => { setPathName('/javascript/dates'); } }>Date Definition</Link>
                                </li>
                                <li className={pathname == '/javascript/date-formats' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/date-formats" onClick={() => { setPathName('/javascript/date-formats'); } }>Date Formats</Link>
                                </li>
                                <li className={pathname == '/javascript/date-get-methods' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/date-get-methods" onClick={() => { setPathName('/javascript/date-get-methods'); } }>Date Get Methods</Link>
                                </li>
                                <li className={pathname == '/javascript/date-set-methods' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/date-set-methods" onClick={() => { setPathName('/javascript/date-set-methods'); } }>Date Set Methods</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            Strings
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/javascript/strings' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/strings" onClick={() => { setPathName('/javascript/strings'); } }>Strings</Link>
                                </li>
                                <li className={pathname == '/javascript/string-methods' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/string-methods" onClick={() => { setPathName('/javascript/string-methods'); } }>String Methods</Link>
                                </li>
                                <li className={pathname == '/javascript/string-search' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/string-search" onClick={() => { setPathName('/javascript/string-search'); } }>String Search</Link>
                                </li>
                                <li className={pathname == '/javascript/string-templates' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/string-templates" onClick={() => { setPathName('/javascript/string-templates'); } }>String Templates</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            Numbers
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/javascript/numbers' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/numbers" onClick={() => { setPathName('/javascript/numbers'); } }>Numbers</Link>
                                </li>
                                <li className={pathname == '/javascript/number-methods' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/number-methods" onClick={() => { setPathName('/javascript/number-methods'); } }>Number Methods</Link>
                                </li>
                                <li className={pathname == '/javascript/number-properties' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/number-properties" onClick={() => { setPathName('/javascript/number-properties'); } }>Number Properties</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            JS Async
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/javascript/callbacks' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/callbacks" onClick={() => { setPathName('/javascript/callbacks'); } }>Callbacks</Link>
                                </li>
                                <li className={pathname == '/javascript/asynchronous' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/asynchronous" onClick={() => { setPathName('/javascript/asynchronous'); } }>Asynchronous</Link>
                                </li>
                                <li className={pathname == '/javascript/promises' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/promises" onClick={() => { setPathName('/javascript/promises'); } }>Promises</Link>
                                </li>
                                <li className={pathname == '/javascript/async-await' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/async-await" onClick={() => { setPathName('/javascript/async-await'); } }>Async/Await</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            DOM Manipulation
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/javascript/selecting-elements' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/selecting-elements" onClick={() => { setPathName('/javascript/selecting-elements'); } }>Selecting Elements</Link>
                                </li>
                                <li className={pathname == '/javascript/changing-content-and-styles' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/changing-content-and-styles" onClick={() => { setPathName('/javascript/changing-content-and-styles'); } }>Changing Content and Styles</Link>
                                </li>
                                <li className={pathname == '/javascript/event-handling' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/event-handling" onClick={() => { setPathName('/javascript/event-handling'); } }>Event Handling</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            Browser Object Model
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/javascript/window-object' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/window-object" onClick={() => { setPathName('/javascript/window-object'); } }>JS Window</Link>
                                </li>
                                <li className={pathname == '/javascript/js-screen' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/js-screen" onClick={() => { setPathName('/javascript/js-screen'); } }>JS Screen</Link>
                                </li>
                                <li className={pathname == '/javascript/js-location' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/js-location" onClick={() => { setPathName('/javascript/js-location'); } }>JS Location</Link>
                                </li>
                                <li className={pathname == '/javascript/js-history' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/js-history" onClick={() => { setPathName('/javascript/js-history'); } }>JS History</Link>
                                </li>
                                <li className={pathname == '/javascript/js-navigator' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/js-navigator" onClick={() => { setPathName('/javascript/js-navigator'); } }>JS Navigator</Link>
                                </li>
                                <li className={pathname == '/javascript/timing' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/timing" onClick={() => { setPathName('/javascript/timing'); } }>JS Timing</Link>
                                </li>
                                <li className={pathname == '/javascript/cookies' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/cookies" onClick={() => { setPathName('/javascript/cookies'); } }>JS Cookies</Link>
                                </li>
                                <li className={pathname == '/javascript/dialog-boxes' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/dialog-boxes" onClick={() => { setPathName('/javascript/dialog-boxes'); } }>Dialog Boxes</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            Working with Forms
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/javascript/form-validation' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/form-validation" onClick={() => { setPathName('/javascript/form-validation'); } }>Form Validation</Link>
                                </li>
                                <li className={pathname == '/javascript/submitting-forms' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/submitting-forms" onClick={() => { setPathName('/javascript/submitting-forms'); } }>Submitting Forms</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            AJAX and Fetch API
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/javascript/making-http-requests' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/making-http-requests" onClick={() => { setPathName('/javascript/making-http-requests'); } }>Making HTTP Requests</Link>
                                </li>
                                <li className={pathname == '/javascript/handling-responses' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/handling-responses" onClick={() => { setPathName('/javascript/handling-responses'); } }>Handling Responses</Link>
                                </li>
                                <li className={pathname == '/javascript/working-with-json' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/working-with-json" onClick={() => { setPathName('/javascript/working-with-json'); } }>Working with JSON</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            Versions
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/javascript/js-2009' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/js-2009" onClick={() => { setPathName('/javascript/js-2009'); } }>JS 2009 (ES5)</Link>
                                </li>
                                <li className={pathname == '/javascript/js-2015' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/js-2015" onClick={() => { setPathName('/javascript/js-2015'); } }>JS 2015 (ES6)</Link>
                                </li>
                                <li className={pathname == '/javascript/js-2016' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/js-2016" onClick={() => { setPathName('/javascript/js-2016'); } }>JS 2016</Link>
                                </li>
                                <li className={pathname == '/javascript/js-2017' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/js-2017" onClick={() => { setPathName('/javascript/js-2017'); } }>JS 2017</Link>
                                </li>
                                <li className={pathname == '/javascript/js-2018' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/js-2018" onClick={() => { setPathName('/javascript/js-2018'); } }>JS 2018</Link>
                                </li>
                                <li className={pathname == '/javascript/js-2019' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/js-2019" onClick={() => { setPathName('/javascript/js-2019'); } }>JS 2019</Link>
                                </li>
                                <li className={pathname == '/javascript/js-2020' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/js-2020" onClick={() => { setPathName('/javascript/js-2020'); } }>JS 2020</Link>
                                </li>
                                <li className={pathname == '/javascript/js-2021' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/js-2021" onClick={() => { setPathName('/javascript/js-2021'); } }>JS 2021</Link>
                                </li>
                                <li className={pathname == '/javascript/js-2022' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/js-2022" onClick={() => { setPathName('/javascript/js-2022'); } }>JS 2022</Link>
                                </li>
                                <li className={pathname == '/javascript/js-2023' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/js-2023" onClick={() => { setPathName('/javascript/js-2023'); } }>JS 2023</Link>
                                </li>
                                <li className={pathname == '/javascript/js-2024' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/js-2024" onClick={() => { setPathName('/javascript/js-2024'); } }>JS 2024</Link>
                                </li>
                                <li className={pathname == '/javascript/history' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/history" onClick={() => { setPathName('/javascript/history'); } }>History</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            Graphics
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/javascript/graphics' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/graphics" onClick={() => { setPathName('/javascript/graphics'); } }>JS Graphics</Link>
                                </li>
                                <li className={pathname == '/javascript/canvas' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/canvas" onClick={() => { setPathName('/javascript/canvas'); } }>JS Canvas</Link>
                                </li>
                                <li className={pathname == '/javascript/plotly' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/plotly" onClick={() => { setPathName('/javascript/plotly'); } }>JS Plotly</Link>
                                </li>
                                <li className={pathname == '/javascript/chart-js' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/chart-js" onClick={() => { setPathName('/javascript/chart-js'); } }>JS Chart.js</Link>
                                </li>
                                <li className={pathname == '/javascript/google-chart' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/google-chart" onClick={() => { setPathName('/javascript/google-chart'); } }>Google Chart</Link>
                                </li>
                                <li className={pathname == '/javascript/d3-js' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/d3-js" onClick={() => { setPathName('/javascript/d3-js'); } }>D3.js</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            Web APIs
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/javascript/web-api' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/web-api" onClick={() => { setPathName('/javascript/web-api'); } }>Web API Intro</Link>
                                </li>
                                <li className={pathname == '/javascript/web-forms-api' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/web-forms-api" onClick={() => { setPathName('/javascript/web-forms-api'); } }>Web Forms API</Link>
                                </li>
                                <li className={pathname == '/javascript/web-history-api' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/web-history-api" onClick={() => { setPathName('/javascript/web-history-api'); } }>Web History API</Link>
                                </li>
                                <li className={pathname == '/javascript/web-storage-api' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/web-storage-api" onClick={() => { setPathName('/javascript/web-storage-api'); } }>Web Storage API</Link>
                                </li>
                                <li className={pathname == '/javascript/web-worker-api' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/web-worker-api" onClick={() => { setPathName('/javascript/web-worker-api'); } }>Web Worker API</Link>
                                </li>
                                <li className={pathname == '/javascript/web-fetch-api' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/web-fetch-api" onClick={() => { setPathName('/javascript/web-fetch-api'); } }>Web Fetch API</Link>
                                </li>
                                <li className={pathname == '/javascript/web-geolocation-api' ? 'activeMenu' : ''}>
                                    <Link to="/javascript/web-geolocation-api" onClick={() => { setPathName('/javascript/web-geolocation-api'); } }>Web Geolocation API</Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div className='col-12 middlePart'>
                        
                        <div className='headerAdSpace' ref={addspaceElement}>
                            <img className='img-fluid' src='https://aspirantshome.com/slide1.png' />
                        </div>
                        <div className='contentPart row sticky-top'>
                            <div className='col-12 col-md-12 mb-4 contentSection'>
                                <div className='container'>
                                    <div className='contentPage'>
                                        {
                                            pathName.urls ? <PreviousNext urls={ pathName.urls } name={pathName.backname} /> : <></>
                                        }
                                            <Outlet />
                                        {
                                            pathName.urls ? <PreviousNext urls={ pathName.urls } /> : <></>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='footerAdSpace'>
                                <img className='img-fluid' src='https://aspirantshome.com/slide4.png' />
                            </div>
                        </div>

                        
                    </div>
                    <div className='col-12  rightPart'>
                        <img className='img-fluid' src='https://aspirantshome.com/slide2.png' />
                        <img className='mt-5 img-fluid' src='https://aspirantshome.com/slide3.png' />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <div className='container-fluid footerPart'>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
