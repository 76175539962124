import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/angular/interpolation";

export default function Interpolation() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Interpolation in Angular | Aspirant's Home");
        const urls = {
            'previous': '/angular/viewchild-and-contentchild',
            'next': '/angular/property-binding'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Interpolation</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Interpolation in Angular is a way to bind data from a TypeScript class (component) to the HTML template. It allows you to display dynamic data within the view by embedding expressions enclosed in double curly braces <span class="background-grey">&#123;&#123; &#125;&#125;</span>.
                </p>

                <h5 className='mt-5 mb-3'>How Interpolation Works</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>Interpolation evaluates the expression inside <span class="background-grey">&#123;&#123; &#125;&#125;</span>.</li>
                    <li>It then converts the result into a string.</li>
                    <li>Finally, the string is displayed in the DOM.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Syntax</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        &#123;&#123; expression &#125;&#125;
                        `
                    }}></div>
                </div>
                <p>Here, the <span class="background-grey">expression</span> is any valid TypeScript or JavaScript expression, such as a variable, property, or method.</p>


                <h5 className='mt-5 mb-3'>Example: Basic Interpolation</h5>
                <h6 className='mt-3'>Component (TypeScript)</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">import</span> { Component } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                        <br />
                        <p><span class="color-red">@Component</span>({</p>
                        <p class="ml-30">    <span class="color-pink">selector</span>: <span class="color-green">'app-interpolation-example'</span>,</p>
                        <p class="ml-30">    <span class="color-pink">template</span>: <span class="color-green">&#96;&lt;h1&gt;Welcome, &#123;&#123; name &#125;&#125;!&lt;/h1&gt;&#96;</span></p>
                        <p>})</p>
                        <p><span class="color-pink">export class</span> <span class="color-blue">InterpolationExampleComponent</span> {</p>
                        <p class="ml-30">    name: <span class="color-blue">string</span> = <span class="color-green">'John'</span>;</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>
                <h6 className='mt-3'>Template (HTML)</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;h1&gt;Welcome, &#123;&#123; name &#125;&#125;!&lt;/h1&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Output in the Browser: Welcome, John!</p>




            </div>
        </section>
    )
}