import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/jquery/chaining";

export default function Chaining() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Chaining in jQuery | Aspirant's Home");
        const urls = {
            'previous': '/jquery/callback',
            'next': '/jquery/get'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Chaining in jQuery</h3>
            <div className='mt-4 mb-5'>
                <p>
                    <strong>Chaining</strong> in jQuery is a technique that allows you to perform multiple actions on the same set of elements in a single line of code. This is possible because most jQuery methods return a jQuery object, allowing you to apply additional methods in a chain without needing to re-select elements each time.
                </p>

                <h5 className='mt-5 mb-3'>How Chaining Works</h5>
                <p>In jQuery, when you call a method on a selected element(s), the method typically returns the jQuery object for those elements. This returned object can be used to immediately call another jQuery method on the same elements, creating a <strong>chain</strong> of actions. Here is the basic syntax -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(<span class="color-green">"#box"</span>).<span class="color-red">slideUp</span>(<span class="color-pink">500</span>).<span class="color-red">slideDown</span>(<span class="color-pink">500</span>).<span class="color-red">css</span>(<span class="color-green">"background-color"</span>, <span class="color-green">"yellow"</span>);</p>
                        `
                    }}></div>
                </div>
                <p>In this example, <span class="background-grey">#box</span> is selected once, and three actions are applied sequentially:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>It slides up over 500 milliseconds.</li>
                    <li>Then it slides back down over 500 milliseconds.</li>
                    <li>Finally, its background color changes to yellow.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Benefits of Chaining</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>Improves Code Readability</strong>: Instead of writing multiple lines to apply actions on the same element, you can use a single line to keep the code cleaner and more readable.</li>
                    <li><strong>Reduces Redundant Element Selection</strong>: By chaining, you avoid repeatedly selecting the same element, making the code more efficient and potentially improving performance.</li>
                </ul>

                
                <h5 className='mt-5 mb-3'>Some Examples of Chaining in jQuery</h5>
                <p>Here are the some examples of Chaining.</p>

                <h6>Example 1: Basic Chain of Methods</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(<span class="color-green">"#text"</span>).<span class="color-red">css</span>(<span class="color-green">"color"</span>, <span class="color-green">"red"</span>).<span class="color-red">slideUp</span>(<span class="color-pink">600</span>).<span class="color-red">slideDown</span>(<span class="color-pink">600</span>);</p>
                        `
                    }}></div>
                </div>
                <p>This code,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>Changes the text color of <span class="background-grey">#text</span> to red.</li>
                    <li>Slides it up over 600 milliseconds.</li>
                    <li>Slides it back down over 600 milliseconds.</li>
                </ul>

                <h6 className='mt-4'>Example 2: Chaining Animation Methods</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"#box"</span>)</p>
                        <p>.<span class="color-red">animate</span>({ <span class="color-pink">width</span>: <span class="color-green">"300px"</span> }, <span class="color-pink">400</span>)</p>
                        <p>.<span class="color-pink">animate</span>({ <span class="color-pink">height</span>: <span class="color-green">"200px"</span> }, <span class="color-pink">400</span>)</p>
                        <p>.<span class="color-red">fadeOut</span>(<span class="color-pink">400</span>)</p>
                        <p>.<span class="color-red">fadeIn</span>(<span class="color-pink">400</span>);</p>
                        `
                    }}></div>
                </div>
                <p>This example shows how chaining can make a complex animation sequence concise. The <span class="background-grey">#box</span>:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>Expands its width to 300px over 400 milliseconds.</li>
                    <li>Increases its height to 200px over the next 400 milliseconds.</li>
                    <li>Fades out over 400 milliseconds.</li>
                    <li>Then fades back in over 400 milliseconds.</li>
                </ul>


            </div>
        </section>
    )
}