import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/jquery/stop-function";

export default function Stop() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("stop() in jQuery | Aspirant's Home");
        const urls = {
            'previous': '/jquery/animate',
            'next': '/jquery/callback'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>stop() in jQuery</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The jQuery <span class="background-grey">stop()</span> function is used to stop animations or effects that are currently running on selected elements. This is especially useful when you have multiple animations or effects on an element, and you want to prevent animations from stacking up or creating unwanted behavior due to rapid user interaction (like repeated button clicks).
                </p>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <p>Here is the basic syntax -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(selector).<span class="color-red">stop</span>(clearQueue, jumpToEnd);</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">clearQueue</span> (optional) is a Boolean value (<span class="background-grey">true</span> or <span class="background-grey">false</span>). If <span class="background-grey">true</span>, any animations waiting in the queue are removed. Default is <span class="background-grey">false</span>.</li>
                    <li><span class="background-grey">jumpToEnd</span> (optional) is a Boolean value (<span class="background-grey">true</span> or <span class="background-grey">false</span>). If <span class="background-grey">true</span>, the current animation immediately jumps to its endpoint without completing the steps. Default is <span class="background-grey">false</span>.</li>
                </ul>


                <h5 className='mt-5 mb-3'>How <span class="background-grey">stop()</span> Works</h5>
                <p>
                    When you apply <span class="background-grey">stop()</span> to an element, it stops any active animations on that element. Without the optional parameters, it will simply halt the animation in its current state. If you specify <span class="background-grey">clearQueue</span> as <span class="background-grey">true</span>, it clears out all pending animations in the queue. If <span class="background-grey">jumpToEnd</span> is <span class="background-grey">true</span>, the animation will jump directly to its final state.
                </p>

                <h5 className='mt-5 mb-3'>Some Examples of <span class="background-grey">stop()</span> Method</h5>
                <p>Here are the some examples of <span class="background-grey">stop()</span> method</p>
                
                <h6>Example 1: Basic <span class="background-grey">stop()</span> to Halt an Animation</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(<span class="color-green">"#box"</span>).<span class="color-red">stop</span>();</p>
                        `
                    }}></div>
                </div>
                <p>This stops any ongoing animations on <span class="background-grey">#box</span>, freezing it in its current state.</p>


                <h6>Example 2: Stopping an Animation with <span class="background-grey">clearQueue</span></h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(<span class="color-green">"#box"</span>).<span class="color-red">stop</span>(<span class="color-blue">true</span>);</p>
                        `
                    }}></div>
                </div>
                <p>This stops any ongoing animation on <span class="background-grey">#box</span> and removes any animations waiting in the queue, so no additional animations will start.</p>

                <h6>Example 3: Stopping an Animation and Jumping to the End</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(<span class="color-green">"#box"</span>).<span class="color-red">stop</span>(<span class="color-blue">true</span>, <span class="color-blue">true</span>);</p>
                        `
                    }}></div>
                </div>
                <p>This stops the animation, clears the queue, and jumps to the final state of the current animation immediately.</p>


            </div>
        </section>
    )
}