import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/switch";

export default function Switch() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Switch in Javascript | Aspirant's Home");
        const urls = {
            'previous': '/javascript/if-else',
            'next': '/javascript/loop-for'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Switch</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className='background-grey'>switch</span> <strong>statement</strong> is used to make decisions based on multiple possible values for a single variable or expression. It is an alternative to writing multiple <span className='background-grey'>if-else</span> statements.
                </p>

                <h5 className='mt-5 mb-3'>How Does <span className='background-grey'>switch</span> Work?</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>The <span className='background-grey'>switch</span> statement compares the value of a variable (or expression) to <strong>different cases</strong>.</li>
                    <li>If a match is found, the code inside the matching case is executed.</li>
                    <li>If no match is found, the <span className='background-grey'>default</span> block (if provided) runs.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Syntax</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">switch</span> (expression) {</p>
                        <p class="ml-30">    <span class="color-blue">case</span> value1:</p>
                        <p class="ml-60 color-grey">        // Code to run if expression === value1</p>
                        <p class="ml-60">        <span class="color-blue">break</span>;</p>
                        <p class="ml-30">    <span class="color-blue">case</span> value2:</p>
                        <p class="ml-60 color-grey">        // Code to run if expression === value2</p>
                        <p class="ml-60">        <span class="color-blue">break</span>;</p>
                        <p class="ml-30 color-grey">    // More cases...</p>
                        <p class="ml-30">    <span class="color-blue">default</span>:</p>
                        <p class="ml-60 color-grey">        // Code to run if no cases match
                        <p>}</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Example: Basic switch Statement</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> day = <span class="color-pink">2</span>;</p>

                        <p><span class="color-blue">switch</span> (day) {</p>
                        <p class="ml-30">    <span class="color-blue">case</span> <span class="color-pink">1</span>:</p>
                        <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Monday"</span>);</p>
                        <p class="ml-60">        <span class="color-blue">break;</span></p>
                        <p class="ml-30">    <span class="color-blue">case</span> <span class="color-pink">2</span>:</p>
                        <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Tuesday"</span>);</p>
                        <p class="ml-60">        <span class="color-blue">break</span>;</p>
                        <p class="ml-30">    <span class="color-blue">case</span> <span class="color-pink">3</span>:</p>
                        <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Wednesday"</span>);</p>
                        <p class="ml-60">        <span class="color-blue">break</span>;</p>
                        <p class="ml-30">    <span class="color-blue">default</span>:</p>
                        <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Invalid day"</span>);</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>
                <p>In this Example,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>If <span className='background-grey'>day</span> is <span className='background-grey'>2</span>, it prints: <strong>"Tuesday"</strong>.</li>
                    <li>If <span className='background-grey'>day</span> is not <span className='background-grey'>1</span>, <span className='background-grey'>2</span>, or <span className='background-grey'>3</span>, it prints: <strong>"Invalid day"</strong>.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Example: Using <span className='background-grey'>default</span></h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> fruit = <span class="color-green">"Apple"</span>;</p>

                        <p><span class="color-blue">switch</span> (fruit) {</p>
                        <p class="ml-30">    <span class="color-blue">case</span> <span class="color-green">"Banana"</span>:</p>
                        <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Bananas are yellow."</span>);</p>
                        <p class="ml-60">        <span class="color-blue">break</span>;</p>
                        <p class="ml-30">    <span class="color-blue">case</span> <span class="color-green">"Apple"</span>:</p>
                        <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Apples are red."</span>);</p>
                        <p class="ml-60">        <span class="color-blue">break</span>;</p>
                        <p class="ml-30">    <span class="color-blue">case</span> <span class="color-green">"Orange"</span>:</p>
                        <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Oranges are orange."</span>);</p>
                        <p class="ml-60">        <span class="color-blue">break</span>;</p>
                        <p class="ml-30">    <span class="color-blue">default</span>:</p>
                        <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Unknown fruit."</span>);</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>
                <h6>Output for <span className='background-grey'>fruit = "Apple"</span>:</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>Apples are red.</p>
                        `
                    }}></div>
                </div>
                <h6>Output for <span className='background-grey'>fruit = "Mango"</span>:</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>Unknown fruit.</p>
                        `
                    }}></div>
                </div>


            </div>
        </section>
    )
}