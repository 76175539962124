import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/events/onscroll";

export default function Onscroll() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - onscroll Event | Aspirant's Home");
        const urls = {
            'previous': '/html/events'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - onscroll Event</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">onscroll</span> event in HTML triggers whenever an element or the entire page is scrolled. This event is commonly used to implement features like sticky headers, infinite scrolling, or animations based on the scroll position.
                </p>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <p>The <span class="background-grey">onscroll</span> event is typically added directly to the <span class="background-grey">&lt;body&gt;</span> tag or any scrollable element.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;body <span class="color-pink">onscroll</span>=<span class="color-green">"handleScroll()"</span>&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Or, you can attach it using JavaScript:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>window.onscroll = <span class="color-red">function</span>() {</p>
                        <p class="ml-30">    console.<span class="color-red">log</span>(<span class="color-green">"Page scrolled!"</span>);</p>
                        <p>};</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Example: Display Scroll Position</h5>
                <p>This example shows how to display the scroll position of the page.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;body <span class="color-pink">onscroll</span>=<span class="color-green">"displayScrollPosition()"</span>&gt;</p>
                        <p class="ml-30">    &lt;div <span class="color-pink">style</span>=<span class="color-green">"height: 1500px; padding: 10px;"</span>&gt;</p>
                        <p class="ml-60">       &lt;p <span class="color-pink">id</span>=<span class="color-green">"scrollPosition"</span>&gt;Scroll position: 0&lt;/p&gt;</p>
                        <p class="ml-30">    &lt;/div&gt;</p>
                        <br />
                        <p class="ml-30">    &lt;script&gt;</p>
                        <p class="ml-60">        <span class="color-blue">function</span> <span class="color-red">displayScrollPosition</span>() {</p>
                        <p class="ml-90">            <span class="color-blue">const</span> scrollPos = window.scrollY; <span class="color-grey">// Vertical scroll position</span></p>
                        <p class="ml-90">            document.<span class="color-red">getElementById</span>(<span class="color-green">"scrollPosition"</span>).textContent = <span class="color-green">&#96;Scroll position: $&#123;scrollPos&#125;&#96;</span>;</p>
                        <p class="ml-60">        }</p>
                        <p class="ml-30">    &lt;/script&gt;</p>
                        <p>&lt;/body&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Here, The <span class="background-grey">displayScrollPosition</span> function updates the scroll position as the user scrolls down the page.</p>





            </div>
        </section>
    )
}