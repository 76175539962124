import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/events/onresize";

export default function Onresize() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - onresize Event | Aspirant's Home");
        const urls = {
            'previous': '/html/events'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - onresize Event</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">onresize</span> event in HTML is triggered when the browser window or an element is resized. This event is commonly used to adjust layouts, scale elements, or change other properties dynamically based on the new window size, making it essential for responsive web design.
                </p>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <p>The <span class="background-grey">onresize</span> event is typically used on the <span class="background-grey">&lt;body&gt;</span> or <span class="background-grey">window</span> object to monitor the resizing of the browser window.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;body <span class="color-pink">onresize</span>=<span class="color-green">"resizeHandler()"</span>&gt;</p>
                        `
                    }}></div>
                </div>
                <p>However, it is more common to attach onresize using JavaScript on the window object:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>window.onresize = <span class="color-red">function</span>() {</p>
                        <p class="ml-30">    console.<span class="color-red">log</span>(<span class="color-green">"Window resized!"</span>);</p>
                        <p>};</p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-3'>Example: Display Window Size on Resize</h5>
                <p>This example shows how you can display the window’s width and height every time it is resized.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;body <span class="color-pink">onload</span>=<span class="color-green">"displaySize()"</span>&gt;</p>
                        <p class="ml-30">    &lt;h1&gt;Window Size&lt;/h1&gt;</p>
                        <p class="ml-30">    &lt;p <span class="color-pink">id</span>=<span class="color-green">"sizeInfo"</span>&gt;&lt;/p&gt;</p>
                        <br />
                        <p class="ml-30">    &lt;script&gt;</p>
                        <p class="ml-60">        <span class="color-blue">function</span> <span class="color-red">displaySize</span>() {</p>
                        <p class="ml-90">            <span class="color-blue">const</span> width = window.innerWidth;</p>
                        <p class="ml-90">            <span class="color-blue">const</span> height = window.innerHeight;</p>
                        <p class="ml-90">            document.<span class="color-red">getElementById</span>(<span class="color-green">"sizeInfo"</span>).textContent = <span class="color-green">&#96;Width: $&#123;width&#125;, Height: $&#123;height&#125;&#96;</span>;</p>
                        <p class="ml-60">        }</p>
                        <p class="ml-60">        window.onresize = displaySize;</p>
                        <p class="ml-30">    &lt;/script&gt;</p>
                        <p>&lt;/body&gt;</p>
                        `
                    }}></div>
                </div >
                    
                <p>In this example, The <span class="background-grey">displaySize</span> function updates the window dimensions in the paragraph element whenever the window is resized.</p>



            </div>
        </section>
    )
}