import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/function-invocation";

export default function FunctionInvocation() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Function Invocation in JavaScript | Aspirant's Home");
        const urls = {
            'previous': '/javascript/function-parameters',
            'next': '/javascript/function-call'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Function Invocation</h3>
            <div className='mt-4 mb-5'>
                <p><strong>Function invocation</strong> means <strong>calling a function</strong> to execute the code written inside it. When you <strong>invoke</strong> a function, the JavaScript engine runs the code block defined in the function.</p>

                <p>Think of it like pressing a button to start an action:</p>
                <ul style={{listStyle:'disc'}}>
                    <li><strong>Defining a function</strong> is like creating the button.</li>
                    <li><strong>Invoking a function</strong> is like pressing the button to perform the action.</li>
                </ul>

                <h5 className='mt-5 mb-3'>How to Invoke a Function</h5>
                <p>You invoke a function by using its <strong>name followed by parentheses</strong> <span className='background-grey'>( )</span>.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">functionName</span>(arguments);</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Simple Function Invocation</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">function</span> <span class="color-red">greet</span>() {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Hello, welcome to JavaScript!"</span>);</p>
                        <p>}</p>
                        <p class="color-grey">// Invoking the function</p>
                        <p><span class="color-red">greet</span>();  <span class="color-grey">// Output: Hello, welcome to JavaScript!</span></p>
                        `
                    }}></div>
                </div>
                <p>In this example:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The function <span className='background-grey'>greet()</span> is defined.</li>
                    <li>The function is invoked using <span className='background-grey'>greet()</span>.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Types of Function Invocation</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>Direct Invocation</li>
                    <li>Method Invocation</li>
                    <li>Constructor Invocation</li>
                </ul>


                <h5 className='mt-5 mb-3'>1. Direct Function Invocation</h5>
                <p>This is the most common way to invoke a function. You simply call the function by its name followed by <span className='background-grey'>( )</span>.</p>
                <h6>Example</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">function</span> <span class="color-red">sayHello</span>() {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Hello!"</span>);</p>
                        <p>}</p>
                        <p><span class="color-red">sayHello</span>(); <span class="color-grey">// Output: Hello!</span></p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-3'>2. Method Invocation</h5>
                <p>When a function is part of an object, it is called a <strong>method</strong>. To invoke a method, use the object name followed by the method name.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> person = {</p>
                        <p class="ml-30">    <span class="color-pink">name</span>: <span class="color-green">"Alice"</span>,</p>
                        <p class="ml-30">    <span class="color-pink">greet</span>: <span class="color-red">function</span>() {</p>
                        <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Hello, "</span> + <span class="color-blue">this</span>.name + <span class="color-green">"!"</span>);</p>
                        <p class="ml-30">    }</p>
                        <p>};</p>
                        <p>person.<span class="color-red">greet</span>(); <span class="color-grey">// Output: Hello, Alice!</span></p>
                        `
                    }}></div>
                </div>
                <p>In this example:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span className='background-grey'>greet</span> is a method of the <span className='background-grey'>person</span> object.</li>
                    <li>It is invoked using <span className='background-grey'>person.greet()</span>.</li>
                </ul>


                <h5 className='mt-5 mb-3'>3. Constructor Invocation</h5>
                <p>When you invoke a function using the <span className='background-grey'>new</span> keyword, it acts as a <strong>constructor</strong>. A constructor is used to create objects.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">function</span> <span class="color-red">Person</span>(name) {</p>
                        <p class="ml-30">    <span class="color-blue">this</span>.name = name;</p>
                        <p>}</p>
                        <p><span class="color-blue">let</span> person1 = <span class="color-blue">new</span> <span class="color-red">Person</span>(<span class="color-green">"Alice"</span>);</p>
                        <p><span class="color-pink">console</span>.<span class="color-red">log</span>(person1.name); <span class="color-grey">// Output: Alice</span></p>
                        `
                    }}></div>
                </div>
                <p>In this example:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span className='background-grey'>Person</span> is invoked as a constructor using <span className='background-grey'>new</span>.</li>
                    <li>It creates a <span className='background-grey'>new</span> object (person1) with the property <span className='background-grey'>name</span>.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Function Invocation with Parameters</h5>
                <p>If a function requires parameters, you pass the <strong>arguments</strong> inside the parentheses.</p>
                <h6>Example</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">function</span> <span class="color-red">addNumbers</span>(a, b) {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(a + b);</p>
                        <p>}</p>
                        <p><span class="color-red">addNumbers</span>(<span class="color-pink">5</span>, <span class="color-pink">10</span>); <span class="color-grey">// Output: 15</span></p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-3'>What Happens If You Don’t Invoke a Function?</h5>
                <p>If you just write the function name without <span className='background-grey'>( )</span>, the function will not execute. Instead, it will return the <strong>function definition</strong>.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">function</span> <span class="color-red">sayHello</span>() {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Hello!"</span>);</p>
                        <p>}</p>
                        <p><span class="color-pink">console</span>.<span class="color-red">log</span>(sayHello); <span class="color-grey">// Output: [Function: sayHello]</span></p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Role of <span className='background-grey'>$this</span></h5>
                <p>The value of <span className='background-grey'>this</span> inside a function depends on how it is invoked.</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>Direct invocation: <span className='background-grey'>this</span> refers to the global object (<span className='background-grey'>window</span> in browsers).</li>
                    <li>Method invocation: <span className='background-grey'>this</span> refers to the object that owns the method.</li>
                    <li>Constructor invocation: <span className='background-grey'>this</span> refers to the new object being created.</li>
                </ul>


            </div>
        </section>
    )
}