import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/next-js/introduction";

export default function Introduction() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("An Introduction to Next.js | Aspirant's Home");
        const urls = {
            'next': '/next-js/key-features'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>What is Next.js?</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Next.js is a <strong>React framework</strong> that helps you build fast, user-friendly websites and applications. If you're new to web development, think of Next.js as a tool that makes building modern websites much easier by taking care of common challenges like routing, fetching data, and improving performance.
                </p>


                <h5 className='mt-5 mb-3'>What is a Framework?</h5>
                <p>A framework is like a toolbox with pre-built tools that help you create something faster and better. Next.js is a framework for React (a library for building user interfaces).</p>


                <h5 className='mt-5 mb-3'>What Makes Next.js Special?</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>Page Routing is Simple</strong>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>
                                <p className='mt-0 mb-0'>You create pages just by making files in the pages folder.</p>
                                <p className='mt-0 mb-0'>Example:</p>
                                <p className='mt-0 mb-0'>If you create about.js inside pages, visiting yourwebsite.com/about will automatically work.</p>
                            </li>
                        </ul>
                    </li>
                    <li className='mt-3'>
                        <strong>Fast Performance</strong>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>
                                Next.js makes your site load super fast by generating the pages ahead of time or on-demand.
                            </li>
                        </ul>
                    </li>
                    <li className='mt-3'>
                        <strong>Handles SEO Automatically</strong>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>
                                SEO (Search Engine Optimization) helps your site appear on Google. Next.js makes it easy by allowing you to set page titles, descriptions, and keywords.
                            </li>
                        </ul>
                    </li>
                    <li className='mt-3'>
                        <strong>Supports Data Fetching</strong>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>
                                It lets you get data (like user profiles, news, or product lists) from a server or API and display it on your website.
                            </li>
                        </ul>
                    </li>
                    <li className='mt-3'>
                        <strong>Full-stack Capabilities</strong>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>
                                You can write server - side code(like APIs) directly in your project.This means you don’t always need a separate backend!
                            </li>
                        </ul>
                    </li>
                </ul>

                <h5 className='mt-5 mb-3'>Why Should Learn Next.js?</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>
                        <strong>Beginner-Friendly:</strong>
                        <p>Even though it's powerful, it’s easy to start with. If you know basic HTML, CSS, JavaScript, and some React, you’re ready to dive in.</p>
                    </li>
                    <li>
                        <strong>Widely Used:</strong>
                        <p>Companies love Next.js because it helps them build fast, reliable websites.</p>
                    </li>
                    <li>
                        <strong>All-in-One Solution:</strong>
                        <p>Instead of learning many tools, Next.js combines everything into one package.</p>
                    </li>
                </ul>

            </div>
        </section>
    )
}