import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/events/onfocus";

export default function Onfocus() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - onfocus Event | Aspirant's Home");
        const urls = {
            'previous': '/html/events'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - onfocus Event</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">onfocus</span> event in HTML triggers when an element gains focus, meaning it's clicked on, tabbed into, or otherwise activated. It’s most commonly used with form elements such as <span class="background-grey">&lt;input&gt;</span>, <span class="background-grey">&lt;textarea&gt;</span>, and <span class="background-grey">&lt;select&gt;</span>, allowing developers to provide a visual cue, perform actions, or validate data as soon as the element is focused.
                </p>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <p>To use <span class="background-grey">onfocus</span>, add it as an attribute to an HTML element with JavaScript code or a function to execute when the element receives focus.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">onfocus</span>=<span class="color-green">"alert('Input field is focused')"</span> <span class="color-pink">placeholder</span>=<span class="color-green">"Click here"</span>&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example, An alert is shown when the input field gains focus.</p>


                <h5 className='mt-5 mb-3'>Example: Highlighting Input Field on Focus</h5>
                <p>You can use <span class="background-grey">onfocus</span> to change the background color of an input field when it's focused, giving users a visual cue.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">onfocus</span>=<span class="color-green">"highlight(this)"</span>  <span class="color-pink">placeholder</span>=<span class="color-green">"Type here"</span>&gt;</p>
                        <br />
                        <p>&lt;script&gt;</p>
                        <p class="ml-30">  <span class="color-blue">function</span> <span class="color-red">highlight</span>(element) {</p>
                        <p class="ml-60">    element.style.backgroundColor = <span class="color-green">"#e0f7fa"</span>; <span class="color-grey">// Light blue background on focus</span></p>
                        <p class="ml-30">  }</p>
                        <p>&lt;/script&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example, The <span class="background-grey">highlight</span> function changes the background color when the input field is focused.</p>
                <p>Output will be the following -</p>
                <div className='outputPalateBox mt-2 mb-4' style={{border:'none'}}>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <input type="text" onfocus="this.style.backgroundColor = '#e0f7fa';" placeholder="Type here">

                        `
                    }}></div>
                </div>
                        


            </div>
        </section>
    )
}