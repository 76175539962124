import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/jquery/events/change";

export default function Change() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("jQuery change() Method | Aspirant's Home");
        const urls = {
            'previous': '/jquery/events'
        }
        path.setPreviousNext(urls);
        path.setPreviousName('Back to Events');
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>jQuery change() Method</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">change()</span> method in jQuery is an event-handling function that triggers when the value of an input, select, or textarea element changes. This method is commonly used to capture and handle changes to form elements, such as dropdowns, checkboxes, and text inputs.
                </p>

                <p>It has a basic syntax -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(selector).<span class="color-red">change</span>(function);</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">selector</span> specifies the element(s) to attach the <span class="background-grey">change</span> event to.</li>
                    <li><span class="background-grey">function</span> is a callback function to execute when the element’s value changes.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Basic Example: <span class="background-grey">change()</span> with a Text Input Field</h5>
                <p>Here’s an example that updates a paragraph when the text in an input field changes.</p>

                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">id</span>=<span class="color-green">"nameInput"</span> <span class="color-pink">placeholder</span>=<span class="color-green">"Enter your name"</span>&gt;</p>
                        <p>&lt;p <span class="color-pink">id</span>=<span class="color-green">"displayName"</span>&gt;&lt;/p&gt;</p>
                        `
                    }}></div>
                </div>
                <h6>jQuery Code</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"#nameInput"</span>).<span class="color-red">change</span>(<span class="color-blue">function</span>() {</p>
                        <p class="ml-30">    $(<span class="color-green">"#displayName"</span>).<span class="color-red">text</span>(<span class="color-green">"Hello, "</span> + $(this).<span class="color-red">val</span>() + <span class="color-green">"!"</span>);</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span class="background-grey">change()</span> method is attached to the input field with the ID <span class="background-grey">#nameInput</span>.</li>
                    <li>When the user changes the value of this input and leaves the field (loses focus), the paragraph with ID <span class="background-grey">#displayName</span> updates to greet the user by their entered name.</li>
                </ul>


            </div>
        </section>
    )
}