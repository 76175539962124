import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/angular/two-way-binding";

export default function TwoWayBinding() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Two-Way Binding in Angular | Aspirant's Home");
        const urls = {
            'previous': '/angular/event-binding',
            'next': '/angular/structural-directives'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Two-Way Binding</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Two-way binding in Angular allows synchronization of data between the <strong>component's class (TypeScript)</strong> and the <strong>template (HTML)</strong>. When data changes in the component, the view reflects the change, and when the user modifies the data in the view, the component is updated automatically.
                </p>
                <p>
                    This is particularly useful for forms and interactive user interfaces.
                </p>


                <h5 className='mt-5 mb-3'>How Two-Way Binding Works</h5>
                <p>Angular achieves two-way binding by combining:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>Property Binding</strong> (<span class="background-grey">[value]</span>) - To display the data in the view.</li>
                    <li><strong>Event Binding</strong> (<span class="background-grey">(input)</span>) - To update the data in the component when the user modifies it.</li>
                </ul>
                <p>Angular provides a special syntax using the <span class="background-grey">[(ngModel)]</span> <strong>directive</strong> for two-way binding.</p>


                <h5 className='mt-5 mb-3'>Syntax</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            &lt;input [<span class="color-pink">(ngModel)</span>]=<span class="color-green">"propertyName"</span> /&gt;
                        `
                    }}></div>
                </div>
                <p>Here,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">[(ngModel)]</span>: Combines property binding and event binding to create two-way data flow.</li>
                    <li><span class="background-grey">propertyName</span>: The variable in the component class bound to the input element.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Setting Up Two-Way Binding</h5>
                <p>To use <span class="background-grey">[(ngModel)]</span>, you need to import the <span class="background-grey">FormsModule</span> in your Angular application.</p>
                <p>In your application's module file (<span class="background-grey">app.module.ts</span>):</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">import</span> { NgModule } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                        <p><span class="color-pink">import</span> { BrowserModule } <span class="color-pink">from</span> <span class="color-green">'@angular/platform-browser'</span>;</p>
                        <p><span class="color-pink">import</span> { FormsModule } <span class="color-pink">from</span> <span class="color-green">'@angular/forms'</span>;</p>
                        <p><span class="color-pink">import</span> { AppComponent } <span class="color-pink">from</span> <span class="color-green">'./app.component'</span>;</p>
                        <br />
                        <p><span class="color-red">@NgModule</span>({</p>
                        <p class="ml-30">    <span class="color-pink">declarations</span>: [AppComponent],</p>
                        <p class="ml-30">    <span class="color-pink">imports</span>: [BrowserModule, FormsModule], <span class="color-grey">// Include FormsModule</span></p>
                        <p class="ml-30">    <span class="color-pink">providers</span>: [],</p>
                        <p class="ml-30">    <span class="color-pink">bootstrap</span>: [AppComponent]</p>
                        <p>})</p>
                        <p><span class="color-pink">export class</span> <span class="color-blue">AppModule</span> {}</p>
                        `
                    }}></div>
                </div>
                <p>To use <span class="background-grey">[(ngModel)]</span> in the Template</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;input [<span class="color-pink">(ngModel)</span>]=<span class="color-green">"username"</span> /&gt;</p>
                        <p>&lt;p&gt;Hello, {{ username }}!&lt;/p&gt;</p>
                        `
                    }}></div>
                </div>


            </div>
        </section>
    )
}