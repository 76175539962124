import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/angular/handling-form-submissions";

export default function FormSubmission() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Handling Form Submissions in Angular | Aspirant's Home");
        const urls = {
            'previous': '/angular/form-validation',
            'next': '/angular/creating-services'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Handling Form Submissions</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Handling form submission in Angular involves collecting and processing the user-provided data from forms. Angular provides two main approaches for form handling:
                </p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li><strong>Template-Driven Forms</strong></li>
                    <li><strong>Reactive Forms</strong></li>
                </ul>

                <h5 className='mt-5 mb-3'>Handling Form Submission in Template-Driven Forms</h5>
                <ul style={{listStyle:'disc'}}>
                    <li>It is simpler and more declarative.</li>
                    <li>It uses Angular directives (ngForm, ngModel) to manage form data and validation.</li>
                    <li>Form handling logic is typically in the component.</li>
                </ul>
                <p>Let's take an example -</p>

                <h6 className='mt-4'>Template</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;form #<span class="color-pink">userForm</span>=<span class="color-green">"ngForm"</span> (<span class="color-pink">ngSubmit</span>)=<span class="color-green">"onSubmit(userForm)"</span>&gt;</p>
                        <p class="ml-30 color-grey">    &lt;!-- Name Input --&gt;</p>
                        <p class="ml-30">    &lt;label <span class="color-pink">for</span>=<span class="color-green">"name"</span>&gt;Name:&lt;/label&gt;</p>
                        <p class="ml-30">    &lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">id</span>=<span class="color-green">"name"</span> <span class="color-pink">name</span>=<span class="color-green">"name"</span> <span class="color-pink">ngModel required</span>&gt;</p>
                        <p class="ml-30">    &lt;div *<span class="color-pink">ngIf</span>=<span class="color-green">"userForm.submitted && userForm.controls.name?.errors?.required"</span>&gt;</p>
                        <p class="ml-60">        &lt;small&gt;Name is required.&lt;/small&gt;</p>
                        <p class="ml-30">    &lt;/div&gt;</p>
                        <br />
                        <p class="ml-30 color-grey">    &lt;!-- Email Input --&gt;</p>
                        <p class="ml-30">    &lt;label <span class="color-pink">for</span>=<span class="color-green">"email"</span>&gt;Email:&lt;/label&gt;</p>
                        <p class="ml-30">    &lt;input <span class="color-pink">type</span>=<span class="color-green">"email"</span> <span class="color-pink">id</span>=<span class="color-green">"email"</span> <span class="color-pink">name</span>=<span class="color-green">"email"</span> <span class="color-pink">ngModel required email</span>&gt;</p>
                        <p class="ml-30">    &lt;div *<span class="color-pink">ngIf</span>=<span class="color-green">"userForm.submitted && userForm.controls.email?.errors"</span>&gt;</p>
                        <p class="ml-60">        &lt;small *<span class="color-pink">ngIf</span>=<span class="color-green">"userForm.controls.email?.errors.required"</span>&gt;Email is required.&lt;/small&gt;</p>
                        <p class="ml-60">        &lt;small *<span class="color-pink">ngIf</span>=<span class="color-green">"userForm.controls.email?.errors.email"</span>&gt;Invalid email format.&lt;/small&gt;</p>
                        <p class="ml-30">    &lt;/div&gt;</p>
                        <br />
                        <p class="ml-30 color-grey">    &lt;!-- Submit Button --&gt;</p>
                        <p class="ml-30">    &lt;button <span class="color-pink">type</span>=<span class="color-green">"submit"</span> [<span class="color-pink">disabled</span>]=<span class="color-green">"userForm.invalid"</span>&gt;Submit&lt;/button&gt;</p>
                        <p>&lt;/form&gt;</p>
                        `
                    }}></div>
                </div>


                <h6 className='mt-4'>Component</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">import</span> { Component } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                        <br />
                        <p><span class="color-red">@Component</span>({</p>
                        <p class="ml-30">    <span class="color-pink">selector</span>: <span class="color-green">'app-user-form'</span>,</p>
                        <p class="ml-30">    <span class="color-pink">templateUrl</span>: <span class="color-green">'./user-form.component.html'</span>,</p>
                        <p>})</p>
                        <p><span class="color-pink">export class</span> <span class="color-blue">UserFormComponent</span> {</p>
                        <p class="ml-30">    <span class="color-red">onSubmit</span>(form: <span class="color-blue">any</span>) {</p>
                        <p class="ml-60">        <span class="color-blue">if</span> (form.valid) {</p>
                        <p class="ml-90">            <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">'Form Data:'</span>, form.value);</p>
                        <p class="ml-60">        } <span class="color-blue">else</span> {</p>
                        <p class="ml-90">            <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">'Form is invalid.'</span>);</p>
                        <p class="ml-60">        }</p>
                        <p class="ml-30">    }</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>

                <h6 className='mt-4'>How It Works</h6>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span class="background-grey">#userForm="ngForm"</span> binds the form to Angular's <span class="background-grey">ngForm</span> directive, giving access to its state and controls.</li>
                    <li>When the form is submitted, the <span class="background-grey">onSubmit</span> method is called, passing the form object.</li>
                    <li>Angular automatically validates the fields based on directives like <span class="background-grey">required</span> and <span class="background-grey">email</span>. Errors are displayed conditionally using <span class="background-grey">*ngIf</span>.</li>
                    <li>If the form is valid, the <span class="background-grey">form.value</span> contains the submitted data.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Handling Form Submission in Reactive Forms</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>It is more programmatic and robust.</li>
                    <li>Form structure and validation logic are defined in the component.</li>
                    <li>It gives precise control over form behavior.</li>
                </ul>

                <p>Let's take an example -</p>

                <h6 className='mt-4'>Template</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;form [<span class="color-pink">formGroup</span>]=<span class="color-green">"userForm"</span> (<span class="color-pink">ngSubmit</span>)=<span class="color-green">"onSubmit()"</span>&gt;</p>
                        <p class="ml-30 color-grey">    &lt;!-- Name Input --&gt;</p>
                        <p class="ml-30">    &lt;label <span class="color-pink">for</span>=<span class="color-green">"name"</span>&gt;Name:&lt;/label&gt;</p>
                        <p class="ml-30">    &lt;input <span class="color-pink">id</span>=<span class="color-green">"name"</span> <span class="color-pink">formControlName</span>=<span class="color-green">"name"</span>&gt;</p>
                        <p class="ml-30">    &lt;div *<span class="color-pink">ngIf</span>=<span class="color-green">"name.touched && name.invalid"</span>&gt;</p>
                        <p class="ml-60">        &lt;small *<span class="color-pink">ngIf</span>=<span class="color-green">"name.errors?.required"</span>&gt;Name is required.&lt;/small&gt;</p>
                        <p class="ml-30">    &lt;/div&gt;</p>
                        <br />
                        <p class="ml-30 color-grey">    &lt;!-- Email Input --&gt;</p>
                        <p class="ml-30">    &lt;label <span class="color-pink">for</span>=<span class="color-green">"email"</span>&gt;Email:&lt;/label&gt;</p>
                        <p class="ml-30">    &lt;input <span class="color-pink">id</span>=<span class="color-green">"email"</span> <span class="color-pink">formControlName</span>=<span class="color-green">"email"</span>&gt;</p>
                        <p class="ml-30">    &lt;div *<span class="color-pink">ngIf</span>=<span class="color-green">"email.touched && email.invalid"</span>&gt;</p>
                        <p class="ml-60">        &lt;small *<span class="color-pink">ngIf</span>=<span class="color-green">"email.errors?.required"</span>&gt;Email is required.&lt;/small&gt;</p>
                        <p class="ml-60">        &lt;small *<span class="color-pink">ngIf</span>=<span class="color-green">"email.errors?.email"</span>&gt;Invalid email format.&lt;/small&gt;</p>
                        <p class="ml-30">    &lt;/div&gt;</p>
                        <br />
                        <p class="ml-30 color-grey">    &lt;!-- Submit Button --&gt;</p>
                        <p class="ml-30">    &lt;button <span class="color-pink">type</span>=<span class="color-green">"submit"</span> [<span class="color-pink">disabled</span>]=<span class="color-green">"userForm.invalid"</span>&gt;Submit&lt;/button&gt;</p>
                        <p>&lt;/form&gt;</p>
                        `
                    }}></div>
                </div>


                <h6 className='mt-4'>Component</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">import</span> { Component } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                        <p><span class="color-pink">import</span> { FormBuilder, FormGroup, Validators } <span class="color-pink">from</span> <span class="color-green">'@angular/forms'</span>;</p>
                        <br />
                        <p><span class="color-red">@Component</span>({</p>
                        <p class="ml-30">    <span class="color-pink">selector</span>: <span class="color-green">'app-user-form'</span>,</p>
                        <p class="ml-30">    <span class="color-pink">templateUrl</span>: <span class="color-green">'./user-form.component.html'</span>,</p>
                        <p>})</p>
                        <p><span class="color-pink">export class</span> <span class="color-blue">UserFormComponent</span> {</p>
                        <p class="ml-30">    userForm: <span class="color-blue">FormGroup</span>;</p>
                        <br />
                        <p class="ml-30">    <span class="color-red">constructor</span>(private fb: <span class="color-blue">FormBuilder</span>) {</p>
                        <p class="ml-60">        <span class="color-blue">this</span>.userForm = <span class="color-blue">this</span>.fb.<span class="color-red">group</spaan>({</p>
                        <p class="ml-90">            <span class="color-pink">name</span>: [<span class="color-green">''</span>, Validators.required],</p>
                        <p class="ml-90">            <span class="color-pink">email</span>: [<span class="color-green">''</span>, [Validators.required, Validators.email]],</p>
                        <p class="ml-60">        });</p>
                        <p class="ml-30">    }</p>
                        <br />
                        <p class="ml-30">    <span class="color-blue">get</span> <span class="color-red">name</span>() {</p>
                        <p class="ml-60">        <span class="color-blue">return this</span>.userForm.<span class="color-red">get</span>(<span class="color-green">'name'</span>);</p>
                        <p class="ml-30">    }</p>
                        <br />
                        <p class="ml-30">    <span class="color-blue">get</span> <span class="color-pink">email</span>() {</p>
                        <p class="ml-60">        <span class="color-blue">return this</span>.userForm.<span class="color-red">get</span>(<span class="color-green">'email'</span>);</p>
                        <p class="ml-30">    }</p>
                        <br />
                        <p class="ml-30">    <span class="color-red">onSubmit</span>() {</p>
                        <p class="ml-60">        <span class="color-blue">if</span> (this.userForm.valid) {</p>
                        <p class="ml-90">           <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">'Form Data:'</span>, this.userForm.value);</p>
                        <p class="ml-60">        } <span class="color-blue">else</span> {</p>
                        <p class="ml-90">            <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">'Form is invalid.'</span>);</p>
                        <p class="ml-60">        }</p>
                        <p class="ml-30">    }</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>

                <h6 className='mt-4'>How It Works</h6>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The form (<span class="background-grey">userForm</span>) is defined in the component using <span class="background-grey">FormBuilder</span>, which simplifies the creation of <span class="background-grey">FormGroup</span> and <span class="background-grey">FormControl</span> objects. Each control is associated with validation rules.</li>
                    <li>The <span class="background-grey">formGroup</span> directive binds the HTML form to the <span class="background-grey">userForm</span> object in the component.</li>
                    <li>When the form is submitted, the <span class="background-grey">onSubmit</span> method is called, and the form's data (<span class="background-grey">userForm.value</span>) is logged or processed.</li>
                    <li>Angular tracks the form and control states (<span class="background-grey">touched</span>, <span class="background-grey">invalid</span>, etc.) and updates the errors automatically.</li>
                    <li>Errors are displayed conditionally using <span class="background-grey">*ngIf</span> and the control’s <span class="background-grey">errors</span> property.</li>
                </ul>

            </div>
        </section>
    )
}