import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/jquery/events/keypress";

export default function Keypress() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("jQuery keypress() Method | Aspirant's Home");
        const urls = {
            'previous': '/jquery/events'
        }
        path.setPreviousNext(urls);
        path.setPreviousName('Back to Events');
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>jQuery keypress() Method</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">keypress()</span> method in jQuery triggers an event when a key is pressed down on an element. It’s generally used to capture character keys, such as letters and numbers, as they are typed, making it useful for handling input validation and implementing shortcuts. However, it's worth noting that <span class="background-grey">keypress()</span> has limited support for non-character keys (e.g., arrow keys, Backspace), so for those, the <span class="background-grey">keydown()</span> or <span class="background-grey">keyup()</span> methods are often preferred.
                </p>

                <p>It has a basic syntax -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(selector).<span class="color-red">keypress</span>(function);</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">selector</span> specifies the element(s) to attach the <span class="background-grey">keypress</span> event to.</li>
                    <li><span class="background-grey">function</span> is a callback function to execute when a key is pressed.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Example: Displaying Character Key Presses</h5>
                <p>Here’s an example that displays each character as it's typed in an input field.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">id</span>=<span class="color-green">"inputField"</span> <span class="color-pink">placeholder</span>=<span class="color-green">"Type here..."</span>&gt;</p>
                        <p>&lt;p&gt;Typed character: &lt;span <span class="color-pink">id</span>=<span class="color-green">"characterDisplay"</span>&gt;&lt;/span&gt;&lt;/p&gt;</p>
                        `
                    }}></div>
                </div>
                <h6>jQuery Code</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"#inputField"</span>).<span class="color-red">keypress</span>(<span class="color-blue">function</span>(event) {</p>
                        <p class="ml-30">    $(<span class="color-green">"#characterDisplay"</span>).<span class="color-red">text</span>(String.<span class="color-red">fromCharCode</span>(event.which));</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>When a key is pressed in <span class="background-grey">#inputField</span>, the <span class="background-grey">keypress</span> event captures it.</li>
                    <li>The <span class="background-grey">event.which</span> property gives the ASCII code of the key, which is converted to a character using <span class="background-grey">String.fromCharCode()</span> and displayed in <span class="background-grey">#characterDisplay</span>.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Example: Limiting Input to Numeric Characters</h5>
                <p>You can use <span class="background-grey">keypress()</span> to restrict input to only numeric characters.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">id</span>=<span class="color-green">"numberInput"</span> <span class="color-pink">placeholder</span>=<span class="color-green">"Enter numbers only"</span>&gt;</p>
                        <p>&lt;p <span class="color-pink">id</span>=<span class="color-green">"warningMessage"</span>&gt;&lt;/p&gt;</p>
                        `
                    }}></div>
                </div>
                <h6>jQuery Code</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"#numberInput"</span>).<span class="color-red">keypress</span>(<span class="color-blue">function</span>(event) {</p>
                        <p class="ml-30 color-grey">    // ASCII codes for 0-9 are 48-57</p>
                        <p class="ml-30">    <span class="color-blue">if</span> (event.which < <span class="color-pink">48</span> || event.which > <span class="color-pink">57</span>) {</p>
                        <p class="ml-60">        event.<span class="color-red">preventDefault</span>();</p>
                        <p class="ml-60">        $(<span class="color-green">"#warningMessage"</span>).<span class="color-red">text</span>(<span class="color-green">"Please enter numbers only."</span>);</p>
                        <p class="ml-30">    } <span class="color-blue">else</span> {</p>
                        <p class="ml-60">        $(<span class="color-green">"#warningMessage"</span>).<span class="color-red">text</span>(<span class="color-green">""</span>); <span class="color-grey">// Clear message on valid input</span></p>
                        <p class="ml-30">    }</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>Only numeric characters (ASCII 48–57) are allowed in <span class="background-grey">#numberInput</span>.</li>
                    <li>If a non-numeric character is typed, <span class="background-grey">event.preventDefault()</span> stops the input, and a warning message is shown.</li>
                </ul>



            </div>
        </section>
    )
}