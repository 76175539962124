import React, { useEffect, useState, useRef } from 'react'
import { Outlet, Link } from "react-router-dom";
import TopHeader from '../TopHeader';
import Footer from '../Footer'
import { MenuContext, TitleContext, PathContext } from "../../Context";
import { useContext } from "react";
import PreviousNext from '../PreviousNext';

export default function HTMLLayout() {

    const addspaceElement = useRef();
    const menuElement = useRef([]);
    const menu = useContext(MenuContext);
    const pathName = useContext(PathContext);
    const pathname = pathName.pathName;
    const [showSubMenu, setShowSubMenu] = useState(document.getElementsByClassName("activeSubMenu")[0] ? false : true)
    const title = useContext(TitleContext);

    const setPathName = (path) => {
        if(path)
            pathName.setPathName(path);
    }

    const OpenShowSubMenu = (e, path) => {
        //console.log(path, e.target);
        setPathName(path)
        e.currentTarget.nextSibling.style.display = e.currentTarget.nextSibling.style.display == 'block' ? 'none' : 'block';
    }

    useEffect(() => {
        setPathName(window.location.pathname)
        if (menu.isMenu == true) menu.setMenuOpen(false)
        addspaceElement.current.scrollIntoView(true)
        const element = document.getElementsByClassName("activeMenu")[0];
        element && element.scrollIntoView({ block: "center" });
        const subelement = document.getElementsByClassName("activeSubMenu")[0];
        subelement && subelement.scrollIntoView({ block: "center" });
        console.log(subelement)
        if (window.location.pathname != '/html/basic-tags') {
            if(subelement == undefined) setShowSubMenu(false)
        }
    }, [pathname,showSubMenu])

    return (
        <>
            <TopHeader />
            <div className='container-fluid'>
                <div className='row' style={{background:'#ddd'}}>
                    <div ref={menuElement} className={menu.isMenu == true ? 'col-md-3 col-lg-2 menuColumnMobile menuColumn p-0' : ' menuColumn p-0'} >
                        <div className='d-flex align-items-center headerMenu headerMainMenu sticky-top mb-4' style={{ 'height': '150px' }}>
                            <div>
                                <h2>HTML Tutorial</h2>
                                <Link to="/quiz/html" className='rounded-corner btn btn-primary float-end'><small>Get Quiz</small></Link>
                            </div>
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/html/introduction' ? 'activeMenu' : ''}>
                                    <Link to="/html/introduction" onClick={() => { setPathName('/html/introduction'); } }>Introduction</Link>
                                </li>
                                <li className={pathname == '/html/editors' ? 'activeMenu' : ''}>
                                    <Link to="/html/editors" onClick={() => setPathName('/html/editors')}>Editors</Link>
                                </li>
                                <li onClick={() => { setPathName('/html/basic-tags'); setShowSubMenu(!showSubMenu) }}>
                                    <Link to="/html/basic-tags">Basic Tags
                                    <span className='dropdown float-end'>
                                        <span className="btn btn-secondary dropdown-toggle dropdown-link-menu"></span>
                                    </span>
                                    </Link>
                                </li>
                                {
                                    showSubMenu == true ? 
                                        <>
                                            <li className={pathname == '/html/basic-tags' ? 'activeSubMenu' : 'background-white'}>
                                                <Link to="/html/basic-tags" onClick={() => setPathName('/html/basic-tags')}>
                                                    <span className='ml-30'>Basic Tags</span>
                                                </Link>
                                            </li>
                                            <li className={pathname == '/html/basic-tags/html' ? 'activeSubMenu' : 'background-white'}>
                                                <Link to="/html/basic-tags/html" onClick={() => setPathName('/html/basic-tags/html')}>
                                                    <span className='ml-30'>&lt;html&gt;</span>
                                                </Link>
                                            </li>
                                            <li className={pathname == '/html/basic-tags/head' ? 'activeSubMenu' : 'background-white'}>
                                                <Link to="/html/basic-tags/head" onClick={() => setPathName('/html/basic-tags/head')}>
                                                    <span className='ml-30'>&lt;head&gt;</span>
                                                </Link>
                                            </li>
                                            <li className={pathname == '/html/basic-tags/body' ? 'activeSubMenu' : 'background-white'}>
                                                <Link to="/html/basic-tags/body" onClick={() => setPathName('/html/basic-tags/body')}>
                                                    <span className='ml-30'>&lt;body&gt;</span>
                                                </Link>
                                            </li>
                                        </>
                                        : <></>
                                }
                                
                                <li className={pathname == '/html/elements' ? 'activeMenu' : ''}>
                                    <Link to="/html/elements" onClick={() => setPathName('/html/elemnets')}>Elements</Link>
                                </li>
                                <li className={pathname == '/html/attributes' ? 'activeMenu' : ''}>
                                    <Link to="/html/attributes" onClick={() => setPathName('/html/attributes')}>Attributes</Link>
                                </li>
                                <li className={pathname == '/html/headings' ? 'activeMenu' : ''}>
                                    <Link to="/html/headings" onClick={() => setPathName('/html/headings')}>Headings</Link>
                                </li>
                                <li className={pathname == '/html/paragraphs' ? 'activeMenu' : ''}>
                                    <Link to="/html/paragraphs" onClick={() => setPathName('/html/editors')}>Paragraphs</Link>
                                </li>
                                <li className={pathname == '/html/styles' ? 'activeMenu' : ''}>
                                    <Link to="/html/styles" onClick={() => setPathName('/html/styles')}>Styles</Link>
                                </li>
                                {/* <li className={pathname == '/html/fonts' ? 'activeMenu' : ''}>
                                    <Link to="/html/fonts" onClick={() => setPathName('/html/fonts')}>Fonts</Link>
                                </li> */}
                                <li className={pathname == '/html/blocks' ? 'activeMenu' : ''}>
                                    <Link to="/html/blocks" onClick={() => setPathName('/html/blocks')}>Blocks & Inline</Link>
                                </li>
                                <li className={pathname == '/html/formatting' ? 'activeMenu' : ''}>
                                    <Link to="/html/formatting" onClick={() => setPathName('/html/formatting')}>Formatting</Link>
                                </li>
                                <li className={pathname == '/html/quotations' ? 'activeMenu' : ''}>
                                    <Link to="/html/quotations" onClick={() => setPathName('/html/quotations')}>Quotations</Link>
                                </li>
                                <li className={pathname == '/html/comments' ? 'activeMenu' : ''}>
                                    <Link to="/html/comments" onClick={() => setPathName('/html/comments')}>Comments</Link>
                                </li>
                                {/* <li className={pathname == '/html/colors' ? 'activeMenu' : ''}>
                                    <Link to="/html/colors" onClick={() => setPathName('/html/colors')}>Colors</Link>
                                </li> */}
                                <li className={pathname == '/html/images' ? 'activeMenu' : ''}>
                                    <Link to="/html/images" onClick={() => setPathName('/html/images')}>Images</Link>
                                </li>
                                <li className={pathname == '/html/favicon' ? 'activeMenu' : ''}>
                                    <Link to="/html/favicon" onClick={() => setPathName('/html/favicon')}>Favicon</Link>
                                </li>
                                <li className={pathname == '/html/page-title' ? 'activeMenu' : ''}>
                                    <Link to="/html/page-title" onClick={() => setPathName('/html/page-title')}>Page Title</Link>
                                </li>
                                <li className={pathname == '/html/iframes' ? 'activeMenu' : ''}>
                                    <Link to="/html/iframes" onClick={() => setPathName('/html/iframes')}>Iframes</Link>
                                </li>
                                <li className={pathname == '/html/javascripts' ? 'activeMenu' : ''}>
                                    <Link to="/html/javascripts" onClick={() => setPathName('/html/javascripts')}>Javascripts</Link>
                                </li>
                                <li className={pathname == '/html/meta-tags' ? 'activeMenu' : ''}>
                                    <Link to="/html/meta-tags" onClick={() => setPathName('/html/meta-tags')}>Meta Tags</Link>
                                </li>
                                <li className={pathname == '/html/classes' ? 'activeMenu' : ''}>
                                    <Link to="/html/classes" onClick={() => setPathName('/html/classes')}>Classes</Link>
                                </li>
                                <li className={pathname == '/html/ids' ? 'activeMenu' : ''}>
                                    <Link to="/html/ids" onClick={() => setPathName('/html/ids')}>IDs</Link>
                                </li>
                                <li className={pathname == '/html/entities' ? 'activeMenu' : ''}>
                                    <Link to="/html/entities" onClick={() => setPathName('/html/entities')}>Entities</Link>
                                </li>
                                <li className={pathname == '/html/symbols' ? 'activeMenu' : ''}>
                                    <Link to="/html/symbols" onClick={() => setPathName('/html/symbols')}>Symbols</Link>
                                </li>
                                <li className={pathname == '/html/emojis' ? 'activeMenu' : ''}>
                                    <Link to="/html/emojis" onClick={() => setPathName('/html/emojis')}>Emojis</Link>
                                </li>
                                <li className={pathname == '/html/layouts' ? 'activeMenu' : ''}>
                                    <Link to="/html/layouts" onClick={() => setPathName('/html/layouts')}>Layout</Link>
                                </li>
                                <li className={pathname == '/html/semantics' ? 'activeMenu' : ''}>
                                    <Link to="/html/semantics" onClick={() => setPathName('/html/semantics')}>Semantics Element</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            HTML Tables
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/html/tables' ? 'activeMenu' : ''}>
                                    <Link to="/html/tables" onClick={() => setPathName('/html/tables')}>Tables</Link>
                                </li>
                                <li className={pathname == '/html/table-styling' ? 'activeMenu' : ''}>
                                    <Link to="/html/table-styling" onClick={() => setPathName('/html/table-styling')}>Table Styling</Link>
                                </li>
                                <li className={pathname == '/html/table-colgroup' ? 'activeMenu' : ''}>
                                    <Link to="/html/table-colgroup" onClick={() => setPathName('/html/table-colgroup')}>Table Colgroup</Link>
                                </li>
                                <li className={pathname == '/html/table-colspan-rowspan' ? 'activeMenu' : ''}>
                                    <Link to="/html/table-colspan-rowspan" onClick={() => setPathName('/html/table-colspan-rowspan')}>Colspan & Rowspan</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            HTML Lists
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/html/lists' ? 'activeMenu' : ''}>
                                    <Link to="/html/lists" onClick={() => setPathName('/html/lists')}>Lists</Link>
                                </li>
                                <li className={pathname == '/html/unordered-lists' ? 'activeMenu' : ''}>
                                    <Link to="/html/unordered-lists" onClick={() => setPathName('/html/unordered-lists')}>Unordered Lists</Link>
                                </li>
                                <li className={pathname == '/html/ordered-lists' ? 'activeMenu' : ''}>
                                    <Link to="/html/ordered-lists" onClick={() => setPathName('/html/ordered-lists')}>Ordered Lists</Link>
                                </li>
                                <li className={pathname == '/html/definition-lists' ? 'activeMenu' : ''}>
                                    <Link to="/html/definition-lists" onClick={() => setPathName('/html/definition-lists')}>Definition Lists</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            HTML Links
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/html/text-links' ? 'activeMenu' : ''}>
                                    <Link to="/html/text-links" onClick={() => setPathName('/html/text-links')}>Text Links</Link>
                                </li>
                                <li className={pathname == '/html/customize-links' ? 'activeMenu' : ''}>
                                    <Link to="/html/customize-links" onClick={() => setPathName('/html/customize-links')}>Customize Links</Link>
                                </li>
                                <li className={pathname == '/html/create-bookmark' ? 'activeMenu' : ''}>
                                    <Link to="/html/create-bookmark" onClick={() => setPathName('/html/create-bookmark')}>Create Bookmark</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            HTML Forms
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/html/forms' ? 'activeMenu' : ''}>
                                    <Link to="/html/forms" onClick={() => setPathName('/html/forms')}>Forms</Link>
                                </li>
                                <li className={pathname == '/html/form-attributes' ? 'activeMenu' : ''}>
                                    <Link to="/html/form-attributes" onClick={() => setPathName('/html/form-attributes')}>Form Attributes</Link>
                                </li>
                                <li className={pathname == '/html/form-elements' ? 'activeMenu' : ''}>
                                    <Link to="/html/form-elements" onClick={() => setPathName('/html/form-elements')}>Form Elements</Link>
                                </li>
                                <li className={pathname == '/html/inputs' ? 'activeMenu' : ''}>
                                    <Link to="/html/inputs" onClick={() => setPathName('/html/inputs')}>Inputs</Link>
                                </li>
                                <li className={pathname == '/html/input-attributes' ? 'activeMenu' : ''}>
                                    <Link to="/html/input-attributes" onClick={() => setPathName('/html/input-attributes')}>Input Attributes</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            HTML Media
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/html/video-element' ? 'activeMenu' : ''}>
                                    <Link to="/html/video-element" onClick={() => setPathName('/html/video-element')}>Video Element</Link>
                                </li>
                                <li className={pathname == '/html/audio-element' ? 'activeMenu' : ''}>
                                    <Link to="/html/audio-element" onClick={() => setPathName('/html/audio-element')}>Audio Element</Link>
                                </li>
                                <li className={pathname == '/html/embed-multimedia' ? 'activeMenu' : ''}>
                                    <Link to="/html/embed-multimedia" onClick={() => setPathName('/html/embed-multimedia')}>Embed Multimedia</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            HTML Graphics
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/html/svg' ? 'activeMenu' : ''}>
                                    <Link to="/html/svg" onClick={() => setPathName('/html/svg')}>SVG</Link>
                                </li>
                                <li className={pathname == '/html/canvas' ? 'activeMenu' : ''}>
                                    <Link to="/html/canvas" onClick={() => setPathName('/html/canvas')}>Canvas</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            HTML APIs
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/html/geolocation' ? 'activeMenu' : ''}>
                                    <Link to="/html/geolocation" onClick={() => setPathName('/html/geolocation')}>Geolocation</Link>
                                </li>
                                <li className={pathname == '/html/drag-drop' ? 'activeMenu' : ''}>
                                    <Link to="/html/drag-drop" onClick={() => setPathName('/html/drag-drop')}>Drag/Drop</Link>
                                </li>
                                <li className={pathname == '/html/web-storage' ? 'activeMenu' : ''}>
                                    <Link to="/html/web-storage" onClick={() => setPathName('/html/web-storage')}>Web Storage</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            HTML References
                        </div>
                        <nav>
                            <ul>
                                <li className='subMenuList'>
                                    <Link to="/html/events" onClick={(e) => OpenShowSubMenu(e, '/html/events')}>Events
                                        <span className='dropdown float-end'>
                                            <span className="btn btn-secondary dropdown-toggle dropdown-link-menu"></span>
                                        </span>
                                    </Link>
                                    <ul style={{ 'display': (pathname == '/html/events' || pathname == '/html/events/onclick' || pathname == '/html/events/ondblclick' || pathname == '/html/events/onmousedown' || pathname == '/html/events/onmouseup' || pathname == '/html/events/onmouseover' || pathname == '/html/events/onmouseout' || pathname == '/html/events/onmousemove' || pathname == '/html/events/onkeydown' || pathname == '/html/events/onkeypress' || pathname == '/html/events/onkeyup' || pathname == '/html/events/onsubmit' || pathname == '/html/events/onchange' || pathname == '/html/events/onfocus' || pathname == '/html/events/onblur' || pathname == '/html/events/oninput' || pathname == '/html/events/onload' || pathname == '/html/events/onunload' || pathname == '/html/events/onresize' || pathname == '/html/events/onscroll' || pathname == '/html/events/oncopy' || pathname == '/html/events/oncut' || pathname == '/html/events/onpaste' || pathname == '/html/events/onplay' || pathname == '/html/events/onpause' || pathname == '/html/events/onvolumechange' || pathname == '/html/events/onended' || pathname == '/html/events/ondrag' || pathname == '/html/events/ondrop' || pathname == '/html/events/ondragover' || pathname == '/html/events/ontouchstart' || pathname == '/html/events/ontouchmove' || pathname == '/html/events/ontouchend') ? 'block' : 'none' }}>
                                        <li className={pathname == '/html/events' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/events" onClick={() => setPathName('/html/events')}>
                                                <span className='ml-30'>Events</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/events/onclick' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/events/onclick" onClick={() => setPathName('/html/events/onclick')}>
                                                <span className='ml-30'>onclick</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/events/ondblclick' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/events/ondblclick" onClick={() => setPathName('/html/events/ondblclick')}>
                                                <span className='ml-30'>ondblclick</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/events/onmousedown' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/events/onmousedown" onClick={() => setPathName('/html/events/onmousedown')}>
                                                <span className='ml-30'>onmousedown</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/events/onmouseup' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/events/onmouseup" onClick={() => setPathName('/html/events/onmouseup')}>
                                                <span className='ml-30'>onmouseup</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/events/onmouseover' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/events/onmouseover" onClick={() => setPathName('/html/events/onmouseover')}>
                                                <span className='ml-30'>onmouseover</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/events/onmouseout' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/events/onmouseout" onClick={() => setPathName('/html/events/onmouseout')}>
                                                <span className='ml-30'>onmouseout</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/events/onmousemove' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/events/onmousemove" onClick={() => setPathName('/html/events/onmousemove')}>
                                                <span className='ml-30'>onmousemove</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/events/onkeydown' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/events/onkeydown" onClick={() => setPathName('/html/events/onkeydown')}>
                                                <span className='ml-30'>onkeydown</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/events/onkeypress' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/events/onkeypress" onClick={() => setPathName('/html/events/onkeypress')}>
                                                <span className='ml-30'>onkeypress</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/events/onkeyup' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/events/onkeyup" onClick={() => setPathName('/html/events/onkeyup')}>
                                                <span className='ml-30'>onkeyup</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/events/onsubmit' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/events/onsubmit" onClick={() => setPathName('/html/events/onsubmit')}>
                                                <span className='ml-30'>onsubmit</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/events/onfocus' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/events/onfocus" onClick={() => setPathName('/html/events/onfocus')}>
                                                <span className='ml-30'>onfocus</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/events/onblur' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/events/onblur" onClick={() => setPathName('/html/events/onblur')}>
                                                <span className='ml-30'>onblur</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/events/onchange' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/events/onchange" onClick={() => setPathName('/html/events/onchange')}>
                                                <span className='ml-30'>onchange</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/events/oninput' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/events/oninput" onClick={() => setPathName('/html/events/oninput')}>
                                                <span className='ml-30'>oninput</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/events/onload' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/events/onload" onClick={() => setPathName('/html/events/onload')}>
                                                <span className='ml-30'>onload</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/events/onunload' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/events/onunload" onClick={() => setPathName('/html/events/onunload')}>
                                                <span className='ml-30'>onunload</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/events/onresize' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/events/onresize" onClick={() => setPathName('/html/events/onresize')}>
                                                <span className='ml-30'>onresize</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/events/onscroll' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/events/onscroll" onClick={() => setPathName('/html/events/onscroll')}>
                                                <span className='ml-30'>onscroll</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/events/oncopy' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/events/oncopy" onClick={() => setPathName('/html/events/oncopy')}>
                                                <span className='ml-30'>oncopy</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/events/oncut' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/events/oncut" onClick={() => setPathName('/html/events/oncut')}>
                                                <span className='ml-30'>oncut</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/events/onpaste' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/events/onpaste" onClick={() => setPathName('/html/events/onpaste')}>
                                                <span className='ml-30'>onpaste</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/events/onplay' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/events/onplay" onClick={() => setPathName('/html/events/onplay')}>
                                                <span className='ml-30'>onplay</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/events/onpause' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/events/onpause" onClick={() => setPathName('/html/events/onpause')}>
                                                <span className='ml-30'>onpause</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/events/onvolumechange' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/events/onvolumechange" onClick={() => setPathName('/html/events/onvolumechange')}>
                                                <span className='ml-30'>onvolumechange</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/events/onended' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/events/onended" onClick={() => setPathName('/html/events/onended')}>
                                                <span className='ml-30'>onended</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/events/ondrag' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/events/ondrag" onClick={() => setPathName('/html/events/ondrag')}>
                                                <span className='ml-30'>ondrag</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/events/ondrop' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/events/ondrop" onClick={() => setPathName('/html/events/ondrop')}>
                                                <span className='ml-30'>ondrop</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/events/ondragover' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/events/ondragover" onClick={() => setPathName('/html/events/ondragover')}>
                                                <span className='ml-30'>ondragover</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/events/ontouchstart' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/events/ontouchstart" onClick={() => setPathName('/html/events/ontouchstart')}>
                                                <span className='ml-30'>ontouchstart</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/events/ontouchmove' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/events/ontouchmove" onClick={() => setPathName('/html/events/ontouchmove')}>
                                                <span className='ml-30'>ontouchmove</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/events/ontouchend' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/events/ontouchend" onClick={() => setPathName('/html/events/ontouchend')}>
                                                <span className='ml-30'>ontouchend</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className='subMenuList'>
                                    <Link to="/html/tag-list" onClick={(e) => OpenShowSubMenu(e, '/html/tag-list')}>Tags List
                                        <span className='dropdown float-end'>
                                            <span className="btn btn-secondary dropdown-toggle dropdown-link-menu"></span>
                                        </span>
                                    </Link>
                                    <ul style={{ 'display': (pathname == '/html/tag-list' || pathname == '/html/tag/details' || pathname == '/html/tag/summary' || pathname == '/html/tag/dialog' || pathname == '/html/tag/progress' || pathname == '/html/tag/meter' || pathname == '/html/tag/header' || pathname == '/html/tag/nav' || pathname == '/html/tag/section' || pathname == '/html/tag/article' || pathname == '/html/tag/aside' || pathname == '/html/tag/footer' || pathname == '/html/tag/main' || pathname == '/html/tag/canvas' || pathname == '/html/tag/svg' || pathname == '/html/tag/script' || pathname == '/html/tag/noscript' || pathname == '/html/tag/doctype-html' || pathname == '/html/tag/html' || pathname == '/html/tag/head' || pathname == '/html/tag/title' || pathname == '/html/tag/body' || pathname == '/html/tag/meta' || pathname == '/html/tag/link' || pathname == '/html/tag/headings' || pathname == '/html/tag/p' || pathname == '/html/tag/br' || pathname == '/html/tag/hr' || pathname == '/html/tag/blockquote' || pathname == '/html/tag/pre' || pathname == '/html/tag/b' || pathname == '/html/tag/i' || pathname == '/html/tag/u' || pathname == '/html/tag/strong' || pathname == '/html/tag/em' || pathname == '/html/tag/mark' || pathname == '/html/tag/small'  || pathname == '/html/tag/sup' || pathname == '/html/tag/sub' || pathname == '/html/tag/del' || pathname == '/html/tag/ins' || pathname == '/html/tag/li' || pathname == '/html/tag/tr' || pathname == '/html/tag/td' || pathname == '/html/tag/th' || pathname == '/html/tag/input' || pathname == '/html/tag/textarea' || pathname == '/html/tag/button' || pathname == '/html/tag/select' || pathname == '/html/tag/option' || pathname == '/html/tag/label') ? 'block' : 'none' }}>

                                        <li className={pathname == '/html/tag-list' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/tag-list" onClick={() => setPathName('/html/tag-list')}>
                                                <span className='ml-30'>Tags</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/tag/doctype-html' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/tag/doctype-html" onClick={() => setPathName('/html/tag/doctype-html')}>
                                                <span className='ml-30'>&lt;!DOCTYPE html&gt;</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/tag/html' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/tag/html" onClick={() => setPathName('/html/tag/html')}>
                                                <span className='ml-30'>&lt;html&gt;</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/tag/head' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/tag/head" onClick={() => setPathName('/html/tag/head')}>
                                                <span className='ml-30'>&lt;head&gt;</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/tag/title' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/tag/title" onClick={() => setPathName('/html/tag/title')}>
                                                <span className='ml-30'>&lt;title&gt;</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/tag/body' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/tag/body" onClick={() => setPathName('/html/tag/body')}>
                                                <span className='ml-30'>&lt;body&gt;</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/tag/meta' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/tag/meta" onClick={() => setPathName('/html/tag/meta')}>
                                                <span className='ml-30'>&lt;meta&gt;</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/tag/link' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/tag/link" onClick={() => setPathName('/html/tag/link')}>
                                                <span className='ml-30'>&lt;link&gt;</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/tag/headings' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/tag/headings" onClick={() => setPathName('/html/tag/headings')}>
                                                <span className='ml-30'>&lt;h1&gt; - &lt;h6&gt;</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/tag/p' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/tag/p" onClick={() => setPathName('/html/tag/p')}>
                                                <span className='ml-30'>&lt;p&gt;</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/tag/br' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/tag/br" onClick={() => setPathName('/html/tag/br')}>
                                                <span className='ml-30'>&lt;br&gt;</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/tag/hr' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/tag/hr" onClick={() => setPathName('/html/tag/hr')}>
                                                <span className='ml-30'>&lt;hr&gt;</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/tag/blockquote' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/tag/blockquote" onClick={() => setPathName('/html/tag/blockquote')}>
                                                <span className='ml-30'>&lt;blockquote&gt;</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/tag/pre' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/tag/pre" onClick={() => setPathName('/html/tag/pre')}>
                                                <span className='ml-30'>&lt;pre&gt;</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/tag/b' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/tag/b" onClick={() => setPathName('/html/tag/b')}>
                                                <span className='ml-30'>&lt;b&gt;</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/tag/i' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/tag/i" onClick={() => setPathName('/html/tag/i')}>
                                                <span className='ml-30'>&lt;i&gt;</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/tag/u' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/tag/u" onClick={() => setPathName('/html/tag/u')}>
                                                <span className='ml-30'>&lt;u&gt;</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/tag/strong' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/tag/strong" onClick={() => setPathName('/html/tag/strong')}>
                                                <span className='ml-30'>&lt;strong&gt;</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/tag/em' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/tag/em" onClick={() => setPathName('/html/tag/em')}>
                                                <span className='ml-30'>&lt;em&gt;</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/tag/mark' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/tag/mark" onClick={() => setPathName('/html/tag/mark')}>
                                                <span className='ml-30'>&lt;mark&gt;</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/tag/small' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/tag/small" onClick={() => setPathName('/html/tag/small')}>
                                                <span className='ml-30'>&lt;small&gt;</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/tag/sup' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/tag/sup" onClick={() => setPathName('/html/tag/sup')}>
                                                <span className='ml-30'>&lt;sup&gt;</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/tag/sub' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/tag/sub" onClick={() => setPathName('/html/tag/sub')}>
                                                <span className='ml-30'>&lt;sub&gt;</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/tag/del' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/tag/del" onClick={() => setPathName('/html/tag/del')}>
                                                <span className='ml-30'>&lt;del&gt;</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/tag/ins' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/tag/ins" onClick={() => setPathName('/html/tag/ins')}>
                                                <span className='ml-30'>&lt;ins&gt;</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/tag/li' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/tag/li" onClick={() => setPathName('/html/tag/li')}>
                                                <span className='ml-30'>&lt;li&gt;</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/tag/tr' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/tag/tr" onClick={() => setPathName('/html/tag/tr')}>
                                                <span className='ml-30'>&lt;tr&gt;</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/tag/td' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/tag/td" onClick={() => setPathName('/html/tag/td')}>
                                                <span className='ml-30'>&lt;td&gt;</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/tag/th' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/tag/th" onClick={() => setPathName('/html/tag/th')}>
                                                <span className='ml-30'>&lt;th&gt;</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/tag/input' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/tag/input" onClick={() => setPathName('/html/tag/input')}>
                                                <span className='ml-30'>&lt;input&gt;</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/tag/textarea' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/tag/textarea" onClick={() => setPathName('/html/tag/textarea')}>
                                                <span className='ml-30'>&lt;textarea&gt;</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/tag/button' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/tag/button" onClick={() => setPathName('/html/tag/button')}>
                                                <span className='ml-30'>&lt;button&gt;</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/tag/select' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/tag/select" onClick={() => setPathName('/html/tag/select')}>
                                                <span className='ml-30'>&lt;select&gt;</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/tag/label' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/tag/label" onClick={() => setPathName('/html/tag/label')}>
                                                <span className='ml-30'>&lt;label&gt;</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/tag/header' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/tag/header" onClick={() => setPathName('/html/tag/header')}>
                                                <span className='ml-30'>&lt;header&gt;</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/tag/nav' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/tag/nav" onClick={() => setPathName('/html/tag/nav')}>
                                                <span className='ml-30'>&lt;nav&gt;</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/tag/section' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/tag/section" onClick={() => setPathName('/html/tag/section')}>
                                                <span className='ml-30'>&lt;section&gt;</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/tag/article' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/tag/article" onClick={() => setPathName('/html/tag/article')}>
                                                <span className='ml-30'>&lt;article&gt;</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/tag/aside' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/tag/aside" onClick={() => setPathName('/html/tag/aside')}>
                                                <span className='ml-30'>&lt;aside&gt;</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/tag/footer' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/tag/footer" onClick={() => setPathName('/html/tag/footer')}>
                                                <span className='ml-30'>&lt;footer&gt;</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/tag/main' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/tag/main" onClick={() => setPathName('/html/tag/main')}>
                                                <span className='ml-30'>&lt;main&gt;</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/tag/script' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/tag/script" onClick={() => setPathName('/html/tag/script')}>
                                                <span className='ml-30'>&lt;script&gt;</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/tag/noscript' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/tag/noscript" onClick={() => setPathName('/html/tag/noscript')}>
                                                <span className='ml-30'>&lt;noscript&gt;</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/tag/details' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/tag/details" onClick={() => setPathName('/html/tag/details')}>
                                                <span className='ml-30'>&lt;details&gt;</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/tag/summary' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/tag/summary" onClick={() => setPathName('/html/tag/summary')}>
                                                <span className='ml-30'>&lt;summary&gt;</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/tag/dialog' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/tag/dialog" onClick={() => setPathName('/html/tag/dialog')}>
                                                <span className='ml-30'>&lt;dialog&gt;</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/tag/progress' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/tag/progress" onClick={() => setPathName('/html/tag/progress')}>
                                                <span className='ml-30'>&lt;progress&gt;</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/html/tag/meter' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/html/tag/meter" onClick={() => setPathName('/html/tag/meter')}>
                                                <span className='ml-30'>&lt;meter&gt;</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div className='col-12 middlePart'>
                        
                        <div className='headerAdSpace' ref={addspaceElement}>
                            <img className='img-fluid' src='https://aspirantshome.com/slide1.png' />
                        </div>
                        <div className='contentPart row sticky-top'>
                            <div className='col-12 col-md-12 mb-4 contentSection'>
                                <div className='container'>
                                    <div className='contentPage'>
                                        {
                                            pathName.urls ? <PreviousNext urls={ pathName.urls } name={pathName.backname} /> : <></>
                                        }
                                            <Outlet />
                                        {
                                            pathName.urls ? <PreviousNext urls={ pathName.urls } /> : <></>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='footerAdSpace'>
                                <img className='img-fluid' src='https://aspirantshome.com/slide4.png' />
                            </div>
                        </div>

                        
                    </div>
                    <div className='col-12  rightPart'>
                        <img className='img-fluid' src='https://aspirantshome.com/slide2.png' />
                        <img className='mt-5 img-fluid' src='https://aspirantshome.com/slide3.png' />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <div className='container-fluid footerPart'>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}