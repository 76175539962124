import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/dates";

export default function DateDefinition() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Dates in Javascript | Aspirant's Home");
        const urls = {
            'previous': '/javascript/array-iteration',
            'next': '/javascript/date-formats'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Dates</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <strong>Date</strong> object in JavaScript is used to work with <strong>dates and times</strong>. It lets you get the current date and time, create specific dates, and perform operations like adding or comparing dates.
                </p>

                <h5 className='mt-5 mb-3'>Why Use the Date Object?</h5>
                <p>JavaScript's Date object is helpful for:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>Displaying the current date and time.</li>
                    <li>Formatting dates for user interfaces.</li>
                    <li>Calculating differences between dates (e.g., days between two events).</li>
                    <li>Creating date-based functionality like countdowns or reminders.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Creating a Date Object</h5>
                <p>The Date object can be created using the <span className='background-grey'>new Date()</span> constructor.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> currentDate = <span class="color-blue">new</span> <span class="color-red">Date</span>();</p>
                        <p><span class="color-pink">console</span>.<span class="color-red">log</span>(currentDate); <span class="color-grey">// Output: Current date and time, e.g., "2024-11-29T10:15:30.000Z"</span></p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-3'>Ways to Create a Date</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>Default Constructor</strong> (<span className='background-grey'>new Date()</span>):
                        <p>It creates a Date object with the <strong>current date and time</strong>.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">let</span> now = <span class="color-blue">new</span> <span class="color-red">Date</span>();</p>
                                    <p><span class="color-pink">console</span>.<span class="color-red">log</span>(now); <span class="color-grey">// Example: "2024-11-29T10:00:00.000Z"</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Specific Date and Time</strong> (<span className='background-grey'>new Date(year, month, day, hours, minutes, seconds, milliseconds)</span>):
                        <p>It creates a Date object for a specific date and time</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> specificDate = <span class="color-blue">new</span> <span class="color-red">Date</span>(<span class="color-pink">2024</span>, <span class="color-pink">10</span>, <span class="color-pink">29</span>, <span class="color-pink">10</span>, <span class="color-pink">30</span>, <span class="color-pink">0</span>); <span class="color-grey">// November 29, 2024, 10:30:00</span></p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(specificDate);</p>
                                `
                            }}></div>
                        </div>
                    </li>

                    <li>
                        <strong>From a Date String</strong> (<span className='background-grey'>new Date(dateString)</span>):
                        <p>It creates a Date object from a date string.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> dateString = <span class="color-blue">new</span> <span class="color-red">Date</span>(<span class="color-green">"2024-11-29"</span>);</p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(dateString); <span class="color-grey">// Output: "2024-11-29T00:00:00.000Z"</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>From a Timestamp</strong> (<span className='background-grey'>new Date(milliseconds)</span>):
                        <p>It creates a Date object using milliseconds since January 1, 1970.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">let</span> timestamp = <span class="color-blue">new</span> <span class="color-red">Date</span>(<span class="color-pink">0</span>); <span class="color-grey">// Start of Unix time (Epoch time)</span></p>
                                    <p><span class="color-pink">console</span>.<span class="color-red">log</span>(timestamp); <span class="color-grey">// Output: "1970-01-01T00:00:00.000Z"</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>


            </div>
        </section>
    )
}