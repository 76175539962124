import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/array-search";

export default function ArraySearch() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Array Search in Javascript | Aspirant's Home");
        const urls = {
            'previous': '/javascript/array-methods',
            'next': '/javascript/array-sort'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Array Search</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Searching in arrays means finding a specific value or checking if it exists within the array. JavaScript provides several methods to search for elements in an array efficiently.
                </p>


                <h5 className='mt-5 mb-3'>Some Array Search Methods</h5>
                <p>Here are some of the most important array search methods:</p>

                <ul style={{ listStyle: 'decimal' }}>
                    <li className='operator mt-5'>
                        <h5><span className='background-grey'>indexOf()</span> – <strong>Find the Index of a Value</strong></h5>
                        <p>The <span className='background-grey'>indexOf()</span> method searches for the <strong>first occurrence</strong> of a value in an array. It returns the <strong>index</strong> of the value if found, or <span className='background-grey'>-1</span> if the value does not exist.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> fruits = [<span class="color-green">"Apple"</span>, <span class="color-green">"Banana"</span>, <span class="color-green">"Cherry"</span>, <span class="color-green">"Banana"</span>];</p>
                                <br />
                                <p><span class="color-blue">let</span> index = fruits.<span class="color-red">indexOf</span>(<span class="color-green">"Banana"</span>);</p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(index); <span class="color-grey">// Output: 1</span></p>
                                <br />
                                <p><span class="color-blue">let</span> notFound = fruits.<span class="color-red">indexOf</span>(<span class="color-green">"Grapes"</span>);</p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(notFound); <span class="color-grey">// Output: -1</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator mt-5'>
                        <h5><span className='background-grey'>lastIndexOf()</span> – <strong>Find the Last Occurrence of a Value</strong></h5>
                        <p>The <span className='background-grey'>lastIndexOf()</span> method searches for the <strong>last occurrence</strong> of a value in an array. It also returns the <strong>index</strong> or <span className='background-grey'>-1</span> if the value is not found.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> fruits = [<span class="color-green">"Apple"</span>, <span class="color-green">"Banana"</span>, <span class="color-green">"Cherry"</span>, <span class="color-green">"Banana"</span>];</p>
                                <br />
                                <p><span class="color-blue">let</span> lastIndex = fruits.<span class="color-red">lastIndexOf</span>(<span class="color-green">"Banana"</span>);</p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(lastIndex); <span class="color-grey">// Output: 3</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator mt-5'>
                        <h5><span className='background-grey'>includes()</span> – <strong>Check if an Element Exists</strong></h5>
                        <p>The <span className='background-grey'>includes()</span> method checks if an array contains a specific element. It returns <span className='background-grey'>true</span> if the element is found, and <span className='background-grey'>false</span> otherwise.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> fruits = [<span class="color-green">"Apple"</span>, <span class="color-green">"Banana"</span>, <span class="color-green">"Cherry"</span>];</p>
                                <p><span class="color-blue">let</span> hasBanana = fruits.<span class="color-red">includes</span>(<span class="color-green">"Banana"</span>);</p>
                                <br />
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(hasBanana); <span class="color-grey">// Output: true</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator mt-5'>
                        <h5><span className='background-grey'>find()</span> – <strong>Find the First Element That Matches a Condition</strong></h5>
                        <p>The <span className='background-grey'>find()</span> method returns the <strong>first element</strong> in the array that satisfies a given condition. If no element matches, it returns <span className='background-grey'>undefined</span>.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">let</span> numbers = [<span class="color-pink">5</span>, <span class="color-pink">12</span>, <span class="color-pink">8</span>, <span class="color-pink">130</span>, <span class="color-pink">44</span>];</p>
                                    <br />
                                    <p><span class="color-blue">let</span> firstLargeNumber = numbers.<span class="color-red">find</span>(num => num > <span class="color-pink">10</span>);</p>
                                    <p><span class="color-pink">console</span>.<span class="color-red">log</span>(firstLargeNumber); <span class="color-grey">// Output: 12</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator mt-5'>
                        <h5><span className='background-grey'>findIndex()</span> – <strong>Find the Index of the First Element That Matches a Condition</strong></h5>
                        <p>The <span className='background-grey'>findIndex()</span> method is similar to <span className='background-grey'>find()</span>, but instead of returning the value, it returns the <strong>index</strong> of the first matching element. If no element matches, it returns <span className='background-grey'>-1</span>.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> numbers = [<span class="color-pink">5</span>, <span class="color-pink">12</span>, <span class="color-pink">8</span>, <span class="color-pink">130</span>, <span class="color-pink">44</span>];</p>
                                <br />
                                <p><span class="color-blue">let</span> firstLargeIndex = numbers.<span class="color-red">findIndex</span>(num => num > <span class="color-pink">10</span>);</p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(firstLargeIndex); <span class="color-grey">// Output: 1</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator mt-5'>
                        <h5><span className='background-grey'>filter()</span> – <strong>Get All Elements That Match a Condition</strong></h5>
                        <p>The <span className='background-grey'>filter()</span> method returns a <strong>new array</strong> containing all elements that match a specified condition.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> numbers = [<span class="color-pink">5</span>, <span class="color-pink">12</span>, <span class="color-pink">8</span>, <span class="color-pink">130</span>, <span class="color-pink">44</span>];</p>
                                <br />
                                <p><span class="color-blue">let</span> largeNumbers = numbers.<span class="color-red">filter</span>(num => num > <span class="color-pink">10</span>);</p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(largeNumbers); <span class="color-grey">// Output: [12, 130, 44]</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator mt-5'>
                        <h5><span className='background-grey'>some()</span> – <strong>Check if Any Element Matches a Condition</strong></h5>
                        <p>The <span className='background-grey'>some()</span> method checks if <strong>at least one element</strong> in the array satisfies a condition. It returns <span className='background-grey'>true</span> or <span className='background-grey'>false</span>.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> numbers = [<span class="color-pink">5</span>, <span class="color-pink">12</span>, <span class="color-pink">8</span>, <span class="color-pink">130</span>, <span class="color-pink">44</span>];</p>
                                <br />
                                <p><span class="color-blue">let</span> hasLargeNumbers = numbers.<span class="color-red">some</span>(num => num > <span class="color-pink">100</span>);</p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(hasLargeNumbers); <span class="color-grey">// Output: true</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator mt-5'>
                        <h5><span className='background-grey'>every()</span> – <strong>Check if All Elements Match a Condition</strong></h5>
                        <p>The <span className='background-grey'>every()</span> method checks if <strong>all elements</strong> in the array satisfy a condition. It returns <span className='background-grey'>true</span> only if every element matches; otherwise, it returns <span className='background-grey'>false</span>.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> numbers = [<span class="color-pink">5</span>, <span class="color-pink">12</span>, <span class="color-pink">8</span>, <span class="color-pink">130</span>, <span class="color-pink">44</span>];</p>
                                <br />
                                <p><span class="color-blue">let</span> allLarge = numbers.<span class="color-red">every</span>(num => num > <span class="color-pink">10</span>);</p>
                                <p><span class="color-pink">console</span>.<span class="color-red">log</span>(allLarge); <span class="color-grey">// Output: false</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>



            </div>
        </section>
    )
}