import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/jquery/events/mouseleave";

export default function Mouseleave() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("jQuery mouseleave() Method | Aspirant's Home");
        const urls = {
            'previous': '/jquery/events'
        }
        path.setPreviousNext(urls);
        path.setPreviousName('Back to Events');
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>jQuery mouseleave() Method</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">mouseleave()</span> method in jQuery triggers an event when the mouse pointer leaves the boundary of a selected element. It’s commonly used to revert changes made during a mouseenter event, hide tooltips, or stop animations when the user moves their cursor away from an element.
                </p>

                <p>It has a basic syntax -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(selector).<span class="color-red">mouseleave</span>(function);</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">selector</span> specifies the element(s) to attach the <span class="background-grey">mouseleave</span> event to.</li>
                    <li><span class="background-grey">function</span> is a callback function to execute when the mouse leaves the element.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Example: Changing Background Color on <span class="background-grey">mouseleave</span></h5>
                <p>This example demonstrates how to change the background color of a box back to its original color when the mouse leaves.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;div <span class="color-pink">id</span>=<span class="color-green">"hoverBox"</span> <span class="color-pink">style</span>=<span class="color-green">"width: 150px; height: 100px; background-color: lightblue;"</span>&gt;</p>
                        <p class="ml-30">    Hover over me!</p>
                        <p>&lt;/div&gt;</p>
                        `
                    }}></div>
                </div>
                <h6>jQuery Code</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"#hoverBox"</span>).<span class="color-red">mouseenter</span>(<span class="color-blue">function</span>() {</p>
                        <p class="ml-30">    $(this).<span class="color-red">css</span>(<span class="color-green">"background-color"</span>, <span class="color-green">"lightgreen"</span>);</p>
                        <p>}).<span class="color-red">mouseleave</span>(<span class="color-blue">function</span>() {</p>
                        <p class="ml-30">    $(this).<span class="color-red">css</span>(<span class="color-green">"background-color"</span>, <span class="color-green">"lightblue"</span>);</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span class="background-grey">mouseenter()</span> event changes the background color to light green when the mouse enters the box.</li>
                    <li>The <span class="background-grey">mouseleave()</span> event restores the original light blue color when the mouse leaves.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Example: Hiding Tooltips on <span class="background-grey">mouseleave</span></h5>
                <p>You can use the <span class="background-grey">mouseleave</span> method to hide tooltips or additional information when the mouse leaves an element.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;div <span class="color-pink">id</span>=<span class="color-green">"infoBox"</span> <span class="color-pink">style</span>=<span class="color-green">"width: 150px; height: 100px; background-color: coral;"</span>&gt;</p>
                        <p class="ml-30">    Hover to see info!</p>
                        <p>&lt;/div&gt;</p>
                        <p>&lt;p <span class="color-pink">id</span>=<span class="color-green">"tooltip"</span> <span class="color-pink">style</span>=<span class="color-green">"display: none;"</span>&gt;This is extra information!&lt;/p&gt;</p>
                        `
                    }}></div>
                </div>
                <h6>jQuery Code</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"#infoBox"</span>).<span class="color-red">mouseenter</span>(<span class="color-blue">function</span>() {</p>
                        <p class="ml-30">    $(<span class="color-green">"#tooltip"</span>).<span class="color-red">show</span>();</p>
                        <p>}).<span class="color-red">mouseleave</span>(<span class="color-blue">function</span>() {</p>
                        <p class="ml-30">    $(<span class="color-green">"#tooltip"</span>).<span class="color-red">hide</span>();</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The tooltip (<span class="background-grey">#tooltip</span>) is displayed when the mouse enters <span class="background-grey">#infoBox</span> using <span class="background-grey">mouseenter()</span>.</li>
                    <li>The tooltip is hidden when the mouse leaves <span class="background-grey">#infoBox</span> using <span class="background-grey">mouseleave()</span>.</li>
                </ul>


            </div>
        </section>
    )
}