import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/angular/creating-services";

export default function CreatingServices() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Creating Services in Angular | Aspirant's Home");
        const urls = {
            'previous': '/angular/handling-form-submissions',
            'next': '/angular/injecting-services'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Creating Services</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In Angular, a service is a reusable piece of code that encapsulates logic or data, making it easy to share functionality across components. Services are typically used for tasks like handling data, managing state, or providing utility functions.
                </p>

                <h5 className='mt-5 mb-3'>Steps to Create a Service</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>Use Angular CLI</strong>
                        <p>The easiest way to create a service is by using the Angular CLI. Run the following command:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>ng generate service my-service</p>
                                `
                            }}></div>
                        </div>
                        <p>or the shorthand:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>ng g s my-service</p>
                                `
                            }}></div>
                        </div>
                        <p>This generates two files:</p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li><span class="background-grey">my-service.service.ts</span>: It contains the service logic.</li>
                            <li><span class="background-grey">my-service.service.spec.ts</span>: A test file for the service.</li>
                        </ul>
                    </li>
                    <li className='mt-4'>
                        <strong>Define the Service Logic</strong>
                        <p>The generated <span class="background-grey">my-service.service.ts</span> file will look like this:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">import</span> { Injectable } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                                <br />
                                <p><span class="color-red">@Injectable</span>({</p>
                                <p class="ml-30">    <span class="color-pink">providedIn</span>: <span class="color-green">'root'</span>, <span class="color-grey">// Makes the service available globally</span></p>
                                <p>})</p>
                                <p><span class="color-pink">export class</span> <span class="color-blue">MyService</span> {</p>
                                <p class="ml-30">    <span class="color-red">constructor</span>() {}</p>
                                <p>}</p>
                                `
                            }}></div>
                        </div>
                        <p>In this example -</p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li><span class="background-grey">@Injectable</span> <strong>Decorator</strong>: This decorator tells Angular that the class can participate in dependency injection (DI). It ensures that Angular can create and inject an instance of the class wherever it is needed.</li>
                            <li><span class="background-grey">providedIn: 'root'</span>: The <span class="background-grey">providedIn</span> property determines where the service will be available in your app. By setting <span class="background-grey">providedIn: 'root'</span>, Angular registers the service at the root injector level. This makes the service a singleton (only one instance is created) and it is shared across the entire application.</li>
                        </ul>
                    </li>
                    <li className='mt-4'>
                        <strong>Add Logic to the Service</strong>
                        <p>You can now add methods and properties to your service as needed.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">import</span> { Injectable } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                                <br />
                                <p><span class="color-red">@Injectable</span>({</p>
                                <p class="ml-30">    <span class="color-pink">providedIn</span>: <span class="color-green">'root'</span>,</p>
                                <p>})</p>
                                <p><span class="color-pink">export class</span> <span class="color-blue">LoggingService</span> {</p>
                                <p class="ml-30">    <span class="color-red">log</span>(message: <span class="color-blue">string</span>): void {</p>
                                <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">&#96;LoggingService: $&#123;message	&#125;&#96;</span>);</p>
                                <p class="ml-30">    }</p>
                                <p>}</p>
                                `
                            }}></div>
                        </div>
                        <p>This service has a <span class="background-grey">log()</span> method that can be used to log messages across the application.</p>
                    </li>
                </ul>


                <h5 className='mt-5 mb-3'>Alternative: Manually Create a Service</h5>
                <p>If you're not using the Angular CLI, follow these steps:</p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li><strong>Create a File for the Service</strong> Manually create a file, e.g., <span class="background-grey">logging.service.ts</span>.</li>   
                    <li><strong>Define the Service Class</strong> Add the following code:
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">import</span> { Injectable } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                                <br />
                                <p><span class="color-red">@Injectable</span>({</p>
                                <p class="ml-30">    <span class="color-pink">providedIn</span>: <span class="color-green">'root'</span>,</p>
                                <p>})</p>
                                <p><span class="color-pink">export class</span> <span class="color-blue">LoggingService</span> {</p>
                                <p class="ml-30">    <span class="color-red">log</span>(message: <span class="color-blue">string</span>): void {</p>
                                <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">&#96;LoggingService: $&#123;message&#125;&#96;</span>);</p>
                                <p class="ml-30">    }</p>
                                <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li><strong>Save the File</strong> Save it in an appropriate folder (e.g., <span class="background-grey">src/app/services/</span>).</li>
                </ul>


                <h5 className='mt-5 mb-3'>Registering the Service (If Not Using <span class="background-grey">providedIn</span>)</h5>
                <p>If you omit the <span class="background-grey">providedIn</span> property in the <span class="background-grey">@Injectable</span> decorator, you must manually register the service in the <span class="background-grey">providers</span> array of a module.</p>
                <p>In <strong>AppModule</strong></p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">import</span> { NgModule } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                        <p><span class="color-pink">import</span> { LoggingService } <span class="color-pink">from</span> <span class="color-green">'./services/logging.service'</span>;</p>
                        <br />
                        <p><span class="color-red">@NgModule</span>({</p>
                        <p class="ml-30">    <span class="color-pink">declarations</span>: [<span class="color-green">...</span>],</p>
                        <p class="ml-30">    <span class="color-pink">imports</span>: [<span class="color-green">...</span>],</p>
                        <p class="ml-30">    <span class="color-pink">providers</span>: [LoggingService], <span class="color-grey">// Explicitly register the service</span></p>
                        <p class="ml-30">    <span class="color-pink">bootstrap</span>: [AppComponent],</p>
                        <p>})</p>
                        <p><span class="color-pink">export class</span> <span class="color-blue">AppModule</span> {}</p>
                        `
                    }}></div>
                </div>


            </div>
        </section>
    )
}