import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/jquery/events/hover";

export default function Hover() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("jQuery hover() Method | Aspirant's Home");
        const urls = {
            'previous': '/jquery/events'
        }
        path.setPreviousNext(urls);
        path.setPreviousName('Back to Events');
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>jQuery hover() Method</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">hover()</span> method in jQuery is used to specify two functions that will be triggered when the mouse pointer enters and leaves an element. This is a convenient way to add simple hover effects, such as changing styles or showing/hiding content when a user hovers over an element.
                </p>

                <p>It has a basic syntax -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(selector).<span class="color-red">hover</span>(mouseEnterFunction, mouseLeaveFunction);</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">selector</span> specifies the element(s) to attach the <span class="background-grey">hover</span> event to.</li>
                    <li><span class="background-grey">mouseEnterFunction</span> is a function to execute when the mouse pointer enters the element.</li>
                    <li><span class="background-grey">mouseLeaveFunction</span> is a function to execute when the mouse pointer leaves the element.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Example: Simple Hover Effect with <span class="background-grey">hover()</span></h5>
                <p>This example changes the background color of a <span class="background-grey">div</span> when the mouse hovers over it and reverts it back when the mouse leaves.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;div <span class="color-pink">id</span>=<span class="color-green">"hoverBox"</span> <span class="color-pink">style</span>=<span class="color-green">"width: 200px; height: 100px; border: 1px solid #ddd;"</span>&gt;Hover over me!&lt;/div&gt;</p>
                        `
                    }}></div>
                </div>
                <h6>jQuery Code</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"#hoverBox"</span>).<span class="color-red">hover</span>(</p>
                        <p class="ml-30">    <span class="color-blue">function</span>() {</p>
                        <p class="ml-60">        $(this).<span class="color-red">css</span>(<span class="color-green">"background-color"</span>, <span class="color-green">"#e0f7fa"</span>); <span class="color-grey">// Mouse enters</span></p>
                        <p class="ml-30">    },</p>
                        <p class="ml-30">    <span class="color-blue">function</span>() {</p>
                        <p class="ml-60">        $(this).<span class="color-red">css</span>(<span class="color-green">"background-color"</span>, <span class="color-green">"white"</span>); <span class="color-grey">// Mouse leaves</span></p>
                        <p class="ml-30">    }</p>
                        <p>);</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span class="background-grey">hover()</span> method is applied to the <span class="background-grey">#hoverBox</span> div.</li>
                    <li>When the mouse enters the <span class="background-grey">#hoverBox</span>, the background color changes to light blue (<span class="background-grey">#e0f7fa</span>).</li>
                    <li>When the mouse leaves, the background color resets to white.</li>
                </ul>



            </div>
        </section>
    )
}