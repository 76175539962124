import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Outlet, Link } from "react-router-dom";
export const pageUrl = () => "/angular/creating-custom-pipes";

export default function CustomPipe() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Creating Custom Pipes in Angular | Aspirant's Home");
        const urls = {
            'previous': '/angular/using-built-in-pipes',
            'next': '/angular/pure-vs-impure-pipes'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Custom Pipes</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In Angular, if the built-in pipes (like <span class="background-grey">uppercase</span>, <span class="background-grey">date</span>, <span class="background-grey">currency</span>, etc.) don’t meet your needs, you can create your own <strong>custom pipe</strong>. A custom pipe is a reusable function that transforms data into the desired format for your application.
                </p>

                <h5 className='mt-5 mb-3'>Why Create a Custom Pipe?</h5>
                <p>You may need a custom pipe for:</p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>Formatting specific data (e.g., adding a prefix to numbers).</li>
                    <li>Converting custom objects or arrays into user-friendly formats.</li>
                    <li>Applying domain-specific transformations (e.g., masking sensitive data).</li>
                </ul>

                <h5 className='mt-5 mb-3'>How to Create a Custom Pipe</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>Use Angular CLI to Generate a Pipe</strong>
                        <p>Run this command to create a new pipe:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">ng generate</span> pipe <span class="color-blue">custom</span></p>
                                `
                            }}></div>
                        </div>
                        <p>This creates two files: <span class="background-grey">custom.pipe.ts</span> and <span class="background-grey">custom.pipe.spec.ts</span>.</p>
                    </li>
                    <li>
                        <strong>Implement the Pipe Logic</strong>
                        <p>Open the <span class="background-grey">custom.pipe.ts</span> file and write your transformation logic.</p>
                    </li>
                    <li>
                        <strong>Use the Pipe in a Component Template</strong>
                        <p>Add the pipe to your component's template to see it in action</p>
                    </li>
                </ul>

                <h5 className='mt-5 mb-3'>Example: Masking Sensitive Data</h5>
                <p>Let's take an senario of Mask email addresses like <span class="background-grey">john.doe@example.com</span> to <span class="background-grey">j***@example.com</span>.</p>

                <h6 className='mt-4'>Step 1: Generate the Pipe</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">ng generate</span> pipe <span class="color-blue">maskEmail</span></p>
                        `
                    }}></div>
                </div>

                <h6 className='mt-4'>Step 2: Add Logic Edit <span class="background-grey">mask-email.pipe.ts</span>:</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">import</span> { <span class="color-blue">Pipe</span>, <span class="color-blue">PipeTransform</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                        <br />
                        <p><span class="color-red">@Pipe</span>({</p>
                        <p class="ml-30">    <span class="color-pink">name</span>: <span class="color-green">'maskEmail'</span>,</p>
                        <p>})</p>
                        <p><span class="color-pink">export class</span> <span class="color-blue">MaskEmailPipe</span> <span class="color-pink">implements</span> <span class="color-blue">PipeTransform</span> {</p>
                        <p class="ml-30">    <span class="color-red">transform</span>(value: <span class="color-pink">string</span>): <span class="color-pink">string</span> {</p>
                        <p class="ml-60">        <span class="color-blue">if</span> (<span class="color-pink">!</span>value || <span class="color-pink">!</span>value.includes(<span class="color-green">'@'</span>)) {</p>
                        <p class="ml-90">            <span class="color-pink">return</span> <span class="color-green">'Invalid email'</span>;</p>
                        <p class="ml-60">        }</p>
                        <p class="ml-60">        <span class="color-blue">const</span> [localPart, domain] = value.<span class="color-red">split</span>(<span class="color-green">'@'</span>);</p>
                        <p class="ml-60">        <span class="color-blue">const</span> maskedLocal = localPart[<span class="color-pink">0</span>] + <span class="color-green">'*'</span>.<span class="color-red">repeat</span>(localPart.length - <span class="color-pink">1</span>);</p>
                        <p class="ml-60">        <span class="color-pink">return</span> <span class="color-green">&#96;$&#123;maskedLocal&#125;@$&#123;domain&#125;&#96;</span>;</p>
                        <p class="ml-30">    }</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>

                <h6 className='mt-4'>Step 3: Use in a Template In <span class="background-grey">app.component.html</span>:</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;p&gt;Email: {{ <span class="color-green">'john.doe@example.com'</span> | <span class="color-pink">maskEmail</span> }}&lt;/p&gt;</p>
                        <p class="color-grey">&lt;!-- Output: Email: j***@example.com --&gt;</p>
                        <br />
                        <p>&lt;p&gt;Email: {{ <span class="color-green">'test@gmail.com'</span> | <span class="color-pink">maskEmail</span> }}&lt;/p&gt;</p>
                        <p class="color-grey">&lt;!-- Output: Email: t***@gmail.com --&gt;</p>
                        <br />
                        <p>&lt;p&gt;Email: {{ <span class="color-blue">null</span> | <span class="color-pink">maskEmail</span> }}&lt;/p&gt;</p>
                        <p class="color-grey">&lt;!-- Output: Invalid email --&gt;</p>
                        `
                    }}></div>
                </div>


                <p className='mt-5'>Custom pipes are a great way to make your Angular applications more dynamic and user-friendly.</p>



            </div>
        </section>
    )
}