import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/tag/br";

export default function BrTag() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - <br> Tag | Aspirant's Home");
        const urls = {
            'previous': '/html/tag-list'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - &lt;br&gt; Tag</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">&lt;br&gt;</span> tag in HTML is used to create a line break within text, forcing the content that follows it to start on the next line. Unlike the <span class="background-grey">&lt;p&gt;</span> tag, which creates a new paragraph with margin space, the <span class="background-grey">&lt;br&gt;</span> tag simply moves the following text or elements to the next line without any extra space. It’s particularly useful for formatting text where spacing needs to be controlled within the same paragraph.
                </p>

                <h5 className='mt-5 mb-3'>Structure and Purpose of <span class="background-grey">&lt;br&gt;</span> Tag</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>Used for adding single line breaks within text or elements.</li>
                    <li>Unlike block elements like <span class="background-grey">&lt;p&gt;</span>, the <span class="background-grey">&lt;br&gt;</span> tag does not create extra spacing.</li>
                    <li><span class="background-grey">&lt;br&gt;</span> is a self-closing tag, meaning it doesn’t need a closing tag (<span class="background-grey">&lt;/br&gt;</span> is not used).</li>
                </ul>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <p>The <span class="background-grey">&lt;br&gt;</span> tag is used directly where the line break is needed:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>Line one.&lt;br&gt;Line two.&lt;br&gt;Line three.</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Example Usage of <span class="background-grey">&lt;br&gt;</span></h5>
                <p>The <span class="background-grey">&lt;br&gt;</span> tag can be useful in scenarios like addresses, poems, or structured content where new lines are needed within the same block of text:</p>

                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Line Break Example&lt;/title&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <br />    
                        <p class="ml-60">        &lt;h2&gt;Our Office Address&lt;/h2&gt;</p>
                        <p class="ml-60">        &lt;p&gt;</p>
                        <p class="ml-60">        123 Main Street&lt;br&gt;</p>
                        <p class="ml-60">        Suite 100&lt;br&gt;</p>
                        <p class="ml-60">        New York, NY 10001&lt;br&gt;</p>
                        <p class="ml-60">        USA</p>
                        <p class="ml-60">        &lt;/p&gt;</p>
                        <br />    
                        <p class="ml-60">        &lt;h2&gt;Short Poem&lt;/h2&gt;</p>
                        <p class="ml-60">        &lt;p&gt;</p>
                        <p class="ml-60">        Roses are red,&lt;br&gt;</p>
                        <p class="ml-60">        Violets are blue,&lt;br&gt;</p>
                        <p class="ml-60">        Sugar is sweet,&lt;br&gt;</p>
                        <p class="ml-60">        And so are you.</p>
                        <p class="ml-60">        &lt;/p&gt;</p>
                        <br />    
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Output will be the following -</p>
                <div className='outputPalateBox mt-2 mb-4'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <!DOCTYPE html>
                        <html lang="en">
                        <head>
                          <meta charset="UTF-8">
                          <title>Line Break Example</title>
                        </head>
                        <body>
                        
                        <h2>Our Office Address</h2>
                        <p>
                          123 Main Street<br>
                          Suite 100<br>
                          New York, NY 10001<br>
                          USA
                        </p>
                        
                        <h2>Short Poem</h2>
                        <p>
                          Roses are red,<br>
                          Violets are blue,<br>
                          Sugar is sweet,<br>
                          And so are you.
                        </p>
                        
                        </body>
                        </html>
                        `
                    }}></div>
                </div>

                <p>The <span class="background-grey">&lt;br&gt;</span> tag is ideal for inserting line breaks in HTML without creating new paragraphs or adding extra space. It’s useful for controlling line breaks in addresses, poems, or other content where new lines are required without additional formatting.</p>


            </div>
        </section>
    )
}