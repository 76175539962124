import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/jquery/events/keydown";

export default function Keydown() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("jQuery keydown() Method | Aspirant's Home");
        const urls = {
            'previous': '/jquery/events'
        }
        path.setPreviousNext(urls);
        path.setPreviousName('Back to Events');
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>jQuery keydown() Method</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">keydown()</span> method in jQuery is used to trigger an event when a user presses a key on the keyboard. This event is especially useful for capturing specific key presses, allowing you to perform actions like real-time form validation, shortcuts, or custom keyboard navigation.
                </p>

                <p>It has a basic syntax -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(selector).<span class="color-red">keydown</span>(function);</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">selector</span> specifies the element(s) to attach the <span class="background-grey">keydown</span> event to.</li>
                    <li><span class="background-grey">function</span> is a callback function that executes when a key is pressed down on the selected element(s).</li>
                </ul>

                <h5 className='mt-5 mb-3'>Example: Detecting Any Key Press</h5>
                <p>Here’s a basic example where a message is displayed every time a key is pressed in an input field.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">id</span>=<span class="color-green">"nameInput"</span> <span class="color-pink">placeholder</span>=<span class="color-green">"Type here..."</span>&gt;</p>
                        <p>&lt;p <span class="color-pink">id</span>=<span class="color-green">"message"</span>&gt;&lt;/p&gt;</p>
                        `
                    }}></div>
                </div>
                <h6>jQuery Code</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"#nameInput"</span>).<span class="color-red">keydown</span>(<span class="color-blue">function</span>() {</p>
                        <p class="ml-30">    $(<span class="color-green">"#message"</span>).<span class="color-red">text</span>(<span class="color-green">"A key was pressed!"</span>);</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>When any key is pressed down in the <span class="background-grey">#nameInput</span> input field, the paragraph with <span class="background-grey">#message</span> will update with the text "A key was pressed!"</li>
                </ul>


            </div>
        </section>
    )
}