import React, { useEffect, useState, useRef } from 'react'
import { Outlet, Link } from "react-router-dom";
import TopHeader from '../TopHeader';
import Footer from '../Footer'
import { MenuContext, TitleContext, PathContext } from "../../Context";
import { useContext } from "react";
import PreviousNext from '../PreviousNext';

export default function NextJsLayout() {

    const addspaceElement = useRef();
    const menuElement = useRef([]);
    const menu = useContext(MenuContext);
    const pathName = useContext(PathContext);
    console.log(pathName)
    const pathname = pathName.pathName;
    const [showSubMenu, setShowSubMenu] = useState(document.getElementsByClassName("activeSubMenu")[0] ? false : true)
    const title = useContext(TitleContext);

    const setPathName = (path) => {
        if(path)
            pathName.setPathName(path);
    }

    useEffect(() => {
        setPathName(window.location.pathname)
        pathName.setPreviousName('Previous');
        //console.log(pathName.backname)
        if (menu.isMenu == true) menu.setMenuOpen(false)
        addspaceElement.current.scrollIntoView({ block: "center" })
        const element = document.getElementsByClassName("activeMenu")[0];
        element && element.scrollIntoView({ block: "center" });
        const subelement = document.getElementsByClassName("activeSubMenu")[0];
        subelement && subelement.scrollIntoView({ block: "center" });
    }, [pathname,showSubMenu])

    return (
        <>
            <TopHeader />
            <div className='container-fluid'>
                <div className='row' style={{background:'#ddd'}}>
                    <div ref={menuElement} className={menu.isMenu == true ? 'col-md-3 col-lg-2 menuColumnMobile menuColumn p-0' : ' menuColumn p-0'} >
                        <div className='d-flex align-items-center headerMenu headerMainMenu sticky-top mb-4' style={{ 'height': '150px' }}>
                            <div>
                                <h2>Next Js Tutorial</h2>
                                <Link to="/quiz/next-js" className='rounded-corner btn btn-primary float-end'><small>Get Quiz</small></Link>
                            </div>
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/next-js/introduction' ? 'activeMenu' : ''}>
                                    <Link to="/next-js/introduction" onClick={() => { setPathName('/next-js/introduction'); } }>Introduction</Link>
                                </li>
                                <li className={pathname == '/next-js/key-features' ? 'activeMenu' : ''}>
                                    <Link to="/next-js/key-features" onClick={() => { setPathName('/next-js/key-features'); } }>Key Features</Link>
                                </li>
                                <li className={pathname == '/next-js/benefits' ? 'activeMenu' : ''}>
                                    <Link to="/next-js/benefits" onClick={() => { setPathName('/next-js/benefits'); } }>Benefits</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            Setting Up
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/next-js/folder-structure' ? 'activeMenu' : ''}>
                                    <Link to="/next-js/folder-structure" onClick={() => { setPathName('/next-js/folder-structure'); } }>Installing Node.js and npm</Link>
                                </li>
                                <li className={pathname == '/next-js/folder-structure' ? 'activeMenu' : ''}>
                                    <Link to="/next-js/folder-structure" onClick={() => { setPathName('/next-js/folder-structure'); } }>Creating a New Next.js Project</Link>
                                </li>
                                <li className={pathname == '/next-js/folder-structure' ? 'activeMenu' : ''}>
                                    <Link to="/next-js/folder-structure" onClick={() => { setPathName('/next-js/folder-structure'); } }>Project Structure</Link>
                                </li>
                                <li className={pathname == '/next-js/folder-structure' ? 'activeMenu' : ''}>
                                    <Link to="/next-js/folder-structure" onClick={() => { setPathName('/next-js/folder-structure'); } }>Running the Development Server</Link>
                                </li>
                                <li className={pathname == '/next-js/folder-structure' ? 'activeMenu' : ''}>
                                    <Link to="/next-js/folder-structure" onClick={() => { setPathName('/next-js/folder-structure'); } }>Configuring .env Files</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            Pages and Routing
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/next-js/folder-structure' ? 'activeMenu' : ''}>
                                    <Link to="/next-js/folder-structure" onClick={() => { setPathName('/next-js/folder-structure'); } }>Creating Pages</Link>
                                </li>
                                <li className={pathname == '/next-js/folder-structure' ? 'activeMenu' : ''}>
                                    <Link to="/next-js/folder-structure" onClick={() => { setPathName('/next-js/folder-structure'); } }>Dynamic Routing</Link>
                                </li>
                                <li className={pathname == '/next-js/folder-structure' ? 'activeMenu' : ''}>
                                    <Link to="/next-js/folder-structure" onClick={() => { setPathName('/next-js/folder-structure'); } }>Catch-All Routes</Link>
                                </li>
                                <li className={pathname == '/next-js/folder-structure' ? 'activeMenu' : ''}>
                                    <Link to="/next-js/folder-structure" onClick={() => { setPathName('/next-js/folder-structure'); } }>Custom 404 Pages</Link>
                                </li>
                                <li className={pathname == '/next-js/folder-structure' ? 'activeMenu' : ''}>
                                    <Link to="/next-js/folder-structure" onClick={() => { setPathName('/next-js/folder-structure'); } }>Linking Between Pages</Link>
                                </li>
                                <li className={pathname == '/next-js/folder-structure' ? 'activeMenu' : ''}>
                                    <Link to="/next-js/folder-structure" onClick={() => { setPathName('/next-js/folder-structure'); } }>Navigating Programmatically</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            Styling
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/next-js/folder-structure' ? 'activeMenu' : ''}>
                                    <Link to="/next-js/folder-structure" onClick={() => { setPathName('/next-js/folder-structure'); } }>Global CSS vs. Module CSS</Link>
                                </li>
                                <li className={pathname == '/next-js/folder-structure' ? 'activeMenu' : ''}>
                                    <Link to="/next-js/folder-structure" onClick={() => { setPathName('/next-js/folder-structure'); } }>Using Sass</Link>
                                </li>
                                <li className={pathname == '/next-js/folder-structure' ? 'activeMenu' : ''}>
                                    <Link to="/next-js/folder-structure" onClick={() => { setPathName('/next-js/folder-structure'); } }>Tailwind CSS Integration</Link>
                                </li>
                                <li className={pathname == '/next-js/folder-structure' ? 'activeMenu' : ''}>
                                    <Link to="/next-js/folder-structure" onClick={() => { setPathName('/next-js/folder-structure'); } }>Styled-components</Link>
                                </li>
                                <li className={pathname == '/next-js/folder-structure' ? 'activeMenu' : ''}>
                                    <Link to="/next-js/folder-structure" onClick={() => { setPathName('/next-js/folder-structure'); } }>CSS-in-JS with Emotion</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            Data Fetching
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/next-js/folder-structure' ? 'activeMenu' : ''}>
                                    <Link to="/next-js/folder-structure" onClick={() => { setPathName('/next-js/folder-structure'); } }>getStaticProps</Link>
                                </li>
                                <li className={pathname == '/next-js/folder-structure' ? 'activeMenu' : ''}>
                                    <Link to="/next-js/folder-structure" onClick={() => { setPathName('/next-js/folder-structure'); } }>getServerSideProps</Link>
                                </li>
                                <li className={pathname == '/next-js/folder-structure' ? 'activeMenu' : ''}>
                                    <Link to="/next-js/folder-structure" onClick={() => { setPathName('/next-js/folder-structure'); } }>Using SWR</Link>
                                </li>
                                <li className={pathname == '/next-js/folder-structure' ? 'activeMenu' : ''}>
                                    <Link to="/next-js/folder-structure" onClick={() => { setPathName('/next-js/folder-structure'); } }>Using getInitialProps</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            Middleware
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/next-js/folder-structure' ? 'activeMenu' : ''}>
                                    <Link to="/next-js/folder-structure" onClick={() => { setPathName('/next-js/folder-structure'); } }>Introduction to Middleware</Link>
                                </li>
                                <li className={pathname == '/next-js/folder-structure' ? 'activeMenu' : ''}>
                                    <Link to="/next-js/folder-structure" onClick={() => { setPathName('/next-js/folder-structure'); } }>Creating Middleware</Link>
                                </li>
                                <li className={pathname == '/next-js/folder-structure' ? 'activeMenu' : ''}>
                                    <Link to="/next-js/folder-structure" onClick={() => { setPathName('/next-js/folder-structure'); } }>Applying Middleware Globally</Link>
                                </li>
                                <li className={pathname == '/next-js/folder-structure' ? 'activeMenu' : ''}>
                                    <Link to="/next-js/folder-structure" onClick={() => { setPathName('/next-js/folder-structure'); } }>Redirecting Based on Authentication</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            API Routes
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/next-js/folder-structure' ? 'activeMenu' : ''}>
                                    <Link to="/next-js/folder-structure" onClick={() => { setPathName('/next-js/folder-structure'); } }>Creating API Routes</Link>
                                </li>
                                <li className={pathname == '/next-js/folder-structure' ? 'activeMenu' : ''}>
                                    <Link to="/next-js/folder-structure" onClick={() => { setPathName('/next-js/folder-structure'); } }>Handling HTTP Methods</Link>
                                </li>
                                <li className={pathname == '/next-js/folder-structure' ? 'activeMenu' : ''}>
                                    <Link to="/next-js/folder-structure" onClick={() => { setPathName('/next-js/folder-structure'); } }>API Route Middleware</Link>
                                </li>
                                <li className={pathname == '/next-js/folder-structure' ? 'activeMenu' : ''}>
                                    <Link to="/next-js/folder-structure" onClick={() => { setPathName('/next-js/folder-structure'); } }>Integrating with External APIs</Link>
                                </li>
                                <li className={pathname == '/next-js/folder-structure' ? 'activeMenu' : ''}>
                                    <Link to="/next-js/folder-structure" onClick={() => { setPathName('/next-js/folder-structure'); } }>Protecting API Routes</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            Authentication
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/next-js/folder-structure' ? 'activeMenu' : ''}>
                                    <Link to="/next-js/folder-structure" onClick={() => { setPathName('/next-js/folder-structure'); } }>Implementing Authentication</Link>
                                </li>
                                <li className={pathname == '/next-js/folder-structure' ? 'activeMenu' : ''}>
                                    <Link to="/next-js/folder-structure" onClick={() => { setPathName('/next-js/folder-structure'); } }>Protecting Pages</Link>
                                </li>
                                <li className={pathname == '/next-js/folder-structure' ? 'activeMenu' : ''}>
                                    <Link to="/next-js/folder-structure" onClick={() => { setPathName('/next-js/folder-structure'); } }>Server-side Authentication</Link>
                                </li>
                                <li className={pathname == '/next-js/folder-structure' ? 'activeMenu' : ''}>
                                    <Link to="/next-js/folder-structure" onClick={() => { setPathName('/next-js/folder-structure'); } }>OAuth Authentication</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            Performance Optimization
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/next-js/folder-structure' ? 'activeMenu' : ''}>
                                    <Link to="/next-js/folder-structure" onClick={() => { setPathName('/next-js/folder-structure'); } }>Code Splitting</Link>
                                </li>
                                <li className={pathname == '/next-js/folder-structure' ? 'activeMenu' : ''}>
                                    <Link to="/next-js/folder-structure" onClick={() => { setPathName('/next-js/folder-structure'); } }>Image Optimization</Link>
                                </li>
                                <li className={pathname == '/next-js/folder-structure' ? 'activeMenu' : ''}>
                                    <Link to="/next-js/folder-structure" onClick={() => { setPathName('/next-js/folder-structure'); } }>Lazy Loading Components</Link>
                                </li>
                                <li className={pathname == '/next-js/folder-structure' ? 'activeMenu' : ''}>
                                    <Link to="/next-js/folder-structure" onClick={() => { setPathName('/next-js/folder-structure'); } }>Analyzing Bundle Size</Link>
                                </li>
                                <li className={pathname == '/next-js/folder-structure' ? 'activeMenu' : ''}>
                                    <Link to="/next-js/folder-structure" onClick={() => { setPathName('/next-js/folder-structure'); } }>Caching and Revalidation</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            Advanced Features
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/next-js/folder-structure' ? 'activeMenu' : ''}>
                                    <Link to="/next-js/folder-structure" onClick={() => { setPathName('/next-js/folder-structure'); } }>Custom Server</Link>
                                </li>
                                <li className={pathname == '/next-js/folder-structure' ? 'activeMenu' : ''}>
                                    <Link to="/next-js/folder-structure" onClick={() => { setPathName('/next-js/folder-structure'); } }>Webpack Configuration</Link>
                                </li>
                                <li className={pathname == '/next-js/folder-structure' ? 'activeMenu' : ''}>
                                    <Link to="/next-js/folder-structure" onClick={() => { setPathName('/next-js/folder-structure'); } }>Custom Error Pages</Link>
                                </li>
                                <li className={pathname == '/next-js/folder-structure' ? 'activeMenu' : ''}>
                                    <Link to="/next-js/folder-structure" onClick={() => { setPathName('/next-js/folder-structure'); } }>Rewriting and Redirecting URLs</Link>
                                </li>
                                <li className={pathname == '/next-js/folder-structure' ? 'activeMenu' : ''}>
                                    <Link to="/next-js/folder-structure" onClick={() => { setPathName('/next-js/folder-structure'); } }>Incremental Static Regeneration</Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div className='col-12 middlePart'>
                        
                        <div className='headerAdSpace' ref={addspaceElement}>
                            <img className='img-fluid' src='https://aspirantshome.com/slide1.png' />
                        </div>
                        <div className='contentPart row sticky-top'>
                            <div className='col-12 col-md-12 mb-4 contentSection'>
                                <div className='container'>
                                    <div className='contentPage'>
                                        {
                                            pathName.urls ? <PreviousNext urls={ pathName.urls } name={pathName.backname} /> : <></>
                                        }
                                            <Outlet />
                                        {
                                            pathName.urls ? <PreviousNext urls={ pathName.urls } /> : <></>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='footerAdSpace'>
                                <img className='img-fluid' src='https://aspirantshome.com/slide4.png' />
                            </div>
                        </div>

                        
                    </div>
                    <div className='col-12  rightPart'>
                        <img className='img-fluid' src='https://aspirantshome.com/slide2.png' />
                        <img className='mt-5 img-fluid' src='https://aspirantshome.com/slide3.png' />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <div className='container-fluid footerPart'>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
