import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/angular/attribute-directives";

export default function AttributeDirectives() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Attribute Directives in Angular | Aspirant's Home");
        const urls = {
            'previous': '/angular/structural-directives',
            'next': '/angular/creating-custom-directives'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Attribute Directives</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Attribute directives in Angular modify the <strong>appearance</strong> or <strong>behavior</strong> of an element, component, or another directive. Unlike <strong>structural directives</strong>, which manipulate the DOM by adding or removing elements, attribute directives only affect the element they are applied to.
                </p>

                <h5 className='mt-5 mb-3'>Built-in Attribute Directives</h5>
                <p>Angular provides several built-in attribute directives, such as:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">ngClass</span>: Dynamically adds or removes CSS classes.</li>
                    <li><span class="background-grey">ngStyle</span>: Dynamically sets inline styles.</li>
                    <li><span class="background-grey">[attr.&lt;attributeName&gt;]</span>: Binds an attribute's value dynamically.</li>
                </ul>


                <h5 className='mt-5 mb-3'><span class="background-grey">ngClass</span> Directive</h5>
                <p>The <span class="background-grey">ngClass</span> directive allows you to add or remove CSS classes dynamically based on conditions.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;div [<span class="color-pink">ngClass</span>]=<span class="color-green">"className"</span>&gt;&lt;/div&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Let's take an example -</p>
                <h6 className='mt-3'>Component (TypeScript)</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">import</span> { Component } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                        <br />
                        <p><span class="color-red">@Component</span>({</p>
                        <p class="ml-30">    <span class="color-pink">selector</span>: <span class="color-green">'app-ngclass-example'</span>,</p>
                        <p class="ml-30">    <span class="color-pink">template</span>: <span class="color-green">&#96;</span></p>
                        <p class="ml-60 color-green">        &lt;div [ngClass]="{ 'active': isActive, 'disabled': !isActive }"&gt;</p>
                        <p class="ml-90 color-green">            This div's class changes dynamically.</p>
                        <p class="ml-60 color-green">        &lt;/div&gt;</p>
                        <p class="ml-60 color-green">        &lt;button (click)="toggle()"&gt;Toggle State&lt;/button&gt;</p>
                        <p class="ml-30 color-green">    &#96;</p>
                        <p>})</p>
                        <p><span class="color-pink">export class</span> <span class="color-blue">NgClassExampleComponent</span> {</p>
                        <p class="ml-30">    isActive = <span class="color-blue">true</span>;</p>
                        <br />
                        <p class="ml-30">    <span class="color-red">toggle</span>() {</p>
                        <p class="ml-60">        <span class="color-blue">this</span>.isActive = !<span class="color-blue">this</span>.isActive;</p>
                        <p class="ml-30">    }</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>
                <h6 className='mt-3'>Template (HTML)</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;div [<span class="color-pink">ngClass</span>]=<span class="color-green">"{ 'active': isActive, 'disabled': !isActive }"</span>&gt;</p>
                            <p class="ml-30">    This div's class changes dynamically.</p>
                            <p>&lt;/div&gt;</p>
                        `
                    }}></div>
                </div>
                <p><strong>Output:</strong> The <span class="background-grey">active</span> class is applied when <span class="background-grey">isActive</span> is <span class="background-grey">true</span>, and <span class="background-grey">disabled</span> is applied when it's <span class="background-grey">false</span>.</p>


                <h5 className='mt-5 mb-3'><span class="background-grey">ngStyle</span> Directive</h5>
                <p>The <span class="background-grey">ngStyle</span> directive dynamically sets inline styles for an element.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;div [<span class="color-pink">ngStyle</span>]=<span class="color-green">"styleObject"</span>&lt;&lt;/div&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Let's take an example -</p>
                <h6 className='mt-3'>Component (TypeScript)</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">import</span> { Component } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                        <br />
                        <p><span class="color-red">@Component</span>({</p>
                        <p class="ml-30">    <span class="color-pink">selector</span>: <span class="color-green">'app-ngstyle-example'</span>,</p>
                        <p class="ml-30">    <span class="color-pink">template</span>: <span class="color-green">&#96;</span></p>
                        <p class="ml-60 color-green">        &lt;div [ngStyle]="{ 'color': color, 'font-size': fontSize }"&gt;</p>
                        <p class="ml-90 color-green">            This text's style changes dynamically.</p>
                        <p class="ml-60 color-green">        &lt;/div&gt;</p>
                        <p class="ml-60 color-green">        &lt;button (click)="changeStyle()"&gt;Change Style&lt;/button&gt;</p>
                        <p class="ml-30 color-green">    &#96;</p>
                        <p>})</p>
                        <p><span class="color-pink">export class</span> <span class="color-blue">NgStyleExampleComponent</span> {</p>
                        <p class="ml-30">    color = <span class="color-green">'blue'</span>;</p>
                        <p class="ml-30">    fontSize = <span class="color-green">'16px'</span>;</p>
                        <br />
                        <p class="ml-30">    <span class="color-red">changeStyle</span>() {</p>
                        <p class="ml-60">        <span class="color-blue">this</span>.color = <span class="color-blue">this</span>.color === <span class="color-green">'blue'</span> ? <span class="color-green">'red'</span> : <span class="color-green">'blue'</span>;</p>
                        <p class="ml-60">        <span class="color-blue">this</span>.fontSize = <span class="color-blue">this</span>.fontSize === <span class="color-green">'16px'</span> ? <span class="color-green">'20px'</span> : <span class="color-green">'16px'</span>;</p>
                        <p class="ml-30">    }</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>
                <h6 className='mt-3'>Template (HTML)</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;div [<span class="color-pink">ngStyle</span>]=<span class="color-green">"{ 'color': color, 'font-size': fontSize }"</span>&gt;</p>
                        <p class="ml-30">    This text's style changes dynamically.</p>
                        <p>&lt;/div&gt;</p>
                        `
                    }}></div>
                </div>
                <p><strong>Output:</strong> The text color and font size change dynamically on button clicks. </p>


                <h5 className='mt-5 mb-3'><span class="background-grey">[attr.&lt;attributeName&gt;]</span> Directive</h5>
                <p>The <span class="background-grey">[attr.&lt;attributeName&gt;]</span> directive dynamically sets attributes on an element.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;button [<span class="color-pink">attr.disabled</span>]=<span class="color-green">"isDisabled ? true : null"</span>&gt;Click Me&lt;/button&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Let's take an example -</p>
                <h6 className='mt-3'>Component (TypeScript)</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">import</span> { Component } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                        <br />
                        <p><span class="color-red">@Component</span>({</p>
                        <p class="ml-30">    <span class="color-pink">selector</span>: <span class="color-green">'app-attr-example'</span>,</p>
                        <p class="ml-30">    <span class="color-pink">template</span>: <span class="color-green">&#96;</span></p>
                        <p class="ml-30 color-green">        &lt;button [attr.disabled]="isDisabled ? true : null"&gt;Click Me&lt;/button&gt;</p>
                        <p class="ml-30 color-green">        &lt;button (click)="toggle()"&gt;Toggle Disabled&lt;/button&gt;</p>
                        <p class="ml-30 color-green">    &#96;</p>
                        <p>})</p>
                        <p><span class="color-pink">export class</span> <span class="color-blue">AttrExampleComponent</span> {</p>
                        <p class="ml-30">    isDisabled = <span class="color-blue">true</span>;</p>
                        <br />
                        <p class="ml-30">    <span class="color-red">toggle</span>() {</p>
                        <p class="ml-60">        <span class="color-blue">this</span>.isDisabled = !<span class="color-blue">this</span>.isDisabled;</p>
                        <p class="ml-30">    }</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>
                <h6 className='mt-3'>Template (HTML)</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;button [<span class="color-pink">attr.disabled</span>]=<span class="color-green">"isDisabled ? true : null"</span>&gt;Click Me&lt;/button&gt;</p>
                        `
                    }}></div>
                </div>
                <p><strong>Output:</strong> The button becomes enabled or disabled dynamically based on <span class="background-grey">isDisabled</span>.</p>


            </div>
        </section>
    )
}