import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/angular/architecture";

export default function Architecture() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Architecture of Angular | Aspirant's Home");
        const urls = {
            'previous': '/angular/environment-setup',
            'next': '/angular/creating-and-using-components'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Architecture of Angular</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Angular is a popular web application framework developed and maintained by Google. Its architecture is based on a <strong>component-driven model</strong> and uses <strong>TypeScript</strong> as its primary language. Angular follows the <strong>Model-View-Controller (MVC)</strong> pattern and supports modern web development with tools and features for creating dynamic, responsive, and scalable applications.
                </p>

                <h5 className='mt-5 mb-3'>Key Building Blocks of Angular Architecture</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>Modules</strong>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>Angular applications are modular, and each application has a root module, often named <span class="background-grey">AppModule</span>.</li>
                            <li>Modules organize the app into cohesive blocks of functionality, enabling better reusability and lazy loading.</li>
                            <li>Example:
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                        <p><span class="color-red">@NgModule</span>({</p>
                                        <p class="ml-30">    <span class="color-pink">declarations</span>: [<span class="color-blue">AppComponent</span>],</p>
                                        <p class="ml-30">    <span class="color-pink">imports</span>: [<span class="color-blue">BrowserModule</span>],</p>
                                        <p class="ml-30">    <span class="color-pink">bootstrap</span>: [<span class="color-blue">AppComponent</span>],</p>
                                        <p>})</p>
                                        <p><span class="color-pink">export class</span> <span class="color-blue">AppModule</span> {}</p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>Components</strong>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>
                                Components are the <strong>core building blocks</strong> of the UI. Each component consists of:
                                <ul style={{ listStyle: 'disc' }}>
                                    <li>A <strong>HTML template</strong> for the structure.</li>
                                    <li>A <strong>CSS file</strong> for styling.</li>
                                    <li>A <strong>TypeScript class</strong> for logic and data.</li>
                                </ul>
                            </li>
                            <li>
                                Example:
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                        <p><span class="color-red">@Component</span>({</p>
                                        <p class="ml-30">    <span class="color-pink">selector</span>: <span class="color-green">'app-header'</span>,</p>
                                        <p class="ml-30">    <span class="color-pink">templateUrl</span>: <span class="color-green">'./header.component.html'</span>,</p>
                                        <p class="ml-30">    <span class="color-pink">styleUrls</span>: [<span class="color-green">'./header.component.css'</span>],</p>
                                        <p>})</p>
                                        <p><span class="color-pink">export class</span> <span class="color-blue">HeaderComponent</span> {</p>
                                        <p class="ml-30">    title = <span class="color-green">'Angular Architecture'</span>;</p>
                                        <p>}</p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>Templates</strong>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>Templates define the view and use Angular’s <strong>declarative syntax</strong> to bind data and handle events.</li>
                            <li>Example:
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                        <p>&lt;h1&gt;{{ title }}&lt;/h1&gt;</p>
                                        <p>&lt;button <span class="color-pink">(click)</span>=<span class="color-green">"onClick()"</span>&gt;Click Me&lt;/button&gt;</p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>Services and Dependency Injection</strong>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>Services in Angular handle <strong>business logic</strong> and <strong>data sharing</strong> between components.</li>
                            <li>Dependency Injection (DI) provides an efficient way to supply instances of services where needed.</li>
                            <li>
                                Example:
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                        <p><span class="color-red">@Injectable</span>({</p>
                                        <p class="ml-30">    <span class="color-pink">providedIn</span>: <span class="color-green">'root'</span>,</p>
                                        <p>})</p>
                                        <p><span class="color-pink">export class</span> <span class="color-red">DataService</span> {</p>
                                        <p class="ml-30">    <span class="color-red">getData</span>() {</p>
                                        <p class="ml-60">        <span class="color-blue">return</span> [<span class="color-green">'Item 1'</span>, <span class="color-green">'Item 2'</span>, <span class="color-green">'Item 3'</span>];</p>
                                        <p class="ml-30">    }</p>
                                        <p>}</p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>

            </div>
        </section>
    )
}