import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/events/onmousemove";

export default function Onmousemove() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - onmousemove Event | Aspirant's Home");
        const urls = {
            'previous': '/html/events'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - onmousemove Event</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">onmousemove</span> event in HTML is triggered when the mouse pointer moves within an HTML element. This event fires continuously as the pointer moves, making it useful for interactive features like real-time animations, tracking the cursor's position, updating UI elements based on pointer position, or displaying custom cursors and tooltips that follow the pointer.
                </p>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <p>To use <span class="background-grey">onmousemove</span>, add it as an attribute to an HTML element and specify JavaScript code or a function that should run whenever the mouse moves over that element.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;div <span class="color-pink">onmousemove</span>=<span class="color-green">"alert('Mouse is moving!')"</span>&gt;Move your mouse over this area&lt;/div&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example, whenever the mouse pointer moves within the div, an alert is triggered. However, frequent alerts can be intrusive, so this event is usually best suited for updating elements rather than triggering pop-ups.</p>


                <h5 className='mt-5 mb-3'>Example: Displaying Mouse Coordinates</h5>
                <p>You can use <span class="background-grey">onmousemove</span> to capture and display the coordinates of the mouse pointer as it moves within an element.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;div <span class="color-pink">onmousemove</span>=<span class="color-green">"showCoordinates(event)"</span> <span class="color-pink">style</span>=<span class="color-green">"height: 200px; border: 1px solid black;"</span>&gt;</p>
                        <p class="ml-30">    Move your mouse here to see coordinates</p>
                        <p>&lt;/div&gt;</p>
                        <p>&lt;p <span class="color-pink">id</span>=<span class="color-green">"coordinates"</span>&gt;&lt;/p&gt;</p>
                        <br />
                        <p>&lt;script&gt;</p>
                        <p class="ml-30">    <span class="color-blue">function</span> <span class="color-red">showCoordinates</span>(event) {</p>
                        <p class="ml-60">        <span class="color-blue">const</span> x = event.clientX;</p>
                        <p class="ml-60">        <span class="color-blue">const</span> y = event.clientY;</p>
                        <p class="ml-60">        document.<span class="color-red">getElementById</span>(<span class="color-green">"coordinates"</span>).textContent = &#96;	X: &#123;{x}, Y: &#123;{y}&#96;	;</p>
                        <p class="ml-30">    }</p>
                        <p>&lt;/script&gt;</p>
                        `
                    }}></div>
                </div>
                <p>As the mouse moves within the <span class="background-grey">div</span>, <span class="background-grey">showCoordinates</span> updates the coordinates (relative to the viewport) in the <span class="background-grey">&lt;p&gt;</span> element with <span class="background-grey">id="coordinates"</span>.</p>




            </div>
        </section>
    )
}