import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Outlet, Link } from "react-router-dom";
export const pageUrl = () => "/angular/testing";

export default function Testing() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Testing in Angular | Aspirant's Home");
        const urls = {
            'previous': '/angular/ivy-renderer',
            'next': '/angular/deployment'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Testing</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Testing is the process of verifying that your code works as expected. In Angular, testing ensures your app is <strong>error-free</strong>, behaves as intended, and is ready for real-world use.
                </p>

                <h5 className='mt-5 mb-3'>Types of Testing in Angular</h5>
                <p>Angular supports two main types of testing:</p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>Unit Testing:</strong>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>Focuses on testing small, individual pieces of code (like components, services, or pipes).</li>
                            <li>Example: Testing if a function returns the correct output.</li>
                        </ul>
                    </li>
                    <li>
                        <strong>End-to-End (E2E) Testing:</strong>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>Tests the entire application from the user's perspective.</li>
                            <li>Example: Testing if a user can log in successfully.</li>
                        </ul>
                    </li>
                </ul>


                <h5 className='mt-5 mb-3'>Why Testing is Important</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li><strong>Ensures Quality</strong>: Detect bugs early.</li>
                    <li><strong>Improves Confidence</strong>: Makes developers confident that the code works correctly after changes.</li>
                    <li><strong>Saves Time</strong>: Fixing bugs early is faster and cheaper than fixing them later.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Unit Testing in Angular</h5>
                <p>Let’s say you have a simple <span className='background-grey'>CounterComponent</span>:</p>

                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">import</span> { <span class="color-blue">Component</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                        <br />
                        <p><span class="color-red">@Component</span>({</p>
                        <p class="ml-30">    <span class="color-pink">selector</span>: <span class="color-green">'app-counter'</span>,</p>
                        <p class="ml-30">    <span class="color-pink">template</span>: <span class="color-green">&#96;</span></p>
                        <p class="ml-60 color-green">        &lt;button (click)="increment()"&gt;+&lt;/button&gt;</p>
                        <p class="ml-60 color-green">        &lt;span&gt;{{ count }}&lt;/span&gt;</p>
                        <p class="ml-60 color-green">        &lt;button (click)="decrement()"&gt;-&lt;/button&gt;</p>
                        <p class="ml-30 color-green">    &#96;,</p>
                        <p>})</p>
                        <p><span class="color-pink">export class</span> <span class="color-blue">CounterComponent</span> {</p>
                        <p class="ml-30">    count = <span class="color-pink">0</span>;</p>
                        <br />
                        <p class="ml-30">    <span class="color-red">increment</span>() {</p>
                        <p class="ml-60">        <span class="color-blue">this</span>.count<span class="color-pink">++</span>;</p>
                        <p class="ml-30">    }</p>
                        <br />
                        <p class="ml-30">    <span class="color-red">decrement</span>() {</p>
                        <p class="ml-60">        <span class="color-blue">this</span>.count<span class="color-pink">--</span>;</p>
                        <p class="ml-30">    }</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>

                <h6 className='mt-3'>Writing a Unit Test</h6>
                <p>Angular creates a test file for each component, like <span className='background-grey'>counter.component.spec.ts</span>. Here’s an example test:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">import</span> { <span class="color-blue">ComponentFixture</span>, <span class="color-blue">TestBed</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/core/testing'</span>;</p>
                        <p><span class="color-pink">import</span> { <span class="color-blue">CounterComponent</span> } <span class="color-pink">from</span> <span class="color-green">'./counter.component'</span>;</p>
                        <br />
                        <p><span class="color-red">describe</span>(<span class="color-green">'CounterComponent'</span>, () => {</p>
                        <p class="ml-30">    <span class="color-blue">let</span> component: <span class="color-blue">CounterComponent</span>;</p>
                        <p class="ml-30">    <span class="color-blue">let</span> fixture: <span class="color-blue">ComponentFixture</span><<span class="color-pink">CounterComponent</span>>;</p>
                        <br />
                        <p class="ml-30">    <span class="color-red">beforeEach</span>(() => {</p>
                        <p class="ml-60">        TestBed.<span class="color-red">configureTestingModule</span>({</p>
                        <p class="ml-90">            <span class="color-pink">declarations</span>: [<span class="color-blue">CounterComponent</span>],</p>
                        <p class="ml-60">        });</p>
                        <br />
                        <p class="ml-60">        fixture = TestBed.<span class="color-red">createComponent</span>(<span class="color-blue">CounterComponent</span>);</p>
                        <p class="ml-60">        component = fixture.componentInstance;</p>
                        <p class="ml-60">        fixture.<span class="color-red">detectChanges</span>();</p>
                        <p class="ml-30">    });</p>
                        <br />
                        <p class="ml-30">    <span class="color-red">it</span>(<span class="color-green">'should create the component'</span>, () => {</p>
                        <p class="ml-60">        <span class="color-red">expect</span>(component).<span class="color-red">toBeTruthy</span>();</p>
                        <p class="ml-30">    });</p>
                        <br />
                        <p class="ml-30">    <span class="color-red">it</span>(<span class="color-green">'should increment the count'</span>, () => {</p>
                        <p class="ml-60">        component.<span class="color-red">increment</span>();</p>
                        <p class="ml-60">        <span class="color-red">expect</span>(component.count).<span class="color-red">toBe</span>(<span class="color-pink">1</span>);</p>
                        <p class="ml-30">    });</p>
                        <br />
                        <p class="ml-30">    <span class="color-red">it</span>(<span class="color-green">'should decrement the count'</span>, () => {</p>
                        <p class="ml-60">        component.<span class="color-red">decrement</span>();</p>
                        <p class="ml-60">        <span class="color-red">expect</span>(component.count).<span class="color-red">toBe</span>(<span class="color-pink">-1</span>);</p>
                        <p class="ml-30">    });</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-3'>Running Tests</h5>
                <p>To run your tests, use the Angular CLI command:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">ng</span> test</p>
                        `
                    }}></div>
                </div>
                <p>This opens a browser window and runs all your unit tests. It shows:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>Passed tests (<span className='color-green'>green</span> checkmarks).</li>
                    <li>Failed tests (<span className='color-red'>red</span> crosses).</li>
                </ul>


                <h5 className='mt-5 mb-3'>Expected Output</h5>
                <p>If all the test are passed then -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>Test Results:</p>
                            <br />
                            <p>CounterComponent</p>
                            <p><span class="color-green">✓</span> should create the component</p>
                            <p><span class="color-green">✓</span> should increment the count</p>
                            <p><span class="color-green">✓</span> should decrement the count</p>
                        `
                    }}></div>
                </div>
                <p>If some test fails then its looks like below -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>Test Suite: CounterComponent</p>
                            <p>------------------------------------------------------</p>
                            <p><span class="color-green">✓</span> should create the component (0.003 secs)</p>
                            <p><span class="color-green">✓</span> should increment the count (0.002 secs)</p>
                            <p><span class="color-red">✗</span> should decrement the count</p>
                            <p>- Error: Expected <span class="color-pink">-1</span> to be <span class="color-pink">0</span>.</p>
                            <p>------------------------------------------------------</p>
                            <p>Executed 3 of 3 (1 FAILED) in 0.150 secs.</p>
                        `
                    }}></div>
                </div>



                <h5 className='mt-5 mb-3'>End-to-End (E2E) Testing</h5>
                <p>E2E tests check if your app works as expected when used by a real user.</p>

                <h6 className='mt-3'>Example: Testing Login Flow</h6>
                <p>Suppose you have a login page. Here’s an example E2E test:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">import</span> { <span class="color-blue">browser</span>, <span class="color-blue">by</span>, <span class="color-blue">element</span> } <span class="color-pink">from</span> <span class="color-green">'protractor'</span>;</p>
                        <br />
                        <p><span class="color-red">describe</span>(<span class="color-green">'Login Page'</span>, () => {</p>
                        <p class="ml-30">    <span class="color-red">it</span>(<span class="color-green">'should log in the user'</span>, () => {</p>
                        <p class="ml-60">        browser.<span class="color-red">get</span>(<span class="color-green">'/login'</span>); <span class="color-grey">// Navigate to the login page</span></p>
                        <br />
                        <p class="ml-60">        <span class="color-red">element</span>(by.<span class="color-red">css</span>(<span class="color-green">'input[name="username"]'</span>)).<span class="color-red">sendKeys</span>(<span class="color-green">'testuser'</span>);</p>
                        <p class="ml-60">        <span class="color-red">element</span>(by.<span class="color-red">css</span>(<span class="color-green">'input[name="password"]'</span>)).<span class="color-red">sendKeys</span>(<span class="color-green">'password123'</span>);</p>
                        <p class="ml-60">        <span class="color-red">element</span>(by.<span class="color-red">css</span>(<span class="color-green">'button[type="submit"]'</span>)).<span class="color-red">click</span>();</p>
                        <br />    
                        <p class="ml-60">        <span class="color-red">expect</span>(browser.<span class="color-red">getCurrentUrl</span>()).<span class="color-red">toEqual</span>(<span class="color-green">'/dashboard'</span>);</p>
                        <p class="ml-30">    });</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>Here, The browser opens the app and navigates to <span className='background-grey'>/login</span>. The login page is displayed with input fields for <strong>username</strong> and <strong>password</strong> and a <strong>Submit</strong> button.</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The test fills in the <strong>username</strong> field with <span className='background-grey'>"testuser"</span>.</li>
                    <li>The test fills in the <strong>password</strong> field with <span className='background-grey'>"password123"</span>.</li>
                    <li>The test clicks the <strong>Submit</strong> button.</li>
                </ul>
                <p>If the credentials are valid, the application should navigate to <span className='background-grey'>/dashboard</span>. The test verifies this redirection by checking the current URL.</p>

                <h5 className='mt-5 mb-3'>Expected Output</h5>
                <p>If everything works as expected, running the test (<span className='background-grey'>ng e2e</span>) will display:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>Login Page</p>
                        <p><span class="color-green">✓</span> should log in the user</p>
                        <br />
                        <p>Executed 1 of 1 spec SUCCESS (0.123 secs / 0.100 secs)</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>What Happens If the Test Fails?</h5>
                <p>If there’s an issue, such as incorrect redirection or an invalid URL, the test will fail. You might see:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>Login Page</p>
                        <p><span class="color-red">✗</span> should log in the user</p>
                        <p class="ml-30">  - Error: Expected '/login' to equal '/dashboard'</p>
                        <br />
                        <p>Executed 1 of 1 spec FAILED (0.200 secs / 0.150 secs)</p>
                        `
                    }}></div>
                </div>


            </div>
        </section>
    )
}