import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/jquery/get";

export default function Get() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Get Content and Attributes in jQuery | Aspirant's Home");
        const urls = {
            'previous': '/jquery/chaining',
            'next': '/jquery/set'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Get Content and Attributes in jQuery</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In jQuery, you can easily retrieve (or "get") the content and attributes of HTML elements. This includes fetching text, HTML, and attribute values. Here’s an overview of how to use jQuery methods for getting content and attributes from elements.
                </p>

                <h5 className='mt-5 mb-3'>To Get Content</h5>
                <p>There are three primary methods in jQuery to get content from elements: <span class="background-grey">.text()</span>, <span class="background-grey">.html()</span>, and <span class="background-grey">.val()</span>.</p>

                <h4>.text()</h4>
                <p>The <span class="background-grey">.text()</span> method retrieves the text content of an element, including the text of all child elements.</p>
                <p>Let's look at the example below -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">let</span> content = $(<span class="color-green">"#myElement"</span>).<span class="color-red">text</span>();</p>
                        `
                    }}></div>
                </div>
                <p>It gets all text within <span class="background-grey">#myElement</span>, including text from nested elements. In this example,  it stores the text content as a string in the <span class="background-grey">content</span> variable.</p>

                <h4 className='mt-5'>.html()</h4>
                <p>The <span class="background-grey">.html()</span> method retrieves the HTML content of an element, including any nested HTML tags.</p>
                <p>Let's look at the example below -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">let</span> htmlContent = $(<span class="color-green">"#myElement"</span>).<span class="color-red">html</span>();</p>
                        `
                    }}></div>
                </div>
                <p>It gets the HTML structure inside <span class="background-grey">#myElement</span>. Here, in this example it stores the HTML, including tags, in the <span class="background-grey">htmlContent</span> variable.</p>


                <h4 className='mt-5'>.val()</h4>
                <p>The <span class="background-grey">.val()</span> method is used to get the value of form elements like <span class="background-grey">&lt;input&gt;</span>, <span class="background-grey">&lt;textarea&gt;</span>, and <span class="background-grey">&lt;select&gt;</span> elements. Let's look at the example below -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">let</span> inputValue = $(<span class="color-green">"#myInput"</span>).<span class="color-red">val</span>();</p>
                        `
                    }}></div>
                </div>
                <p>It gets the value entered in the <span class="background-grey">#myInput</span> field. In this example, stores the value of the input in the <span class="background-grey">inputValue</span> variable.</p>



                <h5 className='mt-5 mb-3'>Getting Attributes</h5>
                <p>To get the value of an attribute of an HTML element, you can use the <span class="background-grey">.attr()</span> method. This can be used to retrieve any attribute, like <span class="background-grey">href</span>, <span class="background-grey">src</span>, <span class="background-grey">id</span>, <span class="background-grey">class</span>, <span class="background-grey">data-*</span> attributes, etc.</p>

                <p>The <span class="background-grey">.attr()</span> method retrieves the value of a specified attribute from the selected element(s).</p>
                <h6>Example:</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">let</span> link = $(<span class="color-green">"#myLink"</span>).<span class="color-red">attr</span>(<span class="color-green">"href"</span>);</p>
                        `
                    }}></div>
                </div>
                <p>It gets the href attribute from the <span class="background-grey">#myLink</span> element. In the above example it stores the URL (or path) value in the <span class="background-grey">link</span> variable.</p>


                <p className='featureClass mt-5'>These methods make it easy to retrieve various types of content and attributes from HTML elements, simplifying tasks like reading input values, capturing link URLs, and accessing text or HTML structures.</p>


            </div>
        </section>
    )
}