import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/jquery/events/mouseup";

export default function Mouseup() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("jQuery mouseup() Method | Aspirant's Home");
        const urls = {
            'previous': '/jquery/events'
        }
        path.setPreviousNext(urls);
        path.setPreviousName('Back to Events');
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>jQuery mouseup() Method</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">mouseup()</span> method in jQuery triggers an event when the mouse button is released over a selected element. This event is often used to detect the end of a mouse click or interaction, such as finalizing drag-and-drop actions or responding to user input.
                </p>

                <p>It has a basic syntax -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(selector).<span class="color-red">mouseup</span>(function);</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">selector</span> specifies the element(s) to attach the <span class="background-grey">mouseup</span> event to.</li>
                    <li><span class="background-grey">function</span> is a callback function to execute when the mouse button is released.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Example: Displaying a Message on Mouse Release</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;button <span class="color-pink">id</span>=<span class="color-green">"showMessage"</span>&gt;Click Me&lt;/button&gt;</p>
                        <p>&lt;p <span class="color-pink">id</span>=<span class="color-green">"message"</span> <span class="color-pink">style</span>=<span class="color-green">"display: none;"</span>&gt;Mouse button released!&lt;/p&gt;</p>
                        `
                    }}></div>
                </div>
                <h6>jQuery Code</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"#showMessage"</span>).<span class="color-red">mouseup</span>(<span class="color-blue">function</span>() {</p>
                        <p class="ml-30">    $(<span class="color-green">"#message"</span>).<span class="color-red">show</span>().<span class="color-red">fadeOut</span>(<span class="color-pink">2000</span>);</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <p>When the user clicks and releases the mouse button on the <span class="background-grey">#showMessage</span> button, the <span class="background-grey">#message</span> paragraph is displayed briefly and fades out after 2 seconds.</p>
                </ul>


            </div>
        </section>
    )
}