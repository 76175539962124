import { Link } from "react-router-dom";
import { PathContext } from "../Context";
import { useContext } from "react";

export default function PreviousNext({ urls }) {
    const myurl = urls;

    const pathName = useContext(PathContext);
    console.log(pathName)
    const setPathName = (path) => {
        pathName.setPathName(path);
    }


    return (
        <section class='clearfix mb-3'>
            {
                myurl.previous ? 
                    <span className='float-start'>
                        <Link to={myurl.previous} onClick={() => setPathName(myurl.previous)} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; { pathName.backname} </Link>
                    </span>   
                    : <></>
            }
            {
                myurl.next ?
                    <span className='float-end'>
                        <Link to={myurl.next}  onClick={() => setPathName(myurl.next)} className='btn btn-primary'>Next &nbsp; <i class="fa-solid fa-greater-than"></i></Link>
                    </span>
                    :
                    <></>
            }
        </section>
    )
}