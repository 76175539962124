import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/jquery/events/off";

export default function Off() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("jQuery off() Method | Aspirant's Home");
        const urls = {
            'previous': '/jquery/events'
        }
        path.setPreviousNext(urls);
        path.setPreviousName('Back to Events');
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>jQuery off() Method</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">off()</span> method in jQuery is used to remove event handlers that were previously attached to elements. This is helpful when you want to stop an event from being triggered or prevent memory leaks in your application.
                </p>

                <p>It has a basic syntax -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(selector).<span class="color-red">off</span>(event, childSelector, handler);</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">selector</span> specifies the element(s) whose event handlers you want to remove.</li>
                    <li><span class="background-grey">event</span> (optional) specifies the type of event to remove (e.g., <span class="background-grey">click</span>, <span class="background-grey">mouseenter</span>).</li>
                    <li><span class="background-grey">childSelector</span> (optional) is a child element selector for which the event handler was attached.</li>
                    <li><span class="background-grey">handler</span> (optional) is the specific function to remove from the event.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Example 1: Removing All Event Handlers</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;button <span class="color-pink">id</span>=<span class="color-green">"btn"</span>&gt;Click Me&lt;/button&gt;</p>
                        `
                    }}></div>
                </div>
                <h6>jQuery Code</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p class="color-grey">// Attach an event handler</p>
                        <p>$(<span class="color-green">"#btn"</span>).<span class="color-red">on</span>(<span class="color-green">"click"</span>, <span class="color-blue">function</span>() {</p>
                        <p class="ml-30">    <span class="color-red">alert</span>(<span class="color-green">"Button clicked!"</span>);</p>
                        <p>});</p>
                        <br />
                        <p class="color-grey">// Remove all event handlers</p>
                        <p>$(<span class="color-green">"#btn"</span>).<span class="color-red">off</span>();</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>Initially, clicking the button shows an alert.</li>
                    <li>After calling <span class="background-grey">off()</span>, the button no longer responds to clicks.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Example 2: Removing Specific Event Handlers</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;button <span class="color-pink">id</span>=<span class="color-green">"btn"</span>&gt;Click Me&lt;/button&gt;</p>
                        `
                    }}></div>
                </div>
                <h6>jQuery Code</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">function</span> <span class="color-red">showAlert</span>() {</p>
                        <p class="ml-30">    <span class="color-red">alert</span>(<span class="color-green">"Button clicked!"</span>);</p>
                        <p>}</p>
                        <br />
                        <p class="color-grey">// Attach the handler</p>
                        <p>$(<span class="color-green">"#btn"</span>).<span class="color-red">on</span>(<span class="color-green">"click"</span>, showAlert);</p>
                        <br />
                        <p class="color-grey">// Remove only the specific handler</p>
                        <p>$(<span class="color-green">"#btn"</span>).<span class="color-red">off</span>(<span class="color-green">"click"</span>, showAlert);</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The click handler is removed using the reference to the <span class="background-grey">showAlert</span> function.</li>
                    <li>Other click handlers (if any) would remain active.</li>
                </ul>


            </div>
        </section>
    )
}