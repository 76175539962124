import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/jquery/events/on";

export default function On() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("jQuery on() Method | Aspirant's Home");
        const urls = {
            'previous': '/jquery/events'
        }
        path.setPreviousNext(urls);
        path.setPreviousName('Back to Events');
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>jQuery on() Method</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">on()</span> method in jQuery is used to attach event handlers to selected elements, including those dynamically added to the DOM. It's a versatile method for handling various events such as click, hover, keypress, etc., and it supports event delegation for efficient event management.
                </p>

                <p>It has a basic syntax -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(selector).<span class="color-red">on</span>(event, childSelector, data, function);</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">event</span> is the type of event to attach (e.g., <span class="background-grey">click</span>, <span class="background-grey">hover</span>, <span class="background-grey">keyup</span>). Multiple events can be specified by separating them with spaces (e.g., "click mouseenter").</li>
                    <li><span class="background-grey">childSelector</span> (optional) is a selector string to filter the descendants of the selected elements that trigger the event. Used for event delegation.</li>
                    <li><span class="background-grey">data</span> (optional) is data to be passed to the event handler when the event is triggered.</li>
                    <li><span class="background-grey">function</span> is the callback function to execute when the event occurs.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Example 1: Basic Event Handling</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;button <span class="color-pink">id</span>=<span class="color-green">"btn"</span>&gt;Click Me&lt;/button&gt;</p>
                        `
                    }}></div>
                </div>
                <h6>jQuery Code</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"#btn"</span>).<span class="color-red">on</span>(<span class="color-green">"click"</span>, <span class="color-red">function</span>() {</p>
                        <p class="ml-30">    <span class="color-red">alert</span>(<span class="color-green">"Button clicked!"</span>);</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>Clicking the button triggers an alert.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Example 2: Attaching Multiple Events</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"#btn"</span>).<span class="color-red">on</span>(<span class="color-green">"click mouseenter"</span>, <span class="color-blue">function</span>(event) {</p>
                        <p class="ml-30">    <span class="color-blue">if</span> (event.type === <span class="color-green">"click"</span>) {</p>
                        <p class="ml-60">        <span class="color-red">alert</span>(<span class="color-green">"Button clicked!"</span>);</p>
                        <p class="ml-30">    } <span class="color-blue">else if</span> (event.type === <span class="color-green">"mouseenter"</span>) {</p>
                        <p class="ml-60">        <span class="color-red">alert</span>(<span class="color-green">"Mouse entered the button!"</span>);</p>
                        <p class="ml-30">    }</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>Handles both <span class="background-grey">click</span> and <span class="background-grey">mouseenter</span> events on the same button.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Example 3: Event Delegation</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;div <span class="color-pink">id</span>=<span class="color-green">"container"</span>&gt;</p>
                        <p class="ml-30">    &lt;button <span class="color-pink">class</span>=<span class="color-green">"child"</span>&gt;Child Button&lt;/button&gt;</p>
                        <p>&lt;/div&gt;</p>
                        <p>&lt;button <span class="color-pink">id</span>=<span class="color-green">"addButton"</span>&gt;Add Child&lt;/button&gt;</p>
                        `
                    }}></div>
                </div>
                <h6>jQuery Code</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p class="color-grey">// Event delegation</p>
                        <p>$(<span class="color-green">"#container"</span>).<span class="color-red">on</span>(<span class="color-green">"click"</span>, <span class="color-green">".child"</span>, <span class="color-blue">function</span>() {</p>
                        <p class="ml-30">    <span class="color-red">alert</span>(<span class="color-green">"Child button clicked!"</span>);</p>
                        <p>});</p>
                        <br />
                        <p class="color-grey">// Adding new buttons dynamically</p>
                        <p>$(<span class="color-green">"#addButton"</span>).<span class="color-red">on</span>(<span class="color-green">"click"</span>, <span class="color-blue">function</span>() {</p>
                        <p class="ml-30">    $(<span class="color-green">"#container"</span>).<span class="color-red">append</span>(<span class="color-green">'&lt;button class="child"&gt;New Child&lt;/button&gt;'</span>);</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span class="background-grey">on()</span> method ensures the click event works for dynamically added <span class="background-grey">.child</span> buttons.</li>
                </ul>

            </div>
        </section>
    )
}