import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/events/onmouseup";

export default function Onmouseup() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - onmouseup Event | Aspirant's Home");
        const urls = {
            'previous': '/html/events'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - onmouseup Event</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">onmouseup</span> event in HTML is triggered when the user releases a mouse button over an HTML element. This event fires when the mouse button is released, making it useful for completing actions started with the <span class="background-grey">onmousedown</span> event, toggling styles, stopping animations, or triggering specific actions after the mouse button is let go.
                </p>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <p>To use <span class="background-grey">onmouseup</span>, add it as an attribute to an HTML element, specifying the JavaScript code or function to execute when the mouse button is released.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;button <span class="color-pink">onmouseup</span>=<span class="color-green">"alert('Mouse button released!')"</span>&gt;Release Mouse Here&lt;/button&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example, when a mouse button is released over the button, an alert box shows "Mouse button released!"</p>
                <p>Output will be the following -</p>
                <div className='outputPalateBox mt-2 mb-4' style={{border:'none'}}>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <button onmouseup="alert('Mouse button released!')">Release Mouse Here</button>
                        `
                    }}></div>
                </div>

                <p>The <span class="background-grey">onmouseup</span> event is triggered when a mouse button is released over an element, ideal for completing interactions started on <span class="background-grey">onmousedown</span>.</p>


            </div>
        </section>
    )
}