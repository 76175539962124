import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/jquery/events/mousedown";

export default function Mousedown() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("jQuery mousedown() Method | Aspirant's Home");
        const urls = {
            'previous': '/jquery/events'
        }
        path.setPreviousNext(urls);
        path.setPreviousName('Back to Events');
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>jQuery mousedown() Method</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">mousedown()</span> method in jQuery triggers an event when the user presses down on a mouse button over a selected element. It’s often used to detect the start of a mouse action, such as beginning a drag or initiating a selection. This method works with different mouse buttons, including left, right, and middle clicks, allowing you to distinguish between them if needed.
                </p>

                <p>It has a basic syntax -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(selector).<span class="color-red">mousedown</span>(function);</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">selector</span> specifies the element(s) to attach the <span class="background-grey">mousedown</span> event to.</li>
                    <li><span class="background-grey">function</span> is a callback function that executes when a mouse button is pressed down on the selected element(s).</li>
                </ul>


                <h5 className='mt-5 mb-3'>Example: Detecting a <span class="background-grey">mousedown</span> Event</h5>
                <p>Here’s an example where a message is displayed when the user presses down on a div.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;div <span class="color-pink">id</span>=<span class="color-green">"box"</span> <span class="color-pink">style</span>=<span class="color-green">"width: 100px; height: 100px; background-color: lightblue;"</span>&gt;</p>
                        <p class="ml-30">    Click and hold me!</p>
                        <p>&lt;/div&gt;</p>
                        <p>&lt;p <span class="color-pink">id</span>=<span class="color-green">"status"</span>&gt;&lt;/p&gt;</p>
                        `
                    }}></div>
                </div>
                <h6>jQuery Code</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"#box"</span>).<span class="color-red">mousedown</span>(<span class="color-blue">function</span>() {</p>
                        <p class="ml-30">    $(<span class="color-green">"#status"</span>).<span class="color-red">text</span>(<span class="color-green">"Mouse button pressed down on the box!"</span>);</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>When a mouse button is pressed down on the <span class="background-grey">#box</span> div, the <span class="background-grey">mousedown</span> event triggers, and the text in <span class="background-grey">#status</span> updates to show a message.</li>
                </ul>



            </div>
        </section>
    )
}