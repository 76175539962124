import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/variables-and-data-types";

export default function VariableDataTypes() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Variables and Data Types in Javascript | Aspirant's Home");
        const urls = {
            'previous': '/javascript/environment-setup',
            'next': '/javascript/operators'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Variables and Data Types</h3>
            <div className='mt-4 mb-5'>
                <p>Variables and data types are the building blocks of JavaScript programming.</p>

                <h5 className='mt-5 mb-3'>What is a Variable?</h5>
                <p>A <strong>variable</strong> is like a container where you can store data (e.g., a number, a word, or more complex data).</p>
                <p>You can think of it like a box with a name on it, where the name lets you identify what’s inside.</p>

                <h5 className='mt-5 mb-3'>How to Declare Variables in JavaScript</h5>
                <p>You declare a variable using <strong>var</strong>, <strong>let</strong>, or <strong>const</strong>.</p>

                <h6 className='mt-3'>Syntax</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">let</span> variableName = value;</p>
                        `
                    }}></div>
                </div>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span className='background-grey'>let</span>: Declares a variable that can be changed later.</li>
                    <li><span className='background-grey'>const</span>: Declares a variable whose value cannot be changed.</li>
                    <li><span className='background-grey'>var</span>: Older way to declare variables (use <span className='background-grey'>let</span> or <span className='background-grey'>const</span> instead for modern coding).</li>
                </ul>
                <h6 className='mt-3'>Example</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">let</span> age = <span class="color-pink">25</span>;  <span class="color-grey">// age is the variable name, and 25 is the value</span></p>
                            <p><span class="color-blue">const</span> name = <span class="color-green">"John"</span>;  <span class="color-grey">// name is the variable, and "John" is the value</span></p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-3'>Rules for Naming Variables</h5>
                <ul style={{listStyle: 'decimal'}}>
                    <li>Variable names can include letters, numbers, <span className='background-grey'>$</span>, and <span className='background-grey'>_</span> (but can’t start with a number).</li>
                    <li>They are <strong>case-sensitive</strong> (<span className='background-grey'>age</span> and <span className='background-grey'>Age</span> are different).</li>
                    <li>Use meaningful names to describe the purpose of the variable:
                        <ul style={{listStyle: 'disc'}}>
                            <li>Good: <span className='background-grey'>userAge</span>, <span className='background-grey'>totalPrice</span></li>
                            <li>Bad: <span className='background-grey'>x</span>, <span className='background-grey'>temp</span></li>
                        </ul>
                    </li>
                </ul>

                <h5 className='mt-5 mb-3'>Data Types in JavaScript</h5>
                <p>Data types represent the kind of data you can store in a variable. JavaScript has <strong>two categories</strong> of data types:</p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li><strong>Primitive Data Types</strong> (basic, single value)</li>
                    <li><strong>Non-Primitive Data Types</strong> (complex, like objects)</li>
                </ul>

                <h6 className='mt-3'>Primitive Data Types</h6>
                <p>These hold a single value.</p>
                <table className='borderClass table table-bordered'>
                    <thead>
                        <tr>
                            <th style={{backgroundColor: '#ddd', width: '20%'}}>Data Type</th>
                            <th style={{backgroundColor: '#ddd'}}>Example</th>
                            <th style={{backgroundColor: '#ddd'}}>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>String</td>
                            <td><span className='background-grey'>"Hello"</span> or <span className='background-grey'>'World'</span></td>
                            <td>Text enclosed in quotes.</td>
                        </tr>
                        <tr>
                            <td>Number</td>
                            <td><span className='background-grey'>42</span> or <span className='background-grey'>3.14</span></td>
                            <td>Numbers (both integers and decimals).</td>
                        </tr>
                        <tr>
                            <td>Boolean</td>
                            <td><span className='background-grey'>true</span> or <span className='background-grey'>false</span></td>
                            <td>Logical values for yes/no or true/false scenarios.</td>
                        </tr>
                        <tr>
                            <td>Null</td>
                            <td><span className='background-grey'>null</span></td>
                            <td>Represents an empty or non-existent value.</td>
                        </tr>
                        <tr>
                            <td>Undefined</td>
                            <td>A declared variable without a value</td>
                            <td>A variable that has been declared but not initialized.</td>
                        </tr>
                        <tr>
                            <td>Symbol</td>
                            <td><span className='background-grey'>Symbol('unique')</span></td>
                            <td>A unique and immutable value. (Advanced use cases).</td>
                        </tr>
                    </tbody>
                </table>

                <h6 className='mt-3'>Example</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> name = <span class="color-green">"Alice"</span>;       <span class="color-grey">// String</span></p>
                        <p><span class="color-blue">let</span> age = <span class="color-pink">30</span>;             <span class="color-grey">// Number</span></p>
                        <p><span class="color-blue">let</span> isStudent = <span class="color-blue">false</span>;    <span class="color-grey">// Boolean</span></p>
                        <p><span class="color-blue">let</span> job = <span class="color-blue">null</span>;           <span class="color-grey">// Null</span></p>
                        <p><span class="color-blue">let</span> address;              <span class="color-grey">// Undefined</span></p>
                        `
                    }}></div>
                </div>

                <h6 className='mt-3'>Non-Primitive Data Types</h6>
                <p>These hold collections of values or more complex entities.</p>
                <table className='borderClass table table-bordered'>
                    <thead>
                        <tr>
                            <th style={{backgroundColor: '#ddd', width: '20%'}}>Data Type</th>
                            <th style={{backgroundColor: '#ddd'}}>Example</th>
                            <th style={{backgroundColor: '#ddd'}}>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Object</td>
                            <td><span className='background-grey'>&#123;name: "John", age: 25 &#125;</span></td>
                            <td>A collection of key-value pairs.</td>
                        </tr>
                        <tr>
                            <td>Array</td>
                            <td><span className='background-grey'>[1, 2, 3]</span></td>
                            <td>A list of values.</td>
                        </tr>
                    </tbody>
                </table>

                <h6 className='mt-3'>Example</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> person = { <span class="color-pink">name</span>: <span class="color-green">"John"</span>, <span class="color-pink">age</span>: <span class="color-pink">30</span> };  <span class="color-grey">// Object</span></p>
                        <p><span class="color-blue">let</span> numbers = [<span class="color-pink">1</span>, <span class="color-pink">2</span>, <span class="color-pink">3</span>, <span class="color-pink">4</span>];              <span class="color-grey">// Array</span></p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-3'>Checking Data Types</h5>
                <p>You can check the type of a variable using the <span className='background-grey'>typeof</span> operator.</p>
                <h6 className='mt-3'>Example</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> age = <span class="color-pink">25</span>;</p>
                        <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-pink">typeof</span> age);  <span class="color-grey">// Output: "number"</span></p>
                        <br />
                        <p><span class="color-blue">let</span> name = <span class="color-green">"Alice"</span>;</p>
                        <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-pink">typeof</span> name); <span class="color-grey">// Output: "string"</span></p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Dynamic Typing</h5>
                <p>JavaScript is <strong>dynamically typed</strong>, meaning a variable can hold any data type, and you can change it later.</p>
                <h6 className='mt-3'>Example</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> value = <span class="color-pink">42</span>;           <span class="color-grey">// Number</span></p>
                        <p>value = <span class="color-green">"Hello"</span>;          <span class="color-grey">// Now a String</span></p>
                        <p><span class="color-pink">console</span>.<span class="color-red">log</span>(value);       <span class="color-grey">// Output: "Hello"</span></p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-3'>How to Check If a Variable is Truly an Array</h5>
                <p>Since <span className='background-grey'>typeof</span> doesn’t differentiate between arrays and other objects, you can use the method <span className='background-grey'>Array.isArray()</span> to verify if something is an array:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> items = [<span class="color-green">"Pen"</span>, <span class="color-green">"Book"</span>];</p>
                        <p><span class="color-pink">console</span>.<span class="color-red">log</span>(Array.<span class="color-red">isArray</span>(items)); <span class="color-grey"> // Output: true</span></p>
                        `
                    }}></div>
                </div>



                <h5 className='mt-5 mb-3'>Some Examples</h5>
                <h6 className='mt-3'>Example 1: Declaring and Printing Variables</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> username = <span class="color-green">"JohnDoe"</span>;</p>
                        <p><span class="color-blue">let</span> age = <span class="color-pink">25</span>;</p>
                        <br />
                        <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Name:"</span>, username);  <span class="color-grey">// Output: Name: JohnDoe</span></p>
                        <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Age:"</span>, age);        <span class="color-grey">// Output: Age: 25</span></p>
                        `
                    }}></div>
                </div>

                <h6 className='mt-3'>Example 2: Working with Different Data Types</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> isAvailable = <span class="color-blue">true</span>;     <span class="color-grey">// Boolean</span></p>
                        <p><span class="color-blue">let</span> salary = <span class="color-blue">null</span>;          <span class="color-grey">// Null</span></p>
                        <p><span class="color-blue">let</span> items = [<span class="color-green">"Pen"</span>, <span class="color-green">"Book"</span>]; <span class="color-grey">// Array</span></p>
                        <br />
                        <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-pink">typeof</span> isAvailable);  <span class="color-grey">// Output: "boolean"</span></p>
                        <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-pink">typeof</span> salary);       <span class="color-grey">// Output: "object" (quirk of JavaScript)</span></p>
                        <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-pink">typeof</span> items);        <span class="color-grey">// Output: "object"</span></p>
                        `
                    }}></div>
                </div>
                <p>Here, In JavaScript, arrays are actually a special kind of object. Internally, JavaScript treats arrays as objects with numeric keys for indexing their elements. That's why <span className='background-grey'>typeof items</span> returns <span className='background-grey'>object</span>.</p>

            </div>
        </section>
    )
}