import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/jquery/hide-and-show";

export default function HideShow() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Hide/Show Effects of jQuery | Aspirant's Home");
        const urls = {
            'previous': '/jquery/events',
            'next': '/jquery/fade'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Hide/Show Effects of jQuery</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In jQuery, the <span class="background-grey">hide()</span> and <span class="background-grey">show()</span> methods are used to hide or display HTML elements, adding dynamic effects to web pages. They allow you to control the visibility of elements with just a few lines of code.
                </p>

                <h5 className='mt-5 mb-3'><span class="background-grey">hide()</span> Method</h5>
                <p>The <span class="background-grey">hide()</span> method makes the selected element(s) invisible. You can specify a speed parameter to add a smooth hiding animation. Here is the syntax -</p> 
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(selector).<span class="color-red">hide</span>(speed, callback);</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">speed (optional)</span> defines the duration of the hide animation. Can be <span class="background-grey">"slow"</span>, <span class="background-grey">"fast"</span>, or a specific duration in milliseconds (e.g., <span class="background-grey">500</span>).</li>
                    <li><span class="background-grey">callback (optional)</span> is a function to execute after the <span class="background-grey">hide()</span> animation completes.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Some Examples of <span class="background-grey">hide()</span> Method</h5>
                <p>Here are some examples of <span class="background-grey">hide()</span> method -</p>

                <h6><strong>Example 1</strong>: Hide an element instantly</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(<span class="color-green">"#myElement"</span>).<span class="color-red">hide</span>(); <span class="color-grey">// Hides the element immediately</span></p>
                        `
                    }}></div>
                </div>

                <h6><strong>Example 2</strong>: Hide an element with a speed</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(<span class="color-green">"#myElement"</span>).<span class="color-red">hide</span>(<span class="color-green">"slow"</span>); <span class="color-grey">// Hides the element with a slow animation</span></p>
                        `
                    }}></div>
                </div>

                <h6><strong>Example 3</strong>: Hide an element with a callback</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"#myElement"</span>).<span class="color-red">hide</span>(<span class="color-pink">500</span>, <span class="color-blue">function</span>() {</p>
                        <p class="ml-30">    <span class="color-red">alert</span>(<span class="color-green">"Element is now hidden!"</span>);</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example, the element will hide over 500 milliseconds, and an alert will display once the hiding is complete.</p>



                <h5 className='mt-5 mb-3'><span class="background-grey">show()</span> Method</h5>
                <p>The <span class="background-grey">show()</span> method reveals the selected element(s) if they are hidden. You can also specify a speed parameter to add a smooth showing animation. Below is the syntax -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(selector).<span class="color-red">show</span>(speed, callback);</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">speed</span> (optional) defines the duration of the show animation. Options are <span class="background-grey">"slow"</span>, <span class="background-grey">"fast"</span>, or a specific duration in milliseconds (e.g., <span class="background-grey">500</span>).</li>
                    <li><span class="background-grey">callback</span> (optional) is a function to execute after the <span class="background-grey">show()</span> animation completes.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Some Examples of <span class="background-grey">show()</span> Method</h5>
                <p>Here are some examples of <span class="background-grey">show()</span> method -</p>

                <h6><strong>Example 1</strong>: Show an element instantly</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(<span class="color-green">"#myElement"</span>).<span class="color-red">show</span>(); <span class="color-grey">// Shows the element immediately</span></p>
                        `
                    }}></div>
                </div>

                <h6><strong>Example 2</strong>: Show an element with a speed</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(<span class="color-green">"#myElement"</span>).<span class="color-red">show</span>(<span class="color-green">"fast"</span>); <span class="color-grey">// Shows the element with a fast animation</span></p>
                        `
                    }}></div>
                </div>

                <h6><strong>Example 3</strong>: Show an element with a callback</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"#myElement"</span>).<span class="color-red">show</span>(<span class="color-pink">800</span>, <span class="color-red">function</span>() {</p>
                        <p class="ml-30">    <span class="color-red">alert</span>(<span class="color-green">"Element is now visible!"</span>);</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example, the element will show over 800 milliseconds, and an alert will display once the showing is complete.</p>



                <h5 className='mt-5 mb-3'><span class="background-grey">toggle()</span> Method</h5>
                <p>The jQuery <span class="background-grey">toggle()</span> method is a simple way to switch between showing and hiding an element. It acts as a visibility switch: if the element is currently visible, <span class="background-grey">toggle()</span> will hide it; if it's hidden, <span class="background-grey">toggle()</span> will show it. This makes <span class="background-grey">toggle()</span> particularly useful for creating interactive elements, like dropdown menus or expanding sections.</p>
                <p>Syntax will be given below -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(selector).<span class="color-red">toggle</span>(speed, callback);</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">selector</span> selects the element(s) you want to apply <span class="background-grey">toggle()</span> to.</li>
                    <li><span class="background-grey">speed</span> (optional) defines the speed of the toggle animation, with options like <span class="background-grey">"slow"</span>, <span class="background-grey">"fast"</span>, or a specific time in milliseconds (e.g., <span class="background-grey">500</span>).</li>
                    <li><span class="background-grey">callback</span> (optional) is a function to execute once the toggle animation is complete.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Some Examples of <span class="background-grey">toggle()</span> Method</h5>
                <p>Here are some examples of <span class="background-grey">toggle()</span> method -</p>

                <h6><strong>Example 1</strong>: Basic Toggle</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(<span class="color-green">"#myElement"</span>).<span class="color-red">toggle</span>();</p>
                        `
                    }}></div>
                </div>
                <p className='mb-4'>This code will hide <span class="background-grey">#myElement</span> if it's currently visible or show it if it's hidden. It happens instantly with no animation.</p>

                <h6><strong>Example 2</strong>: Toggle with Animation Speed</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(<span class="color-green">"#myElement"</span>).<span class="color-red">toggle</span>(<span class="color-green">"slow"</span>);</p>
                        `
                    }}></div>
                </div>
                <p className='mb-4'>This code will show or hide <span class="background-grey">#myElement</span> with a slow animation, giving a smooth transition effect.</p>

                <h6><strong>Example 3</strong>: Toggle with Animation Speed</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"#myElement"</span>).<span class="color-red">toggle</span>(<span class="color-pink">400</span>, <span class="color-blue">function</span>() {</p>
                        <p class="ml-30">    <span class="color-red">alert</span>(<span class="color-green">"Toggling is complete!"</span>);</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>This will toggle the visibility of <span class="background-grey">#myElement</span> over 400 milliseconds and, once completed, trigger an alert message.</p>


            </div>
        </section>
    )
}