import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Outlet, Link } from "react-router-dom";
export const pageUrl = () => "/angular/pure-vs-impure-pipes";

export default function PureImpurePipe() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Pure vs. Impure Pipes in Angular | Aspirant's Home");
        const urls = {
            'previous': '/angular/creating-custom-pipes',
            'next': '/angular/state-management'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Pure vs. Impure Pipes</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In Angular, pipes are used to transform data in templates. They come in two types: <strong>pure pipes</strong> and <strong>impure pipes</strong>. Understanding the difference is important when working with dynamic data in your application.
                </p>

                <h5 className='mt-5 mb-3'>Pure Pipes</h5>
                <p>A <strong>pure pipe</strong> is a pipe that only recalculates its output when the <strong>input reference changes</strong>. Pure pipes are faster because they don’t re-run the logic unless necessary. All custom and built-in pipes in Angular are <strong>pure by default</strong>.</p>


                <h5 className='mt-5 mb-3'>Example of a Pure Pipe</h5>
                <p>Here’s a simple pure pipe that converts text to uppercase:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">import</span> { <span class="color-blue">Pipe</span>, <span class="color-blue">PipeTransform</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                        <br />
                        <p><span class="color-red">@Pipe</span>({</p>
                        <p class="ml-30">    <span class="color-pink">name</span>: <span class="color-green">'uppercasePure'</span>,</p>
                        <p class="ml-30">    <span class="color-pink">pure</span>: <span class="color-blue">true</span>, <span class="color-grey">// Pure pipe by default</span></p>
                        <p>})</p>
                        <p><span class="color-pink">export class</span> <span class="color-blue">UppercasePurePipe</span> <span class="color-pink">implements</span> <span class="color-blue">PipeTransform</span> {</p>
                        <p class="ml-30">    <span class="color-red">transform</span>(value: <span class="color-pink">string</span>): <span class="color-pink">string</span> {</p>
                        <p class="ml-60">        <span class="color-pink">return</span> value.<span class="color-red">toUpperCase</span>();</p>
                        <p class="ml-30">    }</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>
                <h6 className='mt-3'>Template Example:</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;p&gt;{{ <span class="color-green">'hello'</span> | <span class="color-pink">uppercasePure</span> }}&lt;/p&gt;</p>
                        <p class="color-grey">&lt;!-- Output: HELLO --&gt;</p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-3'>Impure Pipes</h5>
                <p>An <strong>impure pipe</strong> recalculates its output on <strong>every change detection cycle</strong>, even if the input reference doesn’t change. Impure pipes can impact performance because they run frequently. To make a pipe impure, set <span class="background-grey">pure: false</span> in its metadata.</p>



                <h5 className='mt-5 mb-3'>Example of a Impure Pipe</h5>
                <p>Here’s an impure pipe that filters an array of numbers:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">import</span> { <span class="color-blue">Pipe</span>, <span class="color-blue">PipeTransform</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                        <br />
                        <p><span class="color-red">@Pipe</span>({</p>
                        <p class="ml-30">  <span class="color-pink">name</span>: <span class="color-green">'filterNumbers'</span>,</p>
                        <p class="ml-30">  <span class="color-pink">pure</span>: <span class="color-blue">false</span>, <span class="color-grey">// Impure pipe</span></p>
                        <p>})</p>
                        <p><span class="color-pink">export class</span> <span class="color-blue">FilterNumbersPipe</span> <span class="color-pink">implements</span> <span class="color-blue">PipeTransform</span> {</p>
                        <p class="ml-30">  <span class="color-red">transform</span>(values: <span class="color-pink">number</span>[], threshold: number): <span class="color-pink">number</span>[] {</p>
                        <p class="ml-60">    <span class="color-pink">return</span> values.<span class="color-red">filter</span>((value) => value > threshold);</p>
                        <p class="ml-30">  }</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>
                <h6 className='mt-3'>Template Example:</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;ul&gt;</p>
                        <p class="ml-30">    &lt;li *<span class="color-pink">ngFor</span>=<span class="color-green">"let num of numbers | filterNumbers:5"</span>&gt;{{ num }}&lt;/li&gt;</p>
                        <p>&lt;/ul&gt;</p>
                        `
                    }}></div>
                </div>
                <p>If numbers is <span class="background-grey">[1, 3, 6, 8]</span>, and the threshold is <span class="background-grey">5</span>, the output will be:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;li&gt;6&lt;/li&gt;</p>
                            <p>&lt;li&gt;8&lt;/li&gt;</p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-3'>Differences Between Pure and Impure Pipes</h5>

                <table className='borderClass table'>
                    <thead>
                        <tr>
                            <th>Aspect</th>
                            <th>Pure Pipe</th>
                            <th>Impure Pipe</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Default Behavior</td>
                            <td>Yes, pure by default</td>
                            <td>Must explicitly set <span class="background-grey">pure: false</span></td>
                        </tr>
                        <tr>
                            <td>Recalculation</td>
                            <td>Only when input reference changes</td>
                            <td>Every change detection cycle</td>
                        </tr>
                        <tr>
                            <td>Performance</td>
                            <td>Better performance</td>
                            <td>Slower performance, especially with large data</td>
                        </tr>
                        <tr>
                            <td>Use Case</td>
                            <td>Stable and predictable data transformations</td>
                            <td>Dynamic or frequently changing data</td>
                        </tr>
                    </tbody>
                </table>



            </div>
        </section>
    )
}