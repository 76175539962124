import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/jquery/events/select";

export default function Select() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("jQuery select() Method | Aspirant's Home");
        const urls = {
            'previous': '/jquery/events'
        }
        path.setPreviousNext(urls);
        path.setPreviousName('Back to Events');
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>jQuery select() Method</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">select()</span> method in jQuery is used to attach an event handler to the <strong>select event</strong> of a text input or textarea. This event triggers when a user selects some or all of the text in the input or textarea.
                </p>

                <p>It has a basic syntax -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(document).<span class="color-red">select</span>(function);</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">selector</span> specifies the element(s) to attach the select event.</li>
                    <li><span class="background-grey">function</span> is the callback function to execute when the select event is triggered.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Example 1: Detect Text Selection in an Input</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">id</span>=<span class="color-green">"textInput"</span> <span class="color-pink">value</span>=<span class="color-green">"Select this text"</span>&gt;</p>
                        `
                    }}></div>
                </div>
                <h6>jQuery Code</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"#textInput"</span>).<span class="color-red">select</span>(<span class="color-blue">function</span>() {</p>
                        <p class="ml-30">    <span class="color-red">alert</span>(<span class="color-green">"Text selected in the input!"</span>);</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>It displays an alert when the user selects text in the <span class="background-grey">#textInput</span> field.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Example 2: Updating Element Dimensions</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;textarea <span class="color-pink">id</span>=<span class="color-green">"myTextarea"</span>&gt;Select some text in this area.&lt;/textarea&gt;</p>
                        `
                    }}></div>
                </div>
                <h6>jQuery Code</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"#myTextarea"</span>).<span class="color-red">select</span>(<span class="color-blue">function</span>() {</p>
                        <p class="ml-30">    <span class="color-blue">let</span> selectedText = window.<span class="color-red">getSelection</span>().<span class="color-red">toString</span>();</p>
                        <p class="ml-30">    <span class="color-red">alert</span>(<span class="color-green">"You selected: "</span> + selectedText);</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>It alerts the text selected by the user within the <span class="background-grey">#myTextarea</span>.</li>
                </ul>



            </div>
        </section>
    )
}