import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/events/onsubmit";

export default function Onsubmit() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - onsubmit Event | Aspirant's Home");
        const urls = {
            'previous': '/html/events'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - onsubmit Event</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">onsubmit</span> event in HTML triggers when a form is submitted. This event is used to execute JavaScript code when the user submits a form, which is especially useful for client-side form validation, preventing submission under certain conditions, or modifying the submitted data before it’s sent to the server.
                </p>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <p>To use <span class="background-grey">onsubmit</span>, add it as an attribute to a <span class="background-grey">&lt;form&gt;</span> element, with JavaScript code or a function to run when the form is submitted.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;form <span class="color-pink">onsubmit</span>=<span class="color-green">"return validateForm()"</span>&gt;</p>
                        <p class="ml-30">    &lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">name</span>=<span class="color-green">"username"</span> <span class="color-pink">required</span>&gt;</p>
                        <p class="ml-30">    &lt;button <span class="color-pink">type</span>=<span class="color-green">"submit"</span>&gt;Submit&lt;/button&gt;</p>
                        <p>&lt;/form&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span class="background-grey">onsubmit</span> attribute triggers the <span class="background-grey">validateForm</span> function when the form is submitted.</li>
                    <li>The <span class="background-grey">return</span> statement allows the function to control submission: if <span class="background-grey">validateForm()</span> returns <span class="background-grey">false</span>, the form will not submit.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Example: Preventing Default Submission</h5>
                <p>You can use <span class="background-grey">onsubmit</span> to prevent default form submission, which is helpful when submitting data via AJAX without reloading the page.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;form <span class="color-pink">onsubmit</span>=<span class="color-green">"submitForm(event)"</span>&gt;</p>
                        <p class="ml-30">    &lt;label <span class="color-pink">for</span>=<span class="color-green">"username"</span>&gt;Username:&lt;/label&gt;</p>
                        <p class="ml-30">    &lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">id</span>=<span class="color-green">"username"</span> <span class="color-pink">name</span>=<span class="color-green">"username"</span>&gt;</p>
                        <p class="ml-30">    &lt;button <span class="color-pink">type</span>=<span class="color-green">"submit"</span>&gt;Submit&lt;/button&gt;</p>
                        <p>&lt;/form&gt;</p>
                        <br />
                        <p>&lt;script&gt;</p>
                        <p class="ml-30">    <span class="color-blue">function</span> <span class="color-red">submitForm</span>(event) {</p>
                        <p class="ml-60">        event.<span class="color-red">preventDefault</span>(); <span class="color-grey">// Prevents default form submission</span></p>
                        <p class="ml-60">        <span class="color-blue">const</span> username = document.<span class="color-red">getElementById</span>(<span class="color-green">"username"</span>).value;</p>
                        <p class="ml-60">        <span class="color-red">alert</span>(<span class="color-green">"Submitting "</span> + username + <span class="color-green">" via AJAX"</span>);</p>
                        <p class="ml-60 color-grey">        // AJAX code here to submit the form data</p>
                        <p class="ml-30">    }</p>
                        <p>&lt;/script&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">event.preventDefault()</span> stops the form from reloading the page on submit.</li>
                    <li>You could then use AJAX to send form data without page reload.</li>
                </ul>



            </div>
        </section>
    )
}