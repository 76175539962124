import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/jquery/events/event-target";

export default function Target() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("jQuery event.target Object | Aspirant's Home");
        const urls = {
            'previous': '/jquery/events'
        }
        path.setPreviousNext(urls);
        path.setPreviousName('Back to Events');
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>jQuery event.target Object</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">event.target</span> object in jQuery refers to the <strong>DOM element</strong> that triggered the event. It gives you the element where the event originated, not necessarily the element where the event handler is attached.
                </p>

                <p>It has a basic syntax -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(selector).<span class="color-red">on</span>(event, childSelector, <span class="color-blue">function</span>(event) {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(event.target);</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Example : Simple Use Case</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;ul&gt;</p>
                        <p class="ml-30">    &lt;li <span class="color-pink">id</span>=<span class="color-green">"li1"</span>&gt;Item 1&lt;/li&gt;</p>
                        <p class="ml-30">    &lt;li <span class="color-pink">id</span>=<span class="color-green">"li2"</span>&gt;Item 2&lt;/li&gt;</p>
                        <p class="ml-30">    &lt;li <span class="color-pink">id</span>=<span class="color-green">"li3"</span>&gt;Item 3&lt;/li&gt;</p>
                        <p>&lt;/ul&gt;</p>
                        `
                    }}></div>
                </div>
                <h6>jQuery Code</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"ul"</span>).<span class="color-red">on</span>(<span class="color-green">"click"</span>, <span class="color-blue">function</span>(event) {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Event target:"</span>, event.target);</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>When any <span class="background-grey">&lt;li&gt;</span> is clicked, the event.target gives the clicked <span class="background-grey">&lt;li&gt;</span> element.</li>
                    <li>Output (if Item 2 is clicked): <span class="background-grey">Event target: &lt;li&gt;Item 2&lt;/li&gt;</span></li>
                </ul>

                <p>You can get the specific details of clicked <span class="background-grey">li</span> element by using <span class="background-grey">event.target</span> object.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"ul"</span>).<span class="color-red">on</span>(<span class="color-green">"click"</span>, <span class="color-blue">function</span>(event) {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"You clicked:"</span>, event.target.id);</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>Output (if Item 2 is clicked): <span class="background-grey">You clicked: li2</span></li>
                </ul>


            </div>
        </section>
    )
}