import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/object-definition";

export default function ObjectDefinition() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("What is an Object in JavaScript | Aspirant's Home");
        const urls = {
            'previous': '/javascript/control-structure',
            'next': '/javascript/object-prototypes'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>What is an Object in JavaScript?</h3>
            <div className='mt-4 mb-5'>

                <p>An <span className='background-grey'>object</span> in JavaScript is like a <span className='background-grey'>real-life</span> thing. It has <span className='background-grey'>properties</span> (like its details) and <span className='background-grey'>actions</span> (what it can do).</p>

                <p>Think of a <span className='background-grey'>car</span> as an example:</p>
                <ul style={{listStyle: 'disc'}}>
                    <li>It has <span className='background-grey'>properties</span>: color, brand, model, year.</li>
                    <li>It has <span className='background-grey'>actions</span>: start, stop, honk.</li>
                </ul>
                <p>In JavaScript, you use <span className='background-grey'>objects</span> to group related information together.</p>



                <p>An <strong>object</strong> in JavaScript is a collection of <strong>key-value pairs</strong>. The keys are called <strong>properties</strong>, and the values can be any data type, including other objects, arrays, or functions.</p>

                <p>Objects are a fundamental building block in JavaScript and allow you to group related data and functionality together.</p>


                <h5 className='mt-5 mb-3'>Defining an Object</h5>
                <p>You can define an object in JavaScript in two main ways:</p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li className='mt-3'>
                        <h6>Using Object Literals <span className='background-grey'>Curly Braces (&#123; &#125;)</span></h6>
                        <p>This is the most common way to create an object. Syntax is -</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> objectName = {</p>
                                <p class="ml-30">    <span class="color-pink">key1</span>: value1,</p>
                                <p class="ml-30">    <span class="color-pink">key2</span>: value2,</p>
                                <p class="ml-30">    <span class="color-pink">key3</span>: value3</p>
                                <p>};</p>
                                `
                            }}></div>
                        </div>
                        <p>Let's take an example -</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> person = {</p>
                                <p class="ml-30">    <span class="color-pink">name</span>: <span class="color-green">"John"</span>,</p>
                                <p class="ml-30">    <span class="color-pink">age</span>: <span class="color-pink">25</span>,</p>
                                <p class="ml-30">    <span class="color-pink">isStudent</span>: <span class="color-blue">false</span></p>
                                <p>};</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='mt-3'>
                        <h6>Using the <span className='background-grey'>new Object()</span> Syntax</h6>
                        <p>This method uses JavaScript's built-in Object constructor. Syntax is below -</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> objectName = <span class="color-blue">new</span> <span class="color-red">Object</span>();</p>
                                <p>objectName.key1 = value1;</p>
                                <p>objectName.key2 = value2;</p>
                                `
                            }}></div>
                        </div>
                        <p>Let's take an example -</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">let</span> car = <span class="color-blue">new</span> <span class="color-red">Object</span>();</p>
                                <p>car.brand = <span class="color-green">"Toyota"</span>;</p>
                                <p>car.model = <span class="color-green">"Corolla"</span>;</p>
                                <p>car.year = <span class="color-pink">2023</span>;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>


            </div>
        </section>
    )
}