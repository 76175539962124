import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/break-statement";

export default function BreakStatement() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Break Statement in Javascript | Aspirant's Home");
        const urls = {
            'previous': '/javascript/loop-while',
            'next': '/javascript/object-definition'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Break Statement </h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className='background-grey'>break</span> <strong>statement</strong> in JavaScript is used to <strong>exit a loop</strong> or a <span className='background-grey'>switch</span> statement <strong>immediately</strong>, regardless of the remaining iterations or cases. It allows you to stop the execution of a loop or <span className='background-grey'>switch</span> when a certain condition is met.
                </p>

                <h5 className='mt-5 mb-3'>Why Use <span className='background-grey'>break</span>?</h5>
                <p>The <span className='background-grey'>break</span> statement is useful when:</p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>You want to stop a loop early, before it finishes all iterations.</li>
                    <li>You need to exit a <span className='background-grey'>switch</span> statement after a matching case is found.</li>
                </ul>

                <h5 className='mt-5 mb-3'>How Does <span className='background-grey'>break</span> Work in Loops?</h5>
                <p>When the break statement is executed inside a loop, it <strong>immediately stops the loop</strong>, and the program continues with the code after the loop.</p>


                <h5 className='mt-5 mb-3'>Example 1: Using <span className='background-grey'>break</span> in a <span className='background-grey'>for</span> Loop</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">for</span> (<span class="color-blue">let</span> i = <span class="color-pink">1</span>; i <= <span class="color-pink">10</span>; i<span class="color-pink">++</span>) {</p>
                        <p class="ml-30">    <span class="color-blue">if</span> (i === <span class="color-pink">5</span>) {</p>
                        <p class="ml-60">        <span class="color-blue">break;</span> <span class="color-grey">// Exit the loop when i equals </span></p>
                        <p class="ml-30">    }</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(i);</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>
                <p>Output</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p class="color-pink">1</p>
                        <p class="color-pink">2</p>
                        <p class="color-pink">3</p>
                        <p class="color-pink">4</p>
                        `
                    }}></div>
                </div>
                <p>Here,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The loop starts with <span className='background-grey'>i = 1</span>.</li>
                    <li>When <span className='background-grey'>i</span> reaches <span className='background-grey'>5</span>, the <span className='background-grey'>break</span> statement is executed, stopping the loop immediately.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Example 2: Using <span className='background-grey'>break</span> in a <span className='background-grey'>while</span> Loop</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> count = <span class="color-pink">1</span>;</p>
                        <br />
                        <p><span class="color-blue">while</span> (count <= <span class="color-pink">10</span>) {</p>
                        <p class="ml-30">    <span class="color-blue">if</span> (count === <span class="color-pink">6</span>) {</p>
                        <p class="ml-60">        <span class="color-blue">break</span>; <span class="color-grey">// Exit the loop when count equals 6</span></p>
                        <p class="ml-30">    }</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(count);</p>
                        <p class="ml-30">    count<span class="color-pink">++</span>;</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>
                <p>Output</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p class="color-pink">1</p>
                        <p class="color-pink">2</p>
                        <p class="color-pink">3</p>
                        <p class="color-pink">4</p>
                        <p class="color-pink">5</p>
                        `
                    }}></div>
                </div>
                <p>Here,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The loop runs as long as <span className='background-grey'>count &lt;= 10</span>.</li>
                    <li>When <span className='background-grey'>count</span> reaches <span className='background-grey'>6</span>, the break statement stops the loop.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Example 3: Using <span className='background-grey'>break</span> in a <span className='background-grey'>switch</span> Statement</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> color = <span class="color-green">"blue"</span>;</p>

                        <p><span class="color-blue">switch</span> (color) {</p>
                        <p class="ml-30">    <span class="color-blue">case</span> <span class="color-green">"red"</span>:</p>
                        <p class="ml-60">        <span class="color-pink">console</spna>.<span class="color-red">log</span>(<span class="color-green">"Color is red"</span>);</p>
                        <p class="ml-30">        <span class="color-blue">break</span>;</p>
                        <p class="ml-30">    <span class="color-blue">case</span> <span class="color-green">"blue"</span>:</p>
                        <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Color is blue"</span>);</p>
                        <p class="ml-60">        <span class="color-blue">break</span>;</p>
                        <p class="ml-30">    <span class="color-blue">case</span> <span class="color-green">"green"</span>:</p>
                        <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Color is green"</span>);</p>
                        <p class="ml-60">        <span class="color-blue">break</span>;</p>
                        <p class="ml-30">    <span class="color-blue">default</span>:</p>
                        <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Color not found"</span>);</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>
                <p>Output</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>Color is blue</p>
                        `
                    }}></div>
                </div>
                <p>Here,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span className='background-grey'>switch</span> statement matches the case <span className='background-grey'>"blue"</span>.</li>
                    <li>The <span className='background-grey'>break</span> statement exits the <span className='background-grey'>switch</span> block after executing the code for <span className='background-grey'>"blue"</span>.</li>
                </ul>

                <h5 className='mt-5 mb-3'>When to Use <span className='background-grey'>break</span></h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li><strong>Exiting Loops</strong>: Use <span className='background-grey'>break</span> when you want to stop a loop based on a specific condition.</li>
                    <li><strong>In <span className='background-grey'>switch</span> Statements</strong>: Use <span className='background-grey'>break</span> to prevent unwanted execution of other cases after a match.</li>
                </ul>


            </div>
        </section>
    )
}