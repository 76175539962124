import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/events/onclick";

export default function Onclick() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - onclick Event | Aspirant's Home");
        const urls = {
            'previous': '/html/events'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - onclick Event</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">onclick</span> event in HTML is an attribute used to execute JavaScript code when an HTML element is clicked. This event can be added to various elements, such as buttons, links, images, or even divs, allowing for interactivity in a webpage. For instance, it can be used to display an alert, call a function, toggle content visibility, or perform any other JavaScript action upon clicking.
                </p>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <p>To use <span class="background-grey">onclick</span>, add the attribute to an HTML element and specify the JavaScript code or function you want to run when the element is clicked.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;button <span class="color-pink">onclick</span>=<span class="color-green">"alert('Button clicked!')"</span>&gt;Click Me&lt;/button&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example, when the button is clicked, an alert box with the message "Button clicked!" appears.</p>


                <h5 className='mt-5 mb-3'>Usage of <span class="background-grey">onclick</span> with JavaScript Functions</h5>
                <p>It’s often better to call a JavaScript function in the <span class="background-grey">onclick</span> attribute instead of writing the entire code directly inside it, especially if the code is complex.</p>

                <h6>Example: Calling a Function on Click</h6>
                <p>Define a function in JavaScript, then call it using <span class="background-grey">onclick</span>.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                        <p class="ml-60">        &lt;title&gt;onclick Example&lt;/title&gt;</p>
                        <p class="ml-60">        &lt;script&gt;</p>
                        <p class="ml-90">            <span class="color-blue">function</span> <span class="color-red">showMessage</span>() {</p>
                        <p class="ml-120">                <span class="color-red">alert</span>(<span class="color-green">"Hello, this message is from a function!"</span>);</p>
                        <p class="ml-90">            }</p>
                        <p class="ml-60">        &lt;/script&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <br />    
                        <p class="ml-60">        &lt;button <span class="color-pink">onclick</span>=<span class="color-green">"showMessage()"</span>&gt;Click Me&lt;/button&gt;</p>
                        <br />    
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>

                <h6>Example: Changing Content with <span class="background-grey">onclick</span></h6>
                <p>You can also use <span class="background-grey">onclick</span> to change the content or style of an element when it’s clicked.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;p <span class="color-pink">id</span>=<span class="color-green">"text"</span>&gt;Click the button to change this text.&lt;/p&gt;</p>
                        <p>&lt;button <span class="color-pink">onclick</span>=<span class="color-green">"document.getElementById('text').innerText = 'Text has been changed!'"</span>&gt;Change Text&lt;/button&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example, when the button is clicked, the text within the paragraph is updated.</p>


                <h6>Example: Show/Hide Content with <span class="background-grey">onclick</span></h6>
                <p>Using <span class="background-grey">onclick</span>, you can toggle the visibility of elements on the page, like showing or hiding content.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Show/Hide Example&lt;/title&gt;</p>
                        <p class="ml-60">        &lt;style&gt;</p>
                        <p class="ml-90">            <span class="color-pink">#content</span> {</p>
                        <p class="ml-120">                <span class="color-green">display</span>: none;</p>
                        <p class="ml-90">            }</p>
                        <p class="ml-60">        &lt;/style&gt;</p>
                        <p class="ml-60">        &lt;script&gt;</p>
                        <p class="ml-90">            <span class="color-blue">function</span> <span class="color-red">toggleContent</span>() {</p>
                        <p class="ml-120">                <span class="color-blue">const</span> content = document.<span class="color-red">getElementById</span>(<span class="color-green">"content"</span>);</p>
                        <p class="ml-120">                content.style.display = content.style.display === <span class="color-green">"none"</span> ? <span class="color-green">"block"</span> : <span class="color-green">"none"</span>;</p>
                        <p class="ml-90">            }</p>
                        <p class="ml-60">        &lt;/script&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <br />    
                        <p class="ml-60">        &lt;button <span class="color-pink">onclick</span>=<span class="color-red">"toggleContent()"</span>&gt;Show/Hide Content&lt;/button&gt;</p>
                        <p class="ml-60">        &lt;div <span class="color-pink">id</span>=<span class="color-green">"content"</span>&gt;This is the content to show or hide.&lt;/div&gt;</p>
                        <br />    
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Here, clicking the button toggles the <span class="background-grey">display</span> style of the <span class="background-grey">#content</span> div between <span class="background-grey">"block"</span> and <span class="background-grey">"none"</span>, making it appear and disappear.</p>




            </div>
        </section>
    )
}