import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/events/onchange";

export default function Onchange() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - onchange Event | Aspirant's Home");
        const urls = {
            'previous': '/html/events'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - onchange Event</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">onchange</span> event in HTML is triggered when the value of an element is changed and the focus is moved away (or the user presses "Enter" in some cases). It’s commonly used with form elements like <span class="background-grey">&lt;input&gt;</span>, <span class="background-grey">&lt;select&gt;</span>, <span class="background-grey">&lt;textarea&gt;</span>, and <span class="background-grey">&lt;input type="checkbox"&gt;</span>. This event is helpful for validating or processing data only after the user finishes making changes, rather than during every keystroke.
                </p>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <p>To use <span class="background-grey">onchange</span>, add it as an attribute to an HTML element, along with JavaScript code or a function to execute when the element’s value changes.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">onchange</span>=<span class="color-green">"alert('Value changed!')"</span> <span class="color-pink">placeholder</span>=<span class="color-green">"Enter text"</span>&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example, An alert will appear when the value in the input field is changed and the user clicks out of the field or presses "Enter."</p>


                <h5 className='mt-5 mb-3'>Example: Displaying Selected Option in a Dropdown</h5>
                <p>With <span class="background-grey">onchange</span>, you can get and display the selected value from a dropdown menu.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;label <span class="color-pink">for</span>=<span class="color-green">"fruits"</span>&gt;Choose a fruit:&lt;/label&gt;</p>
                        <p>&lt;select <span class="color-pink">id</span>=<span class="color-green">"fruits"</span> <span class="color-pink">onchange</span>=<span class="color-green">"showSelectedFruit()"</span>&gt;</p>
                        <p class="ml-30">    &lt;option <span class="color-pink">value</span>=<span class="color-green">"apple"</span>&gt;Apple&lt;/option&gt;</p>
                        <p class="ml-30">    &lt;option <span class="color-pink">value</span>=<span class="color-green">"banana"</span>&gt;Banana&lt;/option&gt;</p>
                        <p class="ml-30">    &lt;option <span class="color-pink">value</span>=<span class="color-green">"cherry"</span>&gt;Cherry&lt;/option&gt;</p>
                        <p>&lt;/select&gt;</p>
                        <p>&lt;p <span class="color-pink">id</span>=<span class="color-green">"selectedFruit"</span>&gt;&lt;/p&gt;</p>
                        <br />
                        <p>&lt;script&gt;</p>
                        <p class="ml-30">    <span class="color-blue">function</span> <span class="color-red">showSelectedFruit</span>() {</p>
                        <p class="ml-60">        <span class="color-blue">const</span> selectedValue = document.<span class="color-red">getElementById</span>(<span class="color-green">"fruits"</span>).value;</p>
                        <p class="ml-60">        document.<span class="color-red">getElementById</span>(<span class="color-green">"selectedFruit"</span>).textContent = <span class="color-green">"You selected: "</span> + selectedValue;</p>
                        <p class="ml-30">    }</p>
                        <p>&lt;/script&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example, The <span class="background-grey">showSelectedFruit</span> function updates a <span class="background-grey">&lt;p&gt;</span> element with the selected fruit when the dropdown changes.</p>





            </div>
        </section>
    )
}