import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/jquery/events/event-isimmediatepropagationstopped";

export default function IsImmediatePropagationStopped() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("jQuery event.isImmediatePropagationStopped() Object | Aspirant's Home");
        const urls = {
            'previous': '/jquery/events'
        }
        path.setPreviousNext(urls);
        path.setPreviousName('Back to Events');
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>jQuery event.isImmediatePropagationStopped() Object</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">event.isImmediatePropagationStopped()</span> method in jQuery checks if the <strong>immediate propagation</strong> of an event has been stopped. This happens when the <span class="background-grey">event.stopImmediatePropagation()</span> method is called, which prevents both:
                </p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>The event from propagating to other handlers on parent elements (like <span class="background-grey">event.stopPropagation()</span> does).</li>
                    <li><strong>Any additional handlers of the same event</strong> bound to the same element from executing.</li>
                </ul>

                <p>It has a basic syntax -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>event.<span class="color-red">isImmediatePropagationStopped</span>();</p>
                        `
                    }}></div>
                </div>
                <p>It return <span class="background-grey">true</span>, if <span class="background-grey">event.stopImmediatePropagation()</span> has been called. It return <span class="background-grey">false</span>, if immediate propagation is not stopped.</p>


                <h5 className='mt-5 mb-3'>Example : Stopping Immediate Propagation</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;button <span class="color-pink">id</span>=<span class="color-green">"myButton"</span>&gt;Click Me&lt;/button&gt;</p>
                        `
                    }}></div>
                </div>
                <h6>jQuery Code</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"#myButton"</span>).<span class="color-red">on</span>(<span class="color-green">"click"</span>, <span class="color-blue">function</span>(event) {</p>
                        <p class="ml-30">    event.<span class="color-red">stopImmediatePropagation</span>();</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Handler 1 executed."</span>);</p>
                        <p>});</p>
                        <br />
                        <p>$(<span class="color-green">"#myButton"</span>).<span class="color-red">on</span>(<span class="color-green">"click"</span>, <span class="color-blue">function</span>(event) {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Handler 2 executed."</span>); <span class="color-grey">// This won't execute</span></p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>When the button is clicked: -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The first handler (<span class="background-grey">Handler 1</span>) will execute and call <span class="background-grey">event.stopImmediatePropagation()</span>.</li>
                    <li>The second handler (<span class="background-grey">Handler 2</span>) will not execute because immediate propagation is stopped.</li>
                </ul>





            </div>
        </section>
    )
}