import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/tag/li";

export default function LiTag() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - <li> Tag | Aspirant's Home");
        const urls = {
            'previous': '/html/tag-list'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - &lt;li&gt; Tag</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">&lt;li&gt;</span> tag in HTML is used to define individual list items within a list. The <span class="background-grey">&lt;li&gt;</span> tag is placed inside either an unordered list (<span class="background-grey">&lt;ul&gt;</span>), an ordered list (<span class="background-grey">&lt;ol&gt;</span>), or a menu list (<span class="background-grey">&lt;menu&gt;</span>), where each <span class="background-grey">&lt;li&gt;</span> represents a single item in that list.
                </p>

                <h5 className='mt-5 mb-3'>Structure and Purpose of <span class="background-grey">&lt;li&gt;</span> Tag</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span class="background-grey">&lt;li&gt;</span> tag is used to create items in a list, providing structure to ordered or unordered data.</li>
                    <li>It can be used in various types of lists, like bullet lists (unordered), numbered lists (ordered), or even menu-style lists.</li>
                    <li><span class="background-grey">&lt;li&gt;</span> items can be styled with CSS for custom layouts, icons, colors, and more.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <p>The <span class="background-grey">&lt;li&gt;</span> tag is used inside list tags like <span class="background-grey">&lt;ul&gt;</span>, <span class="background-grey">&lt;ol&gt;</span>, or <span class="background-grey">&lt;menu&gt;</span>:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;ul&gt;</p>
                        <p class="ml-30">    &lt;li&gt;List item 1&lt;/li&gt;</p>
                        <p class="ml-30">    &lt;li&gt;List item 2&lt;/li&gt;</li></p>
                        <p class="ml-30">    &lt;li&gt;List item 3&lt;/li&gt;</li></p>
                        <p>&lt;/ul&gt;</p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-3'>Example Usage of <span class="background-grey">&lt;li&gt;</span> Tag</h5>
                <p>Here’s a simple example of using <span class="background-grey">&lt;li&gt;</span> within an unordered list:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Unordered List Example&lt;/title&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <br />    
                        <p class="ml-60">        &lt;h2&gt;Shopping List&lt;/h2&gt;</p>
                        <p class="ml-60">        &lt;ul&gt;</p>
                        <p class="ml-90">            &lt;li&gt;Apples&lt;/li&gt;</p>
                        <p class="ml-90">            &lt;li&gt;Bananas&lt;/li&gt;</p>
                        <p class="ml-90">            &lt;li&gt;Oranges&lt;/li&gt;</p>
                        <p class="ml-60">        &lt;/ul&gt;</p>
                        <br />    
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div> 
                <p>In this example, the list items "Apples," "Bananas," and "Oranges" appear with bullets because they are in an unordered list (<span class="background-grey">&lt;ul&gt;</span>).</p>
                <p>Output will be the following -</p>
                <div className='outputPalateBox mt-2 mb-4'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <!DOCTYPE html>
                        <html lang="en">
                        <head>
                          <meta charset="UTF-8">
                          <title>Unordered List Example</title>
                        </head>
                        <body>
                        
                        <h2>Shopping List</h2>
                        <ul>
                          <li>Apples</li>
                          <li>Bananas</li>
                          <li>Oranges</li>
                        </ul>
                        
                        </body>
                        </html>
                        `
                    }}></div>
                </div> 



            </div>
        </section>
    )
}