import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/jquery/events/scroll";

export default function Scroll() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("jQuery scroll() Method | Aspirant's Home");
        const urls = {
            'previous': '/jquery/events'
        }
        path.setPreviousNext(urls);
        path.setPreviousName('Back to Events');
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>jQuery scroll() Method</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">scroll()</span> method in jQuery is used to attach an event handler to the <strong>scroll event</strong> of an element, typically the <span class="background-grey">window</span> or a scrollable container. The handler is executed whenever the user scrolls the element.
                </p>

                <p>It has a basic syntax -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(document).<span class="color-red">scroll</span>(function);</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">function</span> is callback function to execute when the scroll event occurs.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Example 1: Detect Scrolling on the <span class="background-grey">window</span></h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(window).<span class="color-red">scroll</span>(<span class="color-blue">function</span>() {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"You are scrolling the page!"</span>);</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>It logs a message to the console every time the user scrolls the page.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Example 2: Get Scroll Position</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(window).<span class="color-red">scroll</span>(<span class="color-blue">function</span>() {</p>
                        <p class="ml-30">    <span class="color-blue">let</span> scrollTop = $(window).<span class="color-red">scrollTop</span>();</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Scroll position from the top: "</span> + scrollTop);</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>It retrieves the vertical scroll position from the top of the page and logs it.</li>
                </ul>


            </div>
        </section>
    )
}