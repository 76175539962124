import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/events/ontouchend";

export default function Ontouchend() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - ontouchend Event | Aspirant's Home");
        const urls = {
            'previous': '/html/events'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - ontouchend Event</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">ontouchend</span> event in HTML is triggered when a touch point (such as a finger or stylus) is removed from a touch-enabled device's screen. This event is part of the <strong>Touch Events API</strong>, which is used primarily for handling touch interactions on mobile and tablet devices.
                </p>
                <p>
                    The <span class="background-grey">ontouchend</span> event typically indicates the end of a touch gesture and can be used to finalize actions like dragging, drawing, or completing a tap.
                </p>


                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <p>You can use the <span class="background-grey">ontouchend</span> event directly in HTML, like this:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;div <span class="color-pink">ontouchend</span>=<span class="color-green">"handleTouchEnd(event)"</span>&gt;</p>
                        <p class="ml-30 color-grey">    Lift your finger from here!</p>
                        <p>&lt;/div&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Or attach it via JavaScript:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>document.<span class="color-red">getElementById</span>(<span class="color-green">"elementId"</span>).ontouchend = <span class="color-red">function</span>(event) {</p>
                        <p class="ml-30 color-grey">    // Your code here</p>
                        <p>};</p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-3'>Example: Basic <span class="background-grey">ontouchend</span> Event Handling</h5>
                <p>This example demonstrates how the <span class="background-grey">ontouchend</span> event is used to detect when a user lifts their finger from the screen, and changes the background color of the element.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                        <p class="ml-30">&lt;head&gt;</p>
                        <p class="ml-60">  &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                        <p class="ml-60">  &lt;meta <span class="color-pink">name</span>=<span class="color-green">"viewport"</span> <span class="color-pink">content</span>=<span class="color-green">"width=device-width, initial-scale=1.0"</span>&gt;</p>
                        <p class="ml-60">  &lt;title&gt;Touch End Example&lt;/title&gt;</p>
                        <p class="ml-30">&lt;/head&gt;</p>
                        <p class="ml-30">&lt;body&gt;</p>
                        <p class="ml-60">  &lt;div <span class="color-pink">id</span>=<span class="color-green">"touchArea"</span> <span class="color-pink">style</span>=<span class="color-green">"width: 200px; height: 200px; background-color: lightblue;"</span>&gt;</p>
                        <p class="ml-90">    Lift your finger!</p>
                        <p class="ml-60">  &lt;/div&gt;</p>
                        <br />
                        <p class="ml-60">  &lt;script&gt;</p>
                        <p class="ml-90">    <span class="color-blue">function</span> <span class="color-red">handleTouchEnd</span>(event) {</p>
                        <p class="ml-120">      event.<span class="color-red">preventDefault</span>(); <span class="color-grey">// Prevent default touch behaviors</span></p>
                        <p class="ml-120">      document.<span class="color-red">getElementById</span>(<span class="color-green">"touchArea"</span>).style.backgroundColor = <span class="color-green">"lightgreen"</span>; <span class="color-grey">// Change color</span></p>
                        <p class="ml-120">      <span class="color-red">alert</span>(<span class="color-green">"Touch ended!"</span>);</p>
                        <p class="ml-90">    }</p>
                        <br />
                        <p class="ml-90">    document.<span class="color-red">getElementById</span>(<span class="color-green">"touchArea"</span>).ontouchend = handleTouchEnd;</p>
                        <p class="ml-60">  &lt;/script&gt;</p>
                          <p class="ml-30">&lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>

                <p>The <span class="background-grey">ontouchend</span> event is used to detect when a touch point is removed. It works well for actions like completing a drag, tapping, or ending a gesture.</p>



            </div>
        </section>
    )
}