import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/object-prototypes";

export default function Prototypes() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Object Prototypes in JavaScript | Aspirant's Home");
        const urls = {
            'previous': '/javascript/object-definition',
            'next': '/javascript/object-methods'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Object Prototypes</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In JavaScript, <strong>prototypes</strong> are a way to share properties and methods across multiple objects. Think of it like a blueprint or a template that other objects can use.
                </p>
                <p>
                    Imagine you're building a class of students, and you want all students to have a common method to say hello. Instead of writing the same code for every student, you can put this method in the <strong>prototype</strong>, and all students can "inherit" it.
                </p>

                <h5 className='mt-5 mb-3'>Key Points about Prototypes</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>Every JavaScript object has a hidden property called <span className='background-grey'>[[Prototype]]</span> (you can access it using <span className='background-grey'>.prototype</span> or <span className='background-grey'>Object.getPrototypeOf)</span>.</li>
                    <li>Prototypes allow objects to share properties and methods.</li>
                    <li>This is called <strong>prototypal</strong> inheritance.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Creating an Object with a Prototype</h5>
                <p>Here’s how you can create and use prototypes:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p class="color-grey">// Create a constructor function</p>
                        <p><span class="color-blue">function</span> <span class="color-red">Student</span>(name, grade) {</p>
                        <p class="ml-30">    <span class="color-blue"></span>.name = name;  <span class="color-grey">// Instance property</span></p>
                        <p class="ml-30">    <span class="color-blue">this</span>.grade = grade; <span class="color-grey">// Instance property</span></p>
                        <p>}</p>
                        <br />
                        <p class="color-grey">// Add a method to the prototype</p>
                        <p><span class="color-blue">Student</span>.<span class="color-pink">prototype</span>.sayHello = <span class="color-red">function</span>() {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">&lt;Hello, my name is $&#123;this.name&#125;&gt;</span>);</p>
                        <p>};</p>
                        <br />
                        <p class="color-grey">// Create new student objects</p>
                        <p><span class="color-blue">let</span> student1 = <span class="color-blue">new</span> <span class="color-red">Student</span>(<span class="color-green">"Alice"</span>, <span class="color-green">"A"</span>);</p>
                        <p><span class="color-blue">let</span> student2 = <span class="color-blue">new</span> <span class="color-red">Student</span>(<span class="color-green">"Bob"</span>, <span class="color-green">"B"</span>);</p>
                        <br />
                        <p class="color-grey">// Call the shared method</p>
                        <p>student1.<span class="color-red">sayHello</span>();  <span class="color-grey">// Output: Hello, my name is Alice</span></p>
                        <p>student2.<span class="color-red">sayHello</span>();  <span class="color-grey">// Output: Hello, my name is Bob</span></p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>How Prototypes Work</h5>
                <p>When you try to access a property or method on an object:</p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>JavaScript first looks for it in the object itself.</li>
                    <li>If it’s not found, it looks in the object’s <strong>prototype</strong>.</li>
                    <li>This continues up the <strong>prototype chain</strong> until the property/method is found or the chain ends.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Why Use Prototypes?</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>Memory Efficiency:</strong>
                        <p>Instead of copying the same method for every object, the method exists in one place (the prototype) and is shared.</p>
                    </li>
                    <li>
                        <strong>Code Reusability:</strong>
                        <p>You can easily add new methods or properties to all objects that inherit from the prototype.</p>
                    </li>
                </ul>

                <h5 className='mt-5 mb-3'>Default Prototypes</h5>
                <p>Every object in JavaScript inherits from <span className='background-grey'>Object.prototype</span> by default. This is why you can use methods like <span className='background-grey'>.toString()</span> on any object.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> myObject = <span class="color-pink">{}</span>;</p>
                        <p><span class="color-pink">console</span>.<span class="color-red">log</span>(myObject.<span class="color-red">toString</span>());  <span class="color-grey">// Output: [object Object]</span></p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'><span className='background-grey'>class</span> Syntax</h5>
                <p>In modern JavaScript, you often use the <span className='background-grey'>class</span> keyword to define prototypes more cleanly.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">class</span> <span class="color-pink">Student</span> {</p>
                        <p class="ml-30">    <span class="color-red">constructor</span>(name, grade) {</p>
                        <p class="ml-60">        <span class="color-blue">this</span>.name = name;</p>
                        <p class="ml-60">        <span class="color-blue">this</span>.grade = grade;</p>
                        <p class="ml-30">    }</p>
                        <br />
                        <p class="ml-30 color-grey">    // Add methods to the prototype</p>
                        <p class="ml-30">    <span class="color-red">sayHello</span>() {</p>
                        <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">log(<span class="color-green">&#96;Hello, my name is $&#123;this.name&#125;&#96;</span>);</p>
                        <p class="ml-30">    }</p>
                        <p>}</p>
                        <br />
                        <p class="color-grey">// Create student objects</p>
                        <p><span class="color-blue">let</span> student1 = <span class="color-blue">new</span> <span class="color-red">Student</span>(<span class="color-green">"Alice"</span>, <span class="color-green">"A"</span>);</p>
                        <p><span class="color-blue">let</span> student2 = <span class="color-blue">new</span> <span class="color-red">Student</span>(<span class="color-green">"Bob"</span>, <span class="color-green">"B"</span>);</p>
                        <br />
                        <p>student1.<span class="color-red">sayHello</span>();  <span class="color-grey">// Output: Hello, my name is Alice</span></p>
                        <p>student2.<span class="color-red">sayHello</span>();  <span class="color-grey">// Output: Hello, my name is Bob</span></p>
                        `
                    }}></div>
                </div>


                <p className='mt-3'>
                    Prototype is like a <strong>template</strong> that objects use to share properties and methods. Methods added to the prototype are shared by all objects created from the same constructor. Use prototypes to make your code more efficient and reusable.
                </p>

            </div>
        </section>
    )
}