import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/events/onpaste";

export default function Onpaste() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - onpaste Event | Aspirant's Home");
        const urls = {
            'previous': '/html/events'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - onpaste Event</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">onpaste</span> event in HTML triggers when a user pastes content into an element. This event is commonly used in input fields or editable elements to monitor, validate, or modify pasted content.
                </p>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <p>The <span class="background-grey">onpaste</span> event can be added directly to an HTML element, like an <span class="background-grey">&lt;input&gt;</span> or <span class="background-grey">&lt;textarea&gt;</span>.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">onpaste</span>=<span class="color-green">"handlePaste()"</span> <span class="color-pink">placeholder</span>=<span class="color-green">"Try pasting here!"</span>&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Alternatively, it can be attached using JavaScript:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>document.<span class="color-red">getElementById</span>(<span class="color-green">"pasteInput"</span>).onpaste = <span class="color-red">function</span>() {</p>
                        <p class="ml-30">    console.<span class="color-red">log</span>(<span class="color-green">"Content pasted!"</span>);</p>
                        <p>};</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Example: Display a Message on Paste</h5>
                <p>This example shows how to log a message to the console when content is pasted.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">onpaste</span>=<span class="color-green">"showPasteMessage()"</span> <span class="color-pink">placeholder</span>=<span class="color-green">"Paste something here!"</span>&gt;</p>
                        <br />
                        <p>&lt;script&gt;</p>
                        <p class="ml-30">  <span class="color-blue">function</span> <span class="color-red">showPasteMessage</span>() {</p>
                        <p class="ml-60">       console.<span class="color-red">log</span>(<span class="color-green">"Content has been pasted!"</span>);</p>
                        <p class="ml-30">  }</p>
                        <p>&lt;/script&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Here, The <span class="background-grey">showPasteMessage</span> function logs a message when content is pasted into the input field.</p>




            </div>
        </section>
    )
}