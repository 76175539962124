import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/events/onmousedown";

export default function Onmousedown() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - onmousedown Event | Aspirant's Home");
        const urls = {
            'previous': '/html/events'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - onmousedown Event</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">onmousedown</span> event in HTML is triggered when a user presses down any mouse button on an HTML element. This event fires at the moment the mouse button is pressed, allowing you to define actions that should occur as soon as the button is clicked, even if the button isn't yet released. This event is often used for interactive elements to initiate actions, show tooltips, start animations, or detect specific button clicks.
                </p>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <p>To use <span class="background-grey">onmousedown</span>, add it as an attribute to an HTML element, specifying the JavaScript code or function to execute when the mouse button is pressed down.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;button <span class="color-pink">onmousedown</span>=<span class="color-green">"alert('Mouse button is pressed!')"</span>&gt;Press Mouse Here&lt;/button&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example, when a mouse button is pressed on the button, an alert box displays "Mouse button is pressed!" immediately.</p>

                <h5 className='mt-5 mb-3'>Mouse Button Detection with <span class="background-grey">onmousedown</span></h5>
                <p>Using <span class="background-grey">event.button</span>, you can determine which mouse button was pressed:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">0</span>: Left button</li>
                    <li><span class="background-grey">1</span>: Middle button (usually the scroll wheel button)</li>
                    <li><span class="background-grey">2</span>: Right button</li>
                </ul>

                <h6>Example: Detecting Which Mouse Button Was Pressed</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                        <p class="ml-60">        &lt;title&gt;onmousedown Example&lt;/title&gt;</p>
                        <p class="ml-60">        &lt;script&gt;</p>
                        <p class="ml-90">            <span class="color-blue">function</span> <span class="color-red">detectMouseButton</span>(event) {</p>
                        <p class="ml-120">                <span class="color-blue">if</span> (event.button === <span class="color-pink">0</span>) {</p>
                        <p class="ml-150">                    <span class="color-red">alert</span>(<span class="color-green">"Left mouse button pressed"</span>);</p>
                        <p class="ml-120">                } <span class="color-blue">else if</span> (event.button === <span class="color-pink">1</span>) {</p>
                        <p class="ml-150">                    <span class="color-red">alert</span>(<span class="color-green">"Middle mouse button pressed"</span>);</p>
                        <p class="ml-120">                } <span class="color-blue">else if</span> (event.button === <span class="color-pink">2</span>) {</p>
                        <p class="ml-150">                    <span class="color-red">alert</span>(<span class="color-green">"Right mouse button pressed"</span>);</p>
                        <p class="ml-120">                }</p>
                        <p class="ml-90">            }</p>
                        <p class="ml-60">        &lt;/script&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <br />    
                        <p class="ml-60">        &lt;div <span class="color-pink">onmousedown</span>=<span class="color-green">"detectMouseButton(event)"</span>&gt;Click any mouse button on this text&lt;/div&gt;</p>
                        <br />    
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>
                <p>When the mouse is pressed down on the <span class="background-grey">div</span>, the <span class="background-grey">detectMouseButton</span> function runs, identifying the button that was pressed.</p>




            </div>
        </section>
    )
}