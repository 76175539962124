import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Outlet, Link } from "react-router-dom";
export const pageUrl = () => "/angular/route-guards";

export default function RouteGuards() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Route Guards in Angular | Aspirant's Home");
        const urls = {
            'previous': '/angular/navigating-between-routes',
            'next': '/angular/root-module'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Route Guards</h3>
            <div className='mt-4 mb-5'>
                <p>
                    <strong>Route Guards</strong> in Angular are used to protect routes and control access based on specific conditions, such as user authentication, roles, or other custom logic. They ensure that users can only access certain routes when specific criteria are met.
                </p>

                <h5 className='mt-5 mb-3'>Types of Route Guards</h5>
                <p>Angular provides five types of route guards:</p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li><strong>CanActivate</strong>: It prevents unauthorized users from accessing a route.</li>
                    <li><strong>CanActivateChild</strong>: It prevents unauthorized users from accessing child routes.</li>
                    <li><strong>CanDeactivate</strong>: Asks users for confirmation before leaving a route (e.g., unsaved changes).</li>
                    <li><strong>Resolve</strong>: It pre-fetches data before a route is activated.</li>
                    <li><strong>CanLoad</strong>: It prevents loading a module until the condition is met (e.g., lazy-loaded modules).</li>
                </ul>

                <p>Let's see how we can use <strong>Route Guards</strong> in the application.</p>

                <h5 className='mt-5 mb-3'>Creating and Using a Route Guard</h5>
                <p>Here’s how to create and use a <strong>CanActivate</strong> guard as an example:</p>

                <h6 className='mt-5'>Step 1: Generate a Guard</h6>
                <p>Use the Angular CLI to generate a guard:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class ="color-pink">ng generate</span> guard <span class ="color-blue">auth</span></p>
                        `
                    }}></div>
                </div>
                <p>This creates two files: <span class="background-grey">auth.guard.ts</span> and <span class="background-grey">auth.guard.spec.ts</span>.</p>


                <h6 className='mt-5'>Step 2: Implement Guard Logic</h6>
                <p>In the <span class="background-grey">auth.guard.ts</span> file, implement your logic in the <span class="background-grey">canActivate</span> method.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">import</span> { <span class="color-blue">Injectable</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                        <p><span class="color-pink">import</span> { <span class="color-blue">CanActivate</span>, <span class="color-blue">Router</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/router'</span>;</p>
                        <br />
                        <p><span class="color-red">@Injectable</span>({</p>
                        <p class="ml-30">    <span class="color-pink">providedIn</span>: <span class="color-green">'root'</span>,</p>
                        <p>})</p>
                        <p><span class="color-pink">export class</span> <span class="color-blue">AuthGuard</span> <span class="color-pink">implements</span> <span class="color-blue">CanActivate</span> {</p>
                        <p class="ml-30">    <span class="color-red">constructor</span>(<span class="color-pink">private</span> router: <span class="color-blue">Router</span>) {}</p>
                        <br />
                        <p class="ml-30">    <span class="color-red">canActivate</span>(): <span class="color-blue">boolean</span> {</p>
                        <p class="ml-60">        <span class="color-pink">const</span> isAuthenticated = <span class="color-blue">this</span>.<span class="color-red">checkAuthentication</span>();</p>
                        <br />
                        <p class="ml-60">        <span class="color-blue">if</span> (<span class="color-red">!</span>isAuthenticated) {</p>
                        <p class="ml-90 color-grey">            // Redirect to login page if not authenticated</p>
                        <p class="ml-90">            <span class="color-blue">this</span>.router.<span class="color-red">navigate</span>([<span class="color-green">'/login'</span>]);</p>
                        <p class="ml-90">            <span class="color-pink">return</span> <span class="color-blue">false</span>;</p>
                        <p class="ml-60">        }</p>
                        <p class="ml-60">        <span class="color-pink">return</span> <span class="color-blue">true</span>;</p>
                        <p class="ml-30">    }</p>
                        <br />
                        <p class="ml-30">    <span class="color-pink">private</span> <span class="color-red">checkAuthentication</span>(): <span class="color-blue">boolean</span> {</p>
                        <p class="ml-60 color-grey">        // Add your authentication logic here</p>
                        <p class="ml-60 color-grey">        // Example: Check if the user has a valid token</p>
                        <p class="ml-60">        <span class="color-blue">const</span> token = localStorage.<span class="color-red">getItem</span>(<span class="color-green">'authToken'</span>);</p>
                        <p class="ml-60">        <span class="color-pink">return</span> <span class="color-red">!!</span>token;</p>
                        <p class="ml-30">    }</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>
                <p>Here,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">canActivate()</span>: It returns <span class="background-grey">true</span> if the route is accessible, otherwise <span class="background-grey">false</span>.</li>
                    <li><span class="background-grey">this.router.navigate()</span>: It redirects the user to another route if access is denied.</li>
                </ul>


                <h6 className='mt-5'>Step 3: Add the Guard to Routes</h6>
                <p>Use the guard in your routing configuration.</p>
                <p>In <span class="background-grey">app-routing.module.ts</span>:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">import</span> { <span class="color-blue">NgModule</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                        <p><span class="color-pink">import</span> { <span class="color-blue">RouterModule</span>, <span class="color-blue">Routes</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/router'</span>;</p>
                        <p><span class="color-pink">import</span> { <span class="color-blue">HomeComponent</span> } <span class="color-pink">from</span> <span class="color-green">'./home/home.component'</span>;</p>
                        <p><span class="color-pink">import</span> { <span class="color-blue">DashboardComponent</span> } <span class="color-pink">from</span> <span class="color-green">'./dashboard/dashboard.component'</span>;</p>
                        <p><span class="color-pink">import</span> { <span class="color-blue">LoginComponent</span> } <span class="color-pink">from</span> <span class="color-green">'./login/login.component'</span>;</p>
                        <p><span class="color-pink">import</span> { <span class="color-blue">AuthGuard</span> } <span class="color-pink">from</span> <span class="color-green">'./auth.guard'</span>;</p>
                        <br />
                        <p><span class="color-blue">const</span> routes: Routes = [</p>
                        <p class="ml-30">  { <span class="color-pink">path</span>: <span class="color-green">''</span>, <span class="color-pink">component</span>: <span class="color-blue">HomeComponent</span> },</p>
                        <p class="ml-30">  { <span class="color-pink">path</span>: <span class="color-green">'dashboard'</span>, <span class="color-pink">component</span>: <span class="color-blue">DashboardComponent</span>, <span class="color-pink">canActivate</span>: [<span class="color-blue">AuthGuard</span>] },</p>
                        <p class="ml-30">  { <span class="color-pink">path</span>: <span class="color-green">'login'</span>, <span class="color-pink">component</span>: <span class="color-blue">LoginComponent</span> },</p>
                        <p>];</p>
                        <br />
                        <p><span class="color-red">@NgModule</span>({</p>
                        <p class="ml-30">  <span class="color-pink">imports</span>: [RouterModule.<span class="color-red">forRoot</span>(routes)],</p>
                        <p class="ml-30">  <span class="color-pink">exports</span>: [<span class="color-blue">RouterModule</span>],</p>
                        <p>})</p>
                        <p><span class="color-pink">export class</span> <span class="color-blue">AppRoutingModule</span> {}</p>
                        `
                    }}></div>
                </div>

                <p>Here, For the <span class="background-grey">dashboard</span> route, <span class="background-grey">AuthGuard</span> is used to protect it. If the guard returns <span class="background-grey">false</span>, navigation to the route is blocked.</p>


                <h5 className='mt-5 mb-3'>Other Route Guards</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <h6 className='mt-4'>CanActivateChild</h6>
                        <p>It is used to protect child routes.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">import</span> { <span class="color-blue">Injectable</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                                <p><span class="color-pink">import</span> { <span class="color-blue">CanActivateChild</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/router'</span>;</p>
                                <br />
                                <p><span class="color-red">@Injectable</span>({</p>
                                <p class="ml-30">    <span class="color-pink">providedIn</span>: <span class="color-green">'root'</span>,</p>
                                <p>})</p>
                                <p><span class="color-pink">export class</span> <span class="color-blue">AuthGuard</span> <span class="color-pink">implements</span> <span class="color-blue">CanActivateChild</span> {</p>
                                <p class="ml-30">    <span class="color-red">canActivateChild</span>(): <span class="color-blue">boolean</span> {</p>
                                <p class="ml-60">        <span class="color-pink">return</span> <span class="color-blue">this</span>.<span class="color-red">checkAuthentication</span>(); <span class="color-grey">// Same logic as CanActivate</span></p>
                                <p class="ml-30">    }</p>
                                <br />
                                <p class="ml-30">    <span class="color-pink">private</span> <span class="color-red">checkAuthentication</span>(): <span class="color-blue">boolean</span> {</p>
                                <p class="ml-60">        <span class="color-blue">const</span> token = localStorage.<span class="color-red">getItem</span>(<span class="color-green">'authToken'</span>);</p>
                                <p class="ml-60">        <span class="color-pink">return</span> !!token;</p>
                                <p class="ml-30">    }</p>
                                <p>}</p>
                                `
                            }}></div>
                        </div>
                        <p>Now add it to child routes:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">const</span> routes: <span class="color-blue">Routes</span> = [</p>
                                <p class="ml-30">    {</p>
                                <p class="ml-60">        <span class="color-pink">path</span>: <span class="color-green">'admin'</span>,</p>
                                <p class="ml-60">        <span class="color-pink">component</span>: <span class="color-blue">AdminComponent</span>,</p>
                                <p class="ml-60">        <span class="color-pink">canActivateChild</span>: [<span class="color-blue">AuthGuard</span>],</p>
                                <p class="ml-60">        <span class="color-pink">children</span>: [</p>
                                <p class="ml-90">            { <span class="color-pink">path</span>: <span class="color-green">'settings'</span>, <span class="color-pink">component</span>: <span class="color-blue">SettingsComponent</span> },</p>
                                <p class="ml-90">            { <span class="color-pink">path</span>: <span class="color-green">'profile'</span>, <span class="color-pink">component</span>: <span class="color-blue">ProfileComponent</span> },</p>
                                <p class="ml-60">        ],</p>
                                <p class="ml-30">    },</p>
                                <p>];</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='mt-5'>
                        <h6 className='mt-4'>CanDeactivate</h6>
                        <p>It asks for confirmation before leaving a route.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">import</span> { <span class="color-blue">Injectable</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                                <p><span class="color-pink">import</span> { <span class="color-blue">CanDeactivate</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/router'</span>;</p>
                                <br />
                                <p><span class="color-pink">export</span> <span class="color-blue">interface</span> <span class="color-blue">CanComponentDeactivate</span> {</p>
                                <p class="ml-30">    <span class="color-red">canDeactivate</span>: () => <span class="color-blue">boolean</span> | Promise<<span class="color-blue">boolean</span>>;</p>
                                <p>}</p>
                                <br />
                                <p><span class="color-red">@Injectable</span>({</p>
                                <p class="ml-30">    <span class="color-red">providedIn</span>: <span class="color-green">'root'</span>,</p>
                                <p>})</p>
                                <p><span class="color-pink">export class</span> <span class="color-blue">CanDeactivateGuard</span> <span class="color-pink">implements</span> CanDeactivate<<span class="color-blue">CanComponentDeactivate</span>> {</p>
                                <p class="ml-30">    <span class="color-red">canDeactivate</span>(component: <span class="color-blue">CanComponentDeactivate</span>): <span class="color-blue">boolean</span> | Promise<<span class="color-pink">boolean</span>> {</p>
                                <p class="ml-60">        <span class="color-pink">return</span> component.canDeactivate ? component.<span class="color-red">canDeactivate</span>() : <span class="color-blue">true</span>;</p>
                                <p class="ml-30">    }</p>
                                <p>}</p>
                                `
                            }}></div>
                        </div>
                        <p>In a component:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">import</span> { <span class="color-blue">Component</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                                <br />
                                <p><span class="color-red">@Component</span>({</p>
                                <p class="ml-30">    <span class="color-pink">selector</span>: <span class="color-green">'app-form'</span>,</p>
                                <p class="ml-30">    <span class="color-pink">template</span>: <span class="color-green">&#96;&lt;p&gt;Form Component&lt;/p&gt;&#96;</span>,</p>
                                })
                                <p><span class="color-pink">export class</span> <span class="color-blue">FormComponent</span> <span class="color-pink">implements</span> <span class="color-blue">CanComponentDeactivate</span> {</p>
                                <p class="ml-30">    <span class="color-red">canDeactivate</span>(): <span class="color-blue">boolean</span> {</p>
                                <p class="ml-60">        <span class="color-pink">return</span> <span class="color-red">confirm</span>(<span class="color-green">'Do you really want to leave this page? Unsaved changes will be lost.'</span>);</p>
                                <p class="ml-30">    }</p>
                                <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='mt-5'>
                        <h6 className='mt-4'>Resolve</h6>
                        <p>It fetches data before a route is activated.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">import</span> { <span class="color-blue">Injectable</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                                <p><span class="color-pink">import</span> { <span class="color-blue">Resolve</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/router'</span>;</p>
                                <p><span class="color-pink">import</span> { <span class="color-blue">Observable</span> } <span class="color-pink">from</span> <span class="color-green">'rxjs'</span>;</p>
                                <br />
                                <p><span class="color-red">@Injectable</span>({</p>
                                <p class="ml-30">    <span class="color-pink">providedIn</span>: <span class="color-green">'root'</span>,</p>
                                <p>})</p>
                                <p><span class="color-pink">export class</span> <span class="color-blue">DataResolver</span> <span class="color-pink">implements</span> <span class="color-blue">Resolve</span><<span class="color-pink">string</span>> {</p>
                                <p class="ml-30">    <span class="color-red">resolve</span>(): <span class="color-blue">Observable</span><<span class="color-pink">string</span>> | Promise<<span class="color-pink">string</span>> | <span class="color-pink">string</span> {</p>
                                <p class="ml-60">        <span class="color-pink">return</span> <span class="color-green">'Resolved Data'</span>; <span class="color-grey">// Replace with real data-fetching logic</span></p>
                                <p class="ml-30">    }</p>
                                <p>}</p>
                                `
                            }}></div>
                        </div>
                        <p>Add it to a route:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">const</span> routes: <span class="color-blue">Routes</span> = [</p>
                                <p class="ml-30">    { <span class="color-pink">path</span>: <span class="color-green">'data'</span>, <span class="color-pink">component</span>: <span class="color-blue">DataComponent</span>, <span class="color-pink">resolve</span>: { data: DataResolver } },</p>
                                <p>];</p>
                                `
                            }}></div>
                        </div>
                        <p>Access the resolved data in the component:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">import</span> { <span class="color-blue">ActivatedRoute</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/router'</span>;</p>
                                <br />
                                <p><span class="color-pink">export class</span> <span class="color-blue">DataComponent</span> {</p>
                                <p class="ml-30">    <span class="color-red">constructor</span>(<span class="color-pink">private</span> route: <span class="color-blue">ActivatedRoute</span>) {</p>
                                <p class="ml-60">        <span class="color-blue">this</span>.route.data.<span class="color-red">subscribe</span>((data) => {</p>
                                <p class="ml-90">            <span class="color-pink">console</span>.<span class="color-red">log</span>(data[<span class="color-green">'data'</span>]); <span class="color-grey">// Access the resolved data</span></p>
                                <p class="ml-60">        });</p>
                                <p class="ml-30">    }</p>
                                <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='mt-5'>
                        <h6 className='mt-4'>CanLoad</h6>
                        <p>It prevents a module from being loaded until a condition is met (used for lazy-loaded modules).</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">import</span> { <span class="color-blue">Injectable</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                                <p><span class="color-pink">import</span> { <span class="color-blue">CanLoad</span>, <span class="color-blue">Route</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/router'</span>;</p>
                                <br />
                                <p><span class="color-red">@Injectable</span>({</p>
                                <p class="ml-30">    <span class="color-pink">providedIn</span>: <span class="color-green">'root'</span>,</p>
                                <p>})</p>
                                <p><span class="color-pink">export class</span> <span class="color-blue">AuthGuard</span> <span class="color-pink">implements</span> <span class="color-blue">CanLoad</span> {</p>
                                <p class="ml-30">    <span class="color-red">canLoad</span>(route: <span class="color-red">Route</span>): <span class="color-pink">boolean</span> {</p>
                                <p class="ml-60">        <span class="color-blue">const</span> isAuthenticated = <span class="color-blue">this</span>.<span class="color-red">checkAuthentication</span>();</p>
                                <p class="ml-60">        <span class="color-blue">if</span> (!isAuthenticated) {</p>
                                <p class="ml-90">            <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">'Unauthorized to load the module'</span>);</p>
                                <p class="ml-60">        }</p>
                                <p class="ml-60">        <span class="color-pink">return</span> isAuthenticated;</p>
                                <p class="ml-30">    }</p>
                                <br />
                                <p class="ml-30">    <span class="color-pink">private</span> <span class="color-red">checkAuthentication</span>(): <span class="color-blue">boolean</span> {</p>
                                <p class="ml-60">        <span class="color-blue">const</span> token = localStorage.<span class="color-red">getItem</span>(<span class="color-green">'authToken'</span>);</p>
                                <p class="ml-60">        <span class="color-pink">return</span> !!token;</p>
                                <p class="ml-30">    }</p>
                                <p>}</p>
                                `
                            }}></div>
                        </div>
                        <p>Add it to a lazy-loaded route:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">const</span> routes: <span class="color-pink">Routes</span> = [</p>
                                <p class="ml-30">    { <span class="color-pink">path</span>: <span class="color-green">'admin'</span>, <span class="color-red">loadChildren</span>: () => <span class="color-red">import</span>(<span class="color-green">'./admin/admin.module'</span>).<span class="color-red">then</span>(m => m.AdminModule), <span class="color-pink">canLoad</span>: [<span class="color-blue">AuthGuard</span>] },</p>
                                <p>];</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>

                <p className='mt-5'>With these route guards, you can manage user navigation, protect your application, and improve user experience.</p>


            </div>
        </section>
    )
}