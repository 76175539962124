import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/angular/reactive-forms";

export default function ReactiveForms() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Reactive Forms in Angular | Aspirant's Home");
        const urls = {
            'previous': '/angular/template-driven-forms',
            'next': '/angular/form-validation'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Reactive Forms</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Reactive Forms in Angular are a powerful way to create and manage forms using a <strong>model-driven approach</strong>. Unlike template-driven forms, reactive forms are more explicit and give greater control over form behavior and validation.
                </p>

                <h5 className='mt-5 mb-3'>Key Features of Reactive Forms</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>Model-Driven:</strong>
                        <p>The form's structure and validation rules are defined in the component class, not the template.</p>
                    </li>
                    <li>
                        <strong>Immutable Updates:</strong>
                        <p>The form state is immutable; every change returns a new state.</p>
                    </li>
                    <li>
                        <strong>Explicit Validation:</strong>
                        <p>Validators are defined programmatically.</p>
                    </li>
                    <li>
                        <strong>Greater Control:</strong>
                        <p>Ideal for complex forms or forms with dynamic fields.</p>
                    </li>
                </ul>

                <h5 className='mt-5 mb-3'>Steps to Implement Reactive Forms</h5>
                <h6 className='mt-5 mb-4'>1. Import ReactiveFormsModule</h6>
                <p>First, you need to import <span class="background-grey">ReactiveFormsModule</span> into your app’s module.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">import</span> { NgModule } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                        <p><span class="color-pink">import</span> { ReactiveFormsModule } <span class="color-pink">from</span> <span class="color-green">'@angular/forms'</span>;</p>
                        <br />
                        <p><span class="color-red">@NgModule</span>({</p>
                        <p class="ml-30">    <span class="color-pink">imports</span>: [<span class="color-blue">ReactiveFormsModule</span>]</p>
                        <p>})</p>
                        <p><span class="color-pink">export class</span> <span class="color-blue">AppModule</span> {}</p>
                        `
                    }}></div>
                </div>

                <h6 className='mt-5 mb-4'>2. Define a Form in the Component Class</h6>
                <p>Reactive forms are created using <span class="background-grey">FormControl</span>, <span class="background-grey">FormGroup</span>, and optionally <span class="background-grey">FormArray</span>.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">import</span> { Component } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                        <p><span class="color-pink">import</span> { FormGroup, FormControl, Validators } <span class="color-pink">from</span> <span class="color-green">'@angular/forms'</span>;</p>
                        <br />
                        <p><span class="color-red">@Component</span>({</p>
                        <p class="ml-30">    <span class="color-pink">selector</span>: <span class="color-green">'app-reactive-form'</span>,</p>
                        <p class="ml-30">    <span class="color-pink">templateUrl</span>: <span class="color-green">'./reactive-form.component.html'</span></p>
                        <p>})</p>
                        <p><span class="color-pink">export class</span> <span class="color-blue">ReactiveFormComponent</span> {</p>
                        <p class="ml-30">    <span class="color-pink">userForm</span>: FormGroup;</p>
                        <br />
                        <p class="ml-30">    <span class="color-red">constructor</span>() {</p>
                        <p class="ml-60">        <span class="color-blue">this</span>.userForm = <span class="color-blue">new</span> <span class="color-red">FormGroup</span>({</p>
                        <p class="ml-90">            <span class="color-pink">name</span>: <span class="color-blue">new</span> <span class="color-red">FormControl</span>(<span class="color-green">''</span>, [Validators.required, Validators.<span class="color-red">minLength</span>(<span class="color-pink">3</span>)]),</p>
                        <p class="ml-90">            <span class="color-pink">email</span>: <span class="color-blue">new</span> <span class="color-red">FormControl</span>(<span class="color-green">''</span>, [Validators.required, Validators.email]),</p>
                        <p class="ml-90">            <span class="color-pink">age</span>: <span class="color-blue">new</span> <span class="color-red">FormControl</span>(<span class="color-green">''</span>, [Validators.required, Validators.<span class="color-red">min</span>(<span class="color-pink">18,</span>)])</p>
                        <p class="ml-60">        });</p>
                        <p class="ml-30">    }</p>
                        <br />
                        <p class="ml-30">    <span class="color-red">onSubmit</span>() {</p>
                        <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">log</span>(this.userForm.value); <span class="color-grey">// Outputs form values</span></p>
                        <p class="ml-30">    }</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>

                
                <h6 className='mt-5 mb-4'>3. Bind the Form to the Template</h6>
                <p>Use Angular directives like <span class="background-grey">formGroup</span> and <span class="background-grey">formControlName</span> to bind the form to the HTML.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;form [<span class="color-pink">formGroup</span>]=<span class="color-green">"userForm"</span> (<span class="color-pink">ngSubmit</span>)=<span class="color-green">"onSubmit()"</span>&gt;</p>
                        <p class="ml-30">    &lt;label <span class="color-pink">for</span>=<span class="color-green">"name"</span>&gt;Name:&lt;/label&gt;</p>
                        <p class="ml-30">    &lt;input <span class="color-pink">id</span>=<span class="color-green">"name"</span> <span class="color-pink">formControlName</span>=<span class="color-green">"name"</span> /&gt;</p>
                        <p class="ml-30">    &lt;div *<span class="color-pink">ngIf</span>=<span class="color-green">"userForm.get('name')?.invalid && userForm.get('name')?.touched"</span>&gt;</p>
                        <p class="ml-60">        Name is required and must be at least 3 characters.</p>
                        <p class="ml-30">    &lt;/div&gt;</p>
                        <br />
                        <p class="ml-30">    &lt;label <span class="color-pink">for</span>=<span class="color-green">"email"</span>&gt;Email:&lt;/label&gt;</p>
                        <p class="ml-30">    &lt;input <span class="color-pink">id</span>=<span class="color-green">"email"</span> <span class="color-pink">formControlName</span>=<span class="color-green">"email"</span> /&gt;</p>
                        <p class="ml-30">    &lt;div *<span class="color-pink">ngIf</span>=<span class="color-green">"userForm.get('email')?.invalid && userForm.get('email')?.touched"</span>&gt;</p>
                        <p class="ml-60">        Enter a valid email address.</p>
                        <p class="ml-30">    &lt;/div&gt;</p>
                        <br />
                        <p class="ml-30">    &lt;label <span class="color-pink">for</span>=<span class="color-green">"age"</span>&gt;Age:&lt;/label&gt;</p>
                        <p class="ml-30">    &lt;input <span class="color-pink">id</span>=<span class="color-green">"age"</span> <span class="color-pink">type</span>=<span class="color-green">"number"</span> <span class="color-pink">formControlName</span>=<span class="color-green">"age"</span> /&gt;</p>
                        <p class="ml-30">    &lt;div *<span class="color-pink">ngIf</span>=<span class="color-green">"userForm.get('age')?.invalid && userForm.get('age')?.touched"</span>&gt;</p>
                        <p class="ml-60">        Age is required and must be 18 or older.</p>
                        <p class="ml-30">    &lt;/div&gt;</p>
                        <br />
                        <p class="ml-30">    &lt;button <span class="color-pink">type</span>=<span class="color-green">"submit"</span> [<span class="color-pink">disabled</span>]=<span class="color-green">"userForm.invalid"</span>&gt;Submit&lt;/button&gt;</p>
                        <p>&lt;/form&gt;</p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-3'>Core Classes in Reactive Forms</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>FormControl</strong>
                        <p>Represents a single input field or form element.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">new</span> <span class="color-red">FormControl</span>(<span class="color-green">'initialValue'</span>, [validators], [asyncValidators]);</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>FormGroup</strong>
                        <p>Groups multiple <span class="background-grey">FormControls</span> into a single unit.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">new</span> <span class="color-red">FormGroup</span>({</p>
                                <p class="ml-30">    <span class="color-pink">field1</span>: <span class="color-blue">new</span> <span class="color-red">FormControl</span>(),</p>
                                <p class="ml-30">    <span class="color-pink">field2</span>: <span class="color-blue">new</span> <span class="color-red">FormControl</span>()</p>
                                <p>});</p>
                                `
                            }}></div>
                        </div> 
                    </li>
                    <li>
                        <strong>FormArray</strong>
                        <p>Handles dynamic forms with an array of controls.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">new</span> <span class="color-red">FormArray</span>([</p>
                                <p class="ml-30">    <span class="color-blue">new</span> <span class="color-red">FormControl</span>(<span class="color-green">'Item 1'</span>),</p>
                                <p class="ml-30">    <span class="color-blue">new</span> <span class="color-red">FormControl</span>(<span class="color-green">'Item 2'</span>)</p>
                                <p>]);</p>
                                `
                            }}></div>
                        </div> 
                    </li>
                </ul>


                <h5 className='mt-5 mb-3'>Advantages of Reactive Forms</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>Scalability:</strong>
                        <p className='ml-30'>Suitable for complex forms or forms with dynamic fields.</p>
                    </li>
                    <li>
                        <strong>Testability:</strong>
                        <p className='ml-30'>Easy to write unit tests since the form logic resides in the component.</p>
                    </li>
                    <li>
                        <strong>Explicit Control:</strong>
                        <p className='ml-30'>Full control over the form’s behavior, validation, and state.</p>
                    </li>
                    <li>
                        <strong>Dynamic Forms:</strong>
                        <p className='ml-30'>Easily add or remove form controls or groups dynamically.</p>
                    </li>
                </ul>


            </div>
        </section>
    )
}