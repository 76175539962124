import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/loop-for-in";

export default function LoopForIn() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Loop For In in Javascript | Aspirant's Home");
        const urls = {
            'previous': '/javascript/loop-for',
            'next': '/javascript/loop-for-of'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>for...in Loop </h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className='background-grey'>for...in</span> <strong>loop</strong> in JavaScript is used to iterate (or loop) over the <strong>properties of an object</strong>. Instead of using it to loop over numbers or arrays, it is designed to work with objects where each property (or key) can be accessed one by one.
                </p>

                <h5 className='mt-5 mb-3'>How Does <span className='background-grey'>for...in</span> Work?</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>The <span className='background-grey'>for...in</span> loop iterates through all <strong>enumerable properties</strong> of an object.</li>
                    <li>For each property, it gives you the <strong>key</strong> (property name).</li>
                </ul>

                <h5 className='mt-5 mb-3'>Syntax</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">for</span> (key <span class="color-pink">in</span> object) {</p>
                        <p class="ml-30 color-grey">    // Code to run for each key</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span className='background-grey'>key</span>: This represents the property name of the object.</li>
                    <li><span className='background-grey'>object</span>: The object whose properties you want to iterate through.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Example 1: Basic <span className='background-grey'>for...in</span> Loop</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> car = {</p>
                        <p class="ml-30">    <span class="color-pink">brand</span>: <span class="color-green">"Toyota"</span>,</p>
                        <p class="ml-30">    <span class="color-pink">model</span>: <span class="color-green">"Corolla"</span>,</p>
                        <p class="ml-30">    <span class="color-pink">year</span>: <span class="color-pink">2020</span></p>
                        <p>};</p>
                        
                        <p><span class="color-blue">for</span> (<span class="color-blue">let</span> key <span class="color-pink">in</span> car) {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(key + <span class="color-green">": "</span> + car[key]);</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>
                <h6>Output</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>brand: Toyota</p>
                        <p>model: Corolla</p>
                        <p>year: <span class="color-pink">2020</span></p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span className='background-grey'>for...in</span> loop iterates through each property in the <span className='background-grey'>car</span> object.</li>
                    <li><span className='background-grey'>key</span> is the property name (like <span className='background-grey'>brand</span>, <span className='background-grey'>model</span>, <span className='background-grey'>year</span>).</li>
                    <li><span className='background-grey'>car[key]</span> is the value of the property.</li>
                </ul>


                <h5 className='mt-5 mb-3'>When to Use <span className='background-grey'>for...in</span></h5>
                <p>Use <span className='background-grey'>for...in</span> when:</p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>You want to loop through the <strong>properties of an object</strong>.</li>
                    <li>You need to access both the property names (keys) and their values.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Example 2: Looping Through an Object</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> student = {</p>
                        <p class="ml-30">    <span class="color-pink">name</span>: <span class="color-green">"John"</span>,</p>
                        <p class="ml-30">    <span class="color-pink">age</span>: <span class="color-pink">18</span>,</p>
                        <p class="ml-30">    <span class="color-pink">grade</span>: <span class="color-green">"A"</span></p>
                        <p>};</p>
                        
                        <p><span class="color-blue">for</span> (<span class="color-blue">let</span> key <span class="color-pink">in</span> student) {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">&#96;$&#123;key&#125;: $&#123;student[key]&#125;&#96;</span>);</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>
                <h6>Output</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>name: John</p>
                        <p>age: 18</p>
                        <p>grade: A</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Example 3: Counting Properties</h5>
                <p>You can count the number of properties in an object using a <span className='background-grey'>for...in</span> loop.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> book = {</p>
                        <p class="ml-30">    <span class="color-pink">title</span>: <span class="color-green">"The Great Gatsby"</span>,</p>
                        <p class="ml-30">    <span class="color-pink">author</span>: <span class="color-green">"F. Scott Fitzgerald"</span>,</p>
                        <p class="ml-30">    <span class="color-pink">pages</span>: <span class="color-pink">218</span></p>
                        <p>};</p>
                        
                        <p><span class="color-blue">let</span> count = <span class="color-pink">0</span>;</p>
                        <p><span class="color-blue">for</span> (<span class="color-blue">let</span> key <span class="color-pink">in</span> book) {</p>
                        <p class="ml-30">    count<span class="color-pink">++</span>;</p>
                        <p>}</p>
                        
                        <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Number of properties:"</span>, count);</p>
                        `
                    }}></div>
                </div>
                <h6>Output</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>Number of properties: <span class="color-pink">3</span></p>
                        `
                    }}></div>
                </div>
                

            </div>
        </section>
    )
}