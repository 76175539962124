import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/jquery/events/event-namespace";

export default function Namespace() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("jQuery event.namespace Object | Aspirant's Home");
        const urls = {
            'previous': '/jquery/events'
        }
        path.setPreviousNext(urls);
        path.setPreviousName('Back to Events');
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>jQuery event.namespace Object</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">event.namespace</span> property in jQuery retrieves the <strong>namespace(s)</strong> associated with an event. Event namespaces are strings used to uniquely identify and manage related event handlers.
                </p>
                <p>
                    By using namespaces, you can group event handlers and selectively remove or trigger specific ones without affecting other handlers attached to the same element.
                </p>

                <h5 className='mt-5 mb-3'>Example : Using <span class="background-grey">event.namespace</span></h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;button <span class="color-pink">id</span>=<span class="color-green">"myButton"</span>&gt;Click Me&lt;/button&gt;</p>
                        `
                    }}></div>
                </div>
                <h6>jQuery Code</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p class="color-grey">// Bind an event handler with a namespace</p>
                        <p>$(<span class="color-green">"#myButton"</span>).<span class="color-red">on</span>(<span class="color-green">"click.myNamespace"</span>, <span class="color-blue">function</span>(event) {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Namespace:"</span>, event.namespace); <span class="color-grey">// Logs: "myNamespace"</span></p>
                        <p>});</p>
                        <br />
                        <p class="color-grey">// Trigger the event</p>
                        <p>$(<span class="color-green">"#myButton"</span>).<span class="color-red">trigger</span>(<span class="color-green">"click.myNamespace"</span>);</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span class="background-grey">event.namespace</span> property retrieves the namespace <span class="background-grey">"myNamespace"</span> when the event is triggered.</li>
                </ul>


            </div>
        </section>
    )
}