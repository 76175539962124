import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/jquery/events/keyup";

export default function Keyup() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("jQuery keyup() Method | Aspirant's Home");
        const urls = {
            'previous': '/jquery/events'
        }
        path.setPreviousNext(urls);
        path.setPreviousName('Back to Events');
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>jQuery keyup() Method</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">keyup()</span> method in jQuery triggers an event when the user releases a key after pressing it down. This is commonly used for real-time input validation, live search filtering, or updating the UI based on what the user types. Unlike <span class="background-grey">keypress()</span>, <span class="background-grey">keyup()</span> works with all keys, including non-character keys like arrows, Enter, Backspace, and more.
                </p>

                <p>It has a basic syntax -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(selector).<span class="color-red">keyup</span>(function);</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">selector</span> specifies the element(s) to attach the <span class="background-grey">keyup</span> event to.</li>
                    <li><span class="background-grey">function</span> is a callback function that executes when a key is released.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Example: Real-Time Display of Input</h5>
                <p>Here’s an example where the text typed in an input field is displayed in real time in a paragraph element.</p>

                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">id</span>=<span class="color-green">"textInput"</span> <span class="color-pink">placeholder</span>=<span class="color-green">"Type something..."</span>&gt;</p>
                        <p>&lt;p&gt;Typed text: &lt;span <span class="color-pink">id</span>=<span class="color-green">"displayText"</span>&gt;&lt;/span&gt;&lt;/p&gt;</p>
                        `
                    }}></div>
                </div>
                <h6>jQuery Code</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"#textInput"</span>).<span class="color-red">keyup</span>(<span class="color-blue">function</span>() {</p>
                        <p class="ml-30">    $(<span class="color-green">"#displayText"</span>).<span class="color-red">text</span>($(this).<span class="color-red">val</span>());</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>When a key is released in <span class="background-grey">#textInput</span>, the <span class="background-grey">keyup</span> event is triggered.</li>
                    <li>The current value of <span class="background-grey">#textInput</span> is shown in the <span class="background-grey">#displayText</span> span.</li>
                </ul>


            </div>
        </section>
    )
}