import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/tag/textarea";

export default function Textarea() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - <textarea> Tag | Aspirant's Home");
        const urls = {
            'previous': '/html/tag-list'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - &lt;textarea&gt; Tag</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">&lt;textarea&gt;</span> tag in HTML is used to create a multi-line text input field where users can enter larger amounts of text, like comments, feedback, or messages. Unlike the <span class="background-grey">&lt;input&gt;</span> tag (which is for single-line text inputs), <span class="background-grey">&lt;textarea&gt;</span> allows users to type multiple lines by default.
                </p>

                <h5 className='mt-5 mb-3'>Basic Structure of the <span class="background-grey">&lt;textarea&gt;</span> Tag</h5>
                <p>Unlike <span class="background-grey">&lt;input&gt;</span>, the <span class="background-grey">&lt;textarea&gt;</span> tag is not self-closing; it has an opening and closing tag. Text between these tags serves as the initial text inside the field.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;textarea <span class="color-pink">name</span>=<span class="color-green">"comments"</span> <span class="color-pink">rows</span>=<span class="color-green">"4"</span> <span class="color-pink">cols</span>=<span class="color-green">"50"</span>&gt;Your comments here...&lt;/textarea&gt;</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Key Attributes of <span class="background-grey">&lt;textarea&gt;</span></h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li><span class="background-grey">name</span>: Specifies the name of the textarea, used when submitting form data.</li>
                    <li><span class="background-grey">rows</span>: Sets the number of visible text lines in the textarea.</li>
                    <li><span class="background-grey">cols</span>: Defines the visible width of the textarea in terms of character count.</li>
                    <li><span class="background-grey">placeholder</span>: Displays hint text inside the field when it’s empty.</li>
                    <li><span class="background-grey">maxlength</span>: Specifies the maximum number of characters allowed.</li>
                    <li><span class="background-grey">readonly</span>: Makes the textarea non-editable.</li>
                    <li><span class="background-grey">disabled</span>: Disables the textarea, preventing user interaction.</li>
                    <li><span class="background-grey">wrap</span>: Controls text wrapping within the field (<span class="background-grey">soft</span>, <span class="background-grey">hard</span>, or <span class="background-grey">off</span>).</li>
                </ul>


                <h5 className='mt-5 mb-3'>Example with Styled <span class="background-grey">&lt;textarea&gt;</span></h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Styled Feedback Form&lt;/title&gt;</p>
                        <p class="ml-60">        &lt;style&gt;</p>
                        <p class="ml-90">            textarea {</p>
                        <p class="ml-120">                <span class="color-green">width</span>: <span class="color-pink">100%</span>;</p>
                        <p class="ml-120">                <span class="color-green">height</span>: <span class="color-pink">100px</span>;</p>
                        <p class="ml-120">                <span class="color-green">padding</span>: <span class="color-pink">10px</span>;</p>
                        <p class="ml-120">                <span class="color-green">border</span>: <span class="color-pink">1px</span> solid <span class="color-pink">#ccc</span>;</p>
                        <p class="ml-120">                <span class="color-green">border-radius</span>: <span class="color-pink">5px</span>;</p>
                        <p class="ml-120">                <span class="color-green">resize</span>: vertical; <span class="color-grey">/* Only allows vertical resizing */</span></p>
                        <p class="ml-90">            }</p>
                        <p class="ml-60">        &lt;/style&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <br />    
                        <p class="ml-60">        &lt;h2&gt;Styled Feedback Form&lt;/h2&gt;</p>
                        <p class="ml-60">        &lt;form&gt;</p>
                        <p class="ml-90">            &lt;label <span class="color-pink">for</span>=<span class="color-green">"feedback"</span>&gt;Your Feedback:&lt;/label&gt;</p>
                        <p class="ml-90">            &lt;textarea <span class="color-pink">id</span>=<span class="color-green">"feedback"</span> <span class="color-pink">name</span>=<span class="color-green">"feedback"</span> <span class="color-pink">placeholder</span>=<span class="color-green">"Type your feedback here..."</span>&gt;&lt;/textarea&gt;</p>
                        <p class="ml-90">            &lt;button <span class="color-pink">type</span>=<span class="color-green">"submit"</span>&gt;Submit&lt;/button&gt;</p>
                        <p class="ml-60">        &lt;/form&gt;</p>
                        <br />    
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Output will be the following -</p>
                <div className='outputPalateBox mt-2 mb-4'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <!DOCTYPE html>
                        <html lang="en">
                            <head>
                                <meta charset="UTF-8">
                                <title>Styled Feedback Form</title>
                                <style>
                                    textarea {
                                        width: 100%;
                                        height: 100px;
                                        padding: 10px;
                                        border: 1px solid #ccc;
                                        border-radius: 5px;
                                        resize: vertical; /* Only allows vertical resizing */
                                    }
                                </style>
                            </head>
                            <body>
                            
                                <h2>Styled Feedback Form</h2>
                                <form>
                                    <label for="feedback">Your Feedback:</label><br><br>
                                    <textarea id="feedback" name="feedback" placeholder="Type your feedback here..."></textarea><br><br>
                                    <button type="submit">Submit</button>
                                </form>
                            
                            </body>
                        </html>
                        `
                    }}></div>
                </div>
                <p>
                    The <span class="background-grey">&lt;textarea&gt;</span> tag is ideal for capturing larger blocks of text, supporting multi-line input with options for resizing, placeholder text, and maximum character count. It can be styled with CSS to fit various form designs and enhance the user experience.
                </p>



            </div>
        </section>
    )
}