import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/loop-while";

export default function LoopWhile() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Loop While in Javascript | Aspirant's Home");
        const urls = {
            'previous': '/javascript/loop-for-of',
            'next': '/javascript/break-statement'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>While Loop </h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className='background-grey'>while</span> <strong>loop</strong> in JavaScript is used to execute a block of code <strong>repeatedly</strong> as long as a specified <strong>condition</strong> is <span className='background-grey'>true</span>. It's a basic loop structure, ideal for situations where you don't know the exact number of iterations in advance.
                </p>

                <h5 className='mt-5 mb-3'>Why Use a <span className='background-grey'>while</span> Loop?</h5>
                <p>A <span className='background-grey'>while</span> loop is useful when:</p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>The number of repetitions depends on a condition.</li>
                    <li>The condition may change dynamically during the loop.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Syntax</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">while</span> (condition) {</p>
                        <p class="ml-30 color-grey">    // Code to execute while the condition is true</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>
                <p>Where, <span className='background-grey'>condition</span> is a test that determines whether the loop continues. If it's true, the loop runs. If false, the loop stops.</p> 


                <h5 className='mt-5 mb-3'>Example 1: Counting Numbers</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> count = <span class="color-pink">1</span>;</p>

                        <p><span class="color-blue">while</span> (count <= <span class="color-pink">5</span>) {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Count is:"</span>, count);</p>
                        <p class="ml-30">    count<span class="color-pink">++</span>; <span class="color-grey">// Increment count</span></p>
                        <p>}</p>
                        `
                    }}></div>
                </div>
                <h6>Output:</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>Count is: <span class="color-pink">1</span></p>
                        <p>Count is: <span class="color-pink">2</span></p>
                        <p>Count is: <span class="color-pink">3</span></p>
                        <p>Count is: <span class="color-pink">4</span></p>
                        <p>Count is: <span class="color-pink">5</span></p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li><strong>Initialization</strong>: <span className='background-grey'>count = 1</span> (before the loop starts).</li>
                    <li><strong>Condition</strong>: The loop runs as long as <span className='background-grey'>count &lt;= 5</span>.</li>
                    <li><strong>Increment</strong>: <span className='background-grey'>count++</span> increases the value of <span className='background-grey'>count</span> by 1 in each iteration.</li>
                    <li>When <span className='background-grey'>count = 6</span>, the condition becomes <span className='background-grey'>false</span>, and the loop stops.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Key Points About <span className='background-grey'>while</span> Loops</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <h6>Condition is Checked First</h6>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>If the condition is false at the start, the loop will <strong>never run</strong>.</li>
                            <li>Example:
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                        <p><span class="color-blue">let</span> x = <span class="color-pink">10</span>;</p>

                                        <p><span class="color-blue">while</span> (x < <span class="color-pink">5</span>) {</p>
                                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(x); <span class="color-grey">// This will not run</span></p>
                                        <p>}</p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <h6>Infinite Loop Risk:</h6>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>If the condition never becomes <span className='background-grey'>false</span>, the loop will run forever.</li>
                            <li>Example of an <strong>infinite loop</strong>:
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                        <p><span class="color-blue">let</span> y = <span class="color-pink">1</span>;</p>

                                        <p><span class="color-blue">while</span> (y > <span class="color-pink">0</span>) {</p>
                                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(y); <span class="color-grey">// This will run forever!</span></p>
                                        <p>}</p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>Always ensure the condition will eventually stop the loop.</li>
                        </ul>
                    </li>
                </ul>

                <h5 className='mt-5 mb-3'>Example 2: Summing Numbers</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> sum = <span class="color-pink">0</span>;</p>
                        <p><span class="color-blue">let</span> number = <span class="color-pink">1</span>;</p>
                        
                        <p><span class="color-blue">while</span> (number <= <span class="color-pink">5</span>) {</p>
                        <p class="ml-30">    sum += number; <span class="color-grey">// Add the number to the sum</span></p>
                        <p class="ml-30">    number++;</p>
                        <p>}</p>
                        
                        <p><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Total Sum:"</span>, sum);</p>
                        `
                    }}></div>
                </div>
                <h6>Output:</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>Total Sum: <span class="color-pink">15</span></p>
                        `
                    }}></div>
                </div>
                <p>The loop calculates the sum of numbers from 1 to 5 (<span className='background-grey'>1 + 2 + 3 + 4 + 5</span>).</p>


                <h5 className='mt-5 mb-3'>The <span className='background-grey'>do...while</span> Loop</h5>
                <p>A variant of the <span className='background-grey'>while</span> loop is the <span className='background-grey'>do...while</span> loop. The difference is that a <span className='background-grey'>do...while</span> loop will always execute the code at least once, even if the condition is <span className='background-grey'>false</span> from the start.</p>

                <h6>Syntax</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">do</span> {</p>
                        <p class="ml-30 color-grey">    // Code to execute</p>
                        <p>} <span class="color-blue">while</span> (condition);</p>
                        `
                    }}></div>
                </div>
                <h6>Example</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">let</span> count = <span class="color-pink">10</span>;</p>

                        <p><span class="color-blue">do</span> {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Count is:"</span>, count);</p>
                        <p class="ml-30">    count<span class="color-pink">++</span>;</span>
                        <p>} <span class="color-blue">while</span> (count <= <span class="color-pink">5</span>);</p>
                        `
                    }}></div>
                </div>
                <h6>Output</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>Count is: <span class="color-pink">10</span></p>
                        `
                    }}></div>
                </div>
                <p>Here,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The code inside the loop runs <strong>once</strong> before the condition count <span className='background-grey'>&lt;= 5</span> is checked.</li>
                    <li>Since the condition is <span className='background-grey'>false</span>, the loop stops after the first iteration.</li>
                </ul>


            </div>
        </section>
    )
}