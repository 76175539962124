import React, { useEffect, useState, useRef } from 'react'
import { Outlet, Link } from "react-router-dom";
import TopHeader from './TopHeader';
import Footer from './Footer';
import { MenuContext, TitleContext, PathContext } from "../Context";
import { useContext } from "react";
import PreviousNext from './PreviousNext';

const Quiz = () => {
    const addspaceElement = useRef();
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [selectedOption, setSelectedOption] = useState(null);
    const [score, setScore] = useState(0);
    const [showScore, setShowScore] = useState(false);
    const pathName = useContext(PathContext);
    const pathname = pathName.pathName;
    const menuElement = useRef([]);
    const menu = useContext(MenuContext);
    const [questions, setQuestion] = useState([]);
    const subject = window.location.pathname.split('/')[2];
    const subjectName = subject.split("-") // Split string into parts: ["node", "js"]
        .map((part, index) =>
            index === 0
                ? part.charAt(0).toUpperCase() + part.slice(1).toLowerCase() // Capitalize first part: "Node"
                : part.charAt(0).toUpperCase() + part.slice(1) // Keep second part with "Js"
        )
        .join("");
    const title = useContext(TitleContext);


    const subjects = {
        'node-js' : () => import("./NodeJSQuiz"),
        'mongo-db' : () => import("./MongoDBQuiz"),
        'angular': () => import("./AngularQuiz"),
        'react-js': () => import("./ReactJSQuiz"),
        'javascript': () => import("./JavascriptQuiz"),
        'html': () => import("./HTMLQuiz"),
        'css': () => import("./CSSQuiz"),
        'mysql': () => import("./MysqlQuiz"),
        'php': () => import("./PHPQuiz"),
        'jquery': () => import("./JQueryQuiz"),
        'next-js': () => import("./NextJsQuiz")
    };

    const loadQuestions = async (subject) => {
        if (subjects[subject]) {
          const module = await subjects[subject]();
          return module.default;
        } else {
          return []
        }
    };

    const handleOptionClick = (index) => {
        setSelectedOption(index);
    };

    const handleNextClick = () => {
        if (selectedOption === questions[currentQuestion].answer) {
            setScore(score + 1);
        }
        if (currentQuestion + 1 < questions.length) {
            setCurrentQuestion(currentQuestion + 1);
            setSelectedOption(null); // Reset selected option for the next question
        } else {
            setShowScore(true);
        }
    };

    const resetQuiz = () => {
        setCurrentQuestion(0);
        setSelectedOption(null);
        setScore(0);
        setShowScore(false);
    };

    const setPathName = (path) => {
        if(path)
            pathName.setPathName(path);
    }

    useEffect(() => {
        setPathName(window.location.pathname)
        title.setPageTitle(subjectName + " Quiz | Aspirant's Home");
        const getRandomQuestions = (questions, count = 25) => {
            if (questions.length <= count) return questions;
            return questions.sort(() => 0.5 - Math.random()).slice(0, count);
        };
        const fetchQuestions = async () => {
            try {
                const loadedQuestions = await loadQuestions(subject);
                setQuestion(getRandomQuestions(loadedQuestions));
            } catch (error) {
                console.error("Failed to load questions:", error);
            }
        };
      
        fetchQuestions();

    }, [pathname,subject])

    return (
        <>
        <TopHeader />
        <div className='container-fluid'>
                <div className='row' style={{ background: '#ddd' }}>
                <div ref={menuElement} className={menu.isMenu == true ? 'col-md-3 col-lg-2 menuColumnMobile menuColumn p-0' : ' menuColumn p-0'} >
                    <div className='d-flex align-items-center headerMenu headerMainMenu sticky-top mb-4' style={{ 'height': '80px' }}>
                        <div>
                            <h2>Quiz</h2>
                        </div>
                    </div>
                    <nav>
                        <ul>
                            <li className={pathname == '/quiz/node-js' ? 'activeMenu' : ''}>
                                <Link to="/quiz/node-js" onClick={() => { setPathName('/quiz/node-js'); } }>NodeJs</Link>
                            </li>
                            <li className={pathname == '/quiz/mongo-db' ? 'activeMenu' : ''}>
                                <Link to="/quiz/mongo-db" onClick={() => { setPathName('/quiz/mongo-db'); } }>MongoDB</Link>
                            </li>
                            <li className={pathname == '/quiz/angular' ? 'activeMenu' : ''}>
                                <Link to="/quiz/angular" onClick={() => { setPathName('/quiz/angular'); } }>Angular</Link>
                            </li>
                            <li className={pathname == '/quiz/react-js' ? 'activeMenu' : ''}>
                                <Link to="/quiz/react-js" onClick={() => { setPathName('/quiz/react-js'); } }>ReactJS</Link>
                            </li>
                            <li className={pathname == '/quiz/javascript' ? 'activeMenu' : ''}>
                                <Link to="/quiz/javascript" onClick={() => { setPathName('/quiz/javascript'); } }>Javascript</Link>
                            </li>
                            <li className={pathname == '/quiz/html' ? 'activeMenu' : ''}>
                                <Link to="/quiz/html" onClick={() => { setPathName('/quiz/html'); } }>HTML</Link>
                            </li>
                            <li className={pathname == '/quiz/css' ? 'activeMenu' : ''}>
                                <Link to="/quiz/css" onClick={() => { setPathName('/quiz/css'); } }>CSS</Link>
                            </li>
                            <li className={pathname == '/quiz/mysql' ? 'activeMenu' : ''}>
                                <Link to="/quiz/mysql" onClick={() => { setPathName('/quiz/mysql'); } }>Mysql</Link>
                            </li>
                            <li className={pathname == '/quiz/php' ? 'activeMenu' : ''}>
                                <Link to="/quiz/php" onClick={() => { setPathName('/quiz/php'); } }>PHP</Link>
                            </li>
                            <li className={pathname == '/quiz/jquery' ? 'activeMenu' : ''}>
                                <Link to="/quiz/jquery" onClick={() => { setPathName('/quiz/jquery'); } }>JQuery</Link>
                            </li>
                            <li className={pathname == '/quiz/next-js' ? 'activeMenu' : ''}>
                                <Link to="/quiz/next-js" onClick={() => { setPathName('/quiz/next-js'); } }>Next Js</Link>
                            </li>
                                
                        </ul>
                    </nav>
                </div>
                <div className='col-12 middlePart'>
                    <div className='headerAdSpace' ref={addspaceElement}>
                        <img className='img-fluid' src='https://aspirantshome.com/slide1.png' />
                    </div>
                    <div className='contentPart row sticky-top'>
                        <div className='col-12 col-md-12 mb-4 contentSection'>
                            <div className='container'>
                                <div className='contentPage mb-5'>
                                    <h3 className='mb-3'>
                                        {subjectName} Quiz
                                    </h3>   
                                    <div style={{ fontFamily: "Arial, sans-serif", padding: "20px" }}>
                                        <div
                                            style={{
                                            margin: "0 auto",
                                            padding: "20px",
                                            borderRadius: "10px",
                                            }}
                                        >
                                            {showScore ? (
                                            <div style={{ textAlign: "center" }}>
                                                <h1>Quiz Completed</h1>
                                                <p>
                                                You scored <strong>{score}</strong> out of{" "}
                                                <strong>{questions.length}</strong>
                                                </p>
                                                <button
                                                onClick={resetQuiz}
                                                style={{
                                                    backgroundColor: "#4848B2",
                                                    color: "#fff",
                                                    border: "none",
                                                    padding: "10px 20px",
                                                    borderRadius: "5px",
                                                    cursor: "pointer",
                                                }}
                                                >
                                                Restart Quiz
                                                </button>
                                            </div>
                                                ) : (
                                                    questions.length > 0 ? (
                                                <div>
                                                            
                                                <h5 className='mb-4'>{currentQuestion+1}. {questions[currentQuestion].question}</h5>
                                                <div>
                                                {questions[currentQuestion].options.map((option, index) => (
                                                    <div className='quizOptions'
                                                    key={index}
                                                    onClick={() => handleOptionClick(index)}
                                                    style={{
                                                        backgroundColor: selectedOption === index ? "#4848B2" : "#f9f9f9",
                                                        color: selectedOption === index ? "#fff" : "#000"
                                                    }}
                                                    >
                                                        {option}
                                                    </div>
                                                ))}
                                                </div>
                                                <button
                                                    onClick={handleNextClick}
                                                    disabled={selectedOption === null}
                                                    style={{
                                                        backgroundColor: selectedOption !== null ? "#4848B2" : "#ccc",
                                                        color: "#fff",
                                                        border: "none",
                                                        padding: "10px 20px",
                                                        borderRadius: "5px",
                                                        cursor: selectedOption !== null ? "pointer" : "not-allowed",
                                                        marginTop: "20px",
                                                    }}
                                                >
                                                    Next
                                                </button>
                                                            </div>
                                                        ) : (
                                                                <></>
                                                    )
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='footerAdSpace'>
                            <img className='img-fluid' src='https://aspirantshome.com/slide4.png' />
                        </div>
                    </div>
                </div>
                <div className='col-12  rightPart'>
                    <img className='img-fluid' src='https://aspirantshome.com/slide2.png' />
                    <img className='mt-5 img-fluid' src='https://aspirantshome.com/slide3.png' />
                </div>
            </div>
            <div className='row'>
                <div className='col-12'>
                    <div className='container-fluid footerPart'>
                        <Footer />
                    </div>
                </div>
            </div>
        </div>





        
        </>
    );
};

export default Quiz;
