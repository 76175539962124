import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/jquery/fade";

export default function Fade() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Fade Effects of jQuery | Aspirant's Home");
        const urls = {
            'previous': '/jquery/hide-and-show',
            'next': '/jquery/slide'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Fade Effects of jQuery</h3>
            <div className='mt-4 mb-5'>
                <p>
                    jQuery provides a set of fade effects that help create smooth transitions for showing and hiding elements by changing their opacity. These effects include <span class="background-grey">fadeIn()</span>, <span class="background-grey">fadeOut()</span>, <span class="background-grey">fadeToggle()</span>, and <span class="background-grey">fadeTo()</span>. They are commonly used to make elements appear or disappear gradually, adding a polished look to your web page.
                </p>

                <h5 className='mt-5 mb-3'><span class="background-grey">fadeIn()</span> Method</h5>
                <p>The <span class="background-grey">fadeIn()</span> method gradually changes the opacity of an element from hidden (opacity 0) to fully visible (opacity 1). It is often used to make an element appear smoothly. Here is the basic syntax -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(selector).<span class="color-red">fadeIn</span>(speed, callback);</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">speed</span> (optional) defines the duration of the fade effect. Options include <span class="background-grey">"slow"</span>, <span class="background-grey">"fast"</span>, or a specific number of milliseconds (e.g., <span class="background-grey">500</span>).</li>
                    <li><span class="background-grey">callback</span> (optional) is a function to execute once the fade-in completes.</li>
                </ul>
                <h6>Example :</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(<span class="color-green">"#myElement"</span>).<span class="color-red">fadeIn</span>(<span class="color-green">"slow"</span>);</p>
                        `
                    }}></div>
                </div>
                <p>This example fades in <span class="background-grey">#myElement</span> with a slow transition.</p>


                <h5 className='mt-5 mb-3'><span class="background-grey">fadeOut()</span> Method</h5>
                <p>The <span class="background-grey">fadeOut()</span> method gradually changes the opacity of an element from fully visible (opacity 1) to hidden (opacity 0), making it disappear smoothly. Here is the basic syntax -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(selector).<span class="color-red">fadeOut</span>(speed, callback);</p>
                        `
                    }}></div>
                </div>
                <h6>Example :</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"#myElement"</span>).<span class="color-red">fadeOut</span>(<span class="color-pink">800</span>, <span class="color-blue">function</span>() {</p>
                        <p class="ml-30">    <span class="color-red">alert</span>(<span class="color-green">"Element has faded out!"</span>);</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>Here, <span class="background-grey">#myElement</span> fades out over 800 milliseconds, and an alert message appears once the fade-out is complete.</p>


                <h5 className='mt-5 mb-3'><span class="background-grey">fadeToggle()</span> Method</h5>
                <p>The <span class="background-grey">fadeToggle()</span> method alternates between <span class="background-grey">fadeIn()</span> and <span class="background-grey">fadeOut()</span>. If the element is visible, <span class="background-grey">fadeToggle()</span> will fade it out, and if it’s hidden, it will fade it in. This makes it a handy tool for toggling visibility with a fading effect.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(selector).<span class="color-red">fadeToggle</span>(speed, callback);</p>
                        `
                    }}></div>
                </div>
                <h6>Example :</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(<span class="color-green">"#myElement"</span>).<span class="color-red">fadeToggle</span>(<span class="color-green">"fast"</span>);</p>
                        `
                    }}></div>
                </div>
                <p>In this example, if <span class="background-grey">#myElement</span> is visible, it will fade out quickly; if it’s hidden, it will fade in quickly.</p>


                <h5 className='mt-5 mb-3'><span class="background-grey">fadeTo()</span> Method</h5>
                <p>The <span class="background-grey">fadeTo()</span> method allows you to fade an element to a specific opacity level, rather than fully showing or hiding it. This is useful when you want to create a semi-transparent effect. Here is the syntax -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(selector).<span class="color-red">fadeTo</span>(speed, opacity, callback);</p>
                        `
                    }}></div>
                </div>
                <p>Where, <span class="background-grey">opacity</span> is a number between 0 (fully transparent) and 1 (fully opaque) representing the target opacity level.</p>
                <h6>Example :</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(<span class="color-green">"#myElement"</span>).<span class="color-red">fadeTo</span>(<span class="color-pink">600</span>, <span class="color-pink">0.5</span>);</p>
                        `
                    }}></div>
                </div>
                <p>In this example, <span class="background-grey">#myElement</span> fades to 50% opacity over 600 milliseconds.</p>



            </div>
        </section>
    )
}