import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/jquery/selectors";

export default function Selectors() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Selectors of jQuery | Aspirant's Home");
        const urls = {
            'previous': '/jquery/syntax',
            'next': '/jquery/events'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Selectors of jQuery</h3>
            <div className='mt-4 mb-5'>
                <p>
                    jQuery selectors are used to <strong>find</strong> or <strong>select</strong> HTML elements, allowing you to manipulate those elements with jQuery's built-in actions. These selectors make it easy to apply changes to specific elements without affecting others.
                </p>
                <p>
                    Let's dive into the various types of selectors in jQuery.
                </p>

                <ul style={{ listStyle: 'decimal' }}>
                    <li className='operator'>
                        <h5>Basic Selectors</h5>
                        <p>We can select an element by its <strong>ID</strong>, <strong>Class</strong>, <strong>Tag Name</strong>. Below are the examples of each type of selector.</p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>
                                <strong>Select By ID (<span class="background-grey">#id</span>)</strong>
                                <p className='mt-3'>It selects an element with a specific ID. IDs are unique, so this will select one element. Let's see an example to understand how it will use.</p>
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>$(<span class="color-green">"#header"</span>).<span class="color-red">hide</span>(); <span class="color-grey">// Hides the element with ID "header"</span></p>
                                        `
                                    }}></div>
                                </div>
                                <p>Here, <span class="background-grey">header</span> is the ID of that element.</p>
                            </li>
                            <li>
                                <strong>Select By Class (<span class="background-grey">.class</span>)</strong>
                                <p className='mt-3'>It selects all the element of that class. Here is an example of how class selector is works - </p>
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>$(<span class="color-green">".menu-item"</span>).<span class="color-red">css</span>(<span class="color-green">"color"</span>, <span class="color-green">"blue"</span>); <span class="color-grey">// Changes the text color of all elements with class "menu-item" to blue</span></p>
                                        `
                                    }}></div>
                                </div>
                                <p>Here, All the element with class <span class="background-grey">menu-item</span> </p>
                            </li>
                            <li>
                                <strong>Select by Tag (<span class="background-grey">tag</span>)</strong>
                                <p className='mt-3'>It selects all the element of a specific HTML tag. Let's see an example below -</p>
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>$(<span class="color-green">"p"</span>).<span class="color-red">text</span>(<span class="color-green">"Updated text!"</span>); <span class="color-grey">// Changes the text inside all &lt;p&gt; tags</span></p>
                                        `
                                    }}></div>
                                </div>
                                <p>Here, in all <span class="background-grey">p</span> tag text will updated.</p>
                            </li>
                            <li>
                                <strong>Select All Elements <span class="background-grey">*</span></strong>
                                <p className='mt-3'>It selects all the elements in the document. Look at the below example -</p>
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>$(<span class="color-green">"*"</span>).<span class="color-red">css</span>(<span class="color-green">"margin"</span>, <span class="color-green">"10px"</span>); <span class="color-grey">// Adds a 10px margin to all elements</span></p>
                                        `
                                    }}></div>
                                </div>
                                <p>Its make a margin of <span class="background-grey">10px</span> to each element in that document.</p>
                            </li>
                        </ul>
                    </li>
                    <li className='operator'>
                        <h5>Hierarchy Selectors</h5>
                        <p>These selectors are useful when you want to select elements based on their position in the HTML hierarchy.</p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>
                                <strong>Descendant Selector (<span class="background-grey">ancestor descendant</span>)</strong>
                                <p className='mt-3'>It selects all descendants of a specified ancestor. Look at the below example -</p>
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>$(<span class="color-green">"ul li"</span>).<span class="color-red">css</span>(<span class="color-green">"color"</span>, <span class="color-green">"red"</span>); <span class="color-grey">// Selects all &lt;li&gt; elements within any &lt;ul&gt; and changes their color</span></p>
                                        `
                                    }}></div>
                                </div>
                                <p>Here, all the <span class="background-grey">li</span> element within the <span class="background-grey">ul</span> element changes their color.</p>
                            </li>
                            <li>
                                <strong>Direct Child Selector (<span class="background-grey">parent &gt; child</span>)</strong>
                                <p className='mt-3'>It selects direct children of a specified parent. Look at the below example -</p>
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>$(<span class="color-green">"div > p"</span>).<span class="color-red">hide</span>(); <span class="color-grey">// Hides only &lt;p&gt; elements that are direct children of &lt;div&gt; elements</span></p>
                                        `
                                    }}></div>
                                </div>
                                <p>It hides all <span class="background-grey">p</span> tag that are direct children of the <span class="background-grey">div</span> element.</p>
                            </li>
                        </ul>
                    </li>
                    <li className='operator'>
                        <h5>Attribute Selectors</h5>
                        <p>You can select elements based on their attributes, such as <span class="background-grey">type</span>, <span class="background-grey">href</span>, or <span class="background-grey">src</span>.</p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>
                                <strong>Attribute Equals (<span class="background-grey">[attribute=value]</span>)</strong>
                                <p className='mt-3'>It selects elements with an attribute equal to a specific value. Let's look at below example for better understanding -</p>
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>$(<span class="color-green">"input[type=text]"</span>).<span class="color-red">val</span>(<span class="color-green">"Default text"</span>); <span class="color-grey">// Selects all &lt;input&gt; elements of type "text" and sets their value</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <strong>Attribute Contains (<span class="background-grey">[attribute*=value]</span>)</strong>
                                <p className='mt-3'>It selects elements with an attribute that contains a specified value. Let's look at below example for better understanding -</p>
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>$(<span class="color-green">"a[href*=example]"</span>).<span class="color-red">css</span>(<span class="color-green">"color"</span>, <span class="color-green">"green"</span>); <span class="color-grey">// Selects all &lt;a&gt; elements with "example" in the href attribute</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li className='operator'>
                        <h5>Form Selectors</h5>
                        <p>jQuery provides specific selectors for selecting form elements like inputs, checkboxes, etc.</p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>
                                <span class="background-grey">:input</span>
                                <p className='mt-3'>It selects all input elements (including &lt;input&gt;, &lt;textarea&gt;, &lt;select&gt;, and &lt;button&gt;).  Let's look at below example for better understanding -</p>
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>$(<span class="color-green">":input"</span>).<span class="color-red">css</span>(<span class="color-green">"background-color"</span>, <span class="color-green">"#f0f0f0"</span>); <span class="color-grey">// Changes background color of all form input elements</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <span class="background-grey">:text</span>
                                <p className='mt-3'>It selects all text input fields. Let's look at below example for better understanding -</p>
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>$(<span class="color-green">":text"</span>).<span class="color-red">val</span>(<span class="color-green">"Enter text here"</span>); <span class="color-grey">// Sets a default value in all text input fields</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <span class="background-grey">:checkbox</span>
                                <p className='mt-3'>It selects all checkboxes. Let's look at below example for better understanding -</p>
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>$(<span class="color-green">":checkbox"</span>).<span class="color-red">prop</span>(<span class="color-green">"checked"</span>, <span class="color-blue">true</span>); <span class="color-grey">// Checks all checkboxes</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <span class="background-grey">:selected</span>
                                <p className='mt-3'>It selects the currently selected option in a &lt;select&gt; element. Let's look at below example for better understanding -</p>
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>$(<span class="color-green">"select option:selected"</span>).<span class="color-red">css</span>(<span class="color-green">"font-weight"</span>, <span class="color-green">"bold"</span>); <span class="color-grey">// Bolds the selected option in dropdowns</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li className='operator'>
                        <h5>Filter Selectors</h5>
                        <p>Filter selectors allow you to narrow down selections further, based on specific conditions.</p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>
                                <strong><span class="background-grey">:first</span> and <span class="background-grey">:last</span></strong>
                                <p className='mt-3'>The <span class="background-grey">:first</span> selects the first element, <span class="background-grey">:last</span> selects the last element.</p>
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>$(<span class="color-green">"p:first"</span>).<span class="color-red">css</span>(<span class="color-green">"color"</span>, <span class="color-green">"red"</span>); <span class="color-grey">// Changes the color of the first &lt;p&gt; element to red</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <strong><span class="background-grey">:even</span> and <span class="background-grey">:odd</span></strong>
                                <p className='mt-3'>The <span class="background-grey">:even</span> selects elements at even positions, <span class="background-grey">:odd</span> selects elements at odd positions (based on zero-based indexing).</p>
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>$(<span class="color-green">"li:odd"</span>).<span class="color-red">css</span>(<span class="color-green">"background-color"</span>, <span class="color-green">"#f0f0f0"</span>); <span class="color-grey">// Applies a background color to odd &lt;li&gt; elements</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <strong><span class="background-grey">:eq(index)</span></strong>
                                <p className='mt-3'>It selects the element at the specified index.</p>
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>$(<span class="color-green">"p:eq(2)"</span>).<span class="color-red">css</span>(<span class="color-green">"font-size"</span>, <span class="color-green">"20px"</span>); <span class="color-grey">// Changes the font size of the third &lt;p&gt; element</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <strong><span class="background-grey">:lt(index)</span> and <span class="background-grey">:gt(index)</span></strong>
                                <p className='mt-3'>The <span class="background-grey">:lt(index)</span> selects elements with an index less than index, <span class="background-grey">:gt(index)</span> selects elements with an index greater than index.</p>
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>$(<span class="color-green">"li:lt(3)"</span>).<span class="color-red">css</span>(<span class="color-green">"color"</span>, <span class="color-green">"blue"</span>); <span class="color-grey">// Selects the first three &lt;li&gt; elements and changes their color to blue</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                        </ul>
                    </li>

                    <li className='operator'>
                        <h5>Content Selectors</h5>
                        <p>These selectors help you find elements based on the content they contain.</p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>
                                <strong><span class="background-grey">:contains(text)</span></strong>
                                <p>It selects elements containing the specified text.</p>
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>$(<span class="color-green">"p:contains('hello')"</span>).<span class="color-red">css</span>(<span class="color-green">"font-weight"</span>, <span class="color-green">"bold"</span>); <span class="color-grey">// Bolds all &lt;p&gt; elements that contain the word "hello"</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <strong><span class="background-grey">:empty</span></strong>
                                <p>It selects elements that have no children (including text nodes).</p>
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>$(<span class="color-green">"div:empty"</span>).<span class="color-red">text</span>(<span class="color-green">"This was empty"</span>); <span class="color-grey">// Adds text to all empty &lt;div&gt; elements</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <strong><span class="background-grey">:has(selector)</span></strong>
                                <p>It selects elements that contain at least one element matching the specified selector.</p>
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>$(<span class="color-green">"div:has(p)"</span>).<span class="color-red">css</span>(<span class="color-green">"border"</span>, <span class="color-green">"1px solid red"</span>); <span class="color-grey">// Adds a red border to all &lt;div&gt; elements that contain a &lt;p&gt; tag</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                        </ul>
                    </li>


                </ul>




            </div>
        </section>
    )
}