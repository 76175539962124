import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/jquery/events/focusout";

export default function Focusout() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("jQuery focusout() Method | Aspirant's Home");
        const urls = {
            'previous': '/jquery/events'
        }
        path.setPreviousNext(urls);
        path.setPreviousName('Back to Events');
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>jQuery focusout() Method</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">focusout()</span> method in jQuery triggers an event when an element (usually an input, textarea, or select) or any of its child elements loses focus. It is the opposite of the <span class="background-grey">focusin()</span> method. Like <span class="background-grey">focusin()</span>, the <span class="background-grey">focusout()</span> method supports event bubbling, so it can be applied to a parent element to handle focus loss on child elements as well.
                </p>

                <p>It has a basic syntax -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(selector).<span class="color-red">focusout</span>(function);</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">selector</span> specifies the element(s) to attach the <span class="background-grey">focusout</span> event to.</li>
                    <li><span class="background-grey">function</span> is a callback function to execute when the element or any of its child elements loses focus.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Example: Using <span class="background-grey">focusout()</span> to Reset a Field’s Background Color</h5>
                <p>Here’s an example that resets the background color of an input field when it loses focus.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">id</span>=<span class="color-green">"nameInput"</span> <span class="color-pink">placeholder</span>=<span class="color-green">"Enter your name"</span>&gt;</p>
                        `
                    }}></div>
                </div>
                <h6>jQuery Code</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"#nameInput"</span>).<span class="color-red">focusin</span>(<span class="color-blue">function</span>() {</p>
                        <p class="ml-30">    $(this).<span class="color-red">css</span>(<span class="color-green">"background-color"</span>, <span class="color-green">"#e0f7fa"</span>);</p>
                        <p>}).<span class="color-red">focusout</span>(<span class="color-blue">function</span>() {</p>
                        <p class="ml-30">    $(this).<span class="color-red">css</span>(<span class="color-green">"background-color"</span>, <span class="color-green">"white"</span>);</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span class="background-grey">focusin()</span> method changes the background color of the <span class="background-grey">#nameInput</span> field to light blue when it gains focus.</li>
                    <li>The <span class="background-grey">focusout()</span> method resets the background color to white when the field loses focus.</li>
                </ul>


            </div>
        </section>
    )
}