import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Outlet, Link } from "react-router-dom";
export const pageUrl = () => "/angular/root-module";

export default function RootModule() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Root Module in Angular | Aspirant's Home");
        const urls = {
            'previous': '/angular/route-guards',
            'next': '/angular/feature-modules'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Root Module</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <strong>Root Module</strong> in Angular is the main entry point of an Angular application. It is a module that bootstraps the application and organizes other modules, components, services, and dependencies in a cohesive manner.
                </p>
                <p>
                    The root module is usually named <span class="background-grey">AppModule</span> and is defined in the <span class="background-grey">app.module.ts</span> file.
                </p>

                <h5 className='mt-5 mb-3'>Purpose of the Root Module</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li><strong>Bootstrapping the Application</strong>: The Angular application starts with the root module, which bootstraps the root component (typically <span class="background-grey">AppComponent</span>).</li>
                    <li><strong>Organizing Dependencies</strong>: It declares components, directives, and pipes and imports other modules required for the application.</li>
                    <li><strong>Centralized Configuration</strong>: It provides a single location to configure services and routing.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Structure of a Root Module</h5>
                <p className='mt-3'>Here’s an example of a typical root module:</p>
                <h6 className='mt-4'>app.module.ts</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">import</span> { <span class="color-blue">NgModule</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                        <p><span class="color-pink">import</span> { <span class="color-blue">BrowserModule</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/platform-browser'</span>;</p>
                        <p><span class="color-pink">import</span> { <span class="color-blue">FormsModule</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/forms'</span>; <span class="color-grey">// For template-driven forms</span></p>
                        <p><span class="color-pink">import</span> { <span class="color-blue">HttpClientModule</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/common/http'</span>; <span class="color-grey">// For making HTTP requests</span></p>
                        <p><span class="color-pink">import</span> { <span class="color-blue">AppRoutingModule</span> } <span class="color-pink">from</span> <span class="color-green">'./app-routing.module'</span>; <span class="color-grey">// For routing</span></p>
                        <br />
                        <p><span class="color-pink">import</span> { <span class="color-blue">AppComponent</span> } <span class="color-pink">from</span> <span class="color-green">'./app.component'</span>;</p>
                        <p><span class="color-pink">import</span> { <span class="color-blue">HomeComponent</span> } <span class="color-pink">from</span> <span class="color-green">'./home/home.component'</span>;</p>
                        <p><span class="color-pink">import</span> { <span class="color-blue">AboutComponent</span> } <span class="color-pink">from</span> <span class="color-green">'./about/about.component'</span>;</p>
                        <br />
                        <p><span class="color-red">@NgModule</span>({</p>
                        <p class="ml-30">  <span class="color-pink">declarations</span>: [</p>
                        <p class="ml-60">    <span class="color-blue">AppComponent</span>, <span class="color-grey">// Declaring components</span></p>
                        <p class="ml-60">    <span class="color-blue">HomeComponent</span>,</p>
                        <p class="ml-60">    <span class="color-blue">AboutComponent</span>,</p>
                        <p class="ml-30">  ],</p>
                        <p class="ml-30">  <span class="color-pink">imports</span>: [</p>
                        <p class="ml-60">    <span class="color-blue">BrowserModule</span>, <span class="color-grey">// Required for running the application in a browser</span></p>
                        <p class="ml-60">    <span class="color-blue">FormsModule</span>, <span class="color-grey">// Enables template-driven forms</span></p>
                        <p class="ml-60">    <span class="color-blue">HttpClientModule</span>, <span class="color-grey">// Enables HTTP communication</span></p>
                        <p class="ml-60">    <span class="color-blue">AppRoutingModule</span>, <span class="color-grey">// Configures application routing</span></p>
                        <p class="ml-30">  ],</p>
                        <p class="ml-30">  <span class="color-pink">providers</span>: [], <span class="color-grey">// Services used application-wide</span></p>
                        <p class="ml-30">  <span class="color-pink">bootstrap</span>: [<span class="color-blue">AppComponent</span>], <span class="color-grey">// Root component to bootstrap</span></p>
                        <p>})</p>
                        <p><span class="color-pink">export class</span> <span class="color-blue">AppModule</span> {}</p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-3'>Key Components of the Root Module</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong><span class="background-grey">@NgModule</span> Decorator:</strong>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>It defines metadata for the module.</li>
                            <li>It contains the <span class="background-grey">declarations</span>, <span class="background-grey">imports</span>, <span class="background-grey">providers</span>, and <span class="background-grey">bootstrap</span> properties.</li>
                        </ul>
                    </li>
                    <li className='mt-3'>
                        <strong>Properties of <span class="background-grey">@NgModule</span>:</strong>
                        <table className='myBorderClass table mt-4'>
                            <thead>
                                <tr>
                                    <th>Property</th>
                                    <th>Purpose</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>declarations</td>
                                    <td>Lists all components, directives, and pipes that belong to the module.</td>
                                </tr>
                                <tr>
                                    <td>imports</td>
                                    <td>Specifies external modules required by the application (e.g., <span class="background-grey">BrowserModule</span>, <span class="background-grey">FormsModule</span>).</td>
                                </tr>
                                <tr>
                                    <td>providers</td>
                                    <td>Registers services to be used application-wide (dependency injection).</td>
                                </tr>
                                <tr>
                                    <td>bootstrap</td>
                                    <td>Specifies the root component to bootstrap when the application starts.</td>
                                </tr>
                            </tbody>
                        </table>
                    </li>
                </ul>


                <h5 className='mt-5 mb-3'>Modules Commonly Used in Root Module</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li><span class="background-grey">BrowserModule</span>: It is required for any Angular app that runs in a web browser. It includes services like change detection and dependency injection.</li>
                    <li><span class="background-grey">FormsModule</span>: It is used for template-driven forms.</li>
                    <li><span class="background-grey">ReactiveFormsModule</span>: It is used for reactive forms.</li>
                    <li><span class="background-grey">HttpClientModule</span>: It provides functionalities to make HTTP requests.</li>
                    <li><span class="background-grey">AppRoutingModule</span>: It defines and configures routing for the application.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Bootstrapping the Root Module</h5>
                <p>The Angular application starts by bootstrapping the root module. This is configured in the <span class="background-grey">main.ts</span> file.</p>
                <h6 className='mt-3'>main.ts</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">import</span> { <span class="color-blue">platformBrowserDynamic</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/platform-browser-dynamic'</span>;</p>
                        <p><span class="color-pink">import</span> { <span class="color-blue">AppModule</span> } <span class="color-pink">from</span> <span class="color-green">'./app/app.module'</span>;</p>
                        <br />
                        <p><span class="color-red">platformBrowserDynamic</span>().<span class="color-red">bootstrapModule</span>(AppModule)
                          .<span class="color-red">catch</span>(err => <span class="color-pink">console</span>.<span class="color-red">error</span>(err));</p>
                        `
                    }}></div>
                </div>

                <p><strong>Root Module</strong> is the starting point of an Angular application. It configures the application by declaring components, importing necessary modules, and bootstrapping the root component.</p>
                

            </div>
        </section>
    )
}