import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/jquery/slide";

export default function Slide() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Slide Effects of jQuery | Aspirant's Home");
        const urls = {
            'previous': '/jquery/fade',
            'next': '/jquery/animate'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Slide Effects of jQuery</h3>
            <div className='mt-4 mb-5'>
                <p>
                    jQuery provides a set of <strong>slide effects</strong> that allow you to show or hide elements with a sliding motion. These effects are great for creating dropdowns, accordion panels, and other interactive UI elements where you want a smooth slide transition. The main slide methods are <span class="background-grey">slideUp()</span>, <span class="background-grey">slideDown()</span>, and <span class="background-grey">slideToggle()</span>.
                </p>

                <h5 className='mt-5 mb-3'><span class="background-grey">slideDown()</span> Method</h5>
                <p>The <span class="background-grey">slideDown()</span> method displays a hidden element by sliding it down, revealing its content. Syntax will be the following -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(selector).<span class="color-red">slideDown</span>(speed, callback);</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">speed</span> (optional) defines the duration of the slide-down effect. Options include <span class="background-grey">"slow"</span>, <span class="background-grey">"fast"</span>, or a specific duration in milliseconds (e.g., <span class="background-grey">500</span>).</li>
                    <li><span class="background-grey">callback</span> (optional) is a function to execute after the slide-down completes.</li>
                </ul>
                <h6>Example :</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(<span class="color-green">"#panel"</span>).<span class="color-red">slideDown</span>(<span class="color-green">"slow"</span>);</p>
                        `
                    }}></div>
                </div>
                <p>This code makes the <span class="background-grey">#panel</span> element slide down with a slow animation, revealing it.</p>




                <h5 className='mt-5 mb-3'><span class="background-grey">slideUp()</span> Method</h5>
                <p>The <span class="background-grey">slideUp()</span> method hides an element by sliding it up, making it disappear. Look at the syntax below -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(selector).<span class="color-red">slideUp</span>(speed, callback);</p>
                        `
                    }}></div>
                </div>
                <h6>Example :</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"#panel"</span>).<span class="color-red">slideUp</span>(<span class="color-pink">800</span>, <span class="color-blue">function</span>() {</p>
                        <p class="ml-30">    <span class="color-red">alert</span>(<span class="color-green">"Panel has slid up and is now hidden!"</span>);</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>Here, <span class="background-grey">#panel</span> will slide up over 800 milliseconds, and an alert will appear once the slide-up is complete.</p>


                <h5 className='mt-5 mb-3'><span class="background-grey">slideToggle()</span> Method</h5>
                <p>The <span class="background-grey">slideToggle()</span> method alternates between <span class="background-grey">slideDown()</span> and <span class="background-grey">slideUp()</span>. If the element is currently visible, <span class="background-grey">slideToggle()</span> will slide it up to hide it. If it’s hidden, <span class="background-grey">slideToggle()</span> will slide it down to show it. This is useful for creating interactive elements that expand and collapse. Look at the below syntax -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(selector).<span class="color-red">slideToggle</span>(speed, callback);</p>
                        `
                    }}></div>
                </div>
                <h6>Example :</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(<span class="color-green">"#panel"</span>).<span class="color-red">slideToggle</span>(<span class="color-green">"fast"</span>);</p>
                        `
                    }}></div>
                </div>
                <p>In this example, if <span class="background-grey">#panel</span> is visible, it will slide up quickly to hide it. If it’s hidden, it will slide down quickly to show it.</p>




                
            </div>
        </section>
    )
}