import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Outlet, Link } from "react-router-dom";
export const pageUrl = () => "/angular/making-http-requests";

export default function HttpRequest() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Making HTTP Requests in Angular | Aspirant's Home");
        const urls = {
            'previous': '/angular/lazy-loaded-modules',
            'next': '/angular/handling-http-responses'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Making HTTP Requests</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In Angular, we use the <strong>HttpClient</strong> module to make requests to a server (like getting data, sending data, etc.). Think of it like sending letters to a post office and getting replies—<strong>HttpClient</strong> helps you handle this communication.
                </p>

                <h5 className='mt-5 mb-3'>How Does It Work?</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>You ask the server for data (e.g., user information).</li>
                    <li>The server responds with the requested data.</li>
                    <li>You use the data in your application.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Steps to Make an HTTP Request</h5>
                <p>Here’s a step-by-step guide to making a <strong>http request</strong> in Angular:</p>

                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>Import the HttpClientModule</strong>
                        <p>The <span class="background-grey">HttpClientModule</span> provides tools to make HTTP requests. First, you need to enable it in your app.</p>
                        <p>Open <span class="background-grey">app.module.ts</span> and add:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">import</span> { <span class="color-blue">HttpClientModule</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/common/http'</span>;</p>
                                <br />
                                <p><span class="color-red">@NgModule</span>({</p>
                                <p class="ml-30">    <span class="color-pink">declarations</span>: [<span class="color-green">...</span>],</p>
                                <p class="ml-30">    <span class="color-pink">imports</span>: [</p>
                                <p class="ml-60">        <span class="color-blue">BrowserModule</span>,</p>
                                <p class="ml-60">        <span class="color-blue">HttpClientModule</span>, <span class="color-grey">// Import this module</span></p>
                                <p class="ml-30">    ],</p>
                                <p class="ml-30">    <span class="color-pink">bootstrap</span>: [<span class="color-blue">AppComponent</span>],</p>
                                <p>})</p>
                                <p><span class="color-pink">export class</span> <span class="color-blue">AppModule</span> {}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='mt-4'>
                        <strong>Inject the HttpClient in a Service</strong>
                        <p>It’s a good practice to make HTTP requests in a <strong>service</strong>. A service is a class that handles data and logic for components.</p>

                        <p>Create a service using Angular CLI:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">ng generate</span> service <span class="color-blue">data</span></p>
                                `
                            }}></div>
                        </div>
                        <p>This creates: <span class="background-grey">data.service.ts</span></p>
                        <p>In <span class="background-grey">data.service.ts</span>, inject the HttpClient:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">import</span> { <span class="color-blue">Injectable</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                                <p><span class="color-pink">import</span> { <span class="color-blue">HttpClient</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/common/http'</span>;</p>
                                <p><span class="color-pink">import</span> { <span class="color-blue">Observable</span> } <span class="color-pink">from</span> <span class="color-green">'rxjs'</span>;</p>
                                <br />
                                <p><span class="color-red">@Injectable</span>({</p>
                                <p class="ml-30">    <span class="color-pink">providedIn</span>: <span class="color-green">'root'</span>, <span class="color-grey">// Makes the service available globally</span></p>
                                <p>})</p>
                                <p><span class="color-pink">export class</span> <span class="color-blue">DataService</span> {</p>
                                <p class="ml-30">    <span class="color-red">constructor</span>(<span class="color-pink">private</span> http: <span class="color-blue">HttpClient</span>) {}</p>
                                <br />
                                <p class="ml-30 color-grey">    // Method to get data from an API</p>
                                <p class="ml-30">    <span class="color-red">getUsers</span>(): Observable<any> {</p>
                                <p class="ml-60">        <span class="color-pink">return</span> <span class="color-blue">this</span>.http.<span class="color-red">get</span>(<span class="color-green">'https://jsonplaceholder.typicode.com/users'</span>);</p>
                                <p class="ml-30">    }</p>
                                <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='mt-4'>
                        <strong>Use the Service in a Component</strong>
                        <p>Now, use this service in a component to display data.</p>
                        <p>Example:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">ng generate</span> component <span class="color-blue">user-list</span></p>
                                `
                            }}></div>
                        </div>
                        <p>In user-list.component.ts:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">import</span> { <span class="color-blue">Component</span>, <span class="color-blue">OnInit</span> } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                                <p><span class="color-pink">import</span> { <span class="color-blue">DataService</span> } <span class="color-pink">from</span> <span class="color-green">'../data.service'</span>;</p>
                                <br />
                                <p><span class="color-red">@Component</span>({</p>
                                <p class="ml-30">    <span class="color-pink">selector</span>: <span class="color-green">'app-user-list'</span>,</p>
                                <p class="ml-30">    <span class="color-pink">template</span>: <span class="color-green">&#96;</span></p>
                                <p class="ml-60 color-green">        &lt;h2&gt;User List&lt;/h2&gt;</p>
                                <p class="ml-60 color-green">        &lt;ul&gt;</p>
                                <p class="ml-90 color-green">            &lt;li *ngFor="let user of users"&gt;{{ user.name }}&lt;/li&gt;</p>
                                <p class="ml-60 color-green">        &lt;/ul&gt;</p>
                                <p class="ml-30 color-green">    &#96;,</p>
                                <p>})</p>
                                <p><span class="color-pink">export class</span> <span class="color-blue">UserListComponent</span> <span class="color-pink">implements</span> <span class="color-blue">OnInit</span> {</p>
                                <p class="ml-30">    <span class="color-pink">users</span>: <span class="color-blue">any</span>[] = []; <span class="color-grey">// To store the user data</span></p>
                                <br />
                                <p class="ml-30">    <span class="color-red">constructor</span>(<span class="color-pink">private</span> dataService: <span class="color-blue">DataService</span>) {}</p>
                                <br />
                                <p class="ml-30">    <span class="color-red">ngOnInit</span>(): <span class="color-blue">void</span> {</p>
                                <p class="ml-60 color-grey">        // Call the service to fetch data</p>
                                <p class="ml-60">        <span class="color-blue">this</span>.dataService.<span class="color-red">getUsers</span>().<span class="color-red">subscribe</span>((data) => {</p>
                                <p class="ml-90">            <span class="color-blue">this</span>.users = data;</p>
                                <p class="ml-60">        });</p>
                                <p class="ml-30">    }</p>
                                <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='mt-4'>
                        <strong>Add the Component to the App</strong>
                        <p>Add the component to your main template (<span class="background-grey">app.component.html</span>):</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    &lt;app-user-list&gt;&lt;/app-user-list&gt;
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>

                
                <h5 className='mt-5 mb-3'>What Happens in the Code?</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li><strong>HttpClientModule</strong> enables HTTP requests.</li>
                    <li>The <span class="background-grey">DataService</span> uses <strong>HttpClient</strong> to fetch data from an API.</li>
                    <li>The component (<span class="background-grey">UserListComponent</span>) calls the service and subscribes to the data.</li>
                    <li>The data is displayed in the template using <span class="background-grey">*ngFor</span>.</li>
                </ul>


            </div>
        </section>
    )
}