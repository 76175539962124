import React, { useEffect, useState, useRef } from 'react'
import { Outlet, Link } from "react-router-dom";
import TopHeader from '../TopHeader';
import Footer from '../Footer'
import { MenuContext, TitleContext, PathContext } from "../../Context";
import { useContext } from "react";
import PreviousNext from '../PreviousNext';

export default function JQueryLayout() {

    const addspaceElement = useRef();
    const menuElement = useRef([]);
    const menu = useContext(MenuContext);
    const pathName = useContext(PathContext);
    console.log(pathName)
    const pathname = pathName.pathName;
    const [showSubMenu, setShowSubMenu] = useState(document.getElementsByClassName("activeSubMenu")[0] ? false : true)
    const title = useContext(TitleContext);

    const setPathName = (path) => {
        if(path)
            pathName.setPathName(path);
    }

    const OpenShowSubMenu = (e, path) => {
        //console.log(path, e.target);
        setPathName(path)
        e.currentTarget.nextSibling.style.display = e.currentTarget.nextSibling.style.display == 'block' ? 'none' : 'block';
    }

    useEffect(() => {
        setPathName(window.location.pathname)
        pathName.setPreviousName('Previous');
        //console.log(pathName.backname)
        if (menu.isMenu == true) menu.setMenuOpen(false)
        addspaceElement.current.scrollIntoView(true)
        const element = document.getElementsByClassName("activeMenu")[0];
        element && element.scrollIntoView({ block: "center" });
        const subelement = document.getElementsByClassName("activeSubMenu")[0];
        subelement && subelement.scrollIntoView({ block: "center" });
    }, [pathname,showSubMenu])

    return (
        <>
            <TopHeader />
            <div className='container-fluid'>
                <div className='row' style={{background:'#ddd'}}>
                    <div ref={menuElement} className={menu.isMenu == true ? 'col-md-3 col-lg-2 menuColumnMobile menuColumn p-0' : ' menuColumn p-0'} >
                        <div className='d-flex align-items-center headerMenu headerMainMenu sticky-top mb-4' style={{ 'height': '150px' }}>
                            <div>
                                <h2>jQuery Tutorial</h2>
                                <Link to="/quiz/jquery" className='rounded-corner btn btn-primary float-end'><small>Get Quiz</small></Link>
                            </div>
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/jquery/introduction' ? 'activeMenu' : ''}>
                                    <Link to="/jquery/introduction" onClick={() => { setPathName('/jquery/introduction'); } }>Introduction</Link>
                                </li>
                                <li className={pathname == '/jquery/get-started' ? 'activeMenu' : ''}>
                                    <Link to="/jquery/get-started" onClick={() => { setPathName('/jquery/get-started'); } }>Get Started</Link>
                                </li>
                                <li className={pathname == '/jquery/syntax' ? 'activeMenu' : ''}>
                                    <Link to="/jquery/syntax" onClick={() => { setPathName('/jquery/syntax'); } }>Syntax</Link>
                                </li>
                                <li className={pathname == '/jquery/selectors' ? 'activeMenu' : ''}>
                                    <Link to="/jquery/selectors" onClick={() => { setPathName('/jquery/selectors'); } }>Selectors</Link>
                                </li>
                                <li className={pathname && pathname.includes('/jquery/events') ? 'activeMenu' : ''}>
                                    <Link to="/jquery/events" onClick={() => { setPathName('/jquery/events'); } }>Events</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            jQuery Effects
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/jquery/hide-and-show' ? 'activeMenu' : ''}>
                                    <Link to="/jquery/hide-and-show" onClick={() => { setPathName('/jquery/hide-and-show'); } }>Hide/Show</Link>
                                </li>
                                <li className={pathname == '/jquery/fade' ? 'activeMenu' : ''}>
                                    <Link to="/jquery/fade" onClick={() => { setPathName('/jquery/fade'); } }>Fade</Link>
                                </li>
                                <li className={pathname == '/jquery/slide' ? 'activeMenu' : ''}>
                                    <Link to="/jquery/slide" onClick={() => { setPathName('/jquery/slide'); } }>Slide</Link>
                                </li>
                                <li className={pathname == '/jquery/animate' ? 'activeMenu' : ''}>
                                    <Link to="/jquery/animate" onClick={() => { setPathName('/jquery/animate'); } }>Animate</Link>
                                </li>
                                <li className={pathname == '/jquery/stop-function' ? 'activeMenu' : ''}>
                                    <Link to="/jquery/stop-function" onClick={() => { setPathName('/jquery/stop-function'); } }>stop()</Link>
                                </li>
                                <li className={pathname == '/jquery/callback' ? 'activeMenu' : ''}>
                                    <Link to="/jquery/callback" onClick={() => { setPathName('/jquery/callback'); } }>Callback</Link>
                                </li>
                                <li className={pathname == '/jquery/chaining' ? 'activeMenu' : ''}>
                                    <Link to="/jquery/chaining" onClick={() => { setPathName('/jquery/chaining'); } }>Chaining</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            jQuery HTML
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/jquery/get' ? 'activeMenu' : ''}>
                                    <Link to="/jquery/get" onClick={() => { setPathName('/jquery/get'); } }>Get</Link>
                                </li>
                                <li className={pathname == '/jquery/set' ? 'activeMenu' : ''}>
                                    <Link to="/jquery/set" onClick={() => { setPathName('/jquery/set'); } }>Set</Link>
                                </li>
                                <li className={pathname == '/jquery/add' ? 'activeMenu' : ''}>
                                    <Link to="/jquery/add" onClick={() => { setPathName('/jquery/add'); } }>Add</Link>
                                </li>
                                <li className={pathname == '/jquery/remove' ? 'activeMenu' : ''}>
                                    <Link to="/jquery/remove" onClick={() => { setPathName('/jquery/remove'); } }>Remove</Link>
                                </li>
                                <li className={pathname == '/jquery/css-classes' ? 'activeMenu' : ''}>
                                    <Link to="/jquery/css-classes" onClick={() => { setPathName('/jquery/css-classes'); } }>CSS Classes</Link>
                                </li>
                                <li className={pathname == '/jquery/css-function' ? 'activeMenu' : ''}>
                                    <Link to="/jquery/css-function" onClick={() => { setPathName('/jquery/css-function'); } }>css()</Link>
                                </li>
                                <li className={pathname == '/jquery/dimensions' ? 'activeMenu' : ''}>
                                    <Link to="/jquery/dimensions" onClick={() => { setPathName('/jquery/dimensions'); } }>Dimensions</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            jQuery Traversing
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/jquery/traversing' ? 'activeMenu' : ''}>
                                    <Link to="/jquery/traversing" onClick={() => { setPathName('/jquery/traversing'); } }>Traversing</Link>
                                </li>
                                <li className={pathname == '/jquery/ancestors' ? 'activeMenu' : ''}>
                                    <Link to="/jquery/ancestors" onClick={() => { setPathName('/jquery/ancestors'); } }>Ancestors</Link>
                                </li>
                                <li className={pathname == '/jquery/descendants' ? 'activeMenu' : ''}>
                                    <Link to="/jquery/descendants" onClick={() => { setPathName('/jquery/descendants'); } }>Descendants</Link>
                                </li>
                                <li className={pathname == '/jquery/siblings' ? 'activeMenu' : ''}>
                                    <Link to="/jquery/siblings" onClick={() => { setPathName('/jquery/siblings'); } }>Siblings</Link>
                                </li>
                                <li className={pathname == '/jquery/filtering' ? 'activeMenu' : ''}>
                                    <Link to="/jquery/filtering" onClick={() => { setPathName('/jquery/filtering'); } }>Filtering</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            jQuery AJAX
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/jquery/ajax' ? 'activeMenu' : ''}>
                                    <Link to="/jquery/ajax" onClick={() => { setPathName('/jquery/ajax'); } }>AJAX</Link>
                                </li>
                                <li className={pathname == '/jquery/load' ? 'activeMenu' : ''}>
                                    <Link to="/jquery/load" onClick={() => { setPathName('/jquery/load'); } }>Load</Link>
                                </li>
                                <li className={pathname == '/jquery/get-post' ? 'activeMenu' : ''}>
                                    <Link to="/jquery/get-post" onClick={() => { setPathName('/jquery/get-post'); } }>Get/Post</Link>
                                </li>
                                <li className={pathname == '/jquery/no-conflict-function' ? 'activeMenu' : ''}>
                                    <Link to="/jquery/no-conflict-function" onClick={() => { setPathName('/jquery/no-conflict-function'); } }>noConflict()</Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div className='col-12 middlePart'>
                        
                        <div className='headerAdSpace' ref={addspaceElement}>
                            <img className='img-fluid' src='https://aspirantshome.com/slide1.png' />
                        </div>
                        <div className='contentPart row sticky-top'>
                            <div className='col-12 col-md-12 mb-4 contentSection'>
                                <div className='container'>
                                    <div className='contentPage'>
                                        {
                                            pathName.urls ? <PreviousNext urls={ pathName.urls } name={pathName.backname} /> : <></>
                                        }
                                            <Outlet />
                                        {
                                            pathName.urls ? <PreviousNext urls={ pathName.urls } /> : <></>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='footerAdSpace'>
                                <img className='img-fluid' src='https://aspirantshome.com/slide4.png' />
                            </div>
                        </div>

                        
                    </div>
                    <div className='col-12  rightPart'>
                        <img className='img-fluid' src='https://aspirantshome.com/slide2.png' />
                        <img className='mt-5 img-fluid' src='https://aspirantshome.com/slide3.png' />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <div className='container-fluid footerPart'>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}