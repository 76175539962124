import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/angular/folder-structure";

export default function FolderStructure() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Folder Structure of Angular | Aspirant's Home");
        const urls = {
            'previous': '/angular/environment-setup',
            'next': '/angular/architecture'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Folder Structure</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The folder structure of an Angular application is designed to help developers organize code effectively and maintain scalability. Below is an explanation of the typical folders and files generated in an Angular project:
                </p>

                <h5 className='mt-5 mb-3'>Root Folder Structure</h5>
                <p>When you create a new Angular project, the folder structure looks like this:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate folderStructure' dangerouslySetInnerHTML={{
                        __html: `
                        <p>my-angular-app/</p>
                        <p>├── e2e/</p>
                        <p>├── node_modules/</p>
                        <p>├── src/</p>
                        <p>├── angular.json</p>
                        <p>├── package.json</p>
                        <p>├── tsconfig.json<p>
                        <p>├── README.md</p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-3'>Key Folders and Files</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong><span class="background-grey">e2e/</span> (End-to-End Testing)</strong>
                        <p className='mt-3'>This folder contains files for setting up and running <strong>end-to-end (E2E) tests</strong>.</p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li><span class="background-grey">protractor.conf.js</span>: This is configuration file for Protractor, the default testing framework.</li>
                            <li><span class="background-grey">src/</span>: This folder contains E2E test scripts.</li>
                        </ul>
                    </li>
                    <li className='mt-4'>
                        <span class="background-grey">node_modules/</span>
                        <p>This folder contains all the dependencies and libraries installed via <strong>npm</strong>.</p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>You should not modify files in this folder.</li>
                            <li>It is automatically managed by package.json.</li>
                        </ul>
                    </li>
                    <li className='mt-4'>
                        <span class="background-grey">src/</span>
                        <p>The main folder where the application’s source code resides. Its structure is as follows:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate folderStructure' dangerouslySetInnerHTML={{
                                __html: `
                                <p>src/</p>
                                <p>├── app/</p>
                                <p>├── assets/</p>
                                <p>├── environments/</p>
                                <p>├── index.html</p>
                                <p>├── main.ts</p>
                                <p>├── polyfills.ts</p>
                                <p>├── styles.css</p>
                                <p>├── test.ts</p>
                                `
                            }}></div>
                        </div>
                        <ul style={{ listStyle: 'lower-alpha' }}>
                            <li>
                                <span class="background-grey">app/</span>
                                <p>The core folder of the application where components, services, and modules are created.</p>
                                <ul style={{ listStyle: 'disc' }}>
                                    <li>
                                        <span class="background-grey">app.module.ts</span>
                                        <ul style={{ listStyle: 'disc' }}>
                                            <li>This is the root angular module.</li>
                                            <li>It declares all components and imports other modules.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <span class="background-grey">app.component.ts</span>
                                        <ul style={{ listStyle: 'disc' }}>
                                            <li>This is root component for the application.</li>
                                            <li>It acts as the entry point for the application UI.</li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li className='mt-4'>
                                <span className='background-grey'>assets/</span>
                                <ul style={{ listStyle: 'disc' }}>
                                    <li>It contains static files like images, icons, and custom fonts.</li>
                                    <li>The files here are served directly without processing.</li>
                                </ul>
                            </li>
                            <li className='mt-4'>
                                <span className='background-grey'>environments/</span>
                                <p>It contains configuration files for different environments (e.g., development, production).</p>
                                <ul style={{ listStyle: 'disc' }}>
                                    <li><span className='background-grey'>environment.ts</span>: It is the development configuration file.</li>
                                    <li><span className='background-grey'>environment.prod.ts</span>: It is the production configuration file.</li>
                                </ul>
                            </li>
                            <li className='mt-4'>
                                <span className='background-grey'>index.html</span>
                                <p>The main HTML file for the application.</p>
                                <p>It contains the <span className='background-grey'>&lt;app-root&gt;</span> tag where the Angular app gets bootstrapped.</p>
                            </li>
                            <li className='mt-4'>
                                <span className='background-grey'>main.ts</span>
                                <ul style={{ listStyle: 'disc' }}>
                                    <li>It is the entry point of the application.</li>
                                    <li>It bootstraps the root module (<span className='background-grey'>AppModule</span>) and starts the app.</li>
                                </ul>
                            </li>
                            <li className='mt-4'>
                                <span className='background-grey'>polyfills.ts</span>
                                <ul style={{ listStyle: 'disc' }}>
                                    <li>It contains polyfills to support older browsers that do not natively support modern JavaScript features.</li>
                                </ul>
                            </li>
                            <li className='mt-4'>
                                <span className='background-grey'>styles.css</span>
                                <ul style={{ listStyle: 'disc' }}>
                                    <li>It is the global stylesheet for the application.</li>
                                    <li>You can add other stylesheets or preprocessors like SCSS here.</li>
                                </ul>
                            </li>
                            <li className='mt-4'>
                                <span className='background-grey'>test.ts</span>
                                <ul style={{ listStyle: 'disc' }}>
                                    <li>This is the entry point for running unit tests.</li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                    <li className='mt-4'>
                        <strong>Configuration Files</strong>
                        <p className='mt-4 mb-0'><span class="background-grey">angular.json</span></p>
                        <p className='mt-0 mb-0'>It is the configuration file for Angular CLI.</p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>It defines settings for building, serving, and testing the app.</li>
                        </ul>

                        <p className='mt-4 mb-0'><span class="background-grey">package.json</span></p>
                        <p className='mt-0 mb-0'>It manages dependencies and scripts for the application.</p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>It is a list of all installed libraries and tools.</li>
                        </ul>

                        <p className='mt-4 mb-0'><span class="background-grey">tsconfig.json</span></p>
                        <p className='mt-0 mb-0'>It is typeScript configuration file.</p>
                        <ul style={{ listStyle: 'disc' }}>
                            <p>It specifies TypeScript compiler options, like target version and module settings.</p>
                        </ul>

                    </li>


                    <li className='mt-4'>
                        <strong>Configuration Files</strong>

                        <p className='mt-4 mb-0'><span class="background-grey">README.md</span></p>
                        <p className='mt-0 mb-0'>A markdown file that provides an overview of the project.</p>

                        <p className='mt-4 mb-0'><span class="background-grey">.gitignore</span></p>
                        <p className='mt-0 mb-0'>It specifies files and folders to ignore when pushing to a Git repository.</p>

                    </li>


                </ul>



            </div>
        </section>
    )
}