import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/function-parameters";

export default function FunctionParameter() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Function Parameters in JavaScript | Aspirant's Home");
        const urls = {
            'previous': '/javascript/function-definition',
            'next': '/javascript/function-invocation'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Function Parameters</h3>
            <div className='mt-4 mb-5'>
                <p>
                    A <strong>parameter</strong> is like a placeholder or a variable that you define in a function. It allows you to pass <strong>input values</strong> to the function so the function can perform actions using those inputs.
                </p>
                <p>Think of a parameter as a way to tell the function: "Here’s the value you need to work with."</p>

                <h5 className='mt-5 mb-3'>Key Points About Parameters</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>Defined in the Function Declaration:</strong>
                        <p>Parameters are specified inside the parentheses <span className='background-grey'>( )</span> when you define a function.</p>
                    </li>
                    <li>
                        <strong>Dynamic Input:</strong>
                        <p>You can give the function different values (called <strong>arguments</strong>) when calling it.</p>
                    </li>
                    <li>
                        <strong>Reusable:</strong>
                        <p>You don’t need to write separate functions for every input. Instead, use parameters to handle various inputs.</p>
                    </li>
                </ul>

                <h5 className='mt-5 mb-3'>Example : Function with Parameters</h5>
                <p>Here’s a function that greets a person by their name:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">function</span> <span class="color-red">greet</span>(name) {  <span class="color-grey">// 'name' is a parameter</span></p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Hello, "</span> + name + <span class="color-green">"!"</span>);</p>
                        <p>}</p>
                        <br />
                        <p class="color-grey">// Call the function with different arguments</p>
                        <p><span class="color-red">greet</span>(<span class="color-green">"Alice"</span>); <span class="color-grey">// Output: Hello, Alice!</span></p>
                        <p><span class="color-red">greet</span>(<span class="color-green">"Bob"</span>);   <span class="color-grey">// Output: Hello, Bob!</span></p>
                        `
                    }}></div>
                </div>
                <p>In this example:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span className='background-grey'>name</span> is a parameter.</li>
                    <li><span className='background-grey'>"Alice"</span> and <span className='background-grey'>"Bob"</span> are <strong>arguments</strong> passed to the function when it is called.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Parameters vs. Arguments</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>
                        <p className='mb-0'><strong>Parameter</strong>: Defined when creating a function. It’s a placeholder for the value.</p>
                        <p className='mt-0'>Example: <span className='background-grey'>name</span> in <span className='background-grey'>function greet(name)</span>.</p>
                    </li>
                    <li>
                        <p className='mb-0'><strong>Argument</strong>: The actual value you pass when calling the function.</p>
                        <p className='mt-0'>Example: <span className='background-grey'>"Alice"</span> in <span className='background-grey'>greet("Alice")</span>.</p>
                    </li>
                </ul>

                <h5 className='mt-5 mb-3'>Multiple Parameters</h5>
                <p>A function can have more than one parameter.</p>
                <h6>Function with Multiple Parameters</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">function</span> <span class="color-red">addNumbers</span>(a, b) {  <span class="color-grey">// 'a' and 'b' are parameters</span></p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(a + b);</p>
                        <p>}</p>
                        <br />
                        <p class="color-grey">// Call the function with arguments</p>
                        <p><span class="color-red">addNumbers</span>(<span class="color-pink">5</span>, <span class="color-pink">10</span>); <span class="color-grey">// Output: 15</span></p>
                        <p><span class="color-red">addNumbers</span>(<span class="color-pink">20</span>, <span class="color-pink">30</span>); <span class="color-grey">// Output: 50</span></p>
                        `
                    }}></div>
                </div>
                <p>In this example,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span className='background-grey'>a</span> and <span className='background-grey'>b</span> are parameters.</li>
                    <li><span className='background-grey'>5</span> and <span className='background-grey'>10</span> are arguments.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Default Parameters</h5>
                <p>You can provide <strong>default values</strong> for parameters. These are used when no argument is passed to the function.</p>
                <h6>Function with Default Parameters</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">function</span> <span class="color-red">greet</span>(name = <span class="color-green">"Guest"</span>) {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"Hello, "</span> + name + <span class="color-green">"!"</span>);</p>
                        <p>}</p>
                        <br />
                        <p><span class="color-red">greet</span>();           <span class="color-grey">// Output: Hello, Guest! (default value used)</span></p>
                        <p><span class="color-red">greet</span>(<span class="color-green">"Charlie"</span>);  <span class="color-grey">// Output: Hello, Charlie!</span></p>
                        `
                    }}></div>
                </div>
                <p>In this example,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>If no argument is provided, <span className='background-grey'>name</span> takes the default value <span className='background-grey'>"Guest"</span>.</li>
                </ul>



            </div>
        </section>
    )
}