import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/jquery/events/mousemove";

export default function Mousemove() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("jQuery mousemove() Method | Aspirant's Home");
        const urls = {
            'previous': '/jquery/events'
        }
        path.setPreviousNext(urls);
        path.setPreviousName('Back to Events');
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>jQuery mousemove() Method</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">mousemove()</span> method in jQuery triggers an event whenever the mouse pointer moves within the boundaries of a selected element. This method is commonly used for tracking mouse movements, creating interactive effects, or implementing custom functionality like real-time position tracking or dynamic UI updates.
                </p>

                <p>It has a basic syntax -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(selector).<span class="color-red">mousemove</span>(function);</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">selector</span> specifies the element(s) to attach the <span class="background-grey">mousemove</span> event to.</li>
                    <li><span class="background-grey">function</span> is a callback function that executes every time the mouse moves within the element.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Example: Displaying Mouse Coordinates</h5>
                <p>You can use <span class="background-grey">mousemove()</span> to show the real-time position of the mouse pointer.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;div <span class="color-pink">id</span>=<span class="color-green">"trackingArea"</span> <span class="color-pink">style</span>=<span class="color-green">"width: 300px; height: 150px; background-color: lightgray;"</span>&gt;</p>
                        <p class="ml-30">    Move your mouse here!</p>
                        <p>&lt;/div&gt;</p>
                        <p>&lt;p <span class="color-pink">id</span>=<span class="color-green">"coordinates"</span>&gt;&lt;/p&gt;</p>
                        `
                    }}></div>
                </div>
                <h6>jQuery Code</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"#trackingArea"</span>).<span class="color-red">mousemove</span>(<span class="color-blue">function</span>(event) {</p>
                        <p class="ml-30">    $(<span class="color-green">"#coordinates"</span>).<span class="color-red">text</span>(<span class="color-green">"X: "</span> + event.pageX + <span class="color-green">", Y: "</span> + event.pageY);</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span class="background-grey">mousemove()</span> event tracks the mouse pointer's position as it moves within the <span class="background-grey">#trackingArea</span> div.</li>
                    <li>The coordinates are displayed in the <span class="background-grey">#coordinates</span> paragraph using the <span class="background-grey">event.pageX</span> and <span class="background-grey">event.pageY</span> properties, which return the mouse's position relative to the document.</li>
                </ul>


            </div>
        </section>
    )
}