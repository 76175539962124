import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/tag/hr";

export default function HrTag() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - <hr> Tag | Aspirant's Home");
        const urls = {
            'previous': '/html/tag-list'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - &lt;hr&gt; Tag</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">&lt;hr&gt;</span> tag in HTML represents a thematic break or horizontal line, commonly used to visually separate sections or content within a webpage. It is a self-closing tag and is typically styled by browsers to display a horizontal line across the page, creating a division between content areas.
                </p>

                <h5 className='mt-5 mb-3'>Structure and Purpose of <span class="background-grey">&lt;hr&gt;</span> Tag</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span class="background-grey">&lt;hr&gt;</span> tag adds a horizontal line that spans across the page, providing a visual separator.</li>
                    <li>Used to signify a shift in topic or content, making it easier for readers to distinguish between different sections.</li>
                    <li><span class="background-grey">&lt;hr&gt;</span> doesn’t need a closing tag (<span class="background-grey">&lt;/hr&gt;</span> is not required).</li>
                </ul>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <p>The <span class="background-grey">&lt;hr&gt;</span> tag is straightforward and requires no attributes to function:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;hr&gt;</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Example Usage of <span class="background-grey">&lt;hr&gt;</span> Tag</h5>
                <p>Here’s an example showing how <span class="background-grey">&lt;hr&gt;</span> can be used to separate sections of content:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Horizontal Rule Example&lt;/title&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <br />    
                        <p class="ml-60">        &lt;h2&gt;About Us&lt;/h2&gt;</p>
                        <p class="ml-60">        &lt;p&gt;We are a company dedicated to delivering quality products and services.&lt;/p&gt;</p>
                        <br />        
                        <p class="ml-60">        &lt;hr&gt;</p>
                        <br />        
                        <p class="ml-60">        &lt;h2&gt;Our Services&lt;/h2&gt;</p>
                        <p class="ml-60">        &lt;p&gt;We offer a wide range of services to meet your needs.&lt;/p&gt;</p>
                        <br />        
                        <p class="ml-60">        &lt;hr&gt;</p>
                        <br />        
                        <p class="ml-60">        &lt;h2&gt;Contact Us&lt;/h2&gt;</p>
                        <p class="ml-60">        &lt;p&gt;Feel free to reach out to us via email or phone.&lt;/p&gt;</p>
                        <br />    
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Output will be the following -</p>
                <div className='outputPalateBox mt-2 mb-4'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <!DOCTYPE html>
                        <html lang="en">
                        <head>
                          <meta charset="UTF-8">
                          <title>Horizontal Rule Example</title>
                        </head>
                        <body>
                        
                        <h2>About Us</h2>
                        <p>We are a company dedicated to delivering quality products and services.</p>
                        
                        <hr>
                        
                        <h2>Our Services</h2>
                        <p>We offer a wide range of services to meet your needs.</p>
                        
                        <hr>
                        
                        <h2>Contact Us</h2>
                        <p>Feel free to reach out to us via email or phone.</p>
                        
                        </body>
                        </html>
                        `
                    }}></div>
                </div>

                <p>
                    The <span class="background-grey">&lt;hr&gt;</span> tag is a simple but effective HTML element for adding visual separations between sections. It helps organize content, making it easier for users to follow along. With CSS, the appearance of <span class="background-grey">&lt;hr&gt;</span> can be customized to match any design, giving developers flexibility in how they structure and style page content.
                </p>


            </div>
        </section>
    )
}