import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/tag/ins";

export default function Ins() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - <ins> Tag | Aspirant's Home");
        const urls = {
            'previous': '/html/tag-list'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - &lt;ins&gt; Tag</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">&lt;ins&gt;</span> tag in HTML is used to represent inserted text. Text inside the <span class="background-grey">&lt;ins&gt;</span> tag is typically displayed with an underline, visually indicating that the text has been added or modified. This tag is useful when marking edits or additions in a document and provides semantic meaning, indicating that the text was deliberately inserted.
                </p>

                <h5 className='mt-5 mb-3'>Structure and Purpose of <span class="background-grey">&lt;ins&gt;</span> Tag</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The text within <span class="background-grey">&lt;ins&gt;</span> is usually rendered with an underline by default, indicating insertion.</li>
                    <li>The <span class="background-grey">&lt;ins&gt;</span> tag conveys that the text is newly added or modified content.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <p>The <span class="background-grey">&lt;ins&gt;</span> tag wraps around the text you want to mark as inserted:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;ins&gt;Your inserted text here&lt;/ins&gt;</p>
                        `
                    }}></div>
                </div> 

                <h5 className='mt-5 mb-3'>Example Usage of <span class="background-grey">&lt;ins&gt;</span> Tag</h5>
                <p>Here’s an example using <span class="background-grey">&lt;ins&gt;</span> to show text that has been added or edited:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Inserted Text Example&lt;/title&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <br />    
                        <p class="ml-60">        &lt;p&gt;The product price is &lt;del&gt;$30&lt;/del&gt; &lt;ins&gt;$25&lt;/ins&gt;.&lt;/p&gt;</p>
                        <br />    
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div> 
                <p>In this example, "$30" is shown as deleted using <span class="background-grey">&lt;del&gt;</span>, while "$25" is marked with <span class="background-grey">&lt;ins&gt;</span>, indicating it is the new price.</p>
                <p>Output will be the following -</p>
                <div className='outputPalateBox mt-2 mb-4'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <!DOCTYPE html>
                        <html lang="en">
                            <head>
                                <meta charset="UTF-8">
                                <title>Inserted Text Example</title>
                            </head>
                            <body>
                            
                                <p>The product price is <del>$30</del> <ins>$25</ins>.</p>
                            
                            </body>
                        </html>
                        `
                    }}></div>
                </div> 


            </div>
        </section>
    )
}