import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/events/oncopy";

export default function Oncopy() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - oncopy Event | Aspirant's Home");
        const urls = {
            'previous': '/html/events'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - oncopy Event</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">oncopy</span> event in HTML is triggered when a user copies content from a webpage, typically using keyboard shortcuts (like <span class="background-grey">Ctrl+C</span>) or right-clicking and selecting "Copy." This event can be used to display a message, track content copying, or modify the copied content.
                </p>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <p>The <span class="background-grey">oncopy</span> event can be attached to an HTML element that contains the text or content users may want to copy.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;p <span class="color-pink">oncopy</span>=<span class="color-green">"handleCopy()"</span>&gt;This is some copyable text.&lt;/p&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Alternatively, it can be attached using JavaScript:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>document.<span class="color-red">getElementById</span>(<span class="color-green">"copyableText"</span>).oncopy = <span class="color-red">function</span>() {</p>
                        <p class="ml-30">    console.<span class="color-red">log</span>(<span class="color-green">"Content copied!"</span>);</p>
                        <p>};</p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-3'>Example: Display Message on Copy</h5>
                <p>This example displays a message in the console when the text is copied.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;p <span class="color-pink">oncopy</span>=<span class="color-green">"showCopyMessage()"</span>&gt;Copy this text!&lt;/p&gt;</p>
                        <br />
                        <p>&lt;script&gt;</p>
                        <p class="ml-30">  <span class="color-blue">function</span> <span class="color-red">showCopyMessage</span>() {</p>
                        <p class="ml-60">      console.<span class="color-red">log</span>(<span class="color-green">"Content has been copied!"</span>);</p>
                        <p class="ml-30">  }</p>
                        <p>&lt;/script&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example, The <span class="background-grey">showCopyMessage</span> function is triggered when the text is copied, logging a message to the console.</p>



            </div>
        </section>
    )
}