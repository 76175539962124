import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/javascript/introduction";

export default function Introduction() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("An Introduction to Javascript | Aspirant's Home");
        const urls = {
            'next': '/javascript/environment-setup'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>What is Javascript?</h3>
            <div className='mt-4 mb-5'>
                <p>
                    JavaScript is a <strong>programming language</strong> used to make websites and web applications interactive. It allows you to add dynamic behavior to web pages, such as:
                </p>
                <ul style={{listStyle: 'disc'}}>
                    <li><strong>Animations</strong> (e.g., slideshows, image galleries).</li>
                    <li><strong>Interactive forms</strong> (e.g., real-time validation of user input).</li>
                    <li><strong>Dynamic content</strong> (e.g., loading data without refreshing the page).</li>
                    <li><strong>User interaction</strong> (e.g., responding to clicks, mouse movements, or keyboard input).</li>
                </ul>

                <h5 className='mt-5 mb-3'>Simple Explanation</h5>
                <p>Think of a web page as a house:</p>
                <ul style={{listStyle: 'disc'}}>
                    <li><strong>HTML</strong> is the structure or the walls of the house.</li>
                    <li><strong>CSS</strong> is the decoration, like paint and furniture, making it look nice.</li>
                    <li><strong>JavaScript</strong> is what makes the house "smart" — like opening doors automatically, controlling lights, or turning on the AC when someone enters. It brings the house (or web page) to life.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Why Learn JavaScript?</h5>
                <p>JavaScript is:</p>
                <ul style={{listStyle: 'decimal'}}>
                    <li><strong>Beginner-Friendly</strong>: It's easy to start with and widely used.</li>
                    <li><strong>Everywhere</strong>: Works on all modern web browsers.</li>
                    <li><strong>Powerful</strong>: Used for websites, mobile apps, games, and even servers.</li>
                </ul>

                
                <h5 className='mt-5 mb-3'>How Does JavaScript Work?</h5>
                <p>JavaScript runs directly in your web browser. Every browser (like Chrome, Firefox, Safari) has a JavaScript engine that understands and runs JavaScript code.</p>

                <p>For example:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">alert</span>(<span class="color-green">"Welcome to JavaScript!"</span>);</p>
                        `
                    }}></div>
                </div>
                <p>When you run this code, the browser shows a pop-up message saying, "Welcome to JavaScript!"</p>

            </div>
        </section>
    )
}