import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/angular/template-driven-forms";

export default function TemplateDrivenForms() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Template-Driven Forms in Angular | Aspirant's Home");
        const urls = {
            'previous': '/angular/creating-custom-directives',
            'next': '/angular/reactive-forms'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Template-Driven Forms</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Template-Driven Forms in Angular are forms where most of the logic and validation are defined directly in the HTML template, rather than in the component class. They are simpler to implement compared to <strong>Reactive Forms</strong> and are ideal for basic use cases.
                </p>
                <p>
                    Template-driven forms rely on Angular’s <strong>two-way data binding</strong> and use directives like ngModel to bind data between the form controls and the component.
                </p>


                <h5 className='mt-5 mb-3'>Steps to Create Template-Driven Forms</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li><strong>Enable FormsModule</strong> Import the <span class="background-grey">FormsModule</span> in your application module to enable template-driven forms.</li>
                    <li><strong>Create the Template</strong> Define the form controls using directives such as ngModel.</li>
                    <li><strong>Handle Form Submission</strong> Use Angular's event binding to handle form submissions.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Example: A Simple Contact Form</h5>
                <h6 className='mt-4'>Step 1: Import FormsModule</h6>
                <p>In your application module, import the <span class="background-grey">FormsModule</span> from <span class="background-grey">@angular/forms</span>.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p class="color-grey">// app.module.ts</p>
                        <p><span class="color-pink">import</span> { NgModule } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                        <p><span class="color-pink">import</span> { BrowserModule } <span class="color-pink">from</span> <span class="color-green">'@angular/platform-browser'</span>;</p>
                        <p><span class="color-pink">import</span> { FormsModule } <span class="color-pink">from</span> <span class="color-green">'@angular/forms'</span>;</p>
                        <br />
                        <p><span class="color-pink">import</span> { AppComponent } <span class="color-pink">from</span> <span class="color-green">'./app.component'</span>;</p>
                        <br />
                        <p><span class="color-red">@NgModule</span>({</p>
                        <p class="ml-30">    <span class="color-pink">declarations</span>: [</p>
                        <p class="ml-60">        <span class="color-blue">AppComponent</span></p>
                        <p class="ml-30">    ],</p>
                        <p class="ml-30">    <span class="color-pink">imports</span>: [</p>
                        <p class="ml-60">        <span class="color-blue">BrowserModule</span>,
                        <p class="ml-60">        <span class="color-blue">FormsModule</span> <span class="color-grey">// Import FormsModule here</span></p>
                        <p class="ml-30">    ],</p>
                        <p class="ml-30">    <span class="color-pink">providers</span>: [],</p>
                        <p class="ml-30">    <span class="color-pink">bootstrap</span>: [<span class="color-blue">AppComponent</span>]</p>
                        <p>})</p>
                        <p><span class="color-pink">export class</span> <span class="color-blue">AppModule</span> { }</p>
                        `
                    }}></div>
                </div>

                <h6 className='mt-5'>Step 2: Create the Component</h6>
                <h6 className='mt-4'>HTML Template</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;form <span class="color-pink">#contactForm</span>=<span class="color-green">"ngForm"</span> <span class="color-pink">(ngSubmit)</span>=<span class="color-green">"onSubmit(contactForm)"</span>&gt;</p>
                        <p class="ml-30">    &lt;div&gt;</p>
                        <p class="ml-60">        &lt;label <span class="color-pink">for</span>=<span class="color-green">"name"</span>&gt;Name:&lt;/label&gt;</p>
                        <p class="ml-60">        &lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">id</span>=<span class="color-green">"name"</span> <span class="color-pink">name</span>=<span class="color-green">"name"</span> [<span class="color-pink">(ngModel)</span>]=<span class="color-green">"contact.name"</span> <span class="color-pink">required</span>&gt;</p>
                        <p class="ml-30">    &lt;/div&gt;</p>
                        <br />    
                        <p class="ml-30">    &lt;div&gt;</p>
                        <p class="ml-60">        &lt;label <span class="color-pink">for</span>=<span class="color-green">"email"</span>&gt;Email:&lt;/label&gt;</p>
                        <p class="ml-60">        &lt;input <span class="color-pink">type</span>=<span class="color-green">"email"</span> <span class="color-pink">id</span>=<span class="color-green">"email"</span> <span class="color-pink">name</span>=<span class="color-green">"email"</span> [<span class="color-pink">(ngModel)</span>]=<span class="color-green">"contact.email"</span> <span class="color-pink">required</span>&gt;</p>
                        <p class="ml-30">    &lt;/div&gt;</p>
                        <br />    
                        <p class="ml-30">    &lt;div&gt;</p>
                        <p class="ml-60">        &lt;label <span class="color-pink">for</span>=<span class="color-green">"message"</span>&gt;Message:&lt;/label&gt;</p>
                        <p class="ml-60">        &lt;textarea <span class="color-pink">id</span>=<span class="color-green">"message"</span> <span class="color-pink">name</span>=<span class="color-green">"message"</span> [<span class="color-pink">(ngModel)</span>]=<span class="color-green">"contact.message"</span> <span class="color-pink">required</span>&gt;&lt;/textarea&gt;</p>
                        <p class="ml-30">    &lt;/div&gt;</p>
                        <br />
                        <p class="ml-30">    &lt;button <span class="color-pink">type</span>=<span class="color-green">"submit"</span> [<span class="color-pink">disabled</span>]=<span class="color-green">"!contactForm.valid"</span>&gt;Submit&lt;/button&gt;</p>
                        <p>&lt;/form&gt;</p>
                        `
                    }}></div>
                </div>
                <h6>Component (TypeScript)</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">import</span> { Component } <span class="color-pink">from</span> <span class="color-green">'@angular/core'</span>;</p>
                        <br />
                        <p><span class="color-red">@Component</span>({</p>
                        <p class="ml-30">    <span class="color-pink">selector</span>: <span class="color-green">'app-contact-form'</span>,</p>
                        <p class="ml-30">    <span class="color-pink">templateUrl</span>: <span class="color-green">'./contact-form.component.html'</span></p>
                        <p>})</p>
                        <p><span class="color-pink">export class</span> <span class="color-blue">ContactFormComponent</span> {</p>
                        <p class="ml-30">    contact = {</p>
                        <p class="ml-60">        <span class="color-pink">name</span>: <span class="color-green">''</span>,</p>
                        <p class="ml-60">        <span class="color-pink">email</span>: <span class="color-green">''</span>,</p>
                        <p class="ml-60">        <span class="color-pink">message</span>: <span class="color-green">''</span></p>
                        <p class="ml-30">    };</p>
                        <br />
                        <p class="ml-30">    <span class="color-red">onSubmit</span>(form: <span class="color-blue">any</span>) {</p>
                        <p class="ml-60">        <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">'Form Submitted!'</span>, form.value);</p>
                        <p class="ml-30">    }</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>

                <h6 className='mt-4'>Step 3: Run the Application</h6>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The form will automatically validate required fields.</li>
                    <li>The <strong>Submit</strong> button will be disabled until all fields are valid.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Advantages of Template-Driven Forms</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>Simplicity:</strong>
                        <p className='ml-30'>Easy to set up with minimal code.</p>
                    </li>
                    <li>
                        <strong>Two-Way Data Binding:</strong>
                        <p className='ml-30'>Automatically synchronizes the form with the model.</p>
                    </li>
                    <li>
                        <strong>Declarative Syntax:</strong>
                        <p className='ml-30'>Validation and form logic are directly in the template.</p>
                    </li>
                </ul>

                <h5 className='mt-5 mb-3'>Limitations of Template-Driven Forms</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>Limited Scalability:</strong>
                        <p className='ml-30'>Not ideal for large or complex forms.</p>
                    </li>
                    <li>
                        <strong>Less Control:</strong>
                        <p className='ml-30'>Difficult to track detailed form state in the component class.</p>
                    </li>
                    <li>
                        <strong>Unit Testing:</strong>
                        <p className='ml-30'>Harder to test compared to reactive forms.</p>
                    </li>
                </ul>


                <h5 className='mt-5 mb-3'>When to Use Template-Driven Forms?</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>Template-Driven</strong> forms are ideal for forms with a limited number of input fields and straightforward requirements. With just the <span class="background-grey">FormsModule</span> and a template, you can quickly create and manage forms.</li>
                    <li>If your form's validation rules are simple and do not require complex or custom logic, <strong>Template-Driven</strong> forms are a great fit.</li>
                    <li>When creating a prototype or a small application, you need rapid development with minimal setup. <strong>Template-Driven</strong> forms are lightweight and easy to implement.</li>
                </ul>



            </div>
        </section>
    )
}