import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/jquery/syntax";

export default function Syntax() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Syntax of jQuery | Aspirant's Home");
        const urls = {
            'previous': '/jquery/get-started',
            'next': '/jquery/selectors'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Syntax of jQuery</h3>
            <div className='mt-4 mb-5'>
                <p>In jQuery, the syntax follows a simple structure that allows you to select HTML elements and perform actions on them.</p>
                <p>The basic jQuery syntax structure is:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(selector).<span class="color-red">action</span>();</p>
                        `
                    }}></div>
                </div>
                <p>Here,</p>
                <ul style={{listStyle:'disc'}}>
                    <li>The <span class="background-grey">$</span> symbol is shorthand for jQuery. It’s a quick way to tell the browser that we’re using jQuery. Whenever you see <span class="background-grey">$</span>, it means jQuery is being used to do something.</li>
                    <li>
                        The <strong>selector</strong> tells jQuery which HTML elements to target. You can select elements by their <strong>ID, class, tag name</strong>, or other attributes.
                        <ul style={{ listStyle: 'disc' }}>
                            <li><span class="background-grey">$("#myId")</span> selects an element with ID <span class="background-grey">myId</span>.</li>
                            <li><span class="background-grey">$(".myClass")</span> selects all elements with the class <span class="background-grey">myClass</span>.</li>
                            <li><span class="background-grey">$("p")</span> selects all <span class="background-grey">&lt;p&gt;</span> (paragraph) elements.</li>
                        </ul>
                    </li>
                    <li>The <strong>action</strong> is what you want to do with the selected elements.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Example of jQuery Syntax</h5>
                <p>Here’s a simple example to illustrate the syntax:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>$(<span class="color-green">"#example"</span>).<span class="color-red">text</span>(<span class="color-green">"Hello, jQuery!"</span>);</p>
                        `
                    }}></div>
                </div>
                <p>In this example,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">$("#example")</span>: Selects the element with ID <span class="background-grey">example</span>.</li>
                    <li><span class="background-grey">.text("Hello, jQuery!")</span>: Changes the text inside this element to <span class="background-grey">"Hello, jQuery!"</span>.</li>
                </ul>



            </div>
        </section>
    )
}