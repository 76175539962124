import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/jquery/events/event-delegatetarget";

export default function DelegateTarget() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("jQuery event.delegateTarget Object | Aspirant's Home");
        const urls = {
            'previous': '/jquery/events'
        }
        path.setPreviousNext(urls);
        path.setPreviousName('Back to Events');
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>jQuery event.delegateTarget Object</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">event.delegateTarget</span> property in jQuery refers to the <strong>element to which the event handler was attached</strong>, especially in the context of <strong>delegated events</strong>. This is useful when events are bound to a parent or ancestor element, and the handler is triggered by a child element.
                </p>

                <p>It has a basic syntax -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(selector).<span class="color-red">on</span>(eventType, childSelector, <span class="color-blue">function</span>(event) {</p>
                        <p class="ml-30">    <span class="color-blue">let</span> element = event.delegateTarget;</p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">selector</span> is the element to which the event is delegated.</li>
                    <li><span class="background-grey">eventType</span> is type of event (e.g., <span class="background-grey">"click"</span>, <span class="background-grey">"focus"</span>).</li>
                    <li><span class="background-grey">childSelector</span> (optional) is a selector string to filter descendants.</li>
                    <li><span class="background-grey">event.delegateTarget</span> refers to the element where the handler was originally bound.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Example : Simple Delegated Event</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;div <span class="color-pink">id</span>=<span class="color-green">"container"</span>&gt;</p>
                        <p class="ml-30">    &lt;button <span class="color-pink">class</span>=<span class="color-green">"child"</span>&gt;Click Me&lt;/button&gt;</p>
                        <p>&lt;/div&gt;</p>
                        `
                    }}></div>
                </div>
                <h6>jQuery Code</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>$(<span class="color-green">"#container"</span>).<span class="color-red">on</span>(<span class="color-green">"click"</span>, <span class="color-green">".child"</span>, <span class="color-blue">function</span>(event) {</p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"delegateTarget:"</span>, event.delegateTarget.id); <span class="color-grey">// Logs "container"</span></p>
                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">"target:"</span>, event.target.className); <span class="color-grey">// Logs "child"</span></p>
                        <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">event.delegateTarget</span> refers to <span class="background-grey">#container</span>, where the handler is attached.</li>
                    <li><span class="background-grey">event.target</span> refers to <span class="background-grey">.child</span>, the element clicked.</li>
                </ul>


            </div>
        </section>
    )
}